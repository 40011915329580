import React, { useEffect, useState } from "react";
import CompareProductsList from "./CompareProductsList/CompareProductsList";
import Features from "./Features/Features";
import "./comparePage.scss";
import styled, { createGlobalStyle } from "styled-components";
import { Col, Row, Button } from "react-bootstrap";
import { subMonths } from "date-fns";
import { useHistory } from "react-router-dom";
import { Avatar } from "../Home/style";
import _ from "lodash";
import { BackButton, FloatButton } from "components";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { compareQuotes, setShowPop } from "../quotesPage/quote.slice";
import PrevInsurerPopup2 from "modules/quotesPage/quotesPopup/prevInsurerPopup/prevInsurerPopup2";
import { UpdateQuotesData } from "../quotesPage/quote.slice";
import moment from "moment";
import { useMediaPredicate } from "react-media-hook";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { currencyFormater, downloadFile } from "utils";
import Popup from "components/Popup/Popup";
import CancelIcon from "@material-ui/icons/Cancel";
import { TypeReturn } from 'modules/type';

/*---------------date config----------------*/

const notSureDate = subMonths(new Date(Date.now()), 9);
const formatedDate = moment(notSureDate).format("DD-MM-YYYY");
/*-----x---------date config-----x----------*/

export const ComparePage = (props) => {
  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
  const { temp_data } = useSelector((state) => state.home);
  const { tempData } = useSelector((state) => state.quoteFilter);
  const {
    compareQuotesList,
    addOnsAndOthers,
    quoteComprehesive: ComprehensiveQuotes,
    comparePdfData,
    shortTermType,
  } = useSelector((state) => state.quotes);

  //toggling b/w comprehensive and short term
  const quoteComprehesive = shortTermType ? shortTermType : ComprehensiveQuotes;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const { type } = props?.match?.params;
  const typeId = query.get("typeid");
  const [prevPopup2, setPrevPopup2] = useState(false);

  //
  const [selectedId, setSelectedId] = useState(false);

  const [selectedCompanyName, setSelectedCompanyName] = useState(false);

  const [selectedCompanyAlias, setSelectedCompanyAlias] = useState(false);

  const [selectedIcId, setSelectedIcId] = useState(false);

  const [applicableAddonsLits, setApplicableAddonsLits] = useState(false);

  //scrolEvent

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //updating prev insurer

  useEffect(() => {
    if (prevPopup2) {
      var data = {
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        vehicleIdv: tempData.idvChoosed,
        idvChangedType: tempData?.idvType,
        vehicleElectricAccessories: Number(
          addOnsAndOthers?.vehicleElectricAccessories
        ),
        vehicleNonElectricAccessories: Number(
          addOnsAndOthers?.vehicleNonElectricAccessories
        ),
        externalBiFuelKit: Number(addOnsAndOthers?.externalBiFuelKit),
        OwnerDriverPaCover: addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        )
          ? "Y"
          : "N",
        antiTheft: addOnsAndOthers?.selectedDiscount?.includes(
          "Is the car fitted with ARAI approved anti-theft device?"
        )
          ? "Y"
          : "N",
        UnnamedPassengerPaCover: addOnsAndOthers?.selectedAdditions?.includes(
          "Unnamed Passenger PA Cover"
        )
          ? addOnsAndOthers?.unNamedCoverValue === "₹ 2 lac"
            ? 200000
            : 100000
          : null,

        voluntarydeductableAmount:
          addOnsAndOthers?.volDiscountValue !== "None" &&
          addOnsAndOthers?.selectedDiscount?.includes("Voluntary Discounts")
            ? addOnsAndOthers?.volDiscountValue
            : 0,
        isClaim: temp_data?.noClaimMade ? "N" : "Y",
        previousNcb: temp_data?.carOwnership
          ? 0
          : temp_data?.ncb
          ? temp_data?.ncb?.slice(0, -1)
          : 0,
        applicableNcb: temp_data?.carOwnership
          ? 0
          : temp_data?.newNcb
          ? temp_data?.newNcb?.slice(0, -1)
          : 0,

        previousInsurer:
          temp_data?.prevIcFullName?.length !== "NEW"
            ? temp_data?.prevIcFullName === "New"
              ? "NEW"
              : temp_data?.prevIcFullName
            : "NEW",
        previousInsurerCode:
          temp_data?.prevIc !== "New"
            ? temp_data?.prevIc === "New"
              ? "NEW"
              : temp_data?.prevIc
            : "NEW",

        manufactureYear: temp_data?.manfDate,
        policyExpiryDate:
          temp_data?.expiry === "Not Sure" || temp_data?.expiry === "New"
            ? formatedDate
            : temp_data?.expiry,
        vehicleRegisterDate: temp_data?.regDate,
        previousPolicyType: !temp_data?.newCar
          ? tempData?.policyType === "New"
            ? "Not sure"
            : tempData?.policyType
          : "NEW",
        ownershipChanged: temp_data?.carOwnership ? "Y" : "N",
        isIdvChanged:
          tempData.idvChoosed && tempData.idvChoosed !== 0 ? "Y" : "N",
        businessType: temp_data?.newCar
          ? "newbusiness"
          : temp_data?.breakIn
          ? "breakin"
          : "rollover",

        policyType: temp_data?.odOnly ? "own_damage" : "comprehensive",
        vehicleOwnerType: temp_data?.ownerTypeId === 1 ? "I" : "C",
        version: temp_data?.versionId,
        versionName: temp_data?.versionName,
        fuelType: temp_data?.fuel,
      };

      dispatch(UpdateQuotesData(data));
    }
  }, [
    addOnsAndOthers?.selectedAccesories,
    addOnsAndOthers?.vehicleElectricAccessories,
    addOnsAndOthers?.vehicleNonElectricAccessories,
    addOnsAndOthers?.externalBiFuelKit,
    addOnsAndOthers?.selectedAdditions,
    addOnsAndOthers?.unNamedCoverValue,
    addOnsAndOthers?.additionalPaidDriver,
    addOnsAndOthers?.selectedDiscount,
    addOnsAndOthers?.volDiscountValue,
    tempData?.idvChoosed,
    tempData?.idvType,
    temp_data?.ncb,
    temp_data?.expiry,
    temp_data?.prevIc,
    temp_data?.prevIcFullName,
    temp_data?.manfDate,
    temp_data?.regDate,
    temp_data?.expiry,
    tempData?.policyType,
    temp_data?.noClaimMade,
    temp_data?.newCar,
    temp_data?.breakIn,
    temp_data?.carOwnership,
    temp_data?.ownerTypeId,
    temp_data?.fuel,
    temp_data?.versionId,
    temp_data?.versionName,
  ]);

  useEffect(() => {
    if (compareQuotesList?.length !== 0) {
      //comparePageWindowScrollEvent();
    }
  }, [compareQuotesList?.length]);

  const back = () => {
    dispatch(compareQuotes([]));

    history.push(
      `/${type}/quotes?enquiry_id=${enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}`
    );
  };

  // Back to quotes page if there is no quotes to compare
  useEffect(() => {
    if (compareQuotesList.length === 0 && quoteComprehesive?.length < 1) {
      history.push(
        `/${type}/quotes?enquiry_id=${enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}`
      );
    }
  }, [compareQuotesList, quoteComprehesive]);

  const lessThan768 = useMediaPredicate("(max-width: 768px)");

  // PDF Downloader funtion
  function handlePdfDownlaod() {
    if (comparePdfData) {
      let stringifiedData = JSON.stringify(comparePdfData);
      var b = new Buffer(stringifiedData);
      var s = b.toString("base64");
      let downlaodPDF = `${process.env?.REACT_APP_API_BASE_URL}/policyComparePdf?data=${stringifiedData}`;

      downloadFile(downlaodPDF, false, true);
    }
  }

  const { showPop } = useSelector((state) => state.quotes);

  const [validQuote, setValidQuote] = useState(
    compareQuotesList?.filter((x) => x.idv)
  );

  const compareFn = (singleQuote) => {
    if (validQuote?.length < 3) {
      console.log("singleQuote", singleQuote);
      let validCompareQuotes = compareQuotesList?.filter((x) => x.idv);
      dispatch(compareQuotes([...validCompareQuotes, singleQuote]));
    }
    // dispatch(compareQuotes([...validCompareQuotes, singleQuote]))
  };

  const removeFn = (singleQuote) => {
    if (validQuote?.length > 1) {
      let allQuotes = compareQuotesList?.filter(
        (x) => x.policyId !== singleQuote.policyId
      );
      dispatch(compareQuotes(allQuotes));
    }
  };

  const compareFn2 = (singleQuote) => {
    if (validQuote?.length < 2) {
      console.log("singleQuote", singleQuote);
      let validCompareQuotes = compareQuotesList?.filter((x) => x.idv);
      dispatch(compareQuotes([...validCompareQuotes, singleQuote]));
    }
    // dispatch(compareQuotes([...validCompareQuotes, singleQuote]))
  };

  useEffect(() => {
    let quotes = compareQuotesList?.filter((x) => x.idv);
    setValidQuote(quotes);
    if (quotes.length === 3) {
      closePopup();
    }
  }, [compareQuotesList]);

  const closePopup = () => dispatch(setShowPop(false));

  const content = (
    <TopPop>
      <div
        style={{ overflow: lessThan768 ? "auto" : "hidden", overflowX: "clip" }}
      >
        <h4
          className="mt-4 add_plans"
          style={{
            paddingLeft: lessThan768 ? "" : "40px",
            paddingBottom: "20px",
            paddingTop: lessThan768 ? "20px" : "",
            fontSize: lessThan768 ? "20px" : "",
            textAlign: lessThan768 ? "center" : "",
            fontSize: lessThan768 ? "17px" : "",
          }}
        >
          Add upto {lessThan768 ? "2" : "3"} plans to compare
        </h4>
        <div
          className="row mt-4"
          style={{
            borderBottom: "1px dotted black",
            paddingBottom: "20px",
            width: "100%",
            padding: "0px 20px 20px 20px",
          }}
        >
          {(lessThan768
            ? compareQuotesList.slice(0, 2)
            : compareQuotesList
          )?.map((item) =>
            item.idv ? (
              <div className="col-6 col-md-4">
                <CardDiv
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    height: "100%",
                    padding: "10px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 20px",
                    boxShadow: "rgb(0 131 10 / 18%) 0px 5px 20px 0px",
                  }}
                >
                  {validQuote.length > 1 && (
                    <CancelIcon
                      onClick={() => removeFn(item)}
                      // onClick={() => {
                      //   compareFn2(item);
                      // }}
                      style={{
                        position: "absolute",
                        top: lessThan768 ? "-10px" : "-12px",
                        right: lessThan768 ? "-15px" : "-17px",
                        fontSize: lessThan768 ? "18px" : "24px",
                        background: "#fff",
                        borderRadius: "50%",
                        cursor: "pointer",
                        color: "black",
                      }}
                    />
                  )}
                  <img
                    src={item?.companyLogo}
                    alt="myImage"
                    style={{
                      width: lessThan768 ? "90px" : "100%",
                      height: lessThan768 ? "" : "80px",
                      padding: lessThan768 ? "" : "0px 40px",
                      objectFit: "cover"
                    }}
                  />
                  {/* <p
											style={{
												fontSize: "13px",
												margin: "10px",
												textAlign: "center",
											}}
										>
											{item?.companyName}
										</p> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      fontSize: "13.5px",
                      marginTop: "10px",
                      flexDirection: lessThan768 ? "column" : "",
                      alignItems: lessThan768 ? "" : "center",
                      paddingLeft: lessThan768 ? "15px" : "",
                    }}
                  >
                    <p style={{ margin: lessThan768 ? "0px" : "" }}>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Base premium:
                      </div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(item?.finalPayableAmount)}
                      </strong>
                    </p>

                    <p style={{ margin: lessThan768 ? "0px" : "" }}>
                      <div style={{ textAlign: "left" }}>IDV: </div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(item?.idv)}
                      </strong>
                    </p>
                  </div>
                </CardDiv>
              </div>
            ) : (
              <div className={lessThan768 ? "col-6" : "col-4"}>
                <NoPlansDiv
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 20px",
                  }}
                >
                  <i
                    className="fa fa-plus"
                    style={{
                      marginBottom: "5px",
                      boxShadow: "1px 1px 5px grey",
                      fontSize: "25px",
                      background: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                    }}
                  ></i>
                  <p className="text-center">No Plans Added</p>
                </NoPlansDiv>
              </div>
            )
          )}
        </div>
        {/* <p>All available quotes</p> */}
        <div
          className="row"
          //	className="newProductList"
          style={{
            padding: "10px 32px",
            maxHeight: "214px",
            overflow: "scroll",
            overflowX: "clip",
            maxWidth: "915px",
          }}
        >
          {(shortTermType
            ? shortTermType
            : tempData.quoteComprehesiveGrouped
          )?.map((singleQuote, index) => (
            <div className="col-md-4" style={{ width: "100%", height: "100%" }}>
              <div
                className="temp_data_quotes"
                style={{
                  boxShadow: "1px 1px 5px grey",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    _.compact(
                      compareQuotesList.map((x) => x.policyId)
                    )?.includes(singleQuote?.policyId)
                  ) {
                    removeFn(singleQuote);
                  } else {
                    compareFn(singleQuote);
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={singleQuote.companyLogo}
                    width="150"
                    height="75"
                    style={{ margin: "auto", objectFit: "contain" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      fontSize: "13.5px",
                      marginTop: "10px",
                      // flexDirection: lessThan768 ? "column" : "",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Base premium:
                      </div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.finalPayableAmount)}
                      </strong>
                    </p>

                    <p style={{ marginBottom: "0px" }}>
                      <div style={{ textAlign: "left" }}>IDV:</div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.idv)}
                      </strong>
                    </p>
                  </div>
                  <StyledDiv1>
                    <span
                      className="group-check float-right"
                      style={{ width: "5%" }}
                      // onClick={document.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // ? document
                      // 		.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // 		.click()
                      // : {}}
                    >
                      {" "}
                      {_.compact(
                        compareQuotesList.map((x) => x.policyId)
                      )?.includes(singleQuote?.policyId) ? (
                        <i
                          style={{
                            background: "green",
                            color: "#fff",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                          className="fa fa-check"
                        ></i>
                      ) : (
                        <i
                          style={{
                            background: "#fff",
                            color: "#fff",
                            marginTop: "15px",
                            border: "0.5px solid #0000007a",
                            fontSize: "13.5px",
                          }}
                          className="fa fa-check"
                        ></i>
                      )}
                      {/* <input
									// onClick={() => console.log(singleQuote?.policyId)}
									type="checkbox"
									className="round-check"
									id={`compareCheck${singleQuote?.policyId}`}
									name={`compareCheck[${index}]`}
									ref={register}
									// value={singleQuote?.policyId}
									defaultValue={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
									value={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
	
									// disabled={
									// length >= (lessThan768 ? 2 : 3) &&
									// !watch(`checkmark[${index}]`)
									// 	? true
									// 	: false
									// 		}
										/> */}
                      {/* <label
											className="round-label"
											htmlFor={`compareCheck${singleQuote?.policyId}`}
										></label> */}
                    </span>
                  </StyledDiv1>
                  {/* <i className="fa fa-plus" onClick={() => addItems(singleQuote)}>Add</i> */}
                </div>
                {/* <p style={{ fontSize: '13px' }}> {singleQuote.companyName} </p> */}
              </div>
            </div>
          ))}
        </div>
        {/* {ids.length === 3 && closePop()} */}
        <div style={{ margin: "20px 0px", textAlign: "center" }}>
          <CompareButton
            // onClick={closePop}
            onClick={closePopup}
            style={{
              border: "none",
              padding: "15px 50px",
              letterSpacing: "0.5px",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Compare
          </CompareButton>
        </div>
      </div>
    </TopPop>
  );

  const innerHeight = window.innerHeight;

  const contentModal = (
    <>
      <TopPop2 innerHeight={innerHeight}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "sticky",
              top: "0",
              background: "#fff",
              zIndex: "999",
              paddingBottom: "20px",
              boxShadow: "rgb(0 0 0 / 24%) 0px 8px 6px -6px",
            }}
          >
            <h4
              className="add_plans"
              style={{
                // paddingLeft: lessThan768 ? "" : "40px",
                paddingBottom: "20px",
                // paddingTop: lessThan768 ? "20px" : "",
                fontSize: "15px",
                textAlign: "left",
                marginLeft: "15px",
                paddingTop: "15px",
              }}
            >
              Add upto {lessThan768 ? "2" : "3"} plans to compare
            </h4>
            <div
              className="row mt-4"
              style={{
                // borderBottom: "1px dotted black",
                // paddingBottom: "20px",
                width: "100%",
                // padding: "0px 20px 20px 20px",
                paddingLeft: "15px",
              }}
            >
              {(lessThan768
                ? compareQuotesList.slice(0, 2)
                : compareQuotesList
              )?.map((item) =>
                item.idv ? (
                  <div className="col-6">
                    <CardDiv
                      style={{
                        position: "relative",
                        borderRadius: "5px",
                        width: "100%",
                        // height: "100%",
                        padding: "10px 4px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // margin: "0px 20px",
                        // boxShadow: "rgb(0 131 10 / 18%) 0px 5px 20px 0px",
                      }}
                    >
                      {validQuote?.length > 1 && (
                        <CancelIcon
                          onClick={() => removeFn(item)}
                          // onClick={() => {
                          //   compareFn2(item);
                          // }}
                          style={{
                            position: "absolute",
                            top: lessThan768 ? "-10px" : "-12px",
                            right: lessThan768 ? "-15px" : "-17px",
                            background: "#fff",
                            borderRadius: "50%",
                            cursor: "pointer",
                            color: "black",
                          }}
                        />
                      )}
                      <div style={{ height: "90px" }}>
                        <img
                          src={item?.companyLogo}
                          alt="myImage"
                          style={{
                            width: "100%",
                            padding: "0 5px",
                            objectFit: "cover"
                          }}
                        />
                      </div>
                      {/* <p
											style={{
												fontSize: "13px",
												margin: "10px",
												textAlign: "center",
											}}
										>
											{item?.companyName}
										</p> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                          fontSize: "11px",
                          marginTop: "10px",
                          flexDirection: lessThan768 ? "column" : "",
                          alignItems: lessThan768 ? "" : "center",
                          padding: "0 5px",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Base premium:
                          </div>
                          <br />
                          <strong
                            style={
                              {
                                // fontSize: "14px",
                                // position: "relative",
                                // bottom: "15px",
                              }
                            }
                          >
                            <span
                              style={
                                {
                                  // fontSize: "14px",
                                }
                              }
                            >
                              ₹
                            </span>{" "}
                            {currencyFormater(item?.finalPayableAmount)}
                          </strong>
                        </p>

                        <p
                          style={{
                            margin: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ textAlign: "left" }}>IDV: </div>
                          <br />
                          <strong
                            style={
                              {
                                // fontSize: "14px",
                                // position: "relative",
                                // bottom: "15px",
                              }
                            }
                          >
                            <span
                              style={
                                {
                                  // fontSize: "14px",
                                }
                              }
                            >
                              ₹
                            </span>{" "}
                            {currencyFormater(item?.idv)}
                          </strong>
                        </p>
                      </div>
                    </CardDiv>
                  </div>
                ) : (
                  <div className={lessThan768 ? "col-6" : "col-4"}>
                    <NoPlansDiv
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0px 20px",
                      }}
                    >
                      <i
                        className="fa fa-plus"
                        style={{
                          marginBottom: "5px",
                          // boxShadow: "1px 1px 5px grey",
                          fontSize: "16px",
                          background: "#80808078",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                        }}
                      ></i>
                      <p className="text-center">No Plans Added</p>
                    </NoPlansDiv>
                  </div>
                )
              )}
            </div>
          </div>
          {/* <div style={{ width: '100%', marginTop: '10px', height: '20px', borderRadius: '10%', boxShadow: '0 8px 6px -6px #0000003d' }}></div> */}
          {/* <p>All available quotes</p> */}
          <div
            className="row"
            //	className="newProductList"
            style={{
              // padding: "10px 32px",
              // maxHeight: "214px",
              // overflow: "scroll",
              // overflowX: "clip",
              // maxWidth: "915px",
              padding: "10px 30px",
              marginTop: "20px",
              position: "relative",
              height: "auto",
              overflow: "auto",
            }}
          >
            {tempData.quoteComprehesiveGrouped?.map((singleQuote, index) => (
              <div
                className="col-6"
                style={{ margin: "3px 0px", padding: "5px 15px" }}
              >
                <div
                  onClick={() => {
                    if (
                      _.compact(
                        compareQuotesList.map((x) => x.policyId)
                      )?.includes(singleQuote?.policyId)
                    ) {
                      removeFn(singleQuote);
                    } else {
                      compareFn2(singleQuote);
                    }
                  }}
                  style={{
                    position: "relative",
                    borderRadius: "5px",
                    width: "100%",
                    // height: "100%",
                    padding: "10px 4px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // margin: "0px 20px",
                    // boxShadow: "rgb(0 131 10 / 18%) 0px 5px 20px 0px",
                    boxShadow: "1px 1px 5px grey",
                    background: "#fff !important",
                    border: "none",
                  }}
                >
                  {/* {validQuote?.length > 1 && (
								<CancelIcon
									onClick={() => {
										compareFn2(singleQuote);
									}}
									style={{
										position: "absolute",
										top: lessThan768 ? "-10px" : "-12px",
										right: lessThan768 ? "-15px" : "-17px",
										background: "#fff",
										borderRadius: "50%",
										cursor: "pointer",
										color: "black",
									}}
								/>
							)} */}
                  <div style={{ height: "90px" }}>
                    <img
                      src={singleQuote?.companyLogo}
                      alt="myImage"
                      style={{
                        width: "100%",
                        padding: "0 5px",
                        objectFit: "cover"
                      }}
                    />
                  </div>
                  {/* <p
								style={{
									fontSize: "13px",
									margin: "10px",
									textAlign: "center",
								}}
							>
								{item?.companyName}
							</p> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      fontSize: "10.5px",
                      marginTop: "10px",
                      flexDirection: lessThan768 ? "column" : "",
                      alignItems: lessThan768 ? "" : "center",
                      padding: "0 5px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Base premium:
                      </div>
                      <br />
                      <strong
                        style={
                          {
                            // fontSize: "14px",
                            // position: "relative",
                            // bottom: "15px",
                          }
                        }
                      >
                        <span
                          style={
                            {
                              // fontSize: "14px",
                            }
                          }
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.finalPayableAmount)}
                      </strong>
                    </p>

                    <p
                      style={{
                        margin: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>IDV: </div>
                      <br />
                      <strong
                        style={
                          {
                            // fontSize: "14px",
                            // position: "relative",
                            // bottom: "15px",
                          }
                        }
                      >
                        <span
                          style={
                            {
                              // fontSize: "14px",
                            }
                          }
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.idv)}
                      </strong>
                    </p>
                  </div>
                  <StyledDiv2>
                    <span
                      className="group-check float-right"
                      style={{ width: "5%" }}
                      // onClick={document.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // ? document
                      // 		.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // 		.click()
                      // : {}}
                    >
                      {" "}
                      {_.compact(
                        compareQuotesList.map((x) => x.policyId)
                      )?.includes(singleQuote?.policyId) ? (
                        <i
                          style={{
                            color: "#fff",
                            // marginTop: "15px",
                            fontSize: "14px",
                            borderRadius: "50%",
                            padding: "3px",
                          }}
                          className="fa fa-check productCheck"
                        ></i>
                      ) : (
                        // <i
                        // 	style={{
                        // 		background: "#fff",
                        // 		color: "#fff",
                        // 		marginTop: "15px",
                        // 		border: "0.5px solid #0000007a",
                        // 		fontSize: "13.5px",
                        // 	}}
                        // 	className="fa fa-check"
                        // ></i>
                        <noscript></noscript>
                      )}
                      {/* <input
									// onClick={() => console.log(singleQuote?.policyId)}
									type="checkbox"
									className="round-check"
									id={`compareCheck${singleQuote?.policyId}`}
									name={`compareCheck[${index}]`}
									ref={register}
									// value={singleQuote?.policyId}
									defaultValue={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
									value={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
	
									// disabled={
									// length >= (lessThan768 ? 2 : 3) &&
									// !watch(`checkmark[${index}]`)
									// 	? true
									// 	: false
									// 		}
										/> */}
                      {/* <label
											className="round-label"
											htmlFor={`compareCheck${singleQuote?.policyId}`}
										></label> */}
                    </span>
                  </StyledDiv2>
                </div>
              </div>
              // <div className="col-6">
              // 	<div
              // 		className="temp_data_quotes"
              // 		style={{
              // 			boxShadow: "1px 1px 5px grey",
              // 			padding: "10px",
              // 			margin: "10px",
              // 			borderRadius: "6px",
              // 			cursor: "pointer",
              // 		}}
              // 		onClick={() => compareFn(singleQuote)}
              // 	>
              // 		<div
              // 			style={{
              // 				display: "flex",
              // 				justifyContent: "space-between",
              // 				padding: "5px",
              // 				flexDirection: "column",
              // 			}}
              // 		>
              // 			<img
              // 				src={singleQuote.companyLogo}
              // 				width="150"
              // 				height="75"
              // 				style={{ margin: "auto" }}
              // 			/>
              // 			<div
              // 				style={{
              // 					display: "flex",
              // 					justifyContent: "space-around",
              // 					width: "100%",
              // 					fontSize: "13.5px",
              // 					marginTop: "10px",
              // 					// flexDirection: lessThan768 ? "column" : "",
              // 					alignItems: "center",
              // 				}}
              // 			>
              // 				<p style={{ marginBottom: "0px" }}>
              // 					<div
              // 						style={{
              // 							textAlign: "left",
              // 						}}
              // 					>
              // 						Base premium:
              // 					</div>
              // 					<br />
              // 					<strong
              // 						style={{
              // 							fontSize: "14px",
              // 							position: "relative",
              // 							bottom: "15px",
              // 						}}
              // 					>
              // 						<span
              // 							style={{
              // 								fontSize: "14px",
              // 							}}
              // 						>
              // 							₹
              // 						</span>{" "}
              // 						{currencyFormater(singleQuote?.finalPayableAmount)}
              // 					</strong>
              // 				</p>

              // 				<p style={{ marginBottom: "0px" }}>
              // 					<div style={{ textAlign: "left" }}>IDV:</div>
              // 					<br />
              // 					<strong
              // 						style={{
              // 							fontSize: "14px",
              // 							position: "relative",
              // 							bottom: "15px",
              // 						}}
              // 					>
              // 						<span
              // 							style={{
              // 								fontSize: "14px",
              // 							}}
              // 						>
              // 							₹
              // 						</span>{" "}
              // 						{currencyFormater(singleQuote?.idv)}
              // 					</strong>
              // 				</p>
              // 			</div>
              // 			<StyledDiv1>
              // 				<span
              // 					className="group-check float-right"
              // 					style={{ width: "5%" }}
              // 					// onClick={document.getElementById(`compareCheck${singleQuote?.policyId}`)
              // 					// ? document
              // 					// 		.getElementById(`compareCheck${singleQuote?.policyId}`)
              // 					// 		.click()
              // 					// : {}}
              // 				>
              // 					{" "}
              // 					{_.compact(
              // 						compareQuotesList.map((x) => x.policyId)
              // 					)?.includes(singleQuote?.policyId) ? (
              // 						<i
              // 							style={{
              // 								background: "green",
              // 								color: "#fff",
              // 								marginTop: "15px",
              // 								fontSize: "16px",
              // 							}}
              // 							className="fa fa-check"
              // 						></i>
              // 					) : (
              // 						<i
              // 							style={{
              // 								background: "#fff",
              // 								color: "#fff",
              // 								marginTop: "15px",
              // 								border: "0.5px solid #0000007a",
              // 								fontSize: "13.5px",
              // 							}}
              // 							className="fa fa-check"
              // 						></i>
              // 					)}
              // 					{/* <input
              // 		    // onClick={() => console.log(singleQuote?.policyId)}
              // 			type="checkbox"
              // 			className="round-check"
              // 			id={`compareCheck${singleQuote?.policyId}`}
              // 			name={`compareCheck[${index}]`}
              // 			ref={register}
              // 			// value={singleQuote?.policyId}
              // 			defaultValue={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
              // 			value={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}

              // 			// disabled={
              // 			// length >= (lessThan768 ? 2 : 3) &&
              // 			// !watch(`checkmark[${index}]`)
              // 			// 	? true
              // 			// 	: false
              // 			// 		}
              // 				/> */}
              // 					{/* <label
              // 					className="round-label"
              // 					htmlFor={`compareCheck${singleQuote?.policyId}`}
              // 				></label> */}
              // 				</span>
              // 			</StyledDiv1>
              // 			{/* <i className="fa fa-plus" onClick={() => addItems(singleQuote)}>Add</i> */}
              // 		</div>
              // 		{/* <p style={{ fontSize: '13px' }}> {singleQuote.companyName} </p> */}
              // 	</div>
              // </div>
            ))}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            position:
              tempData?.quoteComprehesiveGrouped?.length > 2
                ? "sticky"
                : "absolute",
            bottom: "0",
            right: "0",
            width: "100%",
            zIndex: "999",
          }}
        >
          <CompareButton
            onClick={closePopup}
            style={{
              width: "100%",
              fontWeight: "bold",
              border: "none",
              padding: "15px 50px",
              letterSpacing: "0.5px",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Compare
          </CompareButton>
        </div>
      </TopPop2>
    </>
  );

  return (
    <>
      {showPop &&
        (!lessThan768 ? (
          <Popup
            content={content}
            show={showPop}
            closePop={closePopup}
            reduxClose
            width="900px"
            mobileHeight="90% !important"
            height={lessThan768 ? "90% !important" : "auto"}
            outside={true}
          />
        ) : (
          <Popup
            content={contentModal}
            show={showPop}
            onClose={closePopup}
            reduxClose
            width="100%"
            mobileHeight="100% !important"
            height={lessThan768 ? "100% !important" : "auto"}
          />
        ))}
      <TopDiv>
        <div className="compareConatiner" id={"topdf"}>
          {/* <Row style={{ margin: "0px" }}>
				<Col className="landing-laxmi mx-auto" xl={3} lg={3} md={3}>
					<div className="review-details3 text-center">
						<Avatar src="/assets/images/auto-car.jpg" alt="avatarImage" />
					</div>
				</Col>
			</Row> */}
          <div className="backBtn" style={{ paddingBottom: "30px" }}>
            <BackButton
              type="button"
              onClick={back}
              style={{
                marginTop: "-20px",
                left: "35px",
                top: Theme?.BackButton?.backButtonTop
                  ? Theme?.BackButton?.backButtonTop
                  : "110px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                viewBox="0 0 24 24"
              >
                <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
              <text style={{ color: "black" }}>Back</text>
            </BackButton>
          </div>
          <div>
            {/* <CompareHeader className="compare-header">
					Compare to get better understanding of plans
				</CompareHeader> */}

            <div className="compare-page compare-page-container">
              <div className="compare-products-wrap">
                <Features
                  compareQuotes={compareQuotesList}
                  ButtonPanel={() => {}}
                  type={type}
                  quote={compareQuotesList[0]}
                  scrollPosition={scrollPosition}
                />
                <CompareProductsList
                  quoteComprehesive={quoteComprehesive}
                  compareQuotes={compareQuotesList}
                  type={type}
                  setPrevPopup={setPrevPopup2}
                  prevPopup={prevPopup2}
                  setSelectedId={setSelectedId}
                  setSelectedCompanyName={setSelectedCompanyName}
                  setSelectedIcId={setSelectedIcId}
                  setSelectedCompanyAlias={setSelectedCompanyAlias}
                  setApplicableAddonsLits={setApplicableAddonsLits}
                  scrollPosition={scrollPosition}
                />
              </div>
            </div>
          </div>
          {prevPopup2 && (
            <PrevInsurerPopup2
              show={prevPopup2}
              onClose={setPrevPopup2}
              selectedId={selectedId}
              type={type}
              selectedCompanyName={selectedCompanyName}
              selectedCompanyAlias={selectedCompanyAlias}
              selectedIcId={selectedIcId}
              applicableAddonsLits={applicableAddonsLits}
            />
          )}
        </div>
        <FloatButton />
        {lessThan768 && (
          <PdfDiv
            onClick={handlePdfDownlaod}
            style={{
              position: "fixed",
              right: "1%",
              top: "67%",
              zIndex: "1",
            }}
          >
            <i class="fa fa-download pdf_icon"></i>
          </PdfDiv>
        )}
      </TopDiv>
    </>
  );
};

export function comparePageWindowScrollEvent() {
  window.scrollTo(0, 0);
  const topInfos = document.querySelectorAll(".top-info1");
  const topInfoPosition = document
    .querySelector(".top-info1")
    .getBoundingClientRect().y;
  const handleBodyScroll = () => {
    if (topInfoPosition < window.scrollY)
      topInfos.forEach((topInfo) => topInfo.classList.add("planStickyHeader"));
    else
      topInfos.forEach((topInfo) =>
        topInfo.classList.remove("planStickyHeader")
      );
  };
  window.addEventListener("scroll", handleBodyScroll);
  return () => {
    window.removeEventListener("scroll", handleBodyScroll);
  };
}

export const GlobalStyle = createGlobalStyle`
body {
  
}
`;

const CompareHeader = styled.div`
  // color: #006600;
  color: rgb(74, 74, 74);
  font-size: 36px;
  @media (max-width: 767px) {
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  // color: ${({ theme }) => theme.Products?.headColor || "#006600"};
`;

const QuoteCardHeding = styled.div`
  width: 212px;
  border-bottom: ${({ theme }) =>
    theme.comparePage2?.borderBottom || "1px solid green"};
`;

const Icons = styled.i`
  color: ${({ theme }) => theme.comparePage2?.iconsColor || "green"};
  margin-right: 5px;
`;

const CarDetailsHeading = styled.span`
  background: ${({ theme }) => theme.comparePage2?.background || "green"};
  color: #fff;
  padding: 5px 40px 5px 5px;
  font-size: 14px;
  border-radius: 0px 50px 0px 0px;
`;

const SecondColumn = styled.div`
  border-top: ${({ theme }) =>
    theme.comparePage2?.borderBottom || "1px solid green"};
`;

const TopDiv = styled.div`
  .compareConatiner {
    width: 100%;
    // background-repeat: no-repeat;
    // background-position: top right;
    // background-size: 100% 100%;
    // background-image: linear-gradient(to bottom, transparent, #fff),
    // 	url("/assets/images/background-green3-min.jpg");
  }
  .compare-header {
    display: flex;
    justify-content: center;
    position: inherit;
    align-items: center;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: Merriweather, Georgia, serif;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .compare-page {
    margin: auto;
    position: relative;
    &-container {
      max-width: 1110px;
      padding: 0px 0px 80px 0px;
      @media only screen and (max-width: 1177px) and (min-width: 768px) {
        & {
          max-width: 710px;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 0 15px;
        width: 100%;
        // margin-bottom: 124px;
      }
    }
    &-features .cd-features-list li,
    .compare-products-wrap &-features .top-info {
      font-family: ${({ theme }) =>
        theme.regularFont?.fontFamily || "Inter-Regular"};
      font-size: 14px;
      line-height: 19px;
      color: #333;
      line-height: 14px;
      padding: 0;
      text-align: left;
    }
    & .laxmigreeting__wrapper {
      margin-top: -33px;
    }
    &__top {
      margin-top: 60px;
      display: flex;
      background-color: #ffffff;
      z-index: 100;
    }
    &__bottom {
      display: flex;
      background-color: #ffffff;
      margin-bottom: 30px;
    }
    &__laxmi-text {
      font-family: ${({ theme }) =>
        theme.regularFont?.fontFamily || "Inter-Regular"};
      font-size: 24px;
      line-height: 24px;
      color: #333;
      text-align: center;
      margin-top: -19px;
    }
    &__email-quotes-btn {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      font-family: ${({ theme }) =>
        theme.regularFont?.fontFamily || "Inter-Regular"};
      font-size: 14px;
      line-height: 14px;
      color: #333 !important;
      text-decoration: underline;
      margin-top: 18px;
      border: none;
      background: none;
      z-index: 99;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
    &__back-btn {
      text-transform: uppercase;
      border: none;
      background: none;
      z-index: 99;
      width: max-content;
      display: flex;
      align-items: center;
      color: #808080;
      font-size: 14px;
      font-family: ${({ theme }) =>
        theme.regularFont?.fontFamily || "Inter-Regular"};
      position: absolute;
      margin-top: 18px;
      left: 46px;
      cursor: pointer;
      & svg {
        width: 52px;
        height: 20px;
      }
      & .compare-page__back-text {
        margin-left: 1px;
        margin-bottom: 2px;
      }
    }
    .compare-products-wrap {
      position: relative;
      & .top-info {
        position: relative;
        height: 220px;
        text-align: center;
        padding: 0;
        -webkit-transition: height 0.3s;
        -moz-transition: height 0.3s;
        transition: height 0.3s;
        background-color: #ffffff;
        z-index: 1500;
      }
      & .planOptionHead {
        font-family: ${({ theme }) =>
          theme.mediumFont?.fontFamily || "Inter-SemiBold"};
        font-size: 16px;
        line-height: 19px;
        // color: #333;
        border-bottom: 1px solid #e3e4e8;
        margin-right: 10px;
      }
      & .planOptionNameSub {
        font-size: 12px;
        line-height: 16px;
        color: #808080;
        overflow: revert !important;
        margin-top: -16px;
        margin-bottom: 0px !important;
        height: 52px !important;
      }
      & .productDividerPadding {
        margin-bottom: 16px !important;
      }
      & .planOptionNameSub {
        font-size: 12px;
        line-height: 16px !important;
        color: #808080;
        overflow: revert !important;
        margin-top: -16px;
        margin-bottom: 0px !important;
        height: 52px !important;
      }
      & .planStickyHeader {
        position: fixed !important;
        top: 0;
        // width: 183px;
        // border: solid 1px #e3e4e8;
        // border-left-width: 0;
        border-top-width: 0;
        // background-color: #fff;
      }
      & .compare-page-features .planStickyHeader {
        position: fixed !important;
        top: 0;
        width: 275px;
        border: none;
        // background-color: #fff;
      }
      & .planStickyHeader ~ .cd-features-list {
        margin-top: 320px;
      }
      @media screen and (min-width: 768px) and (max-width: 1177px) {
        & .productDividerPadding {
          margin-bottom: 0 !important;
        }
      }
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        & .top-info {
          height: 160px !important;
        }
        & .compare-page-features .cd-features-list li,
        .compare-products-wrap .compare-page-features .top-info {
          font-size: 10px;
          line-height: 14px !important;
          color: #7a7d80;
          border: none;
        }
        & .planStickyHeader {
          position: relative !important;
          top: auto;
          border: none !important;
          border-left-width: 0;
          border-top-width: 0;
          // background-color: #fff;
        }
        & .compare-page-features .planStickyHeader {
          position: relative !important;
          top: auto;
          border: none !important;
          width: auto;
          border-left-width: 0;
          border-top-width: 0;
          // background-color: #fff;
        }
        & .planStickyHeader ~ .cd-features-list {
          margin-top: 0;
        }
        & .planOptionHead {
          font-size: 12px;
          width: 100%;
          margin-top: -1px;
        }
        & .planOptionNameSub {
          display: block;
          height: 0 !important;
          overflow: hidden !important;
          margin-bottom: 53px !important;
        }
        & .productDividerPadding {
          margin-bottom: 7px !important;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1177px) {
      &__back-btn {
        left: 32px;
      }
      &__laxmi-text {
        font-size: 20px;
      }
      & .laxmigreeting__wrapper {
        margin-top: -19px !important;
        margin-bottom: 2px !important;
        & .laxmiGreeting__img {
          height: 80px;
          width: 80px;
        }
      }
      &__email-quotes-btn {
        display: inline-block;
      }
    }
    @media screen and (max-width: 767px) {
      &__back-btn {
        font-size: 13px;
        margin-top: 7px;
        left: 18px;
        text-transform: capitalize;
        & .backSvg {
          margin-bottom: 2px !important;
          margin-right: -3px !important;
        }
        & .compare-page__back-text {
          font-size: 13px;
        }
      }
      &__laxmi-text {
        font-size: 13px;
      }
      & .laxmigreeting__wrapper {
        margin-top: -18px !important;
        margin-bottom: 3px !important;
        & .laxmiGreeting__img {
          height: 80px;
          width: 80px;
        }
      }
      &__email-quotes-btn {
        display: inline-block;
        font-size: 13px;
        line-height: 14px;
        color: #333;
        text-decoration: none;
        right: 15px;
        margin-top: 11px;
      }
      &-features .cd-features-list li,
      .compare-products-wrap &-features .top-info {
        font-size: 10px;
        line-height: 14px;
        color: #7a7d80;
        border: none;
      }
      &-features .top-info .planOptionHead {
      }
    }
  }
  .two-line-height {
    height: 40px;
  }
  .styledDiv {
    display: flex;
    justify-content: space-between;
    width: 90px;
  }
  @media (max-width: 767px) {
    .styledDiv {
      display: flex;
      justify-content: space-between;
      width: 70px;
    }
  }
  .compare-page {
  }
  .compare-header {
    font-size: 2rem;
    /* margin-left: 8.5em; */
  }
  @media (max-width: 768px) {
    .compare-page {
    }
    .compare-header {
      font-size: 0.95rem;
      text-align: center;
      margin-top: 60px;
    }
    .backBtn button {
      top: 56px !important;
      left: 0px !important;
    }
  }
`;

const PdfDiv = styled.div`
  // margin-right: 5px;
  border: ${({ theme }) =>
    theme.floatButton?.floatBorder || "1px solid #bdd400"};
  // padding: 5px 10px;
  // border-radius: 50%;

  .pdf_icon {
    color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
    padding: 8px 10px;
    font-size: 1.5rem;
  }
`;

const TopPop = styled.div`
  .add_plans {
    font-family: ${({ theme }) =>
      theme.mediumFont?.fontFamily || "Inter-SemiBold"};
  }
  h4 {
    color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  }
`;

const TopPop2 = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  height: ${({ innerHeight }) => (innerHeight ? innerHeight + "px" : "100vh")};
  .add_plans {
    font-family: ${({ theme }) =>
      theme.mediumFont?.fontFamily || "Inter-SemiBold"};
  }
  .productCheck {
    background: ${({ theme }) => theme.comparePage?.color || "#bdd400"};
  }
  h4 {
    color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  }
`;

const CardDiv = styled.div`
  background: ${({ theme }) =>
    theme.CardPop?.background || "rgb(18 211 77 / 6%)"};
  border: ${({ theme }) => theme.CardPop?.border || "1px solid green"};
`;

const NoPlansDiv = styled.div`
  background: ${({ theme }) => theme.NoPlanCard?.background || "#f7f7fa"};
  // border: ${({ theme }) => theme.NoPlanCard?.border || "2px dotted green"};
`;

const CompareButton = styled.button`
  background: ${({ theme }) => theme.comparePage?.color || "#bdd400"};
`;

const StyledDiv1 = styled.div`
  position: absolute;
  right: 47px;
  top: 1px;
  z-index: 101;
  .round-label::after {
    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid  #d4d5d9"};
  }

  .group-check input[type="checkbox"]:checked + label::before {
    transform: scale(1);
    background-color: ${({ theme }) => theme?.QuoteCard?.color || "#bdd400"};

    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid #bdd400"};
  }
`;

const StyledDiv2 = styled.div`
  position: absolute;
  right: 10px;
  top: -12px;
  z-index: 101;
  .round-label::after {
    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid  #d4d5d9"};
  }

  .group-check input[type="checkbox"]:checked + label::before {
    transform: scale(1);
    background-color: ${({ theme }) => theme?.QuoteCard?.color || "#bdd400"};

    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid #bdd400"};
  }
`;
