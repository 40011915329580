import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const SummaryOwner = ({ summary, lessthan768, fields }) => {
  const { temp_data } = useSelector((state) => state.proposal);
  const InfoFn = (header, value, fullSpanInDesktop) => {
    return (
      <Col
        sm={6}
        xs={6}
        md={fullSpanInDesktop ? 12 : 6}
        lg={fullSpanInDesktop ? 12 : 4}
        xl={fullSpanInDesktop ? 12 : 4}
        className="py-2 px-2 text-nowrap"
      >
        <DivHeader lessthan768={lessthan768}>{header}</DivHeader>
        <DivValue lessthan768={lessthan768}>
          {(value || Number(value) === 0 ? value : "-").toString()}
        </DivValue>
      </Col>
    );
  };

  return (
    <div className="d-flex flex-wrap" style={{ marginTop: "-50px" }}>
      <Row
        xs={1}
        sm={1}
        md={2}
        lg={3}
        xl={3}
        style={{ width: "100%" }}
        className="d-flex p-1"
      >
        {!_.isEmpty(summary) ? (
          <>
            {Number(temp_data?.ownerTypeId) === 1 ? (
              <>
                {summary?.fullName && InfoFn("FULL NAME", summary?.fullName)}
                {/* {summary?.firstName && InfoFn("FIRST NAME", summary?.firstName)}
                {summary?.lastName && InfoFn("LAST NAME", summary?.lastName)} */}
              </>
            ) : (
              <>
                {summary?.firstName &&
                  InfoFn("COMPANY NAME", summary?.firstName)}
                {summary?.lastName &&
                  InfoFn("REPRESENTATIVE NAME", summary?.lastName)}
              </>
            )}
            {fields.includes("gender") &&
              (summary?.genderName || summary?.gender) &&
              InfoFn("GENDER", summary?.genderName || summary?.gender)}
            {fields.includes("dob") &&
              summary?.dob &&
              InfoFn("DATE OF BIRTH", summary?.dob)}
            {summary?.mobileNumber &&
              InfoFn("MOBILE NUMBER", summary?.mobileNumber)}
            {(process.env.REACT_APP_BROKER !== "OLA" ||
              fields.includes("email")) &&
              summary?.email &&
              InfoFn("EMAIL ID", summary?.email)}
            {fields.includes("panNumber") &&
              summary?.panNumber &&
              InfoFn("PAN NO.", summary?.panNumber)}
            {fields.includes("gstNumber") &&
              summary?.gstNumber &&
              InfoFn("GST NUMBER", summary?.gstNumber)}
            {fields.includes("occupation") &&
              (summary?.occupationName || summary?.occupation) &&
              InfoFn(
                "OCCUPATION TYPE",
                summary?.occupationName || summary?.occupation
              )}
            {fields.includes("maritalStatus") &&
              summary?.maritalStatus &&
              InfoFn("MARITAL STATUS", summary?.maritalStatus)}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="mt-1 px-2"
              style={{ marginBottom: "-10px" }}
            >
              <p
                style={{
                  color: Theme?.proposalHeader?.color
                    ? Theme?.proposalHeader?.color
                    : "#1a5105",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Communication Address
              </p>
            </Col>
            {summary?.address && InfoFn("ADDRESS", summary?.address)}
            {/* {summary?.addressLine1 &&
              InfoFn("ADDRESS LINE 1", summary?.addressLine1)}
            {summary?.addressLine2 &&
              InfoFn("ADDRESS LINE 2", summary?.addressLine2)}
            {summary?.addressLine3 &&
              InfoFn("ADDRESS LINE 3", summary?.addressLine3)} */}
            {summary?.pincode && InfoFn("PINCODE", summary?.pincode)}
            {summary?.state && InfoFn("STATE", summary?.state)}
            {summary?.city && InfoFn("CITY", summary?.city)}
          </>
        ) : (
          <p style={{ color: "red" }}>Form data not found</p>
        )}
      </Row>
    </div>
  );
};

const DivHeader = styled.div`
  font-size: ${({ lessthan768 }) => (lessthan768 ? "11px" : "12px")};
  font-weight: 600;
`;

const DivValue = styled.div`
  font-size: ${({ lessthan768 }) => (lessthan768 ? "11px" : "12px")};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export default SummaryOwner;
