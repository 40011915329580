import React from "react";
import "./compare-container.scss";
import { ComparePlan } from "./compare-plans";
import _ from "lodash";
import styled from "styled-components";
import { compareQuotes } from "../quote.slice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import CancelIcon from "@material-ui/icons/Cancel";
import { useMediaPredicate } from "react-media-hook";
export const CompareContainer = ({ CompareData, type }) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { temp_data } = useSelector((state) => state.home);
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const typeId = query.get("typeid");
  const journey_type = query.get("journey_type");
  const getCompareBox = (quote) => (
    <ComparePlan
      key={quote?.policyId}
      id={quote?.policyId}
      logoUrl={quote?.companyLogo}
      plan={quote?.companyName}
    />
  );

  const handleClick = () => {
    dispatch(compareQuotes(CompareData));
    //redirect to compare page
    // history.push('')

    history.push(
      `/${type}/compare-quote?enquiry_id=${enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}${
        journey_type ? `&journey_type=${journey_type}` : ``
      }`
    );
  };
  const handleClose = () => {
    if (!_.isEmpty(CompareData)) {
      CompareData.forEach((quote) => {
        if (
          document.getElementById(`reviewAgree${quote?.policyId}`) &&
          quote?.policyId
        ) {
          document.getElementById(`reviewAgree${quote?.policyId}`).click();
        }
      });
    }
  };

  return (
    <div className="quote-page__quote-cards--on-compare">
      <div
        className="compare-container-wrap"
        // style={{ backdropFilter: "blur(20px) !important" }}
      >
        <div className="compare-container">
          <div className="compare-boxes w-100">
            {!_.isEmpty(CompareData)
              ? CompareData?.map((item) => getCompareBox(item))
              : getCompareBox({})}
            {CompareData?.length === 1 && !lessthan767 && (
              <>
                <CompareBox>
                  <AddPlanIcon
                    className="fa fa-plus"
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                    }}
                  ></AddPlanIcon>
                  Add Plan
                </CompareBox>
                <CompareBox>
                  <AddPlanIcon
                    className="fa fa-plus"
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                    }}
                  ></AddPlanIcon>
                  Add Plan
                </CompareBox>
              </>
            )}
            {CompareData?.length === 2 && !lessthan767 && (
              <CompareBox>
                <AddPlanIcon
                  className="fa fa-plus"
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                ></AddPlanIcon>
                Add Plan
              </CompareBox>
            )}
          </div>
          <CompareContainerButton
            style={{
              cursor:
                CompareData?.length < 2 || temp_data?.tab === "tab2"
                  ? "not-allowed"
                  : "pointer",
            }}
            className={`${
              CompareData?.length < 2 || temp_data?.tab === "tab2"
                ? "btn--disabled"
                : "btn--highlighted"
            }`}
            onClick={handleClick}
            disabled={CompareData?.length < 2 || temp_data?.tab === "tab2"}
          >
            {" "}
            COMPARE{" "}
          </CompareContainerButton>
          <CloseButton onClick={handleClose}>
            {lessthan767 ? (
              <CancelIcon fontSize={"1.25rem"} />
            ) : (
              <b style={{ cursor: "pointer" }}>Close</b>
            )}
          </CloseButton>
        </div>
      </div>
    </div>
  );
};
const CompareContainerButton = styled.button`
  background-color: ${({ theme }) => theme.QuotePopups?.color2 || "#060"};
  border: ${({ theme }) => theme.QuotePopups?.border2 || "solid 1px #060"};
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 16px;
  color: #ffff;

  &:hover {
    background: #fff;
    border: ${({ theme }) =>
      theme.floatButton?.floatBorder || "1px solid #060"};
    color: ${({ theme }) => theme.floatButton?.floatColor || "#060"};
  }
`;

const CloseButton = styled.div`
  margin-left: 25px;
  @media screen and (max-width: 766px) {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    margin-left: unset;
  }
`;

const CompareBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f2f6;
  border-radius: 4px;
  border: 1px solid #262729 !important;
  width: 190px;
  height: 68px;
  padding: 20px 12px;
  margin-right: 32px;
`;

const AddPlanIcon = styled.i`
  color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
`;
