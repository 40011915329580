import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import Theme from "modules/theme-config/theme-config";
import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./routes";
import { useSelector, useDispatch } from "react-redux";
import ErrorBoundary from "./utils/ErrorBoundary";
import "./css/globalcss.css";
import { ThemeConf } from "modules/Home/home.slice";
import { TypeAccess } from "modules/login/login.slice";
import SecureLS from "secure-ls";
import { isEmpty } from "lodash";
import _ from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import { useLoginWidget } from "components";
//cache busting
import { useClearCache } from "react-clear-cache";

const ls = new SecureLS();

const App = () => {
  
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  /*---Theme---*/
  const dispatch = useDispatch();
  const { theme_conf } = useSelector((state) => state.home);
  const { typeAccess } = useSelector((state) => state.login);

  //cache busting
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion]);

  //load theme
  useEffect(() => {
    dispatch(ThemeConf());
  }, []);

  //store theme
  useEffect(() => {
    if (theme_conf && !_.isEmpty(theme_conf)) {
      ls.set("themeData", theme_conf);
    } else {
      ls.remove("themeData");
    }
  }, [theme_conf]);
  /*-x-Theme-x-*/

  /*---Type-Access---*/
  useEffect(() => {
    dispatch(TypeAccess());

    //clearing LS if broker is not renewbuy
    if (process.env.REACT_APP_BROKER !== "RB") {
      localStorage.removeItem("SSO_user");
      localStorage.removeItem("SSO_user_motor");
    }
  }, []);
  /*-x-Type-Access-x-*/

  //RenewBuy SSO
  const SSO = () => {
    useLoginWidget();
  };
  process.env.REACT_APP_BROKER === "RB" && SSO();

  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector("meta[name=viewport]");

    if (el !== null) {
      let content = el.getAttribute("content");
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, "maximum-scale=1.0");
      } else {
        content = [content, "maximum-scale=1.0"].join(", ");
      }

      el.setAttribute("content", content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }

  return (
    <ErrorBoundary>
      <ThemeProvider
        theme={!_.isEmpty(theme_conf) && theme_conf ? theme_conf : Theme}
      >
        <GlobalStyle broker={process.env?.REACT_APP_BROKER === "ABIBL"} />
        <Router />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;

export const GlobalStyle = createGlobalStyle`
body {
	font-family: ${({ theme }) =>
    theme?.regularFont?.fontFamily
      ? theme?.regularFont?.fontFamily
      : "basier_squareregular"};
 .swal-button {
       background-color: ${({ broker }) =>
         broker ? "#c7222a !important" : ""};
             }

  .swal-button--cancel {
      color: #555;
      background-color: #efefef !important;
  }


    }
.backBtn button {
	top: 
	${({ theme }) =>
    theme?.BackButton?.backButtonTop
      ? theme?.BackButton?.backButtonTop
      : "136px"};
	font-family: ${({ theme }) =>
    theme?.regularFont?.fontFamily
      ? theme?.regularFont?.fontFamily
      : "basier_squareregular"};

@media (max-width: 767px) 
			{
				top: ${({ theme }) =>
          theme?.BackButton?.backButtonTopMobile
            ? theme?.BackButton?.backButtonTopMobile
            : "112px"};
			}

}


`;
