//eslint-disable-next-line
//test
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import { object } from "prop-types";

export * from "./validations";

//api encryption
export const DataEncrypt = (text) => {
  var key = CryptoJS.enc.Utf8.parse("01234567890123456789012345678901");
  var iv = CryptoJS.enc.Utf8.parse("1234567890123412");
  let encryptedTxt = CryptoJS.AES.encrypt(JSON.stringify(text), key, {
    iv: iv,
  });
  return encryptedTxt.toString();
};

//api decryption
export const DataDecrypt = (encryptedTxt) => {
  var DataEncrypt = encryptedTxt ? encryptedTxt.toString() : "";
  var key = CryptoJS.enc.Utf8.parse("01234567890123456789012345678901");
  var iv = CryptoJS.enc.Utf8.parse("1234567890123412");
  var decrypted = CryptoJS.AES.decrypt(DataEncrypt, key, {
    iv: iv,
  });
  var decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
  return decrypted;
};

/** Encryption */
export const Encrypt = (str) => {
  // return AES.encrypt(
  //   str.toString(),
  //   `${process.env.REACT_APP_BROKER}900#`
  // ).toString();
  return window.btoa(str);
};

/** Decryption */
export const Decrypt = (str) => {
  // const bytes = AES.decrypt(
  //   str.toString(),
  //   `${process.env.REACT_APP_BROKER}900#`
  // );
  // return bytes.toString(Utf8);
  return window.atob(str);
};

export const useQuery = (search) => {
  return new URLSearchParams(search);
};

export const getFirstError = (errors) => {
  const keys = Object.keys(errors);
  const error = keys && keys.length > 0 ? errors[keys[0]] : "";
  return error && error.length > 0 ? error[0] : "";
};

export const processData = (data) => {
  if (!data) return data;
  const dataStr = JSON.stringify(data);
  dataStr.replace(/true/g, 1);
  dataStr.replace(/false/g, 0);
  return JSON.parse(dataStr);
};

export const checkBool = (bool) => {
  return (
    typeof bool === "boolean" ||
    (typeof bool === "object" &&
      bool !== null &&
      typeof bool.valueOf() === "boolean")
  );
};

export const downloadFile = (url, options, isTarget) => {
  const link = document.createElement("a");
  if (options) link.setAttribute("href", `options${encodeURIComponent(url)}`);
  if (isTarget) {
    link.setAttribute("target", `_blank`);
  }
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// all error
export const serializeError = (payload) => {
  let message = "";
  if (typeof payload === "string") message = payload;
  else if (typeof payload === "object") {
    for (const property in payload) {
      message = `${message}
${payload[property][0]}`;
    }
  }
  return message === "" ? "Network Error" : message;
};

// common action creatre(single get & post)
export const actionStructre = async (
  dispatch,
  onSuccess,
  onError,
  api,
  payload
) => {
  const { data, message, errors, success, showMessage } = await api(payload);
  if (data?.data || success) {
    dispatch(onSuccess(data?.data || message));
  } else {
    dispatch(onError(errors || message));
    console.error("Error", errors || message);
  }
};

// common action creatre(single get & post)
export const actionStructreBoth = async (
  dispatch,
  onSuccess,
  onError,
  api,
  payload
) => {
  const { data, message, errors, success, showMessage } = await api(payload);
  if (data.data && success) {
    dispatch(onSuccess(data.data || message));
  } else {
    dispatch(onError(errors || message));
    console.error("Error", errors || message);
  }
};

export const numOnlyNoZero = (event) => {
  let key = event.keyCode || event.which;
  var startPos = event.currentTarget.selectionStart;

  if (startPos === 0 && (key === 48 || key === 96)) {
    event.preventDefault();
  } else if (
    event.shiftKey === false &&
    ((key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 36 ||
      key === 37 ||
      key === 39 ||
      key === 46)
    // key === 144
  ) {
  } else {
    event.preventDefault();
  }
};

export const numOnly = (event) => {
  let key = event.keyCode || event.which;
  if (
    event.shiftKey === false &&
    ((key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 36 ||
      key === 37 ||
      key === 39 ||
      key === 46)
    // key === 144
  ) {
  } else {
    event.preventDefault();
  }
};

export const alpha = (e) => {
  let k = e.keyCode || e.which;
  return (
    (k > 64 && k < 91) ||
    (k > 96 && k < 123) ||
    k === 8 ||
    k === 32 ||
    (k >= 48 && k <= 57)
  );
};

export const noSpace = (event) => {
  let key = event.keyCode || event.which;
  if (key === 32) {
    event.preventDefault();
  }
};

export const toDate = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year, month - 1, day);
};

export const toDateOld = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year - 3, month - 1, day);
};

export const toDateDayOld = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year, month - 1, day - 1);
};

export const scrollToTargetAdjusted = (id, offsetVal) => {
  var element = document.getElementById(`${id}`);
  if (element) {
    const offset = offsetVal || 45;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const reloadPage = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const randomString = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const currencyFormater = (amount, decimal) => {
  if (amount) {
    let formatedAmount = Number(amount)
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .slice(0, -3);
    if (decimal) {
      formatedAmount = Number(amount)
        ?.toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
    return formatedAmount;
  } else {
    return 0;
  }
};

export const camelToUnderscore = (key) => {
  var result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

export const RedirectFn = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      if (
        process.env?.REACT_APP_API_BASE_URL === "https://olaapi.fynity.in/api"
      ) {
        return "https://ola-dashboard.fynity.in/";
      } else {
        return "http://uatoladashboard.fynity.in/";
      }
    case "UNILIGHT":
      return "https://www.policyongo.com/";
    case "CP":
      return "https://www.comparepolicy.com/";
    case "FYNTUNE":
      return "";
    case "ABIBL":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apiabibl-preprod-carbike.fynity.in/api"
      ) {
        return "http://preprod-dasbhoard-abibl.fynity.in/";
      } else if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apiabibl-carbike.fynity.in/api"
      ) {
        return "http://uat-dasbhoard-abibl.fynity.in/";
      } else {
        return "http://abibl-prod-dashboard.fynity.in/";
      }
    case "GRAM":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apigramcover-carbike.fynity.in/api"
      ) {
        return "http://uat-dasbhoard-gramcover.fynity.in/";
      } else {
        return "https://dashboard.gramcover.com/";
      }
    case "ACE":
      return "https://crm.aceinsurance.com:5555/";
    case "SRIYAH":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://nammacover.com/"
        : "https://uat.nammacover.com/";
    case "PINC":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://live.pincnews.co.in/"
        : "https://dev.pincnews.co.in/";
    case "EPOCH":
      return `http://online.epochbrokers.com:8092/`;
    case "RB":
      return "https://www.renewbuy.com/";
    case "SPA":
      return `https://uatdashboard.insuringall.com/`;
    case "BIMA":
      return ``;
    case "BAJAJ":
      return `https://www.bajajcapitalinsurance.com/`;
    case "UIB":
      return ``;
    default:
      break;
  }
};

export const getLogoCvType = (productSubTypeId) => {
  switch (productSubTypeId) {
    case 5:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/auto.png`;
    case 6:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/taxi-car1.png`;
    case 9:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/trck.png`;
    case 13:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/dumper2.png`;
    case 14:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/pickup.png`;
    case 15:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/tractor.png`;
    case 16:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/tanker.png`;
    default:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/auto-car.png`;
  }
};

export const UrlFn = (urlresp) => {
  console.log(url, "url");
  const url = urlresp ? urlresp : "";
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      if (
        process.env?.REACT_APP_API_BASE_URL === `https://olaapi.fynity.in/api`
      ) {
        return `https://ola-dashboard.fynity.in/${url}`;
      } else {
        return `http://uatoladashboard.fynity.in/${url}`;
      }
    case "UNILIGHT":
      return `https://www.policyongo.com/pos/login/${url}`;
    case "CP":
      return `https://www.comparepolicy.com/${url}`;
    case "FYNTUNE":
      return "";
    case "ABIBL":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        `https://apiabibl-preprod-carbike.fynity.in/api`
      ) {
        return `http://preprod-dasbhoard-abibl.fynity.in/${url}`;
      } else if (
        process.env?.REACT_APP_API_BASE_URL ===
        `https://apiabibl-carbike.fynity.in/api`
      ) {
        return `http://uat-dasbhoard-abibl.fynity.in/${url}`;
      } else {
        return `http://abibl-prod-dashboard.fynity.in/${url}`;
      }
    case "GRAM":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apigramcover-carbike.fynity.in/api"
      ) {
        return `http://uat-dasbhoard-gramcover.fynity.in/${url}`;
      } else {
        return `https://dashboard.gramcover.com/${url}`;
      }
    case "ACE":
      return "https://crm.aceinsurance.com:5555/";
    case "SRIYAH":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://nammacover.com/"
        : "https://uat.nammacover.com/";
    case "PINC":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://live.pincnews.co.in/"
        : "https://dev.pincnews.co.in/";
    case "EPOCH":
      return `http://online.epochbrokers.com:8092/`;
    case "RB":
      return "https://www.renewbuy.com/";
    case "SPA":
      return `https://uatdashboard.insuringall.com/${url}`;
    case "BIMA":
      return ``;
    case "BAJAJ":
      return `https://www.bajajcapitalinsurance.com/`;
    case "UIB":
      return ``;
    default:
      return `https://ola-dashboard.fynity.in/${url}`;
  }
};

export const simpleEncrypt = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const simpleDecrypt = (salt, encoded) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};
