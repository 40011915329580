import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./features.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import CustomTooltip from "components/tooltip/CustomTooltip";
import { Badge } from "react-bootstrap";
import {
  SetaddonsAndOthers,
  SaveAddonsData,
} from "../../quotesPage/quote.slice";
import { useMediaPredicate } from "react-media-hook";
import ThemeObj from "modules/theme-config/theme-config";
import _ from "lodash";
import SecureLS from "secure-ls";
import { TypeReturn } from "modules/type";
import { BlockedSections } from "modules/quotesPage/addOnCard/cardConfig";

function Features({ ButtonPanel, type, quote, scrollPosition }) {
  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
  const lessThan768 = useMediaPredicate("(max-width: 768px)");
  const dispatch = useDispatch();
  const location = useLocation();
  const { temp_data } = useSelector((state) => state.home);
  const { addOnsAndOthers } = useSelector((state) => state.quotes);
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  //addon logic implement

  //addons
  const [cpa, setCpa] = useState(
    addOnsAndOthers?.selectedCpa?.includes("Compulsory Personal Accident") &&
      _.isEmpty(addOnsAndOthers?.isTenure)
      ? true
      : false
  );

  const [rsa, setRsa] = useState(
    addOnsAndOthers?.selectedAddons?.includes("roadSideAssistance")
      ? true
      : false
  );

  const [zeroDep, setZeroDep] = useState(
    addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation") ? true : false
  );

  //gcv
  const [imt23, setImt23] = useState(
    addOnsAndOthers?.selectedAddons?.includes("imt23") ? true : false
  );
  //motor
  const [keyReplace, setKeyReplace] = useState(
    addOnsAndOthers?.selectedAddons?.includes("keyReplace") ? true : false
  );
  const [engineProtector, setEngineProtector] = useState(
    addOnsAndOthers?.selectedAddons?.includes("engineProtector") ? true : false
  );
  const [ncbProtection, setNcbProtectiont] = useState(
    addOnsAndOthers?.selectedAddons?.includes("ncbProtection") ? true : false
  );
  const [consumables, setConsumables] = useState(
    addOnsAndOthers?.selectedAddons?.includes("consumables") ? true : false
  );
  const [tyreSecure, setTyreSecure] = useState(
    addOnsAndOthers?.selectedAddons?.includes("tyreSecure") ? true : false
  );
  const [returnToInvoice, setReturnToInvoice] = useState(
    addOnsAndOthers?.selectedAddons?.includes("returnToInvoice") ? true : false
  );
  const [lopb, setLopb] = useState(
    addOnsAndOthers?.selectedAddons?.includes("lopb") ? true : false
  );

  const [cpa1, setCpa1] = useState(
    !_.isEmpty(addOnsAndOthers?.isTenure) ? true : false
  );

  useEffect(() => {
    var addons = [];
    var addons2 = [];

    if (rsa) {
      addons.push("roadSideAssistance");
      addons2.push({ name: "Road Side Assistance" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (zeroDep) {
      addons.push("zeroDepreciation");
      addons2.push({ name: "Zero Depreciation", prevZeroDep: "Yes" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (imt23) {
      addons.push("imt23");
      addons2.push({ name: "IMT - 23" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    //motor addons

    if (keyReplace) {
      addons.push("keyReplace");
      addons2.push({ name: "Key Replacement" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (engineProtector) {
      addons.push("engineProtector");
      addons2.push({ name: "Engine Protector" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (ncbProtection) {
      addons.push("ncbProtection");
      addons2.push({ name: "NCB Protection" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (consumables) {
      addons.push("consumables");
      addons2.push({ name: "Consumable" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (tyreSecure) {
      addons.push("tyreSecure");
      addons2.push({ name: "Tyre Secure" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (returnToInvoice) {
      addons.push("returnToInvoice");
      addons2.push({ name: "Return To Invoice" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    if (lopb) {
      addons.push("lopb");
      addons2.push({ name: "Loss of Personal Belongings" });
    } else {
      addons.push(false);
      addons2.push(false);
    }

    var data = {
      selectedAddons: addons.filter(Boolean),
    };
    var data1 = {
      enquiryId: temp_data?.enquiry_id || enquiry_id,
      //	type: "addons",
      addonData: { addons: addons2.filter(Boolean) },
    };
    dispatch(SetaddonsAndOthers(data));
    dispatch(SaveAddonsData(data1));
  }, [
    rsa,
    zeroDep,
    imt23,
    keyReplace,
    engineProtector,
    ncbProtection,
    consumables,
    tyreSecure,
    returnToInvoice,
    lopb,
  ]);

  // Save Add-Ons Data
  // useEffect(() => {
  //   if (temp_data?.ownerTypeId === 1) {
  //     if (!cpa) {
  //       // if (!tempData?.cpaReason) {
  //       //	setCpaPopup(true);
  //       // }
  //       var selectedCpa = [];
  //       var data1 = {
  //         enquiryId: temp_data?.enquiry_id || enquiry_id,
  //         //type: "compulsory_personal_accident",
  //         addonData: {
  //           compulsory_personal_accident: [
  //             {
  //               reason:
  //                 "I have another motor policy with PA owner driver cover in my name",
  //             },
  //           ],
  //         },
  //       };

  //       dispatch(SaveAddonsData(data1));
  //     } else if (cpa) {
  //       //	setCpaPopup(false);
  //       var selectedCpa = ["Compulsory Personal Accident"];
  //       var data1 = {
  //         enquiryId: temp_data?.enquiry_id || enquiry_id,
  //         //type: "compulsory_personal_accident",
  //         addonData: {
  //           compulsory_personal_accident: [
  //             { name: "Compulsory Personal Accident" },
  //           ],
  //         },
  //       };

  //       dispatch(SaveAddonsData(data1));
  //     }

  //     var data = {
  //       selectedCpa: selectedCpa,
  //     };
  //     dispatch(SetaddonsAndOthers(data));
  //   } else if (temp_data?.ownerTypeId === 2) {
  //     var data2 = {
  //       selectedCpa: [],
  //     };
  //     dispatch(SetaddonsAndOthers(data2));
  //     var data1 = {
  //       enquiryId: temp_data?.enquiry_id || enquiry_id,
  //       type: "compulsory_personal_accident",
  //       addonData: {
  //         compulsory_personal_accident: [
  //           { reason: "cpa not applicable to company" },
  //         ],
  //       },
  //     };
  //     dispatch(SaveAddonsData(data1));
  //   }
  // }, [cpa, temp_data?.ownerTypeId]);

  useEffect(() => {
    if (temp_data?.ownerTypeId === 1) {
      if (!cpa && !cpa1) {
        // if (!tempData?.cpaReason) {
        //	setCpaPopup(true);
        // }
        var selectedCpa = [];
        var tenureConst = [];
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          //type: "compulsory_personal_accident",
          addonData: {
            compulsory_personal_accident: [
              {
                reason:
                  "I have another motor policy with PA owner driver cover in my name",
              },
            ],
          },
        };

        dispatch(SaveAddonsData(data1));
      } else if (cpa) {
        //	setCpaPopup(false);
        var selectedCpa = ["Compulsory Personal Accident"];
        var tenureConst = [];
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          isTenure: tenureConst,
          //type: "compulsory_personal_accident",
          addonData: {
            compulsory_personal_accident: [
              { name: "Compulsory Personal Accident" },
            ],
          },
        };

        dispatch(SaveAddonsData(data1));
      } else if (cpa1) {
        //	setCpaPopup(false);
        var selectedCpa = ["Compulsory Personal Accident"];
        var tenureConst = [type === "car" ? 3 : 5];
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          isTenure: tenureConst,
          //type: "compulsory_personal_accident",
          addonData: {
            compulsory_personal_accident: [
              {
                name: "Compulsory Personal Accident",
                tenure: type === "car" ? 3 : 5,
              },
            ],
          },
        };

        dispatch(SaveAddonsData(data1));
      }

      var data = {
        selectedCpa: selectedCpa,
        isTenure: tenureConst,
      };
      dispatch(SetaddonsAndOthers(data));
    } else if (temp_data?.ownerTypeId === 2) {
      var data2 = {
        selectedCpa: [],
      };
      dispatch(SetaddonsAndOthers(data2));
      var data1 = {
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        type: "compulsory_personal_accident",
        addonData: {
          compulsory_personal_accident: [
            { reason: "cpa not applicable to company" },
          ],
        },
      };
      dispatch(SaveAddonsData(data1));
    }
  }, [cpa, temp_data?.ownerTypeId, cpa1]);

  const lessThan767 = useMediaPredicate("(max-width: 767px)");

  return (
    <>
      <TopDiv>
        <div className="compare-page-features">
          <div className="top-info ">
            <VehicleDetails
              fixed={
                lessThan768
                  ? false
                  : scrollPosition >
                    (Theme?.QuoteBorderAndFont?.scrollHeight
                      ? Theme?.QuoteBorderAndFont?.scrollHeight
                      : 68)
                  ? true
                  : false
              }
            >
              <div className="vehicleName">
                {" "}
                {quote?.mmvDetail?.manfName}-{quote?.mmvDetail?.modelName}-
                {quote?.mmvDetail?.versionName}-{quote?.fuelType}-
                {TypeReturn(type) !== "bike" && quote?.mmvDetail?.cubicCapacity}
                {TypeReturn(type) !== "bike" && "CC"}{" "}
              </div>
              <div className="policyType">{quote?.policyType} </div>
              <div className="dates">
                Reg Date: {quote?.vehicleRegisterDate}
              </div>
              {!temp_data?.breakIn && (
                <div className="dates">
                  Policy Start Date: {quote?.policyStartDate}
                </div>
              )}

              <PDFButton
                className="d-flex align-items-center justify-content-center"
                onClick={() =>
                  document?.getElementById("comparePdfDownload") &&
                  document?.getElementById("comparePdfDownload").click()
                }
              >
                <i
                  class="fa fa-download"
                  aria-hidden="true"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    margin: "0px 5px",
                  }}
                ></i>

                <label
                  className="m-0 p-0"
                  style={{
                    fontSize: "14px",
                    paddingTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  PDF
                </label>
              </PDFButton>
            </VehicleDetails>
          </div>

          <ul className="cd-features-list">
            <li>
              <PlanOptionHead className="planOptionHead">
                <p className="planOptionText">IC USP</p>
              </PlanOptionHead>

              <div className="planOptionName icContent"></div>

              <div className="planOptionName icContent"> </div>
              <div className="planOptionName icContent"></div>
            </li>
            <li>
              <PlanOptionHead className="planOptionHead">
                <p className="planOptionText">Premium Breakup</p>
              </PlanOptionHead>

              <div
                className="planOptionName"
                style={{ borderTop: lessThan768 ? "none" : "" }}
              >
                Total OD Payable (E)
              </div>

              {/* {temp_data.journeyCategory !== "GCV" && (
							<div className="planOptionName"><p style={{ color: 'green' }}>Total OD Premium</p></div>
						)}
						{temp_data.journeyCategory !== "GCV" && (
							<div className="planOptionName">Basic TP Premium</div>
						)}
						{temp_data.journeyCategory !== "GCV" && (
							<div className="planOptionName">Compulsory CPA cover 15 lakh</div>
						)}
						{temp_data.journeyCategory !== "GCV" && (
							<div className="planOptionName" style={{ color: 'green' }}>Total Liability Premium</div>
						)} */}

              <div className="planOptionName">
                <p>Total TP Payable</p>
              </div>
              <div className="planOptionName">Addon Premium (incl. in (E))</div>
              <div className="planOptionName">
                New NCB ({quote?.ncbDiscount}%)
              </div>
              <div
                className="planOptionName"
                style={{ fontFamily: "Inter-SemiBold" }}
              >
                Gross Premium (incl GST)
              </div>
            </li>

            <li>
              <PlanOptionHead className="planOptionHead">
                <p className="planOptionText addOnDetails">Addon Details</p>
              </PlanOptionHead>

              <div
                className="planOptionName longNameText"
                style={{
                  borderTop: lessThan768 ? "none" : "",
                  display:
                    (temp_data?.odOnly ||
                      temp_data?.corporateVehiclesQuoteRequest
                        ?.vehicleOwnerType === "C") &&
                    "none",
                }}
              >
                <FilterMenuBoxCheckConatiner>
                  <div className="filterMenuBoxCheck">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={"Compulsory Personal Accident"}
                      value={"Compulsory Personal Accident"}
                      defaultChecked={cpa && !cpa1}
                      //value={cpa}
                      checked={cpa && !cpa1}
                      onChange={(e) => {
                        setCpa(!cpa);
                        setCpa1(false);
                      }}
                      // onClick={() => }
                    />

                    <CustomTooltip
                      rider="true"
                      id="cpa1__Tooltipvol"
                      place={"right"}
                      customClassName="mt-3  "
                    >
                      <label
                        style={{ zIndex: "9" }}
                        onChange={() => {
                          setCpa(!cpa);
                          setCpa1(false);
                        }}
                        // onClick={() => }
                        data-tip={
                          lessThan767
                            ? ""
                            : "<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                        }
                        data-html={true}
                        data-for="cpa1__Tooltipvol"
                        className="form-check-label"
                        htmlFor={"Compulsory Personal Accident"}
                      >
                        {"Compulsory Personal Accident"}{" "}
                      </label>
                    </CustomTooltip>

                    <span style={{ marginLeft: "3px" }}></span>
                  </div>
                </FilterMenuBoxCheckConatiner>
              </div>
              {TypeReturn(type) !== "cv" && temp_data?.newCar && (
                <div
                  className="planOptionName longNameText"
                  style={{
                    borderTop: lessThan768 ? "none" : "",
                    display:
                      (temp_data?.odOnly ||
                        temp_data?.corporateVehiclesQuoteRequest
                          ?.vehicleOwnerType === "C") &&
                      "none",
                  }}
                >
                  <FilterMenuBoxCheckConatiner>
                    <div className="filterMenuBoxCheck">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"Compulsory Personal Accident 1"}
                        value={"Compulsory Personal Accident 1"}
                        defaultChecked={cpa1}
                        //value={cpa}
                        checked={cpa1}
                        onChange={(e) => {
                          setCpa1(!cpa1);
                          setCpa(false);
                        }}
                        // onClick={() => }
                      />

                      <CustomTooltip
                        rider="true"
                        id="cpa1__Tooltipvol"
                        place={"right"}
                        customClassName="mt-3  "
                      >
                        <label
                          style={{ zIndex: "9" }}
                          onChange={() => {
                            setCpa1(!cpa1);
                            setCpa(false);
                          }}
                          // onClick={() => }
                          data-tip={
                            lessThan767
                              ? ""
                              : "<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                          }
                          data-html={true}
                          data-for="cpa1__Tooltipvol"
                          className="form-check-label"
                          htmlFor={"Compulsory Personal Accident 1"}
                        >
                          {`Compulsory Personal Accident (${
                            TypeReturn(type) === "car" ? "3" : "5"
                          } years)`}{" "}
                        </label>
                      </CustomTooltip>

                      <span style={{ marginLeft: "3px" }}></span>
                    </div>
                  </FilterMenuBoxCheckConatiner>
                </div>
              )}

              <div className="planOptionName longNameText">
                {" "}
                <FilterMenuBoxCheckConatiner>
                  <div className="filterMenuBoxCheck">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={"Zero Depreciation"}
                      value={"Zero Depreciation"}
                      defaultChecked={zeroDep}
                      checked={zeroDep}
                      // value={zeroDep}
                      onChange={(e) => {
                        setZeroDep(e.target.checked);
                      }}
                    />
                    <CustomTooltip
                      rider="true"
                      id="zero__Tooltipvol"
                      place={"right"}
                      customClassName="mt-3  "
                    >
                      <label
                        style={{ zIndex: "9" }}
                        onChange={() => setZeroDep(!zeroDep)}
                        data-tip={
                          lessThan767
                            ? ""
                            : "<h3 >Zero Depreciation</h3> <div>Also called Nil Depreciation cover or Bumper-to-Bumper cover. An add-on which gives you complete cover on any body parts of the car excluding tyres and batteries. Insurer will pay entire cost of body parts, ignoring the year-on-year depreciation in value of these parts.</div>"
                        }
                        data-html={true}
                        data-for="zero__Tooltipvol"
                        className="form-check-label"
                        htmlFor={"Zero Depreciation"}
                      >
                        {"Zero Depreciation"}{" "}
                      </label>
                    </CustomTooltip>
                    <span style={{ marginLeft: "3px" }}></span>
                  </div>
                </FilterMenuBoxCheckConatiner>
              </div>

              <div className="planOptionName longNameText">
                {" "}
                <FilterMenuBoxCheckConatiner>
                  <div className="filterMenuBoxCheck">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={"Road Side Assistance"}
                      value={"Road Side Assistance"}
                      // value={rsa}
                      defaultChecked={rsa}
                      //value={cpa}
                      checked={rsa}
                      onChange={(e) => {
                        setRsa(e.target.checked);
                      }}
                    />

                    <CustomTooltip
                      rider="true"
                      id="rsa__Tooltipvol"
                      place={"right"}
                      customClassName="mt-3  "
                    >
                      <label
                        style={{ zIndex: "9" }}
                        onChange={() => setRsa(!rsa)}
                        data-tip={
                          lessThan767
                            ? ""
                            : "<h3 >Road Side Assistance</h3> <div>Roadside Assistance Coverage means a professional technician comes to your rescue when your car breaks down in the middle of the journey leaving you stranded.</div>"
                        }
                        data-html={true}
                        data-for="rsa__Tooltipvol"
                        className="form-check-label"
                        htmlFor={"Road Side Assistance"}
                      >
                        {"Road Side Assistance"}{" "}
                      </label>
                    </CustomTooltip>
                  </div>
                </FilterMenuBoxCheckConatiner>
              </div>

              {TypeReturn(type) === "cv" && (
                <>
                  <div className="planOptionName longNameText">
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"IMT - 23"}
                          value={"IMT - 23"}
                          // value={imt23}
                          defaultChecked={imt23}
                          //value={cpa}
                          checked={imt23}
                          onChange={(e) => {
                            setImt23(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="imtTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() => setImt23(!imt23)}
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >IMT - 23</h3> <div>COVER FOR LAMPS TYRES / TUBES MUDGUARDS BONNET /SIDE PARTS BUMPERS HEADLIGHTS AND PAINTWORK OF DAMAGED PORTION ONLY .</div>"
                            }
                            data-html={true}
                            data-for="imtTooltipvol"
                            className="form-check-label"
                            htmlFor={"IMT - 23"}
                          >
                            {"IMT - 23"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  {temp_data?.journeyCategory !== "GCV" && (
                    <div className="planOptionName longNameText">
                      {" "}
                      <FilterMenuBoxCheckConatiner>
                        <div className="filterMenuBoxCheck">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={"Consumable"}
                            value={"Consumable"}
                            // value={consumables}
                            defaultChecked={consumables}
                            checked={consumables}
                            onChange={(e) => {
                              setConsumables(e.target.checked);
                            }}
                          />

                          <CustomTooltip
                            rider="true"
                            id="consumableTooltipvol"
                            place={"right"}
                            customClassName="mt-3  "
                          >
                            <label
                              style={{ zIndex: "9" }}
                              onChange={() => setConsumables(!consumables)}
                              data-tip={
                                lessThan767
                                  ? ""
                                  : "<h3 >Consumable</h3> <div>The consumables in car insurance are those items that are subject to the constant wear and tear. They are continuously consumed by the car during its life for e.g nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.</div>"
                              }
                              data-html={true}
                              data-for="consumableTooltipvol"
                              className="form-check-label"
                              htmlFor={"Consumable"}
                            >
                              {"Consumable"}{" "}
                            </label>
                          </CustomTooltip>
                          <span style={{ marginLeft: "3px" }}></span>
                        </div>
                      </FilterMenuBoxCheckConatiner>
                    </div>
                  )}
                </>
              )}
              {(TypeReturn(type) === "car" || TypeReturn(type) === "bike") && (
                <>
                  <div
                    className="planOptionName longNameText"
                    style={{ display: TypeReturn(type) === "bike" && "none" }}
                  >
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Key Replacement"}
                          value={"Key Replacement"}
                          // value={keyReplace}
                          defaultChecked={keyReplace}
                          checked={keyReplace}
                          onChange={(e) => {
                            setKeyReplace(e.target.checked);
                          }}
                        />
                        <CustomTooltip
                          rider="true"
                          id="keyTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          {/* <img
																	data-tip="<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
																	data-html={true}
																	data-for="keyTooltipvol"
																	src={tooltip}
																	alt="tooltip"
																	className="toolTipRiderChild"
																/> */}
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() => setKeyReplace(!keyReplace)}
                            className="form-check-label"
                            htmlFor={"Key Replacement"}
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
                            }
                            data-html={true}
                            data-for="keyTooltipvol"
                            alt="tooltip"
                          >
                            {"Key Replacement"}{" "}
                          </label>
                        </CustomTooltip>
                        {/* <label
															className="form-check-label"
															htmlFor={"Key Replacement"}
														>
															{"Key Replacement"}{" "}
														</label> */}
                        <div style={{ marginLeft: "3px" }}></div>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  <div className="planOptionName longNameText">
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Engine Protector"}
                          value={"Engine Protector"}
                          // value={engineProtector}
                          defaultChecked={engineProtector}
                          checked={engineProtector}
                          onChange={(e) => {
                            setEngineProtector(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="engTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() =>
                              setEngineProtector(!engineProtector)
                            }
                            className="form-check-label"
                            htmlFor={"Engine Protector"}
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >Engine Protector</h3> <div>Engine protection cover in car insurance provides coverage towards damages or losses to the insured vehicle’s engine. The add-on compensates you for the replacement or repair of your car’s engine or parts.</div>"
                            }
                            data-html={true}
                            data-for="engTooltipvol"
                          >
                            {"Engine Protector"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  <div
                    className="planOptionName longNameText"
                    style={{ display: TypeReturn(type) === "bike" && "none" }}
                  >
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"NCB Protection"}
                          value={"NCB Protection"}
                          // value={ncbProtection}
                          defaultChecked={ncbProtection}
                          checked={ncbProtection}
                          onChange={(e) => {
                            setNcbProtectiont(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="ncbProtTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() => setNcbProtectiont(!ncbProtection)}
                            className="form-check-label"
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >NCB Protection</h3> <div>The NCB Protector protects Your Earned No claim Bonus, in the event of an Own Damage claim made for Partial Loss including claims for Windshield glass, Total Loss, and Theft of vehicle/ accessories. The No Claim Bonus will not get impacted for the first 2 claims preferred during the course of this policy per year.</div>"
                            }
                            data-html={true}
                            data-for="ncbProtTooltipvol"
                            htmlFor={"NCB Protection"}
                          >
                            {"NCB Protection"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  <div className="planOptionName longNameText">
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Consumable"}
                          value={"Consumable"}
                          // value={consumables}
                          defaultChecked={consumables}
                          checked={consumables}
                          onChange={(e) => {
                            setConsumables(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="consumableTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() => setConsumables(!consumables)}
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >Consumable</h3> <div>The consumables in car insurance are those items that are subject to the constant wear and tear. They are continuously consumed by the car during its life for e.g nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.</div>"
                            }
                            data-html={true}
                            data-for="consumableTooltipvol"
                            className="form-check-label"
                            htmlFor={"Consumable"}
                          >
                            {"Consumable"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  <div
                    className="planOptionName longNameText"
                    style={{ display: TypeReturn(type) === "bike" && "none" }}
                  >
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Tyre Secure"}
                          value={"Tyre Secure"}
                          // value={tyreSecure}
                          defaultChecked={tyreSecure}
                          checked={tyreSecure}
                          onChange={(e) => {
                            setTyreSecure(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="tyreTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() => setTyreSecure(!tyreSecure)}
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >Tyre Secure</h3> <div>This is an add-on cover which covers the damages to the tyre of the car caused due to accidental external means. The cost of tyre replacement, rebalancing, removal and refitting is covered.</div>"
                            }
                            data-html={true}
                            data-for="tyreTooltipvol"
                            className="form-check-label"
                            htmlFor={"Tyre Secure"}
                          >
                            {"Tyre Secure"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  <div className="planOptionName longNameText">
                    {" "}
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Return To Invoice"}
                          value={"Return To Invoice"}
                          // value={returnToInvoice}
                          defaultChecked={returnToInvoice}
                          checked={returnToInvoice}
                          onChange={(e) => {
                            setReturnToInvoice(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="roiTooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() =>
                              setReturnToInvoice(!returnToInvoice)
                            }
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >Return To Invoice</h3> <div>Return to Invoice is an add-on option which covers the gap between the insured declared value and the invoice value of your car along with the registration and other applicable taxes.</div>"
                            }
                            data-html={true}
                            data-for="roiTooltipvol"
                            className="form-check-label"
                            htmlFor={"Return To Invoice"}
                          >
                            {"Return To Invoice"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                  <div
                    className="planOptionName longNameText"
                    style={{ display: TypeReturn(type) === "bike" && "none" }}
                  >
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Loss of Personal Belongings"}
                          value={"Loss of Personal Belongings"}
                          // value={lopb}
                          defaultChecked={lopb}
                          checked={lopb}
                          onChange={(e) => {
                            setLopb(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="lopb__Tooltipvol"
                          place={"right"}
                          customClassName="mt-3  "
                        >
                          <label
                            style={{ zIndex: "9" }}
                            onChange={() => setLopb(!lopb)}
                            data-tip={
                              lessThan767
                                ? ""
                                : "<h3 >Loss of Personal Belongings</h3> <div>With this cover in place, your insurer will cover losses arising due to damage or theft of your personal Belongings from the insured car as per the terms and conditions of the policy.</div>"
                            }
                            data-html={true}
                            data-for="lopb__Tooltipvol"
                            className="form-check-label"
                            htmlFor={"Loss of Personal Belongings"}
                          >
                            {"Loss of Personal Belongings"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}></span>
                      </div>
                    </FilterMenuBoxCheckConatiner>
                  </div>
                </>
              )}
            </li>
            <li>
              <PlanOptionHead className="planOptionHead">
                <p className="planOptionText">Accessories</p>
              </PlanOptionHead>
              <div
                className="planOptionName "
                style={{ borderTop: lessThan768 ? "none" : "" }}
              >
                Electrical Accessories{" "}
                {addOnsAndOthers?.selectedAccesories?.includes(
                  "Electrical Accessories"
                ) && (
                  <Badge
                    variant="dark"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "5px",
                    }}
                  >
                    ₹ {addOnsAndOthers?.vehicleElectricAccessories}
                  </Badge>
                )}{" "}
              </div>
              <div className="planOptionName">
                Non Electrical Accessories{" "}
                {addOnsAndOthers?.selectedAccesories?.includes(
                  "Non-Electrical Accessories"
                ) && (
                  <Badge
                    variant="dark"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "5px",
                    }}
                  >
                    ₹ {addOnsAndOthers?.vehicleNonElectricAccessories}
                  </Badge>
                )}{" "}
              </div>
              <div
                className="planOptionName"
                style={{ display: TypeReturn(type) === "bike" && "none" }}
              >
                Bi Fuel Kit
                {addOnsAndOthers?.selectedAccesories?.includes(
                  "External Bi-Fuel Kit CNG/LPG"
                ) && (
                  <Badge
                    variant="dark"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      marginLeft: "5px",
                    }}
                  >
                    ₹ {addOnsAndOthers?.externalBiFuelKit}
                  </Badge>
                )}{" "}
              </div>
              {/* {temp_data.journeyCategory !== "GCV" && (
								<div className="planOptionName">
									Trailer
									{addOnsAndOthers?.selectedAccesories?.includes("Trailer") && (
										<Badge
											variant="dark"
											style={{
												cursor: "pointer",
												position: "relative",
												marginLeft: "5px",
											}}
										>
											₹ {addOnsAndOthers?.trailerCover}
										</Badge>
									)}{" "}
								</div>
							)} */}
            </li>
            {!temp_data?.odOnly && (
              <li>
                <PlanOptionHead className="planOptionHead">
                  <p className="planOptionText additionalCovers">
                    Additional Covers
                  </p>
                </PlanOptionHead>
                {temp_data.journeyCategory !== "GCV" && (
                  <>
                    <div
                      className="planOptionName"
                      style={{ borderTop: lessThan768 ? "none" : "" }}
                    >
                      Unnamed Passenger PA cover
                      {addOnsAndOthers?.selectedAdditions?.includes(
                        "Unnamed Passenger PA Cover"
                      ) && (
                        <Badge
                          variant="dark"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            marginLeft: "5px",
                          }}
                        >
                          {addOnsAndOthers?.unNamedCoverValue}
                        </Badge>
                      )}
                    </div>
                    <div
                      className="planOptionName"
                      style={{ display: TypeReturn(type) === "bike" && "none" }}
                    >
                      PA cover for additional paid driver
                      {addOnsAndOthers?.selectedAdditions?.includes(
                        "PA cover for additional paid driver"
                      ) && (
                        <Badge
                          variant="dark"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            marginLeft: "5px",
                          }}
                        >
                          {addOnsAndOthers?.additionalPaidDriver}
                        </Badge>
                      )}
                    </div>
                    <div className="planOptionName">
                      LL Paid Driver{" "}
                      {/* {addOnsAndOthers?.selectedAdditions?.includes(
											"LL paid driver"
										) && (
											<Badge
												variant="dark"
												style={{
													cursor: "pointer",
													position: "relative",
													marginLeft: "5px",
												}}
											>
												₹ 1 Lac
											</Badge>
										)}{" "} */}
                    </div>
                  </>
                )}

                {temp_data.journeyCategory === "GCV" && (
                  <>
                    <div className="planOptionName">
                      LL paid driver/conductor/cleaner
                      {/* {addOnsAndOthers?.selectedAdditions?.includes(
										"LL paid driver/conductor/cleaner"
									) && (
										<Badge
											variant="dark"
											style={{
												cursor: "pointer",
												position: "relative",
												marginLeft: "5px",
											}}
										>
											{addOnsAndOthers?.unNamedCoverValue}
										</Badge>
									)} */}
                    </div>
                    <div className="planOptionName">
                      PA paid driver/conductor/cleaner
                      {/* {addOnsAndOthers?.selectedAdditions?.includes(
										"PA paid driver/conductor/cleaner"
									) && (
										<Badge
											variant="dark"
											style={{
												cursor: "pointer",
												position: "relative",
												marginLeft: "5px",
											}}
										>
											{addOnsAndOthers?.additionalPaidDriver}
										</Badge>
									)} */}
                    </div>
                  </>
                )}
              </li>
            )}
            {temp_data.journeyCategory !== "GCV" && (
              <li>
                <PlanOptionHead className="planOptionHead">
                  <p className="planOptionText">Discounts</p>
                </PlanOptionHead>
                <div
                  className="planOptionName "
                  style={{ borderTop: lessThan768 ? "none" : "" }}
                >
                  Vehicle is fitted with ARAI{" "}
                  {addOnsAndOthers?.selectedDiscount?.includes(
                    "Is the vehicle fitted with ARAI approved anti-theft device?"
                  ) && (
                    <Badge
                      variant="dark"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        marginLeft: "5px",
                      }}
                    >
                      Yes
                    </Badge>
                  )}
                </div>
                {TypeReturn(type) !== "cv" &&
                  !BlockedSections(process.env.REACT_APP_BROKER).includes(
                    "voluntary discount"
                  ) && (
                    <div className="planOptionName">
                      Voluntary Deductible{" "}
                      {addOnsAndOthers?.selectedDiscount?.includes(
                        "Voluntary Discounts"
                      ) && (
                        <Badge
                          variant="dark"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            marginLeft: "5px",
                          }}
                        >
                          ₹ {addOnsAndOthers?.volDiscountValue}
                        </Badge>
                      )}{" "}
                    </div>
                  )}
                {type === "cv" && (
                  <div className="planOptionName">
                    Vehicle Limited to Own Premises{" "}
                    {/* {addOnsAndOthers?.selectedDiscount?.includes(
											"Vehicle Limited to Own Premises"
									) && (
										<Badge
											variant="dark"
											style={{
												cursor: "pointer",
												position: "relative",
												marginLeft: "5px",
											}}
										>
									</Badge>
								)} */}{" "}
                  </div>
                )}

                {/* <div className="planOptionName">
							Vehicle Limited to Own Premises{" "}
								{addOnsAndOthers?.selectedDiscount?.includes(
									"Vehicle Limited to Own Premises"
								) && (
									<Badge
										variant="dark"
										style={{
											cursor: "pointer",
											position: "relative",
											marginLeft: "5px",
										}}
									>
										₹ {addOnsAndOthers?.volDiscountValue}
									</Badge>
								)}{" "}
							</div> */}
                <div
                  className="planOptionName"
                  style={{ display: temp_data?.odOnly && "none" }}
                >
                  TPPD Cover
                  {/* {addOnsAndOthers?.selectedAccesories?.includes(
									"TPPD Cover"
								) && (
									<Badge
										variant="dark"
										style={{
											cursor: "pointer",
											position: "relative",
											marginLeft: "5px",
										}}
									>
										₹ {addOnsAndOthers?.externalBiFuelKit}
									</Badge>
								)}{" "} */}
                </div>
                {/* {temp_data.journeyCategory !== "GCV" && (
								<div className="planOptionName">
									Trailer
									{addOnsAndOthers?.selectedAccesories?.includes("Trailer") && (
										<Badge
											variant="dark"
											style={{
												cursor: "pointer",
												position: "relative",
												marginLeft: "5px",
											}}
										>
											₹ {addOnsAndOthers?.trailerCover}
										</Badge>
									)}{" "}
								</div>
							)} */}
              </li>
            )}
          </ul>
        </div>
      </TopDiv>
    </>
  );
}

export default Features;

const PlanOptionHead = styled.div`
  color: ${({ theme }) => theme.comparePage?.color || "#55d400 !important"};
  border-bottom: none !important;
  .planOptionText {
    background: ${({ theme }) =>
      theme.comparePage2?.lg ||
      "-webkit-linear-gradient(-134deg, #ffffff, #d7df23) "};
    padding: 5px;
    color: white;
    font-size: 14px;
    border-radius: 0px 00px 0px 0px;
    width: 475%;
    border-left: ${({ theme }) =>
      theme.comparePage2?.borderHeader || " 5px solid #a6ac1a "};
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    margin-top: 10px;
    @media (max-width: 768px) {
      font-size: 10px;
      border-radius: 0px;
      width: 100%;
    }
  }
`;

const VehicleDetails = styled.div`
  // box-shadow: rgb(0 0 0 / 10%) 0px 8px 25px -5px,
  // 	rgb(0 0 0 / 4%) 0px 10px 10px -5px;
  background: ${(props) => (props.fixed ? "#ffffff" : "#f7f7fa")};
  height: ${(props) => (props.fixed ? "235px" : "220px")};
  position: ${(props) => (props.fixed ? "fixed !important" : "relative")};
  bottom: ${(props) => (props.fixed ? "unset" : "17px")};
  top: ${(props) => (props.fixed ? "0px" : "unset")};
  min-width: ${(props) => (props.fixed ? "240px" : "unset")};
  width: ${(props) => (props.fixed ? "1107px" : "240px")};
  padding: 25px;
  .vehicleName {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0px;
    border-bottom: 1px solid #6b6e71;
    text-align: left;
    max-width: 200px;
  }
  .policyType {
    font-family: ${({ theme }) =>
      theme.regularFont?.fontFamily || "Inter-Regular"};
    font-size: 12px;
    padding: 10px 0px;
    color: #6b6e71;
    text-align: left;
  }
  .dates {
    font-family: ${({ theme }) =>
      theme.regularFont?.fontFamily || "Inter-Regular"};
    font-size: 12px;
    padding: 5px 0px;
    text-align: left;
  }

  @media screen and (min-width: 768px) and (max-width: 1177px) {
    min-width: ${(props) => (props.fixed ? "220px" : "unset")};
    width: ${(props) => (props.fixed ? "710px" : "220px")};
    .vehicleName {
      font-size: 12px;
      line-height: 18px;
      padding: 5px 0px;
      max-width: 140px;
    }
    .policyType {
      font-size: 10px;
      padding: 5px 0px;
    }
    .dates {
      font-size: 10px;
      padding: 5px 0px;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
    .vehicleName {
      font-size: 12px;
      padding: 5px 0px;
      line-height: 18px;
      max-width: 140px;
    }
    .policyType {
      font-size: 10px;
      padding: 5px 0px;
    }
    .dates {
      font-size: 10px;
      padding: 5px 0px;
    }
  }
`;

const FilterMenuBoxCheckConatiner = styled.div`
	.filterMenuBoxCheck input[type="checkbox"]:checked + label:before {
		background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
		border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
		box-shadow: ${({ theme }) => theme.QuoteBorderAndFont?.shadowCheck || "none"};
	filter: ${({ theme }) =>
    theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};

	}
	.filterMenuBoxCheck label {
		font-family: ${({ theme }) => theme.regularFont?.fontFamily || "Inter-Regular"};
		font-size: 14px;
		color: #333;
		@media screen and (max-width: 767px) {
			font-size: 11px !important;
		}
	}
	.filterMenuBoxCheck label:before {
		border: 1px solid #000000;
		@media screen and (max-width: 767px) {
			padding-left: 30px;
			height: 14px;
			padding: 1px 0 0;
			vertical-align: top;
			width: 14px;
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1177px) {
		.filterMenuBoxCheck label {
			font-size: 10px !important;			
			font-family: ${({ theme }) => theme.regularFont?.fontFamily || "Inter-Regular"};
			font-size: 14px;
			color: #333;
		}
	}

}
`;

const TopDiv = styled.div`
  .compare-page {
    &-features {
      position: absolute;
      // top: 124px;
      top: 18px;
      left: 0;
      width: 292px;
      padding-right: 48px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 4px;
        height: 100%;
        background-color: transparent;
        background-image: -webkit-linear-gradient(
          left,
          rgba(0, 0, 0, 0.06),
          transparent
        );
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.06),
          transparent
        );
        opacity: 0;
      }
      & .top-info {
        font-family: ${({ theme }) =>
          theme.regularFont?.fontFamily || "Inter-Regular"};
        line-height: 19px;
        color: #333;
        line-height: 14px;
        padding: 0;
        text-align: left;
        & .planOptionHead {
          border-bottom: none !important;
          margin-right: 60px;
          padding-bottom: 6px;
          text-align: justify;
        }
        & .planOptionFName {
          margin-top: 24px;
          margin-bottom: 26px;
          text-align: justify;
        }
      }
      & .cd-features-list {
        padding: 0;
        list-style: none;
        & li {
          font-family: ${({ theme }) =>
            theme.regularFont?.fontFamily || "Inter-Regular"};
          font-size: 14px;
          line-height: 19px;
          // color: ${({ theme }) => theme.regularFont?.textColor || "#333"};
          & .planOptionName {
            margin-bottom: 0;
            height: 39px;
            overflow: hidden;
            line-height: 19px;
            border-bottom: 1px solid #d0d0d0;
            width: 453%;
            padding: 10px 0px 0px 20px;
            font-weight: 300 !important;
            &:last-child {
            }
            @media (max-width: 768px) {
              border-top: 1px solid;
            }
          }
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1177px) {
        width: 150px;
        padding-right: 4px;
        & .cd-features-list {
          & li {
            font-size: 10px !important;
          }
          & .planOptionHead {
            font-size: 12px;
            margin-right: 0;
          }
          & .planOptionName {
            font-size: 10px !important;
            font-weight: 300 !important;
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        top: 10px;
        & .top-info .planOptionFName {
          margin-top: 11px;
        }
        & .cd-features-list {
          border: none;
        }
        & .cd-features-list li .planOptionName {
          margin-bottom: 14px;
          height: 34px;
          line-height: 14px;
          border-bottom: none;
          width: 100% !important;
          padding: 10px 10px 0px 0px;
          &:last-child {
          }
        }
        .longNameText {
          min-height: 60px;
        }
      }
    }
  }
  .compare-page .compare-products-wrap .planOptionHead {
    font-size: 19px !important;
  }
  .compare-page .compare-products-wrap .planOptionFName {
    font-size: 16px;
    font-weight: bold;
    margin-top: 35px !important;
  }
  .compare-page-features .cd-features-list li .planOptionName {
    font-size: 14px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    .compare-page .compare-products-wrap .planOptionHead {
      font-size: 11px !important;
    }
    .compare-page .compare-products-wrap .planOptionFName {
      font-size: 11px;
      font-weight: bold;
    }
    .compare-page-features .cd-features-list li .planOptionName {
      font-size: 11px;
      font-weight: bold;
    }
  }
  @media (max-width: 768px) {
    .addOnDetails {
      margin-top: 30px;
    }
    .icContent {
      border-top: none !important;
      border-bottom: 1px solid !important;
    }
  }
  .cd-features-list li {
    color: ${({ theme }) => theme.regularFont?.textColor + "!important" || ""};
  }
`;
const PDFButton = styled.div`
  display: ${process.env.REACT_APP_BROKER === "ABIBL"
    ? "flex"
    : "none"} !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;
