export const protecting = [
	{
		name: "Corporate General Insurance ",
		link:
			"https://corporategeneralinsurance.adityabirlacapital.com/products/overview",
	},
	{
		name: "Group Life Insurance",
		link:
			"https://lifeinsurance.adityabirlacapital.com/group-insurance/overview",
	},
	{
		name: "Group Health Insurance",
		link:
			"https://www.adityabirlacapital.com/healthinsurance/#!/group-health-insurance-products",
	},
];
export const investing = [
	{
		name: "Private Equity",
		link:
			"https://privateequity.adityabirlacapital.com/Pages/Individual/Funds-Under-Management/Overview.aspx",
	},
	{
		name: "Mutual Funds ",
		link:
			"https://mutualfund.adityabirlacapital.com/investor-solution-corporate",
	},
	{
		name: "Stock and Securities",
		link: "https://stocksandsecurities.adityabirlacapital.com/",
	},
	{
		name: "Portfolio Management Services",
		link: "https://portfoliomanagementservices.adityabirlacapital.com/",
	},
	{
		name: "Pension Funds ",
		link: "https://pensionfund.adityabirlacapital.com/",
	},
];

export const financing = [
	{
		name: "Corporate Finance",
		link:
			"https://corporatefinance.adityabirlacapital.com/Pages/Individual/Products/Overview.aspx",
	},
	{
		name: "Mortgage Finance ",
		link:
			"https://mortgagefinance.adityabirlacapital.com/Pages/Individual/Products/Overview.aspx",
	},
	{
		name: "Loans Against Securities ",
		link:
			"https://loanagainstsecurities.adityabirlacapital.com/Pages/Individual/Products/Overview.aspx",
	},
	{
		name: "DCM & Loan Syndication ",
		link:
			"https://dcmandsyndication.adityabirlacapital.com/Pages/Individual/Products/Overview.aspx",
	},
	{ name: "", link: "" },
];
export const advertising = [
	{
		name: "MoneyforLife Planner ",
		link: "https://advising.adityabirlacapital.com/money-for-life-planner",
	},
	{
		name: "ABC of Money ",
		link: "https://www.adityabirlacapital.com/abc-of-money",
	},
];