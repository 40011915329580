import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, ErrorMsg } from "../../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Form, ToggleButton } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FormGroupTag, ButtonGroupTag, H4Tag2, ColDiv } from "../style.js";
import DateInput from "../DateInput";
import _, { forEach } from "lodash";
import { numOnly, toDate as DateUtil } from "../../../utils";
import { subYears, toDate } from "date-fns";
import { getGender, Pincode, clear, getOccupation } from "../proposal.slice";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { ShareQuote } from "modules/Home/home.slice";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

/*---------------date config----------------*/
const AdultCheck = subYears(new Date(Date.now() - 86400000), 18);
/*-----x---------date config-----x----------*/

const OwnerCard = ({
  onSubmitOwner,
  owner,
  CardData,
  conditionChk,
  Theme,
  lessthan768,
  lessthan376,
  enquiry_id,
  fields,
  type,
  token,
}) => {
  const {
    gender,
    pincode: pin,
    occupation,
    temp_data,
  } = useSelector((state) => state.proposal);
  const dispatch = useDispatch();
  const { validationConfig } = useSelector((state) => state.home);
  console.log(fields);
  /*----------------Validation Schema---------------------*/
  const yupValidate = yup.object(
    temp_data?.selectedQuote?.isRenewal === "Y"
      ? {}
      : {
          pincode: yup
            .string()
            .required("Pincode is Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Must be 6 digits")
            .max(6, "Must be 6 digits")
            .trim(),
          // address: yup.string(),
          // // .matches(
          // //   /^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/,
          // //   "address must be alpha-numeric "
          // //)
          addressLine1: yup
            .string()
            .required("Address1 is Required")
            // .max(30, "Max 30 chars allowed")
            .trim(),
          addressLine2: yup
            .string()
            // .required("Address2 is Required")
            // .max(30, "Max 30 chars allowed")
            .trim(),
          // addressLine3: yup
          //   .string()
          //   // .matches(/^([[A-Za-z0-9\s.])+$/, "Must be alpha-numeric ")
          //   .max(30, "Max 30 chars allowed")
          //   .required("Address3 is Required")
          //   .trim(),
          city: yup
            .string()
            .required("Required")
            .matches(/[1-9A-Za-z]/, "City Required"),
          state: yup
            .string()
            .required("Required")
            .matches(/[1-9A-Za-z]/, "State Required"),
          cityId: yup.string().required("Required"),
          stateId: yup.string().required("Required"),
          ...(!fields.includes("gstNumber")
            ? {}
            : (Number(temp_data?.ownerTypeId) === 2 &&
                temp_data?.selectedQuote?.companyAlias === "sbi") ||
              (temp_data?.selectedQuote?.companyAlias === "liberty_videocon" &&
                (type === "car" || type === "bike") &&
                Number(temp_data?.ownerTypeId) === 2 &&
                !token)
            ? {
                gstNumber: yup
                  .string()
                  .required("GST is required")
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "GST number invalid"
                  )
                  .trim(),
              }
            : {
                gstNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "GST number invalid"
                  )
                  .trim(),
              }),
          ...(!fields.includes("panNumber")
            ? {}
            : !(
                temp_data?.selectedQuote?.companyAlias === "liberty_videocon" &&
                (type === "car" || type === "bike") &&
                !token &&
                Number(temp_data?.ownerTypeId) === 2
              )
            ? {
                panNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                    "PAN number invalid"
                  )
                  .trim(),
              }
            : {
                panNumber: yup
                  .string()
                  .required("PAN is required")
                  .matches(
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                    "PAN number invalid"
                  )
                  .trim(),
              }),
          ...((process.env.REACT_APP_BROKER !== "OLA" ||
            fields.includes("email")) && {
            email: yup
              .string()
              .email("Please enter valid email id")
              .min(2, "Please enter valid email id")
              .max(50, "Must be less than 50 chars")
              .required("Email id is required")
              .trim(),
          }),
          mobileNumber: yup
            .string()
            .required("Mobile No. is required")
            .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
            .min(10, "Mobile No. should be 10 digits")
            .max(10, "Mobile No. should be 10 digits"),
          ...(fields.includes("dob") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              dob: yup.string().required("DOB is required"),
            }),
          ...(fields.includes("gender") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              gender: yup
                .string()
                .required("Gender is required")
                .matches(
                  /^([A-Za-z\s])+$/,
                  "Salutation and gender doesn't match"
                ),
            }),
          ...(Number(temp_data?.ownerTypeId) === 1
            ? {
                fullName: yup
                  .string()
                  .required("Name is required")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .min(2, "Minimum 2 chars required")
                  .trim(),
                firstName: yup
                  .string()
                  .required("First Name is required")
                  .matches(/^([[A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .min(1, "Minimum 2 chars required")
                  .max(50, "Must be less than 50 chars")
                  .trim(),
                lastName: yup
                  .string()
                  // .required("Last Name is required")
                  // .min(1, "Minimum 1 char required")
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .max(50, "Must be less than 50 chars")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .trim(),
              }
            : {
                firstName: yup
                  .string()
                  .required("Name is required")
                  .matches(
                    /^([[0-9A-Za-z\s.'&_+@!#,-])+$/,
                    "Must be only AlphaNumeric"
                  )
                  .min(1, "Minimum 1 char required")
                  .max(50, "Must be less than 50 chars")
                  .trim(),
                lastName: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  // .required("Name is required")
                  .min(1, "Minimum 1 char required")
                  .max(50, "Must be less than 50 chars")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .trim(),
              }),
          ...(fields.includes("maritalStatus") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              maritalStatus: yup
                .string()
                .required("Marital Status is required"),
            }),
          ...(fields.includes("occupation") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              occupation: yup
                .string()
                .required("Occupation is required")
                .matches(/[^@]/, "Occupation is required"),
            }),
        }
  );
  /*----------x------Validation Schema----------x-----------*/

  const { handleSubmit, register, errors, control, reset, setValue, watch } =
    useForm({
      defaultValues: !_.isEmpty(owner)
        ? owner
        : !_.isEmpty(CardData?.owner)
        ? CardData?.owner
        : {},
      resolver: yupResolver(yupValidate),
      mode: "onBlur",
      reValidateMode: "onBlur",
    });

  const companyAlias = !_.isEmpty(temp_data?.selectedQuote)
    ? temp_data?.selectedQuote?.companyAlias
    : "";

  const DOB = watch("dob");
  console.log("errorserrors", errors);
  const allFieldsReadOnly = temp_data?.selectedQuote?.isRenewal === "Y";

  //prefill Api
  useEffect(() => {
    if (_.isEmpty(owner) && !_.isEmpty(CardData?.owner)) {
      reset(CardData?.owner);
      CardData?.owner?.maritalStatus &&
        setRadioValue2(CardData?.owner?.maritalStatus);
      setRadioValue(CardData?.owner?.gender);
      // setValue("city", CardData?.owner?.city);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData.owner]);

  //Personal Details
  useEffect(() => {
    if (_.isEmpty(owner) && _.isEmpty(CardData?.owner)) {
      if (Number(temp_data?.ownerTypeId) === 1) {
        setValue(
          "firstName",
          Number(temp_data?.ownerTypeId) === 1 ? temp_data?.firstName : ""
        );

        temp_data?.firstName &&
          setValue(
            "fullName",
            `${temp_data?.firstName}${
              temp_data?.lastName ? ` ${temp_data?.lastName}` : ""
            }`
          );
      }
      setValue(
        "lastName",
        Number(temp_data?.ownerTypeId) === 1
          ? temp_data?.lastName
          : temp_data?.firstName && temp_data?.lastName
          ? `${temp_data?.firstName} ${temp_data?.lastName}`
          : ""
      );
      setValue("email", temp_data?.emailId);
      setValue("mobileNumber", temp_data?.mobileNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //occupation
  useEffect(() => {
    if (companyAlias)
      dispatch(
        getOccupation({ companyAlias: companyAlias, enquiryId: enquiry_id })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias]);

  const Occupations = !_.isEmpty(occupation)
    ? occupation?.map(({ name, id }) => {
        return { name, id };
      })
    : [];

  //setting hidden i/p
  const OccupationType =
    watch("occupation") || owner?.occupation || CardData?.owner?.occupation;

  const OccupationName = Occupations.filter(
    ({ id }) => id === OccupationType
  )[0]?.name;
  /*----------------gender config-----------------------*/

  useEffect(() => {
    if (companyAlias)
      dispatch(
        getGender({ companyAlias: companyAlias, enquiryId: enquiry_id })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias]);

  const radios = !_.isEmpty(gender)
    ? gender?.map(({ code, name }, index) => {
        return { name, val: String(index), value: code, code };
      })
    : [];

  const [radioValue, setRadioValue] = useState(watch("gender"));

  /*--------- marital status-----*/
  const maritalStatus = [
    { name: "Single", value: "1" },
    { name: "Married", value: "2" },
  ];

  const [radioValue2, setRadioValue2] = useState(
    watch("maritalStatus") ||
      (process.env.REACT_APP_BROKER === "OLA" ? "Single" : "Married")
  );

  /*----x---- marital status--x--*/
  const PinCode = watch("pincode");
  //get state-city
  useEffect(() => {
    if (PinCode?.length === 6 && companyAlias) {
      dispatch(
        Pincode({
          companyAlias: companyAlias,
          pincode: PinCode,
          enquiryId: enquiry_id,
        })
      );
    } else {
      dispatch(clear("pincode"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PinCode]);

  useEffect(() => {
    if (!_.isEmpty(pin)) {
      setValue("state", pin?.state?.state_name);
      setValue("stateId", pin?.state?.state_id);
    } else {
      setValue("state", "");
      setValue("stateId", "");
      setValue("city", "");
      setValue("cityId", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  // auto selecting if only one option is present.
  const city =
    watch("city") ||
    owner?.city ||
    CardData?.owner?.city ||
    (!_.isEmpty(pin?.city) &&
      pin?.city?.length === 1 &&
      pin?.city[0]?.city_name);

  useEffect(() => {
    if (city) {
      const city_id = pin?.city?.filter(({ city_name }) => city_name === city);
      !_.isEmpty(city_id)
        ? setValue("cityId", city_id[0]?.city_id)
        : setValue("cityId", owner?.cityId || CardData?.owner?.cityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, pin]);

  //pre-selection of toggle inputs (gender -male, marital status - married)
  const GenderIP = watch("gender");
  const MaritalIP = watch("maritalStatus");
  useEffect(() => {
    if (
      _.isEmpty(owner) &&
      _.isEmpty(CardData?.owner) &&
      !GenderIP &&
      !temp_data?.userProposal?.gender &&
      !temp_data?.userProposal?.genderName
    ) {
      !MaritalIP &&
        !temp_data?.userProposal?.maritalStatus &&
        (process.env.REACT_APP_BROKER === "OLA"
          ? setRadioValue2("Single")
          : setRadioValue2("Married"));
      Number(temp_data?.ownerTypeId) &&
        !_.isEmpty(radios) &&
        !GenderIP &&
        setRadioValue(
          !_.isEmpty(gender)
            ? !_.isEmpty(
                gender?.map(
                  ({ name }) =>
                    name.toLowerCase() === "male" || name.toLowerCase() === "m"
                )
              )
              ? gender?.filter(
                  ({ name }) =>
                    name.toLowerCase() === "male" || name.toLowerCase() === "m"
                )[0]?.code
              : ""
            : ""
        );
      let genderHidden = !_.isEmpty(gender)
        ? !_.isEmpty(
            gender?.map(
              ({ name }) =>
                name.toLowerCase() === "male" || name.toLowerCase() === "m"
            )
          )
          ? gender?.filter(
              ({ name }) =>
                name.toLowerCase() === "male" || name.toLowerCase() === "m"
            )[0]?.name
          : ""
        : "";
      genderHidden && setValue("genderName", genderHidden);
    }
    if (
      _.isEmpty(owner) &&
      _.isEmpty(CardData?.owner) &&
      !GenderIP &&
      // temp_data?.userProposal?.gender &&
      temp_data?.userProposal?.genderName
    ) {
      //Gender Index check
      !_.isEmpty(
        _.compact(
          gender?.map((item) =>
            item?.name.toLowerCase() ===
            temp_data?.userProposal?.genderName.toLowerCase()
              ? item
              : ""
          )
        )
      ) &&
        setRadioValue(
          _.compact(
            gender?.map((item) =>
              item?.name.toLowerCase() ===
              temp_data?.userProposal?.genderName.toLowerCase()
                ? item
                : ""
            )
          )[0]?.code
        );
    }
    if (
      _.isEmpty(owner) &&
      _.isEmpty(CardData?.owner) &&
      !!MaritalIP &&
      temp_data?.userProposal?.maritalStatus
    ) {
      setRadioValue2(temp_data?.userProposal?.maritalStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData.owner, radios, owner]);

  //ongrid prefill
  useEffect(() => {
    if (_.isEmpty(CardData?.owner) && _.isEmpty(owner)) {
      if (
        temp_data?.corporateVehiclesQuoteRequest?.journeyType === "adrila" &&
        temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
      ) {
        (temp_data?.userProposal?.firstName ||
          temp_data?.userProposal?.lastName) &&
          setValue(
            "firstName",
            `${
              temp_data?.userProposal?.firstName
                ? temp_data?.userProposal?.firstName
                : ""
            }${
              temp_data?.userProposal?.lastName
                ? temp_data?.userProposal?.lastName
                : ""
            }`
          );
      } else {
        temp_data?.userProposal?.firstName &&
          setValue("firstName", temp_data?.userProposal?.firstName);
        temp_data?.userProposal?.lastName &&
          setValue("lastName", temp_data?.userProposal?.lastName);
      }
      ((temp_data?.userProposal?.firstName &&
        temp_data?.userProposal?.lastName) ||
        temp_data?.userProposal?.fullName) &&
        setValue(
          "fullName",
          `${
            temp_data?.userProposal?.fullName
              ? temp_data?.userProposal?.fullName
              : `${temp_data?.userProposal?.firstName} ${temp_data?.userProposal?.lastName}`
          }`
        );
      // temp_data?.userProposal?.addressLine1 &&
      //   setValue("addressLine1", temp_data?.userProposal?.addressLine1);
      // temp_data?.userProposal?.addressLine2 &&
      //   setValue("addressLine2", temp_data?.userProposal?.addressLine2);
      // temp_data?.userProposal?.addressLine3 &&
      //   setValue("addressLine3", temp_data?.userProposal?.addressLine3);
      (temp_data?.userProposal?.addressLine1 ||
        temp_data?.userProposal?.addressLine2 ||
        temp_data?.userProposal?.addressLine3) &&
        setValue(
          "address",
          `${
            temp_data?.userProposal?.addressLine1
              ? temp_data?.userProposal?.addressLine1
              : ""
          }${
            temp_data?.userProposal?.addressLine2
              ? ` ${temp_data?.userProposal?.addressLine2}`
              : ""
          }${
            temp_data?.userProposal?.addressLine3
              ? ` ${temp_data?.userProposal?.addressLine3}`
              : ""
          }`
        );
      temp_data?.userProposal?.pincode &&
        setValue("pincode", temp_data?.userProposal?.pincode);
      temp_data?.userProposal?.dob &&
        setValue("dob", temp_data?.userProposal?.dob);
      temp_data?.userProposal?.genderName &&
        setValue("genderName", temp_data?.userProposal?.genderName);
      temp_data?.userProposal?.gender &&
        setValue("gender", temp_data?.userProposal?.gender);
      temp_data?.userProposal?.gstNumber &&
        setValue("gstNumber", temp_data?.userProposal?.gstNumber);
      temp_data?.userProposal?.panNumber &&
        setValue("panNumber", temp_data?.userProposal?.panNumber);
      temp_data?.userProposal?.occupation &&
        setValue("occupation", temp_data?.userProposal?.occupation);
      temp_data?.userProposal?.occupationName &&
        setValue("occupationName", temp_data?.userProposal?.occupationName);
    }
  }, [temp_data?.userProposal]);

  const prevOwnerType = watch("prevOwnerType");

  useEffect(() => {
    if (
      !_.isEmpty(CardData?.owner) &&
      !_.isEmpty(temp_data) &&
      prevOwnerType &&
      temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !==
        prevOwnerType
    ) {
      setTimeout(() => {
        //resetting fName and lName when prevOwnerType is diff from quote Page
        setValue("firstName", "");
        setValue("lastName", "");
        //Change the prevOwnerType
        setValue(
          "prevOwnerType",
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType
        );
      }, 0);
    }
  }, [temp_data, CardData, prevOwnerType]);

  //ACE lead SMS
  const mobileNoLead = watch("mobileNumber");

  const [memonisedNo, setMemonisedNo] = useState(false);
  useEffect(() => {
    if (
      process.env.REACT_APP_BROKER === "ACE" &&
      mobileNoLead &&
      mobileNoLead.length === 10 &&
      memonisedNo * 1 !== mobileNoLead * 1 &&
      CardData?.owner?.mobileNumber * 1 !== mobileNoLead * 1 &&
      owner?.mobileNumber * 1 !== mobileNoLead * 1
    ) {
      setMemonisedNo(mobileNoLead);
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "sms",
          domain: `http://${window.location.hostname}`,
          type: "Aceleadsms",
          mobileNo: mobileNoLead,
          productName: temp_data?.selectedQuote?.productName,
        })
      );
    }
  }, [mobileNoLead]);

  //splitting fullname
  const FullName = watch("fullName");
  useEffect(() => {
    if (FullName) {
      let FullnameCheck = FullName.split(" ");
      if (!_.isEmpty(FullnameCheck) && FullnameCheck?.length === 1) {
        let fname = FullnameCheck[0];
        setValue("firstName", fname);
      }
      if (!_.isEmpty(FullnameCheck) && FullnameCheck?.length > 1) {
        let FullnameLen = FullnameCheck?.length;
        let fname = FullnameCheck.slice(0, -1).join(" ");
        let lname = FullnameCheck.slice(-1)[0];
        setValue("firstName", fname);
        setValue("lastName", lname);
        console.log(fname, lname);
      } else {
        setValue("lastName", "");
      }
    } else {
      if (
        !_.isEmpty(temp_data?.userProposal) &&
        temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C"
      ) {
        setValue("firstName", "");
        setValue("lastName", "");
      }
    }
  }, [FullName]);

  const address = watch("address");

  // const splitter = (addressSplit) => {
  //   const n = 3;
  //   const result = [[], [], []];
  //   const wordsPerLine = address && Math.ceil(addressSplit.length / 3);

  //   for (let line = 0; line < n; line++) {
  //     for (let i = 0; i < wordsPerLine; i++) {
  //       const value = addressSplit[i + line * wordsPerLine];
  //       if (!value) continue; //avoid adding "undefined" values
  //       result[line].push(value);
  //     }
  //   }
  //   return result;
  // };

  // useLayoutEffect(() => {
  //   const result = address && splitter(_.words(address));
  //   if (!_.isEmpty(result) && result) {
  //     result.forEach((el, index) =>
  //       setValue(`addressLine${index + 1}`, !_.isEmpty(el) ? el.join(" ") : "")
  //     );
  //   } else {
  //     setValue("addressLine1", "");
  //     setValue("addressLine2", "");
  //     setValue("addressLine3", "");
  //   }
  // }, [address]);

  return (
    <Form onSubmit={handleSubmit(onSubmitOwner)} autoComplete="none">
      <Row
        style={{
          margin: lessthan768
            ? "-60px -30px 20px -30px"
            : "-60px -20px 20px -30px",
        }}
        className="p-2"
      >
        {Number(temp_data?.ownerTypeId) === 1 ? (
          <>
            {/* <Col xs={12} sm={12} md={12} lg={6} xl={4} className="w-100">
              <div className="py-2 w-100">
                <FormGroupTag>{"First Name"}</FormGroupTag>
                <div className="d-flex w-100 fname">
                  <div
                    style={{ maxWidth: "100%", width: "100%" }}
                    className="fname1"
                  >
                    <Form.Control
                      autoComplete="none"
                      ref={register}
                      errors={errors.firstName}
                      name="firstName"
                      type="text"
                      onInput={(e) =>
                        (e.target.value =
                          e.target.value.length <= 1
                            ? ("" + e.target.value).toUpperCase()
                            : e.target.value)
                      }
                      maxLength="50"
                      // minlength="2"
                      placeholder={"Enter First Name"}
                      size="sm"
                    />
                    {!!errors?.firstName && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors?.firstName?.message}
                      </ErrorMsg>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>{"Last Name"}</FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  type="text"
                  placeholder={"Enter Last Name"}
                  name="lastName"
                  maxLength="50"
                  minlength="1"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value).toUpperCase()
                        : e.target.value)
                  }
                  errors={errors.lastName}
                  size="sm"
                />
                {!!errors?.lastName && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.lastName?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>  */}
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>{"Full Name"}</FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  type="text"
                  placeholder={"Enter Full Name"}
                  name="fullName"
                  maxLength="100"
                  minlength="1"
                  ref={register}
                  readOnly={allFieldsReadOnly}
                  onBlur={(e) => (e.target.value = e.target.value.trim())}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(/\s\s/gi, " ")
                        : e.target.value.replace(/\s\s/gi, " "))
                  }
                  errors={errors.lastName || errors.firstName}
                  size="sm"
                />
                {!!errors?.lastName || !!errors?.firstName ? (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.firstName?.message
                      ? errors?.firstName?.message
                      : errors?.lastName?.message}
                  </ErrorMsg>
                ) : (
                  <Form.Text className="text-muted">
                    <text style={{ color: "#bdbdbd" }}>
                      (Full Name as mentioned in RC copy)
                    </text>
                  </Form.Text>
                )}
              </div>
              <input type="hidden" ref={register} name="firstName" />
              <input type="hidden" ref={register} name="lastName" />
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>
                  {Number(temp_data?.ownerTypeId) === 1
                    ? "First Name"
                    : "Company Name"}
                </FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  type="text"
                  allFieldsReadOnly={allFieldsReadOnly}
                  placeholder={
                    Number(temp_data?.ownerTypeId) === 1
                      ? "Enter First Name"
                      : "Enter Company Name"
                  }
                  name="firstName"
                  maxLength="50"
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value).toUpperCase()
                        : e.target.value)
                  }
                  errors={errors?.firstName}
                  size="sm"
                />
                {!!errors?.firstName && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.firstName?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            {fields.includes("representativeName") && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="w-100">
                <div className="py-2 w-100">
                  <FormGroupTag>
                    {Number(temp_data?.ownerTypeId) === 1
                      ? "Last Name"
                      : "Representative Name"}
                  </FormGroupTag>
                  <div className="d-flex w-100 fname">
                    <div
                      style={{ maxWidth: "100%", width: "100%" }}
                      className="fname1"
                    >
                      <Form.Control
                        ref={register}
                        errors={errors.lastName}
                        autoComplete="none"
                        name="lastName"
                        allFieldsReadOnly={allFieldsReadOnly}
                        type="text"
                        onInput={(e) =>
                          (e.target.value =
                            e.target.value.length <= 1
                              ? ("" + e.target.value).toUpperCase()
                              : e.target.value)
                        }
                        maxLength="50"
                        // minlength="2"
                        placeholder={
                          Number(temp_data?.ownerTypeId) === 1
                            ? "Enter Last Name"
                            : "Enter Name"
                        }
                        size="sm"
                      />
                      {!!errors?.lastName && (
                        <ErrorMsg fontSize={"12px"}>
                          {errors?.lastName?.message}
                        </ErrorMsg>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </>
        )}
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2">
            <FormGroupTag>Mobile Number</FormGroupTag>
            <Form.Control
              name="mobileNumber"
              ref={register}
              type="tel"
              autoComplete="none"
              placeholder="Enter Mobile Number"
              errors={errors?.mobileNumber}
              size="sm"
              onKeyDown={numOnly}
              readOnly={allFieldsReadOnly}
              maxLength="10"
            />
            {!!errors?.mobileNumber && (
              <ErrorMsg fontSize={"12px"}>
                {errors?.mobileNumber?.message}
              </ErrorMsg>
            )}
          </div>
        </Col>
        {(process.env.REACT_APP_BROKER !== "OLA" ||
          fields.includes("email")) && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>Email ID</FormGroupTag>
              <Form.Control
                type="email"
                autoComplete="none"
                placeholder="Enter Email Id"
                size="sm"
                name="email"
                maxLength="50"
                readOnly={allFieldsReadOnly}
                ref={register}
                errors={errors?.email}
              />
              {!!errors?.email && (
                <ErrorMsg fontSize={"12px"}>{errors?.email?.message}</ErrorMsg>
              )}
            </div>
            <input type="hidden" ref={register} name="officeEmail" />
          </Col>
        )}
        {fields.includes("dob") && Number(temp_data?.ownerTypeId) === 1 && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <StyledDatePicker>
              <div className="py-2 dateTimeOne">
                <FormGroupTag>Date of Birth</FormGroupTag>
                <Controller
                  control={control}
                  name="dob"
                  render={({ onChange, onBlur, value, name }) => (
                    <DateInput
                      minDate={false}
                      maxDate={AdultCheck}
                      value={value}
                      name={name}
                      onChange={onChange}
                      readOnly={allFieldsReadOnly}
                      ref={register}
                      selected={
                        DOB || owner?.dob || CardData?.owner?.dob
                          ? DateUtil(DOB || owner?.dob || CardData?.owner?.dob)
                          : false
                      }
                    />
                  )}
                />
                {!!errors?.dob && (
                  <ErrorMsg fontSize={"12px"}>{errors?.dob?.message}</ErrorMsg>
                )}
              </div>
            </StyledDatePicker>
          </Col>
        )}
        {fields.includes("gender") && Number(temp_data?.ownerTypeId) === 1 && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <FormGroupTag style={{ paddingTop: "10px" }}>Gender</FormGroupTag>
            <div className="" style={{ width: "100%", paddingTop: "2px" }}>
              <ButtonGroupTag toggle style={{ width: "100%" }}>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    style={{
                      minWidth: "fill-available",
                      width: "fill-available",
                      minHeight: "32px",
                    }}
                    key={idx}
                    className={
                      radio.val === "0"
                        ? `mb-2 mr-4 index-key${idx}`
                        : `mb-2 index-key${idx}`
                    }
                    type="radio"
                    variant="secondary"
                    readOnly={allFieldsReadOnly}
                    ref={register}
                    size="sm"
                    tabIndex={"0"}
                    id={`index-key${idx}`}
                    onKeyDown={(e) => {
                      if (e.keyCode === 32 && !allFieldsReadOnly) {
                        e.preventDefault();
                        document.getElementById(`index-key${idx}`) &&
                          document.getElementById(`index-key${idx}`).click();
                      }
                    }}
                    name="gender1"
                    value={radio.code}
                    checked={radioValue === radio.code}
                    onInput={() => setValue("genderName", radio.name)}
                    onChange={(e) => {
                      !allFieldsReadOnly && setRadioValue(e.target.value);
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroupTag>
            </div>
            <input
              type="hidden"
              name="gender"
              value={radioValue}
              ref={register}
            />
            <input
              type="hidden"
              name="genderName"
              // value={genderName}
              ref={register}
            />
            {!!errors?.gender && (
              <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                {errors?.gender?.message}
              </ErrorMsg>
            )}
          </Col>
        )}
        {/*Tag to keep track of the previous owner type*/}
        <input
          type="hidden"
          name={"prevOwnerType"}
          value={temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType}
          ref={register}
        />
        {fields.includes("panNumber") && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>
                {!(
                  temp_data?.selectedQuote?.companyAlias ===
                    "liberty_videocon" &&
                  (type === "car" || type === "bike") &&
                  !token &&
                  Number(temp_data?.ownerTypeId) === 2
                )
                  ? "PAN No (Optional)"
                  : "PAN No"}
              </FormGroupTag>
              <Form.Control
                type="text"
                autoComplete="none"
                placeholder="Enter PAN No"
                size="sm"
                ref={register}
                name="panNumber"
                readOnly={allFieldsReadOnly}
                maxLength="10"
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value)
                    .replace(/[^A-Za-z0-9]/gi, "")
                    .toUpperCase())
                }
              />
              {errors?.panNumber ? (
                <ErrorMsg fontSize={"12px"}>
                  {errors?.panNumber?.message}
                </ErrorMsg>
              ) : (
                <Form.Text className="text-muted">
                  <text style={{ color: "#bdbdbd" }}>e.g AAAPL1234C</text>
                </Form.Text>
              )}
            </div>
          </Col>
        )}
        {fields.includes("gstNumber") && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>{`GSTIN${
                temp_data?.selectedQuote?.companyAlias !== "sbi" &&
                !(
                  temp_data?.selectedQuote?.companyAlias ===
                    "liberty_videocon" &&
                  (type === "car" || type === "bike") &&
                  temp_data?.ownerTypeId === 2 &&
                  !token
                )
                  ? " (Optional)"
                  : ""
              }`}</FormGroupTag>
              <Form.Control
                type="text"
                autoComplete="none"
                placeholder="Enter GSTIN"
                size="sm"
                ref={register}
                name="gstNumber"
                readOnly={allFieldsReadOnly}
                maxLength={"15"}
                onInput={(e) =>
                  (e.target.value = ("" + e.target.value)
                    .replace(/[^A-Za-z0-9]/gi, "")
                    .toUpperCase())
                }
              />
              {errors?.gstNumber ? (
                <ErrorMsg fontSize={"12px"}>
                  {errors?.gstNumber?.message}
                </ErrorMsg>
              ) : (
                <Form.Text className="text-muted">
                  <text style={{ color: "#bdbdbd" }}>e.g 18AABCU9603R1ZM</text>
                </Form.Text>
              )}
            </div>
          </Col>
        )}
        {fields.includes("occupation") && Number(temp_data?.ownerTypeId) === 1 && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2 fname">
              <FormGroupTag>Occupation Type</FormGroupTag>
              <Form.Control
                as="select"
                autoComplete="none"
                size="sm"
                ref={register}
                name="occupation"
                readOnly={allFieldsReadOnly}
                className="title_list"
                style={{ cursor: "pointer" }}
              >
                (
                <option
                  selected={
                    process.env.REACT_APP_BROKER === "ACE" ? false : true
                  }
                  value={"@"}
                >
                  Select
                </option>
                )
                {Occupations.map(({ name, id, priority }, index) => (
                  <option
                    style={{ cursor: "pointer" }}
                    selected={
                      CardData?.owner?.occupation === id ||
                      (process.env.REACT_APP_BROKER === "OLA" &&
                        name === "Business / Professional Services") ||
                      (process.env.REACT_APP_BROKER === "ACE" &&
                        (priority * 1 === 1 ||
                          name === "others" ||
                          name === "Others" ||
                          name === "Other" ||
                          name === "other"))
                    }
                    value={id}
                  >
                    {name}
                  </option>
                ))}
              </Form.Control>
            </div>
            {watch("occupation") && (
              <input
                type="hidden"
                ref={register}
                name="occupationName"
                value={OccupationName}
              />
            )}
            {!!errors?.occupation && (
              <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                {errors?.occupation?.message}
              </ErrorMsg>
            )}
          </Col>
        )}
        {fields.includes("maritalStatus") &&
          Number(temp_data?.ownerTypeId) === 1 && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <FormGroupTag style={{ paddingTop: "10px" }}>
                Marital Status
              </FormGroupTag>
              <div className="" style={{ width: "100%", paddingTop: "2px" }}>
                <ButtonGroupTag toggle style={{ width: "100%" }}>
                  {maritalStatus.map((item, idx) => (
                    <ToggleButton
                      style={{
                        minWidth: "fill-available",
                        width: "fill-available",
                        minHeight: "32px",
                      }}
                      key={idx}
                      className={item.value === "1" ? "mb-2 mr-4" : "mb-2"}
                      type="radio"
                      variant="secondary"
                      ref={register}
                      readOnly={allFieldsReadOnly}
                      size="sm"
                      name="maritalS"
                      tabIndex={"0"}
                      id={`index-key2${idx}`}
                      onKeyDown={(e) => {
                        if (e.keyCode === 32 && !allFieldsReadOnly) {
                          e.preventDefault();
                          document.getElementById(`index-key2${idx}`) &&
                            document.getElementById(`index-key2${idx}`).click();
                        }
                      }}
                      value={item.name}
                      checked={radioValue2 === item.name}
                      onChange={(e) => {
                        !allFieldsReadOnly && setRadioValue2(e.target.value);
                      }}
                    >
                      {item.name}
                    </ToggleButton>
                  ))}
                </ButtonGroupTag>
              </div>
              <input
                type="hidden"
                name="maritalStatus"
                value={radioValue2}
                ref={register}
              />
              {!!errors?.maritalStatus && (
                <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                  {errors?.maritalStatus.message}
                </ErrorMsg>
              )}
            </Col>
          )}
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className=" mt-1"
          style={{ marginBottom: "-10px" }}
        >
          <p
            style={{
              color: Theme?.proposalHeader?.color
                ? Theme?.proposalHeader?.color
                : "#1a5105",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Communication Address
          </p>
        </Col>
        {true && (
          <>
            {" "}
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
              <div className="py-2">
                <FormGroupTag>Address</FormGroupTag>
                <Form.Control
                  as="textarea"
                  rows={2}
                  autoComplete="none"
                  // placeholder="e.g C/58 Jeet Nagar, Versova Bowla"
                  name="address"
                  maxLength={"120"}
                  readOnly={allFieldsReadOnly}
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors?.addressLine1}
                  size="sm"
                />
                {errors?.addressLine1 ||
                errors?.addressLine2 ||
                errors?.addressLine3 ||
                errors?.address ? (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.addressLine1?.message ||
                      errors?.addressLine2?.message ||
                      errors?.addressLine3?.message ||
                      errors?.address?.message}
                  </ErrorMsg>
                ) : (
                  <Form.Text className="text-muted">
                    <text style={{ color: "#bdbdbd" }}>
                      {`(${(watch("address") || "").length}/120)`}
                    </text>
                  </Form.Text>
                )}
              </div>
            </Col>
            <input
              type="hidden"
              ref={register}
              name="addressLine1"
              value={watch("address")}
            />
            {/* <input type="hidden" ref={register} name="addressLine2" />
            <input type="hidden" ref={register} name="addressLine3" /> */}
          </>
        )}
        {false && (
          <>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Address Line 1</FormGroupTag>
                <Form.Control
                  type="text"
                  autoComplete="none"
                  placeholder="Enter Address Line 1"
                  name="addressLine1"
                  readOnly={allFieldsReadOnly}
                  maxLength="50"
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors.addressLine1}
                  size="sm"
                />
                {!!errors?.addressLine1 && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.addressLine1?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Address Line 2</FormGroupTag>
                <Form.Control
                  type="text"
                  autoComplete="none"
                  placeholder="Enter Address Line 2"
                  name="addressLine2"
                  readOnly={allFieldsReadOnly}
                  maxLength="50"
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors.addressLine2}
                  size="sm"
                />
                {!!errors?.addressLine2 && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.addressLine2?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Address Line 3 (optional)</FormGroupTag>
                <Form.Control
                  type="text"
                  autoComplete="none"
                  placeholder="Enter Address Line 3"
                  name="addressLine3"
                  readOnly={allFieldsReadOnly}
                  maxLength="50"
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors.addressLine3}
                  size="sm"
                />
                {!!errors?.addressLine3 && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.addressLine3?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
          </>
        )}

        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2">
            <FormGroupTag>Pincode</FormGroupTag>
            <Form.Control
              name="pincode"
              autoComplete="none"
              ref={register}
              readOnly={allFieldsReadOnly}
              type="tel"
              placeholder="Enter Pincode"
              errors={errors?.pincode}
              size="sm"
              onKeyDown={numOnly}
              maxLength="6"
            />
            {!!errors?.pincode && (
              <ErrorMsg fontSize={"12px"}>{errors?.pincode?.message}</ErrorMsg>
            )}
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2">
            <FormGroupTag>State</FormGroupTag>
            <Form.Control
              name="state"
              ref={register}
              type="text"
              placeholder="Select State"
              style={{ cursor: "not-allowed" }}
              errors={errors?.state}
              size="sm"
              readOnly
            />
            {!!errors?.state && (
              <ErrorMsg fontSize={"12px"}>{errors?.state?.message}</ErrorMsg>
            )}
          </div>
          <input
            name="stateId"
            ref={register}
            type="hidden"
            value={pin?.state?.state_id}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2 fname">
            <FormGroupTag>City</FormGroupTag>
            <Form.Control
              as="select"
              readOnly={allFieldsReadOnly}
              size="sm"
              ref={register}
              name={`city`}
              errors={errors?.city}
              style={{ cursor: "pointer" }}
              // value={owner?.city || CardData?.owner?.city}
            >
              <option selected={true} value={"@"}>
                Select
              </option>

              {pin?.city?.map(({ city_name, city_id }, index) => (
                <option
                  selected={
                    CardData?.owner?.city?.trim() === city_name?.trim() ||
                    (pin?.city?.length === 1 &&
                      !CardData?.owner?.city?.trim()) ||
                    (_.isEmpty(CardData?.owner) &&
                      _.isEmpty(owner) &&
                      temp_data?.userProposal?.city &&
                      temp_data?.userProposal?.city.trim() ===
                        city_name?.trim())
                  }
                  value={city_name}
                >
                  {city_name}
                </option>
              ))}
            </Form.Control>
            {!!errors?.city && (
              <ErrorMsg fontSize={"12px"}>{errors?.city?.message}</ErrorMsg>
            )}
          </div>
          <input name="cityId" ref={register} type="hidden" />
        </Col>
        <Col
          sm={12}
          lg={12}
          md={12}
          xl={12}
          className="d-flex justify-content-center mt-5 mx-auto"
        >
          <Button
            type="submit"
            buttonStyle="outline-solid"
            className=""
            hex1={
              Theme?.proposalProceedBtn?.hex1
                ? Theme?.proposalProceedBtn?.hex1
                : "#4ca729"
            }
            hex2={
              Theme?.proposalProceedBtn?.hex2
                ? Theme?.proposalProceedBtn?.hex2
                : "#4ca729"
            }
            borderRadius="5px"
            color="white"
            onClick={() =>
              navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50])
            }
          >
            <text
              style={{
                fontSize: "15px",
                padding: "-20px",
                margin: "-20px -5px -20px -5px",
                fontWeight: "400",
              }}
            >
              {Number(temp_data?.ownerTypeId) === 1 && conditionChk
                ? `Proceed to Nominee${!lessthan376 ? " Details" : ""}`
                : `Proceed to Vehicle${!lessthan376 ? " Details" : ""}`}
            </text>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
`;

export default OwnerCard;
