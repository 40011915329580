import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { reloadPage } from "utils";
import swal from "sweetalert";
export const AdminPanel = () => {
  const history = useHistory();
  const redirectTo = () => {
    swal({
      title: "Confirm Action",
      text: "Confirm redirection to admin panel",
      icon: "info",

      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Confirm",
          value: "confirm",
        },
      },
      dangerMode: true,
    }).then((caseValue) => {
      switch (caseValue) {
        case "confirm":
          reloadPage(
            `${process.env.REACT_APP_API_BASE_URL.substring(
              0,
              process.env.REACT_APP_API_BASE_URL.length - 4
            )}/admin`
          );
          break;
        default:
          history.replace("/");
      }
    });
  };

  useEffect(() => {
    redirectTo();
  }, []);
  return <></>;
};
