import React, { useState, useEffect } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import styled, { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { Bounce } from "react-toastify";
import "./toaster.scss";
import { useMediaPredicate } from "react-media-hook";
toast.configure();

// toast component for edit details toaster

function ToasterOla({
	callToaster,
	setCall,
	setEdit,
	Theme,
	setToasterShown,
	setShareQuotesFromToaster,
}) {
	const lessthan993 = useMediaPredicate("(max-width: 993px)");
	const dismissAll = () => toast.dismiss();
	const Share = () => {
		setEdit(true);
		setShareQuotesFromToaster(true);
		dismissAll();
	};
	const notify = () => {
		toast(
			<ToastMessageContainer Theme={Theme}>
				{/* <ContactImg
					src={"/assets/images/documentGif.gif"}
					alt="pointer"
				></ContactImg> */}
				<ToastMessage>
					Would you like to take documents from customer?
					<div style={{ fontSize: "12px" }}>
						(eg. RC copy, previous policy details){" "}
					</div>
				</ToastMessage>{" "}
				<ButtonContainer>
					<ToastButton onClick={() => Share()} Theme={Theme}>
						Yes
					</ToastButton>
					<ToastButton onClick={() => dismissAll()} Theme={Theme}>
						No
					</ToastButton>
				</ButtonContainer>{" "}
			</ToastMessageContainer>,
			{
				transition: Bounce,
				className: "toasterStyle",
				position: "top-left",
				autoClose: 10000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,

				style: {
					position: "relative",
					top: Theme?.QuoteBorderAndFont?.toasterTop
						? Theme?.QuoteBorderAndFont?.toasterTop
						: lessthan993
						? "250px"
						: "120px",
					left: lessthan993 ? "0px" : "70px",
				},
			}
		); // Default
	};
	useEffect(() => {
		if (callToaster) {
			notify();
			setCall(false);
			setToasterShown(false);
		}
	}, [callToaster]);
	return (
		<div>
			<GlobalStyle Theme={Theme} />
		</div>
	);
}

export default ToasterOla;

const GlobalStyle = createGlobalStyle`
body {
	.Toastify__progress-bar-theme--light {
		background: ${({ Theme }) =>
			`${
				Theme?.QuoteCard?.color
					? `${Theme?.QuoteCard?.color} !important`
					: "#bdd400 !important"
			}`};
	}
}
`;

const ToastMessageContainer = styled.div`
	width: 100%;
	font-family: ${({ Theme }) =>
		Theme?.regularFont?.fontFamily || " Inter-Regular"};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.toasterStyle {
		.Toastify__progress-bar-theme--light {
			background: ${({ Theme }) =>
				`${
					Theme?.QuoteCard?.color
						? `${Theme?.QuoteCard?.color} !important`
						: "#bdd400 !important"
				}`};
		}
	}
`;

const ToastButton = styled.div`
	font-family: ${({ Theme }) =>
		Theme?.regularFont?.fontFamily || " Inter-Medium"};
	background-color: ${({ Theme }) => Theme?.QuotePopups?.color || "#bdd400"};
	border: ${({ Theme }) => Theme?.QuotePopups?.border || "1px solid #bdd400"};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ Theme }) => Theme?.QuoteBorderAndFont?.fontColor || "#000"};
	flex-direction: column;
	padding: 5px 10px;
	margin-top: 5px;
	border-radius: ${({ Theme }) =>
		Theme?.QuoteBorderAndFont?.borderRadius || "30px"};
`;

const ToastMessage = styled.div`
	font-family: ${({ Theme }) =>
		Theme?.regularFont?.fontFamily || " Inter-Regular"};
	color: black;
	font-size: 14px;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-evenly;
`;

const ContactImg = styled.img`
	float: left;
	height: 40px;
`;
