export const redirection = (type, broker, url, token, enquiry_id) => {
  switch (type) {
    case "car":
      if (broker === "ABIBL") {
        //uat
        if (url === "https://apiabibl-carbike.fynity.in/api") {
          return `https://abibl-car-bike.fynity.in/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://apiabibl-preprod-carbike.fynity.in/api") {
          return `https://abibl-preprod-car-bike.fynity.in/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //prod
        if (url === "https://apiprod-abibl.fynity.in/api") {
          return `https://carprod-abibl.fynity.in/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "ACE") {
        //uat
        if (url === "https://apicar-aceinsurance.fynity.in/api") {
          return `https://carbike-uat-aceinsurance.fynity.in/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://uatmotorapis.aceinsurance.com/api") {
          return `https://uatcar.aceinsurance.com/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //prod
        if (url === "https://motorapis.aceinsurance.com/api") {
          return `https://car.aceinsurance.com/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "EPOCH") {
        //uat
        if (url === "https://uatapimotor.policylo.com/api") {
          return `https://uatcar.policylo.com/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "RB") {
        //uat
        if (url === "https://apiuatmotor.rbstaging.in/api") {
          return `https://uatcar.rbstaging.in/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://apipreprodmotor.rbstaging.in/api") {
          return `https://prepodcar.rbstaging.in/car/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
    case "bike":
      if (broker === "ABIBL") {
        //uat
        if (url === "https://apiabibl-carbike.fynity.in/api") {
          return `https://abibl-car-bike.fynity.in/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://apiabibl-preprod-carbike.fynity.in/api") {
          return `https://abibl-preprod-car-bike.fynity.in/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //prod
        if (url === "https://apiprod-abibl.fynity.in/api") {
          return `https://carprod-abibl.fynity.in/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "ACE") {
        //uat
        if (url === "https://apicar-aceinsurance.fynity.in/api") {
          return `https://carbike-uat-aceinsurance.fynity.in/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://uatmotorapis.aceinsurance.com/api") {
          return `https://uatbike.aceinsurance.com/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //prod
        if (url === "https://motorapis.aceinsurance.com/api") {
          return `https://bike.aceinsurance.com/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "EPOCH") {
        //uat
        if (url === "https://uatapimotor.policylo.com/api") {
          return `https://uatcar.policylo.com/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "RB") {
        //uat
        if (url === "https://apiuatmotor.rbstaging.in/api") {
          return `https://uatcar.rbstaging.in/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://apipreprodmotor.rbstaging.in/api") {
          return `https://prepodcar.rbstaging.in/bike/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
    case "cv":
      if (broker === "ABIBL") {
        //uat
        if (url === "https://apiabibl-carbike.fynity.in/api") {
          return `https://abibl-car-bike.fynity.in/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://apiabibl-preprod-carbike.fynity.in/api") {
          return `https://abibl-preprod-car-bike.fynity.in/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //prod
        if (url === "https://apiprod-abibl.fynity.in/api") {
          return `https://carprod-abibl.fynity.in/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "ACE") {
        //uat
        if (url === "https://apicar-aceinsurance.fynity.in/api") {
          return `https://carbike-uat-aceinsurance.fynity.in/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://uatmotorapis.aceinsurance.com/api") {
          return `https://uatpcv.aceinsurance.com/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //prod
        if (url === "https://motorapis.aceinsurance.com/api") {
          return `https://pcv.aceinsurance.com/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "EPOCH") {
        //uat
        if (url === "https://uatapimotor.policylo.com/api") {
          return `https://uatcar.policylo.com/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
      if (broker === "RB") {
        //uat
        if (url === "https://apiuatmotor.rbstaging.in/api") {
          return `https://uatcar.rbstaging.in/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
        //preprod
        if (url === "https://apipreprodmotor.rbstaging.in/api") {
          return `https://prepodcar.rbstaging.in/cv/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`;
        }
      }
    default:
      break;
  }
};
