import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { range } from "lodash";
import { getYear, getMonth } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.scss";
import moment from "moment";

const DateInputTwo = ({
  onChange,
  value,
  minDate,
  maxDate,
  name,
  ref,
  readOnly,
  reviewDate,
  showMonthYearPicker,
  expiryDate,
  id,
  autoFocus,
  filterDate,
  onSubmit,
  monthsShown,
  selected
}) => {
  const [startDate, setStartDate] = useState("");
  const years = range(1940, getYear(new Date()) + 6, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <>
      {!monthsShown ? (
        <DatePicker
          className={`date ${reviewDate ? "curvedDate" : ""} ${
            expiryDate ? "expiryDate" : ""
          } ${filterDate ? "filterDate" : ""}`}
          showPopperArrow={false}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              className="date-header"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="date-button-left"
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                className="date-button-right"
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">>"}
              </button>
            </div>
          )}
          dateFormat="dd/MM/yyyy"
          showMonthYearPicker={showMonthYearPicker}
          onChange={(date) => {
            setStartDate(date);
            onChange(
              showMonthYearPicker
                ? moment(date).format("MM-YYYY")
                : moment(date).format("DD-MM-YYYY")
            );

            onSubmit && onSubmit(moment(date).format("DD-MM-YYYY"));
          }}
          selected={selected ? selected : startDate}
          value={value || ""}
          autoComplete="off"
          minDate={minDate}
          maxDate={maxDate}
          name={name || "date"}
          ref={ref}
          onFocus={(e) => e.target.blur()} // <--- Adding this
          autocomplete="off"
          readOnly={readOnly ? true : false}
          id={id}
          autoFocus={autoFocus}
        />
      ) : (
        <>
          <DatePicker
            className={`date ${reviewDate ? "curvedDate" : ""} ${
              expiryDate ? "expiryDate" : ""
            } ${filterDate ? "filterDate" : ""}`}
            selected={selected ? selected : startDate}
            onChange={(date) => {
              setStartDate(date);
              onChange(
                showMonthYearPicker
                  ? moment(date).format("MM-YYYY")
                  : moment(date).format("DD-MM-YYYY")
              );

              onSubmit && onSubmit(moment(date).format("DD-MM-YYYY"));
            }}
            monthsShown={monthsShown}
            dateFormat={"dd-MM-yyyy"}
            showYearDropdownvalue={value || ""}
            autoComplete="off"
            minDate={minDate}
            maxDate={maxDate}
            name={name || "date"}
            ref={ref}
            autocomplete="off"
            readOnly={readOnly ? true : false}
            id={id}
			onFocus={e => e.target.blur()} // <--- Adding this
            autoFocus={autoFocus}
            showMonthYearPicker={showMonthYearPicker}
            showPopperArrow={false}
          />
        </>
      )}
    </>
  );
};

export default DateInputTwo;
