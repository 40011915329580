import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { useMediaPredicate } from "react-media-hook";
import Popup from "../../../../components/Popup/Popup";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { set_temp_data } from "modules/Home/home.slice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { setTempData } from "../../filterConatiner/quoteFilter.slice";
import "./policyTypePopup.css";
import { differenceInDays } from "date-fns";
import { CancelAll } from "modules/quotesPage/quote.slice";
import moment from "moment";
import Drawer from "@mui/material/Drawer";
import { toDate } from "utils";

const PolicyTypePopup = ({
  show,
  onClose,
  setPolicy,
  policyType,
  setPreviousPopup,
  type,
  setToasterPolicyChange,
}) => {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    // resolver: yupResolver(mobileValidation),
    // mode: "onBlur",
    // reValidateMode: "onBlur",
  });

  const todaysDate = moment().format("DD-MM-YYYY");
  const lessthan993 = useMediaPredicate("(max-width: 993px)");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const { tempData } = useSelector((state) => state.quoteFilter);
  const dispatch = useDispatch();
  const location = useLocation();
  const { temp_data } = useSelector((state) => state.home);

  const handleChange = () => {};
  const [selected, setSelected] = useState("Comprehensive");

  let a = temp_data?.expiry;
  let b = moment().format("DD-MM-YYYY");
  let diffDays = a && b && differenceInDays(toDate(b), toDate(a));

  const getNewNcb = (ncb) => {
    switch (ncb) {
      case "0%":
        return "20%";
      case "20%":
        return "25%";
      case "25%":
        return "35%";
      case "35%":
        return "45%";
      case "45%":
        return "50%";
      case "50%":
        return "50%";
      default:
        return "20%";
    }
  };

  const onSubmit = (data) => {
    if (data !== tempData?.policyType) {
      dispatch(CancelAll(true));
    }
    setPolicy(data);
    dispatch(
      setTempData({
        policyType:
          data === "Not sure"
            ? "Not sure"
            : data === "Short Term"
            ? "Comprehensive"
            : data,
      })
    );

    if (data === "Third-party") {
      dispatch(
        set_temp_data({
          ncb: "0%",
          isToastShown: "Y",
          prevYearNcb: "0%",
          newNcb: "0%",
          noClaimMade: temp_data?.noClaimMade ? temp_data?.noClaimMade : true,
          breakIn:
            differenceInDays(
              toDate(b),
              toDate(
                temp_data?.expiry === "New" ? todaysDate : temp_data?.expiry
              )
            ) > 0
              ? true
              : false,
          expiry: temp_data?.expiry === "New" ? todaysDate : temp_data?.expiry,
          ...(temp_data?.expiry === "New" && { prevIc: "Not selected" }),
          prevShortTerm: 0,
        })
      );
      if (temp_data?.expiry === "New") {
        setToasterPolicyChange(true);
      }
    } else if (
      data === "Comprehensive" ||
      data === "Own-damage" ||
      data === "Short Term"
    ) {
      dispatch(
        set_temp_data({
          ncb:
            temp_data?.ncb && temp_data?.ncb.replace(/%/g, "") * 1
              ? temp_data?.ncb
              : "0%",
          isToastShown: "Y",
          newNcb: temp_data?.newCar
            ? "0%"
            : temp_data?.noClaimMade && !temp_data?.carOwnership
            ? data === "Short Term"
              ? temp_data?.ncb && temp_data?.ncb.replace(/%/g, "") * 1
                ? temp_data?.ncb
                : "0%"
              : temp_data?.newNcb && temp_data?.newNcb.replace(/%/g, "") * 1
              ? temp_data?.prevShortTerm * 1
                ? getNewNcb(temp_data?.ncb)
                : temp_data?.newNcb
              : "20%"
            : "0%",
          breakIn:
            differenceInDays(
              toDate(b),
              toDate(
                temp_data?.expiry === "New" ? todaysDate : temp_data?.expiry
              )
            ) > 0
              ? true
              : false,
          expiry: temp_data?.expiry === "New" ? todaysDate : temp_data?.expiry,
          ...(temp_data?.expiry === "New" && { prevIc: "Not selected" }),
          prevShortTerm: data === "Short Term" ? 1 : 0,
        })
      );
      if (temp_data?.expiry === "New") {
        setToasterPolicyChange(true);
      }
    } else if (data === "Not sure") {
      dispatch(
        set_temp_data({
          ncb: "0%",
          isToastShown: "Y",
          expiry: "New",
          noClaimMade: true,
          policyExpired: true,
          prevYearNcb: "0%",
          prevIc: "New",
          prevIcFullName: "New",
          leadJourneyEnd: true,
          newNcb: "0%",
          breakIn: true,
          prevShortTerm: 0,
        })
      );
    }
    dispatch(CancelAll(false));
    onClose(false);
  };

  //---drawer for mobile

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);

  const bundledPolicy =
    temp_data?.odOnly &&
    new Date().getFullYear() -
      Number(temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)) > 1;

  const content = (
    <>
      <ContentWrap>
        <ContentTitle>What type of policy did you buy last year?</ContentTitle>
        <ContentSubTitle>
          It will help us to provide accurate quotes for you
        </ContentSubTitle>
        <ExpertForm>
          <form id="confirmPolicyForm" action="">
            <div className="homeInsuInput">
              <div className="homeInsuInputWrap">
                <Row>
                  <Col
                    lg={
                      temp_data?.regDate &&
                      Number(
                        temp_data?.regDate?.slice(
                          temp_data?.regDate?.length - 4
                        )
                      ) <
                        new Date().getFullYear() - 1 &&
                      temp_data?.odOnly
                        ? 3
                        : temp_data?.odOnly
                        ? 4
                        : ["ACE", "FYNTUNE", "OLA"].includes(
                            process.env.REACT_APP_BROKER
                          ) && temp_data?.journeyCategory === "PCV"
                        ? 3
                        : 4
                    }
                    sm={3}
                  >
                    <label
                      className="panel-heading ratioButton policyTypeRadio"
                      style={{ marginTop: lessthan993 ? "10px" : "" }}
                    >
                      <input
                        type="radio"
                        name="confirmPolicy"
                        value="Bundled Policy"
                        checked={
                          policyType === "Third-party" ||
                          tempData?.policyType === "Third-party"
                        }
                        onClick={() => {
                          onSubmit("Third-party");
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="smokingTxt">Third-party Policy</span>
                      <div className="valuntaryDisTxt">
                        Offers protection against damages to the third-party by
                        the insured vehicle.
                      </div>
                    </label>
                  </Col>
                  <Col
                    lg={
                      temp_data?.regDate &&
                      Number(
                        temp_data?.regDate?.slice(
                          temp_data?.regDate?.length - 4
                        )
                      ) <
                        new Date().getFullYear() - 1 &&
                      temp_data?.odOnly
                        ? 3
                        : temp_data?.odOnly
                        ? 4
                        : ["ACE", "FYNTUNE", "OLA"].includes(
                            process.env.REACT_APP_BROKER
                          ) && temp_data?.journeyCategory === "PCV"
                        ? 3
                        : 4
                    }
                    sm={3}
                  >
                    <label
                      className="panel-heading ratioButton policyTypeRadio "
                      style={{ marginTop: lessthan993 ? "10px" : "" }}
                    >
                      <input
                        type="radio"
                        name="confirmPolicy"
                        value="Comprehensive Policy"
                        checked={
                          policyType === "Comprehensive" ||
                          tempData?.policyType === "Comprehensive" ||
                          tempData?.policyType === "Own-damage"
                        }
                        onClick={() =>
                          bundledPolicy
                            ? onSubmit("Comprehensive")
                            : temp_data?.odOnly
                            ? onSubmit("Own-damage")
                            : onSubmit("Comprehensive")
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="smokingTxt">
                        {bundledPolicy
                          ? "Bundled Policy"
                          : temp_data?.odOnly
                          ? "Own-damage"
                          : "Comprehensive"}
                      </span>
                      <div className="valuntaryDisTxt">
                        {temp_data?.regDate &&
                        Number(
                          temp_data?.regDate?.slice(
                            temp_data?.regDate?.length - 4
                          )
                        ) >= 2018 &&
                        temp_data?.odOnly
                          ? type === "car"
                            ? bundledPolicy
                              ? "1- Year Own Damage + 3-Year Third Party coverage"
                              : ""
                            : "1- Year Own Damage + 5-Year Third Party coverage"
                          : "Policy with 1 year Own Damage and 1 year Third Party "}
                      </div>
                    </label>
                  </Col>
                  {temp_data?.regDate &&
                    Number(
                      temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)
                    ) <
                      new Date().getFullYear() - 1 &&
                    temp_data?.odOnly && (
                      <Col
                        lg={
                          temp_data?.regDate &&
                          Number(
                            temp_data?.regDate?.slice(
                              temp_data?.regDate?.length - 4
                            )
                          ) <
                            new Date().getFullYear() - 1 &&
                          temp_data?.odOnly
                            ? 3
                            : temp_data?.odOnly
                            ? 4
                            : ["ACE", "FYNTUNE", "OLA"].includes(
                                process.env.REACT_APP_BROKER
                              ) && temp_data?.journeyCategory === "PCV"
                            ? 3
                            : 4
                        }
                        sm={3}
                      >
                        <label
                          className="panel-heading ratioButton policyTypeRadio "
                          style={{ marginTop: lessthan993 ? "10px" : "" }}
                        >
                          <input
                            type="radio"
                            name="confirmPolicy"
                            value="Own-damage Policy"
                            checked={
                              policyType === "Own-damage" ||
                              tempData?.policyType === "Own-damage"
                            }
                            onClick={() => onSubmit("Own-damage")}
                          />
                          <span className="checkmark"></span>
                          <span className="smokingTxt">1-Year OD only</span>
                          <div className="valuntaryDisTxt">
                            Covers damages to your Vehicle only and not third
                            party.
                          </div>
                        </label>
                      </Col>
                    )}
                  {["ACE", "FYNTUNE", "OLA"].includes(
                    process.env.REACT_APP_BROKER
                  ) &&
                    type === "cv" &&
                    temp_data?.journeyCategory === "PCV" && (
                      <Col
                        lg={
                          temp_data?.regDate &&
                          Number(
                            temp_data?.regDate?.slice(
                              temp_data?.regDate?.length - 4
                            )
                          ) <
                            new Date().getFullYear() - 1 &&
                          temp_data?.odOnly
                            ? 3
                            : temp_data?.odOnly
                            ? 4
                            : ["ACE", "FYNTUNE", "OLA"].includes(
                                process.env.REACT_APP_BROKER
                              ) && temp_data?.journeyCategory === "PCV"
                            ? 3
                            : 4
                        }
                        sm={3}
                      >
                        <label
                          className="panel-heading ratioButton policyTypeRadio"
                          style={{ marginTop: lessthan993 ? "10px" : "" }}
                        >
                          <input
                            type="radio"
                            name="confirmPolicy"
                            value="Short Term"
                            checked={temp_data?.prevShortTerm * 1}
                            onClick={() => {
                              onSubmit("Short Term");
                            }}
                          />
                          <span className="checkmark"></span>
                          <span className="smokingTxt">Short Term</span>
                        </label>
                      </Col>
                    )}
                  {!temp_data?.newCar && (
                    <Col
                      lg={
                        temp_data?.regDate &&
                        Number(
                          temp_data?.regDate?.slice(
                            temp_data?.regDate?.length - 4
                          )
                        ) <
                          new Date().getFullYear() - 1 &&
                        temp_data?.odOnly
                          ? 3
                          : temp_data?.odOnly
                          ? 4
                          : ["ACE", "FYNTUNE", "OLA"].includes(
                              process.env.REACT_APP_BROKER
                            ) && temp_data?.journeyCategory === "PCV"
                          ? 3
                          : 4
                      }
                      sm={3}
                    >
                      <label
                        className="panel-heading ratioButton policyTypeRadio"
                        style={{ marginTop: lessthan993 ? "10px" : "" }}
                      >
                        <input
                          type="radio"
                          name="confirmPolicy"
                          value="Not sure"
                          checked={
                            policyType === "Not sure" ||
                            tempData?.policyType === "Not sure" ||
                            tempData?.policyType === "New"
                          }
                          onClick={() => {
                            onSubmit("Not sure");
                          }}
                        />
                        <span className="checkmark"></span>
                        <span className="smokingTxt">
                          Not Sure About Policy
                        </span>
                      </label>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </form>
        </ExpertForm>
      </ContentWrap>
    </>
  );
  return !lessthan767 ? (
    <Popup
      height={"auto"}
      width={
        temp_data?.odOnly
          ? temp_data?.regDate &&
            Number(temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)) <
              new Date().getFullYear() - 1 &&
            temp_data?.odOnly
            ? "900px"
            : "700px"
          : ["ACE", "FYNTUNE", "OLA"].includes(process.env.REACT_APP_BROKER) &&
            temp_data?.journeyCategory === "PCV"
          ? "900px"
          : "700px"
      }
      show={show}
      onClose={onClose}
      content={content}
      position={lessthan993 ? "bottom" : "center"}
      hiddenClose={tempData?.policyType ? false : true}
    />
  ) : (
    <>
      <React.Fragment
        key={"bottom"}
        style={{ borderRadius: "5% 5% 0% 0%", overflow: "hidden" }}
      >
        <Drawer
          anchor={"bottom"}
          open={drawer}
          onClose={() => {
            setDrawer(false);
            onClose(false);
          }}
          onOpen={() => setDrawer(true)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MobileDrawerBody>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>
            {content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle />
    </>
  );
};

// PropTypes
PolicyTypePopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
PolicyTypePopup.defaultProps = {
  show: false,
  onClose: () => {},
};

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 99999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 100000 !important;
  }
}
`;
const ContentWrap = styled.div`
  padding: 0px 32px 40px 32px;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 14px;
  line-height: 22px;
  color: #333;
  position: relative;
  margin-top: 30px;
`;
const ContentTitle = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 8px;
`;
const ContentSubTitle = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 14px;
  line-height: 22px;
  color: #808080;
  margin-bottom: 24px;
`;
const ExpertForm = styled.div`
  margin: 0 0 48px 0;
  form {
    display: flex;
    font-family: "Inter-Medium";
    font-size: 16px;
    line-height: 24px;
  }
  .smokingTxt {
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  }
  .valuntaryDisTxt {
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  }
`;
const PaymentTermRadioWrap = styled.div`
  float: left;
  width: 100%;
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
  overflow: hidden;

  .ratioButton {
    margin: 0 !important;
    padding-top: 0px !important;
  }
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;

export default PolicyTypePopup;
