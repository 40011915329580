import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { MultiSelect, Error, ErrorMsg, Textbox } from "components";
import Popup from "../../../../components/Popup/Popup";
import { Row, Col, Button, Badge } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  setTempData,
  saveQuoteData,
  error,
} from "../../filterConatiner/quoteFilter.slice";
import DateInput from "../../../Home/steps/car-details/DateInput";
import "../policyTypePopup/policyTypePopup.css";
import _, { isNull } from "lodash";
import {
  set_temp_data,
  Variant as VariantType,
  getFuelType,
  //fueldelay as fdelay,
  getFuel as setFuelType,
  BrandType,
  ModelType,
  Rto,
  modelType as emptyModelType,
  variant as emptyVariant,
} from "modules/Home/home.slice";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  clear as clearQuote,
  setQuotesList,
  SaveAddonsData,
  error as quotesError,
} from "../../quote.slice";
import moment from "moment";
import { toDate, toDateOld, toDateDayOld } from "utils";
import {
  addDays,
  subMonths,
  differenceInMonths,
  differenceInDays,
  getYear,
  differenceInYears,
} from "date-fns";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import { getCalculatedNcb, getNewNcb } from "../../quoteUtil";
import Drawer from "@mui/material/Drawer";
import { CancelAll, clear } from "modules/quotesPage/quote.slice";
//---------------------Theme imports----------------------------

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

//---------------------yup validate for man date and variant----------------------------

const yupValidate = yup.object({
  variant: yup.string("Variant is required").required("Variant is required"),
  // date2: yup.string().required("Date is required"),
});

const EditInfoPopup2 = ({ show, onClose, type, TypeReturn }) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan600 = useMediaPredicate("(max-width: 600px)");
  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const journey_type = query.get("journey_type");
  const FuelSelected = watch("fuel");
  const PrevIcSelected = watch("preIc");
  const GcvCarrierType = watch("carrierType");
  const versionSelected = watch("variant");
  const brand = watch("brand");
  const model = watch("model");
  const rtoValue = watch("rto");
  const newRegDate = watch("date1");
  const newManDate = watch("date2");
  const { tempData, prevInsList } = useSelector((state) => state.quoteFilter);
  const typeId = query.get("typeid");
  const {
    temp_data,
    variant: varnt,
    getFuel,
    brandType,
    modelType,
    rto,
    prefill,
  } = useSelector((state) => state.home);

  let userAgent = navigator.userAgent;
  let isMobileIOS = false; //initiate as false
  // device detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && lessthan767) {
    isMobileIOS = true;
  }

  const [manufactureDate, setManfactureDate] = useState(false);

  console.log("FuelSelected", FuelSelected);
  const dispatch = useDispatch();

  //----------------setting maximum date for new registration date-----------------

  let policyMax = subMonths(new Date(Date.now()), 9);

  //---------------setLoader for fuel api & clear previous fuel type data-------------------

  useEffect(() => {
    dispatch(setFuelType([]));
  }, [brand, model, dispatch]);

  console.log("Brand", brand);
  console.log("Model", model);
  // console.log("RTO", rtoValue)

  //-------------------------------get available fuel types----------------------------

  useEffect(() => {
    if (temp_data?.productSubTypeId)
      dispatch(
        getFuelType(
          {
            modelId: model?.id,
            productSubTypeId: temp_data?.productSubTypeId,
          },
          true
        )
      );
  }, [temp_data.productSubTypeId, brand, model, dispatch]);

  //----------------------------setting man date to component--------------------------------

  useEffect(() => {
    if (newManDate) {
      setManfactureDate(`01-${newManDate}`);
    }
  }, [newManDate]);

  //--------------------------handle edit which redirect to reg page (also clearing quote data)-----------------------------------

  const handleEdit = () => {
    dispatch(CancelAll(true));
    dispatch(clear());
    dispatch(quotesError(null));
    dispatch(error(null));
    dispatch(setQuotesList([]));
    dispatch(clearQuote());
    dispatch(saveQuoteData(null));
    dispatch(
      setTempData({
        policyType: false,
      })
    );
    dispatch(
      set_temp_data({
        newCar: false,
        breakIn: false,
        leadJourneyEnd: false,
      })
    );

    history.push(
      `/${type}/registration?enquiry_id=${enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}${
        journey_type ? `&journey_type=${journey_type}` : ``
      }`
    );
    var data1 = {
      enquiryId: temp_data?.enquiry_id || enquiry_id,
      addonData: {
        addons: null,
        accessories: null,
        discounts: null,
        additional_covers: null,
      },
    };

    dispatch(SaveAddonsData(data1));
  };

  //---------------------on submit function for edit info popup---------------------------------

  const onSubmit = () => {
    dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
    let today = moment().format("DD-MM-YYYY");
    let a = temp_data?.expiry;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    let diffYear =
      newRegDate &&
      today &&
      differenceInYears(toDate(today), toDate(newRegDate));
    console.log("versionSelected?.fuelFype", versionSelected?.fuelFype);
    dispatch(
      set_temp_data({
        gcvCarrierType: GcvCarrierType?.id,
        fuel: versionSelected?.fuelFype || FuelSelected?.value,
        versionId: versionSelected?.id,
        versionName: versionSelected?.name,
        // regDate: newRegDate,
        // manfDate: newManDate,
        prevIc: prevInsList.filter(
          (i) => i.previousInsurer === PrevIcSelected?.name
        )[0]?.companyAlias,
        prevIcFullName: PrevIcSelected?.name,
        // ncb:
        //   tempData?.policyType === "Third-party" ||
        //   temp_data?.newCar ||
        //   tempData?.policyType === "Not sure"
        //     ? "0%"
        //     : diffDays && diffDays > 90
        //     ? "0%"
        //     : temp_data?.ncb && temp_data?.isNcbVerified === "Y"
        //     ? temp_data?.ncb
        //     : diffYear > 8
        //     ? "50%"
        //     : getCalculatedNcb(diffYear),

        // newNcb:
        //   tempData?.policyType === "Third-party" ||
        //   temp_data?.newCar ||
        //   tempData?.policyType === "Not sure"
        //     ? "0%"
        //     : diffDays && diffDays > 90
        //     ? "0%"
        //     : temp_data?.newNcb && temp_data?.isNcbVerified === "Y"
        //     ? temp_data?.newNcb
        //     : getNewNcb(diffYear > 8 ? "50%" : getCalculatedNcb(diffYear)),
        manfId: brand?.id,
        manfName: brand?.name,
        manfactureId: brand?.id,
        manfactureName: brand?.name,
        modelId: model?.id,
        modelName: model?.name,
        model: model?.id,
        rtoNumber: rtoValue?.value,
        rto: rtoValue?.value,
        rtoCode: rtoValue?.value,
        rtoCity: rtoValue?.rtoName,
        // ...(!temp_data?.regNo && {
        //   rtoNumber: rtoValue?.value,
        //   rto: rtoValue?.value,
        //   rtoCode: rtoValue?.value,
        // })
      })
    );
    dispatch(
      setTempData({
        idvChoosed: 0,
        idvType: "lowIdv",
        manfId: brand?.id,
        manfName: brand?.name,
        manfactureId: brand?.id,
        manfactureName: brand?.name,
        modelId: model?.id,
        modelName: model?.name,
        model: model?.id,
        rtoNumber: rtoValue?.value,
        rto: rtoValue?.value,
      })
    );
    dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
    onClose(false);
  };

  //-------------------resetting man data nd its max and min limits when reg date value changes -------------------------

  const handleValueChange = (date) => {
    let newDate = moment(date).format("DD-MM-YYYY");

    if (newDate && manufactureDate) {
      let differenceInMonthsMan = differenceInMonths(
        toDate(newDate),
        toDate(manufactureDate)
      );

      let differneceInDaysMan = differenceInDays(
        toDate(newDate),
        toDate(manufactureDate)
      );

      if (
        differenceInMonthsMan < 0 ||
        differenceInMonthsMan > 36 ||
        differneceInDaysMan < 0
      ) {
        setValue("date2", "");
      }
    }
  };

  // prefilling Brand

  useEffect(() => {
    if (temp_data?.productSubTypeId) {
      dispatch(
        BrandType({ productSubTypeId: temp_data?.productSubTypeId }, true)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data.productSubTypeId]);

  //load Model Data
  useEffect(() => {
    if (temp_data?.productSubTypeId && temp_data?.manfId) {
      dispatch(
        ModelType(
          {
            productSubTypeId: temp_data?.productSubTypeId,
            manfId: brand?.id || temp_data?.manfId,
          },
          false,
          true
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data, brand]);

  //--------------------loading variant data when fuel selected is changed-----------------------------

  useEffect(() => {
    if (temp_data?.modelId && FuelSelected?.value) {
      dispatch(
        VariantType(
          {
            productSubTypeId: temp_data?.productSubTypeId,
            modelId: model?.id || temp_data?.modelId,
            fuelType: FuelSelected?.value || temp_data?.fuel,
            LpgCngKitValue: temp_data?.kit_val ? temp_data?.kit_val : null,
          },
          true
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data.modelId, FuelSelected, brand, model]);

  //get rto
  useEffect(() => {
    if (temp_data?.rtoNumber) dispatch(Rto({}, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.rtoNumber, temp_data?.regNo1]);

  //prefill
  useEffect(() => {
    if (temp_data?.rtoNumber) {
      console.log("rto", rto);
      const filtered_data = !_.isEmpty(rto)
        ? rto?.filter(({ rtoNumber }, index) => {
            return rtoNumber === temp_data?.rtoNumber;
          })
        : [];
      let selected_option = [
        {
          rtoNumber: !_.isEmpty(filtered_data) && filtered_data[0]?.rtoNumber,
          rtoId: !_.isEmpty(filtered_data) && filtered_data[0]?.rtoId,
          stateName: !_.isEmpty(filtered_data) && filtered_data[0]?.stateName,
          rtoName: !_.isEmpty(filtered_data) && filtered_data[0]?.rtoName,
          label:
            !_.isEmpty(filtered_data) &&
            `${filtered_data[0]?.rtoNumber?.replace(/-/g, "")} (${
              filtered_data[0]?.stateName
            } : ${filtered_data[0]?.rtoName})`,
          name:
            !_.isEmpty(filtered_data) &&
            `${filtered_data[0]?.rtoNumber?.replace(/-/g, "")} (${
              filtered_data[0]?.stateName
            } : ${filtered_data[0]?.rtoName})`,
          value: !_.isEmpty(filtered_data) && filtered_data[0]?.rtoNumber,
          id: !_.isEmpty(filtered_data) && filtered_data[0]?.rtoId,
        },
      ];
      console.log(selected_option, "Selected_option");
      !_.isEmpty(selected_option) && setValue("rto", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data, rto]);

  ///-------------creating options for variant from api response  -----------------------------

  const Options = !_.isEmpty(varnt)
    ? varnt?.map(
        ({
          versionId,
          versionName,
          cubicCapacity,
          grosssVehicleWeight,
          kw,
          fuelFype,
          vehicleBuiltUp,
        }) => ({
          label:
            temp_data?.parent?.productSubTypeCode !== "GCV"
              ? `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${
                  fuelFype === "ELECTRIC"
                    ? kw
                      ? TypeReturn(type) !== "bike"
                        ? ` - ${kw}kW`
                        : ""
                      : ""
                    : cubicCapacity
                    ? TypeReturn(type) !== "bike"
                      ? ` - ${cubicCapacity}CC`
                      : ""
                    : ""
                }`
              : `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${grosssVehicleWeight ? ` - ${grosssVehicleWeight}GVW` : ""}`,
          name:
            temp_data?.parent?.productSubTypeCode !== "GCV"
              ? `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${
                  fuelFype === "ELECTRIC"
                    ? kw
                      ? TypeReturn(type) !== "bike"
                        ? ` - ${kw}kW`
                        : ""
                      : ""
                    : cubicCapacity
                    ? TypeReturn(type) !== "bike"
                      ? ` - ${cubicCapacity}CC`
                      : ""
                    : ""
                }`
              : `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${grosssVehicleWeight ? ` - ${grosssVehicleWeight}GVW` : ""}`,
          id: versionId,
          value: versionId,
          fuelFype: fuelFype,
        })
      )
    : [];
  ///-------------creating options for variant from api response  -----------------------------

  const Brands = !_.isEmpty(brandType)
    ? brandType?.map(({ manfName, manfId }) => ({
        label: manfName,
        name: manfName,
        id: manfId,
        value: manfName,
      }))
    : [];

  const Models = !_.isEmpty(modelType)
    ? modelType?.map(({ modelName, modelId }) => ({
        label: modelName,
        name: modelName,
        id: modelId,
        value: modelName,
      }))
    : [];

  const RTO = !_.isEmpty(rto)
    ? rto?.map(({ rtoName, rtoNumber, rtoId, stateName }) => ({
        label: `${rtoNumber?.replace(/-/g, "")} - (${stateName} : ${rtoName})`,
        name: `${rtoNumber?.replace(/-/g, "")} - (${stateName} : ${rtoName})`,
        id: rtoId,
        value: rtoNumber,
        rtoName: rtoName,
      }))
    : [];
  //------------------------getiing fuel types from api and formating them -----------------------------

  const availableTypes = !_.isEmpty(getFuel)
    ? getFuel.map((item) => item.toUpperCase())
    : [];
  console.log("getFuel", getFuel, "availableTypes", availableTypes);
  const Fuel = _.compact([
    availableTypes?.includes("PETROL") && {
      name: "Petrol",
      label: "Petrol",
      value: "PETROL",
      id: "PETROL",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/petrol10.png`,
    },
    availableTypes?.includes("DIESEL") && {
      name: "Diesel",
      label: "Diesel",
      value: "DIESEL",
      id: "DIESEL",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/oil2.png`,
    },
    (availableTypes?.includes("CNG") || availableTypes?.includes("LPG")) && {
      name: "Inbuilt CNG/LPG",
      label: "Inbuilt CNG/LPG",
      value: "CNG",
      id: "CNG",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/cng3.png`,
    },
    availableTypes?.includes("ELECTRIC") && {
      name: "Electric",
      label: "Electric",
      value: "ELECTRIC",
      id: "ELECTRIC",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/electric.png`,
    },
  ]);

  //------------carrier type options (this options will only be there in gcv) --------------------------

  const carrierType = [
    {
      name: "PUBLIC",
      label: "PUBLIC",
      value: "PUBLIC",
      id: "PUBLIC",
    },
    {
      name: "PRIVATE",
      label: "PRIVATE",
      value: "PRIVATE",
      id: "PRIVATE",
    },
  ];

  //-----------------------------prefill man date and reg date------------------
  useEffect(() => {
    if (temp_data?.manfDate) setValue("date2", temp_data?.manfDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (temp_data?.regDate) setValue("date1", temp_data?.regDate);
  }, [temp_data]);

  //-----------------------------prefill brand------------------
  useEffect(() => {
    if (temp_data?.versionId && !_.isEmpty(brandType)) {
      let check = brandType?.filter(
        ({ manfName }) => manfName === temp_data?.manfName
      );
      let selected_option = check?.map(({ manfId, manfName }) => {
        return {
          id: manfId,
          value: manfName,
          label: temp_data?.manfName,
          name: temp_data?.manfName,
        };
      });

      !_.isEmpty(selected_option) && setValue("brand", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.manfId, brandType]);
  //-----------------------------prefill Model------------------
  useEffect(() => {
    let check = modelType?.filter(
      ({ modelName }) => modelName === temp_data?.modelName
    );
    let selected_option = check?.map(({ modelId, modelName }) => {
      return {
        id: modelId,
        value: modelName,
        label: temp_data?.modelName,
        name: temp_data?.modelName,
      };
    });

    !_.isEmpty(selected_option) && setValue("model", selected_option[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.manfId, modelType]);
  //-----------------------------prefill variant------------------
  useEffect(() => {
    if (temp_data?.versionId && !_.isEmpty(varnt)) {
      let check = varnt?.filter(
        ({ versionId }) =>
          Number(versionId) === Number(temp_data?.versionId) ||
          versionId === temp_data?.versionId
      );
      let selected_option = check?.map(
        ({
          versionId,
          versionName,
          cubicCapacity,
          grosssVehicleWeight,
          kw,
          fuelFype,
          vehicleBuiltUp,
        }) => {
          return {
            id: versionId,
            value: versionId,
            label:
              temp_data?.parent?.productSubTypeCode !== "GCV"
                ? `${versionName}${
                    vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                  }${
                    fuelFype === "ELECTRIC"
                      ? kw
                        ? ` - ${kw}kW`
                        : ""
                      : cubicCapacity
                      ? ` - ${cubicCapacity}CC`
                      : ""
                  }`
                : `${versionName}${
                    vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                  }${
                    grosssVehicleWeight ? ` - ${grosssVehicleWeight}GVW` : ""
                  }`,
            name:
              temp_data?.parent?.productSubTypeCode !== "GCV"
                ? `${versionName}${
                    vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                  }${
                    fuelFype === "ELECTRIC"
                      ? kw
                        ? ` - ${kw}kW`
                        : ""
                      : cubicCapacity
                      ? ` - ${cubicCapacity}CC`
                      : ""
                  }`
                : `${versionName}${
                    vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                  }${
                    grosssVehicleWeight ? ` - ${grosssVehicleWeight}GVW` : ""
                  }`,
            fuelFype: fuelFype,
          };
        }
      );

      !_.isEmpty(selected_option) && setValue("variant", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.versionId, varnt]);

  //-----------------------------prefill carrier type------------------

  useEffect(() => {
    if (temp_data?.gcvCarrierType) {
      let check = carrierType?.filter(
        ({ id }) => id === temp_data?.gcvCarrierType
      );
      let selected_option = check?.map(({ id }) => {
        return {
          id: id,
          value: id,
          label: id,
          name: id,
        };
      });

      !_.isEmpty(selected_option) &&
        setValue("carrierType", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.gcvCarrierType]);

  //-----------------------------prefill fuel-----------------

  useEffect(() => {
    if (
      temp_data?.fuel &&
      !_.isEmpty(Fuel) &&
      !FuelSelected &&
      !_.isEmpty(brand) &&
      !_.isEmpty(model)
    ) {
      let check = Fuel?.filter(({ value }) =>
        temp_data?.fuel === "LPG" ? value === "CNG" : value === temp_data?.fuel
      );
      let selected_option = check?.map(({ label, value, logo }) => {
        return {
          name: label,
          label: label,
          value: value,
          id: value,
          logo: logo,
        };
      });

      !_.isEmpty(selected_option) && setValue("fuel", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data, Fuel, brand, model]);
  //-------------------------------prev ic logic and prefill--------------------------------

  const [prevIcData, setPrevIcData] = useState(false);

  // ------ logic( only show prev ic option to change when it is selcted once )-----------------------

  useEffect(() => {
    if (
      temp_data?.prevIc &&
      !temp_data?.newCar &&
      temp_data?.prevIc !== "NEW" &&
      temp_data?.prevIc !== "New"
    ) {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);

  const insData = !_.isEmpty(prevInsList)
    ? prevInsList?.map(({ previousInsurer }) => {
        return {
          label: previousInsurer,
          name: previousInsurer,
          value: previousInsurer,
          id: previousInsurer,
        };
      })
    : [];

  useEffect(() => {
    if (temp_data?.prevIcFullName) {
      let check = prevInsList?.filter(
        ({ previousInsurer }) => previousInsurer === temp_data?.prevIcFullName
      );
      let selected_option = check?.map(({ previousInsurer }) => {
        return {
          label: previousInsurer,
          name: previousInsurer,
          value: previousInsurer,
          id: previousInsurer,
        };
      });

      !_.isEmpty(selected_option) && setValue("preIc", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.prevIcFullName]);

  ///-----------------handling drawer mobile----------------------
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);

  const getPetrolValue = () => {
    let value = temp_data?.fuel;
    let finalValue =
      value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
    return finalValue;
  };

  const content = (
    <>
      <ContentWrap isMobileIOS={isMobileIOS}>
        {/* <HeaderPopup> Edit Policy Details</HeaderPopup> */}
        <ContentBox>
          <DetailsWrapper>
            {!lessthan767 && (
              <CarLogo
                src={
                  TypeReturn(type) !== "bike"
                    ? `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/new-car.jpg`
                    : `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/vehicle/bike2.png`
                }
                alt="car"
              />
            )}
            {!temp_data?.regNo ? (
              <span className="vehicleDetails">
                {temp_data?.manfName}-{temp_data?.modelName} -
                {temp_data?.versionName}{" "}
              </span>
            ) : (
              <span>
                {" "}
                {temp_data?.regNo !== "NEW"
                  ? temp_data?.regNo
                  : temp_data?.rtoNumber}{" "}
              </span>
            )}
            <Badge
              variant="dark"
              style={{
                cursor: "pointer",
                position: "relative",
                bottom: "0px",
                left: "15px ",
                height: "24px",
                fontSize: "16px",
              }}
              onClick={handleEdit}
            >
              {"Change"}
            </Badge>
          </DetailsWrapper>

          {temp_data?.journeyCategory === "GCV" && (
            <DetailsSection>
              <Row>
                <Col md={4} sm={12}>
                  <DetailsSectionLabel>Vehicle Type </DetailsSectionLabel>
                </Col>
                <Col md={8} sm={12} style={{}} className="dropDownColomn">
                  <Controller
                    control={control}
                    name="carrierType"
                    defaultValue={""}
                    render={({ onChange, onBlur, value, name }) => (
                      <MultiSelect
                        quotes
                        knowMore
                        name={name}
                        onChange={onChange}
                        ref={register}
                        value={value}
                        onBlur={onBlur}
                        isMulti={false}
                        options={carrierType}
                        placeholder={"Vehicle Type"}
                        errors={errors.carrierType}
                        Styled
                        closeOnSelect
                      />
                    )}
                  />
                  {!!errors?.carrierType && (
                    <Error className="mt-1">
                      {errors?.carrierType?.message}
                    </Error>
                  )}
                </Col>
              </Row>
            </DetailsSection>
          )}
          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>Brand </DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12} style={{}} className="dropDownColomn">
                <Controller
                  control={control}
                  name="brand"
                  // defaultValue={temp_data?.fuel}
                  render={({ onChange, onBlur, value, name }) => (
                    <MultiSelect
                      quotes
                      knowMore
                      name={name}
                      onChange={onChange}
                      ref={register}
                      value={value}
                      onBlur={onBlur}
                      isMulti={false}
                      options={Brands}
                      placeholder={"Brand"}
                      errors={errors.brand}
                      Styled
                      closeOnSelect
                      onValueChange={() => {
                        dispatch(emptyModelType([]));
                        dispatch(emptyVariant([]));
                        dispatch(setFuelType([]));
                        setValue("model", null);
                        setValue("fuel", null);
                        setValue("variant", null);
                      }}
                    />
                  )}
                />
                {!!errors?.brand && (
                  <Error className="mt-1">{errors?.brand?.message}</Error>
                )}
              </Col>
            </Row>
          </DetailsSection>
          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>Model </DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12} style={{}} className="dropDownColomn">
                <Controller
                  control={control}
                  name="model"
                  // defaultValue={temp_data?.fuel}
                  render={({ onChange, onBlur, value, name }) => (
                    <MultiSelect
                      quotes
                      knowMore
                      name={name}
                      onChange={onChange}
                      ref={register}
                      value={value}
                      onBlur={onBlur}
                      isMulti={false}
                      options={Models}
                      placeholder={"Model"}
                      errors={errors.model}
                      Styled
                      closeOnSelect
                      onValueChange={() => {
                        setValue("fuel", null);
                        setValue("variant", null);
                      }}
                    />
                  )}
                />
                {!!errors?.model && (
                  <Error className="mt-1">{errors?.model?.message}</Error>
                )}
              </Col>
            </Row>
          </DetailsSection>

          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>Fuel Type </DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12} style={{}} className="dropDownColomn">
                <Controller
                  control={control}
                  name="fuel"
                  // defaultValue={temp_data?.fuel}
                  render={({ onChange, onBlur, value, name }) => (
                    <MultiSelect
                      quotes
                      knowMore
                      name={name}
                      onChange={onChange}
                      ref={register}
                      value={Fuel?.length === 1 ? Fuel[0] : value}
                      onBlur={onBlur}
                      isMulti={false}
                      options={Fuel}
                      placeholder={
                        !model
                          ? "Select Fuel"
                          : Fuel.length > 0
                          ? "Fuel"
                          : "Loading..."
                      }
                      errors={errors.Fuel}
                      Styled
                      closeOnSelect
                      onValueChange={() => setValue("variant", null)}
                    />
                  )}
                />
                {!!errors?.fuel && (
                  <Error className="mt-1">{errors?.fuel?.message}</Error>
                )}
              </Col>
            </Row>
          </DetailsSection>

          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>Variant </DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12} style={{}} className="dropDownColomn">
                <Controller
                  control={control}
                  name="variant"
                  defaultValue={""}
                  render={({ onChange, onBlur, value, name }) => (
                    <MultiSelect
                      quotes
                      knowMore
                      name={name}
                      onChange={onChange}
                      ref={register}
                      value={value}
                      onBlur={onBlur}
                      isMulti={false}
                      options={Options}
                      placeholder={"Select Variant"}
                      errors={errors.variant}
                      Styled
                      closeOnSelect
                    />
                  )}
                />
              </Col>
            </Row>
          </DetailsSection>

          {(!temp_data?.regNo || temp_data?.regNo === "NEW") && (
            <DetailsSection>
              <Row>
                <Col md={4} sm={12}>
                  <DetailsSectionLabel>RTO </DetailsSectionLabel>
                </Col>
                <Col md={8} sm={12} style={{}} className="dropDownColomn">
                  <Controller
                    control={control}
                    name="rto"
                    defaultValue={""}
                    render={({ onChange, onBlur, value, name }) => (
                      <MultiSelect
                        quotes
                        knowMore
                        name={name}
                        onChange={onChange}
                        ref={register}
                        value={value}
                        onBlur={onBlur}
                        isMulti={false}
                        options={RTO}
                        placeholder={"Select RTO"}
                        errors={errors.rto}
                        Styled
                        closeOnSelect
                        rto
                      />
                    )}
                  />
                </Col>
              </Row>
            </DetailsSection>
          )}
          {/* <DetailsSection>
						<Row>
							<Col md={4} sm={12}>
								<DetailsSectionLabel>RTO </DetailsSectionLabel>
							</Col>
							<Col
								md={8}
								sm={12}
								style={{  }}
								className="dropDownColomn"
							>
								<Controller
									control={control}
									name="variant"
									defaultValue={""}
									render={({ onChange, onBlur, value, name }) => (
										<MultiSelect
											quotes
											knowMore
											name={name}
											onChange={onChange}
											ref={register}
											value={value}
											onBlur={onBlur}
											isMulti={false}
											options={Options}
											placeholder={"Select Variant"}
											errors={errors.variant}
											Styled
											closeOnSelect
										/>
									)}
								/>
							</Col>
						</Row>
					</DetailsSection> */}

          {/* <DetailsSection>
						<Row>
							<Col md={4} sm={12}>
								<DetailsSectionLabel>Registration Date</DetailsSectionLabel>
							</Col>
							<Col md={8} sm={12}>
								<StyledDatePicker>
									<div
										className="py-2 dateTimeOne "
										style={{
										}}
									>
										<Controller
											control={control}
											name="date1"
											defaultValue={temp_data?.regDate}
											render={({ onChange, onBlur, value, name }) => (
												<DateInput
													editPopupDate
													value={value}
													selected={
														newRegDate || temp_data?.regDate
															? toDate(newRegDate || temp_data?.regDate)
															: false
													}
													maxDate={policyMax}
													name={name}
													onChange={onChange}
													ref={register}
													onValueChange={(date) => {
														handleValueChange(date);
													}}
													rangeMax={
														!temp_data?.newCar
															? getYear(new Date(Date.now()) + 1)
															: false
													}
													readOnly={
														temp_data?.newCar || isNull(temp_data?.regDate)
													}
													withPortal={lessthan767 ? true : false}
													
												/>
											)}
										/>
										{!!errors.date1 && (
											<ErrorMsg fontSize={"12px"}>
												{errors.date1.message}
											</ErrorMsg>
										)}
									</div>
								</StyledDatePicker>
							</Col>
						</Row>
					</DetailsSection>

					<DetailsSection>
						<Row>
							<Col md={4} sm={12}>
								<DetailsSectionLabel>
									Manufacture Month-Year
								</DetailsSectionLabel>
							</Col>
							<Col md={8} sm={12}>
								<StyledDatePicker>
									<div
										className="py-2 dateTimeOne "
										style={{
										}}
									>
										<Controller
											control={control}
											name="date2"
											defaultValue={temp_data?.manfDate}
											render={({ onChange, onBlur, value, name }) => (
												<DateInput
													editPopupDate
													selected={
														newRegDate || temp_data?.regDate
															? toDate(
																	manufactureDate || `01-${temp_data?.manfDate}`
															  )
															: false
													}
													maxDate={
														newRegDate || temp_data?.regDate
															? toDate(newRegDate || temp_data?.regDate)
															: false
													}
													minDate={
														newRegDate || temp_data?.regDate
															? toDateOld(newRegDate || temp_data?.regDate)
															: false
													}
													value={value}
													name={name}
													onChange={onChange}
													ref={register}
													rangeMax={
														!temp_data?.newCar
															? getYear(new Date(Date.now()) + 1)
															: false
													}
													showMonthYearPicker={true}
													dateFormat={"MM/yyyy"}
													withPortal={lessthan767 ? true : false}
												/>
											)}
										/>
									</div>
								</StyledDatePicker>
							</Col>
						</Row>
					</DetailsSection> */}
          {/* <div className={!prevIcData ? "hiddenInput" : ""}>
						<DetailsSection>
							<Row>
								<Col md={4} sm={12}>
									<DetailsSectionLabel>Previous Ic </DetailsSectionLabel>
								</Col>
								<Col
									md={8}
									sm={12}
									style={{ }}
									className="dropDownColomn"
								>
									<Controller
										control={control}
										name="preIc"
										defaultValue={""}
										render={({ onChange, onBlur, value, name }) => (
											<MultiSelect
												quotes
												knowMore
												name={name}
												onChange={onChange}
												ref={register}
												value={value}
												onBlur={onBlur}
												isMulti={false}
												options={insData}
												placeholder={"Previous Insurer"}
												errors={errors.preIc}
												Styled
												closeOnSelect
											/>
										)}
									/>
								</Col>
							</Row>
						</DetailsSection>
					</div> */}
        </ContentBox>
        <PremChangeWarning>
          <div class="ncb_msg">
            <div class="image"></div>
            <p
              className="messagetxt"
              style={{ fontSize: "15px", fontWeight: "800" }}
            >
              {"Your premium will be updated based on your changes"}
              <b></b>.
            </p>
          </div>
        </PremChangeWarning>
        <UpdateButton onClick={handleSubmit(onSubmit)}>Update</UpdateButton>
      </ContentWrap>
    </>
  );
  return !lessthan767 ? (
    <Popup
      height={lessthan767 ? "100%" : "auto"}
      width={lessthan767 ? "100%" : "700px"}
      top="40%"
      show={show}
      onClose={onClose}
      content={content}
      position="middle"
      hiddenClose={tempData?.policyType ? false : true}
    />
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          onClose={() => {
            setDrawer(false);
            onClose(false);
          }}
          onOpen={() => setDrawer(true)}
          ModalProps={{
            keepMounted: true,
          }}
          style={{ overflowX: isMobileIOS && "hidden !important" }}
        >
          <MobileDrawerBody isMobileIOS={isMobileIOS}>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>
            {content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle />
    </>
  );
};

// PropTypes
EditInfoPopup2.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
EditInfoPopup2.defaultProps = {
  show: false,
  onClose: () => {},
};

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 99999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 100000 !important;
  }
}
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
  overflow-x: ${({ isMobileIOS }) => isMobileIOS && "hidden !important"};
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;
const ContentWrap = styled.div`
  padding: 0px 32px 20px 32px;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 14px;
  line-height: 22px;
  color: #333;
  position: relative;
  margin-top: 30px;
  overflow-x: ${({ isMobileIOS }) => isMobileIOS && "hidden !important"};
`;
const HeaderPopup = styled.div`
  text-align: center !important;
  font-family: Merriweather, Georgia, serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #333;
  width: 100%;
`;
const ContentBox = styled.div`
  height: auto;
  width: 562px;
  margin: 12px auto 24px;
  background-color: #fff;
  .hiddenInput {
    display: none;
  }
  @media screen and (max-width: 993px) {
    width: 100%;
    margin-top: 50px;
  }
`;
const DetailsWrapper = styled.div`
  font-size: 16px;
  text-align: center;
  line-height: 25px;
  margin-top: -15px;
  max-height: 45px !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .vehicleDetails {
    max-width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
`;

const DetailsSection = styled.div`
  margin: 25px 0;
  display: table;
  width: 100%;
  min-height: 34px;

  .detail {
    float: left;
    width: 64%;
    float: right;
    margin-top: 15px;
  }
  .detail button {
    width: 44%;
    height: 21px;
    border-radius: 10.5px;
    border: solid 1px #212121;
    color: #212121;
    font-size: 14px;
    line-height: 12px;
    margin-right: 20px;
    padding: 0 12px;
    background: #fff;
  }
  .detail button.selected {
    color: #fff;
    border: solid #1596fe 1px;
    background: #1596fe;
  }
  .editDate {
    position: relative !important;
    z-index: 1000 !important;
  }
  .DetailsSection {
    width: 67%;
  }
  .vehicleCategory {
    height: 40px !important;
  }
  @media (max-width: 993px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    margin: 0;
  }
`;

const DetailsSectionLabel = styled.div`
  float: left;
  width: 120px;
  font-size: 14px;
  color: #696969;
  font-weight: 300;
  margin-top: 15px !important;
  white-space: pre;
`;

const UpdateButton = styled.button`
  background: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  color: #fff;
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
  width: 134px;
  height: 36px;
  border-radius: 3px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 0;
  margin-top: 30px;
`;

const PremChangeWarning = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  .ncb_msg {
    //background: #f9ffc8;
    line-height: normal;
    padding-left: 52px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 90%;
    height: 48px;
  }
  .ncb_msg .image {
    background-image: url(${process.env.REACT_APP_BASENAME !== "NA"
      ? `/${process.env.REACT_APP_BASENAME}`
      : ""}/assets/images/icon/bulb.png);
    width: 93px;
    height: 92px;
    left: -28px;
  }
  .newpopup_wrapper .ncb_msg p {
    color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
    line-height: normal;
    font-size: 12px;
  }
  .messagetxt {
    margin-top: 10px;
    margin-left: 10px;
  }
  @media screen and (max-width: 993px) {
    display: none;
  }
`;

const CarLogo = styled.img`
  height: 100px;
  width: 100px;
  max-wodth: 100px;
  max-height: 100px;
  margin-right: 10px;
  // margin-top: 10px;
  //	border-radius: 20px;
  //	box-shadow: 0px 4px 13px rgba(41, 41, 41, 0.35);
  //border: 2.5px solid #bdd400;
  position: relative;
  //right: 60px;
  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
    margin-top: 2px;
  }
`;

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }

  .dateTimeOne .react-datepicker__day--keyboard-selected,
  .dateTimeOne .react-datepicker__month-text--keyboard-selected,
  .dateTimeOne .react-datepicker__quarter-text--keyboard-selected,
  .dateTimeOne .react-datepicker__year-text--keyboard-selected,
  .dateTimeThree .react-datepicker__day--keyboard-selected,
  .dateTimeThree .react-datepicker__month-text--keyboard-selected,
  .dateTimeThree .react-datepicker__quarter-text--keyboard-selected,
  .dateTimeThree .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    color: #fff;
  }
`;
export default EditInfoPopup2;
