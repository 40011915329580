import { createSlice } from "@reduxjs/toolkit";
import service from "./serviceApi";
import { actionStructre, actionStructreBoth, serializeError, toDate } from "utils";
import moment from "moment";
import _ from "lodash";
import { differenceInDays } from "date-fns";

export const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    loading: false,
    prefillLoad: null,
    error: null,
    success: null,
    save: null,
    prefill: {},
    error_other: null,
    temp_data: {},
    pincode: {},
    carPincode: {},
    financer: [],
    agreement: [],
    gender: [],
    occupation: [],
    relation: [],
    submit: null,
    previc: [],
    submitProcess: null,
    lead: null,
    checkAddon: [],
    wording: null,
    url: null,
    saveaddon: null,
    category: [],
    usage: [],
    otp: {},
    verifyOtp: null,
    otpError: null,
    duplicateEnquiry: null,
    adrila: {},
    icList: [],
    fields: null,
    ongridLoad: {},
    gridLoad: null,
    breakinEnquiry: null,
  },
  reducers: {
    loading: (state, { payload }) => {
      state.loading = true;
      state.error = null;
      state.success = null;
      switch (payload) {
        case "prefill":
          state.prefillLoad = true;
          break;
        default:
          break;
      }
    },
    success: (state, { payload }) => {
      state.loading = null;
      state.error = null;
      state.success = payload;
    },
    error: (state, { payload }) => {
      state.loading = null;
      state.error = serializeError(payload);
      state.success = null;
    },
    error_other: (state, { payload }) => {
      state.loading = null;
      state.error_other = serializeError(payload);
      state.success = null;
      state.submitProcess = null;
    },
    clear: (state, { payload }) => {
      state.loading = null;
      state.error = null;
      state.success = null;
      state.error_other = null;
      state.otpError = null;
      switch (payload) {
        case "pincode":
          state.pincode = {};
          break;
        case "submit":
          state.submit = null;
          break;
        case "car_pincode":
          state.carPincode = {};
          break;
        case "wording":
          state.wording = null;
          break;
        case "verifyOtp":
          state.verifyOtp = null;
          break;
        default:
          break;
      }
    },
    save: (state, { payload }) => {
      state.loading = null;
      state.Save = payload;
    },
    prefill: (state, { payload }) => {
      state.loading = null;
      state.prefillLoad = null;
      state.prefill = payload;
      let prefillData = {
        //login (corpId)
        corpId: payload?.corpId,
        userId: payload?.userId,
        //lead-page
        firstName: payload?.userFname,
        lastName: payload?.userLname,
        emailId: payload?.userEmail,
        mobileNo: payload?.userMobile,
        //registration-page
        journeyType:
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo &&
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo !==
            "NEW"
            ? 1
            : payload?.corporateVehiclesQuoteRequest?.businessType ===
              "newbusiness"
            ? payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ===
                "NEW" ||
                differenceInDays(
                  toDate(moment().format("DD-MM-YYYY")),
                  toDate(moment(payload?.corporateVehiclesQuoteRequest?.vehicleRegisterDate).format("DD-MM-YYYY"))
                ) <= 15
              ? 3
              : 2
            : 2,
        regNo: payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo,
        regNo1:
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo &&
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo !==
            "NEW"
            ? `${
                payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                  "-"
                )[0]
              }-${
                payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                  "-"
                )[1]
              }`
            : "",
        regNo2:
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo &&
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo !==
            "NEW"
            ? `${
                _.compact(
                  payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                    "-"
                  )
                )?.length === 4
                  ? payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                      "-"
                    )[2]
                  : ""
              }`
            : "",
        regNo3:
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo &&
          payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo !==
            "NEW"
            ? `${
                _.compact(
                  payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                    "-"
                  )
                )?.length === 4
                  ? payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                      "-"
                    )[3]
                  : _.compact(
                      payload?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo?.split(
                        "-"
                      )
                    )[2]
              }`
            : "",
        //vehicle-type
        productSubTypeId: payload?.productSubTypeId,
        productSubTypeCode: payload?.subProduct?.productSubTypeCode,
        gcvCarrierType: payload?.corporateVehiclesQuoteRequest?.gcvCarrierType,
        parent: payload?.subProduct?.parent,
        //vehicle-details
        //brand
        manfId: payload?.quoteLog?.quoteDetails?.manfactureId,
        manfName: payload?.quoteLog?.quoteDetails?.manfactureName,
        //model
        modelId: payload?.quoteLog?.quoteDetails?.model,
        modelName:
          payload?.corporateVehiclesQuoteRequest?.modelName ||
          payload?.quoteLog?.quoteDetails?.modelName,
        //fuel-type
        fuel:
          payload?.quoteLog?.quoteDetails?.fuelType ||
          payload?.corporateVehiclesQuoteRequest?.fuelType,
        kit_val:
          payload?.quoteLog?.quoteDetails?.vehicleLpgCngKitValue ||
          payload?.corporateVehiclesQuoteRequest?.vehicleLpgCngKitValue,
        kit: payload?.quoteLog?.quoteDetails?.vehicleLpgCngKitValue ? 1 : 0,
        //variant
        versionId:
          payload?.quoteLog?.quoteDetails?.version ||
          payload?.corporateVehiclesQuoteRequest?.versionId,
        versionName:
          payload?.corporateVehiclesQuoteRequest?.versionName ||
          payload?.quoteLog?.quoteDetails?.versionName,
        //rto
        rtoNumber:
          payload?.corporateVehiclesQuoteRequest?.rtoCode ||
          payload?.quoteLog?.quoteDetails?.rto ||
          payload?.quoteLog?.quoteDetails?.rtoCode,
        //year
        regDate:
          payload?.quoteLog?.quoteDetails?.vehicleRegisterDate ||
          payload?.corporateVehiclesQuoteRequest?.vehicleRegisterDate,
        manfDate:
          payload?.corporateVehiclesQuoteRequest?.manufactureYear ||
          payload?.quoteLog?.quoteDetails?.manufactureYear,
        //journey-type
        ownerTypeId:
          payload?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "I"
            ? 1
            : payload?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
            ? 2
            : null,
        //quoteData
        ncb: payload?.corporateVehiclesQuoteRequest?.previousNcb
          ? payload?.corporateVehiclesQuoteRequest?.previousNcb + "%"
          : 0,
        newNcb: payload?.corporateVehiclesQuoteRequest?.applicableNcb
          ? payload?.corporateVehiclesQuoteRequest?.applicableNcb + "%"
          : 0,
        prevIc: payload?.corporateVehiclesQuoteRequest?.previousInsurerCode,
        prevIcFullName: payload?.corporateVehiclesQuoteRequest?.previousInsurer,
        expiry:
          payload?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate,
        currentPolicyType: payload?.corporateVehiclesQuoteRequest?.businessType,
        noClaimMade:
          payload?.corporateVehiclesQuoteRequest?.isClaim === "Y"
            ? false
            : true,
        policyType: payload?.corporateVehiclesQuoteRequest?.previousPolicyType,
        leadJourneyEnd: payload?.leadStageId >= 2 ? true : false,
        vehicleLpgCngKitValue:
          payload?.quoteLog?.quoteDetails?.vehicleLpgCngKitValue || 0,
        vehicleIdv: payload?.corporateVehiclesQuoteRequest?.editIdv,
        isIdvChanged:
          payload?.corporateVehiclesQuoteRequest?.isIdvChanged === "Y"
            ? true
            : false,
        isOdDiscountApplicable:
          payload?.corporateVehiclesQuoteRequest?.isOdDiscountApplicable === "Y"
            ? true
            : false,
        vehicleIdvType:
          payload?.corporateVehiclesQuoteRequest?.idvChangedType || "avgIdv",
        addons: !_.isEmpty(payload?.addons) ? payload?.addons[0] : [],
        carOwnership:
          payload?.corporateVehiclesQuoteRequest?.ownershipChanged === "Y"
            ? true
            : false,
        newCar:
          payload?.corporateVehiclesQuoteRequest?.businessType ===
          "newbusiness",
        breakIn:
          payload?.corporateVehiclesQuoteRequest?.businessType === "breakin",
        //proposal
        userProposal: payload?.userProposal,
        selectedQuote: payload?.quoteLog?.premiumJson
          ? payload?.quoteLog?.premiumJson
          : {},
        fastlaneJourney:
          payload?.corporateVehiclesQuoteRequest?.isFastlane == "Y"
            ? true
            : false,
        fastlaneNcbPopup:
          payload?.corporateVehiclesQuoteRequest?.isPopupShown == "N" &&
          (payload?.corporateVehiclesQuoteRequest?.isFastlane == "Y" ||
            payload?.corporateVehiclesQuoteRequest?.journeyType == "fastlane")
            ? true
            : false,
        odOnly:
          payload?.corporateVehiclesQuoteRequest?.policyType == "own_damage"
            ? true
            : false,

        isNcbVerified:
          payload?.corporateVehiclesQuoteRequest?.isNcbVerified === "Y"
            ? "Y"
            : "N",
        //quotes-data
        quoteLog: payload?.quoteLog,
        corporateVehiclesQuoteRequest: payload?.corporateVehiclesQuoteRequest,
        prevShortTerm:
          payload?.corporateVehiclesQuoteRequest?.prevShortTerm * 1,
        //addons
        addons: !_.isEmpty(payload?.addons) ? payload?.addons[0] : [],
        //agent data
        quoteAgent: payload?.quoteAgent,
        //journey Stage
        journeyStage: payload?.journeyStage,
        //agent details
        agentDetails: payload?.agentDetails,
        rtoCity: payload.corporateVehiclesQuoteRequest?.rtoCity,
        corporateVehiclesQuoteRequest: payload?.corporateVehiclesQuoteRequest,
        isClaim: payload?.isClaim?.corporateVehiclesQuoteRequest,
        isClaimVerified:
          payload?.corporateVehiclesQuoteRequest?.isClaimVerified,
        isToastShown: payload?.corporateVehiclesQuoteRequest?.isToastShown,
        isRedirectionDone:
          payload?.corporateVehiclesQuoteRequest?.isRedirectionDone,
        isRenewalRedirection:
          payload?.corporateVehiclesQuoteRequest?.isRenewalRedirection,
        prefillPolicyNumber:
          payload?.corporateVehiclesQuoteRequest?.prefillPolicyNumber,
        breakinGenerationDate: payload?.cvBreakinDetails?.breakinGenerationDate,
      };
      state.temp_data = { ...state.temp_data, ...prefillData };
    },
    pincode: (state, { payload }) => {
      state.pincode = payload;
    },
    carPincode: (state, { payload }) => {
      state.carPincode = payload;
    },
    financer: (state, { payload }) => {
      state.financer = payload;
    },
    agreement: (state, { payload }) => {
      state.agreement = payload;
    },
    gender: (state, { payload }) => {
      state.gender = payload;
    },
    occupation: (state, { payload }) => {
      state.occupation = payload;
    },
    relation: (state, { payload }) => {
      state.relation = payload;
    },
    submit: (state, { payload }) => {
      state.submitProcess = null;
      state.submit = payload;
    },
    prevIc: (state, { payload }) => {
      state.prevIc = payload;
    },
    submitProcess: (state, { payload }) => {
      state.submitProcess = true;
    },
    clearProcess: (state, { payload }) => {
      state.submitProcess = null;
    },
    lead: (state, { payload }) => {
      state.lead = payload;
    },
    checkAddon: (state, { payload }) => {
      state.checkAddon = payload;
    },
    error_hide: (state, { payload }) => {
      // state.checkAddon = payload;
    },
    wording: (state, { payload }) => {
      state.wording = payload;
    },
    url: (state, { payload }) => {
      state.url = payload;
    },
    saveaddon: (state, { payload }) => {
      state.saveaddon = payload;
    },
    category: (state, { payload }) => {
      state.category = payload;
    },
    usage: (state, { payload }) => {
      state.usage = payload;
    },
    otp: (state, { payload }) => {
      state.otp = payload;
    },
    verifyOtp: (state, { payload }) => {
      state.verifyOtp = payload;
    },
    otpError: (state, { payload }) => {
      state.otpError = serializeError(payload);
    },
    setDuplicateEnquiry: (state, { payload }) => {
      state.duplicateEnquiry = payload;
    },
    setBreakinEnquiry: (state, { payload }) => {
      state.breakinEnquiry = payload;
    },
    clrDuplicateEnquiry: (state, { payload }) => {
      state.duplicateEnquiry = null;
      state.breakinEnquiry = null;
    },
    adrila: (state, { payload }) => {
      state.adrila = payload;
      state.temp_data = {
        ...state.temp_data,
        userProposal: payload?.additional_details
          ? _.mapKeys(payload?.additional_details, (value, key) =>
              _.camelCase(key)
            )
          : null,
      };
    },
    icList: (state, { payload }) => {
      state.icList = payload;
    },
    fields: (state, { payload }) => {
      state.fields = payload;
    },
    ongridLoad: (state, { payload }) => {
      state.ongridLoad = payload;
      state.gridLoad = false;
    },
    gridLoad: (state, { payload }) => {
      state.gridLoad = payload;
    },
    gridError: (state, { payload }) => {
      state.gridLoad = false;
    },
  },
});

export const {
  loading,
  success,
  error,
  clear,
  save,
  prefill,
  error_other,
  pincode,
  carPincode,
  financer,
  agreement,
  gender,
  occupation,
  relation,
  submit,
  prevIc,
  submitProcess,
  clearProcess,
  lead,
  checkAddon,
  error_hide,
  wording,
  url,
  saveaddon,
  category,
  usage,
  otp,
  verifyOtp,
  otpError,
  setDuplicateEnquiry,
  clrDuplicateEnquiry,
  adrila,
  icList,
  fields,
  ongridLoad,
  gridLoad,
  gridError,
  setBreakinEnquiry,
} = proposalSlice.actions;

export const Save = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, save, error, service.save, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//submit
export const SubmitData = (data, typeRoute) => {
  return async (dispatch) => {
    try {
      dispatch(submitProcess());
      actionStructreBoth(dispatch, submit, error_other, service.submit, {
        data,
        typeRoute,
      });
    } catch (err) {
      dispatch(clearProcess());
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Prefill
export const Prefill = (data, check) => {
  return async (dispatch) => {
    try {
      if (!check) {
        dispatch(loading("prefill"));
      }
      actionStructre(dispatch, prefill, error_other, service.prefill, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//pincode
export const Pincode = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, pincode, error_other, service.pincode, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Car pincode
export const CarPincode = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, carPincode, error_other, service.pincode, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//financer
export const getFinancer = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, financer, error_other, service.financer, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//agreement
export const getAgreement = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, agreement, error_other, service.agreement, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//gender
export const getGender = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, gender, error_other, service.gender, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//occupation
export const getOccupation = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(
        dispatch,
        occupation,
        error_other,
        service.occupation,
        data
      );
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Relations
export const getRelation = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, relation, error_other, service.relations, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Relations
export const PrevIc = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, prevIc, error_other, service.prevIc, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//trigger lead
export const Lead = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, lead, error_other, service.saveLeadData, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//trigger lead
export const CheckAddon = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(
        dispatch,
        checkAddon,
        error_hide,
        service.checkAddon,
        data
      );
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Wordings
export const Wording = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, wording, error_hide, service.wording, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Url
export const Url = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, url, error_hide, service.url, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Save addon
export const SaveAddon = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, saveaddon, error_hide, service.saveAddons, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Save addon
export const Category = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, category, error_hide, service.category, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Save addon
export const Usage = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, usage, error_hide, service.usage, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//get OTP
export const OTP = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, otp, error_hide, service.otp, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//verify OTP
export const VerifyOTP = (data) => {
  return async (dispatch) => {
    try {
      actionStructre(dispatch, verifyOtp, otpError, service.verifyOtp, data);
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//Duplicate Enquiry
export const DuplicateEnquiryId = (data, breakinCase) => {
  return async (dispatch) => {
    try {
      actionStructre(
        dispatch,
        breakinCase ? setBreakinEnquiry : setDuplicateEnquiry,
        error_other,
        service.duplicateEnquiry,
        data
      );
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//verify OTP
export const AdrilaLoad = (data, type) => {
  return async (dispatch) => {
    try {
      type && dispatch(gridLoad(true));
      !type &&
        actionStructre(dispatch, adrila, error_hide, service.adrila, data);
      type &&
        actionStructreBoth(
          dispatch,
          ongridLoad,
          gridError,
          service.adrila,
          data
        );
    } catch (err) {
      type && dispatch(gridLoad(false));
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//all ic
export const GetIc = (baseUrl) => {
  return async (dispatch) => {
    try {
      const {
        data,
        message,
        errors,
        success: s,
      } = await service.getIc(baseUrl);
      if (data?.data || s) {
        dispatch(icList(data?.data || message));
      } else {
        dispatch(error(errors || message));
        console.error("Error", errors || message);
      }
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//all fields
export const GetFields = (payload, Broker) => {
  return async (dispatch) => {
    try {
      const {
        data,
        message,
        errors,
        success: s,
      } = await service.fields(payload, Broker);
      if (data?.data || s) {
        dispatch(fields(data?.data || message));
      } else {
        dispatch(error(errors || message));
        console.error("Error", errors || message);
      }
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

//all fields
export const SetFields = (payload, Broker) => {
  return async (dispatch) => {
    try {
      const {
        data,
        message,
        errors,
        success: s,
      } = await service.setFields(payload, Broker);
      if (data?.data || s) {
        dispatch(success(data?.data || message));
      } else {
        dispatch(error(errors || message));
        console.error("Error", errors || message);
      }
    } catch (err) {
      dispatch(error("Something went wrong"));
      console.error("Error", err);
    }
  };
};

export default proposalSlice.reducer;
