import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useMediaPredicate } from "react-media-hook";
const FloatButton = () => {
  const { quoteComprehesive, quotetThirdParty, quoteShortTerm } = useSelector(
    (state) => state.quotes
  );
  const location = useLocation();
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const loc = location.pathname ? location.pathname.split("/") : "";
  const type = !_.isEmpty(loc) ? (loc?.length >= 2 ? loc[1] : "") : "";
  const includeRouteShare = [
    `/${type}/quotes`,
    `/${type}/compare-quote`,
    `/${type}/proposal-page`,
    `/${type}/payment-confirmation`,
    `/payment-success`,
  ];
  const ContactFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "7829411222";
      case "UNILIGHT":
        return "18002101225";
      case "CP":
        return "1800120000055";
      case "FYNTUNE":
        return "1800120000065";
      case "ABIBL":
        return "18002707000";
      case "GRAM":
        return "+91 9311672463";
      case "ACE":
        return "+918750881122";
      case "SRIYAH":
        return "+1800 203 0504";
      case "PINC":
        return "18002672670";
      case "EPOCH":
        return "1800-12000-0065";
      case "RB":
        return "18004197852";
      case "SPA":
        return "+91-11-45675555";
      case "BIMA":
        return "9999366494";
      case "ANALAH":
        return "+91 9920878181";
      case "BAJAJ":
        return "1800 212 123123";
      case "UIB":
        return "+91 79820 39210";
      default:
        return "18002669639";
    }
  };
  return (
    <StyledDiv>
      <div id="">
        {/* <a role="button" onClick={() => {}} title="Menu" id="share">
					<i class="fa fa-bars my-social"></i>
				</a> */}
        {includeRouteShare.includes(location.pathname) && (
          <a
            style={{
              position: "fixed",
              top: "76%",
              right: "1%",
              zIndex: "9",
              visibility:
                (quoteComprehesive && quoteComprehesive.length >= 1) ||
                (quotetThirdParty && quotetThirdParty.length >= 1) ||
                (quoteShortTerm && quoteShortTerm.length >= 1) ||
                loc[2] === "proposal-page" ||
                loc[1] === "payment-success"
                  ? "visible"
                  : "hidden",
            }}
            className="floatBtn"
            role="button"
            onClick={() =>
              document?.getElementById("shareQuotes1") &&
              document?.getElementById("shareQuotes1").click()
            }
            title="share"
            id="reddit"
          >
            <i class="fa fa-share-alt my-social"></i>
          </a>
        )}
        <a
          style={{ position: "fixed", top: "85%", right: "1%", zIndex: "9" }}
          className="floatBtn"
          role="button"
          href={lessthan767 && `tel:${ContactFn()}`}
          onClick={() =>
            !lessthan767 &&
            document?.getElementById("callus1") &&
            document?.getElementById("callus1").click()
          }
          title="call us"
          id="linkedin"
        >
          <i class="fa fa-phone my-social"></i>
        </a>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #social-share {
    position: fixed;
    right: 0px;
    top: 85%;
    z-index: 10;
  }

  #social-share a {
    text-decoration: none;
    float: right;
  }

  #social-share a:not(:first-child) {
    animation: fadeOut 0.5s;
    display: none;
  }

  #social-share:hover a:not(:first-child) {
    display: inline;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }

  #social-share:hover .fa-share-alt {
    border-radius: 0px;
  }
  #social-share:hover .fa-phone {
    border-radius: 0px;
  }

  #social-share a .my-social {
    border-right: 0;
  }

  #social-share a .fa-phone {
    color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }

  #social-share a .fa-bars {
    color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }

  #social-share > a > .fa-share-alt {
    color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
    border-right: 0;
    padding: 8px 10px;
    border-radius: 3px 0px 0px 3px;
  }

  #social-share #whatsapp:focus > .fa-share-alt,
  #social-share .fa-share-alt:hover {
    background-color: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    border-collapse: #c7222a;
    color: #fff;
  }

  #social-share #linkedin:focus > .fa-share-alt,
  #social-share .fa-share-alt:hover {
    background-color: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    border-collapse: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    color: #fff;
  }

  #social-share #reddit:focus > .fa-share-alt,
  #social-share .fa-share-alt:hover {
    background-color: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    border-collapse: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    color: #fff;
  }

  #social-share #share:focus > .fa-bars,
  #social-share .fa-bars:hover {
    color: #fff;
    background-color: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    border-color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }

  #social-share #linkedin:focus > .fa-phone,
  #social-share .fa-phone:hover {
    color: #fff;
    background-color: ${({ theme }) =>
      theme.floatButton?.floatColor || "#bdd400"};
    border-color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }

  .my-social {
    font-size: 1.5em;
    border: ${({ theme }) =>
      theme.floatButton?.floatBorder || "1px solid #bdd400"};
    padding: 8px 10px;
    cursor: pointer;
    transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -moz-o-trasition: all 0.4s ease-out;
    -o-trasition: all 0.4s ease-out;
  }
  .floatBtn {
    color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }
  .floatBtn:hover {
    background: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
    color: #fff;
  }
`;

export default FloatButton;
