import React, { useEffect, useState, useMemo } from "react";
import { Row } from "react-bootstrap";
import { useLocation, useHistory } from "react-router";
import swal from "sweetalert";
import { Loader, BackButton, FloatButton } from "components";
import _ from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import InfoCard from "./info-card";
import FormSection from "./form-section";
import { useDispatch, useSelector } from "react-redux";
import {
  Prefill,
  CheckAddon,
  clear,
  Url,
  set_temp_data,
  DuplicateEnquiryId,
  clrDuplicateEnquiry,
  AdrilaLoad,
  GetFields,
} from "./proposal.slice";
import { reloadPage, Encrypt, toDate } from "utils";
import { useMedia, useMediaPredicate } from "react-media-hook";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import moment from "moment";
import {
  LinkTrigger,
  Prefill as PrefillHome,
  SaveQuoteData,
  clear as clr,
} from "modules/Home/home.slice";
import { fieldList } from "modules/proposal/field-config/helper";
import { cpaSet as setCpa } from "modules/quotesPage/quote.slice";
import { TypeReturn } from "modules/type";
import { differenceInDays } from "date-fns";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

export const Proposal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    temp_data: temp,
    error_other,
    prefillLoad,
    duplicateEnquiry,
    fields,
    ongridLoad,
    breakinEnquiry,
  } = useSelector((state) => state.proposal);
  const { typeAccess } = useSelector((state) => state.login);
  const { saveQuoteData } = useSelector((state) => state.home);
  const { cpaSet } = useSelector((state) => state.quotes);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const typeId = query.get("typeid");
  const openTo = query.get("openTo");
  const journey_type = query.get("journey_type");
  const key = query.get("key");
  const icr = query.get("icr");
  const dropout =
    query.get("dropout") ||
    (["Proposal Accepted", "Payment Initiated", "Payment Failed"].includes(
      temp?.journeyStage?.stage
    )
      ? "true"
      : false);
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const selectedQuote = !_.isEmpty(temp?.selectedQuote)
    ? temp?.selectedQuote
    : {};
  const Additional = !_.isEmpty(temp?.addons) ? temp?.addons : {};
  const { type } = props?.match?.params;
  const lessthan767 = useMediaPredicate("(max-width: 767px)");

  let userAgent = navigator.userAgent;
  let isMobileIOS = false; //initiate as false
  // device detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && lessthan767) {
    isMobileIOS = true;
  }
  console.log("ongridLoad", ongridLoad);
  /*--------------- Access-Control-------------------*/
  //Access-Control
  useEffect(() => {
    if (!_.isEmpty(typeAccess)) {
      if (type) {
        const AccessControl = !_.isEmpty(typeAccess)
          ? _.compact(
              typeAccess.map((item) =>
                item?.product_sub_type_code
                  ? item?.product_sub_type_code.toLowerCase()
                  : null
              )
            )
          : [];
        let typeRt = TypeReturn(type) === "cv" ? "pcv" : TypeReturn(type);
        let typeRt1 = TypeReturn(type) === "cv" ? "gcv" : TypeReturn(type);
        if (
          !(AccessControl.includes(typeRt) || AccessControl.includes(typeRt1))
        ) {
          swal(
            "Error",
            "Access Control Error. Please contact the administrator for clearance.",
            "error",
            { closeOnClickOutside: false }
          ).then(() => history.replace("/"));
        }
      }
    }
  }, [typeAccess]);

  //Link-Click & Delivery
  useEffect(() => {
    key && dispatch(LinkTrigger({ key: key }));
  }, [key]);

  /*---------------- back button---------------------*/
  const back = () => {
    history.push(
      `/${type}/quotes?enquiry_id=${enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}${
        journey_type ? `&journey_type=${journey_type}` : ``
      }`
    );
  };
  /*----------x----- back button-------x-------------*/

  //no enquiry id
  useEffect(() => {
    if (!enquiry_id) {
      history.replace(
        `/${type}/lead-page${token ? `?token=${token}` : ``}${
          typeId ? `&typeid=${typeId}` : ``
        }${journey_type ? `&journey_type=${journey_type}` : ``}`
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //prefill Api
  useEffect(() => {
    dispatch(Prefill({ enquiryId: enquiry_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //onError
  useEffect(() => {
    if (error_other) {
      swal(
        "Error",
        `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error_other}`}`,
        "error"
      ).then(() => {
        if (error_other === "Payment Initiated") {
          window.location.reload();
        }
      });
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_other]);

  //clearing cpa status
  useEffect(() => {
    if (cpaSet) {
      dispatch(Prefill({ enquiryId: enquiry_id }, true));
      dispatch(PrefillHome({ enquiryId: enquiry_id }, true));
    }

    return () => {
      dispatch(setCpa(false));
    };
  }, [cpaSet]);

  //get available addons/accessories
  useEffect(() => {
    if (temp?.quoteLog?.icId)
      dispatch(
        CheckAddon({ icId: temp?.quoteLog?.icId, enquiryId: enquiry_id })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp]);

  //Adreilla Prefill
  useEffect(() => {
    if (
      temp?.regNo &&
      temp?.regNo !== "NEW" &&
      process.env.REACT_APP_BROKER === "ACE" &&
      !temp?.userProposal &&
      _.isEmpty(temp?.userProposal)
    ) {
      dispatch(
        AdrilaLoad({
          registration_no: temp?.regNo,
          enquiryId: enquiry_id,
          type: "PRO",
          section: TypeReturn(type),
        })
      );
    }
  }, [temp?.regNo]);

  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola-dashboard.fynity.in/";
      case "UNILIGHT":
        return "https://www.policyongo.com/pos/login";
      case "CP":
        return "https://www.comparepolicy.com/";
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return "http://preprod-dasbhoard-abibl.fynity.in/";
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-abibl.fynity.in/";
        } else {
          return "http://abibl-prod-dashboard.fynity.in/";
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-gramcover.fynity.in/";
        } else {
          return "https://dashboard.gramcover.com/";
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://live.pincnews.co.in/"
          : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return `https://www.bajajcapitalinsurance.com/`;
      case "UIB":
        return ``;
      default:
        break;
    }
  };

  const getLogoUrl = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/ola.png`;
      case "UNILIGHT":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/unilight.png`;
      case "CP":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/cp.png`;
      case "FYNTUNE":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/fyntune.png`;
      case "ABIBL":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/abiblPdf.jpeg`;
      case "GRAM":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/gc.jpg`;
      case "ACE":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/ace.png`;
      case "SRIYAH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/sriyah.png`;
      case "PINC":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/pinc.png`;
      case "EPOCH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/epochlogo.png`;
      case "RB":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/rb.png`;
      case "SPA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/insuringall.jpeg`;
      case "BIMA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bima.png`;
      case "ANALAH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/analah.jpeg`;
      case "BAJAJ":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bajaj.png`;
      case "UIB":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/uib.png`;
      default:
        break;
    }
  };

  // On breakin Status or resubmission after payment success
  const PaymentSuccessfulStages = [
    "Policy Issued And PDF Generated",
    "Policy Issued",
    "Policy Issued, but pdf not generated",
    "payment success",
  ];

  useEffect(() => {
    if (PaymentSuccessfulStages.includes(temp?.journeyStage?.stage)) {
      swal("Info", "This Proposal has already been submitted", "info").then(
        () =>
          temp?.journeyStage?.stage !== "Payment Failed"
            ? reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
            : reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-failure${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp?.journeyStage?.stage]);

  //Url
  useMemo(() => {
    if (
      enquiry_id &&
      temp?.journeyStage?.stage &&
      type?.toLowerCase() !== "null" &&
      !(
        temp?.userProposal?.isBreakinCase === "y" ||
        temp?.userProposal?.isBreakinCase === "Y"
      )
    ) {
      ![
        "Proposal Accepted",
        "Payment Initiated",
        "pg_redirection",
        "Policy Issued",
        "Policy Issued, but pdf not generated",
        "Policy Issued And PDF Generated",
        "payment success",
        "Payment Failed",
        "Inspection Accept",
      ].includes(temp?.journeyStage?.stage) &&
        dispatch(
          Url({
            proposalUrl: window.location.href,
            quoteUrl: window.location.href
              ? window.location.href?.replace(/proposal-page/g, "quotes")
              : "",
            stage: "Proposal Drafted",
            userProductJourneyId: enquiry_id,
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp?.journeyStage?.stage]);

  useEffect(() => {
    if (duplicateEnquiry?.enquiryId) {
      reloadPage(
        `${window.location.protocol}//${window.location.host}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ``
        }/${type}/proposal-page?enquiry_id=${duplicateEnquiry?.enquiryId}${
          token ? `&token=${token}` : ""
        }${dropout ? `&dropout=${Encrypt(true)}` : ""}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}`
      );
    }
    return () => {
      dispatch(clrDuplicateEnquiry());
    };
  }, [duplicateEnquiry]);

  //breakin enquiry
  useEffect(() => {
    if (breakinEnquiry?.enquiryId) {
      reloadPage(
        `${window.location.protocol}//${window.location.host}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ``
        }/${type}/quotes?enquiry_id=${breakinEnquiry?.enquiryId}${
          token ? `&token=${token}` : ""
        }${dropout ? `&dropout=${Encrypt(true)}` : ""}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}`
      );
    }
    return () => {
      dispatch(clrDuplicateEnquiry());
    };
  }, [breakinEnquiry]);

  const GenerateDulicateEnquiry = (breakinExp) => {
    //If Payment has been initiated and left incomplete then a new Enquiry Id is generated for the user.
    if (
      temp?.journeyStage?.stage === "Payment Initiated" ||
      temp?.journeyStage?.stage === "Payment Failed" ||
      breakinExp
    ) {
      dispatch(
        DuplicateEnquiryId(
          {
            enquiryId: enquiry_id,
            ...(breakinExp && { isBreakinExpired: true }),
          },
          breakinExp
        )
      );
    }
  };

  /*---- Payment Timeout ----*/
  const JourneyUpdatedAt = !_.isEmpty(temp)
    ? temp?.journeyStage?.updatedAt
    : null;

  //Time b/w proposal journey state updated and now in seconds
  const TimeDiff =
    JourneyUpdatedAt &&
    moment().diff(moment(JourneyUpdatedAt, "YYYY-MM-DD hh-mm-ss"), "seconds");
  const overTime = Number(TimeDiff) < 600 ? 600 - TimeDiff : 0;

  const innerWidth = window.innerWidth;

  //Field Configurator

  const [fieldsPresent, setFieldPresent] = useState(false);

  useEffect(() => {
    if (
      !fieldsPresent &&
      temp?.selectedQuote?.companyAlias &&
      temp?.corporateVehiclesQuoteRequest?.vehicleOwnerType
    )
      console.log("fired");
    dispatch(
      GetFields({
        company_alias: temp?.selectedQuote?.companyAlias,
        section: TypeReturn(type),
        owner_type: temp?.corporateVehiclesQuoteRequest?.vehicleOwnerType,
      })
    );
    setFieldPresent(true);
  }, [
    temp?.selectedQuote?.companyAlias,
    temp?.corporateVehiclesQuoteRequest?.vehicleOwnerType,
  ]);

  useEffect(() => {
    //excluding breakin journey s and bike product
    //Rollover to Breakin Transition.
    if (
      TypeReturn(type) !== "bike" &&
      !_.isEmpty(temp) &&
      temp?.corporateVehiclesQuoteRequest?.businessType !== "breakin" &&
      temp?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate &&
      temp?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate !== "New"
    ) {
      //comparing expiry date with current date
      let expDateObj = new Date([
        temp?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate.split(
          "-"
        )[2],
        temp?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate.split(
          "-"
        )[1],
        temp?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate.split(
          "-"
        )[0],
      ]);
      if (
        expDateObj.setHours(0, 0, 0, 0).valueOf() <
        new Date().setHours(0, 0, 0, 0).valueOf()
      ) {
        //changing bussinessType
        dispatch(
          SaveQuoteData({
            businessType: "breakin",
            enquiryId: enquiry_id,
            stage: 11,
            vehicleRegistrationNo:
              temp?.journeyType === 3
                ? "NEW"
                : temp?.regNo
                ? temp?.regNo
                : Number(temp?.regDate?.slice(temp?.regDate?.length - 4)) ===
                    new Date().getFullYear() && temp?.newCar
                ? "NEW"
                : "NULL",
            userProductJourneyId: temp?.enquiry_id || enquiry_id,
            productSubTypeId: temp?.productSubTypeId,
            fullName: temp?.firstName + " " + temp?.lastName,
            firstName: temp?.firstName,
            lastName: temp?.lastName,
            emailId: temp?.emailId,
            mobileNo: temp?.mobileNo,
            policyType:
              temp?.odOnly && TypeReturn(type) !== "cv"
                ? "own_damage"
                : "comprehensive",
            //------------------
            rto: temp?.rtoNumber,
            manfactureId: temp?.manfId,
            manufactureYear: temp?.manfDate,
            //	motorManufactureYear: temp?.manfDate,
            model: temp?.modelId,
            version: temp?.versionId,
            versionName: temp?.versionName,
            vehicleRegisterAt: temp?.rtoNumber,
            vehicleRegisterDate: temp?.regDate,
            vehicleOwnerType: temp?.ownerTypeId === 2 ? "C" : "I",
            policyExpiryDate:
              temp?.expiry === "Not Sure" || temp?.expiry === "New"
                ? "New"
                : temp?.expiry,
            hasExpired: temp?.policyExpired ? "yes" : "no",
            isNcb: temp?.ncb ? "Yes" : "No",

            isClaim: temp?.noClaimMade ? "N" : "Y",
            previousNcb: (temp?.ncb && temp?.ncb?.slice(0, -1)) || 0,
            applicableNcb: (temp?.newNcb && temp?.newNcb?.slice(0, -1)) || 0,

            fuelType: temp?.fuel,
            vehicleUsage: temp?.carrierType || 2,

            vehicleLpgCngKitValue: temp?.kit_val ? temp?.kit_val : "",

            previousInsurer:
              temp?.prevIcFullName !== "NEW"
                ? temp?.prevIcFullName === "New"
                  ? "NEW"
                  : temp?.prevIcFullName
                : "NEW",
            previousInsurerCode:
              temp?.prevIc !== "NEW"
                ? temp?.prevIc === "New"
                  ? "NEW"
                  : temp?.prevIc
                : "NEW",

            previousPolicyType:
              temp?.corporateVehiclesQuoteRequest?.previousPolicyType,
            modelName: temp?.modelName,
            manfactureName: temp?.manfName,
            ownershipChanged: temp?.carOwnership ? "Y" : "N",
            leadJourneyEnd: temp?.leadJourneyEnd ? temp?.leadJourneyEnd : false,
            isNcbVerified: temp?.isNcbVerified === "Y" ? "Y" : "N",
            isClaimVerified: temp?.isClaimVerified,
          })
        );
      }
    }

    //NB Registration Date Update
    // if (temp?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo === "NEW") {
    //   let regDateObj = new Date([
    //     temp?.corporateVehiclesQuoteRequest?.vehicleRegisterDate.split("-")[2],
    //     temp?.corporateVehiclesQuoteRequest?.vehicleRegisterDate.split("-")[1],
    //     temp?.corporateVehiclesQuoteRequest?.vehicleRegisterDate.split("-")[0],
    //   ]);

    //   if (
    //     regDateObj.setHours(0, 0, 0, 0).valueOf() <
    //     new Date().setHours(0, 0, 0, 0).valueOf()
    //   ) {
    //     //changing registration date
    //     dispatch(
    //       SaveQuoteData({
    //         businessType: temp?.corporateVehiclesQuoteRequest?.businessType,
    //         enquiryId: enquiry_id,
    //         stage: 11,
    //         vehicleRegistrationNo:
    //           temp?.journeyType === 3
    //             ? "NEW"
    //             : temp?.regNo
    //             ? temp?.regNo
    //             : Number(temp?.regDate?.slice(temp?.regDate?.length - 4)) ===
    //                 new Date().getFullYear() && temp?.newCar
    //             ? "NEW"
    //             : "NULL",
    //         userProductJourneyId: temp?.enquiry_id || enquiry_id,
    //         productSubTypeId: temp?.productSubTypeId,
    //         fullName: temp?.firstName + " " + temp?.lastName,
    //         firstName: temp?.firstName,
    //         lastName: temp?.lastName,
    //         emailId: temp?.emailId,
    //         mobileNo: temp?.mobileNo,
    //         policyType: temp?.corporateVehiclesQuoteRequest?.policyType,
    //         //------------------
    //         rto: temp?.rtoNumber,
    //         manfactureId: temp?.manfId,
    //         manufactureYear: temp?.manfDate,
    //         //	motorManufactureYear: temp?.manfDate,
    //         model: temp?.modelId,
    //         version: temp?.versionId,
    //         versionName: temp?.versionName,
    //         vehicleRegisterAt: temp?.rtoNumber,
    //         vehicleRegisterDate: temp?.regDate,
    //         vehicleOwnerType: temp?.ownerTypeId === 2 ? "C" : "I",
    //         policyExpiryDate:
    //           temp?.expiry === "Not Sure" || temp?.expiry === "New"
    //             ? "New"
    //             : temp?.expiry,
    //         hasExpired: temp?.policyExpired ? "yes" : "no",
    //         isNcb: temp?.ncb ? "Yes" : "No",

    //         isClaim: temp?.noClaimMade ? "N" : "Y",
    //         previousNcb: (temp?.ncb && temp?.ncb?.slice(0, -1)) || 0,
    //         applicableNcb: (temp?.newNcb && temp?.newNcb?.slice(0, -1)) || 0,

    //         fuelType: temp?.fuel,
    //         vehicleUsage: temp?.carrierType || 2,

    //         vehicleLpgCngKitValue: temp?.kit_val ? temp?.kit_val : "",

    //         previousInsurer:
    //           temp?.prevIcFullName !== "NEW"
    //             ? temp?.prevIcFullName === "New"
    //               ? "NEW"
    //               : temp?.prevIcFullName
    //             : "NEW",
    //         previousInsurerCode:
    //           temp?.prevIc !== "NEW"
    //             ? temp?.prevIc === "New"
    //               ? "NEW"
    //               : temp?.prevIc
    //             : "NEW",

    //         previousPolicyType:
    //           temp?.corporateVehiclesQuoteRequest?.previousPolicyType,
    //         modelName: temp?.modelName,
    //         manfactureName: temp?.manfName,
    //         ownershipChanged: temp?.carOwnership ? "Y" : "N",
    //         leadJourneyEnd: temp?.leadJourneyEnd ? temp?.leadJourneyEnd : false,
    //         isNcbVerified: temp?.isNcbVerified === "Y" ? "Y" : "N",
    //         isClaimVerified: temp?.isClaimVerified,
    //       })
    //     );
    //   }
    // }

    //72 hrs breakin condition ( bike excluded )
    if (
      TypeReturn(type) !== "bike" &&
      !_.isEmpty(temp) &&
      temp?.corporateVehiclesQuoteRequest?.businessType === "breakin" &&
      temp?.selectedQuote?.companyAlias === "icici_lombard" &&
      temp?.breakinGenerationDate
    ) {
      // checking for 72 hours/3 days limit.
      if (
        differenceInDays(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(moment(temp?.breakinGenerationDate).format("DD-MM-YYYY"))
        ) > 3
      ) {
        swal("Please Note", "This proposal has expired", "info").then(() =>
          GenerateDulicateEnquiry(true)
        );
      }
    }
  }, [temp]);

  //clearing saveQuotedata on load
  useEffect(() => {
    dispatch(clr("saveQuoteData"));
  }, []);

  //after saveQuoteData
  useEffect(() => {
    if (saveQuoteData) {
      swal("Please Note", "This proposal has expired", "info").then(
        () =>
          reloadPage(window.location.href.replace(/proposal-page/g, "quotes"))
        // history.go(
        //   `/${type}/quotes?enquiry_id=${enquiry_id}${
        //     token ? `&token=${token}` : ``
        //   }${typeId ? `&typeid=${typeId}` : ``}${
        //     journey_type ? `&journey_type=${journey_type}` : ``
        //   }`
        // )
      );
    }
    return () => {
      dispatch(clr("saveQuoteData"));
    };
  }, [saveQuoteData]);

  return (
    <StyledDiv innerWidth={innerWidth} isMobileIOS={isMobileIOS}>
      <div
        className="backBtn"
        style={!lessthan767 ? { paddingBottom: "30px" } : {}}
      >
        {(!(
          temp?.userProposal?.isBreakinCase === "Y" ||
          temp?.userProposal?.isBreakinCase === "y"
        ) ||
          TypeReturn(type) === "bike") &&
          process.env?.REACT_APP_BROKER !== "ABIBL" &&
          !lessthan767 &&
          !icr && (
            <BackButton
              type="button"
              onClick={back}
              style={lessthan767 ? {} : { zIndex: "9999" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                viewBox="0 0 24 24"
              >
                <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
              <text style={{ color: "black" }}>Back</text>
            </BackButton>
          )}
      </div>
      {!prefillLoad ? (
        <RowTag className="row-dimension-design">
          <DivTag1
            className={`col-12 col-lg-3 col-xs-12 col-sm-12 col-md-4 ${
              lessthan767 ? "mb-4" : ""
            }`}
          >
            <InfoCard
              selectedQuote={selectedQuote}
              enquiry_id={enquiry_id}
              Additional={Additional}
              type={type}
              token={token}
              Theme={Theme}
              breakinCase={
                temp?.userProposal?.isBreakinCase === "Y" ||
                temp?.userProposal?.isBreakinCase === "y"
              }
              lessthan767={lessthan767}
              GenerateDulicateEnquiry={GenerateDulicateEnquiry}
              journey_type={journey_type}
              typeId={typeId}
              icr={icr}
              TypeReturn={TypeReturn}
            />
          </DivTag1>
          <DivTag2 className="col-12 col-lg-9 col-sm-12 col-xs-12 col-md-8">
            <H4Tag>Almost Done! Just a few more details.</H4Tag>
            <FormSection
              temp={temp}
              Additional={Additional}
              token={token}
              enquiry_id={enquiry_id}
              dropout={dropout}
              type={type}
              openTo={openTo}
              breakinCase={
                temp?.userProposal?.isBreakinCase === "Y" ||
                temp?.userProposal?.isBreakinCase === "y"
              }
              getLogoUrl={getLogoUrl}
              overTime={
                overTime &&
                temp?.journeyStage?.stage === "Payment Initiated" &&
                false
                  ? overTime
                  : false
              }
              typeId={typeId}
              GenerateDulicateEnquiry={GenerateDulicateEnquiry}
              DuplicateEnquiryId={DuplicateEnquiryId}
              journey_type={journey_type}
              icr={icr}
              fields={
                // process.env.REACT_APP_BROKER === "OLA" || process.env.REACT_APP_BROKER === "FYNTUNE"
                //?
                fields ? fields : []
                //: _.compact(fieldList)
              }
              TypeReturn={TypeReturn}
            />
          </DivTag2>
          <GlobalStyle />
          <FloatButton />
        </RowTag>
      ) : (
        <Loader />
      )}
    </StyledDiv>
  );
};

const H4Tag = styled.h4`
  margin-bottom: -20px;
  margin-top: -8px;
  text-align: center;
  color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  @media (max-width: 992px) {
    display: none;
  }
`;

const DivTag1 = styled.div`
  color: ${({ theme }) => theme.regularFont?.textColor || ""};
  @media (min-width: 890px) {
    width: 28.5%;
    max-width: 28.5%;
    flex: 0 0 28.5%;
  }
  @media screen (min-width: 300px) {
    padding: 0;
  }
`;

const DivTag2 = styled.div`
  color: ${({ theme }) => theme.regularFont?.textColor || ""};
  @media (min-width: 890px) {
    width: 71.5%;
    max-width: 71.5%;
    flex: 0 0 71.5%;
  }
  @media screen (min-width: 300px) {
    padding: 0;
  }
`;

const RowTag = styled(Row)`
  margin: 15px
    ${process.env?.REACT_APP_BROKER === "ABIBL"
      ? "45px 20px 45px"
      : "60px 20px 30px"} !important;
  @media (max-width: 600px) {
    margin: 10px 0 20px 0 !important;
    width: 100%;
  }
`;

const StyledDiv = styled.div`
  // background-repeat: no-repeat;
  // background-position: ${(props) => props.pos || "top"};
  // background-size: 100% ${(props) => props.heightPer || "100%"};
  // background-image: linear-gradient(to bottom, transparent, #fff),
  // 	url(/assets/images/background-green3-min.jpg);
  width: ${({ isMobileIOS, innerWidth }) =>
    isMobileIOS ? innerWidth + "px" : "100%"};
  max-width: ${({ isMobileIOS, innerWidth }) =>
    isMobileIOS ? innerWidth + "px" : "100%"};
  overflow-x: ${({ isMobileIOS }) => isMobileIOS && "hidden !important"};
`;

//proposal-page-font
export const GlobalStyle = createGlobalStyle`
body {
    
  } 
`;
