import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import { Button, ErrorMsg } from "../../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { FormGroupTag } from "../style.js";
import DateInput from "../DateInput";
import _ from "lodash";
import { subYears } from "date-fns";
import moment from "moment";
import { getRelation } from "../proposal.slice";
import styled from "styled-components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { toDate } from "utils";
import { SaveAddonsData } from "modules/quotesPage/quote.slice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

/*---------------date config----------------*/
const AdultCheck = subYears(new Date(Date.now() - 86400000), 18);
/*-----x---------date config-----x----------*/

const NomineeCard = ({
  onSubmitNominee,
  nominee,
  CardData,
  Theme,
  lessthan768,
  lessthan376,
  PACondition,
  enquiry_id,
  dropout,
  NomineeBroker,
  type,
  Tenure,
  fields,
}) => {
  const dispatch = useDispatch();
  const { relation, temp_data } = useSelector((state) => state.proposal);
  const { cpaSet } = useSelector((state) => state.quotes);
  const [cpaToggle, setCpaToggle] = useState(false);
  /*----------------Validation Schema---------------------*/
  const yupValidate = yup.object(
    temp_data?.selectedQuote?.isRenewal === "Y" && fields.includes("cpaOptIn") ||
      (!cpaToggle &&
        !NomineeBroker &&
        temp_data?.corporateVehiclesQuoteRequest?.policyType !== "own_damage" &&
        temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C" &&
        fields.includes("cpaOptIn"))
      ? {
          cpa: yup.string().required("selection is required"),
        }
      : {
          nomineeName: yup
            .string()
            .required("Nominee Name is required")
            .min(2, "Minimum 2 chars required")
            .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
            .trim(),
          nomineeDob: yup.string().required("DOB is required"),
          nomineeRelationship: yup
            .string()
            .required("Nominee Relation Required")
            .matches(/[^@]/, "Nominee Relation Required"),
        }
  );
  /*----------x------Validation Schema----------x-----------*/

  const { handleSubmit, register, errors, control, reset, watch, setValue } =
    useForm({
      defaultValues: !_.isEmpty(nominee)
        ? nominee
        : !_.isEmpty(CardData?.nominee)
        ? CardData?.nominee
        : {},
      resolver: yupResolver(yupValidate),
      mode: "onBlur",
      reValidateMode: "onBlur",
    });

  const DOB = watch("nomineeDob");
  const allFieldsReadOnly = false;

  //prefill Api
  useEffect(() => {
    if (_.isEmpty(nominee) && !_.isEmpty(CardData?.nominee)) {
      reset(CardData?.nominee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData.nominee]);

  const companyAlias = !_.isEmpty(temp_data?.selectedQuote)
    ? temp_data?.selectedQuote?.companyAlias
    : "";

  //get nominee data
  useEffect(() => {
    dispatch(
      getRelation({ companyAlias: companyAlias, enquiryId: enquiry_id })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias]);

  const Relations = !_.isEmpty(relation)
    ? relation?.map(({ name, code }) => {
        return { name, code };
      })
    : [
        // { name: "Father", id: 1 },
        // { name: "Mother", id: 2 },
        // { name: "Spouse", id: 3 },
      ];

  //setting hidden i/p
  const NomineeRel = watch("nomineeRelationship");
  const NomineeRelName = Relations.filter(({ code }) => code === NomineeRel)[0]
    ?.name;

  const NomineeDob = watch("nomineeDob");
  const NomineeAge =
    NomineeDob && moment().diff(moment(NomineeDob, "DD-MM-YYYY"), "years");

  //prefill
  useEffect(() => {
    if (_.isEmpty(CardData?.nominee) && _.isEmpty(nominee)) {
      temp_data?.userProposal?.nomineeName &&
        setValue("nomineeName", temp_data?.userProposal?.nomineeName);
      temp_data?.userProposal?.nomineeDob &&
        setValue("nomineeDob", temp_data?.userProposal?.nomineeDob);
      temp_data?.userProposal?.nomineeRelationship &&
        setValue(
          "nomineeRelationship",
          temp_data?.userProposal?.nomineeRelationship
        );
    }
  }, [temp_data?.userProposal]);

  //setting cpa
  const cpaStatus = watch("cpa")
    ? watch("cpa")
    : !_.isEmpty(temp_data) && !PACondition
    ? !_.isEmpty(Tenure)
      ? "MultiYear"
      : "OneYear"
    : "NO";
  console.log("cpaStatus", cpaStatus, errors);

  useEffect(() => {
    if (
      cpaStatus &&
      !_.isEmpty(temp_data) &&
      !dropout &&
      temp_data?.corporateVehiclesQuoteRequest?.policyType !== "own_damage" &&
      temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C" &&
      fields.includes("cpaOptIn")
    ) {
      if (cpaStatus === "MultiYear") {
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          addonData: {
            compulsory_personal_accident: [
              {
                name: "Compulsory Personal Accident",
                tenure: type === "bike" ? 5 : 3,
              },
            ],
          },
        };

        dispatch(SaveAddonsData(data1, true));
        setCpaToggle(true);
      }
      if (cpaStatus === "OneYear") {
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          addonData: {
            compulsory_personal_accident: [
              { name: "Compulsory Personal Accident" },
            ],
          },
        };

        dispatch(SaveAddonsData(data1, true));
        setCpaToggle(true);
      }
      if (cpaStatus === "NO") {
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          addonData: {
            compulsory_personal_accident: [
              {
                reason:
                  "I have another motor policy with PA owner driver cover in my name",
              },
            ],
          },
        };
        dispatch(SaveAddonsData(data1, true));
        setCpaToggle(false);
      }
    }
  }, [cpaStatus]);

  //prefill cpa for the first time
  useEffect(() => {
    if (
      !_.isEmpty(temp_data) &&
      _.isEmpty(CardData?.nominee) &&
      _.isEmpty(nominee) &&
      PACondition &&
      fields.includes("cpaOptIn")
    ) {
      setValue("cpa", "NO");
    }
  }, [temp_data]);

  return (
    <Form onSubmit={handleSubmit(onSubmitNominee)} autoComplete="none">
      <Row
        style={{
          margin: lessthan768
            ? "-60px -30px 20px -30px"
            : "-60px -20px 20px -30px",
        }}
        className="p-2"
      >
        {temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C" &&
          temp_data?.corporateVehiclesQuoteRequest?.policyType !==
            "own_damage" &&
          fields.includes("cpaOptIn") && (
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
              <div className="py-2 fname" style={{ marginTop: "2px" }}>
                <FormGroupTag>Compulsory Personal Accident</FormGroupTag>
                {/* <Form.Control
                  autoComplete="off"
                  as="select"
                  size="sm"
                  ref={register}
                  readOnly={allFieldsReadOnly}
                  name={`cpa`}
                  style={{ cursor: "pointer" }}
                >
                  <option selected={!PACondition} value={"YES"}>
                    YES
                  </option>
                  <option selected={PACondition} value={"NO"}>
                    NO
                  </option>
                </Form.Control> */}
                <div>
                  <FormControl>
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                    <Controller
                      as={
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          size="small"
                          sx={{ fontSize: 9 }}
                          defaultValue={
                            !PACondition
                              ? !_.isEmpty(Tenure)
                                ? "MultiYear"
                                : "OneYear"
                              : PACondition
                              ? "NO"
                              : null
                          }
                        >
                          <FormControlLabel
                            value="OneYear"
                            control={
                              <Radio
                                sx={{
                                  color: `${
                                    Theme1?.proposalProceedBtn?.hex1
                                      ? Theme1?.proposalProceedBtn?.hex1
                                      : "#4ca729"
                                  }`,
                                  fontSize: 9,
                                  "&.Mui-checked": {
                                    color: `${
                                      Theme1?.proposalProceedBtn?.hex1
                                        ? Theme1?.proposalProceedBtn?.hex1
                                        : "#4ca729"
                                    }`,
                                  },
                                }}
                              />
                            }
                            label={type === "cv" ? "Yes" : "1 Year"}
                            sx={{ fontSize: 9 }}
                          />
                          {(type === "bike" || type === "car") && (
                            <FormControlLabel
                              value="MultiYear"
                              disabled={
                                !temp_data?.selectedQuote?.multiYearCpa * 1
                              }
                              control={
                                <Radio
                                  sx={{
                                    color: `${
                                      Theme1?.proposalProceedBtn?.hex1
                                        ? Theme1?.proposalProceedBtn?.hex1
                                        : "#4ca729"
                                    }`,
                                    fontSize: 9,
                                    "&.Mui-checked": {
                                      color: `${
                                        Theme1?.proposalProceedBtn?.hex1
                                          ? Theme1?.proposalProceedBtn?.hex1
                                          : "#4ca729"
                                      }`,
                                    },
                                  }}
                                />
                              }
                              label={`${
                                type === "bike" ? "5 Years" : "3 Years"
                              }`}
                              size="small"
                            />
                          )}
                          <FormControlLabel
                            value="NO"
                            control={
                              <Radio
                                sx={{
                                  color: `${
                                    Theme1?.proposalProceedBtn?.hex1
                                      ? Theme1?.proposalProceedBtn?.hex1
                                      : "#4ca729"
                                  }`,
                                  fontSize: 9,
                                  "&.Mui-checked": {
                                    color: `${
                                      Theme1?.proposalProceedBtn?.hex1
                                        ? Theme1?.proposalProceedBtn?.hex1
                                        : "#4ca729"
                                    }`,
                                  },
                                }}
                              />
                            }
                            label="No"
                            sx={{ fontSize: 9 }}
                          />
                        </RadioGroup>
                      }
                      name="cpa"
                      control={control}
                    />
                  </FormControl>
                </div>
              </div>
              <input
                type="hidden"
                ref={register}
                name="compulsoryPersonalAccident"
                value={
                  watch("cpa") === "OneYear"
                    ? type === "cv"
                      ? "YES"
                      : "One Year"
                    : watch("cpa") === "MultiYear"
                    ? type === "bike"
                      ? "5 Years"
                      : "3 Years"
                    : watch("cpa")
                }
              />
            </Col>
          )}
        {(cpaStatus === "MultiYear" ||
          cpaStatus === "OneYear" ||
          NomineeBroker) && (
          <>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Nominee Name</FormGroupTag>
                <Form.Control
                  type="text"
                  autoComplete="none"
                  placeholder="Enter Nominee Name"
                  name={`nomineeName`}
                  readOnly={allFieldsReadOnly}
                  ref={register}
                  maxLength="50"
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value).toUpperCase()
                        : e.target.value)
                  }
                  minlength="2"
                  size="sm"
                />
                {!!errors?.nomineeName && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.nomineeName?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>Nominee DOB</FormGroupTag>
                  <Controller
                    control={control}
                    name={`nomineeDob`}
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        maxDate={AdultCheck}
                        value={value}
                        readOnly={allFieldsReadOnly}
                        name={name}
                        onChange={onChange}
                        ref={register}
                        selected={
                          DOB ||
                          nominee?.nomineeDob ||
                          CardData?.nominee?.nomineeDob
                            ? toDate(
                                DOB ||
                                  nominee?.nomineeDob ||
                                  CardData?.nominee?.nomineeDob
                              )
                            : false
                        }
                      />
                    )}
                  />
                  {!!errors?.nomineeDob && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.nomineeDob?.message}
                    </ErrorMsg>
                  )}
                </div>
                <input
                  type="hidden"
                  name="nomineeAge"
                  ref={register}
                  value={NomineeAge}
                />
              </StyledDatePicker>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>Relationship with the Owner</FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  as="select"
                  readOnly={allFieldsReadOnly}
                  size="sm"
                  ref={register}
                  name={`nomineeRelationship`}
                  style={{ cursor: "pointer" }}
                >
                  <option selected={true} value={"@"}>
                    Select
                  </option>
                  {Relations.map(({ name, code }, index) => (
                    <option
                      selected={
                        CardData?.nominee?.nomineeRelationship === code ||
                        (_.isEmpty(CardData?.nominee) &&
                          _.isEmpty(nominee) &&
                          temp_data?.userProposal?.nomineeRelationship &&
                          temp_data?.userProposal?.nomineeRelationship.trim() ===
                            code.trim())
                      }
                      value={code}
                    >
                      {name}
                    </option>
                  ))}
                </Form.Control>
                {!!errors?.nomineeRelationship && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.nomineeRelationship?.message}
                  </ErrorMsg>
                )}
              </div>
              {watch("nomineeRelationship") && (
                <input
                  type="hidden"
                  name={"relationship_with_owner"}
                  ref={register}
                  value={NomineeRelName}
                />
              )}
            </Col>
          </>
        )}
        <Col
          sm={12}
          lg={12}
          md={12}
          xl={12}
          className="d-flex justify-content-center mt-5"
        >
          <Button
            type="submit"
            buttonStyle="outline-solid"
            hex1={
              Theme?.proposalProceedBtn?.hex1
                ? Theme?.proposalProceedBtn?.hex1
                : "#4ca729"
            }
            hex2={
              Theme?.proposalProceedBtn?.hex2
                ? Theme?.proposalProceedBtn?.hex2
                : "#4ca729"
            }
            borderRadius="5px"
            color="white"
            disabled={cpaSet}
            onClick={() => navigator && navigator?.vibrate && navigator.vibrate([100, 0 ,50])}
          >
            <text
              style={{
                fontSize: "15px",
                padding: "-20px",
                margin: "-20px -5px -20px -5px",
                fontWeight: "400",
              }}
            >
              {`Proceed to Vehicle${!lessthan376 ? " Details" : ""}`}
            </text>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
`;

export default NomineeCard;
