import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import "./style.css";
import { Brand, Model, FuelType, Variant, City, YearCM } from "./steps";
import { scrollToTargetAdjusted, reloadPage, RedirectFn } from "utils";
import { useHistory } from "react-router";
import { BackButton, Loader } from "components";
import { useSelector, useDispatch } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import {
  Url,
  DuplicateEnquiryId,
  clrDuplicateEnquiry,
} from "modules/proposal/proposal.slice";
import { clear, Prefill } from "modules/Home/home.slice";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import swal from "sweetalert";
import { clear as ClearQuotesData } from "modules/quotesPage/quote.slice";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

export const CarDetails = ({
  enquiry_id,
  type,
  token,
  typeId,
  stepperfill,
  isMobileIOS,
  journey_type,
  savedStep,
  TypeReturn,
}) => {
  /*---------------- back button---------------------*/
  const back = () => {
    if (TypeReturn(type) === "cv" && TypeReturn(type)) {
      !typeId &&
        history.push(
          `/${type}/vehicle-type?enquiry_id=${
            temp_data?.enquiry_id || enquiry_id
          }${token ? `&token=${token}` : ``}${
            typeId ? `&typeid=${typeId}` : ``
          }${journey_type ? `&journey_type=${journey_type}` : ``}`
        );
      typeId &&
        history.push(
          `/${type}/registration?enquiry_id=${
            temp_data?.enquiry_id || enquiry_id
          }${token ? `&token=${token}` : ``}${
            typeId ? `&typeid=${typeId}` : ``
          }${journey_type ? `&journey_type=${journey_type}` : ``}`
        );
    } else if (temp_data?.isRenewalRedirection === "Y") {
      history.push(
        `/${type}/renewal?enquiry_id=${temp_data?.enquiry_id || enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
    } else {
      history.push(
        `/${type}/registration?enquiry_id=${
          temp_data?.enquiry_id || enquiry_id
        }${token ? `&token=${token}` : ``}${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
    }
  };
  /*----------x----- back button-------x-------------*/
  const [Step, setStep] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();

  const { temp_data, loading, stepperLoad } = useSelector(
    (state) => state.home
  );
  const { duplicateEnquiry } = useSelector((state) => state.proposal);

  const lessthan600 = useMediaPredicate("(max-width: 600px)");
  const lessthan400 = useMediaPredicate("(max-width: 400px)");
  const lessthan330 = useMediaPredicate("(max-width: 330px)");
  //clearing quotes page data on browser back button

  useEffect(() => {
    dispatch(ClearQuotesData());
  }, []);

  //update temp-data
  useEffect(() => {
    if (!_.isEmpty(temp_data)) {
      dispatch(Prefill({ enquiryId: enquiry_id }));
    }
  }, []);

  //center auto scroll
  useEffect(() => {
    if (!loading) scrollToTargetAdjusted("stepper", 45);
  }, [loading]);

  //formData's
  const [brandData, setBrandData] = useState({});
  const [ModelData, setModelData] = useState({});
  const [FuelData, setFuelData] = useState({});
  const [VariantData, setVariantData] = useState({});
  const [CitytData, setCityData] = useState({});
  const [YearData, setYearData] = useState({});

  const stepFn = (stepNo, data, newStep) => {
    dispatch(clear("saveQuoteData"));
    setStep(Number(newStep));
    switch (Number(stepNo)) {
      case 1:
        setBrandData(data);
        break;
      case 2:
        setModelData(data);
        break;
      case 3:
        setFuelData(data);
        break;
      case 4:
        setVariantData(data);
        break;
      case 5:
        setCityData(data);
        break;
      case 6:
        setYearData(data);
        break;
      default:
        break;
    }
  };
  const TitleFn = (Step) => {
    switch (Step) {
      case 1:
        return lessthan600
          ? "Select Vehicle's Brand"
          : "Select the Brand of your Vehicle";
      case 2:
        return lessthan600
          ? "Select Vehicle's Model"
          : "Select the Model of your Vehicle";
      case 3:
        return lessthan600
          ? "Select Fuel Type"
          : "Select Fuel type of your Vehicle";
      case 4:
        return lessthan600
          ? "Select Vehicle's Variant"
          : "Select the Variant of your Vehicle";
      case 5:
        return lessthan600 ? "Enter RTO Details" : "Enter RTO Details";
      case 6:
        return lessthan600
          ? "Select Registration Year"
          : "Select the Vehicle Registration Year";
      default:
        break;
    }
  };

  //preselecting last saved step
  useEffect(() => {
    if (savedStep * 1) {
      setStep(savedStep * 1);

      //removing the query param
      var queryUrl = window.location.search.substring(1);
      if (queryUrl.length) {
        // are the new history methods available ?
        if (
          window.history != undefined &&
          window.history.pushState != undefined
        ) {
          // if pushstate exists, add a new state to the history, this changes the url without reloading the page
          const newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            `?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ``}${
              typeId ? `&typeid=${typeId}` : ``
            }${journey_type ? `&journey_type=${journey_type}` : ``}`;
          window.history.pushState({ path: newurl }, "", newurl);
        }
      }
    }
  }, [savedStep]);

  useEffect(() => {
    if (stepperfill) {
      setStep(6);
    }
  }, [stepperfill]);

  //Url
  useEffect(() => {
    if (
      enquiry_id &&
      temp_data?.journeyStage?.stage &&
      temp_data?.userProposal?.isBreakinCase !== "Y"
    ) {
      ![
        "Payment Initiated",
        "pg_redirection",
        "Policy Issued",
        "Policy Issued, but pdf not generated",
        "Policy Issued And PDF Generated",
        "payment success",
        "Payment Failed",
        "Inspection Accept",
      ].includes(temp_data?.journeyStage?.stage) &&
        dispatch(
          Url({
            proposalUrl: `${window.location.href}${
              Step ? `&stepNo=${Step}` : ``
            }`,
            quoteUrl: `${window.location.href}${Step ? `&stepNo=${Step}` : ``}`,
            stage: "Lead Generation",
            userProductJourneyId: enquiry_id,
          })
        );
    }
    if (
      temp_data?.journeyStage?.stage === "Payment Initiated" ||
      temp_data?.journeyStage?.stage === "Payment Failed"
    ) {
      dispatch(DuplicateEnquiryId({ enquiryId: enquiry_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage, Step]);

  //generate new enquiry id.
  useEffect(() => {
    if (duplicateEnquiry?.enquiryId) {
      swal(
        "Please Note",
        "Payment status is Incomplete. Proposal update required.",
        "info"
      ).then(() => {
        reloadPage(
          `${window.location.protocol}//${window.location.host}${
            process.env.REACT_APP_BASENAME !== "NA"
              ? `/${process.env.REACT_APP_BASENAME}`
              : ``
          }/${type}/vehicle-details?enquiry_id=${duplicateEnquiry?.enquiryId}${
            token ? `&token=${token}` : ""
          }${journey_type ? `&journey_type=${journey_type}` : ``}${
            typeId ? `&typeid=${typeId}` : ``
          }`
        );
      });
    }
    return () => {
      dispatch(clrDuplicateEnquiry());
    };
  }, [duplicateEnquiry]);

  //Journey already submitted
  const PaymentSuccessfulStages = [
    "Policy Issued And PDF Generated",
    "Policy Issued",
    "Policy Issued, but pdf not generated",
    "payment success",
  ];

  useEffect(() => {
    if (PaymentSuccessfulStages.includes(temp_data?.journeyStage?.stage)) {
      swal("Info", "This Proposal has already been submitted", "info").then(
        () =>
          temp_data?.journeyStage?.stage !== "Payment Failed"
            ? reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
            : reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  //After Completion
  useEffect(() => {
    if (Number(temp_data?.journeyType) === 3 || temp_data?.regNo === "NEW") {
      if (Step > 5) {
        history.push(
          `/${type}/quotes?enquiry_id=${temp_data?.enquiry_id || enquiry_id}${
            token ? `&token=${token}` : ``
          }${journey_type ? `&journey_type=${journey_type}` : ``}${
            typeId ? `&typeid=${typeId}` : ``
          }`
        );
      }
    } else {
      if (Step > 6) {
        history.push(
          `/${type}/quotes?enquiry_id=${temp_data?.enquiry_id || enquiry_id}${
            token ? `&token=${token}` : ``
          }${journey_type ? `&journey_type=${journey_type}` : ``}${
            typeId ? `&typeid=${typeId}` : ``
          }`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Step]);

  //step switcher
  const Switcher = () => {
    switch (Step) {
      case 1:
        setStep((prev) => prev - 1);
        break;
      case 2:
        setStep((prev) => prev - 1);
        break;
      case 3:
        setStep((prev) => prev - 1);
        break;
      case 4:
        if (TypeReturn(type) === "bike") {
          //skipping fuel type in bike
          setStep(2);
        } else {
          setStep((prev) => prev - 1);
        }
        break;
      case 5:
        setStep((prev) => prev - 1);
        break;
      case 6:
        //If regNo is availvale then rto step will be skipped
        if (temp_data?.journeyType === 1) {
          setStep(4);
        }
        //  rto step mandatory for NB & if regNo is not availvale
        else {
          setStep((prev) => prev - 1);
        }
        break;
      default:
        break;
    }
  };

  //Stepper Display
  const NoOfSteppers = () => {
    //In case of bike, fuel type selection is not required as it will be mentioned in version name
    if (TypeReturn(type) === "bike") {
      //Incase of journey with regNo and NB the no. of steps = 4
      if (
        Number(temp_data?.journeyType) === 1 ||
        Number(temp_data?.journeyType) === 3 ||
        temp_data?.regNo === "NEW"
      ) {
        return "customStep2";
      }
      //Incase of journey without regNo and rollover the no. of steps = 5
      else {
        return "customStep";
      }
    }
    //In journeys other than bike, fuel type selection is mandatory
    else {
      //Incase of journey with regNo and NB the no. of steps = 5
      if (
        Number(temp_data?.journeyType) === 1 ||
        Number(temp_data?.journeyType) === 3 ||
        temp_data?.regNo === "NEW"
      ) {
        return "customStep";
      }
      //Incase of journey without regNo and rollover the no. of steps = 6
      else {
        return "general";
      }
    }
  };

  return stepperLoad ? (
    <Loader />
  ) : (
    <StyledDiv lessthan600={lessthan600} Step={Step}>
      {((!(Step > 1) && lessthan600) || !lessthan600) && (
        <StyledBack className="backBtn">
          <BackButton type="button" onClick={back}>
            {!lessthan600 ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                  viewBox="0 0 24 24"
                >
                  <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                <text style={{ color: "black" }}>Back</text>
              </>
            ) : (
              <img
                src={`${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ""
                }/assets/images/back-button.png`}
                alt="bck"
              />
            )}
          </BackButton>
        </StyledBack>
      )}
      {!lessthan600 && (
        <Row
          className="w-100 mx-auto my-4 d-flex justify-content-center"
          style={{ zIndex: "999", position: "relative" }}
          id="stepper"
        >
          <Col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="p-0 my-0 m-0 w-100 mx-auto"
          >
            <ListDiv class="wrapper-progressBar">
              <ul class="progressBar">
                <li
                  className={
                    Step > 0 ? `active ${NoOfSteppers()}` : `${NoOfSteppers()}`
                  }
                >
                  <StyledP className="px-0 mx-0 py-1">Brand</StyledP>
                </li>
                <li
                  className={
                    Step > 1 ? `active ${NoOfSteppers()}` : `${NoOfSteppers()}`
                  }
                >
                  <StyledP className="px-0 mx-0 py-1">Model</StyledP>
                </li>
                {TypeReturn(type) !== "bike" && (
                  <li
                    className={
                      Step > 2
                        ? `active ${NoOfSteppers()}`
                        : `${NoOfSteppers()}`
                    }
                  >
                    <StyledP className="px-0 mx-0 py-1">Fuel Type</StyledP>
                  </li>
                )}
                <li
                  className={
                    Step > 3 ? `active ${NoOfSteppers()}` : `${NoOfSteppers()}`
                  }
                >
                  <StyledP className="px-0 mx-0 py-1">Variant</StyledP>
                </li>
                {Number(temp_data?.journeyType) !== 1 && (
                  <li
                    className={
                      Step > 4
                        ? `active ${NoOfSteppers()}`
                        : `${NoOfSteppers()}`
                    }
                  >
                    <StyledP className="px-0 mx-0 py-1">RTO</StyledP>
                  </li>
                )}
                {Number(temp_data?.journeyType) !== 3 &&
                  temp_data?.regNo !== "NEW" && (
                    <li
                      className={
                        Step > 5
                          ? `active ${NoOfSteppers()}`
                          : `${NoOfSteppers()}`
                      }
                    >
                      <StyledP className="px-0 mx-0 py-1">Reg.Year</StyledP>
                    </li>
                  )}
              </ul>
            </ListDiv>
          </Col>
        </Row>
      )}
      <Row
        className={`w-100 ${lessthan600 ? "" : "mt-4"} mx-auto`}
        style={lessthan600 ? { marginTop: "-15px" } : {}}
      >
        <Row className="mx-auto d-flex w-100 ">
          <Button
            className={lessthan600 ? "mb-2 mt-4" : "my-2"}
            size="sm"
            variant="light"
            onClick={() => Switcher()}
            disabled={Step > 1 ? false : true}
            style={
              Step > 1
                ? {
                    ...(lessthan600 && {
                      position: "relative",
                      top: "5.5px",
                      left: lessthan400 ? "3px" : "1px",
                      zIndex: 999,
                    }),
                  }
                : { visibility: "hidden" }
            }
          >
            <img
              src={`${
                process.env.REACT_APP_BASENAME !== "NA"
                  ? `/${process.env.REACT_APP_BASENAME}`
                  : ""
              }/assets/images/back-button.png`}
              alt="bck"
            />
          </Button>
          <StyledH3
            style={{ ...(lessthan600 && Step > 1 && { marginTop: "10px" }) }}
            className={`text-center w-100 ${lessthan600 ? "mb-2" : "mb-4"}`}
          >
            {TitleFn(Step)}
          </StyledH3>
        </Row>
        <div
          className={`text-center w-100`}
          style={
            !lessthan330 && lessthan600
              ? { position: "relative", top: "-41px" }
              : {}
          }
        >
          {Step === 1 && (
            <Brand
              stepFn={stepFn}
              enquiry_id={temp_data?.enquiry_id || enquiry_id}
              token={token}
              TypeReturn={TypeReturn}
            />
          )}
          {Step === 2 && (
            <Model
              stepFn={stepFn}
              enquiry_id={temp_data?.enquiry_id || enquiry_id}
              token={token}
              type={type}
              TypeReturn={TypeReturn}
            />
          )}
          {Step === 3 && (
            <FuelType
              stepFn={stepFn}
              enquiry_id={temp_data?.enquiry_id || enquiry_id}
              token={token}
              TypeReturn={TypeReturn}
            />
          )}
          {Step === 4 && (
            <Variant
              stepFn={stepFn}
              enquiry_id={temp_data?.enquiry_id || enquiry_id}
              token={token}
              type={type}
              TypeReturn={TypeReturn}
            />
          )}
          {Step === 5 && (
            <City
              stepFn={stepFn}
              enquiry_id={temp_data?.enquiry_id || enquiry_id}
              isMobileIOS={isMobileIOS}
              token={token}
              TypeReturn={TypeReturn}
            />
          )}
          {Step === 6 &&
            (Number(temp_data?.journeyType) !== 3 ||
              temp_data?.regNo !== "NEW") && (
              <YearCM
                stepFn={stepFn}
                enquiry_id={temp_data?.enquiry_id || enquiry_id}
                token={token}
                type={type}
                TypeReturn={TypeReturn}
              />
            )}
        </div>
      </Row>
      <GlobalStyle />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  height: ${({ Step }) => (Number(Step) === 6 ? "710px" : "650px")};
  @media (max-width: 768px) {
    height: 900px;
  }
  @media (max-width: 600px) {
    height: unset;
  }
  @media (max-width: 400px) {
    height: unset;
  }
  ${({ lessthan600 }) =>
    lessthan600
      ? `::-webkit-scrollbar {
    width: 0;
    height: 0;
}`
      : ``}
`;

const ListDiv = styled.div`
  .wrapper-progressBar {
    width: 100%;
  }

  .progressBar {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .progressBar .general {
    list-style-type: none;
    float: left;
    width: 16.61%;
    position: relative;
    text-align: center;
  }

  .progressBar .customStep {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
  }

  .progressBar .customStep2 {
    list-style-type: none;
    float: left;
    width: 25%;
    position: relative;
    text-align: center;
  }

  .progressBar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    // border: 1px solid #bdd400;
    border-left: none;
    display: block;
    text-align: center;
    transition: 0.5s ease-in;
    margin: 8.5px auto 0px;
    background-color: #eee;
  }
  .progressBar li:after {
    content: "";
    position: absolute;
    width: 97%;
    height: 5px;
    background-color: #eee;
    transition: 0.5s ease-in;
    // border: 1px solid #bdd400;
    border-right: none;
    top: 15px;
    left: -50%;
    z-index: -1;
  }

  .progressBar li:first-child:after {
    content: none;
  }

  .progressBar li.active {
    color: ${({ theme }) =>
      theme?.Stepper?.stepperColor?.background || "#bdd400"};
  }

  .progressBar li.active:before {
    border-color: ${({ theme }) =>
      theme?.Stepper?.stepperColor?.background || "#bdd400"};
    background-color: ${({ theme }) =>
      theme?.Stepper?.stepperColor?.background || "#bdd400"};
    transition: 0.5s ease-in;
  }

  .progressBar .active:after {
    background-color: ${({ theme }) =>
      theme?.Stepper?.stepperColor?.background || "#bdd400"};
    transition: 0.5s ease-in;
  }
`;

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  font-size: 30px;
  font-family: ${({ theme }) =>
    theme.regularFont?.headerFontFamily || "sans-serif"};
  @media (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    position: relative;
    top: -41px;
  }
  @media (max-width: 330px) {
    position: unset;
  }
  /* ${process.env.REACT_APP_BROKER === "RB" &&
  `
    background: -webkit-linear-gradient(45deg,#339AEE,#387FB9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `} */
`;

const StyledP = styled.p`
  color: rgb(74, 74, 74);
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const StyledBack = styled.div`
  padding-bottom: 30px;
  margin-top: -20px;
  z-index: 999;
  ${process.env.REACT_APP_BROKER === "ABIBL"
    ? `@media (max-width: 780px) {
    position: relative;
    top: -120px;
    left: -8.5%;
  }
  @media (max-width: 769px) {
    position: relative;
    top: -125px;
    left: -11%;
  }
  @media (max-width: 600px) {
    position: relative;
    top: -120px;
    left: -8.5%;
  }`
    : `@media (max-width: 780px) {
      position: relative;
      top: -73px;
      left: -8.5%;
    }
    @media (max-width: 769px) {
      position: relative;
      top: -125px;
      left: -11%;
    }
    @media (max-width: 600px) {
      position: relative;
      top: -73px;
      left: -8.5%;
    }`}
`;

const GlobalStyle = createGlobalStyle`
.btn-link{
  color: ${({ theme }) => theme.Stepper?.stepperColor?.background}!important;

}
${
  process.env.REACT_APP_BROKER === "BAJAJ" &&
  ` 
.btn-light,.btn-light.focus, .btn-light:focus, .btn-light:hover{
background: transparent !important;
border: 1px solid transparent !important;
};

`
};
`;
