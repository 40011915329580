import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Error404 from "components/ErrorPages/404";
import PaymentFail from "components/ErrorPages/payment-failure";
import JournerSuccess from "components/ErrorPages/journey-success";
import PaymentSuccess from "components/ErrorPages/payment-success";
import {
  Loader,
  AbiblHeader,
  Header,
  Layout,
  AbiblFooter,
  Footer,
  SriyahFooter,
  BajajFooter,
} from "components";
import {
  Home,
  QuotesPage,
  Proposal,
  // Login,
  ComparePage,
  Feedback,
  LandingPage,
  Inspection,
  FieldConfig,
} from "modules";
import PaymentConfirmation from "modules/proposal/payment-confirmation";
import ProposalValidation from "modules/proposal/validations/ProposalValidation";
// import Dummy from "modules/proposal/validations/dummy";
import { Payment } from "modules/payment-gateway/payment";
import GeneratePdf from "modules/GeneratePdf/GeneratePdf";
import Theme from "modules/Theme/Theme";
import { AdminPanel } from "./modules/admin-panel/AdminPanel";
import { CarGetDataMmv } from "./modules/car-getdata-mmv/CarGetDataMmv";
import _ from "lodash";
import { subroutes } from "modules/type";

//Auth
const PrivateRoute = ({ component: Component, props, ...rest }) => {
  const typeRoute = window.location.pathname.split("/");
  const type =
    !_.isEmpty(typeRoute) && process.env.REACT_APP_BASENAME === "NA"
      ? typeRoute.length >= 1
        ? typeRoute[1]
        : []
      : typeRoute.length >= 2
      ? typeRoute[2]
      : [];
  return (
    <Route
      {...rest}
      render={(props) =>
        type && [...subroutes].includes(type) ? (
          <Component {...props} />
        ) : process.env.REACT_APP_BASENAME !== "NA" ? (
          <Component {...props} />
        ) : (
          <Error404 {...props} />
        )
      }
    />
  );
};
const Router = (props) => {
  return (
    <BrowserRouter
      basename={
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }
    >
      <Switch>
        <Route exact path="/">
          <Redirect to="/landing-page" />
        </Route>
        <Route exact path="/landing-page" component={LandingPage} />
        {/* <Route exact path="/:type/login" component={Login} /> */}
        <Layout>
          {process.env.REACT_APP_BROKER === "ABIBL" ? (
            <AbiblHeader />
          ) : (
            <Header />
          )}
          <Switch>
            <PrivateRoute exact path="/:type/lead-page" component={Home} />
            <PrivateRoute exact path="/:type/journey-type" component={Home} />
            <PrivateRoute exact path="/:type/registration" component={Home} />
            <PrivateRoute exact path="/:type/vehicle-type" component={Home} />
            <PrivateRoute
              exact
              path="/:type/vehicle-details"
              component={Home}
            />
            <PrivateRoute exact path="/:type/quotes" component={QuotesPage} />
            <PrivateRoute
              exact
              path="/:type/compare-quote"
              component={ComparePage}
            />
            <PrivateRoute
              exact
              path="/:type/proposal-page"
              component={Proposal}
            />
            <PrivateRoute
              exact
              path="/:type/payment-confirmation"
              component={PaymentConfirmation}
            />
            <PrivateRoute
              exact
              path="/:type/payment-gateway"
              component={Payment}
            />
            <Route exact path="/payment-success" component={PaymentSuccess} />
            <Route exact path="/payment-failure" component={PaymentFail} />
            <PrivateRoute
              exact
              path="/:type/successful"
              component={JournerSuccess}
            />
            <Route exact path="/feedback" component={Feedback} />
            <PrivateRoute exact path="/:type/renewal" component={Home} />
            <Route
              exact
              path="/motor/check-inspection-status"
              component={Inspection}
            />
            <Route exact path="/admin" component={AdminPanel} />
            <Route exact path="/mmv" component={CarGetDataMmv} />
            <Route exact path="/generate-pdf" component={GeneratePdf} />
            <Route
              exact
              path="/proposal-validation"
              component={ProposalValidation}
            />
            <Route exact path="/field-config" component={FieldConfig} />
            <Route exact path="/theme" component={Theme} />
            <Route
              exact
              path="/loader"
              component={(props) => <Loader {...props} />}
            />
            <Route exact path="/404" component={Error404} />
            <Route component={Error404} />
          </Switch>
          {process.env.REACT_APP_BROKER === "ABIBL" ? (
            <AbiblFooter />
          ) : process.env.REACT_APP_BROKER === "SRIYAH" ? (
            <SriyahFooter />
          ) : process.env.REACT_APP_BROKER === "BAJAJ" ? (
            <BajajFooter />
          ) : (
            <Footer />
          )}
        </Layout>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
