import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import _ from "lodash";
import { Button } from "components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Prefill, clear } from "./proposal.slice";
import swal from "sweetalert";
import { ShareQuote, clear as clr } from "../../modules/Home/home.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import { currencyFormater } from "utils";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const PaymentModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = props?.enquiry_id;
  const token = props?.token;
  const type = props?.type;
  const lessthan768 = useMediaPredicate("(max-width: 768px)");

  const { temp_data, error_other, prefillLoad } = useSelector(
    (state) => state.proposal
  );
  const { share } = useSelector((state) => state.home);

  const CardData = !_.isEmpty(temp_data)
    ? temp_data?.userProposal?.additonalData
      ? temp_data?.userProposal?.additonalData
      : {}
    : {};

  //load prefill data
  useEffect(() => {
    if (enquiry_id) {
      dispatch(Prefill({ enquiryId: enquiry_id }, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, props.show]);

  //onError
  useEffect(() => {
    if (error_other) {
      swal(
        "Error",
        props?.enquiry_id
          ? `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${error_other}`}`
          : error_other,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_other]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmation Required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!_.isEmpty(temp_data?.userProposal) && !prefillLoad ? (
          <div>
            <p>{`Your new policy will start from ${
              temp_data?.userProposal?.policyStartDate
                ? `${temp_data?.userProposal?.policyStartDate?.split("-")[0]}/${
                    temp_data?.userProposal?.policyStartDate?.split("-")[1]
                  }/${temp_data?.userProposal?.policyStartDate?.split("-")[2]}`
                : "N/A"
            }.`}</p>
            <p>{`The total premium payable is ₹ ${currencyFormater(
              Number(temp_data?.userProposal?.finalPayableAmount) || 0
            )}.`}</p>
            <p>Do you wish to proceed?</p>
          </div>
        ) : (
          <p>
            <Spinner animation="border" size="sm" /> Please wait.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          buttonStyle="outline-solid"
          onClick={() => {
            return [
              document?.getElementById("shareQuotes1") &&
                document?.getElementById("shareQuotes1").click(),
              props?.onHide(),
            ];
          }}
          hex1={
            Theme?.paymentConfirmation?.Button?.hex1
              ? Theme?.paymentConfirmation?.Button?.hex1
              : "#4ca729"
          }
          hex2={
            Theme?.paymentConfirmation?.Button?.hex2
              ? Theme?.paymentConfirmation?.Button?.hex2
              : "#4ca729"
          }
          borderRadius="5px"
          color={
            Theme?.PaymentConfirmation?.buttonTextColor
              ? Theme?.PaymentConfirmation?.buttonTextColor
              : "white"
          }
          style={{ ...(lessthan768 && { width: "100%" }) }}
        >
          <text
            style={{
              fontSize: "15px",
              padding: "-20px",
              margin: "-20px -5px -20px -5px",
              fontWeight: "400",
            }}
          >
            {lessthan768 ? "Share Payment Link" : ""}
            <i className={`fa fa-share-alt ${lessthan768 ? "ml-2" : ""}`}></i>
          </text>
        </Button>
        <Button
          type="submit"
          buttonStyle="outline-solid"
          onClick={() => props?.payment()}
          hex1={
            Theme?.paymentConfirmation?.Button?.hex1
              ? Theme?.paymentConfirmation?.Button?.hex1
              : "#4ca729"
          }
          hex2={
            Theme?.paymentConfirmation?.Button?.hex2
              ? Theme?.paymentConfirmation?.Button?.hex2
              : "#4ca729"
          }
          borderRadius="5px"
          color={
            Theme?.PaymentConfirmation?.buttonTextColor
              ? Theme?.PaymentConfirmation?.buttonTextColor
              : "white"
          }
          style={{ ...(lessthan768 && { width: "100%" }) }}
        >
          <text
            style={{
              fontSize: "15px",
              padding: "-20px",
              margin: "-20px -5px -20px -5px",
              fontWeight: "400",
            }}
          >
            {"Proceed to payment"}
            <i className="fa fa-arrow-circle-right ml-2"></i>
          </text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;
