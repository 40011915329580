import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { setTempData } from "../../filterConatiner/quoteFilter.slice";
import { useForm, Controller } from "react-hook-form";
import { Button as Btn, Error } from "components";
import Popup from "../../../../components/Popup/Popup";
import { set_temp_data } from "modules/Home/home.slice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toDate } from "utils";
import {
  subYears,
  addDays,
  differenceInDays,
  differenceInMonths,
  addYears,
} from "date-fns";
import _ from "lodash";
import "./preInsurerPopup.scss";
import { differenceInYears } from "date-fns/esm";
import { useMediaPredicate } from "react-media-hook";
import { useOutsideClick } from "../../../../hoc";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { CancelAll, clear } from "modules/quotesPage/quote.slice";
import { getNewNcb, getCalculatedNcb } from "modules/quotesPage/quoteUtil";
import Drawer from "@mui/material/Drawer";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const dateValidation = (broker) => {
  console.log(broker, "broker");
  switch (broker) {
    case "RB":
      return 45;
    default:
      return 365;
  }
};

const PrevInsurerPopup = ({
  show,
  onClose,
  edit,
  setEdit,
  type,
  ConfigNcb,
  showPrevPopUp,
  assistedMode,
}) => {
  const { handleSubmit, register, watch, control, errors, setValue } = useForm({
    // resolver: yupResolver(yupValidate),
  });
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const { temp_data } = useSelector((state) => state.home);
  const [step, setStep] = useState(2);
  const [ownerShipChange, setOwnerShipChange] = useState(
    temp_data?.carOwnership ? temp_data?.carOwnership : false
  );

  /*---------------date config----------------*/
  const CarCheck = subYears(new Date(Date.now() - 86400000), 15);
  //const policyMin = subYears(new Date(Date.now() - 86400000), 1);
  const policyMin = moment('01-01-1900').format('DD-MM-YYYY');
  const policyMin1 =
    temp_data?.regDate &&
    new Date().getFullYear() - Number(temp_data?.regDate?.split("-")[2]) === 1
      ?  moment('01-01-1900').format('DD-MM-YYYY')
      // addYears(
      //     new Date(
      //       new Date([
      //         `${temp_data?.regDate?.split("-")[2]}`,
      //         `${temp_data?.regDate?.split("-")[1]}`,
      //         `${temp_data?.regDate?.split("-")[0]}`,
      //       ])
      //     ),
      //     1
      //   )
      : moment('01-01-1900').format('DD-MM-YYYY')
  const policyMax = addDays(
    new Date(Date.now() - 86400000),
    process.env?.REACT_APP_API_BASE_URL === "https://api-ola-uat.fynity.in/api"
      ? 180
      : dateValidation(process.env.REACT_APP_BROKER)
  );
  /*-----x---------date config-----x----------*/

  const { prevInsList } = useSelector((state) => state.quoteFilter);

  // page 2 logic

  const dispatch = useDispatch();

  const [noClaimMade, setNoClaimMade] = useState(
    temp_data?.noClaimMade === false ? temp_data?.noClaimMade : true
  );
  const [prevInsurer, setPrevInsurer] = useState(false);

  const [prevIcData, setPrevIcData] = useState(false);

  useEffect(() => {
    if (
      temp_data?.prevIc &&
      temp_data?.prevIc !== "others" &&
      temp_data?.prevIc !== "Not selected"
    ) {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);

  //prefill

  const onSubmit = async (date) => {
    !assistedMode && dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
    let a = date;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    let regDateFormat = regDate.slice(6);
    // convert string into date
    let dateWithoutFormat = moment(date, "DD-MM-YYYY").toDate();
    // increase date by 1 day
    let result = new Date(dateWithoutFormat);
    result.setDate(result.getDate() + 1);
    // convert date into string
    let finalResult = moment(result).format("DD-MM-YYYY");
    let selectedExpiryDate = finalResult.slice(0, 6);

    if (
      //display prev popup when config is true
      ConfigNcb &&
      //display prev popup if expiry date is not more than 90 days past
      !(diffDays && diffDays > 90) &&
      //display prev popup if it's a non new business, if prev IC is absent and prevPolicy type is not tp.
      !temp_data?.newCar &&
      !prevIcData &&
      tempData?.policyType !== "Third-party"
    ) {
      console.log("assistedmode working");
      // invoking function to display prevIC pop up
      showPrevPopUp(
        {
          // ncb:
          //   prevPolicyType === "Third-party"
          //     ? "0%"
          //     : diffDays && diffDays > 90
          //     ? "0%"
          //     : temp_data?.ncb && temp_data?.isNcbVerified === "Y"
          //     ? temp_data?.ncb
          //     : diffYear > 8
          //     ? "50%"
          //     : getCalculatedNcb(diffYear),
          expiry: date,
          // noClaimMade: temp_data?.noClaimMade ? temp_data?.noClaimMade : true,
          policyExpired: diffDays && diffDays > 0 ? true : false,
          // newNcb:
          //   prevPolicyType === "Third-party"
          //     ? "0%"
          //     : diffDays && diffDays > 90
          //     ? "0%"
          //     : temp_data?.newNcb && temp_data?.isNcbVerified === "Y"
          //     ? temp_data?.newNcb
          //     : getNewNcb(diffYear > 8 ? "50%" : getCalculatedNcb(diffYear)),
          leadJourneyEnd: true,
          // carOwnership: temp_data?.carOwnership
          //   ? temp_data?.carOwnership
          //   : false,
          breakIn: diffDays && diffDays > 0 ? true : false,
          ...(prevPolicyType === "Third-party" && {
            regDate: selectedExpiryDate + regDateFormat,
          }),
        },
        {
          policyType: prevPolicyType ? prevPolicyType : "Comprehensive",
        }
      );
    } else {
      dispatch(
        set_temp_data({
          ncb:
            prevPolicyType === "Third-party"
              ? "0%"
              : diffDays && diffDays > 90
              ? "0%"
              : temp_data?.ncb && temp_data?.isNcbVerified === "Y"
              ? temp_data?.ncb
              : diffYear > 8
              ? "50%"
              : getCalculatedNcb(diffYear),
          expiry: date,
          noClaimMade: temp_data?.noClaimMade ? temp_data?.noClaimMade : true,
          policyExpired: diffDays && diffDays > 0 ? true : false,
          newNcb:
            prevPolicyType === "Third-party"
              ? "0%"
              : diffDays && diffDays > 90
              ? "0%"
              : temp_data?.newNcb && temp_data?.isNcbVerified === "Y"
              ? temp_data?.newNcb
              : getNewNcb(diffYear > 8 ? "50%" : getCalculatedNcb(diffYear)),
          leadJourneyEnd: true,
          carOwnership: temp_data?.carOwnership
            ? temp_data?.carOwnership
            : false,
          breakIn: diffDays && diffDays > 0 ? true : false,
          ...(prevPolicyType === "Third-party" && {
            regDate: selectedExpiryDate + regDateFormat,
          }),
        })
      );

      dispatch(
        setTempData({
          policyType: prevPolicyType ? prevPolicyType : "Comprehensive",
        })
      );
      !assistedMode && dispatch(CancelAll(false));
      dispatch(clear());
      onClose(false);
    }
  };

  setTimeout(() => {
    if (temp_data?.expiry) {
      setEdit(false);
    }
  }, 500);

  const handleNoPrev = () => {
    dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
    dispatch(
      set_temp_data({
        ncb: "0%",
        expiry: "New",
        policyExpired: false,
        newNcb: "0%",
        prevIc: "New",
        prevIcFullName: "New",
        noClaimMade: true,
        leadJourneyEnd: true,
        carOwnership: false,
        breakIn: true,
      })
    );
    dispatch(
      setTempData({
        policyType: "Not sure",
      })
    );
    dispatch(CancelAll(false));
    dispatch(clear());
    onClose(false);
  };

  const { ncbList, tempData } = useSelector((state) => state.quoteFilter);

  let today = moment().format("DD-MM-YYYY");
  let regDate = temp_data?.regDate;
  let diffYear =
    regDate && today && differenceInYears(toDate(today), toDate(regDate));

  const [date, setDate] = useState(false);
  const [isFocused, setIsFocused] = useState(true);

  function onDateChange(date) {
    setDate(date);
    let newDate = moment(date).toDate().toISOString();

    let newDateFormated = moment(newDate).format("DD-MM-YYYY");

    onSubmit(newDateFormated);

    dispatch(
      setTempData({
        unFormatedExpityDate: date,
      })
    );
  }

  function onFocusChange({ focused }) {
    setIsFocused(true);
  }

  //setting initial step for od

  //cancel all logic

  useEffect(() => {
    if (!temp_data?.expiry) {
      dispatch(CancelAll(true));
    }
  }, [temp_data?.expiry]);

  //setting od only
  const [odOnly, setOdOnly] = useState(false);

  useEffect(() => {
    let b = "01-09-2018";
    let c = temp_data?.regDate;
    let d = moment().format("DD-MM-YYYY");
    let diffMonthsOd = c && b && differenceInMonths(toDate(c), toDate(b));
    let diffDaysOd = c && b && differenceInDays(toDate(c), toDate(b));
    let diffMonthsOdCar = c && d && differenceInMonths(toDate(d), toDate(c));
    let diffDayOd = c && d && differenceInDays(toDate(d), toDate(c));
    if (
      (diffDaysOd >= 0 && diffDayOd > 270 && type === "bike") ||
      (diffDayOd > 270 && diffMonthsOdCar <= 34 && type === "car")
    ) {
      setOdOnly(true);
    } else {
      setOdOnly(false);
    }
  }, [temp_data?.regDate]);

  //handle policy type

  const [prevPolicyType, setPrevPolicyType] = useState(tempData?.policyType);

  const handlePrevPolicySelection = (data) => {
    console.log(data, "handledata");
    if (data === "Comprehensive") {
      setPrevPolicyType("Comprehensive");
    } else if (data === "Third-party") {
      setPrevPolicyType("Third-party");
      dispatch(
        setTempData({
          policyType: "Third-party",
        })
      );
    } else if (data === "Not sure") {
      setPrevPolicyType("Not sure");
      handleNoPrev();
    } else if (data === "Own-damage") {
      setPrevPolicyType("Own-damage");
    }
    setStep(2);
    setTimeout(() => {
      onFocusChange({ focused: true });
    }, 1000);
  };

  //policy type selected logic

  const [policyTypeSelected, setPolicyTypeSelected] = useState(false);

  useEffect(() => {
    if (tempData?.policyType) {
      setPolicyTypeSelected(true);
    } else {
      setPolicyTypeSelected(false);
    }
  }, [tempData?.policyType]);

  //setting step based on od

  useEffect(() => {
    if (
      odOnly &&
      !policyTypeSelected
      // &&
      // (process.env?.REACT_APP_BROKER === "ABIBL" ||
      // process.env?.REACT_APP_BROKER === "FYNTUNE"
      // ||	process.env?.REACT_APP_BROKER === "GRAM")
    ) {
      setStep(1);
    } else {
      setStep(2);
    }
  }, [policyTypeSelected, odOnly]);

  //---drawer for mobile

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);

  const content = (
    <>
      <Body id="whole" className="someClass">
        {step === 2 && (
          <Page2 display={step === 2}>
            <Row>
              <ModelWrap>
                <Form onSubmit={handleSubmit(onSubmit)} className="w-100  ">
                  {temp_data?.ncb ? (
                    <RegiHeading>
                      {" "}
                      Select Previous{" "}
                      {temp_data?.odOnly &&
                        tempData?.policyType !== "Third-party" &&
                        "OD"}{" "}
                      Policy Expiration Date
                      {/* What is your policy expiration date ? */}
                    </RegiHeading>
                  ) : (
                    <>
                      {" "}
                      <div className="greetings-wrapper">
                        <div className="greetings-text">
                          {" "}
                          Quotes are just a step away.{" "}
                        </div>
                      </div>
                      <RegiHeading>
                        {" "}
                        Select Previous{" "}
                        {temp_data?.odOnly &&
                          tempData?.policyType !== "Third-party" &&
                          "OD"}{" "}
                        Policy Expiration Date
                      </RegiHeading>
                    </>
                  )}
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="w-100 mt-4 mx-auto"
                  >
                    <div
                      className={`py-2 dateTimeFour date-picker-ico-set w-100 hiddenInput single-date-picker ${
                        lessthan767 ? "mobileDate" : "text-center-picker"
                      }`}
                    >
                      <Controller
                        control={control}
                        name="expiry"
                        defaultValue={temp_data?.expiry}
                        render={({ onChange, onBlur, value, name }) => (
                          <SingleDatePicker
                            id="date_input"
                            date={
                              temp_data?.expiry && temp_data?.expiry !== "New"
                                ? moment(
                                    temp_data?.expiry
                                      .split("-")
                                      .reverse()
                                      .join("-")
                                  )
                                : moment()
                            }
                            focused={isFocused}
                            onDateChange={onDateChange}
                            onFocusChange={onFocusChange}
                            // numberOfMonths={2}
                            isOutsideRange={(date) =>
                              date.isAfter(moment(policyMax)) ||
                              date.isBefore(
                                moment(type === "cv" ? policyMin : policyMin1)
                              )
                            }
                            numberOfMonths={lessthan767 ? 1 : 2}
                          />
                        )}
                      />
                      {!!errors?.expiry && (
                        <Error className="mt-1">
                          {errors?.expiry?.message}
                        </Error>
                      )}
                    </div>
                    <input ref={register} name="ncb" type="hidden" />
                  </Col>
                </Form>
                {
                  <TabContinueWrap>
                    <div
                      onClick={() => {
                        handleNoPrev(); //temperory diable
                      }}
                    >
                      I dont know the previous policy details
                    </div>
                  </TabContinueWrap>
                }
              </ModelWrap>
            </Row>
          </Page2>
        )}
        <Page3 display={step === 1}>
          <PrevOdTypeContainer>
            <RegiHeading page3>
              {" "}
              Which cover did you have on your expiring policy?
            </RegiHeading>
            <OptionsOdType>
              {temp_data?.regDate?.slice(temp_data?.regDate?.length - 4) * 1 ===
                new Date().getFullYear() - 1 && (
                <OptionCard
                  onClick={() => {
                    handlePrevPolicySelection("Comprehensive");
                  }}
                >
                  <div className="heading">
                    {temp_data?.regDate &&
                    Number(
                      temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)
                    ) >= 2018
                      ? type === "car"
                        ? "Bundled 1+3 Policy"
                        : "Bundled 1+5 Policy"
                      : "1-Year Comprehensive/Standard Policy"}
                  </div>
                  <div className="subHeading">
                    {temp_data?.regDate &&
                    Number(
                      temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)
                    ) >= 2018
                      ? type === "car"
                        ? "1- Year Own Damage + 3-Year Third Party coverage"
                        : "1- Year Own Damage + 5-Year Third Party coverage"
                      : "Covers your bike and third party for 1 year"}
                  </div>
                </OptionCard>
              )}
              <OptionCard
                onClick={() => {
                  handlePrevPolicySelection("Third-party");
                }}
              >
                <div className="heading">
                  {temp_data?.regDate &&
                  Number(
                    temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)
                  ) >= 2018
                    ? type === "car"
                      ? "3-Year Third Party only"
                      : "5-Year Third Party only"
                    : "1-Year Third Party only"}
                </div>
                <div className="subHeading">
                  {temp_data?.regDate &&
                  Number(
                    temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)
                  ) >= 2018
                    ? type === "car"
                      ? "Covers third party only for 3 year"
                      : "Covers third party only for 5 year"
                    : "Covers third party only for 1 year"}
                </div>
              </OptionCard>
              {temp_data?.regDate &&
                Number(
                  temp_data?.regDate?.slice(temp_data?.regDate?.length - 4)
                ) <
                  new Date().getFullYear() - 1 &&
                (type === "bike" || type === "car") && (
                  <OptionCard
                    onClick={() => {
                      handlePrevPolicySelection("Own-damage");
                    }}
                  >
                    <div className="heading">1-Year Own Damage only</div>
                    <div className="subHeading">
                      Covers damages to your Vehicle only and not third party.
                      You bought this last year because you already had a valid
                      third party policy
                    </div>
                  </OptionCard>
                )}
              {temp_data?.regDate &&
                temp_data?.odOnly &&
                (type === "bike" || type === "car") && (
                  <OptionCard
                    onClick={() => {
                      handlePrevPolicySelection("Not sure");
                    }}
                  >
                    <div className="heading">Not Sure</div>
                    <div className="subHeading">
                      Not Sure about the type of policy
                    </div>
                  </OptionCard>
                )}
              {/* <OptionCard
								onClick={() => {
									handleNoPrev(); //temperory diable
								}}
							>
								<div className="heading">Not Sure</div>
								<div className="subHeading">
									Click here if you are not sure about previous policy details
								</div>
							</OptionCard> */}
            </OptionsOdType>
          </PrevOdTypeContainer>
        </Page3>
      </Body>
    </>
  );

  return tempData?.policyType !== "Not sure" && !lessthan767 ? (
    <Popup
      height={"auto"}
      width="690px"
      top="40%"
      show={show}
      onClose={onClose}
      content={content}
      position="middle"
      outside={temp_data?.expiry ? false : true}
      overFlowDisable={true}
      hiddenClose={temp_data?.expiry ? false : true}
    />
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          onClose={() => {
            setDrawer(false);
            onClose(false);
          }}
          onOpen={() => setDrawer(true)}
          ModalProps={{
            keepMounted: true,
            disableEscapeKeyDown: true,
          }}
          onBackdropClick={false}
        >
          <MobileDrawerBody>
            {temp_data?.expiry && (
              <CloseButton
                onClick={() => {
                  setDrawer(false);
                  onClose(false);
                }}
              >
                <svg
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ height: " 25px" }}
                >
                  <path
                    fill={"#000"}
                    d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                  ></path>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
              </CloseButton>
            )}
            {content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle disabledBackdrop={temp_data?.expiry ? false : true} />
    </>
  );
};

// PropTypes
PrevInsurerPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
PrevInsurerPopup.defaultProps = {
  show: false,
  onClose: () => {},
};

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 99999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 100000 !important;
  }
	.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    pointer-events: ${({ disabledBackdrop }) =>
      disabledBackdrop ? "none !important" : ""};
  }
}
`;
const Body = styled.div`
  padding: 0 15px 15px;
  position: relative;
  margin-top: 30px;
`;
const ModelWrap = styled.div`
  float: left;
  width: 100%;
  padding: 10px 22px 22px 14px;
  min-height: 480px;
  height: 440px;
  max-height: 480px;
  //overflow-y: scroll;
  //	margin-top: 30px;
  @media (max-width: 993px) {
    max-height: none;
    height: auto;
  }
  .hiddenInput {
    //display: none;
  }
  .text-center-picker .react-datepicker__input-container input {
    //	display: none;
  }
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 82%;
    z-index: -2;
    left: 9%;
    position: relative;
  }
  .react-datepicker-popper {
    left: -46px !important;
  }
  .cr__form-proceed .greetings-wrapper .greetings-text {
    text-align: inherit;
    margin-bottom: 32px;
  }
  .greetings-wrapper .greetings-text {
    font-size: 24px;
    color: #bdd400;
    background: ${({ theme }) =>
      theme.QuotePopups?.lg ||
      "-webkit-linear-gradient(-134deg, #bbd300, #5dae40)"};

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-weight: 700;
    margin-top: -15px;
  }

  .dateTimeFour input,
  .dateTimeFour input:focus,
  .dateTimeFour input:active,
  .dateTimeFour input:focus-within {
    display: none;
  }
  .DateInput {
    display: none;
  }
  .DayPicker__withBorder {
    bottom: 40px;
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px;
    display: none !important;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    display: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${({ theme }) =>
      `${theme.QuoteCard?.color} !important` || "#bdd400 !important"};
    border: ${({ theme }) => theme.QuoteCard?.border || "1px solid #bdd400"};
    color: #fff;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: ${({ theme }) =>
      theme.QuotePopups?.prevpopBorder || "33px solid #bdd400"};
    bottom: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    .greetings-wrapper .greetings-text {
      font-size: 20px;
    }
  }
`;
const RegiHeading = styled.div`
  text-align: center !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #333;
  width: 100%;
  text-align: left;
  margin-top: 12px;
  margin-bottom: ${(props) => (props.page3 ? "0px" : "	-20px")};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const RegiHeading2 = styled.div`
  text-align: center !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 17px;
  line-height: 24px;
  color: #333;
  width: 100%;
  text-align: left;
  margin-top: 54px;
  margin-bottom: 24px;
`;
const RegiHeading3 = styled.div`
  text-align: center !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 14px;
  line-height: 24px;
  color: #333;
  width: 100%;
  text-align: left;
  margin-top: 15px;
`;

const TabContinueWrap = styled.div`
  /* float: left; */
  position: absolute;
  z-index: 10000;
  bottom: 15px;

  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 0;
  border-radius: 10px;
  & div {
    font-size: 15px;
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
    font-weight: 550;
    color: ${({ theme }) => theme.QuoteBorderAndFont?.linkColor || "#00a2ff"};
    cursor: pointer;
    margin-top: 8px;
    &:hover {
      color: ${({ theme }) => theme.QuoteBorderAndFont?.linkColor || "#00a2ff"};
      text-decoration: underline;
    }
  }
`;

const TileConatiner = styled.div`
  position: relative;
  left: 0px;
`;

const BackBtn = styled.div`
  border: none;
  background: none;
  color: #808080;
  font-size: 14px;
  ${({ theme }) => theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  margin-top: 24px;
  margin-left: 62px;
  display: block;
  position: absolute;
  text-transform: uppercase;
`;

const NcbWrapPrev = styled.div`
  .vehRadioWrap input:checked + label {
    background-color: ${({ theme }) => theme.QuotePopups?.color2 || "#28a745"};
    color: #fff;
  }
`;

const Page3 = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
`;

const Page2 = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
`;

const PrevOdTypeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  width: 100%;
`;

const OptionsOdType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  width: 100%;
`;

const OptionCard = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 6px 16px #3469cb29;
  width: 100%;
  font-weight: 400;
  margin: 0 0 15px 0;
  border-radius: 6px;
  margin-top: 10px;
  .heading {
    font-size: 16px;
    font-weight: 600;
  }
  .subHeading {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    padding: 0px 40px;

    @media (max-width: 767px) {
      text-align: center;
    }
  }
  :hover {
    transform: scale(1.01);
  }
`;
const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;

export default PrevInsurerPopup;
