import React, { useEffect, useState } from "react";
import { CompactCard } from "components";
import { Row, Col, Badge } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import _ from "lodash";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Wording, clear } from "./proposal.slice";
import { currencyFormater } from "utils";

const InfoCard = ({
  selectedQuote,
  enquiry_id,
  Additional,
  type,
  token,
  Theme,
  breakinCase,
  lessthan767,
  GenerateDulicateEnquiry,
  typeId,
  journey_type,
  icr,
  TypeReturn,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { temp_data, checkAddon, wording } = useSelector(
    (state) => state.proposal
  );
  const addOnName = !_.isEmpty(checkAddon)
    ? checkAddon?.map(({ addon_name }) => addon_name)
    : [];

  const VehicleDetails = !_.isEmpty(selectedQuote?.mmvDetail)
    ? selectedQuote?.mmvDetail
    : {};

  const Accessories = !_.isEmpty(Additional?.accessories)
    ? _.compact(
        Additional?.accessories?.map((elem) => (elem?.sumInsured ? elem : null))
      )
    : [];

  const FilteredAccessories =
    !_.isEmpty(Accessories) && !_.isEmpty(addOnName)
      ? _.compact(
          Accessories?.map((item) =>
            addOnName.includes(item.name) ? item : null
          )
        )
      : [];

  const AdditionalCovers = !_.isEmpty(Additional?.additionalCovers)
    ? _.compact(
        Additional?.additionalCovers?.map((elem) =>
          elem?.sumInsured || elem?.name === "Geographical Extension"
            ? elem
            : elem?.lLNumberCleaner ||
              elem?.lLNumberConductor ||
              elem?.lLNumberDriver
            ? elem
            : null
        )
      )
    : [];

  const FilteredAdditionalCovers =
    !_.isEmpty(AdditionalCovers) && !_.isEmpty(addOnName)
      ? _.compact(
          AdditionalCovers?.map((item) =>
            addOnName.includes(item.name) ? item : null
          )
        )
      : [];

  const CPA = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident
    : [];

  const FilteredCPA =
    !_.isEmpty(CPA) && !_.isEmpty(addOnName)
      ? _.compact(
          CPA?.map((item) => (addOnName.includes(item.name) ? item : null))
        )
      : [];

  const Discounts = !_.isEmpty(Additional?.discounts)
    ? Additional?.discounts
    : [];

  const FilteredDiscounts =
    !_.isEmpty(Discounts) && !_.isEmpty(addOnName)
      ? _.compact(
          Discounts?.map((item) =>
            addOnName.includes(item.name) ? item : null
          )
        )
      : [];

  const quoteLog = !_.isEmpty(temp_data?.quoteLog) ? temp_data?.quoteLog : {};
  console.log("quoteLog", quoteLog);

  const redirectTo = () => {
    swal({
      title: "Confirm Action",
      text: ["Payment Initiated", "Payment Failed"].includes(
        temp_data?.journeyStage?.stage
      )
        ? `Payment status is Incomplete. To edit the Proposal an update is required.`
        : `Are you sure you want to change insurer?`,
      icon: ["Payment Initiated", "Payment Failed"].includes(
        temp_data?.journeyStage?.stage
      )
        ? "info"
        : "warning",
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Confirm",
          value: "confirm",
        },
      },
      dangerMode: true,
    }).then((caseValue) => {
      switch (caseValue) {
        case "confirm":
          ["Payment Initiated", "Payment Failed"].includes(
            temp_data?.journeyStage?.stage
          )
            ? GenerateDulicateEnquiry()
            : history.push(
                `/${type}/quotes?enquiry_id=${enquiry_id}${
                  token ? `&token=${token}` : ``
                }${typeId ? `&typeid=${typeId}` : ``}${
                  journey_type ? `&journey_type=${journey_type}` : ``
                }`
              );
          break;
        default:
      }
    });
  };

  const showDiscount = temp_data?.selectedQuote?.finalTotalDiscount
    ? temp_data?.selectedQuote?.finalTotalDiscount
    : "0";

  useEffect(() => {
    dispatch(clear("clear"));
    dispatch(clear("wording"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (temp_data?.selectedQuote?.policyId)
      dispatch(
        Wording({
          // policyId: temp_data?.selectedQuote?.policyId,
          policyId: enquiry_id,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  /*-----mobile states-----*/
  const [vehicleInfo, showVehicleInfo] = useState(lessthan767 ? false : true);
  const [breakup, showBreakup] = useState(lessthan767 ? false : true);
  const [addonsInfo, showAddonsInfo] = useState(lessthan767 ? false : true);
  const [coversInfo, showCoversInfo] = useState(lessthan767 ? false : true);
  const [accesInfo, showAccesInfo] = useState(lessthan767 ? false : true);
  const [discountInfo, showDiscountInfo] = useState(lessthan767 ? false : true);
  /*--x--mobile states--x--*/

  return (
    <CompactCard removeBottomHeader={"true"}>
      <div className="px-2">
        <DivContent>
          <Row>
            <Col className="m-0 p-0 d-flex justify-content-end">
              {(!breakinCase || TypeReturn(type) === "bike" || "") && !icr && (
                <Badge
                  variant={
                    Theme?.sideCardProposal?.editBadge
                      ? Theme?.sideCardProposal?.editBadge
                      : "dark"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => [
                    navigator &&
                      navigator?.vibrate &&
                      navigator.vibrate([100, 0, 50]),
                    redirectTo(),
                  ]}
                >
                  {"Change Insurer"}
                </Badge>
              )}
            </Col>
          </Row>
          <Row>
            <div className="mb-1 mt-1 mr-2">
              <img
                src={selectedQuote?.companyLogo}
                alt="logo"
                height={"60"}
                width={"auto"}
              />
            </div>
            <TagLineDiv>
              <HeaderTagLine>
                {selectedQuote?.companyName || `N/A`}
              </HeaderTagLine>
              <PTagLine>{selectedQuote?.productName || `N/A`}</PTagLine>
              {/* {(temp_data?.parent?.productSubTypeCode === "PCV" ||
                TypeReturn(type) === "car") && ( */}
              {quoteLog?.premiumJson?.icAddress &&
              (process.env.REACT_APP_BROKER === "PINC" ||
                process.env.REACT_APP_BROKER === "RB" ||
                process.env.REACT_APP_BROKER === "SRIYAH") ? (
                <PTagLine>{quoteLog?.premiumJson?.icAddress}</PTagLine>
              ) : (
                <noscript />
              )}
              <DivSumIns>
                <HeaderSumIns>Plan type & Policy type</HeaderSumIns>
                <PSumIns>
                  <PTagLine className="font-weight-bold">
                    {`${selectedQuote?.policyType || `N/A`}${
                      selectedQuote?.policyType === "Short Term"
                        ? ` (${
                            selectedQuote?.premiumTypeCode === "short_term_3" ||
                            selectedQuote?.premiumTypeCode ===
                              "short_term_3_breakin"
                              ? "3 Months"
                              : "6 Months"
                          }) - Comprehensive`
                        : ` - Annual`
                    }`}
                  </PTagLine>
                </PSumIns>
              </DivSumIns>
              {/* )} */}
            </TagLineDiv>
            <DivSumIns>
              <HeaderSumIns>IDV Value</HeaderSumIns>
              <PSumIns>
                {selectedQuote?.idv
                  ? `₹ ${currencyFormater(selectedQuote?.idv)}`
                  : `N/A`}
              </PSumIns>
            </DivSumIns>
          </Row>
          {!_.isEmpty(VehicleDetails) && (
            <>
              <Row>
                <DivPremium>
                  {lessthan767 ? (
                    <HeaderPremium
                      onClick={() => showVehicleInfo((prev) => !prev)}
                      className={!vehicleInfo ? "mb-2" : ""}
                    >
                      {vehicleInfo ? "Vehicle Details" : "Vehicle Details"}
                      <i
                        style={{
                          fontSize: "18px",
                          position: "relative",
                          top: "2.2px",
                        }}
                        className={
                          vehicleInfo
                            ? "ml-1 fa fa-angle-up"
                            : "ml-1 fa fa-angle-down"
                        }
                      ></i>
                    </HeaderPremium>
                  ) : (
                    <HeaderPremium>Vehicle Details</HeaderPremium>
                  )}
                </DivPremium>
              </Row>
              {vehicleInfo && (
                <RowTagPlan xs={1} sm={1} md={1} lg={1} xl={1}>
                  <UlTag>
                    <LiTag>
                      Manufacturer Name
                      <SpanTagRight
                        length={
                          VehicleDetails?.manfName &&
                          VehicleDetails?.manfName.length * 1 > 21
                        }
                      >
                        {`${VehicleDetails?.manfName}` || `N/A`}
                      </SpanTagRight>
                    </LiTag>
                    <LiTag>
                      Model Name
                      <SpanTagRight
                        length={
                          VehicleDetails?.modelName &&
                          VehicleDetails?.modelName.length * 1 > 36
                        }
                      >
                        {`${VehicleDetails?.modelName}` || `N/A`}
                      </SpanTagRight>
                    </LiTag>
                    {VehicleDetails?.versionName ? (
                      <LiTag>
                        Variant
                        <SpanTagRight
                          length={
                            VehicleDetails?.versionName &&
                            VehicleDetails?.versionName.length * 1 > 30
                          }
                        >
                          {`${VehicleDetails?.versionName} ${
                            TypeReturn(type) !== "bike" ? "-" : ""
                          } ${
                            TypeReturn(type) !== "bike"
                              ? temp_data?.parent?.productSubTypeCode === "GCV"
                                ? VehicleDetails?.grossVehicleWeight || "N/A"
                                : VehicleDetails?.cubicCapacity || "N/A"
                              : ""
                          } ${
                            TypeReturn(type) !== "bike"
                              ? temp_data?.parent?.productSubTypeCode === "GCV"
                                ? "gvw"
                                : "CC"
                              : ""
                          }` || `N/A`}
                        </SpanTagRight>
                      </LiTag>
                    ) : (
                      <noscript />
                    )}
                    {VehicleDetails?.fuelType ? (
                      <LiTag>
                        Fuel Type
                        <SpanTagRight>
                          {`${VehicleDetails?.fuelType}` || `N/A`}
                        </SpanTagRight>
                      </LiTag>
                    ) : (
                      <noscript />
                    )}
                  </UlTag>
                </RowTagPlan>
              )}
            </>
          )}
          <Row>
            <DivPremium>
              {lessthan767 ? (
                <HeaderPremium
                  onClick={() => showBreakup((prev) => !prev)}
                  className={!breakup ? "mb-3" : ""}
                >
                  Premium Break-up
                  <i
                    style={{
                      fontSize: "18px",
                      position: "relative",
                      top: "2.2px",
                    }}
                    className={
                      breakup ? "ml-1 fa fa-angle-up" : "ml-1 fa fa-angle-down"
                    }
                  ></i>
                </HeaderPremium>
              ) : (
                <HeaderPremium>Premium Break-up</HeaderPremium>
              )}
            </DivPremium>
          </Row>
          {breakup && (
            <RowTagPlan xs={1} sm={1} md={1} lg={1} xl={1}>
              <UlTag>
                <LiTag>
                  Own Damage Premium
                  <SpanTagRight>{`₹ ${
                    currencyFormater(quoteLog?.odPremium) || `0`
                  }`}</SpanTagRight>
                </LiTag>
                <LiTag>
                  Third Party Premium
                  <SpanTagRight>{`₹ ${
                    currencyFormater(
                      quoteLog?.tpPremium -
                        (quoteLog?.premiumJson?.tppdDiscount * 1 || 0)
                    ) || `0`
                  }`}</SpanTagRight>
                </LiTag>
                <LiTag>
                  Add-On Premium
                  <SpanTagRight>{`₹ ${
                    currencyFormater(quoteLog?.addonPremium) || `0`
                  }`}</SpanTagRight>
                </LiTag>
                {temp_data?.selectedQuote?.policyType !== "Third Party" && (
                  <LiTag>
                    No Claim Bonus{" "}
                    {`(${
                      temp_data?.corporateVehiclesQuoteRequest?.applicableNcb ||
                      quoteLog?.quoteDetails?.applicableNcb ||
                      `0`
                    }%)`}
                    <SpanTagRight>
                      {`- ₹ ${
                        currencyFormater(
                          temp_data?.selectedQuote?.revisedNcb
                        ) ||
                        currencyFormater(temp_data?.quoteLog?.revisedNcb) ||
                        `0`
                      }`}
                    </SpanTagRight>
                  </LiTag>
                )}
                <LiTag>
                  Total Discount{" "}
                  {`(NCB ${
                    temp_data?.corporateVehiclesQuoteRequest?.applicableNcb ||
                    quoteLog?.quoteDetails?.applicableNcb ||
                    `0`
                  }% Incl.)`}
                  <SpanTagRight>
                    {" "}
                    {`- ₹ ${currencyFormater(
                      temp_data?.selectedQuote?.finalTotalDiscount * 1
                        ? temp_data?.selectedQuote?.finalTotalDiscount * 1
                        : 0
                    )} `}
                  </SpanTagRight>
                </LiTag>
                <LiTag>
                  {"GST"}
                  <SpanTagRight>{`₹ ${
                    currencyFormater(quoteLog?.serviceTax) || `0`
                  }`}</SpanTagRight>
                </LiTag>
              </UlTag>
            </RowTagPlan>
          )}
          <Row xs={1} sm={1} md={1} lg={1} xl={1}>
            <DivTotal>
              <div>
                <small>Total Premium Payable</small>
              </div>
              <div>
                <StrongTag>{`₹ ${
                  currencyFormater(quoteLog?.finalPremiumAmount) || `0`
                }`}</StrongTag>
              </div>
            </DivTotal>
          </Row>
        </DivContent>
        {!_.isEmpty(Additional?.applicableAddons) && (
          <div>
            <RowTag>
              <DivBenefits margin={lessthan767}>
                {lessthan767 ? (
                  <PTag onClick={() => showAddonsInfo((prev) => !prev)}>
                    Selected Add-Ons
                    <i
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "2.2px",
                      }}
                      className={
                        addonsInfo
                          ? "ml-1 fa fa-angle-up"
                          : "ml-1 fa fa-angle-down"
                      }
                    ></i>
                  </PTag>
                ) : (
                  <PTag>Selected Add-Ons</PTag>
                )}
                {addonsInfo && (
                  <DivTag>
                    {Additional?.applicableAddons?.map(
                      ({ name, premium, sumInsured }, index) => (
                        <PBenefits>
                          <span className="mr-1">
                            <i
                              className={`fa fa-star ${
                                Theme?.sideCardProposal?.icon
                                  ? Theme?.sideCardProposal?.icon
                                  : "text-success"
                              }`}
                            />
                          </span>
                          {name}
                          {premium * 1 || sumInsured * 1 ? (
                            <>
                              <br />
                              <Badge
                                variant={
                                  Theme?.sideCardProposal?.badge
                                    ? Theme?.sideCardProposal?.badge
                                    : `success`
                                }
                              >{`₹ ${Math.round(
                                premium || sumInsured
                              )}`}</Badge>
                            </>
                          ) : (
                            <noscript />
                          )}
                        </PBenefits>
                      )
                    )}
                  </DivTag>
                )}
              </DivBenefits>
            </RowTag>
          </div>
        )}
        {/*Acc*/}
        {!_.isEmpty(
          _.compact([
            ...FilteredAccessories,
            temp_data?.selectedQuote?.addOnsData?.other &&
            !_.isEmpty(
              Object.keys(temp_data?.selectedQuote?.addOnsData?.other)
            ) &&
            Object.keys(temp_data?.selectedQuote?.addOnsData?.other).includes(
              "lLPaidDriver"
            )
              ? { name: "LL Paid Driver" }
              : "",
          ])
        ) && (
          <div>
            <RowTag>
              <DivBenefits margin={lessthan767}>
                {lessthan767 ? (
                  <PTag onClick={() => showAccesInfo((prev) => !prev)}>
                    Selected Accessories
                    <i
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "2.2px",
                      }}
                      className={
                        accesInfo
                          ? "ml-1 fa fa-angle-up"
                          : "ml-1 fa fa-angle-down"
                      }
                    ></i>
                  </PTag>
                ) : (
                  <PTag>Selected Accessories</PTag>
                )}
                {accesInfo && (
                  <DivTag>
                    {_.compact([
                      ...FilteredAccessories,
                      temp_data?.selectedQuote?.addOnsData?.other &&
                      !_.isEmpty(
                        Object.keys(temp_data?.selectedQuote?.addOnsData?.other)
                      ) &&
                      Object.keys(
                        temp_data?.selectedQuote?.addOnsData?.other
                      ).includes("lLPaidDriver")
                        ? { name: "LL Paid Driver" }
                        : "",
                    ])?.map(({ name, sumInsured }, index) => (
                      <PBenefits>
                        <span className="mr-1">
                          <i
                            className={`fa fa-star ${
                              Theme?.sideCardProposal?.icon
                                ? Theme?.sideCardProposal?.icon
                                : "text-success"
                            }`}
                          />
                        </span>
                        {name}
                        {sumInsured * 1 ? (
                          <>
                            <br />
                            <Badge
                              variant={
                                Theme?.sideCardProposal?.badge
                                  ? Theme?.sideCardProposal?.badge
                                  : `success`
                              }
                            >{`₹ ${sumInsured}`}</Badge>
                          </>
                        ) : (
                          <noscript />
                        )}
                      </PBenefits>
                    ))}
                  </DivTag>
                )}
              </DivBenefits>
            </RowTag>
          </div>
        )}
        {/*Additional Covers*/}
        {(!_.isEmpty(FilteredAdditionalCovers) ||
          (!_.isEmpty(FilteredCPA)
            ? FilteredCPA[0]?.name
              ? true
              : false
            : false)) && (
          <div>
            <RowTag>
              <DivBenefits margin={lessthan767}>
                {lessthan767 ? (
                  <PTag onClick={() => showCoversInfo((prev) => !prev)}>
                    Additional Covers
                    <i
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "2.2px",
                      }}
                      className={
                        coversInfo
                          ? "ml-1 fa fa-angle-up"
                          : "ml-1 fa fa-angle-down"
                      }
                    ></i>
                  </PTag>
                ) : (
                  <PTag>Additional Covers</PTag>
                )}
                {coversInfo && (
                  <DivTag>
                    {FilteredAdditionalCovers?.map(
                      (
                        {
                          name,
                          sumInsured,
                          lLNumberCleaner,
                          lLNumberConductor,
                          lLNumberDriver,
                        },
                        index
                      ) => (
                        <>
                          <PBenefits>
                            <span className="mr-1">
                              <i
                                className={`fa fa-star ${
                                  Theme?.sideCardProposal?.icon
                                    ? Theme?.sideCardProposal?.icon
                                    : "text-success"
                                }`}
                              />
                            </span>
                            {name}
                            {(sumInsured * 1 && name !== "LL paid driver") ||
                            name === "LL paid driver/conductor/cleaner" ? (
                              <>
                                {name === "LL paid driver/conductor/cleaner" ? (
                                  <>
                                    <br />
                                    {lLNumberCleaner ? (
                                      <Badge
                                        variant={
                                          Theme?.sideCardProposal?.badge
                                            ? Theme?.sideCardProposal?.badge
                                            : `success`
                                        }
                                      >{`No. of Cleaners: ${
                                        temp_data?.selectedQuote
                                          ?.companyAlias === "godigit"
                                          ? 1
                                          : Number(lLNumberCleaner) < 3
                                          ? lLNumberCleaner
                                          : 2
                                      }`}</Badge>
                                    ) : (
                                      <noscript />
                                    )}
                                    {lLNumberConductor ? (
                                      <Badge
                                        variant={
                                          Theme?.sideCardProposal?.badge
                                            ? Theme?.sideCardProposal?.badge
                                            : `success`
                                        }
                                        className={
                                          lLNumberCleaner ? "mx-1" : "mr-1"
                                        }
                                      >{`No. of Conductors: ${
                                        temp_data?.selectedQuote
                                          ?.companyAlias === "godigit"
                                          ? 1
                                          : Number(lLNumberConductor) < 3
                                          ? lLNumberConductor
                                          : 2
                                      }`}</Badge>
                                    ) : (
                                      <noscript />
                                    )}
                                    {lLNumberDriver ? (
                                      <Badge
                                        variant={
                                          Theme?.sideCardProposal?.badge
                                            ? Theme?.sideCardProposal?.badge
                                            : `success`
                                        }
                                        className={
                                          lLNumberConductor && lLNumberCleaner
                                            ? ""
                                            : lLNumberConductor ||
                                              lLNumberCleaner
                                            ? "mx-1"
                                            : "mr-1"
                                        }
                                      >{`No. of Drivers: ${
                                        temp_data?.selectedQuote
                                          ?.companyAlias === "godigit"
                                          ? 1
                                          : Number(lLNumberDriver) < 3
                                          ? lLNumberDriver
                                          : 2
                                      }`}</Badge>
                                    ) : (
                                      <noscript />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <br />
                                    <Badge
                                      variant={
                                        Theme?.sideCardProposal?.badge
                                          ? Theme?.sideCardProposal?.badge
                                          : `success`
                                      }
                                    >{`₹ ${sumInsured}`}</Badge>
                                  </>
                                )}
                              </>
                            ) : (
                              <noscript />
                            )}
                          </PBenefits>
                        </>
                      )
                    )}
                    {FilteredCPA?.map(({ name, sumInsured }, index) => (
                      <PBenefits>
                        {name ? (
                          <span className="mr-1">
                            <i
                              className={`fa fa-star ${
                                Theme?.sideCardProposal?.icon
                                  ? Theme?.sideCardProposal?.icon
                                  : "text-success"
                              }`}
                            />
                          </span>
                        ) : (
                          <noscript />
                        )}
                        {name}
                        {sumInsured * 1 ? (
                          <>
                            <br />
                            <Badge
                              variant={
                                Theme?.sideCardProposal?.badge
                                  ? Theme?.sideCardProposal?.badge
                                  : `success`
                              }
                            >{`₹ ${sumInsured}`}</Badge>
                          </>
                        ) : (
                          <noscript />
                        )}
                      </PBenefits>
                    ))}
                  </DivTag>
                )}
              </DivBenefits>
            </RowTag>
          </div>
        )}
        {/*Discounts*/}
        {!_.isEmpty(FilteredDiscounts) ||
        quoteLog?.premiumJson?.tppdDiscount * 1 ? (
          <div>
            <RowTag>
              <DivBenefits margin={lessthan767}>
                {lessthan767 ? (
                  <PTag onClick={() => showDiscountInfo((prev) => !prev)}>
                    Discounts
                    <i
                      style={{
                        fontSize: "18px",
                        position: "relative",
                        top: "2.2px",
                      }}
                      className={
                        discountInfo
                          ? "ml-1 fa fa-angle-up"
                          : "ml-1 fa fa-angle-down"
                      }
                    ></i>
                  </PTag>
                ) : (
                  <PTag>Discounts</PTag>
                )}
                {discountInfo ? (
                  <DivTag>
                    {FilteredDiscounts?.map(({ name, sumInsured }, index) => (
                      <PBenefits>
                        <span className="mr-1">
                          <i
                            className={`fa fa-star ${
                              Theme?.sideCardProposal?.icon
                                ? Theme?.sideCardProposal?.icon
                                : "text-success"
                            }`}
                          />
                        </span>
                        {name === "voluntary_insurer_discounts"
                          ? "Voluntary Deductibles"
                          : name
                              .replace(/_/g, " ")
                              .split(" ")
                              .map(_.capitalize)
                              .join(" ")}
                        {sumInsured * 1 ? (
                          <>
                            <br />
                            <Badge
                              variant={
                                Theme?.sideCardProposal?.badge
                                  ? Theme?.sideCardProposal?.badge
                                  : `success`
                              }
                            >{`₹ ${sumInsured}`}</Badge>
                          </>
                        ) : (
                          <noscript />
                        )}
                      </PBenefits>
                    ))}
                  </DivTag>
                ) : (
                  <noscript />
                )}
                {quoteLog?.premiumJson?.tppdDiscount * 1 ? (
                  <DivTag>
                    <PBenefits>
                      <span className="mr-1">
                        <i
                          className={`fa fa-star ${
                            Theme?.sideCardProposal?.icon
                              ? Theme?.sideCardProposal?.icon
                              : "text-success"
                          }`}
                        />
                      </span>
                      {"TPPD Discount"}
                      <br />
                      <Badge
                        variant={
                          Theme?.sideCardProposal?.badge
                            ? Theme?.sideCardProposal?.badge
                            : `success`
                        }
                      >{`₹ ${quoteLog?.premiumJson?.tppdDiscount}`}</Badge>
                    </PBenefits>
                  </DivTag>
                ) : (
                  <noscript />
                )}
              </DivBenefits>
            </RowTag>
          </div>
        ) : (
          <noscript />
        )}
        {wording?.pdfUrl ? (
          <RowTag xs={1} sm={1} md={1} lg={1} xl={1}>
            <DivDownload>
              <a
                href={
                  wording?.pdfUrl
                    ? `${wording?.pdfUrl}&download=true`
                    : "/" || "/"
                }
                target="_blank"
                rel="noopener noreferrer"
                download
                className="brochure"
                style={
                  Theme?.sideCardProposal?.linkColor
                    ? { color: Theme?.sideCardProposal?.linkColor }
                    : {}
                }
              >
                <img
                  src={`${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/pdf.png`}
                  alt="BrocureImage"
                  height="36"
                  style={{ paddingRight: "10px" }}
                />
                <SpanDownload>Download Policy Wording</SpanDownload>
              </a>
            </DivDownload>
            {/* <DivDownload>
						<a
							// href={IcDetails?.travel_broucher || "/"}
							target="_blank"
							rel="noopener noreferrer"
							download
							className="brochure"
						>
							<img
								src="/assets/images/pdf.png"
								alt="BrocureImage"
								height="36"
								style={{ paddingRight: "10px" }}
							/>
							<SpanDownload>Download Brochure</SpanDownload>
						</a>
					</DivDownload> */}
          </RowTag>
        ) : (
          <noscript />
        )}
      </div>
    </CompactCard>
  );
};

const DivContent = styled.div`
  margin-top: -55px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;

const TagLineDiv = styled.div`
  width: 100%;
`;

const HeaderTagLine = styled.h6`
  font-size: 14px;
  font-weight: bold;
`;

const PTagLine = styled.p`
  font-size: 80%;
  margin-top: -5px;
  font-weight: 400;
`;

const DivSumIns = styled.div`
  margin-bottom: 15px;
`;

const HeaderSumIns = styled.h6`
  font-size: 11px;
  color: gray;
  font-weight: 1000;
`;

const PSumIns = styled.p`
  font-size: 14.5px;
  font-weight: 700;
  margin-top: -7px;
`;

const DivPremium = styled.div`
  margin-bottom: 15px;
  display: flex;
  margin-top: -10px;
`;

const HeaderPremium = styled.h6`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const RowTagPlan = styled(Row)`
  margin-bottom: 10px;
`;

const UlTag = styled.ul`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: -10px;
  list-style: none;
  line-height: 18px;
  padding: 8px 0;
`;

const LiTag = styled.li`
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const SpanTagRight = styled.span`
  float: right;
  ${({ length }) => (length ? "font-size: 9px;" : "")}
`;

const DivTotal = styled.div`
  border: 1px dashed #f8cf39;
  margin-top: -10px;
  padding: 5px 10px;
`;

const RowTag = styled(Row)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 15px;
`;

const DivTag = styled.div`
  width: 100%;
  padding: 5px 6px;
`;

const SpanRight = styled.div`
  float: right;
  display: flex;
`;

const PTag = styled.p`
  padding: 5px 6px;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 14px;
`;

const StrongTag = styled.strong`
  font-size: 25px;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

const DivBenefits = styled.div`
  margin-top: ${({ margin }) => (margin ? "2px" : "10px")};
  width: 100%;
`;

const PBenefits = styled.p`
  font-size: 13px;
  font-weight: 400;
  .text-danger {
    color: ${({ theme }) =>
      process.env.REACT_APP_BROKER === "UIB" &&
      theme.primary &&
      theme.primary}!important;
  }
  .badge-danger {
    background: ${({ theme }) =>
      process.env.REACT_APP_BROKER === "UIB" &&
      theme.primary &&
      theme.primary}!important;
  }
`;

const DivDownload = styled.div`
  margin-top: 15px;
  padding: 5px 6px;
`;

const SpanDownload = styled.span`
  font-size: 12px;
  color: ${({ theme }) =>
    theme?.sideCardProposal?.linkColor
      ? theme?.sideCardProposal?.linkColor
      : "#00a2ff"};
`;

//proposal-page-font
export const GlobalStyle = createGlobalStyle`
body {
 // font-family:basier_squaremedium;
}
`;

export default InfoCard;
