import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row, Col, Table, Form, Badge } from "react-bootstrap";
import Popup from "../../../../components/Popup/Popup";
import InfoCardKnowMore from "./knowMoreInfo";
import { useForm } from "react-hook-form";
import { Loader } from "components";
import { Error } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { differenceInDays, subMonths } from "date-fns";
import moment from "moment";
import { toDate } from "utils";
import { currencyFormater, camelToUnderscore, Encrypt } from "utils";
import { setTempData } from "../../filterConatiner/quoteFilter.slice";
import CustomTooltip from "../../../../components/tooltip/CustomTooltip";
import debounce from "lodash.debounce";
import {
  setSelectedQuote,
  SaveQuotesData,
  SaveAddonsData,
  clear,
  setQuotesList,
  saveSelectedQuoteResponse,
  GarageList,
  setGarage,
  CancelAll,
  DownloadPremiumBreakup,
} from "../../quote.slice";
import "./knowMorePopup.scss";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useOutsideClick } from "hoc";
import MobilePremiumBreakup from "./mobilePremiumBreakup";
import {
  brokerEmailFunction,
  brokerNumberFunction,
  getLogoUrl,
  getAddonName,
} from "../../quoteUtil";
import { TypeReturn } from "modules/type";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const KnowMorePopup = ({
  quote,
  show,
  onClose,
  selectedKnow,
  totalAddon,
  finalPremium,
  totalPremium,
  gst,
  totalPremiumA,
  totalPremiumB,
  applicableAddons,
  type,
  prevInsName,
  totalPremiumC,
  revisedNcb,
  popupCard,
  setPrevPopup,
  prevPopup,
  setSelectedId,
  setSelectedCompanyName,
  totalApplicableAddonsMotor,
  addonDiscount,
  setSendQuotes,
  setSendPdf,
  sendQuotes,
  addonDiscountPercentage,
  allQuoteloading,
  uwLoading,
  setPrevPopupTp,
  setQuoteData,
  otherDiscounts,
  displayAddress,
}) => {
  console.log(otherDiscounts, "otherDiscounts");
  console.log(quote, "quote");
  const lessthan1350 = useMediaPredicate("(max-width: 1350px)");
  const lessthan993 = useMediaPredicate("(max-width: 993px)");
  const moreThan993 = useMediaPredicate("(min-width: 993px)");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const moreThan767 = useMediaPredicate("(min-width: 767px)");
  const lessthan576 = useMediaPredicate("(max-width: 576px)");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const {
    addOnsAndOthers,
    saveQuoteResponse,
    saveQuoteLoader,
    updateQuoteLoader,
    garage,
    loader,
    shortTerm,
    selectedTab,
  } = useSelector((state) => state.quotes);

  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const typeId = query.get("typeid");
  const journey_type = query.get("journey_type");
  const { temp_data, prefill } = useSelector((state) => state.home);
  const { prevInsList, tempData } = useSelector((state) => state.quoteFilter);
  const [key, setKey] = useState(selectedKnow);
  const { handleSubmit, register, errors, control, reset, watch, setValue } =
    useForm({});
    
  //getting screenwidth
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  // ----------------getting addon ---------------------

  const GetAddonValue = (addonName, addonDiscountPercentage) => {
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let additional = Object.keys(quote?.addOnsData?.additional);
    let selectedAddons = addOnsAndOthers?.selectedAddons;

    if (inbuilt?.includes(addonName)) {
      return (
        <span
          style={{
            ...(lessthan993 && { fontSize: "10px" }),
          }}
        >
          {Number(quote?.addOnsData?.inBuilt[addonName]) !== 0 ? (
            `₹ ${currencyFormater(
              parseInt(
                quote?.company_alias === "royal_sundaram"
                  ? addonName === "ncbProtection" ||
                    addonName === "engineProtector" ||
                    addonName === "tyreSecure"
                    ? quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      200
                      ? 200
                      : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100
                    : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      100
                    ? 100
                    : quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100
                  : quote?.addOnsData?.inBuilt[addonName]
              )
            )}`
          ) : (
            <>
              {addonName === "roadSideAssistance" &&
              quote?.company_alias === "reliance" ? (
                <>-</>
              ) : (
                <>
                  {lessthan993 ? (
                    <>
                      {" "}
                      <i className="fa fa-check" style={{ color: "green" }}></i>
                    </>
                  ) : (
                    <>
                      <Badge
                        variant="primary"
                        style={{ position: "relative", bottom: "2px" }}
                      >
                        Included
                      </Badge>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </span>
      );
    } else if (
      additional?.includes(addonName) &&
      selectedAddons?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
      typeof quote?.addOnsData?.additional[addonName] === "number"
    ) {
      return `₹ ${currencyFormater(
        quote?.company_alias === "royal_sundaram"
          ? addonName === "ncbProtection" ||
            addonName === "engineProtector" ||
            addonName === "tyreSecure"
            ? quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100 <
              200
              ? 200
              : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100
            : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100 <
              100
            ? 100
            : quote?.addOnsData?.additional[addonName] -
              (quote?.addOnsData?.additional[addonName] *
                addonDiscountPercentage) /
                100
          : quote?.addOnsData?.additional[addonName]
      )}`;
    } else if (
      additional?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) === 0
    ) {
      return "N/A";
    } else if (
      !additional?.includes(addonName) &&
      selectedAddons?.includes(addonName)
    ) {
      return "N/A";
    } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
      return "N/A";
    } else if (
      additional?.includes(addonName) &&
      !selectedAddons?.includes(addonName)
    ) {
      return "N/S";
    } else {
      return "N/A";
    }
  };

  // ----------------getting addon for pdf without badge---------------------

  const GetAddonValueNoBadge = (addonName, addonDiscountPercentage) => {
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let additional = Object.keys(quote?.addOnsData?.additional);
    let selectedAddons = addOnsAndOthers?.selectedAddons;

    if (inbuilt?.includes(addonName)) {
      return `${
        Number(quote?.addOnsData?.inBuilt[addonName]) !== 0
          ? `₹ ${currencyFormater(
              parseInt(
                quote?.company_alias === "royal_sundaram"
                  ? addonName === "ncbProtection" ||
                    addonName === "engineProtector" ||
                    addonName === "tyreSecure"
                    ? quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      200
                      ? 200
                      : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100
                    : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      100
                    ? 100
                    : quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100
                  : quote?.addOnsData?.inBuilt[addonName]
              )
            )}`
          : addonName === "roadSideAssistance" &&
            quote?.company_alias === "reliance"
          ? "  "
          : "Included"
      }`;
    } else if (
      additional?.includes(addonName) &&
      selectedAddons?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
      typeof quote?.addOnsData?.additional[addonName] === "number"
    ) {
      return `₹ ${currencyFormater(
        quote?.company_alias === "royal_sundaram"
          ? addonName === "ncbProtection" ||
            addonName === "engineProtector" ||
            addonName === "tyreSecure"
            ? quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100 <
              200
              ? 200
              : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100
            : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100 <
              100
            ? 100
            : quote?.addOnsData?.additional[addonName] -
              (quote?.addOnsData?.additional[addonName] *
                addonDiscountPercentage) /
                100
          : quote?.addOnsData?.additional[addonName]
      )}`;
    } else if (
      additional?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) === 0
    ) {
      return "N/A";
    } else if (
      !additional?.includes(addonName) &&
      selectedAddons?.includes(addonName)
    ) {
      return "N/A";
    } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
      return "N/A";
    } else if (
      additional?.includes(addonName) &&
      !selectedAddons?.includes(addonName)
    ) {
      return "N/S";
    } else {
      return "N/A";
    }
  };

  // ----------------others addon for privare car---------------------

  let others = quote?.addOnsData?.other
    ? Object.keys(
        quote?.company_alias === "royal_sundaram" &&
          !addOnsAndOthers?.selectedAddons.includes("returnToInvoice")
          ? _.omit(quote?.addOnsData?.other, [
              "fullInvoicePrice",
              "fullInvoicePriceInsuranceCost",
              "fullInvoicePriceRegCharges",
              "fullInvoicePriceRoadtax",
            ])
          : quote?.addOnsData?.other
      )
    : [];

  let othersList = quote?.addOnsData?.other;

  // ----------------expiry check---------------------

  const [daysToExpiry, setDaysToExpiry] = useState(false);

  useEffect(() => {
    let a = temp_data?.expiry;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    setDaysToExpiry(diffDays);
  }, [temp_data?.expiry]);

  // ----------------prev ic status ---------------------

  const [prevIcData, setPrevIcData] = useState(false);

  useEffect(() => {
    if (temp_data?.prevIc && temp_data?.prevIc !== "Not selected") {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);

  //-------------buy now conditions checking----------------

  const handleClick = async () => {
    dispatch(CancelAll(true));
    onClose(false);
    if (
      ((quote?.policyType === "Third Party" &&
        process.env?.REACT_APP_BROKER === "GRAM") ||
        tempData?.policyType === "Third-party") &&
      // ||(temp_data?.tab === "tab1" && daysToExpiry > 90))
      !prevIcData &&
      !temp_data?.fastlaneNcbPopup &&
      !temp_data?.newCar &&
      daysToExpiry <= 90
    ) {
      setQuoteData({
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        icId: quote?.companyId,
        icAlias: quote?.companyName,
        productSubTypeId: quote?.productSubTypeId,
        masterPolicyId: quote?.masterPolicyId?.policyId,
        premiumJson: {
          ...quote,
          ...(quote?.companyAlias === "royal_sundaram" && {
            icAddress: displayAddress,
            addOnsData: {
              ...quote?.addOnsData,
              ...(!_.isEmpty(quote?.addOnsData?.additional)
                ? {
                    additional: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.additional).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
              ...(!_.isEmpty(quote?.addOnsData?.inBuilt)
                ? {
                    inBuilt: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.inBuilt).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
            },
          }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.coverUnnamedPassengerValue * 1 && {
              coverUnnamedPassengerValue:
                quote?.coverUnnamedPassengerValue *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.motorAdditionalPaidDriver * 1 && {
              motorAdditionalPaidDriver:
                quote?.motorAdditionalPaidDriver *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
        },
        exShowroomPriceIdv: quote?.idv,
        exShowroomPrice: quote?.showroomPrice,
        finalPremiumAmount: finalPremium,
        odPremium:
          quote?.finalOdPremium * 1 +
          (ElectricalValue() + NonElectricalValue() + BiFuelValue()),
        tpPremium: totalPremiumB,
        addonPremiumTotal: totalAddon,
        serviceTax: gst,
        revisedNcb: revisedNcb,
        applicableAddons:
          quote?.companyAlias === "royal_sundaram"
            ? !_.isEmpty(applicableAddons)
              ? applicableAddons?.map((el) => ({
                  ...el,
                  ...{
                    premium:
                      el?.premium * 1 !== 0
                        ? [
                            "NCB Protection",
                            "Engine Protector",
                            "Tyre Secure",
                          ].includes(el?.name)
                          ? el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            200
                            ? 200
                            : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100
                          : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            100
                          ? 100
                          : el?.premium -
                            (el?.premium * addonDiscountPercentage) / 100
                        : 0,
                  },
                }))
              : []
            : applicableAddons,
        prevInsName: prevInsName[0]?.previousInsurer,
      });
      setPrevPopupTp(true);
    } else if (
      !temp_data?.newCar &&
      (!prevIcData || temp_data?.fastlaneNcbPopup) &&
      !popupCard &&
      tempData?.policyType !== "Third-party" &&
      (quote?.policyType === "Comprehensive" ||
        quote?.policyType === "Short Term" ||
        quote?.policyType === "Own Damage") &&
      daysToExpiry < 90
    ) {
      setPrevPopup(true);
      setSelectedId(quote?.policyId);
      setSelectedCompanyName(quote?.companyName);
      dispatch(
        setTempData({
          oldPremium: finalPremium,
        })
      );
    } else if (
      !prevPopup &&
      (temp_data?.newCar ||
        prevIcData ||
        !temp_data?.fastlaneNcbPopup ||
        quote?.policyType === "Third Party" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90)
    ) {
      dispatch(setSelectedQuote(quote));

      if (
        temp_data?.tab === "tab2" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90
      ) {
        if (temp_data?.tab === "tab2") {
          var newSelectedAccesories = [];
          if (
            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
          ) {
            var newD = {
              name: "External Bi-Fuel Kit CNG/LPG",
              sumInsured: Number(addOnsAndOthers?.externalBiFuelKit),
            };
            newSelectedAccesories.push(newD);
          }
          var discount = [];

          if (addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")) {
            discount.push({ name: "TPPD Cover" });
          }

          var data1 = {
            enquiryId: temp_data?.enquiry_id || enquiry_id,

            addonData: {
              addons: null,
              accessories: newSelectedAccesories,
              discounts: discount,
            },
          };

          dispatch(SaveAddonsData(data1));
        }
      } else {
        let addonLists = [];
        let addonListRedux = addOnsAndOthers?.selectedAddons || [];

        addonListRedux.forEach((el) => {
          let data = {
            name: getAddonName(el),
          };
          addonLists.push(data);
        });

        var data2 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,

          addonData: {
            addons: addonLists,
            compulsory_personal_accident:
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? [
                    {
                      name: "Compulsory Personal Accident",
                      ...(!_.isEmpty(_.compact(addOnsAndOthers?.isTenure)) && {
                        tenure: TypeReturn(type) === "car" ? 3 : 5,
                      }),
                    },
                  ]
                : [
                    {
                      reason:
                        "I have another motor policy with PA owner driver cover in my name",
                    },
                  ],
          },
        };
        dispatch(SaveAddonsData(data2));
      }
      var QuoteData = {
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        icId: quote?.companyId,
        icAlias: quote?.companyName,
        productSubTypeId: quote?.productSubTypeId,
        masterPolicyId: quote?.masterPolicyId?.policyId,
        premiumJson: {
          ...quote,
          ...(quote?.companyAlias === "royal_sundaram" && {
            icAddress: displayAddress,
            addOnsData: {
              ...quote?.addOnsData,
              ...(!_.isEmpty(quote?.addOnsData?.additional)
                ? {
                    additional: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.additional).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
              ...(!_.isEmpty(quote?.addOnsData?.inBuilt)
                ? {
                    inBuilt: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.inBuilt).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
            },
          }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.coverUnnamedPassengerValue * 1 && {
              coverUnnamedPassengerValue:
                quote?.coverUnnamedPassengerValue *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.motorAdditionalPaidDriver * 1 && {
              motorAdditionalPaidDriver:
                quote?.motorAdditionalPaidDriver *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
        },
        exShowroomPriceIdv: quote?.idv,
        exShowroomPrice: quote?.showroomPrice,
        finalPremiumAmount: finalPremium,
        odPremium:
          quote?.finalOdPremium * 1 +
          (ElectricalValue() + NonElectricalValue() + BiFuelValue()),
        tpPremium: totalPremiumB,
        addonPremiumTotal: totalAddon,
        serviceTax: gst,
        revisedNcb: revisedNcb,
        applicableAddons:
          quote?.companyAlias === "royal_sundaram"
            ? !_.isEmpty(applicableAddons)
              ? applicableAddons?.map((el) => ({
                  ...el,
                  ...{
                    premium:
                      el?.premium * 1 !== 0
                        ? [
                            "NCB Protection",
                            "Engine Protector",
                            "Tyre Secure",
                          ].includes(el?.name)
                          ? el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            200
                            ? 200
                            : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100
                          : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            100
                          ? 100
                          : el?.premium -
                            (el?.premium * addonDiscountPercentage) / 100
                        : 0,
                  },
                }))
              : []
            : applicableAddons,
      };
      dispatch(SaveQuotesData(QuoteData));
    }
  };

  //-----proceeding to proposal after buy now api success---------

  useEffect(() => {
    if (saveQuoteResponse && !updateQuoteLoader) {
      history.push(
        `/${type}/proposal-page?enquiry_id=${enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
      dispatch(saveSelectedQuoteResponse(false));
      dispatch(setQuotesList([]));
      dispatch(clear());
    }
  }, [saveQuoteResponse, updateQuoteLoader]);

  //---------------pincide api calling cashless garage with debounce------------------

  const pincode = watch("pincode");
  const cityName = watch("cityName");

  const cashelssCity = (pincode) => {
    if (pincode?.length === 6 && typeof (pincode * 1) === "number") {
      dispatch(
        GarageList({
          pincode: pincode,
          company_alias: quote?.company_alias,
          enquiryId: enquiry_id,
        })
      );
    } else {
      dispatch(
        GarageList({
          company_alias: quote?.company_alias,
          searchString: pincode,
          city_name: pincode,
          pincode: pincode,
          enquiryId: enquiry_id,
        })
      );
    }
  };

  const debouncedChangeHandler = useCallback(debounce(cashelssCity, 1500), []);

  useEffect(() => {
    if (pincode?.length >= 1) {
      debouncedChangeHandler(pincode);
    } else {
      dispatch(setGarage([]));
    }
  }, [pincode]);

  //------------------clearing cashless garage data----------------------

  const clearAllGarage = () => {
    setValue("pincode", null);
    setValue("cityName", "");
    dispatch(setGarage([]));
  };

  //------------------handling pincode search----------------------

  const [showInput, setShowInput] = useState(false);

  const handleSearch = (e) => {
    setValue("pincode", null);
    let query = e.target.value;
    let companyAlias = quote?.company_alias && quote?.company_alias;
    if (companyAlias && query?.length >= 3) {
      dispatch(
        _.debounce(
          GarageList({
            company_alias: quote?.company_alias,
            searchString: query,
            city_name: query,
            pincode: pincode,
            enquiryId: enquiry_id,
          }),
          1000
        )
      );
    }
  };

  const showHandle = () => {
    setShowInput(!showInput);
  };

  const dropRef = useRef(null);

  useOutsideClick(dropRef, () => setShowInput(false));

  // ----------------premium pdf---------------------

  const handlePremPdfClick = async () => {
    let addonList = {};
    if (
      TypeReturn(type) === "car" ||
      TypeReturn(type) === "bike" ||
      TypeReturn(type) === "cv"
    ) {
      totalApplicableAddonsMotor.forEach((item) => {
        if (GetAddonValueNoBadge(item, addonDiscountPercentage) === "N/A") {
        } else {
          addonList[getAddonName(item)] = `${
            GetAddonValueNoBadge(item, addonDiscountPercentage) === "N/S"
              ? "Not selected"
              : GetAddonValueNoBadge(item, addonDiscountPercentage) === "N/A"
              ? "Not Available"
              : GetAddonValueNoBadge(item, addonDiscountPercentage)
          }`;
        }
      });

      others.forEach((item) => {
        addonList[
          `${
            camelToUnderscore(item) &&
            camelToUnderscore(item)
              .replace(/_/g, " ")
              .split(" ")
              .map(_.capitalize)
              .join(" ")
          }`
        ] = ` ${
          Number(currencyFormater(othersList[item])) === 0
            ? "Included"
            : `₹ ${currencyFormater(othersList[item])}`
        } `;
      });

      console.log("addonList", addonList);
    } else {
      if (temp_data?.journeyCategory === "GCV")
        addonList = {
          "Zero Depreciation": `${
            GetAddonValueNoBadge(
              "zeroDepreciation",
              addonDiscountPercentage
            ) === "N/S" ? (
              "Not selected"
            ) : GetAddonValueNoBadge(
                "zeroDepreciation",
                addonDiscountPercentage
              ) === "N/A" ? (
              "Not Available"
            ) : (
              <>
                {GetAddonValueNoBadge(
                  "zeroDepreciation",
                  addonDiscountPercentage
                )}
              </>
            )
          }`,
          "IMT - 23": `${
            GetAddonValueNoBadge("imt23", addonDiscountPercentage) === "N/S" ? (
              "Not selected"
            ) : GetAddonValueNoBadge("imt23", addonDiscountPercentage) ===
              "N/A" ? (
              "Not Available"
            ) : (
              <>{GetAddonValueNoBadge("imt23", addonDiscountPercentage)}</>
            )
          }`,
        };
      else {
        addonList = {
          "Zero Depreciation": `${
            GetAddonValueNoBadge(
              "zeroDepreciation",
              addonDiscountPercentage
            ) === "N/S" ? (
              "Not selected"
            ) : GetAddonValueNoBadge(
                "zeroDepreciation",
                addonDiscountPercentage
              ) === "N/A" ? (
              "Not Available"
            ) : (
              <>
                {GetAddonValueNoBadge(
                  "zeroDepreciation",
                  addonDiscountPercentage
                )}
              </>
            )
          }`,
          "Road Side Assistance": `${
            GetAddonValueNoBadge(
              "roadSideAssistance",
              addonDiscountPercentage
            ) === "N/S" ? (
              "Not selected"
            ) : GetAddonValueNoBadge(
                "roadSideAssistance",
                addonDiscountPercentage
              ) === "N/A" ? (
              "Not Available"
            ) : (
              <>
                {GetAddonValueNoBadge(
                  "roadSideAssistance",
                  addonDiscountPercentage
                )}
              </>
            )
          }`,
        };
      }
    }
    let odLists = {
      "Basic Own Damage(OD)": `₹ ${currencyFormater(quote?.basicPremium)}`,
      ...(quote?.motorElectricAccessoriesValue * 1 && {
        "Electrical Accessories": `₹ ${currencyFormater(
          quote?.motorElectricAccessoriesValue * 1 + ElectricalValue()
        )}`,
      }),
      ...(quote?.motorNonElectricAccessoriesValue * 1 && {
        "Non-Electrical Accessories": `₹ ${currencyFormater(
          quote?.motorNonElectricAccessoriesValue * 1 + NonElectricalValue()
        )}`,
      }),
      ...(quote?.motorLpgCngKitValue * 1 && {
        "LPG/CNG Kit": `₹ ${currencyFormater(
          quote?.motorLpgCngKitValue * 1 + BiFuelValue()
        )}`,
      }),
      ...(quote?.limitedtoOwnPremisesOD * 1 && {
        "Vehicle limited to own premises": `- ₹ ${currencyFormater(
          quote?.limitedtoOwnPremisesOD * 1
        )}`,
      }),
      // "Geographical Extension": `₹ ${currencyFormater(
      //   quote?.geogExtensionODPremium
      // )}`,
    };
    let odListsBike = {
      "Basic Own Damage(OD)": `₹ ${currencyFormater(quote?.basicPremium)}`,
      ...(quote?.motorElectricAccessoriesValue * 1 && {
        "Electrical Accessories": `₹ ${currencyFormater(
          quote?.motorElectricAccessoriesValue * 1 + ElectricalValue()
        )}`,
      }),
      ...(quote?.motorNonElectricAccessoriesValue * 1 && {
        "Non-Electrical Accessories": `₹ ${currencyFormater(
          quote?.motorNonElectricAccessoriesValue * 1 + NonElectricalValue()
        )}`,
      }),
      ...(quote?.limitedtoOwnPremisesOD * 1 && {
        "Vehicle limited to own premises": `- ₹ ${currencyFormater(
          quote?.limitedtoOwnPremisesOD * 1
        )}`,
      }),
      // "Geographical Extension": `₹ ${currencyFormater(
      //   quote?.geogExtensionODPremium
      // )}`,
    };

    if (temp_data?.journeyCategory === "GCV") {
      odLists["Trailer"] = `₹ ${currencyFormater(quote?.trailerValue)}`;
    }

    let tpList = {
      "Third Party Liability": `₹ ${currencyFormater(
        quote?.tppdPremiumAmount
      )}`,
      ...(quote?.tppdDiscount && {
        "TPPD Discounts": `- ₹ ${currencyFormater(quote?.tppdDiscount)}`,
      }),
      ...((quote?.coverUnnamedPassengerValue === "NA" ||
        quote?.coverUnnamedPassengerValue * 1) && {
        "PA For Unnamed Passenger": `${
          quote?.coverUnnamedPassengerValue === "NA"
            ? "Not Available"
            : `₹ ${currencyFormater(
                quote?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? quote?.coverUnnamedPassengerValue *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : quote?.coverUnnamedPassengerValue
              )}`
        }`,
      }),
      ...(quote?.motorAdditionalPaidDriver * 1 && {
        "Additional PA Cover To Paid Driver": `₹ ${currencyFormater(
          quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure)
            ? quote?.motorAdditionalPaidDriver *
                (TypeReturn(type) === "bike" ? 5 : 3)
            : quote?.motorAdditionalPaidDriver
        )}`,
      }),
      ...(quote?.defaultPaidDriver * 1 && {
        "Legal Liability To Paid Driver": `₹ ${currencyFormater(
          quote?.defaultPaidDriver
        )}`,
      }),
      ...(quote?.cngLpgTp * 1 && {
        "LPG/CNG Kit TP": `₹ ${currencyFormater(quote?.cngLpgTp)}`,
      }),
      ...(quote?.compulsoryPaOwnDriver * 1 &&
        addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        ) && {
          "Compulsory PA Cover For Owner Driver": `₹ ${
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? !_.isEmpty(addOnsAndOthers?.isTenure)
                ? currencyFormater(quote?.multiYearCpa)
                : currencyFormater(quote?.compulsoryPaOwnDriver)
              : 0
          }`,
        }),
      ...(quote?.limitedtoOwnPremisesTP * 1 && {
        "Vehicle limited to own premises": `- ₹ ${currencyFormater(
          quote?.limitedtoOwnPremisesTP
        )}`,
      }),
      // "Geographical Extension": `₹ ${currencyFormater(
      //   quote?.geogExtensionTPPremium
      // )}`,
    };

    let tpListGCV = {
      "Third Party Liability": `₹ ${currencyFormater(
        quote?.tppdPremiumAmount
      )}`,
      ...(quote?.tppdDiscount * 1 && {
        "TPPD Discounts": `- ₹ ${currencyFormater(quote?.tppdDiscount)}`,
      }),
      ...(quote?.motorAdditionalPaidDriver && {
        "Additional PA Cover To Paid Driver/Conductor/Cleaner": `₹ ${currencyFormater(
          quote?.motorAdditionalPaidDriver
        )}`,
      }),
      ...(quote?.defaultPaidDriver * 1 && {
        "Legal Liability To Paid Driver/Conductor/Cleaner": `₹ ${currencyFormater(
          quote?.defaultPaidDriver
        )}`,
      }),
      ...(quote?.cngLpgTp * 1 && {
        "LPG/CNG Kit TP": `₹ ${currencyFormater(quote?.cngLpgTp)}`,
      }),
      ...(quote?.compulsoryPaOwnDriver * 1 &&
        addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        ) && {
          "Compulsory PA Cover For Owner Driver": `₹ ${
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? !_.isEmpty(addOnsAndOthers?.isTenure)
                ? currencyFormater(quote?.multiYearCpa)
                : currencyFormater(quote?.compulsoryPaOwnDriver)
              : 0
          }`,
        }),
      ...(quote?.limitedtoOwnPremisesTP * 1 && {
        "Vehicle limited to own premises": `- ₹ ${currencyFormater(
          quote?.limitedtoOwnPremisesTP
        )}`,
      }),
      // "Geographical Extension": `₹ ${currencyFormater(
      //   quote?.geogExtensionTPPremium
      // )}`,
    };
    let tpListBike = {
      "Third Party Liability": `₹ ${currencyFormater(
        quote?.tppdPremiumAmount
      )}`,
      ...(quote?.tppdDiscount * 1 && {
        "TPPD Discounts": `- ₹ ${currencyFormater(quote?.tppdDiscount)}`,
      }),
      ...((quote?.coverUnnamedPassengerValue === "NA" ||
        quote?.coverUnnamedPassengerValue * 1) && {
        "PA For Unnamed Passenger": `${
          quote?.coverUnnamedPassengerValue === "NA"
            ? "Not Available"
            : `₹ ${currencyFormater(
                quote?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? quote?.coverUnnamedPassengerValue *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : quote?.coverUnnamedPassengerValue
              )}`
        }`,
      }),
      ...(quote?.defaultPaidDriver * 1 && {
        "Legal Liability To Paid Driver": `₹ ${currencyFormater(
          quote?.defaultPaidDriver
        )}`,
      }),

      ...(quote?.compulsoryPaOwnDriver * 1 &&
        addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        ) && {
          "Compulsory PA Cover For Owner Driver": `₹ ${
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? !_.isEmpty(addOnsAndOthers?.isTenure)
                ? currencyFormater(quote?.multiYearCpa)
                : currencyFormater(quote?.compulsoryPaOwnDriver)
              : 0
          }`,
        }),
      ...(quote?.limitedtoOwnPremisesTP && {
        "Vehicle limited to own premises": `- ₹ ${currencyFormater(
          quote?.limitedtoOwnPremisesTP
        )}`,
      }),
      // "Geographical Extension": `₹ ${currencyFormater(
      //   quote?.geogExtensionTPPremium
      // )}`,
    };
    let discountList = {
      "Deduction of NCB":
        currencyFormater(revisedNcb) !== 0
          ? `₹ ${currencyFormater(revisedNcb)}`
          : process.env.REACT_APP_BROKER === "SPA"
          ? "N/A"
          : 0,
      ...(quote?.antitheftDiscount * 1 && {
        "Anti-Theft": `₹ ${currencyFormater(quote?.antitheftDiscount)}`,
      }),
      ...(quote?.voluntaryExcess * 1 && {
        "Voluntary Deductible": `₹ ${currencyFormater(quote?.voluntaryExcess)}`,
      }),
      ...(quote?.aaiDiscount * 1 && {
        "Automobile Association of India": `₹ ${currencyFormater(
          quote?.aaiDiscount
        )}`,
      }),
      ...(otherDiscounts * 1 && {
        "Other Discounts": `₹ ${currencyFormater(otherDiscounts) || 0}`,
      }),
    };

    let discountListGcv = {
      "Deduction of NCB":
        currencyFormater(revisedNcb) !== 0
          ? `₹ ${currencyFormater(revisedNcb)}`
          : process.env.REACT_APP_BROKER === "SPA"
          ? "N/A"
          : 0,
      ...(otherDiscounts * 1 && {
        "Other Discounts": `₹ ${currencyFormater(otherDiscounts) || 0}`,
      }),
    };
    let discountListBike = {
      "Deduction of NCB":
        currencyFormater(revisedNcb) !== 0
          ? `₹ ${currencyFormater(revisedNcb)}`
          : process.env.REACT_APP_BROKER === "SPA"
          ? "N/A"
          : 0,
      ...(quote?.antitheftDiscount * 1 && {
        "Anti-Theft": `₹ ${currencyFormater(quote?.antitheftDiscount)}`,
      }),
      ...(quote?.voluntaryExcess * 1 && {
        "Voluntary Deductible": `₹ ${currencyFormater(quote?.voluntaryExcess)}`,
      }),
      "Automobile Association of India": `₹ ${
        currencyFormater(quote?.aaiDiscount) || 0
      }`,
      ...(otherDiscounts * 1 && {
        "Other Discounts": `₹ ${currencyFormater(otherDiscounts) || 0}`,
      }),
    };

    let data = {
      site_logo: getLogoUrl(),
      ic_logo: quote?.companyLogo,
      toll_free_number: brokerNumberFunction(),
      toll_free_number_link: `tel:${brokerNumberFunction()}`,
      support_email: brokerEmailFunction(),
      ic_name: quote?.companyName,
      product_name: quote?.productName,
      policy_tpe: `Policy Type : ${quote?.policyType}`,
      vehicle_details: `${quote?.mmvDetail?.manfName}-${
        quote?.mmvDetail?.modelName
      }-${quote?.mmvDetail?.versionName}-	${
        quote?.mmvDetail?.fuelType === "ELECTRIC" ||
        quote?.mmvDetail?.fuelType === "Electric"
          ? `${quote?.mmvDetail?.kw || " "}kW`
          : `${quote?.mmvDetail?.cubicCapacity || " "}CC`
      } `,
      fuel_type: `Fuel Type : ${quote?.mmvDetail?.fuelType || quote?.fuelType}`,
      rto_code: `RTO : ${quote?.vehicleRegistrationNo}`,
      idv: `IDV : ${
        temp_data?.tab === "tab2"
          ? "Not Applicable"
          : `₹ ${currencyFormater(quote?.idv)}`
      }`,
      new_ncb: `New NCB : ${
        temp_data?.tab === "tab2" ? "Not Applicable" : `${quote?.ncbDiscount}%`
      }`,
      registration_date: `Reg Date : ${quote?.vehicleRegisterDate}`,

      prev_policy: `Previous Policy Expiry : ${
        temp_data?.newCar
          ? "New"
          : temp_data?.breakIn
          ? temp_data?.expiry === "New" ||
            moment(subMonths(new Date(Date.now()), 9)).format("DD-MM-YYYY") ===
              temp_data?.expiry
            ? ""
            : temp_data?.expiry
          : temp_data?.expiry
      }`,

      policy_start_date: !temp_data?.breakIn
        ? `Policy Start Date : ${quote?.policyStartDate}`
        : "",
      business_type: `Business Type : ${
        quote?.businessType &&
        quote?.businessType.split(" ").map(_.capitalize).join(" ")
      }`,
      od: {
        title: "Own Damage",
        list: TypeReturn(type) === "bike" ? odListsBike : odLists,
        total: {
          "Total OD Premium (A)": `₹ ${currencyFormater(totalPremiumA)}`,
        },
      },
      tp: {
        title: "Liability",
        list:
          TypeReturn(type) === "bike"
            ? tpListBike
            : temp_data?.journeyCategory === "GCV"
            ? tpListGCV
            : tpList,
        total: {
          "Total Liability Premium (B)": `₹${currencyFormater(
            totalPremiumB - (quote?.tppdDiscount * 1 || 0)
          )}`,
        },
      },
      discount: {
        title: "Own Damage Discounts",
        list:
          TypeReturn(type) === "bike"
            ? discountListBike
            : temp_data?.journeyCategory === "GCV"
            ? discountListGcv
            : discountList,
        total: {
          "Total Discount (C)": `₹ ${currencyFormater(
            totalPremiumC - (quote?.tppdDiscount * 1 || 0)
          )}`,
        },
      },

      addon: {
        title: "Add-ons",
        list: addonList,
        total: {
          "Total Add-on Premium (D)": `₹ ${currencyFormater(totalAddon)}`,
        },
      },

      totalLoading:
        (quote?.company_alias === "bajaj_allianz" ||
          quote?.company_alias === "sbi") &&
        TypeReturn(type) === "bike" &&
        (Number(quote?.totalLoadingAmount) > 0 ||
          Number(quote?.underwritingLoadingAmount))
          ? `₹ ${currencyFormater(
              Number(quote?.totalLoadingAmount) ||
                Number(quote?.underwritingLoadingAmount)
            )}`
          : "0",

      total: {
        "Total OD Payable (A + D - C)": `₹ ${currencyFormater(
          (totalPremiumA * 1 || 0) +
            (totalAddon * 1 || 0) -
            ((totalPremiumC * 1 || 0) - (quote?.tppdDiscount * 1 || 0))
        )}`,
        "Total TP Payable (B)": `₹ ${currencyFormater(
          totalPremiumB - (quote?.tppdDiscount * 1 || 0)
        )}`,
        "Net Premium": `₹ ${currencyFormater(totalPremium)}`,
        GST: `₹ ${currencyFormater(gst)}`,
        "Gross Premium (incl. GST)": `₹ ${currencyFormater(finalPremium)}`,
      },
      btn_link: `${window.location.href}&productId=${Encrypt(quote?.policyId)}${
        selectedTab === "tab2" ? `&selectedType=${Encrypt(selectedTab)}` : ""
      }${
        shortTerm && selectedTab !== "tab2"
          ? `&selectedTerm=${Encrypt(shortTerm)}`
          : ""
      }`,
      btn_link_text: "Buy Now",
      btn_style: {
        background: Theme.QuotePopups?.color || "rgb(189, 212, 0)",
        ["font-size"]: " 12px",
        ["line-height"]: "40px",
        ["border-radius"]: "50px",
      },

      btn_link_text: "Buy Now",
      btn_style: {
        background: Theme.QuotePopups?.color || "#bdd400",
        ["font-size"]: " 12px",
        ["line-height"]: "40px",
        ["border-radius"]: "50px",
      },
      color_scheme: Theme.links?.color,
    };
    if (data) {
      console.log("dsfsdf", data);
      let stringifiedData = JSON.stringify(data).replace(/\\/g, ""); // => Fullstack

      var b = new Buffer(stringifiedData);
      var s = b.toString("base64");

      //	let downlaodPDF = `${process.env?.REACT_APP_API_BASE_URL}/premiumBreakupPdf?data=${stringifiedData}`;

      dispatch(DownloadPremiumBreakup({ data: data }));

      //	downloadFile(downlaodPDF, false, true);
      //dispatch(getPremPdf(stringifiedData));
    }
  };

  // ----------------handling email click---------------------
  const handleEmailClick = async () => {
    if (process.env?.REACT_APP_BROKER !== "OLA") {
      let addonList = {};
      if (
        TypeReturn(type) === "car" ||
        TypeReturn(type) === "bike" ||
        TypeReturn(type) === "cv"
      ) {
        totalApplicableAddonsMotor.forEach((item) => {
          if (GetAddonValueNoBadge(item, addonDiscountPercentage) === "N/A") {
          } else {
            addonList[getAddonName(item)] = `${
              GetAddonValueNoBadge(item, addonDiscountPercentage) === "N/S"
                ? "Not selected"
                : GetAddonValueNoBadge(item, addonDiscountPercentage) === "N/A"
                ? "Not Available"
                : GetAddonValueNoBadge(item, addonDiscountPercentage)
            }`;
          }
        });

        others.forEach((item) => {
          addonList[
            `${
              camelToUnderscore(item) &&
              camelToUnderscore(item)
                .replace(/_/g, " ")
                .split(" ")
                .map(_.capitalize)
                .join(" ")
            }`
          ] = ` ${
            Number(currencyFormater(othersList[item])) === 0
              ? "Included"
              : `₹ ${currencyFormater(othersList[item])}`
          } `;
        });

        console.log("addonList", addonList);
      } else {
        if (temp_data?.journeyCategory === "GCV")
          addonList = {
            "Zero Depreciation": `${
              GetAddonValueNoBadge(
                "zeroDepreciation",
                addonDiscountPercentage
              ) === "N/S" ? (
                "Not selected"
              ) : GetAddonValueNoBadge(
                  "zeroDepreciation",
                  addonDiscountPercentage
                ) === "N/A" ? (
                "Not Available"
              ) : (
                <>
                  {GetAddonValueNoBadge(
                    "zeroDepreciation",
                    addonDiscountPercentage
                  )}
                </>
              )
            }`,
            "IMT - 23": `${
              GetAddonValueNoBadge("imt23", addonDiscountPercentage) ===
              "N/S" ? (
                "Not selected"
              ) : GetAddonValueNoBadge("imt23", addonDiscountPercentage) ===
                "N/A" ? (
                "Not Available"
              ) : (
                <>{GetAddonValueNoBadge("imt23", addonDiscountPercentage)}</>
              )
            }`,
          };
        else {
          addonList = {
            "Zero Depreciation": `${
              GetAddonValueNoBadge(
                "zeroDepreciation",
                addonDiscountPercentage
              ) === "N/S" ? (
                "Not selected"
              ) : GetAddonValueNoBadge(
                  "zeroDepreciation",
                  addonDiscountPercentage
                ) === "N/A" ? (
                "Not Available"
              ) : (
                <>
                  {GetAddonValueNoBadge(
                    "zeroDepreciation",
                    addonDiscountPercentage
                  )}
                </>
              )
            }`,
            "Road Side Assistance": `${
              GetAddonValueNoBadge(
                "roadSideAssistance",
                addonDiscountPercentage
              ) === "N/S" ? (
                "Not selected"
              ) : GetAddonValueNoBadge(
                  "roadSideAssistance",
                  addonDiscountPercentage
                ) === "N/A" ? (
                "Not Available"
              ) : (
                <>
                  {GetAddonValueNoBadge(
                    "roadSideAssistance",
                    addonDiscountPercentage
                  )}
                </>
              )
            }`,
          };
        }
      }

      let odLists = {
        "Basic Own Damage(OD)": `₹ ${currencyFormater(quote?.basicPremium)}`,
        ...(quote?.motorElectricAccessoriesValue * 1 && {
          "Electrical Accessories": `₹ ${currencyFormater(
            quote?.motorElectricAccessoriesValue * 1 + ElectricalValue()
          )}`,
        }),
        ...(quote?.motorNonElectricAccessoriesValue * 1 && {
          "Non-Electrical Accessories": `₹ ${currencyFormater(
            quote?.motorNonElectricAccessoriesValue * 1 + NonElectricalValue()
          )}`,
        }),
        ...(quote?.motorLpgCngKitValue * 1 && {
          "LPG/CNG Kit": `₹ ${currencyFormater(
            quote?.motorLpgCngKitValue * 1 + BiFuelValue()
          )}`,
        }),
        ...(quote?.limitedtoOwnPremisesOD * 1 && {
          "Vehicle limited to own premises": `- ₹ ${currencyFormater(
            quote?.limitedtoOwnPremisesOD * 1
          )}`,
        }),
        // "Geographical Extension": `₹ ${currencyFormater(
        //   quote?.geogExtensionODPremium
        // )}`,
      };
      let odListsBike = {
        "Basic Own Damage(OD)": `₹ ${currencyFormater(quote?.basicPremium)}`,
        ...(quote?.motorElectricAccessoriesValue * 1 && {
          "Electrical Accessories": `₹ ${currencyFormater(
            quote?.motorElectricAccessoriesValue * 1 + ElectricalValue()
          )}`,
        }),
        ...(quote?.motorNonElectricAccessoriesValue * 1 && {
          "Non-Electrical Accessories": `₹ ${currencyFormater(
            quote?.motorNonElectricAccessoriesValue * 1 + NonElectricalValue()
          )}`,
        }),
        ...(quote?.limitedtoOwnPremisesOD * 1 && {
          "Vehicle limited to own premises": `- ₹ ${currencyFormater(
            quote?.limitedtoOwnPremisesOD * 1
          )}`,
        }),
        // "Geographical Extension": `₹ ${currencyFormater(
        //   quote?.geogExtensionODPremium
        // )}`,
      };

      if (temp_data?.journeyCategory === "GCV") {
        odLists["Trailer"] = `₹ ${currencyFormater(quote?.trailerValue)}`;
      }

      let tpList = {
        "Third Party Liability": `₹ ${currencyFormater(
          quote?.tppdPremiumAmount
        )}`,
        ...(quote?.tppdDiscount && {
          "TPPD Discounts": `- ₹ ${currencyFormater(quote?.tppdDiscount)}`,
        }),
        ...((quote?.coverUnnamedPassengerValue === "NA" ||
          quote?.coverUnnamedPassengerValue * 1) && {
          "PA For Unnamed Passenger": `${
            quote?.coverUnnamedPassengerValue === "NA"
              ? "Not Available"
              : `₹ ${currencyFormater(
                  quote?.companyAlias === "sbi" &&
                    addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) &&
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                    ? quote?.coverUnnamedPassengerValue * TypeReturn(type) ===
                      "bike"
                      ? 5
                      : 3
                    : quote?.coverUnnamedPassengerValue
                )}`
          }`,
        }),
        ...(quote?.motorAdditionalPaidDriver * 1 && {
          "Additional PA Cover To Paid Driver": `₹ ${currencyFormater(
            quote?.companyAlias === "sbi" &&
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              ) &&
              !_.isEmpty(addOnsAndOthers?.isTenure)
              ? quote?.motorAdditionalPaidDriver * TypeReturn(type) === "bike"
                ? 5
                : 3
              : quote?.motorAdditionalPaidDriver
          )}`,
        }),
        ...(quote?.defaultPaidDriver * 1 && {
          "Legal Liability To Paid Driver": `₹ ${currencyFormater(
            quote?.defaultPaidDriver
          )}`,
        }),
        ...(quote?.cngLpgTp * 1 && {
          "LPG/CNG Kit TP": `₹ ${currencyFormater(quote?.cngLpgTp)}`,
        }),
        ...(quote?.compulsoryPaOwnDriver * 1 &&
          addOnsAndOthers?.selectedCpa?.includes(
            "Compulsory Personal Accident"
          ) && {
            "Compulsory PA Cover For Owner Driver": `₹ ${
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? currencyFormater(quote?.multiYearCpa)
                  : currencyFormater(quote?.compulsoryPaOwnDriver)
                : 0
            }`,
          }),
        ...(quote?.limitedtoOwnPremisesTP * 1 && {
          "Vehicle limited to own premises": `- ₹ ${currencyFormater(
            quote?.limitedtoOwnPremisesTP
          )}`,
        }),
        // "Geographical Extension": `₹ ${currencyFormater(
        //   quote?.geogExtensionTPPremium
        // )}`,
      };

      let tpListGCV = {
        "Third Party Liability": `₹ ${currencyFormater(
          quote?.tppdPremiumAmount
        )}`,
        ...(quote?.tppdDiscount * 1 && {
          "TPPD Discounts": `- ₹ ${currencyFormater(quote?.tppdDiscount)}`,
        }),
        ...(quote?.motorAdditionalPaidDriver && {
          "Additional PA Cover To Paid Driver/Conductor/Cleaner": `₹ ${currencyFormater(
            quote?.motorAdditionalPaidDriver
          )}`,
        }),
        ...(quote?.defaultPaidDriver * 1 && {
          "Legal Liability To Paid Driver/Conductor/Cleaner": `₹ ${currencyFormater(
            quote?.defaultPaidDriver
          )}`,
        }),
        ...(quote?.cngLpgTp * 1 && {
          "LPG/CNG Kit TP": `₹ ${currencyFormater(quote?.cngLpgTp)}`,
        }),
        ...(quote?.compulsoryPaOwnDriver * 1 &&
          addOnsAndOthers?.selectedCpa?.includes(
            "Compulsory Personal Accident"
          ) && {
            "Compulsory PA Cover For Owner Driver": `₹ ${
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? currencyFormater(quote?.multiYearCpa)
                  : currencyFormater(quote?.compulsoryPaOwnDriver)
                : 0
            }`,
          }),
        ...(quote?.limitedtoOwnPremisesTP * 1 && {
          "Vehicle limited to own premises": `- ₹ ${currencyFormater(
            quote?.limitedtoOwnPremisesTP
          )}`,
        }),
        // "Geographical Extension": `₹ ${currencyFormater(
        //   quote?.geogExtensionTPPremium
        // )}`,
      };
      let tpListBike = {
        "Third Party Liability": `₹ ${currencyFormater(
          quote?.tppdPremiumAmount
        )}`,
        ...(quote?.tppdDiscount * 1 && {
          "TPPD Discounts": `- ₹ ${currencyFormater(quote?.tppdDiscount)}`,
        }),
        ...((quote?.coverUnnamedPassengerValue === "NA" ||
          quote?.coverUnnamedPassengerValue * 1) && {
          "PA For Unnamed Passenger": `${
            quote?.coverUnnamedPassengerValue === "NA"
              ? "Not Available"
              : `₹ ${currencyFormater(
                  quote?.companyAlias === "sbi" &&
                    addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) &&
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                    ? quote?.coverUnnamedPassengerValue *
                        (TypeReturn(type) === "bike" ? 5 : 3)
                    : quote?.coverUnnamedPassengerValue
                )}`
          }`,
        }),
        ...(quote?.defaultPaidDriver * 1 && {
          "Legal Liability To Paid Driver": `₹ ${currencyFormater(
            quote?.defaultPaidDriver
          )}`,
        }),

        ...(quote?.compulsoryPaOwnDriver * 1 &&
          addOnsAndOthers?.selectedCpa?.includes(
            "Compulsory Personal Accident"
          ) && {
            "Compulsory PA Cover For Owner Driver": `₹ ${
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? currencyFormater(quote?.multiYearCpa)
                  : currencyFormater(quote?.compulsoryPaOwnDriver)
                : 0
            }`,
          }),
        ...(quote?.limitedtoOwnPremisesTP && {
          "Vehicle limited to own premises": `- ₹ ${currencyFormater(
            quote?.limitedtoOwnPremisesTP
          )}`,
        }),
        // "Geographical Extension": `₹ ${currencyFormater(
        //   quote?.geogExtensionTPPremium
        // )}`,
      };
      let discountList = {
        "Deduction of NCB":
          currencyFormater(revisedNcb) !== 0
            ? `₹ ${currencyFormater(revisedNcb)}`
            : process.env.REACT_APP_BROKER === "SPA"
            ? "N/A"
            : 0,
        ...(quote?.antitheftDiscount * 1 && {
          "Anti-Theft": `₹ ${currencyFormater(quote?.antitheftDiscount)}`,
        }),
        ...(quote?.voluntaryExcess * 1 && {
          "Voluntary Deductible": `₹ ${currencyFormater(
            quote?.voluntaryExcess
          )}`,
        }),
        "Automobile Association of India": `₹ ${
          currencyFormater(quote?.aaiDiscount) || 0
        }`,
        ...(otherDiscounts * 1 && {
          "Other Discounts": `₹ ${currencyFormater(otherDiscounts) || 0}`,
        }),
      };

      let discountListGcv = {
        "Deduction of NCB":
          currencyFormater(revisedNcb) !== 0
            ? `₹ ${currencyFormater(revisedNcb)}`
            : process.env.REACT_APP_BROKER === "SPA"
            ? "N/A"
            : 0,
        ...(otherDiscounts * 1 && {
          "Other Discounts": `₹ ${currencyFormater(otherDiscounts) || 0}`,
        }),
      };
      let discountListBike = {
        "Deduction of NCB":
          currencyFormater(revisedNcb) !== 0
            ? `₹ ${currencyFormater(revisedNcb)}`
            : process.env.REACT_APP_BROKER === "SPA"
            ? "N/A"
            : 0,
        ...(quote?.antitheftDiscount * 1 && {
          "Anti-Theft": `₹ ${currencyFormater(quote?.antitheftDiscount)}`,
        }),
        ...(quote?.voluntaryExcess * 1 && {
          "Voluntary Deductible": `₹ ${currencyFormater(
            quote?.voluntaryExcess
          )}`,
        }),
        "Automobile Association of India": `₹ ${
          currencyFormater(quote?.aaiDiscount) || 0
        }`,
        ...(otherDiscounts * 1 && {
          "Other Discounts": `₹ ${currencyFormater(otherDiscounts) || 0}`,
        }),
      };

      let data = {
        site_logo: getLogoUrl(),
        ic_logo: quote?.companyLogo,
        toll_free_number: brokerNumberFunction(),
        toll_free_number_link: `tel:${brokerNumberFunction()}`,
        support_email: brokerEmailFunction(),
        ic_name: quote?.companyName,
        product_name: quote?.productName,
        policy_tpe: `Policy Type : ${quote?.policyType}`,
        vehicle_details: `${quote?.mmvDetail?.manfName}-${
          quote?.mmvDetail?.modelName
        }-${quote?.mmvDetail?.versionName}-	${
          quote?.mmvDetail?.fuelType === "ELECTRIC" ||
          quote?.mmvDetail?.fuelType === "Electric"
            ? `${quote?.mmvDetail?.kw || " "}kW`
            : `${quote?.mmvDetail?.cubicCapacity || " "}CC`
        } `,
        fuel_type: `Fuel Type : ${
          quote?.mmvDetail?.fuelType || quote?.fuelType
        }`,
        rto_code: `RTO : ${quote?.vehicleRegistrationNo}`,
        idv: `IDV : ${
          temp_data?.tab === "tab2"
            ? "Not Applicable"
            : `₹ ${currencyFormater(quote?.idv)}`
        }`,
        new_ncb: `New NCB : ${
          temp_data?.tab === "tab2"
            ? "Not Applicable"
            : `${quote?.ncbDiscount}%`
        }`,
        registration_date: `Reg Date : ${quote?.vehicleRegisterDate}`,
        prev_policy: `Previous Policy Expiry : ${temp_data?.expiry || "New"}`,
        policy_start_date: `Policy Start Date : ${quote?.policyStartDate}`,
        business_type: `Business Type : ${
          quote?.businessType &&
          quote?.businessType.split(" ").map(_.capitalize).join(" ")
        }`,
        od: {
          title: "Own Damage",
          list: TypeReturn(type) === "bike" ? odListsBike : odLists,
          total: {
            "Total OD Premium (A)": `₹ ${currencyFormater(totalPremiumA)}`,
          },
        },
        tp: {
          title: "Liability",
          list:
            TypeReturn(type) === "bike"
              ? tpListBike
              : temp_data?.journeyCategory === "GCV"
              ? tpListGCV
              : tpList,
          total: {
            "Total Liability Premium (B)": `₹${currencyFormater(
              totalPremiumB - (quote?.tppdDiscount * 1 || 0)
            )}`,
          },
        },
        discount: {
          title: "Own Damage Discounts",
          list:
            TypeReturn(type) === "bike"
              ? discountListBike
              : temp_data?.journeyCategory === "GCV"
              ? discountListGcv
              : discountList,
          total: {
            "Total Discount (C)": `₹ ${currencyFormater(
              totalPremiumC - (quote?.tppdDiscount * 1 || 0)
            )}`,
          },
        },

        addon: {
          title: "Add-ons",
          list: addonList,
          total: {
            "Total Add-on Premium (D)": `₹ ${currencyFormater(totalAddon)}`,
          },
        },
        totalLoading:
          (quote?.company_alias === "bajaj_allianz" ||
            quote?.company_alias === "sbi") &&
          TypeReturn(type) === "bike" &&
          (Number(quote?.totalLoadingAmount) > 0 ||
            Number(quote?.underwritingLoadingAmount))
            ? `₹ ${currencyFormater(
                Number(quote?.totalLoadingAmount) ||
                  Number(quote?.underwritingLoadingAmount)
              )}`
            : "0",
        total: {
          "Total OD Payable (A + D - C)": `₹ ${currencyFormater(
            (totalPremiumA * 1 || 0) +
              (totalAddon * 1 || 0) -
              ((totalPremiumC * 1 || 0) - (quote?.tppdDiscount * 1 || 0))
          )}`,
          "Total TP Payable (B)": `₹ ${currencyFormater(
            totalPremiumB - (quote?.tppdDiscount * 1 || 0)
          )}`,
          "Net Premium": `₹ ${currencyFormater(totalPremium)}`,
          GST: `₹ ${currencyFormater(gst)}`,
          "Gross Premium (incl. GST)": `₹ ${currencyFormater(finalPremium)}`,
        },
        btn_link: `${window.location.href}&productId=${Encrypt(
          quote?.policyId
        )}${
          selectedTab === "tab2" ? `&selectedType=${Encrypt(selectedTab)}` : ""
        }${
          shortTerm && selectedTab !== "tab2"
            ? `&selectedTerm=${Encrypt(shortTerm)}`
            : ""
        }`,
        btn_link_text: "Buy Now",
        btn_style: {
          background: Theme.QuotePopups?.color || "#bdd400",
          ["font-size"]: " 12px",
          ["line-height"]: "40px",
          ["border-radius"]: "50px",
        },
      };
      console.log("Pdfdata", data);
      if (data) {
        let stringifiedData = JSON.stringify(data);
        console.log(stringifiedData);
        setSendPdf(stringifiedData);
        setSendQuotes(true);
        // onClose(false)
      }
    }
  };

  let ElectricalValue = () => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecZDPremium
          ? quote?.accessoriesAddons?.electrical?.elecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecENGPremium
          ? quote?.accessoriesAddons?.electrical?.elecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.electrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecNCBPremium
          ? quote?.accessoriesAddons?.electrical?.elecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecCOCPremium
          ? quote?.accessoriesAddons?.electrical?.elecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let NonElectricalValue = () => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let BiFuelValue = () => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium * 1
          : 0);
    }
    return val;
  };

  const content2 = (
    <>
      <ContentWrap>
        <Row className="no-gutters">
          <Col
            lg={3}
            md={12}
            style={{
              display: lessthan993 ? "none" : "block",
              background: "rgb(128 128 128 / 13%)",
              color: process.env.REACT_APP_BROKER === "RB" && "#686868",
            }}
          >
            <DetailsPopHeadWrap>
              <div>
                {
                  <InfoCardKnowMore
                    lessthan767={lessthan767}
                    quote={quote}
                    totalPremium={totalPremium}
                    gst={gst}
                    finalPremium={finalPremium}
                    tab={temp_data?.tab}
                    totalPremiumC={totalPremiumC}
                    revisedNcb={revisedNcb}
                  />
                }
              </div>
              <BuyContainer>
                <CardTopRightCenter>
                  <BuyButton
                    onClick={handleClick}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <p
                        className="p-0 m-0"
                        style={
                          process.env.REACT_APP_BROKER === "UIB"
                            ? { fontSize: "14px !important" }
                            : {}
                        }
                      >
                        BUY NOW
                      </p>{" "}
                      <span style={{ fontSize: "20px", marginLeft: "5px" }}>
                        ₹ {currencyFormater(finalPremium)}
                      </span>
                    </div>
                  </BuyButton>
                </CardTopRightCenter>
              </BuyContainer>
              {!(temp_data?.tab === "tab2" && temp_data?.ownerTypeId !== 1) &&
                moreThan993 && (
                  <>
                    {!(
                      temp_data?.ownerTypeId !== 1 &&
                      quote?.applicableAddons?.length < 1
                    ) &&
                      (quote?.applicableAddons?.length > 0 ||
                        quote?.compulsoryPaOwnDriver > 0) && (
                        <AddonInfo>
                          <div className="addonHead">
                            {" "}
                            {
                              temp_data?.tab === "tab2"
                                ? "CPA"
                                : temp_data?.tab !== "tab2" &&
                                  quote?.applicableAddons?.length &&
                                  quote?.compulsoryPaOwnDriver > 0
                                ? "CPA & Add-Ons"
                                : quote?.applicableAddons?.length > 0
                                ? "Add-Ons"
                                : quote?.compulsoryPaOwnDriver > 0 && "CPA"
                              // (quote?.applicableAddons?.length > 0 &&
                              // quote?.compulsoryPaOwnDriver > 0)
                              //   ? "CPA & Add-Ons"
                              //   : (quote?.compulsoryPaOwnDriver > 0 &&
                              //     quote?.applicableAddons?.length === 0)
                              //   ? "CPA"
                              //   : (quote?.compulsoryPaOwnDriver === 0 &&
                              //     quote?.applicableAddons?.length > 1) &&
                              //     "Add-Ons"
                              // applicableAddons &&
                              // applicableAddons?.length > 0
                              //   ? temp_data?.ownerTypeId === 1 && !temp_data?.odOnly
                              //     ? quote.company_alias === "acko"
                              //       ? "CPA"
                              //       : "CPA & Add-ons"
                              //     : "Add-ons"
                              //   : "CPA"
                            }
                          </div>

                          {temp_data?.ownerTypeId === 1 && !temp_data?.odOnly && (
                            <FilterMenuBoxCheckConatiner>
                              <div className="filterMenuBoxCheck">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={"Compulsory Personal Accident"}
                                  value={"Compulsory Personal Accident"}
                                  defaultChecked={addOnsAndOthers?.selectedCpa?.includes(
                                    "Compulsory Personal Accident"
                                  )}
                                  checked={addOnsAndOthers?.selectedCpa?.includes(
                                    "Compulsory Personal Accident"
                                  )}
                                  // value={addOnsAndOthers?.selectedCpa?.includes(
                                  //   "Compulsory Personal Accident"
                                  // )}
                                  //value={cpa}
                                />

                                <CustomTooltip
                                  rider="true"
                                  //	id="cpa1__Tooltipvol"
                                  place={"right"}
                                  customClassName="mt-3  "
                                >
                                  <label
                                    data-tip="<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                    data-html={true}
                                    data-for="cpa1__Tooltipvol"
                                    className="form-check-label borderless"
                                    htmlFor={"Compulsory Personal Accident"}
                                    style={{ border: "none" }}
                                  >
                                    {"Compulsory Personal Accident"}{" "}
                                  </label>
                                </CustomTooltip>

                                <span style={{ marginLeft: "3px" }}></span>
                              </div>
                            </FilterMenuBoxCheckConatiner>
                          )}
                          {temp_data?.tab !== "tab2" && (
                            <>
                              {quote?.applicableAddons?.includes(
                                "zeroDepreciation"
                              ) && (
                                <FilterMenuBoxCheckConatiner>
                                  <div className="filterMenuBoxCheck">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"Zero Depreciation"}
                                      defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                        "zeroDepreciation"
                                      )}
                                      checked={addOnsAndOthers?.selectedAddons?.includes(
                                        "zeroDepreciation"
                                      )}
                                      value={addOnsAndOthers?.selectedAddons?.includes(
                                        "zeroDepreciation"
                                      )}
                                    />

                                    <CustomTooltip
                                      rider="true"
                                      //	id="zero__Tooltipvol"
                                      place={"top"}
                                      customClassName="mt-3  "
                                    >
                                      <label
                                        data-tip="<h3 >Zero Depreciation</h3> <div>Also called Nil Depreciation cover or Bumper-to-Bumper cover. An add-on which gives you complete cover on any body parts of the car excluding tyres and batteries. Insurer will pay entire cost of body parts, ignoring the year-on-year depreciation in value of these parts.</div>"
                                        data-html={true}
                                        data-for="zero__Tooltipvol"
                                        className="form-check-label"
                                        htmlFor={"Zero Depreciation"}
                                        style={{ border: "none" }}
                                      >
                                        {"Zero Depreciation"}{" "}
                                      </label>
                                    </CustomTooltip>
                                    <span style={{ marginLeft: "3px" }}></span>
                                  </div>
                                </FilterMenuBoxCheckConatiner>
                              )}

                              {quote?.applicableAddons?.includes(
                                "roadSideAssistance"
                              ) && (
                                <FilterMenuBoxCheckConatiner>
                                  <div className="filterMenuBoxCheck">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"Road Side Assistance"}
                                      defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                        "roadSideAssistance"
                                      )}
                                      checked={addOnsAndOthers?.selectedAddons?.includes(
                                        "roadSideAssistance"
                                      )}
                                      value={addOnsAndOthers?.selectedAddons?.includes(
                                        "roadSideAssistance"
                                      )}
                                    />

                                    <CustomTooltip
                                      rider="true"
                                      //	id="rsa__Tooltipvol"
                                      place={"top"}
                                      customClassName="mt-3  "
                                    >
                                      <label
                                        data-tip="<h3 >Road Side Assistance</h3> <div>Roadside Assistance Coverage means a professional technician comes to your rescue when your car breaks down in the middle of the journey leaving you stranded.</div>"
                                        data-html={true}
                                        data-for="rsa__Tooltipvol"
                                        className="form-check-label"
                                        htmlFor={"Road Side Assistance"}
                                        style={{ border: "none" }}
                                      >
                                        {"Road Side Assistance"}{" "}
                                      </label>
                                    </CustomTooltip>
                                  </div>
                                </FilterMenuBoxCheckConatiner>
                              )}
                              {TypeReturn(type) === "cv" &&
                                quote?.applicableAddons?.includes("imt23") && (
                                  <>
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"IMT - 23"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "imt23"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "imt23"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "imt23"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //	id="imtTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >IMT - 23</h3> <div>COVER FOR LAMPS TYRES / TUBES MUDGUARDS BONNET
/SIDE PARTS BUMPERS HEADLIGHTS AND PAINTWORK OF
DAMAGED PORTION ONLY .</div>"
                                            data-html={true}
                                            data-for="imtTooltipvol"
                                            className="form-check-label"
                                            htmlFor={"IMT - 23"}
                                            style={{ border: "none" }}
                                          >
                                            {"IMT - 23"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  </>
                                )}

                              {temp_data?.journeyCategory !== "GCV" &&
                                quote?.applicableAddons?.includes(
                                  "consumables"
                                ) && (
                                  <FilterMenuBoxCheckConatiner>
                                    <div className="filterMenuBoxCheck">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={"Consumable"}
                                        defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                          "consumables"
                                        )}
                                        checked={addOnsAndOthers?.selectedAddons?.includes(
                                          "consumables"
                                        )}
                                        value={addOnsAndOthers?.selectedAddons?.includes(
                                          "consumables"
                                        )}
                                      />

                                      <CustomTooltip
                                        rider="true"
                                        //		id="consumableTooltipvol"
                                        place={"top"}
                                        customClassName="mt-3  "
                                      >
                                        <label
                                          data-tip="<h3 >Consumable</h3> <div>Consumable items of a car include nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts</div>"
                                          data-html={true}
                                          data-for="consumableTooltipvol"
                                          className="form-check-label"
                                          htmlFor={"Consumable"}
                                          style={{ border: "none" }}
                                        >
                                          {"Consumable"}{" "}
                                        </label>
                                      </CustomTooltip>
                                      <span
                                        style={{ marginLeft: "3px" }}
                                      ></span>
                                    </div>
                                  </FilterMenuBoxCheckConatiner>
                                )}
                              {(TypeReturn(type) === "car" ||
                                TypeReturn(type) === "bike") && (
                                <>
                                  {quote?.applicableAddons?.includes(
                                    "keyReplace"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Key Replacement"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "keyReplace"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "keyReplace"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "keyReplace"
                                          )}
                                        />
                                        <CustomTooltip
                                          rider="true"
                                          //		id="keyTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          {/* <img
								 data-tip="<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
								 data-html={true}
								 data-for="keyTooltipvol"
								 src={tooltip}
								 alt="tooltip"
								 className="toolTipRiderChild"
							 /> */}
                                          <label
                                            className="form-check-label"
                                            htmlFor={"Key Replacement"}
                                            data-tip="<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
                                            data-html={true}
                                            data-for="keyTooltipvol"
                                            alt="tooltip"
                                            style={{ border: "none" }}
                                          >
                                            {"Key Replacement"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <div
                                          style={{ marginLeft: "3px" }}
                                        ></div>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}

                                  {quote?.applicableAddons?.includes(
                                    "engineProtector"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Engine Protector"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "engineProtector"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "engineProtector"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "engineProtector"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //	id="engTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            className="form-check-label"
                                            htmlFor={"Engine Protector"}
                                            data-tip="<h3 >Engine Protector</h3> <div>Engine protection cover in car insurance provides coverage towards damages or losses to the insured vehicle’s engine. The add-on compensates you for the replacement or repair of your car’s engine or parts.</div>"
                                            data-html={true}
                                            data-for="engTooltipvol"
                                            style={{ border: "none" }}
                                          >
                                            {"Engine Protector"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                  {quote?.applicableAddons?.includes(
                                    "ncbProtection"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"NCB Protection"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "ncbProtection"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "ncbProtection"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "ncbProtection"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //			id="ncbProtTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            className="form-check-label"
                                            data-tip="<h3 >NCB Protection</h3> <div>The NCB Protector protects Your Earned No claim Bonus, in the event of an Own Damage claim made for Partial Loss including claims for Windshield glass, Total Loss, and Theft of vehicle/ accessories. The No Claim Bonus will not get impacted for the first 2 claims preferred during the course of this policy per year.</div>"
                                            data-html={true}
                                            data-for="ncbProtTooltipvol"
                                            htmlFor={"NCB Protection"}
                                            style={{ border: "none" }}
                                          >
                                            {"NCB Protection"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}

                                  {quote?.applicableAddons?.includes(
                                    "tyreSecure"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Tyre Secure"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "tyreSecure"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "tyreSecure"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "tyreSecure"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //		id="tyreTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >Tyre Secure</h3> <div>The cost of damage to the insured vehicle due to an accident, riot, vandalism, and natural and man-made calamities. The repair cost of the tyre and tube without being damaged due to a claimable event is not covered by any basic insurance policy.</div>"
                                            data-html={true}
                                            data-for="tyreTooltipvol"
                                            className="form-check-label"
                                            htmlFor={"Tyre Secure"}
                                            style={{ border: "none" }}
                                          >
                                            {"Tyre Secure"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                  {quote?.applicableAddons?.includes(
                                    "returnToInvoice"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Return To Invoice"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "returnToInvoice"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "returnToInvoice"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "returnToInvoice"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //		id="roiTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >Return To Invoice</h3> <div>Return to Invoice is an add-on option which covers the gap between the insured declared value and the invoice value of your car along with the registration and other applicable taxes.</div>"
                                            data-html={true}
                                            data-for="roiTooltipvol"
                                            className="form-check-label"
                                            htmlFor={"Return To Invoice"}
                                            style={{ border: "none" }}
                                          >
                                            {"Return To Invoice"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                  {quote?.applicableAddons?.includes(
                                    "lopb"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Loss of Personal Belongings"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "lopb"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "lopb"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "lopb"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //		id="lopb__Tooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >Loss of Personal Belongings</h3> <div>With this cover in place, your insurer will cover losses arising due to damage or theft of your personal Belongings from the insured car as per the terms and conditions of the policy.</div>"
                                            data-html={true}
                                            data-for="lopb__Tooltipvol"
                                            className="form-check-label"
                                            htmlFor={
                                              "Loss of Personal Belongings"
                                            }
                                            style={{ border: "none" }}
                                          >
                                            {"Loss of Personal Belongings"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </AddonInfo>
                      )}
                  </>
                )}
            </DetailsPopHeadWrap>
          </Col>
          <Col lg={9} md={12}>
            <DetailPopTabs
              style={{ width: "100%", marginTop: "0px", marginLeft: "20px" }}
            >
              <ul
                className="nav nav-tabs"
                style={{ display: lessthan993 ? "none" : "flex" }}
              >
                <li
                  className={key === "premiumBreakupPop" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    data-toggle="tab"
                    aria-expanded="true"
                    onClick={() => setKey("premiumBreakupPop")}
                  >
                    Premium Breakup
                  </a>
                </li>

                <li
                  style={{
                    borderRight: "1px solid black",
                    color: "blue",
                    cursor: "pointer",
                    visibility: !quote?.garageCount ? "hidden" : "visible",
                  }}
                  className={key === "cashlessGaragesPop" ? "active" : ""}
                  // style={{
                  // 	borderRight: "1px solid black",
                  // 	color: "blue",
                  // 	pointerEvents: allQuoteloading ? "none" : "",
                  // }}
                >
                  <a
                    data-toggle="tab"
                    aria-expanded="false"
                    onClick={() =>
                      (process.env?.REACT_APP_BROKER !== "ABIBL" ||
                        process.env?.REACT_APP_API_BASE_URL ===
                          "https://api-carbike.fynity.in/api") &&
                      setKey("cashlessGaragesPop")
                    }
                  >
                    Cashless Garages
                  </a>
                </li>
                <PdfMail>
                  <a
                    role="button"
                    onClick={handlePremPdfClick}
                    //onclick="pdfBreakup();"

                    id="export_pdf_breakup"
                    style={{ marginLeft: "35px" }}
                  >
                    <a id="export_pdf_breakup" />
                    <i
                      className="fa fa-file-pdf-o"
                      style={{ fontSize: "20px" }}
                      aria-hidden="true"
                    ></i>
                    &nbsp; PDF&nbsp;&nbsp;
                  </a>
                  {/* <a onClick={() => {}}>
												<i
													className="fa fa-envelope"
													//	style="color:#1f0054!important;"
													aria-hidden="true"
												></i>
												&nbsp; EMAIL
											</a> */}
                </PdfMail>

                <PdfMail>
                  <button
                    // role="button"
                    style={{
                      all: "unset",
                      cursor: "pointer",
                    }}
                    onClick={handleEmailClick}
                    //onclick="pdfBreakup();"
                    id="export_pdf_breakup"
                  >
                    <i
                      className="fa fa-share-alt"
                      //	style="color:#1f0054!important;"
                      style={{ fontSize: "15px" }}
                      aria-hidden="true"
                    ></i>
                    &nbsp; SHARE&nbsp;&nbsp;
                  </button>
                  {/* <a onClick={() => {}}>
												<i
													className="fa fa-envelope"
													//	style="color:#1f0054!important;"
													aria-hidden="true"
												></i>
												&nbsp; EMAIL
											</a> */}
                </PdfMail>
              </ul>

              <TabContet>
                <div
                  class={
                    key === "premiumBreakupPop"
                      ? "showDiv premBreakup"
                      : "hideDiv"
                  }
                >
                  <MobilePremiumBreakup
                    quote={quote}
                    handlePremPdfClick={handlePremPdfClick}
                    handleEmailClick={handleEmailClick}
                    type={TypeReturn(type)}
                    totalPremiumA={totalPremiumA}
                    totalPremiumB={totalPremiumB}
                    revisedNcb={revisedNcb}
                    totalPremiumC={totalPremiumC}
                    totalAddon={totalAddon}
                    GetAddonValue={GetAddonValue}
                    getAddonName={getAddonName}
                    totalApplicableAddonsMotor={totalApplicableAddonsMotor}
                    addonDiscountPercentage={addonDiscountPercentage}
                    others={others}
                    othersList={othersList}
                    totalPremium={totalPremium}
                    gst={gst}
                    finalPremium={finalPremium}
                    handleClick={handleClick}
                    otherDiscounts={otherDiscounts}
                    show={show}
                    lessthan767={lessthan767}
                    ElectricalValue={ElectricalValue}
                    NonElectricalValue={NonElectricalValue}
                    BiFuelValue={BiFuelValue}
                  />
                  <>
                    <Body style={{ ...(lessthan993 && { display: "none" }) }}>
                      <div>
                        <Row id={"capture-ss"}>
                          <Col md={6} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table borderless>
                                  <tbody className="t-body-tag">
                                    <tr>
                                      <td
                                        rowspan="6"
                                        style={{
                                          verticalAlign: "middle",
                                          width: "20%",
                                          borderRight: "1px solid #ccc",
                                        }}
                                      >
                                        <img
                                          src={
                                            quote?.companyLogo
                                              ? quote?.companyLogo
                                              : ""
                                          }
                                          alt=""
                                          className="PremIcon"
                                          id="premium_breakup_ic_img"
                                          style={{
                                            height: "auto",
                                            width: "100%",
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <span style={{ fontWeight: "800" }}>
                                          {quote?.companyName}
                                        </span>{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{quote?.productName} </td>
                                    </tr>
                                    <tr>
                                      <td>Policy Type : {quote?.policyType}</td>
                                    </tr>

                                    <tr>
                                      <td>
                                        {`${quote?.mmvDetail?.manfName || ""}  
																	${quote?.mmvDetail?.modelName || ""}-	${quote?.mmvDetail?.versionName || ""}
																	${
                                    quote?.mmvDetail?.fuelType === "ELECTRIC" ||
                                    quote?.mmvDetail?.fuelType === "Electric"
                                      ? `${quote?.mmvDetail?.kw || " "}${
                                          TypeReturn(type) === "bike"
                                            ? ""
                                            : "kW"
                                        }`
                                      : `${
                                          quote?.mmvDetail?.cubicCapacity || " "
                                        }${
                                          TypeReturn(type) === "bike"
                                            ? ""
                                            : "CC"
                                        }`
                                  }`}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Fuel Type : {quote?.fuelType}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        RTO : {quote?.vehicleRegistrationNo} -{" "}
                                        {temp_data?.rtoCity ||
                                          prefill?.corporateVehiclesQuoteRequest
                                            ?.rtoCity}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table bordered>
                                  <tbody className="t-body-tag">
                                    <tr>
                                      <td>
                                        IDV :{" "}
                                        {temp_data?.tab === "tab2" ? (
                                          <Badge
                                            variant="secondary"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            Not Applicable
                                          </Badge>
                                        ) : (
                                          ` ₹ ${currencyFormater(quote?.idv)}`
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        New NCB :
                                        {temp_data?.tab === "tab2" ? (
                                          <Badge
                                            variant="secondary"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            Not Applicable
                                          </Badge>
                                        ) : (
                                          ` ${quote?.ncbDiscount}%`
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Reg Date : {quote?.vehicleRegisterDate}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Previous Policy Expiry :{" "}
                                        {temp_data?.newCar
                                          ? "New"
                                          : temp_data?.breakIn
                                          ? temp_data?.expiry === "New" ||
                                            moment(
                                              subMonths(new Date(Date.now()), 9)
                                            ).format("DD-MM-YYYY") ===
                                              temp_data?.expiry
                                            ? ""
                                            : temp_data?.expiry
                                          : temp_data?.expiry}
                                      </td>
                                    </tr>
                                    {!temp_data?.breakIn &&
                                      quote?.policyStartDate && (
                                        <tr>
                                          <td>
                                            Policy Start Date :{" "}
                                            {quote?.policyStartDate}
                                          </td>
                                        </tr>
                                      )}
                                    <tr>
                                      <td>
                                        Business Type :{" "}
                                        {quote?.businessType &&
                                          quote?.businessType
                                            .split(" ")
                                            .map(_.capitalize)
                                            .join(" ")}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                          <Col md={6} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          color:
                                            process.env?.REACT_APP_BROKER ===
                                              "RB" && "rgb(25, 102, 255)",
                                        }}
                                      >
                                        Own Damage
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="t-body-tag">
                                    <tr>
                                      <td>
                                        <DetailRow>
                                          <span> Basic Own Damage(OD)</span>
                                          <span className="amount">
                                            ₹{" "}
                                            {currencyFormater(
                                              quote?.basicPremium
                                            )}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    <>
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span> Electrical Accessories</span>
                                            <span className="amount">
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.company_alias ===
                                                  "hdfc_ergo"
                                                  ? quote?.motorElectricAccessoriesValue +
                                                      (ElectricalValue() || 0)
                                                  : quote?.motorElectricAccessoriesValue
                                              )}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>
                                              Non-Electrical Accessories
                                            </span>
                                            <span className="amount">
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.company_alias ===
                                                  "hdfc_ergo"
                                                  ? quote?.motorNonElectricAccessoriesValue +
                                                      (NonElectricalValue() ||
                                                        0)
                                                  : quote?.motorNonElectricAccessoriesValue
                                              )}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                      {quote?.limitedtoOwnPremisesOD ? (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span>
                                                {" "}
                                                Vehicle limited to own premises
                                              </span>
                                              <span>
                                                ₹{" "}
                                                {currencyFormater(
                                                  quote?.limitedtoOwnPremisesOD
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      ) : (
                                        <noscript />
                                      )}
                                      {quote?.geogExtensionODPremium ? (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span>
                                                {" "}
                                                Geographical Extension
                                              </span>
                                              <span>
                                                ₹{" "}
                                                {currencyFormater(
                                                  quote?.geogExtensionODPremium
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      ) : (
                                        <noscript />
                                      )}
                                      {TypeReturn(type) !== "bike" && (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span> LPG/CNG Kit</span>
                                              <span className="amount">
                                                ₹{" "}
                                                {currencyFormater(
                                                  quote?.company_alias ===
                                                    "hdfc_ergo"
                                                    ? quote?.motorLpgCngKitValue +
                                                        (BiFuelValue() || 0)
                                                    : quote?.motorLpgCngKitValue
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                    <tr>
                                      <td>
                                        {temp_data?.journeyCategory ===
                                        "GCV" ? (
                                          <DetailRow>
                                            <span> Trailer</span>
                                            <span className="amount">
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.trailerValue
                                              )}
                                            </span>
                                          </DetailRow>
                                        ) : (
                                          <DetailRow>&nbsp;</DetailRow>
                                        )}{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>&nbsp;</DetailRow>
                                      </td>
                                    </tr>
                                    {TypeReturn(type) !== "bike" && (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>&nbsp;</DetailRow>
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td>
                                        {" "}
                                        {/* <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        {Number(quote?.totalLoadingAmount) > 0
                                          ? "OD Premium"
                                          : "Total OD Premium (A)"}
                                      </span>
                                      <span>
                                        ₹ {currencyFormater(totalPremiumA)}
                                      </span>
                                    </DetailRow> */}
                                      </td>
                                    </tr>
                                    {(quote?.company_alias ===
                                      "bajaj_allianz" ||
                                      quote?.company_alias === "sbi") &&
                                      false &&
                                      TypeReturn(type) === "cv" &&
                                      (Number(quote?.totalLoadingAmount) > 0 ||
                                        Number(
                                          quote?.underwritingLoadingAmount
                                        )) && (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span className="boldText">
                                                {" "}
                                                Total Loading Amount
                                              </span>
                                              <span>
                                                ₹{" "}
                                                {currencyFormater(
                                                  Number(
                                                    quote?.totalLoadingAmount
                                                  ) ||
                                                    Number(
                                                      quote?.underwritingLoadingAmount
                                                    )
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      )}
                                  </tbody>
                                </Table>
                              </div>
                              <DetailRow
                                style={{
                                  background: "#80808024",
                                  padding: "8px 25px",
                                }}
                              >
                                <span
                                  className="boldText"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  {Number(quote?.totalLoadingAmount) > 0
                                    ? "OD Premium"
                                    : "Total OD Premium (A)"}
                                </span>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹ {currencyFormater(totalPremiumA)}
                                </span>
                              </DetailRow>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          color:
                                            process.env?.REACT_APP_BROKER ===
                                              "RB" && "rgb(25, 102, 255)",
                                        }}
                                      >
                                        Liability
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="t-body-tag">
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span> Third Party Liability</span>
                                          <span className="amount">
                                            ₹{" "}
                                            {currencyFormater(
                                              quote?.tppdPremiumAmount
                                            )}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span> TPPD Discounts</span>
                                          <span>
                                            - ₹{" "}
                                            {currencyFormater(
                                              quote?.tppdDiscount
                                            ) || 0}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    {temp_data?.journeyCategory !== "GCV" && (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>
                                              {" "}
                                              PA For Unnamed Passenger
                                            </span>
                                            <span className="amount">
                                              {quote?.coverUnnamedPassengerValue ===
                                                "NA" ||
                                              !(
                                                quote?.coverUnnamedPassengerValue *
                                                1
                                              )
                                                ? // <Badge
                                                  //   variant="danger"
                                                  //   style={{ cursor: "pointer" }}
                                                  // >
                                                  //   Not Available
                                                  // </Badge>
                                                  "N/A"
                                                : `	₹ ${currencyFormater(
                                                    quote?.companyAlias ===
                                                      "sbi" &&
                                                      addOnsAndOthers?.selectedCpa?.includes(
                                                        "Compulsory Personal Accident"
                                                      ) &&
                                                      !_.isEmpty(
                                                        addOnsAndOthers?.isTenure
                                                      )
                                                      ? quote?.coverUnnamedPassengerValue *
                                                          (TypeReturn(type) ===
                                                          "bike"
                                                            ? 5
                                                            : 3)
                                                      : quote?.coverUnnamedPassengerValue
                                                  )}`}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    )}
                                    {TypeReturn(type) !== "bike" && (
                                      <>
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow noWrap={true}>
                                              <span>
                                                {" "}
                                                {temp_data?.journeyCategory ===
                                                "GCV"
                                                  ? "Additional PA Cover To Paid Driver/Conductor/Cleaner"
                                                  : "Additional PA Cover To Paid Driver"}
                                              </span>
                                              <span className="amount">
                                                ₹{" "}
                                                {currencyFormater(
                                                  quote?.companyAlias ===
                                                    "sbi" &&
                                                    addOnsAndOthers?.selectedCpa?.includes(
                                                      "Compulsory Personal Accident"
                                                    ) &&
                                                    !_.isEmpty(
                                                      addOnsAndOthers?.isTenure
                                                    )
                                                    ? quote?.motorAdditionalPaidDriver *
                                                        (TypeReturn(type) ===
                                                        "bike"
                                                          ? 5
                                                          : 3)
                                                    : quote?.motorAdditionalPaidDriver
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span>
                                            {" "}
                                            {temp_data?.journeyCategory ===
                                            "GCV"
                                              ? "Legal Liability To Paid Driver/Conductor/Cleaner"
                                              : "Legal Liability To Paid Driver"}{" "}
                                          </span>
                                          <span className="amount">
                                            {quote?.defaultPaidDriver * 1
                                              ? `₹ ${currencyFormater(
                                                  quote?.defaultPaidDriver
                                                )}`
                                              : "N/A"}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    {TypeReturn(type) !== "bike" && (
                                      <>
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span>LPG/CNG Kit TP</span>
                                              <span className="amount">
                                                ₹{" "}
                                                {currencyFormater(
                                                  quote?.cngLpgTp
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span>
                                            {" "}
                                            Compulsory PA Cover For Owner Driver
                                          </span>
                                          <span className="amount">
                                            ₹{" "}
                                            {addOnsAndOthers?.selectedCpa?.includes(
                                              "Compulsory Personal Accident"
                                            )
                                              ? currencyFormater(
                                                  quote?.compulsoryPaOwnDriver
                                                )
                                              : 0}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    {quote?.limitedtoOwnPremisesTP ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>
                                              {" "}
                                              Vehicle limited to own premises
                                            </span>
                                            <span>
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.limitedtoOwnPremisesTP
                                              )}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                    {quote?.geogExtensionTPPremium ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span> Geographical Extension</span>
                                            <span>
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.geogExtensionTPPremium
                                              )}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                    <tr>
                                      <td>
                                        {" "}
                                        {/* <DetailRow>
                                      <span className="boldText">
                                        Total Liability Premium (B)
                                      </span>
                                      <span className="amount">
                                        ₹{" "}
                                        {currencyFormater(
                                          totalPremiumB -
                                            (quote?.tppdDiscount * 1 || 0)
                                        )}
                                      </span>
                                    </DetailRow> */}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                              <DetailRow
                                style={{
                                  background: "#80808024",
                                  padding: "8px 25px",
                                }}
                              >
                                <span
                                  className="boldText"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total Liability Premium (B)
                                </span>
                                <span
                                  className="amount"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹{" "}
                                  {currencyFormater(
                                    totalPremiumB -
                                      (quote?.tppdDiscount * 1 || 0)
                                  )}
                                </span>
                              </DetailRow>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                          <Col md={6} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          color:
                                            process.env?.REACT_APP_BROKER ===
                                              "RB" && "rgb(25, 102, 255)",
                                        }}
                                      >
                                        Own Damage Discounts
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="t-body-tag">
                                    <tr>
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span> Deduction of NCB</span>
                                          <span>
                                            ₹ {currencyFormater(revisedNcb)}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                    {temp_data?.journeyCategory !== "GCV" && (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span> Voluntary Deductible</span>
                                            <span>
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.voluntaryExcess
                                              )}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    )}
                                    {temp_data?.journeyCategory !== "GCV" && (
                                      <>
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span> Anti-Theft</span>
                                              <span>
                                                ₹{" "}
                                                {currencyFormater(
                                                  quote?.antitheftDiscount
                                                )}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                        {quote?.premisisDiscount ? (
                                          <tr>
                                            <td>
                                              {" "}
                                              <DetailRow>
                                                <span>
                                                  {" "}
                                                  Vehicle limited to own
                                                  premises
                                                </span>
                                                <span>
                                                  ₹{" "}
                                                  {currencyFormater(
                                                    quote?.premisisDiscount
                                                  )}
                                                </span>
                                              </DetailRow>
                                            </td>
                                          </tr>
                                        ) : (
                                          <noscript />
                                        )}
                                        {quote?.aaiDiscount ? (
                                          <tr>
                                            <td>
                                              {" "}
                                              <DetailRow>
                                                <span>
                                                  {" "}
                                                  Automobile Association of
                                                  India
                                                </span>
                                                <span>
                                                  ₹{" "}
                                                  {currencyFormater(
                                                    quote?.aaiDiscount
                                                  ) || 0}
                                                </span>
                                              </DetailRow>
                                            </td>
                                          </tr>
                                        ) : (
                                          <noscript />
                                        )}
                                      </>
                                    )}

                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span> Other Discounts</span>
                                          <span>
                                            ₹{" "}
                                            {currencyFormater(otherDiscounts) ||
                                              0}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        {" "}
                                        {/* <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        Total Discount (C)
                                      </span>
                                      <span>
                                        ₹{" "}
                                        {currencyFormater(
                                          totalPremiumC -
                                            (quote?.tppdDiscount * 1 || 0)
                                        )}
                                      </span>
                                    </DetailRow> */}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                              <DetailRow
                                style={{
                                  background: "#80808024",
                                  padding: "8px 25px",
                                }}
                              >
                                <span
                                  className="boldText"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  Total Discount (C)
                                </span>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹{" "}
                                  {currencyFormater(
                                    totalPremiumC -
                                      (quote?.tppdDiscount * 1 || 0)
                                  )}
                                </span>
                              </DetailRow>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          textAlign: "center",
                                          color:
                                            process.env?.REACT_APP_BROKER ===
                                              "RB" && "rgb(25, 102, 255)",
                                        }}
                                      >
                                        Add-ons
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="t-body-tag">
                                    <>
                                      {totalApplicableAddonsMotor.map(
                                        (item, index) => (
                                          <>
                                            {GetAddonValue(
                                              item,
                                              addonDiscountPercentage
                                            ) !== "N/A" && (
                                              <tr>
                                                <td>
                                                  <DetailRow>
                                                    <span>
                                                      {" "}
                                                      {getAddonName(
                                                        item,
                                                        addonDiscountPercentage
                                                      )}
                                                    </span>

                                                    <span>
                                                      {GetAddonValue(
                                                        item,
                                                        addonDiscountPercentage
                                                      ) === "N/S" ? (
                                                        <Badge
                                                          variant="secondary"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          Not selected
                                                        </Badge>
                                                      ) : GetAddonValue(
                                                          item,
                                                          addonDiscountPercentage
                                                        ) === "N/A" ? (
                                                        <Badge
                                                          variant="danger"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          Not Available
                                                        </Badge>
                                                      ) : (
                                                        <>
                                                          {GetAddonValue(
                                                            item,
                                                            addonDiscountPercentage
                                                          )}
                                                        </>
                                                      )}
                                                    </span>
                                                  </DetailRow>
                                                </td>{" "}
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                      {others.map((item, index) => (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span>
                                                {camelToUnderscore(item) &&
                                                  camelToUnderscore(item)
                                                    .replace(/_/g, " ")
                                                    .split(" ")
                                                    .map(_.capitalize)
                                                    .join(" ")}
                                              </span>
                                              <span>
                                                {" "}
                                                {Number(othersList[item]) ===
                                                0 ? (
                                                  <Badge
                                                    variant="primary"
                                                    style={{
                                                      position: "relative",
                                                      bottom: "2px",
                                                    }}
                                                  >
                                                    Included
                                                  </Badge>
                                                ) : (
                                                  `₹ ${currencyFormater(
                                                    othersList[item]
                                                  )}`
                                                )}{" "}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      ))}
                                    </>

                                    <tr>
                                      <td>
                                        {" "}
                                        {/* <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        Total Add-on Premium (D)
                                      </span>
                                      <span>
                                        ₹ {currencyFormater(totalAddon)}
                                      </span>
                                    </DetailRow> */}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                              <DetailRow
                                style={{
                                  background: "#80808024",
                                  padding: "8px 25px",
                                  position: "absolute",
                                  bottom: "0",
                                  width: "93%",
                                }}
                              >
                                <span
                                  className="boldText"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  Total Add-on Premium (D)
                                </span>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹ {currencyFormater(totalAddon)}
                                </span>
                              </DetailRow>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                          <Col md={12} sm={12}>
                            <div className="div-body-tag">
                              <div style={{ padding: "25px 25px 0px 25px" }}>
                                <Table bordered>
                                  <tbody className="t-body-tag">
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span className="boldText">
                                            {" "}
                                            {"Total OD Payable (A + D - C)"}
                                          </span>
                                          <span className="boldText">
                                            ₹{" "}
                                            {currencyFormater(
                                              (totalPremiumA * 1 || 0) +
                                                (quote?.totalLoadingAmount *
                                                  1 || 0) +
                                                (quote?.underwritingLoadingAmount *
                                                  1 || 0) +
                                                (totalAddon * 1 || 0) -
                                                ((totalPremiumC * 1 || 0) -
                                                  (quote?.tppdDiscount * 1 ||
                                                    0))
                                            )}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span className="boldText">
                                            {" "}
                                            {"Total TP Payable (B)"}
                                          </span>
                                          <span className="boldText">
                                            ₹{" "}
                                            {currencyFormater(
                                              totalPremiumB -
                                                (quote?.tppdDiscount * 1 || 0)
                                            )}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span className="boldText">
                                            {" "}
                                            {uwLoading > 0
                                              ? "Net Premium"
                                              : "Net Premium "}
                                          </span>
                                          <span className="boldText">
                                            ₹ {currencyFormater(totalPremium)}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span className="boldText">
                                            {" "}
                                            GST{" "}
                                          </span>
                                          <span className="boldText">
                                            ₹ {currencyFormater(gst)}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span className="boldText">
                                            Gross Premium (incl. GST)
                                          </span>
                                          <span className="boldText">
                                            ₹ {currencyFormater(finalPremium)}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Body>
                  </>
                </div>

                <div
                  class={key === "premiumBreakupPop" ? "hideDiv" : "showDiv"}
                >
                  <Body>
                    <Row>
                      <Col md={12} sm={12}>
                        <div
                          className="w-100"
                          style={{ paddingRight: !lessthan993 && "20px" }}
                        >
                          <h3
                            className="cashlessHeading"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            {lessthan993
                              ? "Cashless Garages Near You"
                              : "Cashless Garages"}
                          </h3>
                          <RowTag
                            className="row mt-4"
                            style={{ marginLeft: "0px", marginRight: "0px" }}
                          >
                            <div className="col-4 col-lg-3 m-0 p-0"></div>
                            <div className="col-12 col-lg-12 m-0 p-0 d-flex">
                              <input
                                type="text"
                                name="pincode"
                                ref={register}
                                className="form-control search_input"
                                placeholder="Search Garages by Pincode/City"
                                style={{
                                  padding: "29px",
                                  borderRadius: lessthan993 ? "8px" : "8px",
                                }}
                                defaultValue={
                                  temp_data?.rtoCity ||
                                  prefill?.corporateVehiclesQuoteRequest
                                    ?.rtoCity
                                }
                              />
                              <i
                                className="fa fa-search"
                                style={{
                                  position: "absolute",
                                  left: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                  marginTop: "18px",
                                  fontSize: "22px",
                                  marginLeft: "-34px",
                                }}
                              ></i>
                            </div>
                            {/* {showInput && (
															<div style={{ width: "100%", padding: "20px" }}>
																<input
																	className="my-form"
																	onChange={handleSearch}
																	//   options={list}
																	//   Controller={Controller}
																	//   control={control}
																	ref={register}
																	name="cityName"
																	placeholder="Enter City"
																	autoFocus
																/>
															</div>
														)} */}
                          </RowTag>
                          {/* <Textbox
														lg
														type="text"
														id="pincode"
														fieldName="Pincode / City"
														name="pincode"
														placeholder=" "
														register={register}
														//onChange={handleChange}
														// onKeyDown={numOnly}
														error={errors?.lastName}
														// maxLength={6}
													/> */}
                          {!!errors.lastName && (
                            <Error style={{ marginTop: "-20px" }}>
                              {errors.lastName.message}
                            </Error>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Table
                      striped
                      // bordered
                      hover
                      style={
                        {
                          //marginTop: "33px",
                        }
                      }
                    >
                      {/* <div
												style={{
													display: "flex",
													justifyContent: "space-between",
													padding: "16px 16px 0px 16px",
													fontSize: "15.5px",
													fontWeight: "bold",
												}}
											> */}
                      {/* <p>Cashless Garages near you</p> */}

                      {/* </div> */}
                      <div
                        className="garage_div mt-3"
                        style={{
                          margin: lessthan993 ? "0px" : "0px 1.5rem 0px 1.5rem",
                          boxShadow: lessthan993
                            ? "none"
                            : "rgb(0 0 0 / 15%) 0px 5px 15px 0px",
                          borderRadius: "8px",
                        }}
                      >
                        {garage.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "16px",
                              fontSize: !lessthan993 ? "15.5px" : "12.5px",
                              fontWeight: "bold",
                            }}
                          >
                            <p style={{ margin: "0px", alignSelf: "center" }}>
                              Cashless Garages near you
                            </p>
                            <button
                              onClick={clearAllGarage}
                              className={!lessthan993 ? "mr-3" : "m-0"}
                              style={{
                                background: "#ec3b3b",
                                color: "#fff",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              <i className="fa fa-trash"></i>{" "}
                              {!lessthan993 && "Clear All"}
                            </button>
                          </div>
                        )}
                        {/* {garage?.length === 0 && (
                          <div>
                            <div
                              style={{
                                width: "50%",
                                margin: "auto",
                                height: "70vh",
                                textAlign: "center",
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src="/assets/images/nodata3.png"
                              />
                              <p style={{ fontWeight: "bold" }}>No Data</p>
                            </div>
                          </div>
                        )} */}
                        {garage.map((item, index) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: lessthan993 ? "column" : "row",
                              justifyContent: "space-between",
                              padding: "16px",
                              border: "none",
                              fontSize: !lessthan993 ? "13px" : "11px",
                              boxShadow: lessthan993
                                ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                                : "0px 6px 12px #3469cb29",
                              margin: lessthan993 ? "none" : "15px",
                              borderRadius: "8px",
                              marginBottom: lessthan993 ? "20px" : "",
                            }}
                          >
                            <p>
                              <strong
                                style={{
                                  fontSize: !lessthan993 ? "15px" : "12px",
                                }}
                              >
                                {" "}
                                {item?.garageName}{" "}
                              </strong>{" "}
                              <br /> {item?.garageAddress}{" "}
                            </p>
                            <div
                              className="badge bg-success"
                              style={{
                                fontSize: "13.5px",
                                fontWeight: "bold",
                                display: "flex",
                                margin: "0px",
                                color: "#fff",
                                padding: "8px",
                                height: "100%",
                                justifyContent: "center",
                                width: lessthan993 ? "max-content" : "auto",
                              }}
                            >
                              {" "}
                              <i
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                                className="fa fa-phone"
                              ></i>
                              <span style={{ fontSize: lessthan993 && "12px" }}>
                                {" "}
                                {item?.mobileNo}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* {loader && <Loader />}
											{cityName?.length > 2 &&
												garage?.length === 0 &&
												!loader && (
													<div
														style={{ textAlign: "center", marginTop: "30px" }}
													>
														<img src="/assets/images/nodata3.png" width="100" />
														<p style={{ fontSize: "13px", fontWeight: "bold" }}>
															No Data Found
														</p> */}
                      {/* <button
															onClick={clearAllGarage}
															className="mr-3"
															style={{
																background: "#ec3b3b",
																color: "#fff",
																border: "none",
																padding: "5px 10px",
																borderRadius: "5px",
																fontWeight: "bold",
															}}
														>
															<i className="fa fa-trash"></i>{" "}
															{!lessthan767 && "Clear All"}
														</button> */}
                      {/* </div> */}
                      {/* )} */}
                      {/* {garage.map((item, index) => (
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
															padding: "16px",
															border: "none",
															fontSize: !lessthan767 ? "13px" : "11px",
															boxShadow: "0px 6px 12px #3469cb29",
															margin: "15px",
															borderRadius: "8px",
														}}
													>
														<p>
															<strong
																style={{
																	fontSize: !lessthan767 ? "15px" : "12px",
																}}
															>
																{" "}
																{item?.garageName}{" "}
															</strong>{" "}
															<br /> {item?.garageAddress}{" "}
														</p>
														<p
															style={{
																fontSize: "13.5px",
																fontWeight: "bold",
																display: "flex",
															}}
														>
															{" "}
															<i
																style={{
																	marginRight: "5px",
																	fontWeight: "bold",
																	marginTop: "5px",
																}}
																className="fa fa-phone"
															></i>
															<span style={{ fontSize: lessthan767 && "12px" }}>
																{" "}
																{item?.mobileNo}
															</span>
														</p>
													</div>
												))} */}
                      {loader && <Loader />}
                      {garage?.length === 0 && !loader && (
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                          <img
                            src={`${
                              process.env.REACT_APP_BASENAME !== "NA"
                                ? `/${process.env.REACT_APP_BASENAME}`
                                : ""
                            }/assets/images/nodata3.png`}
                            width="100"
                          />
                          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                            No Data Found
                          </p>
                        </div>
                      )}
                    </Table>
                  </Body>
                </div>
              </TabContet>
            </DetailPopTabs>
          </Col>
        </Row>
      </ContentWrap>
      {saveQuoteLoader && <Loader />}
    </>
  );
  const content = (
    <>
      <ContentWrap>
        <Row>
          <Col
            lg={3}
            md={12}
            style={{ display: lessthan993 ? "none" : "block" }}
          >
            <DetailsPopHeadWrap>
              <div>
                {
                  <InfoCardKnowMore
                    lessthan767={lessthan767}
                    quote={quote}
                    totalPremium={totalPremium}
                    gst={gst}
                    finalPremium={finalPremium}
                    tab={temp_data?.tab}
                    totalPremiumC={totalPremiumC}
                    revisedNcb={revisedNcb}
                  />
                }
              </div>
              <BuyContainer>
                <CardTopRightCenter>
                  <BuyButton
                    onClick={handleClick}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ...(process.env.REACT_APP_BROKER === "UIB" && {
                        fontSize: "14px",
                      }),
                    }}
                  >
                    <div
                      style={
                        process.env.REACT_APP_BROKER === "UIB"
                          ? { fontSize: "14px" }
                          : {}
                      }
                    >
                      BUY NOW{" "}
                      <span style={{ fontSize: "20px", marginLeft: "5px" }}>
                        ₹ {currencyFormater(finalPremium)}
                      </span>
                    </div>
                  </BuyButton>
                </CardTopRightCenter>
              </BuyContainer>
              {!(temp_data?.tab === "tab2" && temp_data?.ownerTypeId !== 1) &&
                moreThan993 && (
                  <>
                    {!(
                      temp_data?.ownerTypeId !== 1 &&
                      quote?.applicableAddons?.length < 1
                    ) &&
                      (quote?.applicableAddons?.length > 0 ||
                        quote?.compulsoryPaOwnDriver > 0) && (
                        <AddonInfo>
                          <div className="addonHead">
                            {" "}
                            {
                              temp_data?.tab === "tab2"
                                ? "CPA"
                                : temp_data?.tab !== "tab2" &&
                                  quote?.applicableAddons?.length &&
                                  quote?.compulsoryPaOwnDriver > 0
                                ? "CPA & Add-Ons"
                                : quote?.applicableAddons?.length > 0
                                ? "Add-Ons"
                                : quote?.compulsoryPaOwnDriver > 0 && "CPA"
                              // (quote?.applicableAddons?.length > 0 &&
                              // quote?.compulsoryPaOwnDriver > 0)
                              //   ? "CPA & Add-Ons"
                              //   : (quote?.compulsoryPaOwnDriver > 0 &&
                              //     quote?.applicableAddons?.length === 0)
                              //   ? "CPA"
                              //   : (quote?.compulsoryPaOwnDriver === 0 &&
                              //     quote?.applicableAddons?.length > 1) &&
                              //     "Add-Ons"
                              // applicableAddons &&
                              // applicableAddons?.length > 0
                              //   ? temp_data?.ownerTypeId === 1 && !temp_data?.odOnly
                              //     ? quote.company_alias === "acko"
                              //       ? "CPA"
                              //       : "CPA & Add-ons"
                              //     : "Add-ons"
                              //   : "CPA"
                            }
                          </div>

                          {temp_data?.ownerTypeId === 1 && !temp_data?.odOnly && (
                            <>
                              {!(
                                temp_data?.newCar &&
                                TypeReturn(type) !== "cv" &&
                                temp_data?.tab === "tab2"
                              ) && (
                                <FilterMenuBoxCheckConatiner>
                                  <div className="filterMenuBoxCheck">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"Compulsory Personal Accident"}
                                      value={"Compulsory Personal Accident"}
                                      defaultChecked={
                                        addOnsAndOthers?.selectedCpa?.includes(
                                          "Compulsory Personal Accident"
                                        ) &&
                                        _.isEmpty(addOnsAndOthers?.isTenure)
                                      }
                                      checked={
                                        addOnsAndOthers?.selectedCpa?.includes(
                                          "Compulsory Personal Accident"
                                        ) &&
                                        _.isEmpty(addOnsAndOthers?.isTenure)
                                      }
                                      // value={addOnsAndOthers?.selectedCpa?.includes(
                                      //   "Compulsory Personal Accident"
                                      // )}
                                      //value={cpa}
                                    />

                                    <CustomTooltip
                                      rider="true"
                                      //	id="cpa1__Tooltipvol"
                                      place={"right"}
                                      customClassName="mt-3  "
                                    >
                                      <label
                                        data-tip="<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                        data-html={true}
                                        data-for="cpa1__Tooltipvol"
                                        className="form-check-label"
                                        htmlFor={"Compulsory Personal Accident"}
                                      >
                                        {"Compulsory Personal Accident"}{" "}
                                      </label>
                                    </CustomTooltip>

                                    <span style={{ marginLeft: "3px" }}></span>
                                  </div>
                                </FilterMenuBoxCheckConatiner>
                              )}
                              {TypeReturn(type) !== "cv" && temp_data?.newCar && (
                                <FilterMenuBoxCheckConatiner>
                                  <div className="filterMenuBoxCheck">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"Compulsory Personal Accident 1"}
                                      value={"Compulsory Personal Accident 1"}
                                      defaultChecked={
                                        !_.isEmpty(addOnsAndOthers?.isTenure)
                                      }
                                      checked={
                                        !_.isEmpty(addOnsAndOthers?.isTenure)
                                      }
                                      // value={addOnsAndOthers?.selectedCpa?.includes(
                                      //   "Compulsory Personal Accident"
                                      // )}
                                      //value={cpa}
                                    />

                                    <CustomTooltip
                                      rider="true"
                                      //	id="cpa1__Tooltipvol"
                                      place={"right"}
                                      customClassName="mt-3  "
                                    >
                                      <label
                                        data-tip="<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                        data-html={true}
                                        data-for="cpa1__Tooltipvol"
                                        className="form-check-label"
                                        htmlFor={
                                          "Compulsory Personal Accident 1"
                                        }
                                      >
                                        {`Compulsory Personal Accident (${
                                          TypeReturn(type) === "car"
                                            ? "3"
                                            : TypeReturn(type) === "bike" && "5"
                                        } years)`}{" "}
                                      </label>
                                    </CustomTooltip>

                                    <span style={{ marginLeft: "3px" }}></span>
                                  </div>
                                </FilterMenuBoxCheckConatiner>
                              )}
                            </>
                          )}
                          {temp_data?.tab !== "tab2" && (
                            <>
                              {quote?.applicableAddons?.includes(
                                "zeroDepreciation"
                              ) && (
                                <FilterMenuBoxCheckConatiner>
                                  <div className="filterMenuBoxCheck">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"Zero Depreciation"}
                                      defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                        "zeroDepreciation"
                                      )}
                                      checked={addOnsAndOthers?.selectedAddons?.includes(
                                        "zeroDepreciation"
                                      )}
                                      value={addOnsAndOthers?.selectedAddons?.includes(
                                        "zeroDepreciation"
                                      )}
                                    />

                                    <CustomTooltip
                                      rider="true"
                                      //	id="zero__Tooltipvol"
                                      place={"top"}
                                      customClassName="mt-3  "
                                    >
                                      <label
                                        data-tip="<h3 >Zero Depreciation</h3> <div>Also called Nil Depreciation cover or Bumper-to-Bumper cover. An add-on which gives you complete cover on any body parts of the car excluding tyres and batteries. Insurer will pay entire cost of body parts, ignoring the year-on-year depreciation in value of these parts.</div>"
                                        data-html={true}
                                        data-for="zero__Tooltipvol"
                                        className="form-check-label"
                                        htmlFor={"Zero Depreciation"}
                                      >
                                        {"Zero Depreciation"}{" "}
                                      </label>
                                    </CustomTooltip>
                                    <span style={{ marginLeft: "3px" }}></span>
                                  </div>
                                </FilterMenuBoxCheckConatiner>
                              )}

                              {quote?.applicableAddons?.includes(
                                "roadSideAssistance"
                              ) && (
                                <FilterMenuBoxCheckConatiner>
                                  <div className="filterMenuBoxCheck">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"Road Side Assistance"}
                                      defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                        "roadSideAssistance"
                                      )}
                                      checked={addOnsAndOthers?.selectedAddons?.includes(
                                        "roadSideAssistance"
                                      )}
                                      value={addOnsAndOthers?.selectedAddons?.includes(
                                        "roadSideAssistance"
                                      )}
                                    />

                                    <CustomTooltip
                                      rider="true"
                                      //	id="rsa__Tooltipvol"
                                      place={"top"}
                                      customClassName="mt-3  "
                                    >
                                      <label
                                        data-tip="<h3 >Road Side Assistance</h3> <div>Roadside Assistance Coverage means a professional technician comes to your rescue when your car breaks down in the middle of the journey leaving you stranded.</div>"
                                        data-html={true}
                                        data-for="rsa__Tooltipvol"
                                        className="form-check-label"
                                        htmlFor={"Road Side Assistance"}
                                      >
                                        {"Road Side Assistance"}{" "}
                                      </label>
                                    </CustomTooltip>
                                  </div>
                                </FilterMenuBoxCheckConatiner>
                              )}
                              {TypeReturn(type) === "cv" &&
                                quote?.applicableAddons?.includes("imt23") && (
                                  <>
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"IMT - 23"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "imt23"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "imt23"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "imt23"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //	id="imtTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >IMT - 23</h3> <div>COVER FOR LAMPS TYRES / TUBES MUDGUARDS BONNET
/SIDE PARTS BUMPERS HEADLIGHTS AND PAINTWORK OF
DAMAGED PORTION ONLY .</div>"
                                            data-html={true}
                                            data-for="imtTooltipvol"
                                            className="form-check-label"
                                            htmlFor={"IMT - 23"}
                                          >
                                            {"IMT - 23"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  </>
                                )}

                              {temp_data?.journeyCategory !== "GCV" &&
                                quote?.applicableAddons?.includes(
                                  "consumables"
                                ) && (
                                  <FilterMenuBoxCheckConatiner>
                                    <div className="filterMenuBoxCheck">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={"Consumable"}
                                        defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                          "consumables"
                                        )}
                                        checked={addOnsAndOthers?.selectedAddons?.includes(
                                          "consumables"
                                        )}
                                        value={addOnsAndOthers?.selectedAddons?.includes(
                                          "consumables"
                                        )}
                                      />

                                      <CustomTooltip
                                        rider="true"
                                        //		id="consumableTooltipvol"
                                        place={"top"}
                                        customClassName="mt-3  "
                                      >
                                        <label
                                          data-tip="<h3 >Consumable</h3> <div>Consumable items of a car include nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts</div>"
                                          data-html={true}
                                          data-for="consumableTooltipvol"
                                          className="form-check-label"
                                          htmlFor={"Consumable"}
                                        >
                                          {"Consumable"}{" "}
                                        </label>
                                      </CustomTooltip>
                                      <span
                                        style={{ marginLeft: "3px" }}
                                      ></span>
                                    </div>
                                  </FilterMenuBoxCheckConatiner>
                                )}
                              {(TypeReturn(type) === "car" ||
                                TypeReturn(type) === "bike") && (
                                <>
                                  {quote?.applicableAddons?.includes(
                                    "keyReplace"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Key Replacement"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "keyReplace"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "keyReplace"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "keyReplace"
                                          )}
                                        />
                                        <CustomTooltip
                                          rider="true"
                                          //		id="keyTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          {/* <img
								 data-tip="<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
								 data-html={true}
								 data-for="keyTooltipvol"
								 src={tooltip}
								 alt="tooltip"
								 className="toolTipRiderChild"
							 /> */}
                                          <label
                                            className="form-check-label"
                                            htmlFor={"Key Replacement"}
                                            data-tip="<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
                                            data-html={true}
                                            data-for="keyTooltipvol"
                                            alt="tooltip"
                                          >
                                            {"Key Replacement"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <div
                                          style={{ marginLeft: "3px" }}
                                        ></div>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}

                                  {quote?.applicableAddons?.includes(
                                    "engineProtector"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Engine Protector"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "engineProtector"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "engineProtector"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "engineProtector"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //	id="engTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            className="form-check-label"
                                            htmlFor={"Engine Protector"}
                                            data-tip="<h3 >Engine Protector</h3> <div>Engine protection cover in car insurance provides coverage towards damages or losses to the insured vehicle’s engine. The add-on compensates you for the replacement or repair of your car’s engine or parts.</div>"
                                            data-html={true}
                                            data-for="engTooltipvol"
                                          >
                                            {"Engine Protector"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                  {quote?.applicableAddons?.includes(
                                    "ncbProtection"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"NCB Protection"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "ncbProtection"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "ncbProtection"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "ncbProtection"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //			id="ncbProtTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            className="form-check-label"
                                            data-tip="<h3 >NCB Protection</h3> <div>The NCB Protector protects Your Earned No claim Bonus, in the event of an Own Damage claim made for Partial Loss including claims for Windshield glass, Total Loss, and Theft of vehicle/ accessories. The No Claim Bonus will not get impacted for the first 2 claims preferred during the course of this policy per year.</div>"
                                            data-html={true}
                                            data-for="ncbProtTooltipvol"
                                            htmlFor={"NCB Protection"}
                                          >
                                            {"NCB Protection"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}

                                  {quote?.applicableAddons?.includes(
                                    "tyreSecure"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Tyre Secure"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "tyreSecure"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "tyreSecure"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "tyreSecure"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //		id="tyreTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >Tyre Secure</h3> <div>The cost of damage to the insured vehicle due to an accident, riot, vandalism, and natural and man-made calamities. The repair cost of the tyre and tube without being damaged due to a claimable event is not covered by any basic insurance policy.</div>"
                                            data-html={true}
                                            data-for="tyreTooltipvol"
                                            className="form-check-label"
                                            htmlFor={"Tyre Secure"}
                                          >
                                            {"Tyre Secure"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                  {quote?.applicableAddons?.includes(
                                    "returnToInvoice"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Return To Invoice"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "returnToInvoice"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "returnToInvoice"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "returnToInvoice"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //		id="roiTooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >Return To Invoice</h3> <div>Return to Invoice is an add-on option which covers the gap between the insured declared value and the invoice value of your car along with the registration and other applicable taxes.</div>"
                                            data-html={true}
                                            data-for="roiTooltipvol"
                                            className="form-check-label"
                                            htmlFor={"Return To Invoice"}
                                          >
                                            {"Return To Invoice"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                  {quote?.applicableAddons?.includes(
                                    "lopb"
                                  ) && (
                                    <FilterMenuBoxCheckConatiner>
                                      <div className="filterMenuBoxCheck">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={"Loss of Personal Belongings"}
                                          defaultChecked={addOnsAndOthers?.selectedAddons?.includes(
                                            "lopb"
                                          )}
                                          checked={addOnsAndOthers?.selectedAddons?.includes(
                                            "lopb"
                                          )}
                                          value={addOnsAndOthers?.selectedAddons?.includes(
                                            "lopb"
                                          )}
                                        />

                                        <CustomTooltip
                                          rider="true"
                                          //		id="lopb__Tooltipvol"
                                          place={"top"}
                                          customClassName="mt-3  "
                                        >
                                          <label
                                            data-tip="<h3 >Loss of Personal Belongings</h3> <div>With this cover in place, your insurer will cover losses arising due to damage or theft of your personal Belongings from the insured car as per the terms and conditions of the policy.</div>"
                                            data-html={true}
                                            data-for="lopb__Tooltipvol"
                                            className="form-check-label"
                                            htmlFor={
                                              "Loss of Personal Belongings"
                                            }
                                          >
                                            {"Loss of Personal Belongings"}{" "}
                                          </label>
                                        </CustomTooltip>
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                      </div>
                                    </FilterMenuBoxCheckConatiner>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </AddonInfo>
                      )}
                  </>
                )}
            </DetailsPopHeadWrap>
          </Col>
          <Col lg={9} md={12}>
            <DetailPopTabs>
              <ul
                className="nav nav-tabs"
                style={{ display: lessthan993 ? "none" : "flex" }}
              >
                <li
                  className={key === "premiumBreakupPop" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    data-toggle="tab"
                    aria-expanded="true"
                    onClick={() => setKey("premiumBreakupPop")}
                  >
                    Premium Breakup
                  </a>
                </li>

                <li
                  className={key === "cashlessGaragesPop" ? "active" : ""}
                  style={{
                    borderRight: "1px solid black",
                    color: "blue",
                    cursor: "pointer",
                    visibility: !quote?.garageCount && "hidden",
                  }}
                  // style={{
                  // 	borderRight: "1px solid black",
                  // 	color: "blue",
                  // 	pointerEvents: allQuoteloading ? "none" : "",
                  // }}
                >
                  <a
                    data-toggle="tab"
                    aria-expanded="false"
                    onClick={() =>
                      (process.env?.REACT_APP_BROKER !== "ABIBL" ||
                        process.env?.REACT_APP_API_BASE_URL ===
                          "https://api-carbike.fynity.in/api") &&
                      setKey("cashlessGaragesPop")
                    }
                  >
                    Cashless Garages
                  </a>
                </li>
                <PdfMail>
                  <a
                    role="button"
                    onClick={handlePremPdfClick}
                    //onclick="pdfBreakup();"

                    id="export_pdf_breakup"
                  >
                    <a id="export_pdf_breakup" />
                    <i
                      className="fa fa-file-pdf-o"
                      style={{ fontSize: "20px" }}
                      aria-hidden="true"
                    ></i>
                    &nbsp; PDF&nbsp;&nbsp;
                  </a>
                  {/* <a onClick={() => {}}>
												<i
													className="fa fa-envelope"
													//	style="color:#1f0054!important;"
													aria-hidden="true"
												></i>
												&nbsp; EMAIL
											</a> */}
                </PdfMail>

                <PdfMail>
                  <button
                    style={{
                      all: "unset",
                      cursor: "pointer",
                    }}
                    onClick={handleEmailClick}
                    //onclick="pdfBreakup();"
                    id="export_pdf_breakup"
                  >
                    <i
                      className="fa fa-share-alt"
                      //	style="color:#1f0054!important;"
                      style={{ fontSize: "15px" }}
                      aria-hidden="true"
                    ></i>
                    &nbsp; SHARE&nbsp;&nbsp;
                  </button>
                  {/* <a onClick={() => {}}>
												<i
													className="fa fa-envelope"
													//	style="color:#1f0054!important;"
													aria-hidden="true"
												></i>
												&nbsp; EMAIL
											</a> */}
                </PdfMail>
              </ul>

              <TabContet>
                <div
                  class={
                    key === "premiumBreakupPop"
                      ? "showDiv premBreakup"
                      : "hideDiv"
                  }
                >
                  <MobilePremiumBreakup
                    quote={quote}
                    handlePremPdfClick={handlePremPdfClick}
                    handleEmailClick={handleEmailClick}
                    type={TypeReturn(type)}
                    totalPremiumA={totalPremiumA}
                    totalPremiumB={totalPremiumB}
                    revisedNcb={revisedNcb}
                    totalPremiumC={totalPremiumC}
                    totalAddon={totalAddon}
                    GetAddonValue={GetAddonValue}
                    getAddonName={getAddonName}
                    totalApplicableAddonsMotor={totalApplicableAddonsMotor}
                    addonDiscountPercentage={addonDiscountPercentage}
                    others={others}
                    othersList={othersList}
                    totalPremium={totalPremium}
                    gst={gst}
                    finalPremium={finalPremium}
                    handleClick={handleClick}
                    otherDiscounts={otherDiscounts}
                    show={show}
                    lessthan767={lessthan767}
                    ElectricalValue={ElectricalValue}
                    NonElectricalValue={NonElectricalValue}
                    BiFuelValue={BiFuelValue}
                  />
                  <>
                    <Body style={{ ...(lessthan993 && { display: "none" }) }}>
                      <div>
                        <Row id={"capture-ss"}>
                          <Col md={6} sm={12}>
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td
                                    rowspan="6"
                                    style={{
                                      verticalAlign: "middle",
                                      width: "20%",
                                      borderRight: "1px solid #ccc",
                                    }}
                                  >
                                    <img
                                      src={
                                        quote?.companyLogo
                                          ? quote?.companyLogo
                                          : ""
                                      }
                                      alt=""
                                      className="PremIcon"
                                      id="premium_breakup_ic_img"
                                      style={{ height: "auto", width: "100%" }}
                                    />
                                  </td>
                                  <td>
                                    <span style={{ fontWeight: "800" }}>
                                      {quote?.companyName}
                                    </span>{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{quote?.productName} </td>
                                </tr>
                                <tr>
                                  <td>Policy Type : {quote?.policyType}</td>
                                </tr>

                                <tr>
                                  <td>
                                    {`${quote?.mmvDetail?.manfName || ""}  
																	${quote?.mmvDetail?.modelName || ""}-	${quote?.mmvDetail?.versionName || ""}
																	${
                                    quote?.mmvDetail?.fuelType === "ELECTRIC" ||
                                    quote?.mmvDetail?.fuelType === "Electric"
                                      ? `${quote?.mmvDetail?.kw || " "}${
                                          TypeReturn(type) === "bike"
                                            ? ""
                                            : "kW"
                                        }`
                                      : `${
                                          quote?.mmvDetail?.cubicCapacity || " "
                                        } ${
                                          TypeReturn(type) === "bike"
                                            ? ""
                                            : "CC"
                                        }`
                                  }`}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Fuel Type :{" "}
                                    {quote?.fuelType
                                      ? quote?.fuelType.toUpperCase()
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    RTO : {quote?.vehicleRegistrationNo} -{" "}
                                    {temp_data?.rtoCity ||
                                      prefill?.corporateVehiclesQuoteRequest
                                        ?.rtoCity}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={6} sm={12}>
                            <Table bordered>
                              <tbody>
                                {quote?.mmvDetail?.seatingCapacity * 1 && (
                                  <tr>
                                    <td>
                                      Vehicle Seating Capacity :
                                      {` ${
                                        quote?.mmvDetail?.seatingCapacity * 1
                                      }`}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    IDV :{" "}
                                    {temp_data?.tab === "tab2" ? (
                                      <Badge
                                        variant="secondary"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        Not Applicable
                                      </Badge>
                                    ) : (
                                      ` ₹ ${currencyFormater(quote?.idv)}`
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Reg Date : {quote?.vehicleRegisterDate}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Previous Policy Expiry :{" "}
                                    {temp_data?.newCar
                                      ? "New"
                                      : temp_data?.breakIn
                                      ? temp_data?.expiry === "New" ||
                                        moment(
                                          subMonths(new Date(Date.now()), 9)
                                        ).format("DD-MM-YYYY") ===
                                          temp_data?.expiry
                                        ? ""
                                        : temp_data?.expiry
                                      : temp_data?.expiry}
                                  </td>
                                </tr>
                                {!temp_data?.breakIn && quote?.policyStartDate && (
                                  <tr>
                                    <td>
                                      Policy Start Date :{" "}
                                      {quote?.policyStartDate}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    Business Type :{" "}
                                    {quote?.businessType &&
                                      quote?.businessType
                                        .split(" ")
                                        .map(_.capitalize)
                                        .join(" ")}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={12}>
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      color:
                                        process.env.REACT_APP_BROKER === "RB" &&
                                        "rgb(25, 102, 255)",
                                    }}
                                  >
                                    Own Damage
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <DetailRow>
                                      <span> Basic Own Damage(OD)</span>
                                      <span className="amount">
                                        {quote?.basicPremium * 1 ? "₹" : ""}{" "}
                                        {quote?.basicPremium * 1
                                          ? currencyFormater(
                                              quote?.basicPremium
                                            )
                                          : "N/A"}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                <>
                                  {addOnsAndOthers.selectedAccesories?.includes(
                                    "Electrical Accessories"
                                  ) &&
                                  (quote?.motorElectricAccessoriesValue * 1 ||
                                    quote?.motorElectricAccessoriesValue * 1 ===
                                      0) ? (
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span> Electrical Accessories</span>
                                          <span className="amount">
                                            {quote?.motorElectricAccessoriesValue *
                                              1 || ElectricalValue() > 0
                                              ? `₹
                                            ${currencyFormater(
                                              quote?.company_alias ===
                                                "hdfc_ergo"
                                                ? quote?.motorElectricAccessoriesValue +
                                                    (ElectricalValue() || 0)
                                                : quote?.motorElectricAccessoriesValue
                                            )}`
                                              : "N/A"}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                  ) : (
                                    <noscript />
                                  )}
                                  {addOnsAndOthers.selectedAccesories?.includes(
                                    "Non-Electrical Accessories"
                                  ) &&
                                  (quote?.motorNonElectricAccessoriesValue *
                                    1 ||
                                    quote?.motorNonElectricAccessoriesValue *
                                      1 ===
                                      0) ? (
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span>
                                            Non-Electrical Accessories
                                          </span>
                                          <span className="amount">
                                            {quote?.motorNonElectricAccessoriesValue *
                                              1 || NonElectricalValue() > 0
                                              ? ` ₹
                                            ${currencyFormater(
                                              quote?.company_alias ===
                                                "hdfc_ergo"
                                                ? quote?.motorNonElectricAccessoriesValue +
                                                    (NonElectricalValue() || 0)
                                                : quote?.motorNonElectricAccessoriesValue
                                            )}`
                                              : "N/A"}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                  ) : (
                                    <noscript />
                                  )}
                                  {TypeReturn(type) !== "bike" &&
                                  (quote?.motorLpgCngKitValue * 1 ||
                                    quote?.motorLpgCngKitValue * 1 === 0) ? (
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span> LPG/CNG Kit</span>
                                          <span className="amount">
                                            {quote?.motorLpgCngKitValue * 1 ||
                                            BiFuelValue() > 0
                                              ? `₹
                                            ${currencyFormater(
                                              quote?.company_alias ===
                                                "hdfc_ergo"
                                                ? quote?.motorLpgCngKitValue +
                                                    (BiFuelValue() || 0)
                                                : quote?.motorLpgCngKitValue
                                            )}`
                                              : 
                                              (temp_data?.fuel === "CNG" || temp_data?.fuel === "LPG")
                                              ?
                                              <Badge
                                                    variant="primary"
                                                    style={{
                                                      position: "relative",
                                                      bottom: "2px",
                                                    }}
                                                  >
                                                    Included
                                                  </Badge>
                                              :
                                              "N/A"}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                  ) : (
                                    <noscript />
                                  )}
                                  {(quote?.limitedtoOwnPremisesOD * 1 ||
                                    quote?.limitedtoOwnPremisesOD * 1 === 0) &&
                                  addOnsAndOthers.selectedDiscount?.includes(
                                    "Vehicle Limited to Own Premises"
                                  ) ? (
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span>
                                            {" "}
                                            Vehicle limited to own premises
                                          </span>
                                          <span>
                                            {quote?.limitedtoOwnPremisesOD * 1
                                              ? "- ₹"
                                              : ""}{" "}
                                            {quote?.limitedtoOwnPremisesOD * 1
                                              ? currencyFormater(
                                                  quote?.limitedtoOwnPremisesOD
                                                )
                                              : "N/A"}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                  ) : (
                                    <noscript />
                                  )}
                                  {(quote?.geogExtensionODPremium * 1 ||
                                    quote?.geogExtensionODPremium * 1 === 0) &&
                                  addOnsAndOthers.selectedAdditions?.includes(
                                    "Geographical Extension"
                                  ) ? (
                                    <tr>
                                      <td>
                                        {" "}
                                        <DetailRow>
                                          <span> Geographical Extension</span>
                                          <span>
                                            {quote?.geogExtensionODPremium * 1
                                              ? "₹"
                                              : ""}{" "}
                                            {quote?.geogExtensionODPremium * 1
                                              ? currencyFormater(
                                                  quote?.geogExtensionODPremium
                                                )
                                              : "N/A"}
                                          </span>
                                        </DetailRow>
                                      </td>
                                    </tr>
                                  ) : (
                                    <noscript />
                                  )}
                                </>
                                {addOnsAndOthers.selectedAccesories?.includes(
                                  "Trailer"
                                ) &&
                                (quote?.trailerValue * 1 ||
                                  quote?.trailerValue * 1 === 0) ? (
                                  <tr>
                                    <td>
                                      {temp_data?.journeyCategory === "GCV" ? (
                                        <DetailRow>
                                          <span> Trailer</span>
                                          <span className="amount">
                                            {quote?.trailerValue * 1 ? "₹" : ""}{" "}
                                            {quote?.trailerValue * 1
                                              ? currencyFormater(
                                                  quote?.trailerValue
                                                )
                                              : "N/A"}
                                          </span>
                                        </DetailRow>
                                      ) : (
                                        <DetailRow>&nbsp;</DetailRow>
                                      )}{" "}
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {/* <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>&nbsp;</DetailRow>
                                  </td>
                                </tr> */}
                                {/* {type !== "bike" && (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>&nbsp;</DetailRow>
                                    </td>
                                  </tr>
                                )} */}
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        {Number(quote?.totalLoadingAmount) > 0
                                          ? "OD Premium"
                                          : "Total OD Premium (A)"}
                                      </span>
                                      <span>
                                        ₹ {currencyFormater(totalPremiumA)}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                {(quote?.company_alias === "bajaj_allianz" ||
                                  quote?.company_alias === "sbi") &&
                                false &&
                                TypeReturn(type) === "cv" &&
                                (Number(quote?.totalLoadingAmount) > 0 ||
                                  Number(quote?.underwritingLoadingAmount)) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span className="boldText">
                                          {" "}
                                          Total Loading Amount
                                        </span>
                                        <span>
                                          ₹{" "}
                                          {currencyFormater(
                                            Number(quote?.totalLoadingAmount) ||
                                              Number(
                                                quote?.underwritingLoadingAmount
                                              )
                                          )}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={6} sm={12}>
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      color:
                                        process.env.REACT_APP_BROKER === "RB" &&
                                        "rgb(25, 102, 255)",
                                    }}
                                  >
                                    Liability
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span> Third Party Liability</span>
                                      <span className="amount">
                                        {quote?.tppdPremiumAmount * 1
                                          ? "₹"
                                          : ""}{" "}
                                        {quote?.tppdPremiumAmount * 1
                                          ? currencyFormater(
                                              quote?.tppdPremiumAmount
                                            )
                                          : "N/A"}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                {addOnsAndOthers.selectedDiscount?.includes(
                                  "TPPD Cover"
                                ) &&
                                (quote?.tppdDiscount * 1 ||
                                  quote?.tppdDiscount * 1 === 0) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span> TPPD Discounts</span>
                                        <span>
                                          {quote?.tppdDiscount * 1 ? "- ₹" : ""}{" "}
                                          {quote?.tppdDiscount * 1
                                            ? currencyFormater(
                                                quote?.tppdDiscount
                                              )
                                            : "N/A"}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {temp_data?.journeyCategory !== "GCV" && (
                                  <>
                                    {addOnsAndOthers.selectedAdditions?.includes(
                                      "Unnamed Passenger PA Cover"
                                    ) ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>
                                              {" "}
                                              PA For Unnamed Passenger
                                            </span>
                                            <span className="amount">
                                              {quote?.coverUnnamedPassengerValue ===
                                                "NA" ||
                                              !(
                                                quote?.coverUnnamedPassengerValue *
                                                1
                                              )
                                                ? "N/A"
                                                : `	₹ ${currencyFormater(
                                                    quote?.companyAlias ===
                                                      "sbi" &&
                                                      addOnsAndOthers?.selectedCpa?.includes(
                                                        "Compulsory Personal Accident"
                                                      ) &&
                                                      !_.isEmpty(
                                                        addOnsAndOthers?.isTenure
                                                      )
                                                      ? quote?.coverUnnamedPassengerValue *
                                                          (TypeReturn(type) ===
                                                          "bike"
                                                            ? 5
                                                            : 3)
                                                      : quote?.coverUnnamedPassengerValue
                                                  )}`}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                  </>
                                )}
                                {TypeReturn(type) !== "bike" && (
                                  <>
                                    {(quote?.motorAdditionalPaidDriver * 1 ||
                                      quote?.motorAdditionalPaidDriver * 1 ===
                                        0) &&
                                    (addOnsAndOthers.selectedAdditions?.includes(
                                      "PA paid driver/conductor/cleaner"
                                    ) ||
                                      addOnsAndOthers.selectedAdditions?.includes(
                                        "PA cover for additional paid driver"
                                      )) ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow noWrap={true}>
                                            <span>
                                              {" "}
                                              {temp_data?.journeyCategory ===
                                              "GCV"
                                                ? "Additional PA Cover To Paid Driver/Conductor/Cleaner"
                                                : "Additional PA Cover To Paid Driver"}
                                            </span>
                                            <span className="amount">
                                              {quote?.motorAdditionalPaidDriver *
                                              1
                                                ? "₹"
                                                : ""}{" "}
                                              {quote?.motorAdditionalPaidDriver *
                                              1
                                                ? currencyFormater(
                                                    quote?.companyAlias ===
                                                      "sbi" &&
                                                      addOnsAndOthers?.selectedCpa?.includes(
                                                        "Compulsory Personal Accident"
                                                      ) &&
                                                      !_.isEmpty(
                                                        addOnsAndOthers?.isTenure
                                                      )
                                                      ? quote?.motorAdditionalPaidDriver *
                                                          (TypeReturn(type) ===
                                                          "bike"
                                                            ? 5
                                                            : 3)
                                                      : quote?.motorAdditionalPaidDriver
                                                  )
                                                : "N/A"}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                  </>
                                )}
                                {(quote?.defaultPaidDriver * 1 ||
                                  quote?.defaultPaidDriver * 1 === 0) &&
                                (addOnsAndOthers.selectedAdditions?.includes(
                                  "LL paid driver"
                                ) ||
                                  addOnsAndOthers.selectedAdditions?.includes(
                                    "LL paid driver/conductor/cleaner"
                                  )) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span>
                                          {" "}
                                          {temp_data?.journeyCategory === "GCV"
                                            ? "Legal Liability To Paid Driver/Conductor/Cleaner"
                                            : "Legal Liability To Paid Driver"}{" "}
                                        </span>
                                        <span className="amount">
                                          {quote?.defaultPaidDriver * 1
                                            ? `₹ ${currencyFormater(
                                                quote?.defaultPaidDriver
                                              )}`
                                            : "N/A"}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {!addOnsAndOthers.selectedAdditions?.includes(
                                  "LL paid driver"
                                ) &&
                                  !_.isEmpty(others) &&
                                  others?.includes("lLPaidDriver") &&
                                  others.map(
                                    (item) =>
                                      item === "lLPaidDriver" && (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span>
                                                {camelToUnderscore(item) &&
                                                  camelToUnderscore(item)
                                                    .replace(/_/g, " ")
                                                    .split(" ")
                                                    .map(_.capitalize)
                                                    .join(" ")}
                                              </span>
                                              <span>
                                                {" "}
                                                {Number(othersList[item]) ===
                                                0 ? (
                                                  <Badge
                                                    variant="primary"
                                                    style={{
                                                      position: "relative",
                                                      bottom: "2px",
                                                    }}
                                                  >
                                                    Included
                                                  </Badge>
                                                ) : (
                                                  `₹ ${currencyFormater(
                                                    othersList[item]
                                                  )}`
                                                )}{" "}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      )
                                  )}
                                {TypeReturn(type) !== "bike" ? (
                                  <>
                                    {quote?.cngLpgTp * 1 ||
                                    quote?.cngLpgTp * 1 === 0 ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>LPG/CNG Kit TP</span>
                                            <span className="amount">
                                              {quote?.cngLpgTp * 1 ? "₹" : ""}{" "}
                                              {quote?.cngLpgTp * 1
                                                ? currencyFormater(
                                                    quote?.cngLpgTp
                                                  )
                                                : "N/A"}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                  </>
                                ) : (
                                  <noscript />
                                )}
                                {addOnsAndOthers?.selectedCpa?.includes(
                                  "Compulsory Personal Accident"
                                ) && _.isEmpty(addOnsAndOthers?.isTenure) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span>
                                          {" "}
                                          Compulsory PA Cover For Owner Driver
                                        </span>
                                        <span className="amount">
                                          {addOnsAndOthers?.selectedCpa?.includes(
                                            "Compulsory Personal Accident"
                                          ) &&
                                          quote?.compulsoryPaOwnDriver * 1 === 0
                                            ? "N/A"
                                            : "₹" +
                                              currencyFormater(
                                                quote?.compulsoryPaOwnDriver
                                              )}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {addOnsAndOthers?.selectedCpa?.includes(
                                  "Compulsory Personal Accident"
                                ) && !_.isEmpty(addOnsAndOthers?.isTenure) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span>
                                          {" "}
                                          Compulsory PA Cover For Owner Driver
                                        </span>
                                        <span className="amount">
                                          {addOnsAndOthers?.selectedCpa?.includes(
                                            "Compulsory Personal Accident"
                                          ) &&
                                          !_.isEmpty(
                                            addOnsAndOthers?.isTenure
                                          ) &&
                                          (!quote?.multiYearCpa ||
                                            quote?.multiYearCpa * 1 === 0)
                                            ? "N/A"
                                            : "₹" +
                                              currencyFormater(
                                                quote?.multiYearCpa
                                              )}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {(quote?.limitedtoOwnPremisesTP * 1 ||
                                  quote?.limitedtoOwnPremisesTP * 1 === 0) &&
                                addOnsAndOthers.selectedDiscount?.includes(
                                  "Vehicle Limited to Own Premises"
                                ) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span>
                                          {" "}
                                          Vehicle limited to own premises
                                        </span>
                                        <span>
                                          {quote?.limitedtoOwnPremisesTP * 1
                                            ? "- ₹"
                                            : ""}{" "}
                                          {quote?.limitedtoOwnPremisesTP * 1
                                            ? currencyFormater(
                                                quote?.limitedtoOwnPremisesTP
                                              )
                                            : "N/A"}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {(quote?.geogExtensionTPPremium * 1 ||
                                  quote?.geogExtensionTPPremium * 1 === 0) &&
                                addOnsAndOthers.selectedAdditions?.includes(
                                  "Geographical Extension"
                                ) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span> Geographical Extension</span>
                                        <span>
                                          {quote?.geogExtensionTPPremium * 1
                                            ? "₹"
                                            : ""}{" "}
                                          {quote?.geogExtensionTPPremium * 1
                                            ? currencyFormater(
                                                quote?.geogExtensionTPPremium
                                              )
                                            : "N/A"}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        Total Liability Premium (B)
                                      </span>
                                      <span className="amount">
                                        ₹{" "}
                                        {currencyFormater(
                                          totalPremiumB -
                                            (quote?.tppdDiscount * 1 || 0)
                                        )}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={12}>
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      color:
                                        process.env.REACT_APP_BROKER === "RB" &&
                                        "rgb(25, 102, 255)",
                                    }}
                                  >
                                    Own Damage Discounts
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span> Deduction of NCB</span>
                                      <span>
                                        {revisedNcb * 1 ? "₹" : ""}{" "}
                                        {revisedNcb * 1
                                          ? currencyFormater(revisedNcb)
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                {temp_data?.journeyCategory !== "GCV" &&
                                addOnsAndOthers.selectedDiscount?.includes(
                                  "Voluntary Discounts"
                                ) ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span> Voluntary Deductible</span>
                                        <span>
                                          {quote?.voluntaryExcess * 1
                                            ? `₹
                                          ${currencyFormater(
                                            quote?.voluntaryExcess
                                          )}`
                                            : "N/A"}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                {temp_data?.journeyCategory !== "GCV" && (
                                  <>
                                    {(quote?.antitheftDiscount * 1 ||
                                      quote?.antitheftDiscount * 1 === 0) &&
                                    addOnsAndOthers.selectedDiscount?.includes(
                                      "Is the vehicle fitted with ARAI approved anti-theft device?"
                                    ) ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span> Anti-Theft</span>
                                            <span>
                                              {quote?.antitheftDiscount * 1
                                                ? "₹"
                                                : ""}{" "}
                                              {quote?.antitheftDiscount * 1
                                                ? currencyFormater(
                                                    quote?.antitheftDiscount * 1
                                                  )
                                                : "N/A"}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                    {quote?.premisisDiscount ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>
                                              {" "}
                                              Vehicle limited to own premises
                                            </span>
                                            <span>
                                              ₹{" "}
                                              {currencyFormater(
                                                quote?.premisisDiscount
                                              )}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                    {addOnsAndOthers.selectedDiscount?.includes(
                                      "Automobile Association of India Discount"
                                    ) ? (
                                      <tr>
                                        <td>
                                          {" "}
                                          <DetailRow>
                                            <span>
                                              {" "}
                                              Automobile Association of India
                                            </span>
                                            <span>
                                              {quote?.aaiDiscount * 1
                                                ? "₹"
                                                : ""}{" "}
                                              {quote?.aaiDiscount * 1
                                                ? currencyFormater(
                                                    quote?.aaiDiscount * 1
                                                  )
                                                : "N/A"}
                                            </span>
                                          </DetailRow>
                                        </td>
                                      </tr>
                                    ) : (
                                      <noscript />
                                    )}
                                  </>
                                )}
                                {otherDiscounts * 1 ? (
                                  <tr>
                                    <td>
                                      {" "}
                                      <DetailRow>
                                        <span> Other Discounts</span>
                                        <span>
                                          ₹{" "}
                                          {currencyFormater(otherDiscounts) ||
                                            0}
                                        </span>
                                      </DetailRow>
                                    </td>
                                  </tr>
                                ) : (
                                  <noscript />
                                )}
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        Total Discount (C)
                                      </span>
                                      <span>
                                        ₹{" "}
                                        {currencyFormater(
                                          totalPremiumC -
                                            (quote?.tppdDiscount * 1 || 0)
                                        )}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={6} sm={12}>
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      color:
                                        process.env.REACT_APP_BROKER === "RB" &&
                                        "rgb(25, 102, 255)",
                                    }}
                                  >
                                    Add-ons
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  {totalApplicableAddonsMotor.map(
                                    (item, index) => (
                                      <>
                                        {GetAddonValue(
                                          item,
                                          addonDiscountPercentage
                                        ) !== "N/A" && (
                                          <tr>
                                            <td>
                                              <DetailRow>
                                                <span>
                                                  {" "}
                                                  {getAddonName(
                                                    item,
                                                    addonDiscountPercentage
                                                  )}
                                                </span>

                                                <span>
                                                  {GetAddonValue(
                                                    item,
                                                    addonDiscountPercentage
                                                  ) === "N/S" ? (
                                                    <Badge
                                                      variant="secondary"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      Not selected
                                                    </Badge>
                                                  ) : GetAddonValue(
                                                      item,
                                                      addonDiscountPercentage
                                                    ) === "N/A" ? (
                                                    <Badge
                                                      variant="danger"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      Not Available
                                                    </Badge>
                                                  ) : (
                                                    <>
                                                      {GetAddonValue(
                                                        item,
                                                        addonDiscountPercentage
                                                      )}
                                                    </>
                                                  )}
                                                </span>
                                              </DetailRow>
                                            </td>{" "}
                                          </tr>
                                        )}
                                      </>
                                    )
                                  )}
                                  {others.map(
                                    (item, index) =>
                                      item !== "lLPaidDriver" && (
                                        <tr>
                                          <td>
                                            {" "}
                                            <DetailRow>
                                              <span>
                                                {camelToUnderscore(item) &&
                                                  camelToUnderscore(item)
                                                    .replace(/_/g, " ")
                                                    .split(" ")
                                                    .map(_.capitalize)
                                                    .join(" ")}
                                              </span>
                                              <span>
                                                {" "}
                                                {Number(othersList[item]) ===
                                                0 ? (
                                                  <Badge
                                                    variant="primary"
                                                    style={{
                                                      position: "relative",
                                                      bottom: "2px",
                                                    }}
                                                  >
                                                    Included
                                                  </Badge>
                                                ) : (
                                                  `₹ ${currencyFormater(
                                                    othersList[item]
                                                  )}`
                                                )}{" "}
                                              </span>
                                            </DetailRow>
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </>

                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        Total Add-on Premium (D)
                                      </span>
                                      <span>
                                        ₹ {currencyFormater(totalAddon)}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                          <Col md={12} sm={12}>
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        {"Total OD Payable (A + D - C)"}
                                      </span>
                                      <span className="boldText">
                                        ₹{" "}
                                        {currencyFormater(
                                          (totalPremiumA * 1 || 0) +
                                            (quote?.totalLoadingAmount * 1 ||
                                              0) +
                                            (quote?.underwritingLoadingAmount *
                                              1 || 0) +
                                            (totalAddon * 1 || 0) -
                                            ((totalPremiumC * 1 || 0) -
                                              (quote?.tppdDiscount * 1 || 0))
                                        )}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        {"Total TP Payable (B)"}
                                      </span>
                                      <span className="boldText">
                                        ₹{" "}
                                        {currencyFormater(
                                          totalPremiumB -
                                            (quote?.tppdDiscount * 1 || 0)
                                        )}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        {" "}
                                        {uwLoading > 0
                                          ? "Net Premium"
                                          : "Net Premium "}
                                      </span>
                                      <span className="boldText">
                                        ₹ {currencyFormater(totalPremium)}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText"> GST </span>
                                      <span className="boldText">
                                        ₹ {currencyFormater(gst)}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {" "}
                                    <DetailRow>
                                      <span className="boldText">
                                        Gross Premium (incl. GST)
                                      </span>
                                      <span className="boldText">
                                        ₹ {currencyFormater(finalPremium)}
                                      </span>
                                    </DetailRow>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>
                    </Body>
                  </>
                </div>

                <div
                  class={key === "premiumBreakupPop" ? "hideDiv" : "showDiv"}
                >
                  <Body>
                    <Row style={lessthan993 ? {} : { width: "100%" }}>
                      <Col md={12} sm={12}>
                        <div className="w-100 cashless_ui">
                          <h3
                            className="cashlessHeading"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            {lessthan993
                              ? "Cashless Garages Near You"
                              : "Cashless Garages"}
                          </h3>
                          <RowTag className="row mt-4">
                            <div className="col-4 col-lg-3 m-0 p-0"></div>
                            <div className="col-12 col-lg-12 p-0 d-flex cashless_input">
                              <input
                                type="text"
                                name="pincode"
                                ref={register}
                                className="form-control search_input"
                                placeholder="Search Garages by Pincode/City"
                                style={{
                                  padding: "29px",
                                  borderRadius: lessthan993 ? "8px" : "8px",
                                }}
                                defaultValue={
                                  temp_data?.rtoCity ||
                                  prefill?.corporateVehiclesQuoteRequest
                                    ?.rtoCity
                                }
                              />
                              <i
                                className="fa fa-search"
                                style={{
                                  position: "absolute",
                                  left: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                  marginTop: "18px",
                                  fontSize: "22px",
                                  marginLeft: "-34px",
                                }}
                              ></i>
                            </div>
                            {/* {showInput && (
															<div style={{ width: "100%", padding: "20px" }}>
																<input
																	className="my-form"
																	onChange={handleSearch}
																	//   options={list}
																	//   Controller={Controller}
																	//   control={control}
																	ref={register}
																	name="cityName"
																	placeholder="Enter City"
																	autoFocus
																/>
															</div>
														)} */}
                          </RowTag>
                          {/* <Textbox
														lg
														type="text"
														id="pincode"
														fieldName="Pincode / City"
														name="pincode"
														placeholder=" "
														register={register}
														//onChange={handleChange}
														// onKeyDown={numOnly}
														error={errors?.lastName}
														// maxLength={6}
													/> */}
                          {!!errors.lastName && (
                            <Error style={{ marginTop: "-20px" }}>
                              {errors.lastName.message}
                            </Error>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Table
                      striped
                      // bordered
                      hover
                      style={
                        {
                          //marginTop: "33px",
                        }
                      }
                    >
                      {/* <div
												style={{
													display: "flex",
													justifyContent: "space-between",
													padding: "16px 16px 0px 16px",
													fontSize: "15.5px",
													fontWeight: "bold",
												}}
											> */}
                      {/* <p>Cashless Garages near you</p> */}

                      {/* </div> */}
                      <div
                        className="garage_div mt-3"
                        style={{
                          margin: lessthan993 ? "0px" : "0px 1.5rem 0px 1.5rem",
                          boxShadow: lessthan993
                            ? "none"
                            : "rgb(0 0 0 / 15%) 0px 5px 15px 0px",
                          borderRadius: "8px",
                        }}
                      >
                        {garage.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "16px",
                              fontSize: !lessthan993 ? "15.5px" : "12.5px",
                              fontWeight: "bold",
                            }}
                          >
                            <p style={{ margin: "0px", alignSelf: "center" }}>
                              Cashless Garages near you
                            </p>
                            <button
                              onClick={clearAllGarage}
                              className={!lessthan993 ? "mr-3" : "m-0"}
                              style={{
                                background: "#ec3b3b",
                                color: "#fff",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              <i className="fa fa-trash"></i>{" "}
                              {!lessthan993 && "Clear All"}
                            </button>
                          </div>
                        )}
                        {/* {garage?.length === 0 && (
                          <div>
                            <div
                              style={{
                                width: "50%",
                                margin: "auto",
                                height: "70vh",
                                textAlign: "center",
                              }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src="/assets/images/nodata3.png"
                              />
                              <p style={{ fontWeight: "bold" }}>No Data</p>
                            </div>
                          </div>
                        )} */}
                        {garage.map((item, index) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: lessthan993 ? "column" : "row",
                              justifyContent: "space-between",
                              padding: "16px",
                              border: "none",
                              fontSize: !lessthan993 ? "13px" : "11px",
                              boxShadow: lessthan993
                                ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                                : "0px 6px 12px #3469cb29",
                              margin: lessthan993 ? "none" : "15px",
                              borderRadius: "8px",
                              marginBottom: lessthan993 ? "20px" : "",
                            }}
                          >
                            <p>
                              <strong
                                style={{
                                  fontSize: !lessthan993 ? "15px" : "12px",
                                }}
                              >
                                {" "}
                                {item?.garageName}{" "}
                              </strong>{" "}
                              <br /> {item?.garageAddress}{" "}
                            </p>
                            <div
                              className="badge bg-success"
                              style={{
                                fontSize: "13.5px",
                                fontWeight: "bold",
                                display: "flex",
                                margin: "0px",
                                color: "#fff",
                                padding: "8px",
                                height: "100%",
                                justifyContent: "center",
                                width: lessthan993 ? "max-content" : "auto",
                              }}
                            >
                              {" "}
                              <i
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                                className="fa fa-phone"
                              ></i>
                              <span style={{ fontSize: lessthan993 && "12px" }}>
                                {" "}
                                {item?.mobileNo}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* {loader && <Loader />}
											{cityName?.length > 2 &&
												garage?.length === 0 &&
												!loader && (
													<div
														style={{ textAlign: "center", marginTop: "30px" }}
													>
														<img src="/assets/images/nodata3.png" width="100" />
														<p style={{ fontSize: "13px", fontWeight: "bold" }}>
															No Data Found
														</p> */}
                      {/* <button
															onClick={clearAllGarage}
															className="mr-3"
															style={{
																background: "#ec3b3b",
																color: "#fff",
																border: "none",
																padding: "5px 10px",
																borderRadius: "5px",
																fontWeight: "bold",
															}}
														>
															<i className="fa fa-trash"></i>{" "}
															{!lessthan767 && "Clear All"}
														</button> */}
                      {/* </div> */}
                      {/* )} */}
                      {/* {garage.map((item, index) => (
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
															padding: "16px",
															border: "none",
															fontSize: !lessthan767 ? "13px" : "11px",
															boxShadow: "0px 6px 12px #3469cb29",
															margin: "15px",
															borderRadius: "8px",
														}}
													>
														<p>
															<strong
																style={{
																	fontSize: !lessthan767 ? "15px" : "12px",
																}}
															>
																{" "}
																{item?.garageName}{" "}
															</strong>{" "}
															<br /> {item?.garageAddress}{" "}
														</p>
														<p
															style={{
																fontSize: "13.5px",
																fontWeight: "bold",
																display: "flex",
															}}
														>
															{" "}
															<i
																style={{
																	marginRight: "5px",
																	fontWeight: "bold",
																	marginTop: "5px",
																}}
																className="fa fa-phone"
															></i>
															<span style={{ fontSize: lessthan767 && "12px" }}>
																{" "}
																{item?.mobileNo}
															</span>
														</p>
													</div>
												))} */}
                      {loader && <Loader />}
                      {garage?.length === 0 && !loader && (
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                          <img
                            src={`${
                              process.env.REACT_APP_BASENAME !== "NA"
                                ? `/${process.env.REACT_APP_BASENAME}`
                                : ""
                            }/assets/images/nodata3.png`}
                            width="100"
                          />
                          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                            No Data Found
                          </p>
                        </div>
                      )}
                    </Table>
                  </Body>
                </div>
              </TabContet>
            </DetailPopTabs>
          </Col>
        </Row>
      </ContentWrap>
      {saveQuoteLoader && <Loader />}
    </>
  );
  return (
    // <Popup
    //   height={lessthan993 ? "100%" : width < 1300 ? "500px" : "600px"}
    //   width={width < 992 ? "100%" : lessthan1350 ? "90%" : "80%"}
    //   // animate={key === 'cashlessGaragesPop'}
    //   show={show}
    //   onClose={onClose}
    //   content={content}
    //   position={"middle"}
    //   overflowX={true}
    //   outside={sendQuotes ? true : false}
    // />
    <Popup
      // height={lessthan993 ? "100%" : width < 1300 ? "100%" : "100%"}
      // width={width < 992 ? "100%" : lessthan1350 ? "100%" : "100%"}
      height={lessthan993 ? "100%" : width < 1300 ? "500px" : "600px"}
      width={width < 992 ? "100%" : lessthan1350 ? "90%" : "80%"}
      // animate={key === 'cashlessGaragesPop'}
      show={show}
      onClose={onClose}
      content={content}
      position={"middle"}
      overflowX={true}
      outside={sendQuotes ? true : false}
    />
  );
};

// PropTypes
KnowMorePopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
KnowMorePopup.defaultProps = {
  show: false,
  onClose: () => {},
};

const ContentWrap = styled.div`
  // padding: 14px 0px 0px 14px;
  padding: 0px 0px 0px 14px;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 12px;
  line-height: 22px;
  // color: #333;
  position: relative;
  overflow-x: clip;
  @media (max-width: 993px) {
    padding: 0px 0px 0px 0px;
  }

  .div-body-tag {
    box-shadow: rgb(0 0 0 / 10%) 0px 8px 25px -5px,
      rgb(0 0 0 / 4%) 0px 10px 10px -5px;
    width: 100%;
    height: 100%;
    // padding: 25px 25px 0px 25px;

    th {
      border: 1px solid #fff !important;
    }
  }
  .t-body-tag td {
    border: 1px solid #fff !important;
    color: red !important;
  }
  // .filterMenuBoxCheck label:before {
  //   background: ${({ theme }) =>
    theme.CheckBox?.color || "#fff !important"} ;
  // }
`;
const DetailsPopHeadWrap = styled.div`
  float: left;
  width: 294px;
  padding: 0 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.regularFont?.textColor || ""};
  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
const CardTopRightCenter = styled.div`
  margin: 0 auto;
  display: inline-block;
  @media (max-width: 992px) {
    width: 99% !important;
  }
`;
const BuyButton = styled.button`
  float: left;
  display: flex;
  justify-content: center !important;
  align-item: center !important;
  margin-top: 6px;
  background-color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
  color: #fff !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-SemiBold"};
  font-size: 12px;
  line-height: 40px;
  border-radius: ${({ theme }) =>
    theme.QuoteBorderAndFont?.borderRadius || "50px"};
  margin-left: 0;
  outline: none;
  width: ${process.env.REACT_APP_BROKER === "UIB" ? `245px` : `255px`};
  margin-top: 20px;
  line-height: 24px;
  height: 54px;
  .div {
    width: 106px;
    position: relative;
    margin: 0 auto;
    height: 28px;
  }
  .span {
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamily || "Inter-SemiBold"};
    font-size: 14px;
    line-height: 2;
    display: contents;
    float: left;
  }
  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

// const DetailPopTabs = styled.div`
//   float: left;
//   margin-top: -14px;
//   border-left: 1px solid #e3e4e8;

//   min-width: 777px;
//   @media (max-width: 992px) {
//     margin-top: 0px;
//     float: right;
//     border-left: 0px solid #e3e4e8;
//     width: 100%;
//     min-width: 320px;
//   }

//   .nav-tabs > li.active > a {
//     background: ${({ theme }) =>
//       theme.QuoteBorderAndFont?.navColor || "#f3ff91"};
//     color: #333 !important;
//   }
//   .nav-tabs > li > a,
//   .nav-tabs > li > a:hover,
//   .nav-tabs > li > a:focus {
//     font-family: ${({ theme }) =>
//       theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
//   }
// `;
const DetailPopTabs = styled.div`
  float: left;
  // margin-top: -14px;
  border-left: 1px solid #e3e4e8;

  min-width: 777px;
  @media (max-width: 992px) {
    margin-top: 0px;
    float: right;
    border-left: 0px solid #e3e4e8;
    width: 100%;
    min-width: 320px;
  }

  .nav-tabs > li.active > a {
    /* background: ${({ theme }) =>
      theme.QuoteBorderAndFont?.navColor || "#f3ff91"}; */
    color: ${({ theme }) =>
      process.env.REACT_APP_BROKER === "UIB" && theme.QuotePopups?.color
        ? theme.QuotePopups?.color
        : "#333"} !important;
    border-bottom: ${({ theme }) =>
      theme.boldBorder?.border || "5px solid #f3ff91 !important"};
    box-shadow: ${({ theme }) =>
      theme.boldBorder?.boxShadow || "#f3ff916b 0px -50px 36px -28px inset"};
  }
  .nav-tabs > li > a,
  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  }
  /* .nav-tabs > li > {
    visibility: hidden;
  } */
`;

const TabContet = styled.div`
  padding: 0;
  .premBreakup {
    .table td,
    .table th {
      padding: 5px !important;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      color: ${({ theme }) => theme.regularFont?.textColor || "#212529"};
    }
  }
`;

const Body = styled.div`
  position: relative;
  padding: 15px;
  .cashlessHeading {
    font-weight: 800;
  }
  @media (max-width: 993px) {
    .cashlessHeading {
      display: flex;
      justify-content: center;
      font-weight: 800 !important;
      margin-bottom: 20px;
      font-size: 20px !important;
    }
  }
`;
const PdfMail = styled.div`
  margin-top: 20px;
  font-size: 16px;
  margin-left: 10px;
  @media (max-width: 992px) {
    margin-top: 20px;
    font-size: 8px;
    margin-left: 10px;
  }
`;
const DetailRow = styled.div`
  justify-content: space-between;
  display: flex;
  .amount {
    width: 20%;
    text-align: end;
  }
  .boldText {
    font-weight: 600;
  }
  white-space: ${(nowrap) => (nowrap ? "nowrap" : "unset")};
`;
const FilterMenuBoxCheckConatiner = styled.div`
  .filterMenuBoxCheck input[type="checkbox"]:checked + label:before {
    background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
    border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
    box-shadow: ${({ theme }) =>
      theme.QuoteBorderAndFont?.shadowCheck || "none"};
    filter: ${({ theme }) =>
      theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};
    .borderless {
      border: none !important;
    }
  }
`;

const BuyContainer = styled.div`
  @media (max-width: 992px) {
    width: 99% !important;
  }
`;

// const AddonInfo = styled.div`
//   width: 258px;
//   float: left;
//   position: relative;
//   border-radius: 12px;
//   background-color: #ffffff;
//   padding: 24px 15px 30px 15px;
//   box-shadow: 0 8px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
//   border: solid 1px #e3e4e8;
//   margin-top: 10px;
//   @media (max-width: 992px) {
//     width: 100% !important;
//     display: none;
//   }
//   .addonHead {
//     text-align: center;
//     font-size: 16px;
//     margin-bottom: 15px;
//   }
// `;
const AddonInfo = styled.div`
  width: 258px;
  float: left;
  position: relative;
  border-radius: 12px;
  // background-color: #ffffff;
  padding: 24px 15px 30px 15px;
  // box-shadow: 0 8px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
  // border: solid 1px #e3e4e8;
  margin-top: 10px;
  @media (max-width: 992px) {
    width: 100% !important;
    display: none;
  }
  .addonHead {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

const CityButton = styled.button`
  background: ${({ theme }) => theme.City?.background || "rgb(243 255 145)"};
  border: ${({ theme }) => theme.City?.border || "1px solid rgb(189 212 0)"};
`;

const RowTag = styled.div`
	@media (max-width: 1160px) {
		width: 90%;
	}
	@media (max-width: 1050px) {
		width: 80%;
	}
	@media (max-width: 980px) {
		width: 100%;
	}
  }

  @media(max-width: 576px) {

	.city_button {
		padding: 5px 8px !important;
		font-size: 0.75rem !important;
		p {
			margin: 0 !important;
		}
		i {
			margin-top: 4px !important;
		}
	}
	.search_input {
		padding: 26px 15px !important;
		font-size: 0.75rem;
	}
	.i-tag {
		margin-top: 10px !important;
		font-size: 15px !important;
	}
	.my-form {
		padding: 5px 15px !important;
		font-size: 0.75rem !important;
	}
   
	  
  } 
`;

export default KnowMorePopup;
