import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import "./otp.css";
import { Button } from "components";
import { useMediaPredicate } from "react-media-hook";
import styled from "styled-components";
import { clear, VerifyOTP } from "./proposal.slice";
import swal from "sweetalert";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const OTPPopup = (props) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan380 = useMediaPredicate("(max-width: 380px)");
  const lessthan320 = useMediaPredicate("(max-width: 320px)");
  const lessthan415 = useMediaPredicate("(max-width: 415px)");
  const dispatch = useDispatch();
  const { onHide, otpSuccess } = props;
  const { error, verifyOtp, otpError } = useSelector((state) => state.proposal);
  const [resend, setResend] = useState(false);
  const [counter, setCounter] = useState(false);

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();

  const numOnly = (event) => {
    let key = event.keyCode || event.which;
    if (
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 35 ||
      key === 37 ||
      key === 39
      // key === 144
    ) {
      if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
        if (event.target.name === "otp1") {
          otp1.current.value = event.key;
        }
        if (event.target.name === "otp2") {
          otp2.current.value = event.key;
        }
        if (event.target.name === "otp3") {
          otp3.current.value = event.key;
        }
        if (event.target.name === "otp4") {
          otp4.current.value = event.key;
        }
      }
    } else {
      event.preventDefault();
    }
  };

  const nextKey = (e) => {
    if (e.target.value) {
      if (e.target.name === "otp1") {
        otp2.current.focus();
      }
      if (e.target.name === "otp2") {
        otp3.current.focus();
      }
      if (e.target.name === "otp3") {
        otp4.current.focus();
      }
    }
  };

  //verify OTP
  const otpEnter = () => {
    if (
      otp1.current.value &&
      otp2.current.value &&
      otp3.current.value &&
      otp4.current.value
    ) {
      dispatch(
        VerifyOTP({
          enquiryId: props?.enquiry_id,
          otp:
            otp1.current.value +
            otp2.current.value +
            otp3.current.value +
            otp4.current.value,
        })
      );
    }
  };

  //on Error
  useEffect(() => {
    if (otpError) {
      swal(
        "Error",
        props?.enquiry_id
          ? `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${otpError}`}`
          : otpError,
        "error"
      );
    }

    if (verifyOtp) {
      // swal("Success", 'Validation successful', "success").then(() => {

      // });
      otpSuccess();
      onHide();
      // history.push(`/policy-renewal?enquiry_id=${enquiry_id}`)
    }

    return () => {
      dispatch(clear("verifyOtp"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpError, verifyOtp]);
  //'resend
  // useEffect(() => {
  //   setTimeout(setCounter(true), 5000);
  //   console.log('fire', counter)
  // }, []);

  const otpResend = () => {
    setResend(true);
    // dispatch(
    //   resendOTP({
    //     contact_no: parseInt(`91${company_data.contact_no}`),
    //   })
    // );
    setTimeout(() => {
      setResend(false);
    }, 25000);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="my-modal"
    >
      <Modal.Body
        style={{
          padding: "85px 15px 50px 15px",
          background: "white",
          borderRadius: "5px",
        }}
      >
        <CloseButton onClick={props.onHide}>×</CloseButton>
        <Row>
          <ModalLeftContentDiv md={12} lg={5} xl={5} sm={12}>
            <img
              src={`${
                process.env.REACT_APP_BASENAME !== "NA"
                  ? `/${process.env.REACT_APP_BASENAME}`
                  : ""
              }/assets/images/RFQ/security-otp-one-time-password-smartphone-shield_9904-104.png`}
              alt="otp"
            ></img>
          </ModalLeftContentDiv>
          <Col md={12} lg={7} xl={7} sm={12}>
            <div>
              <ModalRightContentDiv style={{ marginBottom: "25px" }}>
                <h2 style={{ fontSize: lessthan320 && "24px" }}>
                  Please enter the OTP
                </h2>
              </ModalRightContentDiv>
              <ModalRightContentDiv>
                <p>
                  OTP has been sent to <b>{`${props?.mobileNumber}`}</b> and{" "}
                  <b>{`${props?.email}`}</b>
                  &nbsp;&nbsp;
                  {/* <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    onClick={props.onHide}
                    style={{ cursor: "pointer" }}
                  ></i> */}
                </p>
              </ModalRightContentDiv>
              <ModalRightContentDiv
                style={{ marginLeft: lessthan320 && "15px" }}
                lessthan767={lessthan767}
              >
                <input
                  autoComplete="none"
                  name="otp1"
                  ref={otp1}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                />
                <input
                  autoComplete="none"
                  name="otp2"
                  ref={otp2}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                />
                <input
                  autoComplete="none"
                  name="otp3"
                  ref={otp3}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                />
                <input
                  autoComplete="none"
                  name="otp4"
                  ref={otp4}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                />
              </ModalRightContentDiv>
              <ModalRightContentDiv>
                {" "}
                <Button
                  onClick={otpEnter}
                  type="submit"
                  buttonStyle="outline-solid"
                  hex1={
                    Theme?.proposalProceedBtn?.hex1
                      ? Theme?.proposalProceedBtn?.hex1
                      : "#4ca729"
                  }
                  hex2={
                    Theme?.proposalProceedBtn?.hex2
                      ? Theme?.proposalProceedBtn?.hex2
                      : "#4ca729"
                  }
                  borderRadius="5px"
                  color="white"
                >
                  <text
                    style={{
                      fontSize: "15px",
                      padding: "-20px",
                      margin: "-20px -5px -20px -5px",
                      fontWeight: "400",
                    }}
                  >
                    Submit
                  </text>
                </Button>
              </ModalRightContentDiv>
              {/* <ModalRightContentDiv>
                <div
                  onClick={() => (!resend ? otpResend() : {})}
                  style={{ cursor: "pointer" }}
                >
                  Didn't receive your OTP?{" "}
                  <text className={resend ? "text-secondary" : "text-primary"}>
                    Resend OTP
                  </text>
                </div>
              </ModalRightContentDiv>
              <ModalRightContentDiv>
                <div
                  style={resend ? {} : { display: "none" }}
                  className={`${resend ? "resendShow" : ""}`}
                >
                  OTP has been sent again
                </div>
              </ModalRightContentDiv> */}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  width: 35px;
  height: 35px;
  // border-radius: 50%;
  color: #232222;
  text-shadow: none;
  opacity: 1;
  z-index: 1;
  border: none !important;
  font-size: 1.7rem;
  font-weight: 700;
  background: white;
  &:focus {
    outline: none;
  }
`;
const ModalRightContentDiv = styled.div`
  margin-bottom: 25px;
  @media (max-width: 991px) {
    input,
    textarea {
      transform: translateZ(0px) !important;
    }
    display: flex;
    justify-content: center;
    & .OTPDiv {
      margin-left: 0px !important;
    }
  }
  & h2 {
    font-weight: bold;
  }
  & p {
    font-size: 15px;
    color: #4e4d4d;
    line-height: 1.8rem;
    word-spacing: 1px;
    // text-align: center;
  }
  & input {
    height: ${(props) => (props?.lessthan767 ? "50px" : "60px")};
    width: ${(props) => (props?.lessthan767 ? "50px" : "60px")};
    margin-right: 25px;
    font-weight: bold;
    font-size: 20px;
    // border: none;
    text-align: center;
    box-shadow: rgb(35 34 34 / 25%) 0px 2px 6px 1px,
      rgb(0 0 0 / 10%) 1px 1px 0px 0px !important;
    @media (max-width: 991px) {
      margin-right: 10px;
    }
  }
  & .OTPDiv {
    margin-left: 55px;
    font-size: 15px;
    cursor: pointer;
  }
`;
const ModalLeftContentDiv = styled(Col)`
  padding-left: 0px;
  margin-top: -30px;
  & img {
    width: 280px;
  }
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    & img {
      width: 180px;
      height: 180px;
      object-fit: cover;
    }
  }
  @media (max-width: 320px) {
    & img {
      width: 130px;
      height: 120px;
      object-fit: cover;
    }
  }
`;

export default OTPPopup;
