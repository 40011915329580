import React from "react";
import "./calandar.css";
import _ from "lodash";
import { format, getDay } from "date-fns";
import moment from "moment";
import styled from "styled-components";

const monthArray = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const weekArray = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

export const CalandarCard = ({ dateStr, DatePicker, DateError, onClick }) => {
	const DateArray = dateStr
		? dateStr.split("-")
		: moment().format("DD-MM-YYYY").split("-");
	const DateNumber = !_.isEmpty(DateArray) && DateArray[0];
	const MonthNumber = !_.isEmpty(DateArray) && DateArray[1];
	const MonthName =
		MonthNumber && monthArray[Number(parseInt(MonthNumber, 10)) - 1];
	const Year = !_.isEmpty(DateArray) && DateArray[2];
	const DayName =
		!_.isEmpty(DateArray) &&
		moment(
			new Date(Year, Number(DateArray[1]) - 1, DateNumber),
			"YYYY-MM-DD HH:mm:ss"
		).format("dddd");

	return (
		<StyledDiv>
			<div className="calandar" onClick={onClick}>
				<div className="calandar-body">
					<span className="month-name">{MonthName || "month"}</span>
					{DatePicker()}
					<span className="day-name">{DayName || "Day"}</span>
					<span className="date-number">{DateNumber || "Date"}</span>
					<span className="year">{Year || "Year"}</span>
				</div>
			</div>
			{DateError}
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	.calandar {
		margin: auto;
		width: 260px;
		z-index: 9999;
		position: relative;
		cursor: pointer;
	}

	.calandar .calandar-body {
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255, 255, 255, 0.1);
		flex-direction: column;
		backdrop-filter: blur(0.5px);
		border-bottom: 6px solid rgb(10, 63, 3);
		border-right: 1px soid rgba(255, 255, 255, 0.1);
		box-shadow: 0 5px 25px rgb(1 1 1 / 15%);
	}

	.calandar .calandar-body .month-name {
		color: #fff;
		background: rgb(10, 63, 3);
		width: 100%;
		font-size: 1.6em;
		text-align: center;
		font-weight: 400;
		padding: 5px 0;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 50px;
	}

	.calandar .calandar-body .day-name {
		color: rgb(10, 63, 3);
		font-size: 1.2em;
		font-weight: 400;
		margin-top: -20px;
	}

	.calandar .calandar-body .date-number {
		color: rgb(10, 63, 3);
		font-size: 6em;
		font-weight: 600;
		line-height: 1.2em;
	}

	.calandar .calandar-body .year {
		color: rgb(10, 63, 3);
		font-size: 1.2em;
		font-weight: 400;
		margin-bottom: 20px;
	}

	.date-picker-ico-set {
		/* position: relative; */
		top: -46px;
		right: -100px;
	}
`;
