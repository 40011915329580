import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Loader } from "components";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile, reloadPage, Decrypt } from "utils";
import styled from "styled-components";
import { Prefill } from "modules/proposal/proposal.slice";
import { Prefill as PrefillHome } from "modules/Home/home.slice";
import _ from "lodash";

const JourneySuccess = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const FinalAmtEncrypted = query.get("xmc");
  const xmc = FinalAmtEncrypted ? window.atob(FinalAmtEncrypted) : "";
  const inspectionNo = query.get("inspection_no");
  const IC = query.get("IC");
  const dropout = query.get("dropout");
  const { type } = props?.match?.params;
  const { temp_data, error, clear } = useSelector((state) => state.proposal);

  const UrlFn = (ut) => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      case "UNILIGHT":
        return `https://www.policyongo.com/${ut ? "employee" : "pos"}/login`;
      case "CP":
        return `https://www.comparepolicy.com/`;
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return `http://preprod-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `http://abibl-prod-dashboard.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-gramcover.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `https://dashboard.gramcover.com/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === 'YES' ? "https://nammacover.com/" : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === 'YES' ? "https://live.pincnews.co.in/" : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/${
          ut ? "employee" : "customer"
        }/login`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/${
          ut ? "employee" : "pos"
        }/login`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return `https://www.bajajcapitalinsurance.com/`;
      case "UIB":
        return ``;
      default:
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
    }
  };

  useEffect(() => {
    if (enquiry_id && inspectionNo) {
      dispatch(Prefill({ enquiryId: enquiry_id }));
      dispatch(PrefillHome({ enquiryId: enquiry_id }));
      window.Android &&
        window.Android.SendToPaymentInspectionPage(inspectionNo);
    }
  }, [enquiry_id, inspectionNo]);

  return !_.isEmpty(temp_data) ? (
    <Row className="text-center w-100 mx-auto">
      <Top className="mx-auto" style={{ width: "50%" }}>
        <div className="mt-4 d-flex justify-content-center w-100">
          <img
            src={`${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/like.svg`}
            alt="errImg"
            height="100"
            width="100"
            className="success_image"
          />
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <h4
            className="text-center w-100 text-success font-weight-bold cong_text"
            style={{ fontSize: "2.3rem" }}
          >
            Congratulations!
          </h4>
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <p
            className="text-center w-100 proposal_text"
            style={{ fontSize: "1.2rem", color: "#006600" }}
          >
            {`Proposal has been submitted successfully. Premium has been evaluated based on the NCB declaration made. ${
              xmc ? `Final Payable premium is Rs. ${xmc}` : ``
            }.`}
          </p>
          <p
            className="text-center w-100 refId_text"
            style={{ fontSize: "1.2rem" }}
          >
            {`Your Inspection request with ${
              IC || "the insurance company"
            } is raised with ID/Reference ID ${inspectionNo}, You will receive an email / Whatsapp / SMS with the above reference id, please continue for the payment from the link provided in 
              the mail after the inspection approval..`}
          </p>
        </div>
        {((process.env.REACT_APP_BROKER === "OLA" &&
          temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
            "driver-app") ||
          process.env.REACT_APP_BROKER !== "OLA") && (
          <div className="mt-2 d-flex justify-content-center w-100">
            <Button
              buttonStyle="outline-solid"
              hex1="#006400"
              hex2="#228B22"
              borderRadius="25px"
              type="submit"
              shadow={"none"}
              onClick={() => [
                process.env.REACT_APP_BROKER === "OLA" &&
                temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
                  "driver-app"
                  ? reloadPage(
                      process.env.REACT_APP_API_BASE_URL ===
                        "https://api-ola-uat.fynity.in/api"
                        ? "https://auth-repose-azure.stg.corp.olacabs.com/olamoney/kyc-web/wallet/driver/crosssell-dashboard"
                        : "https://supply-api.olacabs.com/crosssell-dashboard"
                    )
                  : _.isEmpty(temp_data?.agentDetails) || process.env.REACT_APP_BROKER === 'RB'
                  ? reloadPage(
                      `${window.location.origin}${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ``
                      }/${
                        Number(temp_data?.productSubTypeId) === 1
                          ? "car"
                          : Number(temp_data?.productSubTypeId) === 2
                          ? "bike"
                          : "cv"
                      }/lead-page`
                    )
                  : //checking where to redirect
                  temp_data?.agentDetails?.find((o) => o?.sellerType === "E")
                  ? reloadPage(UrlFn("E"))
                  : reloadPage(UrlFn()),
                window.Android &&
                  window.Android.SendToHomePage("Redirecting to homepage"),
              ]}
            >
              Go To Homepage
            </Button>
          </div>
        )}
      </Top>
    </Row>
  ) : (
    <Loader />
  );
};

const Top = styled.div`
  font-family: ${({ theme }) => theme.Payment?.fontFamily || ""};
  font-weight: ${({ theme }) => theme.Payment?.fontWeight || ""};
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0 30px;
    .success_image {
      height: 50px;
      width: 50px;
    }
    .cong_text {
      font-size: 1.3rem !important;
    }
    .proposal_text {
      font-size: 0.8rem !important;
    }
    .refId_text {
      font-size: 0.8rem !important;
    }
  }
`;

export default JourneySuccess;
