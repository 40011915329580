export const fieldList = [
  "gstNumber",
  "maritalStatus",
  "occupation",
  "panNumber",
  "dob",
  "gender",
  "vehicleColor",
  "hypothecationCity",
  "cpaOptOut",
  "email",
  "pucNo",
  "pucExpiry",
  "representativeName",
  "cpaOptIn",
  "ncb",
];
