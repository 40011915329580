import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import tooltip from "../../../../assets/img/tooltip.svg";
import CustomTooltip from "../../../../components/tooltip/CustomTooltip";
import Popup from "../../../../components/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import _, { parseInt } from "lodash";
import { numOnly } from "utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMsg } from "components";
import "./idvPopup.scss";
import { setTempData } from "../../filterConatiner/quoteFilter.slice";
import { useMediaPredicate } from "react-media-hook";
import { currencyFormater } from "utils";
import { set_temp_data } from "modules/Home/home.slice";
import Drawer from "@mui/material/Drawer";
// const yupValidate = yup.object({
// 	customIdv: yup.string().required("IDV is required"),
// });

const IDVPopup = ({ show, onClose, idv, setIDV, quote, visualIdv }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, watch, control, errors, setValue } = useForm({
    // resolver: yupResolver(),
    // mode: "all",
    // reValidateMode: "onBlur",
  });
  const lessthan963 = useMediaPredicate("(max-width: 963px)");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const { tempData } = useSelector((state) => state.quoteFilter);
  const { quoteComprehesive, quotetThirdParty } = useSelector(
    (state) => state.quotes
  );

  const getLowestIdv = () => {
    let Min = _.minBy(quote, "minIdv");
    return parseInt(Min?.minIdv);
  };

  const getHighestIdv = () => {
    let Max = _.maxBy(quote, "maxIdv");
    return parseInt(Max?.maxIdv);
  };

  const getAverageIdv = () => {
    let filteredQuote = quote?.map((item) =>
      Number(item?.idv) ? Number(item?.idv) : 0
    );
    let newFilterQuote = filteredQuote.filter((cv) => cv != 0);

    let Avg = _.meanBy(newFilterQuote);

    return parseInt(Avg);
  };

  const SelectedIdv = watch("idvType");
  const CustomIdv = watch("customIdv");
  const [idvError, setIdvError] = useState(false);

  //validate custom IDV

  useEffect(() => {
    if (SelectedIdv === "ownIDV") {
      if (
        CustomIdv > getHighestIdv() ||
        CustomIdv < getLowestIdv() ||
        !CustomIdv
      ) {
        setIdvError("Please Enter IDV in specified Range");
      } else {
        setIdvError(false);
      }
    } else {
      setIdvError(false);
    }
  }, [SelectedIdv, CustomIdv]);

  const onSubmit = (data) => {
    //setIDV(data);

    dispatch(
      setTempData({
        idvChoosed: SelectedIdv === "ownIDV" ? CustomIdv : getIDV(SelectedIdv),
        idvType: SelectedIdv,
      })
    );

    dispatch(
      set_temp_data({
        isOdDiscountApplicable: "Y",
      })
    );

    onClose(false);
  };

  //prefill

  const getIDV = (idvType) => {
    switch (idvType) {
      case "avgIdv":
        return getAverageIdv();
      case "lowIdv":
        return getLowestIdv();
      case "highIdv":
        return getHighestIdv();
      default:
        return "0";
    }
  };

  //---drawer for mobile

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);

  const content = (
    <>
      <Conatiner>
        <Row>
          <PaymentTermTitle>
            Insured Value (IDV)
            <span
              className="cardTooltipSvg"
              data-toggle="popover"
              title=""
              data-content="Insured Value (IDV) Text"
              data-original-title="Insured Value (IDV)"
            >
              <CustomTooltip
                rider="true"
                id="RiderInbuilt__Tooltip"
                place={"bottom"}
                customClassName="mt-3 riderPageTooltip "
                allowClick
              >
                <img
                  data-tip="<h3 >Insured Value (IDV)</h3> <div>Insured Declared Value (IDV) is the maximum amount your insurer can provide you in case your car is stolen or totally damaged subject to IDV guidelines. Note: IDV should be 10% less than previous year's IDV, as per the depreciation norms of Indian Motor Tariff. Insurers consider the same for total loss or theft claims.</div>"
                  data-html={true}
                  data-for="RiderInbuilt__Tooltip"
                  src={tooltip}
                  alt="tooltip"
                  className="toolTipRiderChild"
                />
              </CustomTooltip>
            </span>
          </PaymentTermTitle>
          <PopupSubTitle>
            IDV is the maximum value that you get in case of total loss or theft
            of your vehicle.
          </PopupSubTitle>
          <PopupSubHead>Choose your IDV value:</PopupSubHead>
          <div className="paymentTermRadioWrap">
            <label className="panel-heading ratioButton IDVRatio">
              <input
                type="radio"
                className="idvInputClass"
                name="idvType"
                ref={register}
                value="lowIdv"
                defaultChecked={tempData?.idvType === "lowIdv" ? true : false}
              />
              <span className="checkmark"></span>
              <span
                className={`checkBoxTextIdv ${
                  SelectedIdv === `lowIdv` ? "txtCheckedBold" : ""
                }`}
              >
                Lowest IDV (starting from) : ₹{" "}
                {currencyFormater(getLowestIdv())}
              </span>
            </label>
          </div>

          <div className="paymentTermRadioWrap">
            <label className="panel-heading ratioButton IDVRatio">
              <input
                type="radio"
                className="idvInputClass"
                name="idvType"
                ref={register}
                value="highIdv"
                defaultChecked={tempData?.idvType === "highIdv" ? true : false}
              />
              <span className="checkmark"></span>
              <span
                className={`checkBoxTextIdv ${
                  SelectedIdv === `highIdv` ? "txtCheckedBold" : ""
                }`}
              >
                Highest IDV: ₹ {currencyFormater(getHighestIdv())}
              </span>
            </label>
          </div>
          <div className="paymentTermRadioWrap">
            <label className="panel-heading ratioButton IDVRatio">
              <input
                type="radio"
                className="idvInputClass"
                name="idvType"
                ref={register}
                value="ownIDV"
                defaultChecked={tempData?.idvType === "ownIDV" ? true : false}
              />
              <span className="checkmark"></span>
              <span
                className={`checkBoxTextIdv ${
                  SelectedIdv === "ownIDV" ? "txtCheckedBold" : ""
                }`}
              >
                Choose your own IDV
              </span>
            </label>
            {(SelectedIdv === "ownIDV" || tempData?.idvType === "ownIDV") && (
              <div className="paymentTermRadioContent">
                <InputFieldSmall>
                  <Form.Control
                    type="text"
                    placeholder="Eg. ₹ 11,21,280"
                    name="customIdv"
                    maxLength="50"
                    defaultValue={tempData?.idvType ? tempData?.idvChoosed : ""}
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? "" + e.target.value
                          : e.target.value)
                    }
                    errors={errors.customIdv}
                    size="sm"
                    onKeyDown={numOnly}
                  />
                  {idvError && (
                    <ErrorMsg fontSize={"12px"}>{idvError}</ErrorMsg>
                  )}
                </InputFieldSmall>
                <div className="idvCustmTxt">
                  Enter value between ₹ {getLowestIdv()} and ₹ {getHighestIdv()}
                </div>
              </div>
            )}
          </div>
          <div class="paymentTermRadioWrap">
            <ApplyButton
              disabled={idvError}
              onClick={() => [
                navigator &&
                  navigator?.vibrate &&
                  navigator.vibrate([100, 0, 50]),
                onSubmit(),
              ]}
            >
              APPLY
            </ApplyButton>
          </div>
        </Row>
      </Conatiner>
    </>
  );
  return !lessthan767 ? (
    <Popup
      height={"auto"}
      width="360px"
      show={show}
      onClose={onClose}
      content={content}
      position="center"
      top="45%"
      left={lessthan963 ? "50%" : "65%"}
    />
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          onClose={() => {
            setDrawer(false);
            onClose(false);
          }}
          onOpen={() => setDrawer(true)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MobileDrawerBody>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>
            {content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle />
    </>
  );
};

// PropTypes
IDVPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
IDVPopup.defaultProps = {
  show: false,
  onClose: () => {},
};

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 99999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 100000	!important;
  }
}
`;

const Conatiner = styled.div`
  padding: 20px 30px;
  .checkBoxTextIdv {
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  }
`;

const PaymentTermTitle = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 16px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: solid 1px #e3e4e8;
`;

const PopupSubTitle = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 14px;
  line-height: 20px;
  color: #333;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const PopupSubHead = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 14px;
  line-height: 17px;
  color: #333;
  margin-bottom: 12px;
`;

const ApplyButton = styled.button`
  width: 117px;
  height: 32px;
  border-radius: ${({ theme }) =>
    theme.QuoteBorderAndFont?.borderRadius || "50px"};
  background-color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme?.FilterConatiner?.clearAllTextColor || " #000"};
  /* text-transform: uppercase; */
  margin: 0;
  float: right;
`;

const InputFieldSmall = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  .form-control {
    display: block;
    font-size: 12px;
    width: 95%;

    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border-radius: 50px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control:active,
  .form-control:focus {
    border: solid 2px #000;
    border-radius: 0px !important;
  }
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;

export default IDVPopup;
