import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CompactCard, Button } from "components";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { clear, SetFields } from "modules/proposal/proposal.slice";
import _ from "lodash";
import Checkbox from "components/inputs/checkbox/checkbox";
import { fieldList } from "./helper";

export const FieldCard = ({ ic, owner_type, section, fields, Broker }) => {
  const { handleSubmit, register, setValue } = useForm();

  const dispatch = useDispatch();

  //prefill
  useEffect(() => {
    if (!_.isEmpty(fields)) {
      //setval Array
      const setVal = [
        fields.includes("gstNumber") && "gstNumber",
        fields.includes("maritalStatus") && "maritalStatus",
        fields.includes("occupation") && "occupation",
        fields.includes("panNumber") && "panNumber",
        fields.includes("dob") && "dob",
        fields.includes("gender") && "gender",
        fields.includes("vehicleColor") && "vehicleColor",
        fields.includes("hypothecationCity") && "hypothecationCity",
        fields.includes("cpaOptOut") && "cpaOptOut",
        fields.includes("email") && "email",
        fields.includes("pucNo") && "pucNo",
        fields.includes("pucExpiry") && "pucExpiry",
        fields.includes("representativeName") && "representativeName",
        fields.includes("cpaOptIn") && "cpaOptIn",
        fields.includes("ncb") && "ncb",
      ];
      setValue("fields", setVal);
    }
  }, [fields]);

  const onSubmit = (data) => {
    let request = {
      company_alias: ic,
      owner_type,
      section,
      fields: data?.fields.map((el) => (el ? el : 0)),
    };
    dispatch(SetFields(request, Broker));
  };

  const excludes = [
    "occupation",
    "maritalStatus",
    "dob",
    "gender",
    "cpaOptIn",
    "cpaOptOut",
  ];
  const excludesIndividual = ["representativeName"];

  return (
    <CompactCard title="Field List">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ marginTop: "-30px" }}>
          {fieldList.map((item, index) =>
            (!excludes.includes(item) || owner_type === "I") &&
            (!excludesIndividual.includes(item) || owner_type === "C") ? (
              <Col sm="6" md="4" lg="3" xl="3" className="my-1">
                <Checkbox
                  id={item}
                  register={register}
                  fieldName={_.capitalize(
                    item
                      .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
                      .replace(/_/gi, " ")
                  )}
                  index={index}
                  name={"fields"}
                  tooltipData={""}
                />
              </Col>
            ) : (
              <noscript />
            )
          )}
          <Col
            sm={12}
            lg={12}
            md={12}
            xl={12}
            className="d-flex justify-content-end mt-5 mx-auto"
          >
            <Button
              type="submit"
              buttonStyle="outline-solid"
              className=""
              hex1={"#4ca729"}
              hex2={"#4ca729"}
              borderRadius="5px"
              color="white"
            >
              <text
                style={{
                  fontSize: "15px",
                  padding: "-20px",
                  margin: "-20px -5px -20px -5px",
                  fontWeight: "400",
                }}
              >
                Save Fields
              </text>
            </Button>
          </Col>
        </Row>
      </Form>
    </CompactCard>
  );
};
