import React, { useEffect } from "react";
import { Card, Button } from "components";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { Row, Col, Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { themeConfig } from "modules/login/login.slice";
import _ from "lodash";
import Select from "react-select";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const Theme = () => {
  const { register, handleSubmit, watch, control, reset, setValue } = useForm({
    defaultValues: !_.isEmpty(theme) ? theme : {},
  });
  const dispatch = useDispatch();

  useEffect(() => {
    reset(theme);
  }, [theme]);

  const onSubmit = (data) => {
    dispatch(themeConfig({ theme_config: JSON.stringify({}) }));
    console.log("data", data);
  };

  const inputField = (label, name, defaultValue) => {
    return (
      <div className="mr-5">
        <Label>{label}</Label>
        <Input
          type="color"
          ref={register}
          name={name}
          // defaultValue={defaultValue}
        />
      </div>
    );
  };

  const MultiSelect = (label, name) => {
    return (
      <div className="mr-5">
        <Label>{label}</Label>
        <Container>
          <Controller
            name={name}
            control={control}
            render={({ field, onChange, onBlur, value, name }) => (
              <Select
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                ref={register}
                value={value}
                className="selectOption"
                {...field}
                options={[
                  { value: "primary", label: "Primary" },
                  { value: "success", label: "Success" },
                  { value: "info", label: "Info" },
                  { value: "warning", label: "Warning" },
                  { value: "Danger", label: "Danger" },
                  { value: "Light", label: "Light" },
                  { value: "dark", label: "Dark" },
                ]}
              />
            )}
          />
        </Container>
      </div>
    );
  };

  const HiddenInput = (name, defaultValue) => {
    return (
      <>
        <Input
          type="hidden"
          ref={register}
          name={name}
          // defaultValue={defaultValue}
        />
      </>
    );
  };

  return (
    <>
      <h2 className="text-center my-5">Theme Colors</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Proposal Card Start */}

        <Card title={"Proposal Card"}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Proposal Summary Background</SubHeading>
              <Container>
                {inputField("Color-1", "proposalCardSummary.gradient1")}
                {inputField("Color-2", "proposalCardSummary.gradient2")}
                {HiddenInput(
                  "proposalCardSummary.background",
                  `linear-gradient(90deg,${
                    watch("proposalCardSummary.gradient1") || "#95181A"
                  } 0%,${
                    watch("proposalCardSummary.gradient2") || "#95181A"
                  } 100%)`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Proposal Active Background</SubHeading>
              <Container>
                {inputField(
                  "Color-1",
                  "proposalCardActive.gradient1",
                  "#95181A"
                )}
                {inputField(
                  "Color-2",
                  "proposalCardActive.gradient1",
                  "#c7222a"
                )}
                {HiddenInput(
                  "proposalCardSummary.background",
                  `linear-gradient(90deg,${
                    watch("proposalCardActive.gradient1") || "#c7222a"
                  } 0%,${
                    watch("proposalCardActive.gradient2") || "#c7222a"
                  } 100%)`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Proceed Button</SubHeading>
              <Container>
                {inputField(
                  "Background-1",
                  "proposalProceedBtn.hex1",
                  "#C7222A"
                )}
                {inputField(
                  "Background-2",
                  "proposalProceedBtn.hex2",
                  "#c7222a"
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Button Group Toggle</SubHeading>
              <Container>
                {inputField(
                  "Background-1",
                  "genderProposal.gradient1",
                  "rgb(199 34 42)"
                )}
                {inputField(
                  "Background-2",
                  "genderProposal.gradient2",
                  "rgb(179 45 51)"
                )}
                {HiddenInput(
                  "genderProposal.background",
                  `radial-gradient(circle,${
                    watch("genderProposal.gradient1") || "rgb(199 34 42)"
                  } 17%,${
                    watch("genderProposal.gradient2") || "rgb(179 45 51)"
                  } 85%)`
                )}
                {inputField("Box Shadow", "genderProposal.shadowColor")}
                {HiddenInput(
                  "genderProposal.boxShadow",
                  `6.994px 5.664px 21px ${
                    watch("genderProposal.shadowColor") || "#ffffff"
                  }`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Questions</SubHeading>
              <Container>
                {inputField("Color", "questionsProposal.color", "#C7222A")}
                {inputField(
                  "Toggle Background",
                  "questionsProposal.toggleBackgroundColor",
                  "#C7222A"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Side Card</SubHeading>
              <Container>
                {MultiSelect("Icon", "sideCardProposal.icon", "#d9534f")}
                {MultiSelect("Badge", "sideCardProposal.badge", "#d9534f")}
                {MultiSelect(
                  "Edit Badge",
                  "sideCardProposal.editBadge",
                  "#d9534f"
                )}
                {inputField(
                  "LInk Color",
                  "sideCardProposal.linkColor",
                  "#C7222A"
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Proposal Header</SubHeading>
              <Container>
                {inputField("Color", "proposalHeader.color", "#000000")}
              </Container>
            </Col>
          </Row>
        </Card>
        {/* end of proposal card  */}

        {/* other buttons card start  */}
        <Card title={"Others Button"}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Default</SubHeading>
              <Container>
                {inputField("Background", "Button.default.background")}
                {inputField("Border Color", "Button.default.border_color")}
                {inputField("Text Color", "Button.default.text_color")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Danger</SubHeading>
              <Container>
                {inputField(
                  "Background",
                  "Button.danger.background",
                  "#ff8983"
                )}
                {inputField(
                  "Border Color",
                  "Button.danger.border_color",
                  "#1ad2a4"
                )}
                {inputField(
                  "Text Color",
                  "Button.danger.text_color",
                  "#ffffff"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Warning</SubHeading>
              <Container>
                {inputField(
                  "Background",
                  "Button.warning.background",
                  "#eebb4d"
                )}
                {inputField(
                  "Border Color",
                  "Button.warning.border_color",
                  "#eebb4d"
                )}
                {inputField(
                  "Text Color",
                  "Button.warning.text_color",
                  "#ffffff"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Outline</SubHeading>
              <Container>
                {inputField(
                  "Background",
                  "Button.outline.background",
                  "#ffffff"
                )}
                {inputField(
                  "Border Color",
                  "Button.outline.border_color",
                  "#cb68d9"
                )}
                {inputField(
                  "Text Color",
                  "Button.outline.text_color",
                  "#b406cc"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Square Outline</SubHeading>
              <Container>
                {inputField(
                  "Background",
                  "Button.square_outline.background",
                  "#ffffff"
                )}
                {inputField(
                  "Border Color",
                  "Button.square_outline.border_color",
                  "#CE93D8"
                )}
                {inputField(
                  "Text Color",
                  "Button.square_outline.text_color",
                  "#000000"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Outline Secondary</SubHeading>
              <Container>
                {inputField(
                  "Background",
                  "Button.outline_secondary.background",
                  "#efefef"
                )}
                {inputField(
                  "Border Color",
                  "Button.outline_secondary.border_color",
                  "#606060"
                )}
                {inputField(
                  "Text Color",
                  "Button.outline_secondary.text_color",
                  "#606060"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Outline Disabled</SubHeading>
              <Container>
                {inputField(
                  "Background",
                  "Button.submit_disabled.background",
                  "#efefef"
                )}
                {inputField(
                  "Border Color",
                  "Button.submit_disabled.border_color",
                  "#606060"
                )}
                {inputField(
                  "Text Color",
                  "Button.submit_disabled.text_color",
                  "#606060"
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Outline Solid</SubHeading>
              <Container>
                {inputField(
                  "Background-1",
                  "Button.outline_solid.background1",
                  "#0084f4"
                )}
                {inputField(
                  "Background-2",
                  "Button.outline_solid.background2",
                  "#00c48c"
                )}
                {inputField(
                  "Border Color",
                  "Button.outline_solid.border_color",
                  "#D0D0D0"
                )}
                {inputField(
                  "Text Color",
                  "Button.outline_solid.text_color",
                  "#ffffff"
                )}
              </Container>
            </Col>
          </Row>
        </Card>
        {/* end of other color  */}

        {/* quotes page start  */}
        <Card title={"Quotes Page Card"}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Products</SubHeading>
              <Container>
                {inputField("Color", "Products.color")}
                {inputField("Head Color", "Products.headColor")}
                {inputField("Border", "Products.borderColor")}
                {HiddenInput(
                  "Products.border",
                  `1px solid ${watch("Products.borderColor") || "#C7222A"}`
                )}
                {inputField("List Border", "Products.liBorderColor")}
                {HiddenInput(
                  "Products.liBorder",
                  `1px solid ${watch("Products.liBorderColor") || "#C7222A"}`
                )}
                {HiddenInput(
                  "CheckCircle.backgroundImage",
                  `url(${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/check-blue-circle.svg)`
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Tab</SubHeading>
              <Container>
                {inputField("Color", "Tab.color")}
                {inputField("Border", "Tab.borderColor")}
                {HiddenInput(
                  "Tab.border",
                  `1px solid ${watch("Tab.borderColor") || "#C7222A"}`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>CheckBox</SubHeading>
              <Container>
                {inputField("Color", "CheckBox.color")}
                {inputField("Border", "CheckBox.borderColor")}
                {HiddenInput(
                  "CheckBox.border",
                  `1px solid ${watch("CheckBox.borderColor") || "#000000"}`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Header</SubHeading>
              <Container>
                {inputField("Color", "Header.color", "#c7222a")}
                {inputField("Border", "Header.borderColor", "#c7222a")}
                {HiddenInput(
                  "Header.border",
                  `1px solid ${watch("Header.borderColor") || "#c7222a"}`
                )}
                {inputField("Border Logo", "Header.borderLogoColor", "#c7222a")}
                {HiddenInput(
                  "Header.borderLogo",
                  `2.5px solid ${watch("Header.borderLogoColor") || "#c7222a"}`
                )}
                {inputField("Background-1", "Header.hex1", "#c7222a")}
                {inputField("Background-2", "Header.hex2", "#c7222a")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Filter Container</SubHeading>
              <Container>
                {inputField("Color-1", "FilterConatiner.color", "#c7222a")}
                {inputField("Color-2", "FilterConatiner.lightColor", "#DA9089")}
                {inputField("Border-1", "FilterConatiner.liBorderColor")}
                {HiddenInput(
                  "FilterConatiner.lightBorder",
                  `.5px solid ${watch("Header.liBorderColor") || "#DA9089"}`
                )}
                {inputField("Border-2", "FilterConatiner.liBorderColor1")}
                {HiddenInput(
                  "FilterConatiner.lightBorder1",
                  `1px solid ${
                    watch("FilterConatiner.liBorderColor1") || "#DA9089"
                  }`
                )}
              </Container>
            </Col>

            {/* eod  */}

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Quote Card</SubHeading>
              <Container>
                {inputField("Color", "QuoteCard.color")}
                {inputField("Color-2", "QuoteCard.color2")}
                {inputField("Color-3", "QuoteCard.color3")}
                {inputField("Border", "QuoteCard.borderColor")}
                {HiddenInput(
                  "QuoteCard.border",
                  `1px solid ${watch("QuoteCard.borderColor") || "#c7222a"}`
                )}
                {inputField("Border2", "QuoteCard.borderColor2")}
                {HiddenInput(
                  "QuoteCard.border2",
                  `1px solid ${watch("QuoteCard.borderColor2") || "#8B151B"}`
                )}
                {inputField("Border CheckBox", "QuoteCard.borderCheckBoxColor")}
                {inputField("Box Shadow", "QuoteCard.shadowColor1")}
                {inputField("Box Shadow", "QuoteCard.shadowColor2")}
                {HiddenInput(
                  "QuoteCard.boxShadow",
                  `0 8px 25px -5px ${
                    watch("QuoteCard.shadowColor1") || "#DA9089"
                  }, 0 10px 10px -5px ${
                    watch("QuoteCard.shadowColor1") || "#DA9089"
                  }`
                )}
                {inputField(
                  "Mouse Hover Shadow",
                  "QuoteCard.mouseHoverShadowColor"
                )}
                {HiddenInput(
                  "QuoteCard.mouseHoverShadow",
                  `0px 0px 8px 0px ${
                    watch("QuoteCard.mouseHoverShadowColor") || "#DA9089"
                  }`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Quote Popups</SubHeading>
              <Container>
                {inputField("Color", "QuotePopups.color")}
                {inputField("Border", "QuotePopups.borderColor")}
                {HiddenInput(
                  "QuotePopups.border",
                  `1px solid ${watch("QuotePopups.borderColor") || "#c7222a"}`
                )}
                {inputField("Background-1", "QuotePopups.hex1")}
                {inputField("Background-2", "QuotePopups.hex2", "#c7222a")}
                {inputField("Color-2", "QuotePopups.color2", "#8B151B")}
                {inputField("Color-3", "QuotePopups.color3", "#8B151B")}
                {inputField("Border2", "QuotePopups.borderColor2", "#8B151B")}
                {HiddenInput(
                  "QuotePopups.border2",
                  `1px solid ${watch("QuotePopups.borderColor2") || "#8B151B"}`
                )}
                {inputField("Border3", "QuotePopups.borderColor3")}
                {HiddenInput(
                  "QuotePopups.border3",
                  `2px solid ${watch("QuotePopups.borderColor3") || "#c7222a"}`
                )}
                {inputField("Gradient-1", "QuotePopups.gradient1")}
                {inputField("Gradient-2", "QuotePopups.gradient2")}
                {HiddenInput(
                  "QuotePopups.lg",
                  `-webkit-linear-gradient(-134deg, ${
                    watch("QuotePopups.gradient1") || "#c7222a"
                  }, ${watch("QuotePopups.gradient2") || "#d9b7bb"})`
                )}
                {inputField("Popup Border", "QuotePopups.prePopBorderColor")}
                {HiddenInput(
                  "QuotePopups.prevpopBorder",
                  `33px solid ${
                    watch("QuotePopups.prePopBorderColor") || "#c7222a"
                  }`
                )}
              </Container>
            </Col>

            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Border and Font</SubHeading>
              <Container>
                {inputField("Link Color", "QuoteBorderAndFont.linkColor")}
                {inputField("Nav Color", "QuoteBorderAndFont.navColor")}
                {inputField(
                  "CheckBox Shadow-1",
                  "QuoteBorderAndFont.shadowCheckColor1"
                )}
                {inputField(
                  "CheckBox Shadow-2",
                  "QuoteBorderAndFont.shadowCheckColor2"
                )}
                {HiddenInput(
                  "QuoteBorderAndFont.shadowCheck",
                  `${
                    watch("QuoteBorderAndFont.shadowCheckColor1") ||
                    "rgba(0, 0, 0, 0.16)"
                  } 0px 10px 36px 0px, ${
                    watch("QuoteBorderAndFont.shadowCheckColor2") ||
                    "rgba(0, 0, 0, 0.06)"
                  } 0px 0px 0px 1px`
                )}
                {inputField("Font Color", "QuoteBorderAndFont.fontColor")}
                {inputField(
                  "Premium Change Background",
                  "QuoteBorderAndFont.moneyBackground",
                  "#EDC9C6"
                )}
                {inputField(
                  "Filter Shadow",
                  "QuoteBorderAndFont.filterShadowColor",
                  "#cfcfcf"
                )}
                {HiddenInput(
                  "QuoteBorderAndFont.filterShadow",
                  `0 9px 13px ${
                    watch("QuoteBorderAndFont.filterShadowColor") || "#cfcfcf"
                  }`
                )}
                {inputField(
                  "Toggle Color",
                  "QuoteBorderAndFont.checkBoxAndToggleBackground",
                  "#c7222a"
                )}
                {HiddenInput("QuoteBorderAndFont.borderRadius", "5px")}
                {HiddenInput(
                  "QuoteBorderAndFont.filterPropertyCheckBox",
                  "invert(22%) sepia(76%) saturate(2340%) hue-rotate(338deg) brightness(89%) contrast(99%)"
                )}
                {HiddenInput(
                  "QuoteBorderAndFont.fontFamily",
                  "pfhandbooksans_regular"
                )}
                {HiddenInput(
                  "QuoteBorderAndFont.fontFamilyBold",
                  "pfhandbooksans_medium"
                )}
                {HiddenInput("QuoteBorderAndFont.headerTopQuotesPage", "137")}
                {HiddenInput("QuoteBorderAndFont.toasterTop", "210px")}
                {HiddenInput("QuoteBorderAndFont.alertTop", "205px")}
                {/* Back button */}
                {HiddenInput("BackButton.backButtonTop", "190px")}
                {HiddenInput("BackButton.backButtonTopMobile", "160px")}
                {/* Review Card  */}
                {HiddenInput("ReviewCard.color", "#007bff")}
                {HiddenInput("ReviewCard.border", "#007bff")}
                {HiddenInput("ReviewCard.borderDashed", "#007bff")}
                {HiddenInput("ReviewCard.color2", "#007bff")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Filter Container</SubHeading>
              <Container>
                {inputField("Color", "toggleModal.color", "#033500")}
                {HiddenInput(
                  "toggleModal.walletImage",
                  `url(${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/wallet-1.svg)`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Previous Policy</SubHeading>
              <Container>
                {inputField("Color-1", "prevPolicy.color1", "#033500")}
                {inputField("Color-2", "prevPolicy.color2", "#033500")}
                {HiddenInput(
                  "prevPolicy.boxShadow",
                  "0 0 0 0.2rem rgb(225 83 97 / 50%)"
                )}
              </Container>
            </Col>
          </Row>
        </Card>
        {/* End of Quotes Card  */}

        {/* Home card start  */}
        <Card title={"Home Card"}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Avatar</SubHeading>
              <Container>
                {inputField("Color", "avatar.borderColor", "#c7222a")}
                {HiddenInput(
                  "avatar.border",
                  `2px solid ${"avatar.borderColor"}`
                ) || "#c7222a"}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>React Calender</SubHeading>
              <Container>
                {inputField("Selected", "reactCalendar.selected", "#c7222a")}
                {inputField(
                  "Background",
                  "reactCalendar.background",
                  "#c7222a"
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Lead Page Button</SubHeading>
              <Container>
                {inputField("Background", "leadPageBtn.selected", "#c7222a")}
                {inputField("Text Color", "leadPageBtn.textColor", "#ffffff")}
                {MultiSelect("Link", "leadPageBtn.link", "#d9534f")}
                {HiddenInput("leadPageBtn.borderRadius", "8px")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Registration</SubHeading>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  {"Proceed"}
                  <Container>
                    {inputField(
                      "Background",
                      "Registration.proceedBtn.background",
                      "#c7222a"
                    )}
                    {inputField(
                      "Color",
                      "Registration.proceedBtn.color",
                      "#ffffff"
                    )}
                  </Container>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  {"Other Button"}
                  <Container>
                    {inputField(
                      "Background-1",
                      "Registration.otherBtn.hex1",
                      "#c7222a"
                    )}
                    {inputField(
                      "Background-2",
                      "Registration.otherBtn.hex2",
                      "#c7222a"
                    )}
                  </Container>
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Vehicle Type</SubHeading>
              <Container>
                {MultiSelect(
                  "Button Variant",
                  "VehicleType.buttonVariant",
                  "#d9534f"
                )}
                {HiddenInput("Outline Variant", "VehicleType.outlineVariant")}
                {HiddenInput(
                  "VehicleType.filterIconCol",
                  "invert(42%) sepia(93%) saturate(1352%) hue-rotate(324deg) brightness(90%) contrast(119%)"
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Stepper</SubHeading>
              {"Stepper Color"}
              <Container>
                {inputField(
                  "Background",
                  "Stepper.stepperColor.background",
                  "#C7222A"
                )}
                {MultiSelect("Link Color", "Stepper.linkColor")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Tile</SubHeading>
              <Container>
                {inputField("BoxShadow", "Tile.boxShadowColor")}
                {HiddenInput(
                  "Tile.boxShadow",
                  `0px 0px 7px 0px ${watch("Tile.boxShadowColor") || "#C7222A"}`
                )}
                {inputField("Border", "Tile.borderColor", "#C7222A")}
                {HiddenInput(
                  "Tile.border",
                  `2px solid ${watch("Tile.borderColor") || "#C7222A"}`
                )}
                {inputField("Color", "Tile.color", "#C7222A")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Vehicle Proceed</SubHeading>
              <Container>
                {inputField("Background-1", "VehicleProceed.hex1", "#054f6c")}
                {inputField("Background-2", "VehicleProceed.hex2", "#085572")}
                {/* journey Type  */}
                {HiddenInput("journeyType.buttonVariant", "#d9534f")}
                {HiddenInput("journeyType.outlineVariant", "#df4759")}
                {HiddenInput("journeyType.filterIconCol", "none")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Home Text Color</SubHeading>
              <Container>
                {inputField(
                  "backgroundColor",
                  "Home.backgroundColor",
                  "#ffffff"
                )}
                {HiddenInput(
                  "regularFont.fontFamily",
                  "pfhandbooksans_regular"
                )}
                {HiddenInput("mediumFont.fontFamily", "pfhandbooksans_medium")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Float Button</SubHeading>
              <Container>
                {inputField("Float Color", "floatButton.floatColor", "#C7222A")}
                {inputField(
                  "Float Border",
                  "floatButton.floatBorderColor",
                  "#C7222A"
                )}
                {HiddenInput(
                  "floatButton.floatBorder",
                  `1px solid ${
                    watch("floatButton.floatBorderColor") || "#C7222A"
                  }`
                )}
                {inputField(
                  "White Color Color",
                  "floatButton.whiteColor",
                  "#ffffff"
                )}
              </Container>
            </Col>
          </Row>
        </Card>
        {/* end of Home color  */}

        {/* Compare card start  */}
        <Card title={"Compare Page Card"}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Compare Page</SubHeading>
              <Container>
                {inputField("Color", "comparePage.color")}
                {inputField("Border", "comparePage.borderColor")}
                {HiddenInput(
                  "comparePage.border",
                  `1px solid ${
                    watch("comparePage.borderColor") || "#c7222a"
                  } !important`
                )}
                {inputField("Text Color", "comparePage.textColor")}
                {/* paymentConfirmation  */}
                {HiddenInput(
                  "paymentConfirmation.headers",
                  "linear-gradient(81.67deg,#C7222A 0%,#ffffcc 100%)"
                )}
                {HiddenInput("paymentConfirmation.Button.hex1", "#C7222A")}
                {HiddenInput("paymentConfirmation.Button.hex2", "#C7222A")}
                {HiddenInput("paymentConfirmation.table", "#df4759")}
                {HiddenInput("paymentConfirmation.buttonTextColor", "#ffffff")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Lead Page Button</SubHeading>
              <Container>
                {inputField("Background", "leadPageBtn.selected", "#c7222a")}
                {inputField("Text Color", "leadPageBtn.textColor", "#ffffff")}
                {MultiSelect("Link", "leadPageBtn.link", "#d9534f")}
                {HiddenInput("leadPageBtn.borderRadius", "8px")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Registration</SubHeading>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  {"Proceed"}
                  <Container>
                    {inputField(
                      "Background",
                      "Registration.proceedBtn.background",
                      "#c7222a"
                    )}
                    {inputField(
                      "Color",
                      "Registration.proceedBtn.color",
                      "#ffffff"
                    )}
                  </Container>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  {"Other Button"}
                  <Container>
                    {inputField(
                      "Background-1",
                      "Registration.otherBtn.hex1",
                      "#c7222a"
                    )}
                    {inputField(
                      "Background-2",
                      "Registration.otherBtn.hex2",
                      "#c7222a"
                    )}
                  </Container>
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Vehicle Type</SubHeading>
              <Container>
                {MultiSelect(
                  "Button Variant",
                  "VehicleType.buttonVariant",
                  "#d9534f"
                )}
                {/* {MultiSelect("Outline Variant", "VehicleType.outlineVariant", "#d9534f")} outline Danger  */}
                {HiddenInput(
                  "VehicleType.filterIconCol",
                  "invert(42%) sepia(93%) saturate(1352%) hue-rotate(324deg) brightness(90%) contrast(119%)"
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Stepper</SubHeading>
              {"Stepper Color"}
              <Container>
                {inputField(
                  "Background",
                  "Stepper.stepperColor.background",
                  "#C7222A"
                )}
                {MultiSelect("Link Color", "Stepper.linkColor", "#d9534f")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Tile</SubHeading>
              <Container>
                {inputField("BoxShadow", "Tile.boxShadow", "#c7222a")}
                {inputField("Border", "Tile.border", "#C7222A")}
                {inputField("Color", "Tile.color", "#C7222A")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Vehicle Proceed</SubHeading>
              <Container>
                {inputField("Background-1", "VehicleProceed.hex1", "#054f6c")}
                {inputField("Background-2", "VehicleProceed.hex2", "#085572")}
                {/* journey Type  */}
                {HiddenInput("journeyType.buttonVariant", "#d9534f")}
                {HiddenInput("journeyType.outlineVariant", "#df4759")}
                {HiddenInput("journeyType.filterIconCol", "none")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Secondary Compare</SubHeading>
              <Container>
                {inputField("Background", "comparePage2.background", "#fb6b45")}
                {inputField("Border Bottom", "comparePage2.borderBottomColor")}
                {HiddenInput(
                  "comparePage2.borderBottom",
                  `1px solid ${
                    watch("comparePage2.borderBottomColor") || "#c7222a"
                  }`
                )}
                {inputField("Icons Color", "comparePage2.iconsColor")}
                {inputField("Border Header", "comparePage2.borderHeaderColor")}
                {HiddenInput(
                  "comparePage2.borderHeader",
                  `5px solid ${
                    watch("comparePage2.borderHeaderColor") || "#C7222A"
                  }`
                )}
                {inputField("Gradient-1", "comparePage2.gradient1")}
                {inputField("Gradient-2", "comparePage2.gradient2")}
                {HiddenInput(
                  "comparePage2.lg",
                  `-webkit-linear-gradient(-134deg, ${
                    watch("comparePage2.gradient1") || "#ffffff"
                  }, ${watch("comparePage2.gradient2") || "rgb(52, 91, 120)"})`
                )}
              </Container>
            </Col>
          </Row>
        </Card>
        {/* end of compare page  */}

        {/* other and popup card start  */}
        <Card title={"Other and Popup Card"}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Call Us</SubHeading>
              <Container>
                {inputField("Icon", "CallUs.icon", "#C7222A")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Payment Status</SubHeading>
              <Container>
                {inputField("Background-1", "PaymentStatus.hex1")}
                {inputField("Background-1", "PaymentStatus.hex1")}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Card Popup</SubHeading>
              <Container>
                {inputField("Background", "CardPop.background")}
                {inputField("Border", "CardPop.borderColor")}
                {HiddenInput(
                  "CardPop.border",
                  `1px solid ${watch("CardPop.borderColor") || "#EDC9C6"}`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>No Plan Card</SubHeading>
              <Container>
                {inputField("Background", "NoPlanCard.background")}
                {inputField("Border", "NoPlanCard.borderColor")}
                {HiddenInput(
                  "NoPlanCard.border",
                  `2px dotted ${watch("NoPlanCard.borderColor") || "#EDC9C6"}`
                )}
              </Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>MultiSelect</SubHeading>
              <Container>{inputField("Color", "MultiSelect.color")}</Container>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <SubHeading>Landing Page</SubHeading>
              <Container>
                {inputField("color", "LandingPage.color")}
                {inputField("color-2", "LandingPage.color2")}
                {inputField("color-3", "LandingPage.color3")}
                {inputField("Login Button Color", "LandingPage.loginBtnColor")}
                {HiddenInput(
                  "LandingPage.filter",
                  "invert(97%) sepia(83%) saturate(3866%) hue-rotate(65deg) brightness(80%) contrast(98%)"
                )}
                {HiddenInput("LandingPage.fontFamily", "sans-serif")}
                {HiddenInput("LandingPage.fontWeight", "fontWeight")}
              </Container>
            </Col>
          </Row>
        </Card>
        {/* end of other and popup page  */}
        <input type="submit" value="Apply Now" />
      </form>
    </>
  );
};

export default Theme;

const Container = styled.div`
  width: 100%;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  .selectOption {
    width: 200px;
  }
`;

const SubHeading = styled.h5`
  margin-top: 20px;
  // text-align: center;
`;

const Label = styled.label`
  display: block;
  font-weight: 400;
`;

const Input = styled.input`
  width: 200px;
  height: 50px;
  cursor: pointer;
  border-radius: 0 15px 0 15px;
`;
