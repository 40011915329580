import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  InputBorder,
  CustomControl,
  SwitchContainer,
  SwitchInput,
  FormLabel,
  SpanLabel,
  Img,
  ToggleValues,
} from "./style";

const Switch = ({ onChange, value, label, dark, Content, lessthan600 }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    if (onChange) onChange(Number(e.target.checked));
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    if (value) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [value]);

  return (
    <>
      <InputBorder>
        <CustomControl>
          <SwitchContainer>
            <ToggleValues
              onClick={() =>
                document.getElementById("toggle") &&
                document.getElementById("toggle").click()
              }
            >
              {Content}
            </ToggleValues>
            <label>
              <SwitchInput
                dark={dark}
                checked={isChecked}
                // checked={false}
                onChange={handleChange}
                type="checkbox"
                id="toggle"
              />
              <div>
                <div style={{ transition: "all 0.5s" }}></div>
              </div>
            </label>
          </SwitchContainer>

          <span>
            {isChecked ? (
              <span
                className="toggleTextNo"
                // style={lessthan600 ? { fontWeight: "600" } : {}}
                style={{
                  fontWeight:
                    process.env.REACT_APP_BROKER === "RB" ||
                    process.env.REACT_APP_BROKER === "PINC"
                      ? "500"
                      : "800",
                  fontSize: "12px",
                }}
                onClick={() => {
                  document.getElementById(`toggle`).click();
                }}
              >
                YES
              </span>
            ) : (
              <span
                className="toggleTextYes"
                // style={lessthan600 ? { fontWeight: "600" } : {}}
                style={{
                  fontWeight:
                    process.env.REACT_APP_BROKER === "RB" ||
                    process.env.REACT_APP_BROKER === "PINC"
                      ? "500"
                      : "800",
                  fontSize: "12px",
                }}
                onClick={() => {
                  document.getElementById(`toggle`).click();
                }}
              >
                NO
              </span>
            )}
          </span>
        </CustomControl>

        {/* top label */}
      </InputBorder>
    </>
  );
};

// default props
Switch.defaultProps = {
  value: 0,
  label: "",
};

// props types
Switch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default Switch;
