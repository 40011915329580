import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Loader, FloatButton } from "components";
import { Button as Btn } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  PolicyGen,
  clear,
} from "modules/payment-gateway/payment-gateway.slice";
import swal from "sweetalert";
import { downloadFile, reloadPage } from "utils";
import { Url, Prefill } from "modules/proposal/proposal.slice";
import _ from "lodash";
import { ShareQuote, TriggerWhatsapp } from "modules/Home/home.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import "./btn.css";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const JourneySuccess = () => {
  const dispatch = useDispatch();
  const { policy, error, loading, policyLoading } = useSelector(
    (state) => state.payment
  );
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const { temp_data } = useSelector((state) => state.proposal);
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const CardData = !_.isEmpty(temp_data)
    ? temp_data?.userProposal?.additonalData
      ? temp_data?.userProposal?.additonalData
      : {}
    : {};
  //on success script
  useEffect(() => {
    if (
      !_.isEmpty(temp_data) &&
      process.env.REACT_APP_BROKER === "GRAM"
      //&&
      // (!_.isEmpty(policy) || !policyLoading)
    ) {
      let respData = {
        //user details
        userFname: temp_data?.firstName,
        userLname: temp_data?.lastName,
        userMobile: temp_data?.userMobile,
        emailId: temp_data?.emailId,
        //agent details
        agentDetails:
          !_.isEmpty(temp_data?.agentDetails) &&
          temp_data?.agentDetails &&
          temp_data?.agentDetails?.filter((el) => el?.sellerType !== "U"),
        //policy details
        policyStartDate: temp_data?.userProposal?.policyStartDate,
        policyEndDate: temp_data?.userProposal?.policyEndDate,
        policyNumber: policy?.policyNumber,
        proposalNumber: policy?.proposalNumber,
        //pdf url
        ...(policy?.pdfUrl && { pdfUrl: policy?.pdfUrl }),
        //IC details
        ic: temp_data?.selectedQuote?.companyName,
        icLogo: temp_data?.selectedQuote?.companyLogo,
        //product details
        productName: temp_data?.selectedQuote?.productName,
        transactionId: enquiry_id,
        ...(policy?.final_payable_amount && {
          final_payable_amount: policy?.final_payable_amount,
        }),
      };
      console.log(respData);
      window?.Android &&
        window.Android.SendToPaymentSuccessPage(JSON.stringify(respData));
    }
  }, [policy]);

  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola-dashboard.fynity.in/";
      case "UNILIGHT":
        return "https://www.policyongo.com/pos/login";
      case "CP":
        return "https://www.comparepolicy.com/";
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return "http://preprod-dasbhoard-abibl.fynity.in/";
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-abibl.fynity.in/";
        } else {
          return "http://abibl-prod-dashboard.fynity.in/";
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-gramcover.fynity.in/";
        } else {
          return "https://dashboard.gramcover.com/";
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://live.pincnews.co.in/"
          : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return `https://www.bajajcapitalinsurance.com/`;
      case "UIB":
        return ``;
      default:
        return "https://ola-dashboard.fynity.in/";
    }
  };

  const ContentFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> insurance@olacabs.com</b> or call us at our number
            <b> 7829-41-1222</b>
          </>
        );
      case "UNILIGHT":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> Support@policyongo.com</b> or call us at our number
            <b> 1800-210-1225</b>
          </>
        );
      case "CP":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> help@comparepolicy.com</b> or call us at our number
            <b> 1800-12000-0055</b>
          </>
        );
      case "FYNTUNE":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> help@fyntune.com</b> or call us at our number
            <b> 1800-12000-0065</b>
          </>
        );
      case "ABIBL":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> help@abibl.com</b> or call us at our number
            <b> 1800-270-7000</b>
          </>
        );
      case "GRAM":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> info@gramcover.com</b> or call us at our number
            <b> +91 9311672463</b>
          </>
        );
      case "ACE":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> operations@aceinsurance.com</b> or call us at our number
            <b> +918750881122</b>
          </>
        );
      case "SRIYAH":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> care@nammacover.com</b> or call us at our number
            <b> +1800 203 0504</b>
          </>
        );
      case "PINC":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> info@pincinsure.com</b> or call us at our number
            <b> 18002672670 </b>
          </>
        );
      case "EPOCH":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> help@fyntune.com</b> or call us at our number
            <b> 1800-12000-0065</b>
          </>
        );
      case "RB":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> customersupport@renewbuy.com</b> or call us at our number
            <b> 18004197852</b>
          </>
        );
      case "SPA":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> care@insuringall.com</b> or call us at our number
            <b> +91-11-45675555</b>
          </>
        );
      case "BIMA":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> contact@bimaplanner.com</b> or call us at our number
            <b> 9999366494</b>
          </>
        );
      case "ANALAH":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> contactus@analahinsurance.com</b> or call us at our number
            <b> +91 9920878181</b>
          </>
        );
      case "BAJAJ":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> info@bajajcapitalinsurance.com</b> or call us at our number
            <b> 1800 212 123123</b>
          </>
        );
      case "UIB":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> services@uibindia.com</b>
          </>
        );
      default:
        return (
          <>
            In case of any further requirements, please contact us at
            <b> insurance@olacabs.com</b> or call us at our number
            <b> 7829-41-1222</b>
          </>
        );
    }
  };

  // const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const getLogoUrl = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/ola.png`;
      case "UNILIGHT":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/unilight.png`;
      case "CP":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/cp.png`;
      case "FYNTUNE":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/fyntune.png`;
      case "ABIBL":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/abiblPdf.jpeg`;
      case "GRAM":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/gc.png`;
      case "ACE":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/ace.png`;
      case "SRIYAH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/sriyah.png`;
      case "PINC":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/pinc.png`;
      case "EPOCH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/epochlogo.png`;
      case "RB":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/rb.png`;
      case "SPA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/insuringall.jpeg`;
      case "BIMA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bima.png`;
      case "ANALAH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/analah.jpeg`;
      case "BAJAJ":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bajaj.png`;
      case "UIB":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/uib.png`;
      default:
        break;
    }
  };

  //load prefill data
  useEffect(() => {
    if (enquiry_id) dispatch(Prefill({ enquiryId: enquiry_id }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //email - trigger
  useEffect(() => {
    if (
      enquiry_id &&
      CardData?.owner?.email &&
      temp_data?.selectedQuote?.productName &&
      policy?.policyNumber
    )
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "email",
          domain: `http://${window.location.hostname}`,
          type: "paymentSuccess",
          emailId: CardData?.owner?.email,
          firstName: CardData?.owner?.firstName,
          lastName: CardData?.owner?.lastName,
          action: window.location.href,
          link: window.location.href,
          productName: temp_data?.selectedQuote?.productName,
          policyNumber: policy?.policyNumber,
          logo: getLogoUrl(),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data, policy]);

  //mobile - trigger
  useEffect(() => {
    if (
      enquiry_id &&
      CardData?.owner?.mobileNumber &&
      temp_data?.selectedQuote?.productName &&
      policy?.policyNumber
    )
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "sms",
          domain: `http://${window.location.hostname}`,
          type: "policyGeneratedSms",
          emailId: CardData?.owner?.email,
          mobileNo: CardData?.owner?.mobileNumber,
          firstName: CardData?.owner?.firstName,
          lastName: CardData?.owner?.lastName,
          action: window.location.href,
          link: window.location.href,
          productName: temp_data?.selectedQuote?.productName,
          policyNumber: policy?.policyNumber,
          logo: getLogoUrl(),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data, policy]);

  //whatsapp - trigger
  useEffect(() => {
    if (enquiry_id && CardData?.owner?.mobileNumber)
      process.env.REACT_APP_BROKER === "OLA" &&
        dispatch(
          TriggerWhatsapp({
            enquiryId: enquiry_id,
            domain: `http://${window.location.hostname}`,
            type: "paymentSuccess",
            notificationType: "whatsapp",
            firstName: CardData?.owner?.firstName,
            lastName: CardData?.owner?.lastName,
            mobileNo: `91${CardData?.owner?.mobileNumber}`,
            to: `91${CardData?.owner?.mobileNumber}`,
            url: window.location.href,
            action: window.location.href,
            link: window.location.href,
            logo: getLogoUrl(),
          })
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  //Url
  // useEffect(() => {
  //   if (enquiry_id)
  //     dispatch(
  //       Url({
  //         proposalUrl: window.location.href,
  //         quoteUrl: window.location.href
  //           ? window.location.href?.replace(/payment-success/g, "quotes")
  //           : "",
  //         userProductJourneyId: enquiry_id,
  //       })
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [enquiry_id]);

  useEffect(() => {
    if (enquiry_id && !_.isEmpty(temp_data)) {
      dispatch(PolicyGen({ userProductJourneyId: enquiry_id }));
    } else {
      !enquiry_id &&
        swal("Error", "No enquiry id found", "error").then(() =>
          reloadPage(UrlFn())
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data]);

  useEffect(() => {
    if (error) {
      swal(
        "Error",
        `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //onload previous data clear
  useEffect(() => {
    dispatch(clear("pdf"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //policy pdf download
  useEffect(() => {
    if (policy?.pdfUrl) {
      downloadFile(`${policy?.pdfUrl}&download=true`, false, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy?.pdfUrl]);

  return !_.isEmpty(temp_data) && !loading ? (
    <>
      <Row className="text-center w-100 mx-auto">
        <Top className="mx-auto" style={{ width: "50%" }}>
          <div className="mt-4 d-flex justify-content-center w-100">
            <img
              src={`${
                process.env.REACT_APP_BASENAME !== "NA"
                  ? `/${process.env.REACT_APP_BASENAME}`
                  : ""
              }/assets/images/like.svg`}
              alt="errImg"
              height="100"
              width="100"
              className="success_image"
            />
          </div>
          <div className="mt-4 d-flex flex-column justify-content-center w-100">
            <h4
              className="text-center w-100 text-success font-weight-bold cong_text"
              style={{ fontSize: "2.3rem" }}
            >
              Congratulations!
            </h4>
          </div>
          <div className="mt-4 d-flex flex-column justify-content-center w-100">
            <p
              className="text-center w-100 policy_text"
              style={{ fontSize: "1.1rem", color: "#006600" }}
            >
              {`Your${
                temp_data?.selectedQuote?.companyName
                  ? ` ${temp_data?.selectedQuote?.companyName}`
                  : ""
              } policy${
                policy?.policyNumber || policy?.proposalNumber
                  ? ` with ${
                      policy?.policyNumber ? "policy" : "proposal"
                    } number "${
                      policy?.policyNumber || policy?.proposalNumber
                    }"`
                  : ""
              } has been issued & a soft copy will be shared to your registered email address shortly.`}
            </p>
            <p
              className="text-center w-100 mt-2 content_text email_contact_text"
              style={{ fontSize: "1.1rem" }}
            >
              {ContentFn()}
            </p>
            <Thank
              className="text-center w-100 mt-2 thank_text"
              style={{ fontSize: "1.1rem" }}
            >
              Thank you for contacting us.
            </Thank>
          </div>
          {policy?.pdfUrl &&
          temp_data?.corporateVehiclesQuoteRequest?.journeyType !==
            "driver-app" ? (
            <div
              className="mb-4 d-flex justify-content-center w-100"
              style={{ marginTop: "-10px" }}
            >
              <Btn
                variant="link"
                onClick={() => {
                  downloadFile(policy?.pdfUrl, false, true);
                }}
              >
                If download doesn't start automatically then click here.
              </Btn>
            </div>
          ) : (
            <div
              className="mb-4 d-flex justify-content-center w-100"
              style={{ marginTop: "-10px" }}
            >
              <StyleP
                lessthan767={lessthan767}
                // style={{ color: "black", fontSize: lessthan767 && "0.7rem" }}
                // variant="link"
                // onClick={() => {
                //   downloadFile(policy?.pdfUrl, false, true);
                // }}
              >
                Thank You for the transaction. Your policy will be generated
                within 48 - 72 hours.
              </StyleP>
            </div>
          )}
          {((process.env.REACT_APP_BROKER === "OLA" &&
            temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
              "driver-app") ||
            process.env.REACT_APP_BROKER !== "OLA") && (
            <div className="mt-2 d-flex justify-content-center w-100">
              <Button
                buttonStyle="outline-solid"
                hex1={
                  Theme?.proposalProceedBtn?.hex1
                    ? Theme?.proposalProceedBtn?.hex1
                    : "#4ca729"
                }
                hex2={
                  Theme?.proposalProceedBtn?.hex2
                    ? Theme?.proposalProceedBtn?.hex2
                    : "#4ca729"
                }
                borderRadius={
                  Theme?.QuoteBorderAndFont?.borderRadius
                    ? Theme?.QuoteBorderAndFont?.borderRadius
                    : "25px"
                }
                type="submit"
                shadow={"none"}
                onClick={() => [
                  process.env.REACT_APP_BROKER === "OLA" &&
                  temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
                    "driver-app"
                    ? reloadPage(
                        process.env.REACT_APP_API_BASE_URL ===
                          "https://api-ola-uat.fynity.in/api"
                          ? "https://auth-repose-azure.stg.corp.olacabs.com/olamoney/kyc-web/wallet/driver/crosssell-dashboard"
                          : "https://supply-api.olacabs.com/crosssell-dashboard"
                      )
                    : _.isEmpty(policy?.redirection_data) ||
                      process.env.REACT_APP_BROKER === "RB"
                    ? reloadPage(
                        `${window.location.origin}${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ``
                        }/${
                          Number(temp_data?.productSubTypeId) === 1
                            ? "car"
                            : Number(temp_data?.productSubTypeId) === 2
                            ? "bike"
                            : "cv"
                        }/lead-page`
                      )
                    : reloadPage(
                        policy?.redirection_data?.P ||
                          policy?.redirection_data?.E ||
                          UrlFn()
                      ),
                  window.Android &&
                    window.Android.SendToHomePage("Redirecting to homepage"),
                ]}
              >
                Go To Homepage
              </Button>
            </div>
          )}
          {process.env.REACT_APP_BROKER !== "OLA" && false && (
            <Col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              className="d-flex justify-content-center mt-3 noOutLine"
            >
              <Btn
                className="linkLine1"
                variant={"link"}
                type="button"
                onClick={() => {
                  history.push(
                    `/feedback?enquiry_id=${enquiry_id}&&dropout=yes`
                  );
                }}
              >
                Provide Feedback
              </Btn>
            </Col>
          )}
        </Top>
        <GlobalStyle />
      </Row>
      <FloatButton />
    </>
  ) : (
    <Loader />
  );
};

const GlobalStyle = createGlobalStyle`
.btn-link{
  color: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB" && theme?.Payment?.color}!important;

}
.email_contact_text{
  color : ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB" &&
    theme.floatButton?.floatColor &&
    theme.floatButton?.floatColor}!important;

}
`;
const StyleP = styled.p`
  color: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB" && theme?.floatButton?.floatColor
      ? theme.floatButton?.floatColor
      : "black"}!important;
  font-size: ${({ lessthan767 }) => lessthan767 && "0.7rem"};
`;
const Top = styled.div`
  font-family: ${({ theme }) => theme.Payment?.fontFamily || ""};
  font-weight: ${({ theme }) => theme.Payment?.fontWeight || ""};
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0 30px;
    .success_image {
      height: 50px;
      width: 50px;
    }
    .cong_text {
      font-size: 1.3rem !important;
    }
    .policy_text {
      font-size: 0.9rem !important;
    }
    .content_text {
      font-size: 0.8rem !important;
    }
    .thank_text {
      font-size: 0.8rem !important;
    }
    .linkLine1 {
      font-size: 0.8rem !important;
    }
  }
`;

const Thank = styled.p`
  color: ${({ theme }) => theme.Payment?.color || "rgb(189,212,0)"};
`;

export default JourneySuccess;
