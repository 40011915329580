import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Product from "../Product/Product";
import "./compare-products-list.scss";
import _ from "lodash";
import { useLocation } from "react-router";
import { setCompareQuoteFull } from "../../quotesPage/quote.slice";
import { currencyFormater, Encrypt } from "utils";
import {
  setComparePdfDatad,
  setValidQuotes,
} from "../../quotesPage/quote.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import styled from "styled-components";
import moment from "moment";
import { differenceInYears } from "date-fns";
import { toDate } from "utils";
import { useMediaPredicate } from "react-media-hook";
import { TypeReturn } from "modules/type";

function CompareProductsList({
  compareQuotes,
  type,
  setPrevPopup,
  prevPopup,
  setSelectedId,
  setSelectedCompanyName,
  setSelectedIcId,
  setSelectedCompanyAlias,
  setApplicableAddonsLits,
  scrollPosition,
  quoteComprehesive,
}) {
  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const { addOnsAndOthers, shortTerm, selectedTab } = useSelector(
    (state) => state.quotes
  );
  const { temp_data } = useSelector((state) => state.home);
  /// grouping addon based for private car

  const [quoteComprehesiveGrouped, setQuoteComprehesiveGrouped] =
    useState(quoteComprehesive);

  const [quoteComprehesiveGrouped1, setQuoteComprehesiveGrouped1] = useState(
    []
  );

  const GetValidAdditionalKeys = (additional) => {
    var y = Object.entries(additional)
      .filter(([, v]) => Number(v) > 0)
      .map(([k]) => k);
    return y;
  };

  const getAddonName = (addon) => {
    switch (addon) {
      case "roadSideAssistance":
        return "Road Side Assistance";
      case "zeroDepreciation":
        return "Zero Depreciation";
      case "imt23":
        return "IMT - 23";
      case "keyReplace":
        return "Key Replacement";
      case "engineProtector":
        return "Engine Protector";
      case "ncbProtection":
        return "NCB Protection";
      case "consumables":
        return "Consumable";
      case "tyreSecure":
        return "Tyre Secure";
      case "returnToInvoice":
        return "Return To Invoice";
      case "lopb":
        return "Loss of Personal Belongings";

      default:
        return "";
    }
  };

  const GetAddonValueNoBadge = (addonName, addonDiscountPercentage, quote) => {
    let inbuilt = quote?.addOnsData?.inBuilt
      ? Object.keys(quote?.addOnsData?.inBuilt)
      : [];
    let additional = quote?.addOnsData?.additional
      ? Object.keys(quote?.addOnsData?.additional)
      : [];
    let selectedAddons = addOnsAndOthers?.selectedAddons;

    if (inbuilt?.includes(addonName)) {
      return `${
        Number(quote?.addOnsData?.inBuilt[addonName]) !== 0
          ? `₹ ${currencyFormater(
              parseInt(
                quote?.company_alias === "royal_sundaram"
                  ? addonName === "ncbProtection" ||
                    addonName === "engineProtector" ||
                    addonName === "tyreSecure"
                    ? quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      200
                      ? 200
                      : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100
                    : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      100
                    ? 100
                    : quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100
                  : quote?.addOnsData?.inBuilt[addonName]
              )
            )}`
          : addonName === "roadSideAssistance" &&
            quote?.company_alias === "reliance"
          ? "-"
          : "Included"
      }`;
    } else if (
      additional?.includes(addonName) &&
      selectedAddons?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
      typeof quote?.addOnsData?.additional[addonName] === "number"
    ) {
      return `₹ ${currencyFormater(
        quote?.company_alias === "royal_sundaram"
          ? addonName === "ncbProtection" ||
            addonName === "engineProtector" ||
            addonName === "tyreSecure"
            ? quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100 <
              200
              ? 200
              : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100
            : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100 <
              100
            ? 100
            : quote?.addOnsData?.additional[addonName] -
              (quote?.addOnsData?.additional[addonName] *
                addonDiscountPercentage) /
                100
          : quote?.addOnsData?.additional[addonName]
      )}`;
    } else if (
      additional?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) === 0
    ) {
      return quote?.applicableAddons?.includes(addonName)
        ? "Optional"
        : "Not Available";
    } else if (
      !additional?.includes(addonName) &&
      selectedAddons?.includes(addonName)
    ) {
      return quote?.applicableAddons?.includes(addonName)
        ? "Optional"
        : "Not Available";
    } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
      return quote?.applicableAddons?.includes(addonName)
        ? "Optional"
        : "Not Available";
    } else if (
      additional?.includes(addonName) &&
      !selectedAddons?.includes(addonName)
    ) {
      return "Optional";
    } else {
      return quote?.applicableAddons?.includes(addonName)
        ? "Optional"
        : "Not Available";
    }
  };
  useEffect(() => {
    let selectedAddons = addOnsAndOthers?.selectedAddons;

    var groupedQuotesByIC = _.groupBy(
      quoteComprehesive,
      (i) => i.company_alias
    );

    var quoteComprehesiveGroupedUnique = [];

    let icList = Object.keys(groupedQuotesByIC);

    let newList = !_.isEmpty(groupedQuotesByIC) ? _.map(groupedQuotesByIC) : [];

    newList.forEach((el) => {
      let BestMatch = [];
      let match = {};
      let quoteComprehesiveSortedFinalGrouped1 = el.map((i) => {
        if (_.isEmpty(match)) {
          match = i;
        } else {
          //get addon keys of last best
          let x1 =
            match?.addOnsData?.inBuilt &&
            Object.keys(match?.addOnsData?.inBuilt);
          let additional1 = match?.addOnsData?.additional;
          var y1 = GetValidAdditionalKeys(additional1);
          let z1 = [...x1, ...y1];
          let commonLast = selectedAddons
            ? selectedAddons.filter((m) => !_.isEmpty(z1) && z1?.includes(m))
            : 0;
          // get addon keys for current
          let x = i?.addOnsData?.inBuilt && Object.keys(i?.addOnsData?.inBuilt);
          let additional = i?.addOnsData?.additional;
          var y = GetValidAdditionalKeys(additional);
          let z = [...x, ...y];
          let commonCurrent = selectedAddons
            ? selectedAddons.filter((m) => !_.isEmpty(z) && z?.includes(m))
            : 0;

          // if current the elemenet has more addons common with selectedAddons than last then swap it with match.
          if (commonCurrent?.length > commonLast?.length) {
            match = i;
          }
          if (commonCurrent?.length === commonLast?.length) {
            //find premium of match & current elem when the addons matched are equal in number
            let matchAddonPremium = 0;
            let currentAddonPremium = 0;

            Object.entries(
              match?.addOnsData?.additional ? match?.addOnsData?.additional : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons
            Object.entries(
              match?.addOnsData?.other ? match?.addOnsData?.other : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            Object.entries(
              i?.addOnsData?.additional ? i?.addOnsData?.additional : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons
            Object.entries(
              i?.addOnsData?.other ? i?.addOnsData?.other : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons

            Object.entries(
              match?.addOnsData?.inBuilt ? match?.addOnsData?.inBuilt : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            Object.entries(
              i?.addOnsData?.inBuilt ? i?.addOnsData?.inBuilt : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });
            if (currentAddonPremium < matchAddonPremium) {
              match = i;
            }
          }
        }
      });
      !_.isEmpty(match) && BestMatch.push(match);
      //No need to sort after filtering only one best match.

      // const quoteComprehesiveSortedFinalGrouped2 = _.sortBy(
      // 	BestMatch.length > 0 ? BestMatch : el,
      // 	(o) =>
      // 		Object.keys(o?.addOnsData?.inBuilt)?.length +
      // 		GetValidAdditionalKeys(o?.addOnsData?.additional)?.length
      // );

      quoteComprehesiveGroupedUnique.push(BestMatch[0]);
    });

    if (
      TypeReturn(type) === "car" ||
      TypeReturn(type) === "bike" ||
      TypeReturn(type) === "cv"
    ) {
      // let filteredGroup = quoteComprehesiveGroupedUnique.filter(Boolean);

      // let sortedGrouping = _.sortBy(
      // 	BestMatch.length > 0 ? BestMatch : el,
      // 	(o) =>
      // 		Object.keys(o?.addOnsData?.inBuilt)?.length +
      // 		GetValidAdditionalKeys(o?.addOnsData?.additional)?.length
      // );

      let uniquedGroup = quoteComprehesiveGroupedUnique.filter(Boolean);

      let uniquedGroup1 = _.uniqBy(uniquedGroup, "company_alias");

      setQuoteComprehesiveGrouped(uniquedGroup1);
    } else {
      let sortedGroupedcomp = _.sortBy(quoteComprehesive, [
        "finalPayableAmount",
      ]);

      setQuoteComprehesiveGrouped(sortedGroupedcomp);
    }
  }, [addOnsAndOthers?.selectedAddons, quoteComprehesive]);

  const getLogoUrl = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/ola.png`;
      case "UNILIGHT":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/unilight.png`;
      case "CP":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/cp.png`;
      case "FYNTUNE":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/fyntune.png`;
      case "ABIBL":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/abiblPdf.jpeg`;
      case "GRAM":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/gc.png`;
      case "ACE":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/ace.png`;
      case "SRIYAH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/sriyah.png`;
      case "PINC":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/pinc.png`;
      case "EPOCH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/epochlogo.png`;
      case "RB":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/rb.png`;
      case "SPA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/insuringall.jpeg`;
      case "BIMA":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bima.png`;
      case "ANALAH":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/analah.jpeg`;
      case "BAJAJ":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bajaj.png`;
      case "UIB":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/uib.png`;
      default:
        break;
    }
  };

  const getLogoCvType = (productSubTypeId) => {
    switch (productSubTypeId) {
      case 5:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/auto.png`;
      case 6:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/carCp.png`;
      case 9:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/pickup.png`;
      case 13:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/dumper.png`;
      case 14:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/pickup.png`;
      case 15:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/tracktor.png`;
      case 16:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/tanker.png`;
      default:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/cv.png`;
    }
  };

  const getPolicyType = (type) => {
    switch (TypeReturn(type)) {
      case "car":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/carCp.png`;
      case "bike":
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/bike.png`;
      case "cv":
        return getLogoCvType(temp_data?.productSubTypeId);
      default:
        return `${window.location.origin}${
          process.env.REACT_APP_BASENAME !== "NA"
            ? `/${process.env.REACT_APP_BASENAME}`
            : ""
        }/assets/images/vehicle/carCp.png`;
    }
  };

  const getBrokerWebsite = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola.fynity.in/";
      case "UNILIGHT":
        return "https://pcv.policyongo.com/landing-page";
      case "CP":
        return "https://motor.comparepolicy.com";
      case "FYNTUNE":
        return "https://cardemo-re8h1ssyiyqenhj8gzym7hqmo7n67jdq.fynity.in/";
      default:
        break;
    }
  };
  const brokerNumberFunction = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "7829411222";
      case "UNILIGHT":
        return "18002101225";
      case "CP":
        return "1800120000055";
      case "FYNTUNE":
        return "1800120000065";
      case "ABIBL":
        return "18002707000";
      case "GRAM":
        return "+91 9311672463";
      case "ACE":
        return "+918750881122";
      case "SRIYAH":
        return "+1800 203 0504";
      case "PINC":
        return "18002672670";
      case "EPOCH":
        return "1800-12000-0065";
      case "RB":
        return "18004197852";
      case "SPA":
        return "+91-11-45675555";
      case "BIMA":
        return "9999366494";
      case "ANALAH":
        return "+91 9920878181";
      case "BAJAJ":
        return "1800 212 123123";
      case "UIB":
        return "+91 79820 39210";
      default:
        return "18002669639";
    }
  };

  const getIRDAI = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "CA0682";
      case "UNILIGHT":
        return "446";
      case "CP":
        return "IRDAI/WBA23/15";
      case "FYNTUNE":
        return "CA0682";
      case "ABIBL":
        return "CA0682";
      case "GRAM":
        return "CB 691/17";
      case "ACE":
        return "CB/246";
      case "SRIYAH":
        return "203";
      case "PINC":
        return "118 ";
      case "EPOCH":
        return "555 valid upto 10/03/2025";
      case "RB":
        return "DB 571/14";
      case "BIMA":
        return "DB/655";
      case "BAJAJ":
        return "CB 042/02";
      case "UIB":
        return "410";
      default:
        break;
    }
  };

  const brokerEmailFunction = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "insurance@olacabs.com";
      case "UNILIGHT":
        return "Support@policyongo.com";
      case "CP":
        return "help@comparepolicy.com";
      case "FYNTUNE":
        return "help@fyntune.com";
      case "ABIBL":
        return "clientfeedback.abibl@adityabirlacapital.com";
      case "GRAM":
        return "info@gramcover.com";
      case "ACE":
        return "operations@aceinsurance.com";
      case "SRIYAH":
        return "care@nammacover.com";
      case "EPOCH":
        return "support@policylo.com";
      case "PINC":
        return "info@pincinsure.com";
      case "RB":
        return "customersupport@renewbuy.com";
      case "SPA":
        return "care@insuringall.com";
      case "BIMA":
        return "contact@bimaplanner.com";
      case "ANALAH":
        return "contactus@analahinsurance.com";
      case "BAJAJ":
        return "info@bajajcapitalinsurance.com";
      case "UIB":
        return "services@uibindia.com";
      default:
        break;
    }
  };
  useEffect(() => {
    if (quoteComprehesiveGrouped) {
      let sortedAndGrouped = quoteComprehesiveGrouped.map((quote) => {
        let additional = quote?.addOnsData?.additional
          ? Object.keys(quote?.addOnsData?.additional)
          : [];

        let additionalList = quote?.addOnsData?.additional;

        let selectedAddons = addOnsAndOthers?.selectedAddons || [];

        let totalAdditional = 0;

        let totalPayableAmountAddonNoGst = 0;

        let totalPayableAmountWithAddon = 0;

        //////////////////////////

        let totalPremiumA =
          quote?.finalOdPremium * 1 +
          ElectricalValue(quote) +
          NonElectricalValue(quote) +
          BiFuelValue(quote);

        //ncb calculation / discount part
        let totalPremiumc = quote?.finalTotalDiscount;
        let revisedNcb = quote?.deductionOfNcb;
        let otherDiscounts = quote?.icVehicleDiscount || 0;
        let addonDiscountPercentage = 0;
        let addedNcb = 0;

        //addon calculation

        selectedAddons.forEach((el) => {
          if (
            !_.isEmpty(additional) &&
            additional?.includes(el) &&
            typeof additionalList[el] === "number"
          ) {
            totalAdditional =
              totalAdditional +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(additionalList[el]) > 0
                    ? 200
                    : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100
                  : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(additionalList[el]) > 0
                  ? 100
                  : Number(additionalList[el]) -
                    (Number(additionalList[el]) * addonDiscountPercentage) / 100
                : Number(additionalList[el]));
          }
        });

        let inbuilt = quote?.addOnsData?.inBuilt
          ? Object.keys(quote?.addOnsData?.inBuilt)
          : [];
        let allAddons = [
          "zeroDepreciation",
          "roadSideAssistance",
          "imt23",
          "keyReplace",
          "engineProtector",
          "ncbProtection",
          "consumables",
          "tyreSecure",
          "returnToInvoice",
          "lopb",
        ];
        let inbuiltList = quote?.addOnsData?.inBuilt;
        let totalInbuilt = 0;
        allAddons.forEach((el) => {
          if (
            !_.isEmpty(inbuilt) &&
            inbuilt?.includes(el) &&
            typeof inbuiltList[el] === "number"
          ) {
            totalInbuilt =
              totalInbuilt +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(inbuiltList[el]) > 0
                    ? 200
                    : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                  : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(inbuiltList[el]) > 0
                  ? 100
                  : Number(inbuiltList[el]) -
                    (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                : Number(inbuiltList[el]));
          }
        });

        let others =
          (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
          [];

        let othersList = quote?.addOnsData?.other;

        let totalOther = 0;
        others.forEach((el) => {
          if (typeof othersList[el] === "number") {
            totalOther = totalOther + Number(othersList[el]);
          }
        });
        let totalAddon =
          Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);

        if (quote?.company_alias === "oriental" && TypeReturn(type) === "cv") {
          // For Oriental CV, you need to use following formula:NCB premium = (Total OD premium + Addons - discounts(anti theft)) * applicable NCB
          let extraOtherDiscounts = 0;
          let discountPercentageOriental = 0.7;

          // if (quote?.mmvDetail?.manfName === "MARUTI") {
          //   discountPercentageOriental = 0.5;

          //   let c = temp_data?.regDate;
          //   let d = moment().format("DD-MM-YYYY");
          //   let vehicleAge = differenceInYears(toDate(d), toDate(c));
          //   console.log(vehicleAge, "vehicleAge");
          //   if (vehicleAge >= 11) {
          //     discountPercentageOriental = 0.7;
          //   } else if (vehicleAge >= 6 && vehicleAge < 11) {
          //     discountPercentageOriental = 0.75;
          //   } else if (vehicleAge <= 5) {
          //     discountPercentageOriental = 0.8;
          //   }
          // } else {
          //   discountPercentageOriental = 0.5;
          // }
          //for ncb zd is included.
          extraOtherDiscounts = totalAddon * discountPercentageOriental;
          //for extradiscounts we don't need ZeroDep hence recalc total (addon * discount %) without zd ------
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });

          let extraOtherDiscounts2 =
            (Number(totalAdditional) +
              Number(totalInbuilt) +
              Number(totalOther)) *
            discountPercentageOriental;
          addedNcb =
            ((totalAddon - extraOtherDiscounts2) * Number(quote?.ncbDiscount)) /
            100;

          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          otherDiscounts =
            (quote?.icVehicleDiscount || 0) + Number(extraOtherDiscounts2);

          totalPremiumc =
            Number(quote?.finalTotalDiscount) +
            Number(addedNcb) +
            Number(extraOtherDiscounts2);
        } else if (
          selectedAddons?.includes("imt23") &&
          (quote?.company_alias === "hdfc_ergo" ||
            quote?.company_alias === "godigit" ||
            quote?.company_alias === "shriram" ||
            quote?.company_alias === "reliance" ||
            quote?.company_alias === "sbi")
        ) {
          if (
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number"
          ) {
            addedNcb =
              (Number(additionalList["imt23"]) * Number(quote?.ncbDiscount)) /
              100;
          } else if (
            inbuilt?.includes("imt23") &&
            typeof inbuiltList["imt23"] === "number"
          ) {
            addedNcb = Number(
              (inbuiltList["imt23"] * Number(quote?.ncbDiscount)) / 100
            );
          }
          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount) + Number(addedNcb);
        } else if (
          quote?.company_alias === "royal_sundaram" &&
          TypeReturn(type) === "car" &&
          addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
        ) {
          const g1 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "engineProtector",
          ]; // 10 % on final addons
          const g2 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "engineProtector",
          ]; //15% on finaladdons
          const g3 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "tyreSecure",
            "keyReplace",
            "engineProtector",
          ]; // 20 % on final addons
          let addonsSelected = addOnsAndOthers?.selectedAddons;

          if (_.intersection(g3, addonsSelected)?.length >= 4) {
            addonDiscountPercentage = 20;
            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g2, addonsSelected)?.length === 3) {
            addonDiscountPercentage = 15;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g1, addonsSelected)?.length === 2) {
            addonDiscountPercentage = 10;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else {
            addonDiscountPercentage = 0;
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          }
        }
        // else if (
        //   quote?.company_alias === "hdfc_ergo" &&
        //   temp_data?.journeyCategory !== "GCV"
        // ) {
        //   revisedNcb = Number(
        //     (totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100
        //   );
        //   totalPremiumc =
        //     Number(quote?.finalTotalDiscount) +
        //     Number((totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100) -
        //     Number(quote?.deductionOfNcb);
        // }
        else if (
          quote?.company_alias === "oriental" &&
          TypeReturn(type) === "car"
        ) {
          // re-eval required addons with others
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });
          let NcbTotalAddon =
            Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
          revisedNcb = Math.round(
            ((totalPremiumA * 1 +
              NcbTotalAddon * 1 -
              (Number(quote?.finalTotalDiscount) -
                Number(quote?.deductionOfNcb) -
                (Number(quote.tppdDiscount)
                  ? Number(quote.tppdDiscount)
                  : 0))) *
              Number(quote?.ncbDiscount)) /
              100
          );
          totalPremiumc =
            Number(quote?.finalTotalDiscount) -
            //deducting the ncb sent by backend
            Number(quote?.deductionOfNcb) +
            //calculating ncb and adding it to total discount
            Math.round(
              ((totalPremiumA * 1 +
                NcbTotalAddon * 1 -
                (Number(quote?.finalTotalDiscount) -
                  Number(quote?.deductionOfNcb) -
                  (Number(quote.tppdDiscount)
                    ? Number(quote.tppdDiscount)
                    : 0))) *
                Number(quote?.ncbDiscount)) /
                100
            );
        } else {
          revisedNcb = Number(quote?.deductionOfNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount);
        }

        //////cpa part
        let totalPremiumB = quote?.finalTpPremium;

        let selectedCpa = addOnsAndOthers?.selectedCpa;

        let cpa = 0;

        if (selectedCpa?.includes("Compulsory Personal Accident")) {
          if (!_.isEmpty(addOnsAndOthers?.isTenure)) {
            cpa = quote?.multiYearCpa ? quote?.multiYearCpa : 0;
          } else {
            cpa = quote?.compulsoryPaOwnDriver;
          }
        } else {
          cpa = 0;
        }

        totalPremiumB =
          Number(quote?.finalTpPremium) +
          Number(cpa) + //adding un-named passenger cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.coverUnnamedPassengerValue * 1
            ? quote?.coverUnnamedPassengerValue *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0) +
          //adding additional paid driver cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.motorAdditionalPaidDriver * 1
            ? quote?.motorAdditionalPaidDriver *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0);

        let applicableAddons = [];
        if (temp_data?.tab !== "tab2") {
          var addonsSelectedList = [];
          if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
            selectedAddons.forEach((el) => {
              if (
                additional?.includes(el) &&
                Number(additionalList[el]) !== 0
              ) {
                var newList = {
                  name: getAddonName(el),
                  premium: Number(additionalList[el]),
                };
                addonsSelectedList.push(newList);
              }
            });

            inbuilt.forEach((el) => {
              var newList = {
                name: getAddonName(el),
                premium: Number(inbuiltList[el]),
              };
              addonsSelectedList.push(newList);
            });

            applicableAddons = addonsSelectedList;
          } else {
            applicableAddons = [];
          }
        }

        //uv loading
        let uwLoading = 0;
        if (_.isEmpty(applicableAddons)) {
          if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL !==
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 17;
          } else if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL ===
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 50;
          } else {
            uwLoading = 0;
          }
        } else {
          uwLoading = 0;
        }

        let totalLoading = 0;

        if (true) {
          if (
            Number(quote?.totalLoadingAmount) > 0 ||
            Number(quote?.underwritingLoadingAmount)
          ) {
            totalLoading =
              Number(quote?.totalLoadingAmount) ||
              Number(quote?.underwritingLoadingAmount);
          } else {
            totalLoading = 0;
          }
        } else {
          totalLoading = 0;
        }

        let totalPremium =
          Number(totalAddon) +
          Number(totalPremiumA) +
          Number(totalPremiumB) -
          Number(totalPremiumc) +
          Number(uwLoading) +
          Number(totalLoading);

        let totalPremiumGst = parseInt((totalPremium * 18) / 100);

        if (temp_data?.journeyCategory === "GCV") {
          if (quote.company_alias === "oriental") {
            //In Oriental , TPPD discount is not added to third party liability for GST calc
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "sbi") {
            //In sbi , Basic tp - 12%, rest 18%
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "godigit") {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                Math.round(
                  ((totalPremium -
                    quote?.tppdPremiumAmount +
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0)) *
                    18) /
                    100 +
                    ((quote?.tppdPremiumAmount -
                      (Number(quote?.tppdDiscount)
                        ? Number(quote?.tppdDiscount)
                        : 0)) *
                      12) /
                      100
                )
              ) - (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0);
          } else {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  (Number(quote?.tppdDiscount)
                    ? Number(quote?.tppdDiscount)
                    : 0)) *
                  18) /
                  100
              ) +
              ((quote?.tppdPremiumAmount -
                (Number(quote?.tppdDiscount)
                  ? Number(quote?.tppdDiscount)
                  : 0)) *
                12) /
                100;
          }
        }

        let FinalPremium = totalPremium + totalPremiumGst;

        return {
          ...quote,
          totalAddon1: totalAddon,
          finalPremium1: FinalPremium,
          totalPremium1: totalPremium,
          totalPremiumB1: totalPremiumB,
          totalPremiumc1: totalPremiumc,
          addonDiscountPercentage1: addonDiscountPercentage,
          applicableAddons1: applicableAddons,
          gst1: totalPremiumGst,
          revisedNcb1: revisedNcb,
        };
      });

      let sortedGroupedcomp1 = _.sortBy(sortedAndGrouped, [
        "totalPayableAmountWithAddon",
      ]);

      setQuoteComprehesiveGrouped1(sortedGroupedcomp1);
    }
  }, [
    addOnsAndOthers?.selectedAddons,
    quoteComprehesiveGrouped,
    addOnsAndOthers?.selectedCpa,
  ]);

  const [icLists, setICLists] = useState([]);

  useEffect(() => {
    if (compareQuotes) {
      setICLists(_.compact(compareQuotes.map((x) => x.company_alias)));
    }
  }, [compareQuotes]);

  const [newGroupedQuotesCompare, setNewGroupedQuotesCompare] =
    useState(compareQuotes);

  useEffect(() => {
    if (quoteComprehesiveGrouped1) {
      let FilteredGroupedByIc = quoteComprehesiveGrouped1?.filter(
        ({ company_alias }) => icLists?.includes(company_alias)
      );

      let filteredExistingQuote = compareQuotes?.filter(
        ({ minIdv }) => minIdv === 1
      );

      setNewGroupedQuotesCompare(
        _.concat(FilteredGroupedByIc, filteredExistingQuote)
      );
    }
  }, [quoteComprehesiveGrouped1, compareQuotes, icLists]);

  useEffect(() => {
    if (newGroupedQuotesCompare && newGroupedQuotesCompare?.length === 3) {
      //dispatch(setCompareQuoteFull(newGroupedQuotesCompare));
    }
  }, [newGroupedQuotesCompare]);

  const [validQuote, setValidQuote] = useState(false);

  useEffect(() => {
    if (newGroupedQuotesCompare) {
      let validQuote = !_.isEmpty(newGroupedQuotesCompare)
        ? _.compact(
            newGroupedQuotesCompare.map(({ companyName }) =>
              companyName ? companyName : null
            )
          )
        : [];
      setValidQuote(validQuote);
      dispatch(setValidQuotes(validQuote));
    }
  }, [newGroupedQuotesCompare]);

  //pdf json creations

  useEffect(() => {
    if (newGroupedQuotesCompare) {
      var premiumBreakupArray = [];
      premiumBreakupArray.push([
        "Total OD Payable",
        "Total TP Payable",
        "Add On Premium",
        "NCB % (amount)",
        "Gross Premium (incl. GST)",
      ]);

      premiumBreakupArray.push([
        `₹ ${currencyFormater(
          newGroupedQuotesCompare[0]?.finalOdPremium * 1 +
            ElectricalValue(newGroupedQuotesCompare[0]) +
            NonElectricalValue(newGroupedQuotesCompare[0]) +
            BiFuelValue(newGroupedQuotesCompare[0]) +
            (newGroupedQuotesCompare[0]?.totalLoadingAmount * 1 || 0) +
            (newGroupedQuotesCompare[0]?.underwritingLoadingAmount * 1 || 0) +
            (newGroupedQuotesCompare[0]?.totalAddon1 * 1 || 0) -
            ((newGroupedQuotesCompare[0]?.totalPremiumc1 * 1 || 0) -
              (newGroupedQuotesCompare[0]?.tppdDiscount * 1 || 0))
        )}`,
        `₹ ${currencyFormater(
          newGroupedQuotesCompare[0]?.totalPremiumB1 -
            (newGroupedQuotesCompare[0]?.tppdDiscount * 1 || 0)
        )}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.totalAddon1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.revisedNcb1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.finalPremium1)}`,
      ]);

      premiumBreakupArray.push([
        `₹ ${currencyFormater(
          newGroupedQuotesCompare[1]?.finalOdPremium * 1 +
            ElectricalValue(newGroupedQuotesCompare[1]) +
            NonElectricalValue(newGroupedQuotesCompare[1]) +
            BiFuelValue(newGroupedQuotesCompare[1]) +
            (newGroupedQuotesCompare[1]?.totalLoadingAmount * 1 || 0) +
            (newGroupedQuotesCompare[1]?.underwritingLoadingAmount * 1 || 0) +
            (newGroupedQuotesCompare[1]?.totalAddon1 * 1 || 0) -
            ((newGroupedQuotesCompare[1]?.totalPremiumc1 * 1 || 0) -
              (newGroupedQuotesCompare[1]?.tppdDiscount * 1 || 0))
        )}`,
        `₹ ${currencyFormater(
          newGroupedQuotesCompare[1]?.totalPremiumB1 -
            (newGroupedQuotesCompare[1]?.tppdDiscount * 1 || 0)
        )}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.totalAddon1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.revisedNcb1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.finalPremium1)}`,
      ]);

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        premiumBreakupArray.push([
          `₹ ${currencyFormater(
            newGroupedQuotesCompare[2]?.finalOdPremium * 1 +
              ElectricalValue(newGroupedQuotesCompare[2]) +
              NonElectricalValue(newGroupedQuotesCompare[2]) +
              BiFuelValue(newGroupedQuotesCompare[2]) +
              (newGroupedQuotesCompare[2]?.totalLoadingAmount * 1 || 0) +
              (newGroupedQuotesCompare[2]?.underwritingLoadingAmount * 1 || 0) +
              (newGroupedQuotesCompare[2]?.totalAddon1 * 1 || 0) -
              ((newGroupedQuotesCompare[2]?.totalPremiumc1 * 1 || 0) -
                (newGroupedQuotesCompare[2]?.tppdDiscount * 1 || 0))
          )}`,
          `₹ ${currencyFormater(
            newGroupedQuotesCompare[2]?.totalPremiumB1 -
              (newGroupedQuotesCompare[2]?.tppdDiscount * 1 || 0)
          )}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.totalAddon1)}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.revisedNcb1)}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.finalPremium1)}`,
        ]);
      }

      var accessoriesArray = [];

      if (temp_data.journeyCategory === "GCV") {
        accessoriesArray.push([
          `Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleElectricAccessories})`
              : ""
          }  `,
          `Non Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleNonElectricAccessories})`
              : ""
          } `,

          `Bi Fuel Kit  ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
              ? `(${addOnsAndOthers?.externalBiFuelKit})`
              : ``
          } `,
          `Trailer  ${
            addOnsAndOthers?.selectedAccesories?.includes("Trailer")
              ? `(${addOnsAndOthers?.trailerCover})`
              : ``
          } `,
        ]);
      } else {
        accessoriesArray.push([
          `Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleElectricAccessories})`
              : ""
          }  `,
          `Non Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleNonElectricAccessories})`
              : ""
          } `,
          TypeReturn(type) === "bike"
            ? ""
            : `Bi Fuel Kit  ${
                addOnsAndOthers?.selectedAccesories?.includes(
                  "External Bi-Fuel Kit CNG/LPG"
                )
                  ? `(${addOnsAndOthers?.externalBiFuelKit})`
                  : ``
              } `,
        ]);
      }
      if (temp_data.journeyCategory === "GCV") {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  Number(
                    newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
                  ) + ElectricalValue(newGroupedQuotesCompare[0])
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue +
                    NonElectricalValue(newGroupedQuotesCompare[0])
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          )
            ? Number(newGroupedQuotesCompare[0]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.motorLpgCngKitValue +
                    BiFuelValue(newGroupedQuotesCompare[0])
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes("Trailer")
            ? Number(newGroupedQuotesCompare[0]?.trailerValue) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.trailerValue
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      } else {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
                    ) + ElectricalValue(newGroupedQuotesCompare[0])
                  )}`
                : "Not Available"
              : "Not Available"
            : "Not Selected",
          // Number(
          //     newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
          //   ) !== 0
          //   ? `₹ ${currencyFormater(
          //       Number(
          //         newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
          //       ) + ElectricalValue(newGroupedQuotesCompare[0])
          //     )}`
          //   : "Not Available"
          // : "Not Selected"
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[0]
                        ?.motorNonElectricAccessoriesValue
                    ) + NonElectricalValue(newGroupedQuotesCompare[0])
                  )}`
                : "Not Available"
              : "Not Available"
            : "Not Selected",
          // ? Number(
          //       newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
          //     ) !== 0
          //     ? `₹ ${currencyFormater(
          //         Number(
          //           newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
          //         ) + NonElectricalValue(newGroupedQuotesCompare[0])
          //       )}`
          //     : "Not Available"
          //   : "Not Selected"
          TypeReturn(type) === "bike"
            ? ""
            : addOnsAndOthers?.selectedAccesories?.includes(
                "External Bi-Fuel Kit CNG/LPG"
              )
            ? Number(newGroupedQuotesCompare[0]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  Number(newGroupedQuotesCompare[0]?.motorLpgCngKitValue) +
                    BiFuelValue(newGroupedQuotesCompare[0])
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      }
      if (temp_data.journeyCategory === "GCV") {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  Number(
                    newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
                  ) + ElectricalValue(newGroupedQuotesCompare[1])
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  Number(
                    newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
                  ) + NonElectricalValue(newGroupedQuotesCompare[1])
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          )
            ? Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) +
                    BiFuelValue(newGroupedQuotesCompare[1])
                )}`
              : "Not Available"
            : "Not Selected",

          addOnsAndOthers?.selectedAccesories?.includes("Trailer")
            ? Number(newGroupedQuotesCompare[1]?.trailerValue) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[1]?.trailerValue
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      } else {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
                    ) + ElectricalValue(newGroupedQuotesCompare[1])
                  )}`
                : "Not Available"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[1]
                        ?.motorNonElectricAccessoriesValue
                    ) + NonElectricalValue(newGroupedQuotesCompare[1])
                  )}`
                : "Not Available"
              : "Not Available"
            : "Not Selected",
          TypeReturn(type) === "bike"
            ? ""
            : addOnsAndOthers?.selectedAccesories?.includes(
                "External Bi-Fuel Kit CNG/LPG"
              )
            ? Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) +
                    BiFuelValue(newGroupedQuotesCompare[1])
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      }
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        if (temp_data.journeyCategory === "GCV") {
          accessoriesArray.push([
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? Number(
                  newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                    ) + ElectricalValue(newGroupedQuotesCompare[2])
                  )}`
                : "Not Available"
              : "Not Selected",
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? Number(
                  newGroupedQuotesCompare[2]?.motorNonElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[2]
                        ?.motorNonElectricAccessoriesValue
                    ) + NonElectricalValue(newGroupedQuotesCompare[2])
                  )}`
                : "Not Available"
              : "Not Selected",

            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
              ? Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) !== 0
                ? `₹ ${currencyFormater(
                    Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) +
                      BiFuelValue(newGroupedQuotesCompare[2])
                  )}`
                : "Not Available"
              : "Not Selected",

            addOnsAndOthers?.selectedAccesories?.includes("Trailer")
              ? Number(newGroupedQuotesCompare[2]?.trailerValue) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[2]?.trailerValue
                  )}`
                : "Not Available"
              : "Not Selected",
          ]);
        } else {
          console.log(
            newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue,
            "newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue"
          );
          accessoriesArray.push([
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                ? Number(
                    newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      Number(
                        newGroupedQuotesCompare[2]
                          ?.motorElectricAccessoriesValue
                      ) + ElectricalValue(newGroupedQuotesCompare[2])
                    )}`
                  : "Not Available"
                : "Not Available"
              : "Not Selected",
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? newGroupedQuotesCompare[2]?.motorNonElectricAccessoriesValue
                ? Number(
                    newGroupedQuotesCompare[2]?.motorNonElectricAccessoriesValue
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      Number(
                        newGroupedQuotesCompare[2]
                          ?.motorNonElectricAccessoriesValue
                      ) + NonElectricalValue(newGroupedQuotesCompare[2])
                    )}`
                  : "Not Available"
                : "Not Available"
              : "Not Selected",
            TypeReturn(type) === "bike"
              ? ""
              : addOnsAndOthers?.selectedAccesories?.includes(
                  "External Bi-Fuel Kit CNG/LPG"
                )
              ? Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) !== 0 &&
                newGroupedQuotesCompare[2]?.motorLpgCngKitValue !== undefined
                ? `₹ ${currencyFormater(
                    Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) +
                      BiFuelValue(newGroupedQuotesCompare[2])
                  )}`
                : "Not Available"
              : "Not Selected",
          ]);
        }
      }

      console.log(accessoriesArray, "accessoriesArray");
      console.log(newGroupedQuotesCompare, "newGroupedQuotesCompare");

      //additional array
      var additionalArray = [];

      additionalArray.push([
        TypeReturn(type) === "bike" || temp_data.journeyCategory === "GCV"
          ? ""
          : `PA Cover For Additional Paid Driver  ${
              addOnsAndOthers?.selectedAdditions?.includes(
                "PA cover for additional paid driver"
              )
                ? ` (₹ ${
                    addOnsAndOthers?.additionalPaidDriver === "₹ 2 lac"
                      ? "2 lac"
                      : "1 lac"
                  })`
                : ""
            } `,
        `Umnamed Passenger PA Cover  ${
          addOnsAndOthers?.selectedAdditions?.includes(
            "Unnamed Passenger PA Cover"
          )
            ? `(₹ ${
                addOnsAndOthers?.unNamedCoverValue === "₹ 2 lac"
                  ? "2 lac"
                  : "1 lac"
              })`
            : ""
        }   `,
        `LL Paid Driver  ${
          addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
            ? "₹ 1 Lac"
            : ""
        } `,
        ,
      ]);

      additionalArray.push([
        TypeReturn(type) === "bike" || temp_data?.journeyCategory === "GCV"
          ? ""
          : addOnsAndOthers?.selectedAdditions?.includes(
              "PA cover for additional paid driver"
            )
          ? Number(newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        addOnsAndOthers?.selectedAdditions?.includes(
          "Unnamed Passenger PA Cover"
        )
          ? Number(newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue
              )}`
            : "Not Available"
          : "Not Selected",
        addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
          ? Number(newGroupedQuotesCompare[0]?.defaultPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.defaultPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
      ]);

      additionalArray.push([
        TypeReturn(type) === "bike" || temp_data?.journeyCategory === "GCV"
          ? ""
          : addOnsAndOthers?.selectedAdditions?.includes(
              "PA cover for additional paid driver"
            )
          ? Number(newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        addOnsAndOthers?.selectedAdditions?.includes(
          "Unnamed Passenger PA Cover"
        )
          ? Number(newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue
              )}`
            : "Not Available"
          : "Not Selected",
        addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
          ? Number(newGroupedQuotesCompare[1]?.defaultPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.defaultPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        additionalArray.push([
          TypeReturn(type) === "bike" || temp_data?.journeyCategory === "GCV"
            ? ""
            : addOnsAndOthers?.selectedAdditions?.includes(
                "PA cover for additional paid driver"
              )
            ? Number(newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver) !==
              0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAdditions?.includes(
            "Unnamed Passenger PA Cover"
          )
            ? Number(newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue) !==
              0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.companyAlias === "sbi" &&
                    addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) &&
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                    ? newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue *
                        (TypeReturn(type) === "bike" ? 5 : 3)
                    : newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
            ? Number(newGroupedQuotesCompare[2]?.defaultPaidDriver) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.defaultPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      }
      var additionalArrayGcv = [];

      additionalArrayGcv.push([
        `LL paid driver/conductor/cleaner`,
        `PA paid driver/conductor/cleaner`,
      ]);

      additionalArrayGcv.push([
        addOnsAndOthers?.selectedAdditions?.includes(
          "LL paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[0]?.defaultPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.defaultPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        addOnsAndOthers?.selectedAdditions?.includes(
          "	PA paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      additionalArrayGcv.push([
        addOnsAndOthers?.selectedAdditions?.includes(
          "LL paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[1]?.defaultPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.defaultPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        addOnsAndOthers?.selectedAdditions?.includes(
          "	PA paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        additionalArrayGcv.push([
          addOnsAndOthers?.selectedAdditions?.includes(
            "LL paid driver/conductor/cleaner"
          )
            ? Number(newGroupedQuotesCompare[2]?.defaultPaidDriver) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.defaultPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAdditions?.includes(
            "	PA paid driver/conductor/cleaner"
          )
            ? Number(newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver) !==
              0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      }

      //discount array
      var discountArray = [];

      console.log(newGroupedQuotesCompare, "newGroupedQuotesCompare");

      discountArray.push(
        _.compact([
          temp_data.journeyCategory !== "GCV"
            ? `Vehicle is fitted with ARAI  ${
                addOnsAndOthers?.selectedDiscount?.includes(
                  "Is the vehicle fitted with ARAI approved anti-theft device?"
                )
                  ? ""
                  : ""
              } `
            : "",
          TypeReturn(type) !== "cv"
            ? `Voluntary Deductible ${
                addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                )
                  ? ""
                  : ""
              } `
            : "",
          !temp_data?.odOnly && `TPPD Cover`,
          ,
        ])
      );

      console.log(discountArray, "discountArray");

      discountArray.push(
        _.compact([
          temp_data.journeyCategory !== "GCV"
            ? addOnsAndOthers?.selectedDiscount?.includes(
                "Is the vehicle fitted with ARAI approved anti-theft device?"
              )
              ? Number(newGroupedQuotesCompare[0]?.antitheftDiscount) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.antitheftDiscount
                  )}`
                : "Not Available"
              : "Not Selected"
            : "",
          TypeReturn(type) !== "cv"
            ? addOnsAndOthers?.selectedDiscount?.includes("Voluntary Discounts")
              ? addOnsAndOthers?.volDiscountValue !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.voluntaryExcess
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[0]?.voluntaryExcess
                    )}`
                : "Not Available"
              : "Not Selected"
            : "",
          addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
            ? Number(newGroupedQuotesCompare[0]?.tppdDiscount) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.tppdDiscount
                )}` === "₹ 0"
                ? "Not Available"
                : `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.tppdDiscount
                  )}`
              : "Not Available"
            : temp_data?.odOnly
            ? ""
            : "Not Selected",
        ])
      );

      if (Number(newGroupedQuotesCompare[1]?.idv) > 0) {
        discountArray.push(
          _.compact([
            temp_data.journeyCategory !== "GCV"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Is the vehicle fitted with ARAI approved anti-theft device?"
                )
                ? Number(newGroupedQuotesCompare[1]?.antitheftDiscount) !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.antitheftDiscount
                    )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            TypeReturn(type) !== "cv"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                )
                ? addOnsAndOthers?.volDiscountValue !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.voluntaryExcess
                    )}` === "₹ 0"
                    ? "Not Available"
                    : `₹ ${currencyFormater(
                        newGroupedQuotesCompare[1]?.voluntaryExcess
                      )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
              ? Number(newGroupedQuotesCompare[1]?.tppdDiscount) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[1]?.tppdDiscount
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.tppdDiscount
                    )}`
                : "Not Available"
              : temp_data?.odOnly
              ? ""
              : "Not Selected",
          ])
        );
      }

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        console.log(newGroupedQuotesCompare[2]?.antitheftDiscount, "newGroupedQuotesCompare[2]?.antitheftDiscount")
        discountArray.push(
          _.compact([
            temp_data.journeyCategory !== "GCV"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Is the vehicle fitted with ARAI approved anti-theft device?"
                )
                
                ? Number(newGroupedQuotesCompare[2]?.antitheftDiscount ? newGroupedQuotesCompare[2]?.antitheftDiscount : 0) !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.antitheftDiscount ? newGroupedQuotesCompare[2]?.antitheftDiscount : 0
                    )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            TypeReturn(type) !== "cv"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                )
                ? addOnsAndOthers?.volDiscountValue !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.voluntaryExcess
                    )}` === "₹ 0"
                    ? "Not Available"
                    : `₹ ${currencyFormater(
                        newGroupedQuotesCompare[2]?.voluntaryExcess
                      )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
              ? Number(newGroupedQuotesCompare[2]?.tppdDiscount) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[2]?.tppdDiscount
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.tppdDiscount
                    )}`
                : "Not Available"
              : temp_data?.odOnly
              ? ""
              : "Not Selected",
          ])
        );
      }

      // //additional array
      // var discountArray = [];

      // console.log("newgroup", newGroupedQuotesCompare)

      // discountArray.push([
      //   `Is the vehicle fitted with ARAI approved anti-theft device?  ${
      //     addOnsAndOthers?.selectedDiscount?.includes(
      //       "Is the vehicle fitted with ARAI approved anti-theft device?"
      //     )
      //       ? ""
      //       : ""
      //   } `,
      //   `Vehicle Limited to Own Premises  ${
      //     addOnsAndOthers?.selectedDiscount?.includes(
      //       "Vehicle Limited to Own Premises"
      //     )
      //       ? ""
      //       : ""
      //   }   `,
      //   `TPPD Cover  ${
      //     addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
      //       ? ""
      //       : ""
      //   } `,
      //   ,
      // ]);

      // discountArray.push([
      //   addOnsAndOthers?.selectedDiscount?.includes(
      //     "Is the vehicle fitted with ARAI approved anti-theft device?"
      //   )
      //     ? Number(newGroupedQuotesCompare[0]?.antitheftDiscount) !== 0
      //       ? `₹ ${currencyFormater(
      //           newGroupedQuotesCompare[0]?.antitheftDiscount
      //         )}`
      //       : "Not Available"
      //     : "Optional",
      //   addOnsAndOthers?.selectedDiscount?.includes(
      //     "Vehicle Limited to Own Premises"
      //   )
      //     ? Number(newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue) !== 0
      //       ? `₹ ${currencyFormater(
      //           newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue
      //         )}`
      //       : "Not Available"
      //     : "Optional",
      //   addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
      //     ? Number(newGroupedQuotesCompare[0]?.tppdDiscount) !== 0
      //       ? `₹ ${currencyFormater(
      //           newGroupedQuotesCompare[0]?.tppdDiscount
      //         )}`
      //       : "Not Available"
      //     : "Optional",
      // ]);

      // discountArray.push([
      //   addOnsAndOthers?.selectedDiscount?.includes(
      //     "Is the vehicle fitted with ARAI approved anti-theft device?"
      //   )
      //     ? Number(newGroupedQuotesCompare[1]?.antitheftDiscount) !== 0
      //       ? `₹ ${currencyFormater(
      //           newGroupedQuotesCompare[1]?.antitheftDiscount
      //         )}`
      //       : "Not Available"
      //     : "Optional",
      //   addOnsAndOthers?.selectedDiscount?.includes(
      //     "Vehicle Limited to Own Premises"
      //   )
      //     ? Number(newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue) !== 0
      //       ? `₹ ${currencyFormater(
      //           newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue
      //         )}`
      //       : "Not Available"
      //     : "Optional",
      //   addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
      //     ? Number(newGroupedQuotesCompare[1]?.tppdDiscount) !== 0
      //       ? `₹ ${currencyFormater(
      //           newGroupedQuotesCompare[1]?.tppdDiscount
      //         )}`
      //       : "Not Available"
      //     : "Optional",
      // ]);
      // if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
      //   discountArray.push([
      //     addOnsAndOthers?.selectedDiscount?.includes(
      //       "Is the vehicle fitted with ARAI approved anti-theft device?"
      //     )
      //       ? Number(newGroupedQuotesCompare[2]?.antitheftDiscount) !==
      //         0
      //         ? `₹ ${currencyFormater(
      //             newGroupedQuotesCompare[2]?.antitheftDiscount
      //           )}`
      //         : "Not Available"
      //       : "Optional",
      //     addOnsAndOthers?.selectedDiscount?.includes(
      //       "Vehicle Limited to Own Premises"
      //     )
      //       ? Number(newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue) !==
      //         0
      //         ? `₹ ${currencyFormater(
      //             newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue
      //           )}`
      //         : "Not Available"
      //       : "Optional",
      //     addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
      //       ? Number(newGroupedQuotesCompare[2]?.tppdDiscount) !== 0
      //         ? `₹ ${currencyFormater(
      //             newGroupedQuotesCompare[2]?.tppdDiscount
      //           )}`
      //         : "Not Available"
      //       : "Optional",
      //   ]);
      // }

      var AddonArray = [];

      if (TypeReturn(type) === "car") {
        AddonArray.push(
          [
            "Compulsory Personal Accident",
            "Zero Depreciation",
            "Road Side Assistance",
            "Key Replacement",
            "Engine Protector",
            "NCB Protection",
            "Consumable",
            "Tyre Secure",
            "Return To Invoice",
            "Loss of Personal Belongings",
          ].filter((x) =>
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
              ? x !== "Compulsory Personal Accident"
              : x
          )
        );
      } else if (TypeReturn(type) === "bike") {
        AddonArray.push(
          [
            "Compulsory Personal Accident",
            "Zero Depreciation",
            "Road Side Assistance",
            "Engine Protector",
            "Consumable",
            "Return To Invoice",
          ].filter((x) =>
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
              ? x !== "Compulsory Personal Accident"
              : x
          )
        );
      } else if (TypeReturn(type) === "cv") {
        if (temp_data.journeyCategory === "GCV") {
          AddonArray.push(
            [
              "Compulsory Personal Accident",
              "Zero Depreciation",
              "Road Side Assistance",
              "IMT - 23",
            ].filter((x) =>
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
                ? x !== "Compulsory Personal Accident"
                : x
            )
          );
        } else {
          AddonArray.push(
            [
              "Compulsory Personal Accident",
              "Zero Depreciation",
              "Road Side Assistance",
              "IMT - 23",
              "Consumable",
            ].filter((x) =>
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
                ? x !== "Compulsory Personal Accident"
                : x
            )
          );
        }
      }

      console.log(GetAddonValueNoBadge, "GetAddonValueNoBadge");

      if (TypeReturn(type) === "car") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
          ]);
        } else {
          AddonArray.push([
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? `₹ ${currencyFormater(
                  parseInt(
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? newGroupedQuotesCompare[0]?.multiYearCpa
                      : newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver
                  )
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
          ]);
        }
      } else if (TypeReturn(type) === "bike") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
          ]);
        } else {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
            ]);
          } else {
            AddonArray.push([
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? `₹ ${currencyFormater(
                    parseInt(
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? newGroupedQuotesCompare[0]?.multiYearCpa
                        : newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver
                    )
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
            ]);
          }
        }
      } else if (TypeReturn(type) === "cv") {
        if (temp_data.journeyCategory === "GCV") {
          AddonArray.push([
            temp_data?.odOnly
              ? ""
              : addOnsAndOthers?.selectedCpa?.includes(
                  "Compulsory Personal Accident"
                )
              ? `₹ ${currencyFormater(
                  parseInt(newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver)
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "imt-23",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
          ]);
        } else {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "imt-23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
            ]);
          } else {
            AddonArray.push([
              temp_data?.odOnly
                ? ""
                : addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )
                ? `₹ ${currencyFormater(
                    parseInt(newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver)
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "imt-23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
            ]);
          }
        }
      }

      if (TypeReturn(type) === "car") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
          ]);
        } else {
          AddonArray.push([
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? `₹ ${currencyFormater(
                  parseInt(
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? newGroupedQuotesCompare[1]?.multiYearCpa
                      : newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver
                  )
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
          ]);
        }
      } else if (TypeReturn(type) === "bike") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
          ]);
        } else {
          AddonArray.push([
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? `₹ ${currencyFormater(
                  parseInt(
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? newGroupedQuotesCompare[1]?.multiYearCpa
                      : newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver
                  )
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
          ]);
        }
      } else if (TypeReturn(type) === "cv") {
        if (temp_data.journeyCategory === "GCV") {
          AddonArray.push([
            temp_data?.odOnly
              ? ""
              : addOnsAndOthers?.selectedCpa?.includes(
                  "Compulsory Personal Accident"
                )
              ? `₹ ${currencyFormater(
                  parseInt(newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver)
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "imt-23",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0]
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1]
            )}`,
          ]);
        } else {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "imt-23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
            ]);
          } else {
            AddonArray.push([
              temp_data?.odOnly
                ? ""
                : addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )
                ? `₹ ${currencyFormater(
                    parseInt(newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver)
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "imt-23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1]
              )}`,
            ]);
          }
        }
      }

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        if (TypeReturn(type) === "car") {
          if (
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
            ]);
          } else {
            AddonArray.push([
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? `₹ ${currencyFormater(
                    parseInt(
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? newGroupedQuotesCompare[2]?.multiYearCpa
                        : newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver
                    )
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
            ]);
          }
        } else if (TypeReturn(type) === "bike") {
          if (
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
            ]);
          } else {
            AddonArray.push([
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? `₹ ${currencyFormater(
                    parseInt(
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? newGroupedQuotesCompare[2]?.multiYearCpa
                        : newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver
                    )
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
            ]);
          }
        } else if (TypeReturn(type) === "cv") {
          if (temp_data.journeyCategory === "GCV") {
            AddonArray.push([
              temp_data?.odOnly
                ? ""
                : addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )
                ? `₹ ${currencyFormater(
                    parseInt(newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver)
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "imt-23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0]
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2]
              )}`,
            ]);
          } else {
            if (
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
            ) {
              AddonArray.push([
                `${GetAddonValueNoBadge(
                  "zeroDepreciation",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "roadSideAssistance",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "imt-23",
                  newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[0]
                )}`,
                `${GetAddonValueNoBadge(
                  "keyReplace",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "engineProtector",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "ncbProtection",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "consumables",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "tyreSecure",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "returnToInvoice",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "lopb",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
              ]);
            } else {
              AddonArray.push([
                temp_data?.odOnly
                  ? ""
                  : addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    )
                  ? `₹ ${currencyFormater(
                      parseInt(
                        newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver
                      )
                    )}`
                  : "Optional",
                `${GetAddonValueNoBadge(
                  "zeroDepreciation",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "roadSideAssistance",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "imt-23",
                  newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[0]
                )}`,
                `${GetAddonValueNoBadge(
                  "keyReplace",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "engineProtector",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "ncbProtection",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "consumables",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "tyreSecure",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "returnToInvoice",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
                `${GetAddonValueNoBadge(
                  "lopb",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2]
                )}`,
              ]);
            }
          }
        }
      }

      var totalPremiumArray = [];
      totalPremiumArray.push(
        `₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[0]?.finalPremium1)
        )}`
      );
      totalPremiumArray.push(
        `₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[1]?.finalPremium1)
        )}`
      );

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        totalPremiumArray.push(
          `₹ ${currencyFormater(
            parseInt(newGroupedQuotesCompare[2]?.finalPremium1)
          )}`
        );
      }

      var insurerDetailsArray = [];

      insurerDetailsArray.push({
        logo: newGroupedQuotesCompare[0]?.companyLogo,
        idv: `IDV: ${`₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[0]?.idv)
        )}`}`,
        buy_link: `${window.location.protocol}//${
          window.location.host
        }${window.location.pathname.replace(
          /compare-quote/g,
          "quotes"
        )}?enquiry_id=${enquiry_id}`,
        buy_link_text: `Buy Now ₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[0]?.finalPremium1)
        )}`,
        productId: newGroupedQuotesCompare[0]?.policyId,
        selectedType: selectedTab === "tab2" ? Encrypt(selectedTab) : "",
        selectedTerm:
          shortTerm && selectedTab !== "tab2" ? Encrypt(shortTerm) : "",
      });
      insurerDetailsArray.push({
        logo: newGroupedQuotesCompare[1]?.companyLogo,
        idv: `IDV: ${`₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[1]?.idv)
        )}`}`,
        buy_link: `${window.location.protocol}//${
          window.location.host
        }${window.location.pathname.replace(
          /compare-quote/g,
          "quotes"
        )}?enquiry_id=${enquiry_id}`,
        buy_link_text: `Buy Now ₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[1]?.finalPremium1)
        )}`,
        productId: newGroupedQuotesCompare[1]?.policyId,
        selectedType: selectedTab === "tab2" ? Encrypt(selectedTab) : "",
        selectedTerm:
          shortTerm && selectedTab !== "tab2" ? Encrypt(shortTerm) : "",
      });

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        insurerDetailsArray.push({
          logo: newGroupedQuotesCompare[2]?.companyLogo,
          idv: `IDV: ${`₹ ${currencyFormater(
            parseInt(newGroupedQuotesCompare[2]?.idv)
          )}`}`,
          buy_link: `${window.location.protocol}//${
            window.location.host
          }${window.location.pathname.replace(
            /compare-quote/g,
            "quotes"
          )}?enquiry_id=${enquiry_id}`,
          buy_link_text: `Buy Now ₹ ${currencyFormater(
            parseInt(newGroupedQuotesCompare[2]?.finalPremium1)
          )}`,
          productId: newGroupedQuotesCompare[2]?.policyId,
          selectedType: selectedTab === "tab2" ? Encrypt(selectedTab) : "",
          selectedTerm:
            shortTerm && selectedTab !== "tab2" ? Encrypt(shortTerm) : "",
        });
      }

      var uspList = [];
      uspList.push([
        newGroupedQuotesCompare[0]?.usp && newGroupedQuotesCompare[0]?.usp[0]
          ? newGroupedQuotesCompare[0]?.usp[0]?.usp_desc
          : "x",
        newGroupedQuotesCompare[0]?.usp && newGroupedQuotesCompare[0]?.usp[1]
          ? newGroupedQuotesCompare[0]?.usp[1]?.usp_desc
          : "x",
        newGroupedQuotesCompare[0]?.usp && newGroupedQuotesCompare[0]?.usp[2]
          ? newGroupedQuotesCompare[0]?.usp[2]?.usp_desc
          : "x",
      ]);
      uspList.push([
        newGroupedQuotesCompare[1]?.usp && newGroupedQuotesCompare[1]?.usp[0]
          ? newGroupedQuotesCompare[1]?.usp[0]?.usp_desc
          : "x",

        newGroupedQuotesCompare[1]?.usp && newGroupedQuotesCompare[1]?.usp[1]
          ? newGroupedQuotesCompare[1]?.usp[1]?.usp_desc
          : "x",
        newGroupedQuotesCompare[1]?.usp && newGroupedQuotesCompare[1]?.usp[2]
          ? newGroupedQuotesCompare[1]?.usp[2]?.usp_desc
          : "x",
      ]);

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        uspList.push([
          newGroupedQuotesCompare[2]?.usp && newGroupedQuotesCompare[2]?.usp[0]
            ? newGroupedQuotesCompare[2]?.usp[0]?.usp_desc
            : "x",

          newGroupedQuotesCompare[2]?.usp && newGroupedQuotesCompare[2]?.usp[1]
            ? newGroupedQuotesCompare[2]?.usp[1]?.usp_desc
            : "x",

          newGroupedQuotesCompare[2]?.usp && newGroupedQuotesCompare[2]?.usp[2]
            ? newGroupedQuotesCompare[2]?.usp[2]?.usp_desc
            : "x",
        ]);
      }

      console.log("discount", discountArray);
      console.log("discounts", additionalArray);
      console.log("AddonArrayAddonArray", AddonArray);

      var data = {
        broker_logo: getLogoUrl(),
        broker_name: process.env?.REACT_APP_TITLE,
        broker_website:
          `http://${window.location.hostname}` || getBrokerWebsite(),
        broker_phone: brokerNumberFunction(),
        quote_color_code: (Theme?.QuoteCard?.color || "#bdd400").slice(1),
        broker_email: brokerEmailFunction(),
        policy_type: newGroupedQuotesCompare[0]?.productName,
        policy_type_logo: getPolicyType(type),
        vehicle_reg_no_title: "Vehicle Reg No",
        vehicle_reg_no: temp_data?.regNo || temp_data?.rtoNumber,
        vehicle_model_title: "Make and Model",
        vehicle_model: `${newGroupedQuotesCompare[0]?.mmvDetail?.manfName}-${newGroupedQuotesCompare[0]?.mmvDetail?.modelName}-${newGroupedQuotesCompare[0]?.mmvDetail?.versionName}-${newGroupedQuotesCompare[0]?.mmvDetail?.cubicCapacity}cc `,
        vehicle_reg_date_title: "Reg Date / Mfg Year",
        vehicle_reg_date: newGroupedQuotesCompare[0]?.vehicleRegisterDate,
        IRDIANumber: getIRDAI(),
        vehicle_insurance_type_title: "Insurance Type",
        vehicle_insurance_type:
          newGroupedQuotesCompare[0]?.businessType &&
          newGroupedQuotesCompare[0]?.businessType
            .split(" ")
            .map(_.capitalize)
            .join(" "),
        quote_id_title: "Quote ID: ",
        quote_id: enquiry_id,
        quote_date_title: "Quote Date: ",
        quote_date: moment().format("DD-MM-YYYY"),
        customer_name_title: "Customer Name :",
        customer_name:
          temp_data?.firstName && temp_data?.lastName
            ? temp_data?.firstName + " " + temp_data?.lastName
            : "",
        customer_phone_title: "Customer Number :",
        customer_phone: temp_data?.mobileNo || " ",
        customer_email_title: "Customer Email :",
        customer_email: temp_data?.emailId || " ",
        ...(!_.isEmpty(temp_data?.agentDetails) &&
          !_.isEmpty(
            temp_data?.agentDetails.filter((item) => item.sellerType === "P")
          ) && {
            pos_name_title:
              process.env.REACT_APP_BROKER === "EPOCH"
                ? "Policylo Team"
                : "POS Name:",
            pos_name: temp_data?.agentDetails.filter(
              (item) => item.sellerType === "P"
            )[0]?.agentName,
            pos_phone_title:
              process.env.REACT_APP_BROKER === "EPOCH" ? "" : "POS Phone:",
            pos_phone: temp_data?.agentDetails.filter(
              (item) => item.sellerType === "P"
            )[0]?.agentMobile,
          }),
        ic_usp_title: "IC USP",
        ic_usp: uspList,
        premium_breakup_title: "Premium Breakup",
        premium_breakup: premiumBreakupArray,
        accessories_title: "Accessories",
        accessories: accessoriesArray,
        ...(!temp_data?.odOnly && {
          additonal_cover_title: "Additional Covers",
          additonal_cover:
            temp_data.journeyCategory === "GCV"
              ? additionalArrayGcv
              : additionalArray,
        }),
        discount_title: "Discounts",
        discounts: discountArray,
        addons_title: "Addons",
        addons: AddonArray,
        premium_breakup_total_title: "Total Premium (inc GST)",
        premium_breakup_total: totalPremiumArray,
        insurance_details: insurerDetailsArray,
        color_scheme: Theme?.links?.color,
      };
    }
    console.log(data, "pdfdata");
    dispatch(setComparePdfDatad(data));
  }, [newGroupedQuotesCompare]);

  const lessThan767 = useMediaPredicate("(max-width: 767px)");

  let ElectricalValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecZDPremium
          ? quote?.accessoriesAddons?.electrical?.elecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecENGPremium
          ? quote?.accessoriesAddons?.electrical?.elecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.electrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecNCBPremium
          ? quote?.accessoriesAddons?.electrical?.elecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecCOCPremium
          ? quote?.accessoriesAddons?.electrical?.elecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let NonElectricalValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let BiFuelValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium * 1
          : 0);
    }
    return val;
  };

  return (
    <TopDiv>
      <div className="compare-products-list">
        <ul className="cd-products-columns">
          {(lessThan767
            ? newGroupedQuotesCompare.slice(0, 2)
            : newGroupedQuotesCompare
          )?.map((item, index) => (
            <Product
              quote={item}
              index={index}
              length={newGroupedQuotesCompare?.length}
              type={type}
              setPrevPopup={setPrevPopup}
              prevPopup={prevPopup}
              setSelectedId={setSelectedId}
              setSelectedCompanyName={setSelectedCompanyName}
              validQuote={validQuote}
              popupCard={false}
              setSelectedIcId={setSelectedIcId}
              setSelectedCompanyAlias={setSelectedCompanyAlias}
              setApplicableAddonsLits={setApplicableAddonsLits}
              scrollPosition={scrollPosition}
            />
          ))}
        </ul>
      </div>
    </TopDiv>
  );
}

export default CompareProductsList;

const TopDiv = styled.div`
  .compare-products-list {
    & .top-info {
      font-family: ${({ theme }) =>
        theme.regularFont?.fontFamily || "Inter-Regular"};
      font-size: 14px;
      // line-height: 19px;
      color: #333;
      line-height: 14px;
      padding: 0;
      text-align: left;
      position: relative;
      height: 250px;
      text-align: center;
      padding: 0;
      -webkit-transition: height 0.3s;
      -moz-transition: height 0.3s;
      transition: height 0.3s;
    }
    & .cd-products-columns {
      /* width: 1200px; */
      margin-left: 260px;
      list-style: none;
      padding: 0;
      &::after {
        clear: both;
        content: "";
        display: table;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1177px) {
      & .cd-products-columns {
        width: 554px;
        margin-left: 156px;
      }
    }
    @media screen and (max-width: 767px) {
      & .cd-products-columns {
        margin-left: 20px !important;
      }
    }
  }
`;
