import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _, { toInteger } from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Loader } from "components";
import "./product.scss";
import { set_temp_data } from "modules/Home/home.slice";
import { Badge } from "react-bootstrap";
import { currencyFormater } from "utils";
import {
  setSelectedQuote,
  SaveQuotesData,
  clear,
  setQuotesList,
  saveSelectedQuoteResponse,
  SaveAddonsData,
} from "../../quotesPage/quote.slice";
import { differenceInDays } from "date-fns";
import moment from "moment";
import { toDate } from "utils";
import { useMediaPredicate } from "react-media-hook";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { compareQuotes, setShowPop } from "../../quotesPage/quote.slice";
import { setTempData } from "../../quotesPage/filterConatiner/quoteFilter.slice";
import Popup from "components/Popup/Popup";
import { useForm } from "react-hook-form";
import ProductPopup from "./ProductPopup";
import { TypeReturn } from "modules/type";
import { BlockedSections } from "modules/quotesPage/addOnCard/cardConfig";

function Product({
  quote,
  length,
  type,
  setPrevPopup,
  prevPopup,
  setSelectedId,
  setSelectedCompanyName,
  validQuote,
  popupCard,
  setSelectedIcId,
  setSelectedCompanyAlias,
  setApplicableAddonsLits,
  scrollPosition,
  index,
}) {
  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const { temp_data } = useSelector((state) => state.home);
  const { showPop } = useSelector((state) => state.quotes);
  const typeId = query.get("typeid");
  const enquiry_id = query.get("enquiry_id");
  const { prevInsList, tempData } = useSelector((state) => state.quoteFilter);
  const {
    addOnsAndOthers,
    finalPremiumlist,
    saveQuoteResponse,
    saveQuoteLoader,
    compareQuotesList,
    quoteComprehesive,
    quotesList,
    updateQuoteLoader,
  } = useSelector((state) => state.quotes);

  console.log("fquote", quote);

  console.log("Jouney", temp_data.productSubTypeCode);
  console.log("quot", quote);
  console.log("indexx", index);

  var finalPremiumListFiltered = _.map(finalPremiumlist, function (o) {
    if (Number(o?.policyId) === Number(quote?.policyId)) return o;
  });

  var filteredTotal = _.without(finalPremiumListFiltered, undefined);

  const GetAddonAvailability = (addonName) => {
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let additional = Object.keys(quote?.addOnsData?.additional);

    if (inbuilt?.includes(addonName)) {
      return true;
    } else if (
      additional?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const GetAddonValue = (addonName, addonDiscountPercentage) => {
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let additional = Object.keys(quote?.addOnsData?.additional);
    let selectedAddons = addOnsAndOthers?.selectedAddons;

    if (inbuilt?.includes(addonName)) {
      return (
        <span className="addonValueText">
          {/* <Badge
						variant="primary"
						style={{ position: "relative", bottom: "2px" }}
					>
					Inbuilt
					</Badge> */}
          {Number(quote?.addOnsData?.inBuilt[addonName]) !== 0 ? (
            `₹ ${currencyFormater(
              parseInt(
                quote?.company_alias === "royal_sundaram"
                  ? addonName === "ncbProtection" ||
                    addonName === "engineProtector" ||
                    addonName === "tyreSecure"
                    ? quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      200
                      ? 200
                      : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100
                    : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      100
                    ? 100
                    : quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100
                  : quote?.addOnsData?.inBuilt[addonName]
              )
            )}`
          ) : (
            <>
              {addonName === "roadSideAssistance" &&
              quote?.company_alias === "reliance" ? (
                <>-</>
              ) : (
                <Badge
                  variant=""
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Included
                </Badge>
              )}
            </>
          )}
        </span>
      );

      //	return `Inbuilt ₹ ${quote?.addOnsData?.inBuilt[addonName]}`;
    } else if (
      additional?.includes(addonName) &&
      selectedAddons?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
      typeof quote?.addOnsData?.additional[addonName] === "number"
    ) {
      return `₹ ${currencyFormater(
        parseInt(
          quote?.company_alias === "royal_sundaram"
            ? addonName === "ncbProtection" ||
              addonName === "engineProtector" ||
              addonName === "tyreSecure"
              ? quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100 <
                200
                ? 200
                : quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100
              : quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100 <
                100
              ? 100
              : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100
            : quote?.addOnsData?.additional[addonName]
        )
      )}`;
    } else if (
      additional?.includes(addonName) &&
      //	selectedAddons.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) === 0
    ) {
      return "N/A";
    } else if (
      !additional?.includes(addonName) &&
      selectedAddons?.includes(addonName)
    ) {
      return "N/A";
    } else if (
      !(additional?.includes(addonName) || inbuilt?.includes(addonName))
    ) {
      return "N/A";
    } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
      return "N/A";
    } else if (
      additional?.includes(addonName) &&
      !selectedAddons?.includes(addonName)
    ) {
      return "N/S";
    } else {
      return "N/A";
    }
  };

  // const GetAddonValue = (addonName, addonDiscountPercentage) => {

  //   let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
  //   let additional = Object.keys(quote?.addOnsData?.additional);
  //   let selectedAddons = addOnsAndOthers?.selectedAddons;
  //   console.log("selectedAddons",selectedAddons);
  //   console.log("additional", additional)
  //   console.log("addonName", addonName)
  //   if (inbuilt?.includes(addonName)) {
  //     return (
  //       <span className="addonValueText">
  //         {Number(quote?.addOnsData?.inBuilt[addonName]) !== 0 ? (
  //           `₹ ${currencyFormater(
  //             parseInt(
  //               quote?.company_alias === "royal_sundaram"
  //                 ? (addonName === "ncbProtection" ||
  //                   addonName === "engineProtector" ||
  //                   addonName === "tyreSecure"
  //                     ? quote?.addOnsData?.inBuilt[addonName] -
  //                         (quote?.addOnsData?.inBuilt[addonName] *
  //                           addonDiscountPercentage) /
  //                           100 <
  //                       200
  //                       ? 200
  //                       : quote?.addOnsData?.inBuilt[addonName] -
  //                         (quote?.addOnsData?.inBuilt[addonName] *
  //                           addonDiscountPercentage) /
  //                           100
  //                     : quote?.addOnsData?.inBuilt[addonName] -
  //                         (quote?.addOnsData?.inBuilt[addonName] *
  //                           addonDiscountPercentage) /
  //                           100 <
  //                       100
  //                     ? 100
  //                     : quote?.addOnsData?.inBuilt[addonName] -
  //                       (quote?.addOnsData?.inBuilt[addonName] *
  //                         addonDiscountPercentage) /
  //                         100) * 1.18
  //                 : quote?.addOnsData?.inBuilt[addonName] * 1.18
  //             )
  //           )}`
  //         ) : (
  //           <>
  //           {addonName === "roadSideAssistance" &&
  //             quote?.company_alias === "reliance" ? (
  //               <>-</>
  //             ) : (
  //               <Badge
  //                 variant=""
  //                 style={{
  //                   cursor: "pointer",
  //                 }}
  //               >
  //                 Included
  //               </Badge>
  //             )}
  //           </>
  //         )}
  //       </span>
  //     );

  //     //	return `Inbuilt ₹ ${quote?.addOnsData?.inBuilt[addonName]}`;
  //   } else if (
  //     additional?.includes(addonName) &&
  //     selectedAddons?.includes(addonName) &&
  //     Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
  //     typeof quote?.addOnsData?.additional[addonName] === "number"
  //   ) {
  //     return `₹ ${currencyFormater(
  //       parseInt(
  //         quote?.company_alias === "royal_sundaram"
  //           ? (addonName === "ncbProtection" ||
  //             addonName === "engineProtector" ||
  //             addonName === "tyreSecure"
  //               ? quote?.addOnsData?.additional[addonName] -
  //                   (quote?.addOnsData?.additional[addonName] *
  //                     addonDiscountPercentage) /
  //                     100 <
  //                 200
  //                 ? 200
  //                 : quote?.addOnsData?.additional[addonName] -
  //                   (quote?.addOnsData?.additional[addonName] *
  //                     addonDiscountPercentage) /
  //                     100
  //               : quote?.addOnsData?.additional[addonName] -
  //                   (quote?.addOnsData?.additional[addonName] *
  //                     addonDiscountPercentage) /
  //                     100 <
  //                 100
  //               ? 100
  //               : quote?.addOnsData?.additional[addonName] -
  //                 (quote?.addOnsData?.additional[addonName] *
  //                   addonDiscountPercentage) /
  //                   100) * 1.18
  //           : quote?.addOnsData?.additional[addonName] * 1.18
  //       )
  //     )}`;
  //   } else if (
  //     additional?.includes(addonName) &&
  //     //	selectedAddons.includes(addonName) &&
  //     Number(quote?.addOnsData?.additional[addonName]) === 0
  //   ) {
  //     return "N/A";
  //   } else if (
  //     !additional?.includes(addonName) &&
  //     selectedAddons?.includes(addonName)
  //   ) {
  //     return "N/A";
  //   } else if (
  //     !(additional?.includes(addonName) || inbuilt?.includes(addonName))
  //   ) {
  //     return "N/A";
  //   } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
  //     return "N/A";
  //   } else if (
  //     additional?.includes(addonName) &&
  //     !selectedAddons?.includes(addonName)
  //   ) {
  //     return "N/S";
  //   } else {
  //     return "N/A";
  //   }
  // };

  //getting addon names

  const getAddonName = (addon) => {
    switch (addon) {
      case "roadSideAssistance":
        return "Road Side Assistance";
      case "zeroDepreciation":
        return "Zero Depreciation";
      case "imt23":
        return "IMT - 23";
      case "keyReplace":
        return "Key Replacement";
      case "engineProtector":
        return "Engine Protector";
      case "ncbProtection":
        return "NCB Protection";
      case "consumables":
        return "Consumable";
      case "tyreSecure":
        return "Tyre Secure";
      case "returnToInvoice":
        return "Return To Invoice";
      case "lopb":
        return "Loss of Personal Belongings";

      default:
        return "";
    }
  };

  //setting changed premium

  //displayBasePremCaclc

  //display logic of addon card car

  //********************************************************** */END OF  CALCULATIONS

  //handle buy now

  //final save and popup conditions

  const [daysToExpiry, setDaysToExpiry] = useState(false);

  useEffect(() => {
    let a = temp_data?.expiry;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    setDaysToExpiry(diffDays);
  }, [temp_data?.expiry]);

  let prevInsName = prevInsList.filter((i) => i.tataAig === temp_data?.prevIc);

  const [prevIcData, setPrevIcData] = useState(false);

  //---------------------applicable addons-------------------------
  const [applicableAddons, setApplicableAddons] = useState(null);
  useEffect(() => {
    if (temp_data?.tab !== "tab2") {
      let additional = Object.keys(quote?.addOnsData?.additional);
      let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
      let selectedAddons = addOnsAndOthers?.selectedAddons || [];
      let additionalList = quote?.addOnsData?.additional;
      let inbuiltList = quote?.addOnsData?.inBuilt;
      var addonsSelectedList = [];
      if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
        selectedAddons.forEach((el) => {
          if (additional?.includes(el) && Number(additionalList[el])) {
            var newList = {
              name: getAddonName(el),
              premium: Number(additionalList[el]),
            };
            addonsSelectedList.push(newList);
          }
        });

        inbuilt.forEach((el) => {
          var newList = {
            name: getAddonName(el),
            premium: Number(inbuiltList[el]),
          };
          addonsSelectedList.push(newList);
        });

        setApplicableAddons(addonsSelectedList);
      } else {
        setApplicableAddons([]);
      }
    }
  }, [addOnsAndOthers?.selectedAddons, quote]);
  //----------------addonTotalCaluclatuion-----------------------

  useEffect(() => {
    if (temp_data?.prevIc && temp_data?.prevIc !== "Not selected") {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);
  console.log("applicable-addons", quote?.applicableAddons);
  const handleClick = async () => {
    if (
      !temp_data?.newCar &&
      !prevIcData &&
      !popupCard &&
      tempData?.policyType !== "Third-party" &&
      (quote?.policyType === "Comprehensive" ||
        quote?.policyType === "Short Term" ||
        quote?.policyType === "Own Damage") &&
      daysToExpiry < 90
    ) {
      // if (!temp_data?.newCar) {
      setPrevPopup(true);
      setSelectedId(quote?.policyId);
      setSelectedCompanyName(quote?.companyName);
      setSelectedCompanyAlias(quote?.company_alias);
      setApplicableAddonsLits(
        !_.isEmpty(quote?.applicableAddons1) &&
          quote?.applicableAddons1.map((x) => x.name)
      );
      setSelectedIcId(quote?.companyId);
      dispatch(
        setTempData({
          oldPremium: quote?.finalPremium1,
        })
      );
    }
    //		setOnSubmit(true);
    else if (
      !prevPopup &&
      (temp_data?.newCar ||
        prevIcData ||
        quote?.policyType === "Third Party" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90)
    ) {
      dispatch(setSelectedQuote(quote));

      if (
        temp_data?.tab === "tab2" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90
      ) {
        if (!temp_data?.newCar) {
          dispatch(
            set_temp_data({
              //	ncb: "0%",
              //	newNcb: "0%",
              //	tab: "tab2",
              prevIc: "others",
              prevIcFullName: "others",
            })
          );
        }

        var newSelectedAccesories = [];
        if (
          addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          )
        ) {
          var newD = {
            name: "External Bi-Fuel Kit CNG/LPG",
            sumInsured: Number(addOnsAndOthers?.externalBiFuelKit),
          };
          newSelectedAccesories.push(newD);
        }
        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,

          addonData: {
            addons: null,
            accessories: newSelectedAccesories,
            discounts: null,
          },
        };

        dispatch(SaveAddonsData(data1));
      } else {
        let addonLists = [];
        let addonListRedux = addOnsAndOthers?.selectedAddons || [];

        addonListRedux.forEach((el) => {
          let data = {
            name: getAddonName(el),
          };
          addonLists.push(data);
        });

        var data2 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,

          addonData: {
            addons: addonLists,
            compulsory_personal_accident:
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? [{ name: "Compulsory Personal Accident" }]
                : [
                    {
                      reason:
                        "I have another motor policy with PA owner driver cover in my name",
                    },
                  ],
          },
        };
        dispatch(SaveAddonsData(data2));
      }

      var QuoteData = {
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        icId: quote?.companyId,
        icAlias: quote?.companyName,
        productSubTypeId: quote?.productSubTypeId,
        masterPolicyId: quote?.masterPolicyId?.policyId,
        premiumJson: {
          ...quote,
          ...(quote?.companyAlias === "royal_sundaram" && {
            addOnsData: {
              ...quote?.addOnsData,
              ...(!_.isEmpty(quote?.addOnsData?.additional)
                ? {
                    additional: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.additional).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v -
                                  (v * quote?.addonDiscountPercentage1) / 100 <
                                200
                                ? 200
                                : v -
                                  (v * quote?.addonDiscountPercentage1) / 100
                              : v -
                                  (v * quote?.addonDiscountPercentage1) / 100 <
                                100
                              ? 100
                              : v - (v * quote?.addonDiscountPercentage1) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
              ...(!_.isEmpty(quote?.addOnsData?.inBuilt)
                ? {
                    inBuilt: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.inBuilt).map(
                        ([k, v]) => [
                          k,
                          [
                            "ncbProtection",
                            "engineProtector",
                            "tyreSecure",
                          ].includes(k)
                            ? v * 1 !== 0
                              ? v -
                                  (v * quote?.addonDiscountPercentage1) / 100 <
                                200
                                ? 200
                                : v -
                                  (v * quote?.addonDiscountPercentage1) / 100
                              : v -
                                  (v * quote?.addonDiscountPercentage1) / 100 <
                                100
                              ? 100
                              : v - (v * quote?.addonDiscountPercentage1) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
            },
          }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.coverUnnamedPassengerValue * 1 && {
              coverUnnamedPassengerValue:
                quote?.coverUnnamedPassengerValue *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.motorAdditionalPaidDriver * 1 && {
              motorAdditionalPaidDriver:
                quote?.motorAdditionalPaidDriver *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
        },
        exShowroomPriceIdv: quote?.idv,
        exShowroomPrice: quote?.showroomPrice,
        finalPremiumAmount: quote?.finalPremium1,
        odPremium:
          quote?.finalOdPremium * 1 +
          ElectricalValue(quote) +
          NonElectricalValue(quote) +
          BiFuelValue(quote),
        tpPremium: quote?.totalPremiumB1,
        addonPremiumTotal: quote?.totalAddon1,
        revisedNcb: quote?.revisedNcb1,
        serviceTax: quote?.gst1,
        applicableAddons:
          quote?.companyAlias === "royal_sundaram"
            ? !_.isEmpty(applicableAddons)
              ? applicableAddons?.map((el) => ({
                  ...el,
                  ...{
                    premium:
                      el?.premium * 1 !== 0
                        ? [
                            "NCB Protection",
                            "Engine Protector",
                            "Tyre Secure",
                          ].includes(el?.name)
                          ? el?.premium -
                              (el?.premium * quote?.addonDiscountPercentage1) / 100 <
                            200
                            ? 200
                            : el?.premium -
                              (el?.premium * quote?.addonDiscountPercentage1) / 100
                          : el?.premium -
                              (el?.premium * quote?.addonDiscountPercentage1) / 100 <
                            100
                          ? 100
                          : el?.premium -
                            (el?.premium * quote?.addonDiscountPercentage1) / 100
                        : 0,
                  },
                }))
              : []
            : applicableAddons,
        prevInsName: prevInsName[0]?.previousInsurer,
      };
      dispatch(SaveQuotesData(QuoteData));
    }
  };

  useEffect(() => {
    if (saveQuoteResponse && !updateQuoteLoader) {
      history.push(
        `/${type}/proposal-page?enquiry_id=${enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}`
      );
      dispatch(saveSelectedQuoteResponse(false));
      dispatch(setQuotesList([]));
      dispatch(clear());
    }
  }, [saveQuoteResponse, updateQuoteLoader]);

  const lessThan768 = useMediaPredicate("(max-width: 768px)");

  // const [showPop, setShowPop] = useState(true);

  console.log("showPop", showPop);

  // const [policyIds, setPolicyIds] = useState(
  //   _.compact(compareQuotesList.map((x) => x.policyId))
  // );

  // useEffect(() => {
  //   if (compareQuotesList) {
  //     setPolicyIds(_.compact(compareQuotesList.map((x) => x.policyId)));
  //   }
  // }, [compareQuotesList]);

  // const [ids, setIds] = useState(policyIds);

  // console.log("All IDS", ids);

  // const compareFn = (singleQuote) => {
  //   console.log("Single Quote", singleQuote);
  //   if (ids?.includes(singleQuote.policyId)) {
  //     if (ids?.length > 1) {
  //       let x = _.compact(compareQuotesList.map((x) => x.policyId));
  //       console.log("X", x);
  //       const allIds = _.without(x, singleQuote.policyId);
  //       console.log("All the Ids", allIds);
  //       setIds(allIds);
  //     }
  //   } else {
  //     if (
  //       (ids?.length < 3 && !lessThan768) ||
  //       (ids?.length < 2 && lessThan768)
  //     ) {
  //       let allIds = [...ids, singleQuote.policyId];
  //       console.log("All Ids", allIds);
  //       setIds(allIds);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (!_.isEmpty(ids)) {
  //     let allQuotesData = !_.isEmpty(tempData?.quoteComprehesiveGrouped)
  //       ? _.compact(
  //           tempData?.quoteComprehesiveGrouped?.map((x) =>
  //             ids.includes(x?.policyId) ? x : null
  //           )
  //         )
  //       : [];

  //     if (!_.isEmpty(allQuotesData)) dispatch(compareQuotes(allQuotesData));
  //   }
  // }, [ids]);

  // Removing single quote from comparision
  // const compareFn2 = (singleQuote) => {
  //   let x = _.compact(compareQuotesList.map((x) => x.policyId));
  //   let allIds = x.filter((item) => item !== singleQuote.policyId);
  //   console.log("Remove Ids", allIds);

  //   setIds(allIds);
  // };

  // Scrolling event for buy now button

  // const { y: pageYOffset } = useWindowScroll();
  const YOffSet = window.pageYOffset;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (YOffSet > 50) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [YOffSet]);

  let ElectricalValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecZDPremium
          ? quote?.accessoriesAddons?.electrical?.elecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecENGPremium
          ? quote?.accessoriesAddons?.electrical?.elecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.electrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecNCBPremium
          ? quote?.accessoriesAddons?.electrical?.elecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecCOCPremium
          ? quote?.accessoriesAddons?.electrical?.elecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let NonElectricalValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let BiFuelValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium * 1
          : 0);
    }
    return val;
  };

  console.log("comparequote", quote);
  console.log("validQuote", validQuote.length);

  const openPop = () => {
    dispatch(setShowPop(true));
  };

  // const removeItem = (quote) => {
  //   let items =
  // }

  const removeFn = (singleQuote) => {
    let allQuotes = compareQuotesList?.filter(
      (x) => x.policyId !== singleQuote.policyId
    );
    dispatch(compareQuotes(allQuotes));
  };

  return (
    <>
      <TopDiv>
        <TopLi
          className={`compare-page-product compare-page-container ${
            length < 3 ? `compare-page-product--${length}` : ""
          } `}
        >
          {lessThan768 && visible && (
            <div
              className="myDiv"
              style={{
                position: "fixed",
                height: "190px",
                width: "100%",
                background: "#fff",
                zIndex: "999",
                top: "0",
                right: "0",
                left: "0",
              }}
            ></div>
          )}
          {/* {lessThan768 && visible && (
            <div
              style={{
                width: "35%",
                position: "fixed",
                zIndex: "9999",
                textAlign: "center",
                bottom: "10px",
              }}
            >
              <button
                className="buyNowBtn"
                style={{ width: "100%" }}
                onClick={handleClick}
              >
                BUY NOW ₹ {currencyFormater(quote?.finalPremium1)}
              </button>
            </div>
          )} */}
          {quote?.idv ? (
            <TopInfo
              className={`top-info ${
                lessThan768 && visible && "mobile-top-info"
              }`}
              style={{ padding: lessThan768 ? "10px 0px" : "" }}
              fixed={
                lessThan768
                  ? false
                  : scrollPosition >
                    (Theme?.QuoteBorderAndFont?.scrollHeight
                      ? Theme?.QuoteBorderAndFont?.scrollHeight
                      : 68)
                  ? true
                  : false
              }
            >
              {!popupCard && quote?.isRenewal === "Y" ? (
                <FoldedRibbon>Renewal Quote</FoldedRibbon>
              ) : (
                <noscript />
              )}
              {quote?.idv && validQuote.length > 1 && (
                <CloseContainer>
                  <CloseSharpIcon
                    onClick={() => removeFn(quote)}
                    // onClick={() => removeItem(quote)}
                    // onClick={() => compareFn2(quote)}
                    style={{
                      position: "absolute",
                      top: lessThan768 ? "-8px" : "-15px",
                      right: lessThan768 ? "-10px" : "-12px",
                      fontSize: lessThan768 ? "18px" : "24px",
                      background: "#fff",
                      borderRadius: "50%",

                      padding: "3px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      cursor: `${validQuote?.length > 1 ? "pointer" : ""}`,
                      color: `${validQuote?.length > 1 ? "black" : "grey"}`,
                    }}
                  />
                </CloseContainer>
              )}
              <div className="compare-page-product__logo-wrap">
                <img src={quote?.companyLogo} alt="plan" />
              </div>
              <p>
                <br />
                <strong>
                  <span style={{ fontSize: "14px" }}> IDV ₹</span>{" "}
                  {currencyFormater(quote?.idv)}
                </strong>
              </p>
              <div className="planAmt"></div>
              <div className="buy_now_div">
                <RecPlanBuyBtn className="recPlanBuyBtn" onClick={handleClick}>
                  {!lessThan768 && (
                    <span style={{ fontSize: "10px" }}>BUY NOW</span>
                  )}{" "}
                  ₹ {currencyFormater(quote?.finalPremium1)}
                </RecPlanBuyBtn>
              </div>
            </TopInfo>
          ) : (
            <TopInfo
              className={`top-info ${
                lessThan768 && visible && "mobile-top-info"
              }`}
              fixed={
                lessThan768
                  ? false
                  : scrollPosition >
                    (Theme?.QuoteBorderAndFont?.scrollHeight
                      ? Theme?.QuoteBorderAndFont?.scrollHeight
                      : 68)
                  ? true
                  : false
              }
            >
              <div
                style={{
                  height: "185px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // onClick={ document.getElementById(`addPlan1`) && document.getElementById(`addPlan1`).click() }
              >
                <AddPlanIcon
                  // id={`addPlan${index}`}
                  onClick={openPop}
                  className="fa fa-plus"
                  style={{
                    cursor: "pointer",
                    fontSize: lessThan768 ? "15px" : "25px",
                    //background: "#f0f0f0",
                    width: lessThan768 ? "30px" : "50px",
                    height: lessThan768 ? "30px" : "50px",
                    borderRadius: "15%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    //	boxShadow: "1px 1px 5px grey",
                  }}
                ></AddPlanIcon>
                <p
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    fontSize: lessThan768 && "12px",
                  }}
                >
                  Add Plans
                </p>
              </div>
            </TopInfo>
          )}
          <DataCard
            className={`${lessThan768 && visible && "mobile-data-card"}`}
            fixed={
              lessThan768
                ? false
                : scrollPosition >
                  (Theme?.QuoteBorderAndFont?.scrollHeight
                    ? Theme?.QuoteBorderAndFont?.scrollHeight
                    : 68)
                ? true
                : false
            }
          >
            {quote?.idv && (
              <ul className="cd-features-list">
                <li
                  className="features-list-top-list icUsp"
                  style={{ paddingTop: lessThan768 ? "60px !important" : "" }}
                >
                  {quote?.usp?.length > 0 &&
                    quote?.usp?.map((item, index) => (
                      <div className="planSubOptionValue">{item?.usp_desc}</div>
                    ))}

                  {quote?.usp?.length < 1 &&
                    [...Array(3)].map((elementInArray, index) => (
                      <div className="planSubOptionValue"></div>
                    ))}
                </li>

                <li
                  className="features-list-top-list premiumBreakupLi"
                  style={{
                    paddingTop: lessThan768 ? "60px !important" : "",
                    marginTop: "-30px",
                  }}
                >
                  <div className="planSubOptionValue">
                    ₹{" "}
                    {currencyFormater(
                      quote?.finalOdPremium * 1 +
                        ElectricalValue(quote) +
                        NonElectricalValue(quote) +
                        BiFuelValue(quote) +
                        (quote?.totalLoadingAmount * 1 || 0) +
                        (quote?.underwritingLoadingAmount * 1 || 0) +
                        (quote?.totalAddon1 * 1 || 0) -
                        ((quote?.totalPremiumc1 * 1 || 0) -
                          (quote?.tppdDiscount * 1 || 0))
                    )}
                  </div>

                  <div className="planSubOptionValue">
                    ₹{" "}
                    {currencyFormater(
                      quote?.totalPremiumB1 - (quote?.tppdDiscount * 1 || 0)
                    )}
                  </div>
                  <div className="planSubOptionValue">
                    ₹ {currencyFormater(quote?.totalAddon1)}
                  </div>
                  <div className="planSubOptionValue">
                    ₹ {currencyFormater(quote?.revisedNcb1)}
                  </div>
                  <div className="planSubOptionValue totalPremiumDiv">
                    <strong> ₹ {currencyFormater(quote?.finalPremium1)}</strong>
                  </div>
                </li>

                <li
                  className="features-list-Discount addonDetailsLi"
                  style={{
                    marginTop: "-34px",
                  }}
                >
                  <div
                    className="planSubOptionValue longNameText"
                    style={{
                      display:
                        (temp_data?.odOnly ||
                          temp_data?.corporateVehiclesQuoteRequest
                            ?.vehicleOwnerType === "C") &&
                        "none",
                    }}
                  >
                    {addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) && _.isEmpty(addOnsAndOthers?.isTenure) ? (
                      <div style={{ paddingTop: lessThan768 ? "0px" : "7px" }}>
                        ₹{" "}
                        {currencyFormater(
                          parseInt(quote?.compulsoryPaOwnDriver)
                        )}
                      </div>
                    ) : (
                      <Badge
                        variant=""
                        style={{
                          cursor: "pointer",
                          top: lessThan768 ? "0px" : "5px",
                          position: "relative",
                        }}
                      >
                        Optional
                      </Badge>
                    )}
                  </div>
                  {TypeReturn(type) !== "cv" && temp_data?.newCar && (
                    <div
                      className="planSubOptionValue longNameText"
                      style={{
                        display:
                          (temp_data?.odOnly ||
                            temp_data?.corporateVehiclesQuoteRequest
                              ?.vehicleOwnerType === "C") &&
                          "none",
                      }}
                    >
                      {!_.isEmpty(addOnsAndOthers?.isTenure) ? (
                        <div
                          style={{ paddingTop: lessThan768 ? "0px" : "7px" }}
                        >
                          ₹ {currencyFormater(parseInt(quote?.multiYearCpa))}
                        </div>
                      ) : (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",
                            top: lessThan768 ? "0px" : "5px",
                            position: "relative",
                          }}
                        >
                          Optional
                        </Badge>
                      )}
                    </div>
                  )}
                  <div className="planSubOptionValue longNameText">
                    {GetAddonValue(
                      "zeroDepreciation",
                      quote?.addonDiscountPercentage1
                    ) === "N/S" ? (
                      <NoAddonCotainer>
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",
                            top: "5px",
                            position: "relative",
                          }}
                        >
                          Optional
                        </Badge>
                      </NoAddonCotainer>
                    ) : GetAddonValue(
                        "zeroDepreciation",
                        quote?.addonDiscountPercentage1
                      ) === "N/A" ? (
                      <NoAddonCotainer>
                        {quote?.applicableAddons?.includes(
                          "zeroDepreciation"
                        ) ? (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",
                              top: "6px",
                              position: "relative",
                            }}
                          >
                            Optional
                          </Badge>
                        ) : (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",
                              top: "6px",
                              position: "relative",
                            }}
                          >
                            Not Available
                          </Badge>
                        )}
                      </NoAddonCotainer>
                    ) : (
                      <NoAddonCotainer amount>
                        {GetAddonValue(
                          "zeroDepreciation",
                          quote?.addonDiscountPercentage1
                        )}
                      </NoAddonCotainer>
                    )}
                  </div>

                  <div className="planSubOptionValue longNameText">
                    {GetAddonValue(
                      "roadSideAssistance",
                      quote?.addonDiscountPercentage1
                    ) === "N/S" ? (
                      <NoAddonCotainer>
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",
                            top: "6px",
                            position: "relative",
                            marginTop: lessThan768 ? "6px" : "0px",
                          }}
                        >
                          Optional
                        </Badge>
                      </NoAddonCotainer>
                    ) : GetAddonValue(
                        "roadSideAssistance",
                        quote?.addonDiscountPercentage1
                      ) === "N/A" ? (
                      <NoAddonCotainer>
                        {quote?.applicableAddons?.includes(
                          "roadSideAssistance"
                        ) ? (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",
                              top: "6px",
                              position: "relative",
                              marginTop: lessThan768 ? "6px" : "0px",
                            }}
                          >
                            Optional
                          </Badge>
                        ) : (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",
                              top: "6px",
                              position: "relative",
                            }}
                          >
                            Not Available
                          </Badge>
                        )}
                      </NoAddonCotainer>
                    ) : (
                      <NoAddonCotainer amount className="roadSideDiv">
                        {GetAddonValue(
                          "roadSideAssistance",
                          quote?.addonDiscountPercentage1
                        )}
                      </NoAddonCotainer>
                    )}
                  </div>

                  {TypeReturn(type) === "cv" && (
                    <>
                      <div className="planSubOptionValue longNameText">
                        {GetAddonValue(
                          "imt23",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer>
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "imt23",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer>
                            {quote?.applicableAddons?.includes("imt23") ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount>
                            {GetAddonValue(
                              "imt23",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      { temp_data?.journeyCategory !== "GCV" && (
                      <div className="planSubOptionValue longNameText">
                        {GetAddonValue(
                          "consumables",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="consumableDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "consumables",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="consumableDiv">
                            {quote?.applicableAddons?.includes(
                              "consumables"
                            ) ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="consumableDiv">
                            {GetAddonValue(
                              "consumables",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      ) }
                    </>
                  )}

                  {(TypeReturn(type) === "car" ||
                    TypeReturn(type) === "bike") && (
                    <>
                      <div
                        className="planSubOptionValue longNameText"
                        style={{
                          display: TypeReturn(type) === "bike" && "none",
                        }}
                      >
                        {GetAddonValue(
                          "keyReplace",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="keyAddon">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "keyReplace",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="keyAddon">
                            {quote?.applicableAddons?.includes("keyReplace") ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="keyAddon">
                            {GetAddonValue(
                              "keyReplace",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      <div className="planSubOptionValue longNameText">
                        {GetAddonValue(
                          "engineProtector",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="engineDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "engineProtector",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="engineDiv">
                            {quote?.applicableAddons?.includes(
                              "engineProtector"
                            ) ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="engineDiv">
                            {GetAddonValue(
                              "engineProtector",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      <div
                        className="planSubOptionValue longNameText"
                        style={{
                          display: TypeReturn(type) === "bike" && "none",
                        }}
                      >
                        {GetAddonValue(
                          "ncbProtection",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="ncbDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "ncbProtection",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="ncbDiv">
                            {quote?.applicableAddons?.includes(
                              "ncbProtection"
                            ) ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="ncbDiv">
                            {GetAddonValue(
                              "ncbProtection",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      <div className="planSubOptionValue longNameText">
                        {GetAddonValue(
                          "consumables",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="consumableDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "consumables",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="consumableDiv">
                            {quote?.applicableAddons?.includes(
                              "consumables"
                            ) ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="consumableDiv">
                            {GetAddonValue(
                              "consumables",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      <div
                        className="planSubOptionValue longNameText"
                        style={{
                          display: TypeReturn(type) === "bike" && "none",
                        }}
                      >
                        {GetAddonValue(
                          "tyreSecure",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="tyreSecureDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "tyreSecure",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="tyreSecureDiv">
                            {quote?.applicableAddons?.includes("tyreSecure") ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="tyreSecureDiv">
                            {GetAddonValue(
                              "tyreSecure",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      <div className="planSubOptionValue longNameText">
                        {GetAddonValue(
                          "returnToInvoice",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="returnVoiceDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "returnToInvoice",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="returnVoiceDiv">
                            {quote?.applicableAddons?.includes(
                              "returnToInvoice"
                            ) ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="returnVoiceDiv">
                            {GetAddonValue(
                              "returnToInvoice",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                      <div
                        className="planSubOptionValue longNameText"
                        style={{
                          display: TypeReturn(type) === "bike" && "none",
                        }}
                      >
                        {GetAddonValue(
                          "lopb",
                          quote?.addonDiscountPercentage1
                        ) === "N/S" ? (
                          <NoAddonCotainer className="lossDiv">
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          </NoAddonCotainer>
                        ) : GetAddonValue(
                            "lopb",
                            quote?.addonDiscountPercentage1
                          ) === "N/A" ? (
                          <NoAddonCotainer className="lossDiv">
                            {quote?.applicableAddons?.includes("lopb") ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Optional
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )}
                          </NoAddonCotainer>
                        ) : (
                          <NoAddonCotainer amount className="lossDiv">
                            {GetAddonValue(
                              "lopb",
                              quote?.addonDiscountPercentage1
                            )}
                          </NoAddonCotainer>
                        )}
                      </div>
                    </>
                  )}
                </li>

                <li
                  className={`features-list-Discount ${
                    temp_data.productSubTypeCode === "CAR"
                      ? "accessoriesBenifits"
                      : "accessoriesBenifits1"
                  } `}
                  style={{
                    marginTop: "-21px",
                    paddingTop: lessThan768 ? "60px !important" : "",
                  }}
                >
                  <div className="planSubOptionValue">
                    {" "}
                    {addOnsAndOthers?.selectedAccesories?.includes(
                      "Electrical Accessories"
                    ) ? (
                      !quote?.motorElectricAccessoriesValue ? (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",

                            position: "relative",
                          }}
                        >
                          Not Available
                        </Badge>
                      ) : Number(quote?.motorElectricAccessoriesValue) === 0 ? (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",

                            position: "relative",
                          }}
                        >
                          Not Available
                        </Badge>
                      ) : (
                        `₹ ${currencyFormater(
                          Number(quote?.motorElectricAccessoriesValue) +
                            Number(ElectricalValue(quote))
                        )}`
                      )
                    ) : (
                      <Badge
                        variant=""
                        style={{
                          cursor: "pointer",

                          position: "relative",
                        }}
                      >
                        Not Selected
                      </Badge>
                    )}
                  </div>
                  <div className="planSubOptionValue">
                    {addOnsAndOthers?.selectedAccesories?.includes(
                      "Non-Electrical Accessories"
                    ) ? (
                      !quote?.motorNonElectricAccessoriesValue ? (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",

                            position: "relative",
                          }}
                        >
                          Not Available
                        </Badge>
                      ) : Number(quote?.motorNonElectricAccessoriesValue) ===
                        0 ? (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",

                            position: "relative",
                          }}
                        >
                          Not Available
                        </Badge>
                      ) : (
                        `₹ ${currencyFormater(
                          Number(quote?.motorNonElectricAccessoriesValue) +
                            Number(NonElectricalValue(quote))
                        )}`
                      )
                    ) : (
                      <Badge
                        variant=""
                        style={{
                          cursor: "pointer",

                          position: "relative",
                        }}
                      >
                        Not Selected
                      </Badge>
                    )}
                  </div>
                  <div
                    className="planSubOptionValue"
                    style={{ display: TypeReturn(type) === "bike" && "none" }}
                  >
                    {addOnsAndOthers?.selectedAccesories?.includes(
                      "External Bi-Fuel Kit CNG/LPG"
                    ) ? (
                      Number(quote?.motorLpgCngKitValue) === 0 ? (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",
                            top: "-4px",
                            position: "relative",
                          }}
                        >
                          Not Available
                        </Badge>
                      ) : quote?.company_alias === "godigit" ? (
                        "Inbuilt"
                      ) : (
                        `₹ ${currencyFormater(
                          Number(quote?.motorLpgCngKitValue) +
                            Number(BiFuelValue(quote))
                        )}`
                      )
                    ) : (
                      <Badge
                        variant=""
                        style={{
                          cursor: "pointer",
                          top: "-4px",
                          position: "relative",
                        }}
                      >
                        Not Selected
                      </Badge>
                    )}
                  </div>

                  {/* {temp_data.journeyCategory !== "GCV" && (
                    <div className="planSubOptionValue">
                      {addOnsAndOthers?.selectedAccesories?.includes(
                        "Trailer"
                      ) ? (
                        Number(quote?.trailerValue) === 0 ? (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",

                              position: "relative",
                            }}
                          >
                            Not Available
                          </Badge>
                        ) : (
                          `₹ ${currencyFormater(quote?.trailerValue)}`
                        )
                      ) : (
                        <Badge
                          variant=""
                          style={{
                            cursor: "pointer",

                            position: "relative",
                          }}
                        >
                          Optional
                        </Badge>
                      )}
                    </div>
                  )} */}
                </li>
                {!temp_data?.odOnly && (
                  <li
                    className="features-list-Discount additionalCoverDiv"
                    style={{
                      marginTop: "-31px",
                      paddingTop: lessThan768 ? "60px !important" : "",
                    }}
                  >
                    {temp_data.journeyCategory === "GCV" && (
                      <>
                        <div className="planSubOptionValue">
                          {addOnsAndOthers?.selectedAdditions?.includes(
                            "LL paid driver/conductor/cleaner"
                          ) ? (
                            Number(quote?.defaultPaidDriver) == 0 ||
                            quote?.defaultPaidDriver === "N/A" ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            ) : (
                              `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                            )
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Not Selected
                            </Badge>
                          )}
                        </div>
                        <div className="planSubOptionValue">
                          {addOnsAndOthers?.selectedAdditions?.includes(
                            "PA paid driver/conductor/cleaner"
                          ) ? (
                            Number(quote?.motorAdditionalPaidDriver) === 0 ||
                            quote?.motorAdditionalPaidDriver === "N/A" ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            ) : (
                              `₹ ${currencyFormater(
                                quote?.motorAdditionalPaidDriver
                              )}`
                            )
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Not Selected
                            </Badge>
                          )}
                        </div>
                      </>
                    )}

                    {temp_data.journeyCategory !== "GCV" && (
                      <>
                        <div className="planSubOptionValue">
                          {addOnsAndOthers?.selectedAdditions?.includes(
                            "Unnamed Passenger PA Cover"
                          ) ? (
                            Number(quote?.coverUnnamedPassengerValue) === 0 ||
                            quote?.coverUnnamedPassengerValue === "N/A" ||
                            quote?.coverUnnamedPassengerValue === "NA" ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            ) : (
                              `₹ ${currencyFormater(
                                quote?.companyAlias === "sbi" &&
                                  addOnsAndOthers?.selectedCpa?.includes(
                                    "Compulsory Personal Accident"
                                  ) &&
                                  !_.isEmpty(addOnsAndOthers?.isTenure)
                                  ? quote?.coverUnnamedPassengerValue *
                                      (type === "bike" ? 5 : 3)
                                  : quote?.coverUnnamedPassengerValue
                              )}`
                            )
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Not Selected
                            </Badge>
                          )}
                        </div>
                        <div
                          className="planSubOptionValue"
                          style={{
                            display:
                              (TypeReturn(type) === "bike" ||
                                temp_data?.journeyCategory === "GCV") &&
                              "none",
                          }}
                        >
                          {addOnsAndOthers?.selectedAdditions?.includes(
                            "PA cover for additional paid driver"
                          ) ? (
                            Number(quote?.motorAdditionalPaidDriver) === 0 ||
                            quote?.motorAdditionalPaidDriver === "N/A" ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            ) : (
                              `₹ ${currencyFormater(
                                quote?.companyAlias === "sbi" &&
                                  addOnsAndOthers?.selectedCpa?.includes(
                                    "Compulsory Personal Accident"
                                  ) &&
                                  !_.isEmpty(addOnsAndOthers?.isTenure)
                                  ? quote?.motorAdditionalPaidDriver *
                                      (type === "bike" ? 5 : 3)
                                  : quote?.motorAdditionalPaidDriver
                              )}`
                            )
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Not Selected
                            </Badge>
                          )}
                        </div>
                        <div className="planSubOptionValue">
                          {addOnsAndOthers?.selectedAdditions?.includes(
                            "LL paid driver"
                          ) ? (
                            Number(quote?.defaultPaidDriver) === 0 ||
                            quote?.defaultPaidDriver === "N/A" ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",
                                  top: "-4px",
                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            ) : (
                              `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                            )
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",
                                top: "-4px",
                                position: "relative",
                              }}
                            >
                              Not Selected
                            </Badge>
                          )}
                        </div>
                      </>
                    )}
                  </li>
                )}
                {temp_data.journeyCategory !== "GCV" && (
                  <li
                    className="features-list-Discount additionalCoverDiv"
                    style={{
                      marginTop: "-31px",
                      paddingTop: lessThan768 ? "60px !important" : "",
                    }}
                  >
                    <>
                      <div className="planSubOptionValue">
                        {addOnsAndOthers?.selectedDiscount?.includes(
                          "Is the vehicle fitted with ARAI approved anti-theft device?"
                        ) ? (
                          !quote?.antitheftDiscount ? (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Not Available
                            </Badge>
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              {quote?.antitheftDiscount === "" ||
                              quote?.antitheftDiscount === 0
                                ? "Not Available"
                                : `
                        ₹
                        ${currencyFormater(quote?.antitheftDiscount)}
                      `}
                            </Badge>
                          )
                        ) : (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",

                              position: "relative",
                            }}
                          >
                            Not Selected
                          </Badge>
                        )}
                      </div>
                      {TypeReturn(type) !== "cv" &&
                        !BlockedSections(process.env.REACT_APP_BROKER).includes(
                          "voluntary discount"
                        ) && (
                          <div className="planSubOptionValue">
                            {addOnsAndOthers?.selectedDiscount?.includes(
                              "Voluntary Discounts"
                            ) ? (
                              !quote?.voluntaryExcess ? (
                                <Badge
                                  variant=""
                                  style={{
                                    cursor: "pointer",

                                    position: "relative",
                                  }}
                                >
                                  Not Available
                                </Badge>
                              ) : (
                                <Badge
                                  variant=""
                                  style={{
                                    cursor: "pointer",

                                    position: "relative",
                                  }}
                                >
                                  {quote?.voluntaryExcess == 0
                                    ? "Not Available"
                                    : `
                        ₹ ${Math.round(quote?.voluntaryExcess)} `}
                                </Badge>
                              )
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                Not Selected
                              </Badge>
                            )}
                          </div>
                        )}
                      {type === "cv" && (
                        <div className="planSubOptionValue">
                          {addOnsAndOthers?.selectedDiscount?.includes(
                            "Vehicle Limited to Own Premises"
                          ) ? (
                            quote?.limitedtoOwnPremisesOD ? (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                ₹ {quote?.limitedtoOwnPremisesOD}
                              </Badge>
                            ) : (
                              <Badge
                                variant=""
                                style={{
                                  cursor: "pointer",

                                  position: "relative",
                                }}
                              >
                                Not Available
                              </Badge>
                            )
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Optional
                            </Badge>
                          )}
                        </div>
                      )}

                      <div
                        className="planSubOptionValue"
                        style={{ display: temp_data?.odOnly && "none" }}
                      >
                        {addOnsAndOthers?.selectedDiscount?.includes(
                          "TPPD Cover"
                        ) ? (
                          quote?.tppdDiscount ? (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              ₹ {quote?.tppdDiscount}
                            </Badge>
                          ) : (
                            <Badge
                              variant=""
                              style={{
                                cursor: "pointer",

                                position: "relative",
                              }}
                            >
                              Not Available
                            </Badge>
                          )
                        ) : (
                          <Badge
                            variant=""
                            style={{
                              cursor: "pointer",

                              position: "relative",
                            }}
                          >
                            Not Selected
                          </Badge>
                        )}
                      </div>
                    </>
                  </li>
                )}
              </ul>
            )}
          </DataCard>
        </TopLi>
      </TopDiv>
      {saveQuoteLoader && <Loader />}
      {/* {showPop && (
        <ProductPopup
          // showPop={showPop}
          validQuote={validQuote}
          // setShowPop={setShowPop}
          // closePop={() => dispatch(setShowPop(false))}
          // outside={true}
        />
        // <MobileProduct showPop={showPop} validQuote={validQuote} />
      )} */}
      {/* { showPop && (
        <ProductPopup 
         validQuote={validQuote}
        />
      ) } */}
    </>
  );
}

export default Product;

const RecPlanBuyBtn = styled.button`
  background-color: ${({ theme }) => theme.comparePage?.color || "#d7df23"};
  border: ${({ theme }) => theme.comparePage?.border || "solid 1px #d7df23"};
  color: #fff;
  border-radius: ${({ theme }) =>
    theme.QuoteBorderAndFont?.borderRadius || "30px"} !important;
  &:hover {
    background: ${({ theme }) => theme.comparePage?.color || "#fff"};
    color: ${({ theme }) => theme.comparePage?.textColor || "#d7df23"};
  }
`;
const TopLi = styled.li``;
const TopInfo = styled.div`
  position: ${(props) => (props.fixed ? "fixed !important" : "relative")};
  top: ${(props) => (props.fixed ? "15px" : "unset")};
  box-shadow: ${(props) =>
    props.fixed
      ? "rgb(0 0 0 / 10%) 0px 8px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px"
      : "rgb(0 0 0 / 10%) 0px 8px 25px -5px,rgb(0 0 0 / 4%) 0px 10px 10px -5px;"};
`;
const NoAddonCotainer = styled.div`
  position: relative;
  min-height: 39px;
  //	bottom: 5px;
  position: relative;
  top: ${(props) => (props.amount ? "5px" : "")};
`;

const StyledDiv1 = styled.div`
  position: absolute;
  right: 47px;
  top: 1px;
  z-index: 101;
  .round-label::after {
    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid  #d4d5d9"};
  }

  .group-check input[type="checkbox"]:checked + label::before {
    transform: scale(1);
    background-color: ${({ theme }) => theme?.QuoteCard?.color || "#bdd400"};

    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid #bdd400"};
  }
`;

const CardDiv = styled.div`
  background: ${({ theme }) =>
    theme.CardPop?.background || "rgb(18 211 77 / 6%)"};
  border: ${({ theme }) => theme.CardPop?.border || "1px solid green"};
`;

const CompareButton = styled.button`
  background: ${({ theme }) => theme.comparePage2?.background || "green"};
`;

const NoPlansDiv = styled.div`
  background: ${({ theme }) => theme.NoPlanCard?.background || "#f7f7fa"};
  // border: ${({ theme }) => theme.NoPlanCard?.border || "2px dotted green"};
`;

const AddPlanIcon = styled.i`
  color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
`;

const CloseContainer = styled.i`
  box-shadow: rgb(0 0 0 / 10%) 0px 8px 25px -5px,
    rgb(0 0 0 / 4%) 0px 10px 10px -5px;
`;

const DataCard = styled.div`
  position: relative;
  top: ${(props) => (props.fixed ? "220px !important" : "")};

  .badge {
    font-size: 100%;
    font-weight: 500;
  }
`;

const TopDiv = styled.div`
  .compare-page-product {
    width: 276px;
    position: relative;
    float: left;
    text-align: center;
    margin-right: 11px;
    font-family: ${({ theme }) =>
      theme.regularFont?.fontFamily || "Inter-Regular"};
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    /* background-color: #f7fdff; */
    -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    .planSubOptionVal {
      margin-bottom: 18px;
    }
    &:last-child {
      margin-right: 0;
    }
    &__logo-wrap {
      display: inline-flex;
      height: 60px;
      //width: 60px;
      margin: 24px auto;
    }
    & .productRecLabel {
      width: 275px;
      position: absolute;
      top: -40px;
      left: -1px;
      background: #107591;
      font-family: ${({ theme }) =>
        theme.mediumFont?.fontFamily || "Inter-SemiBold"};
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      text-align: center;
      width: 275px;
      padding: 8px 0;
      border-radius: 2px 20px 0 0;
    }
    & .top-info {
      width: 272px;
      & img {
        height: 80px;
        position: relative;
        //	transform: translateX(-20%);
        width: auto;
        // margin: 24px 0;
        margin: auto;
      }
      & .planName {
        font-family: ${({ theme }) =>
          theme.mediumFont?.fontFamily || "Inter-SemiBold"};
        font-size: 14px;
        line-height: 20px;
        color: #333;
        margin-bottom: 22px;
      }
      & .planAmt {
        font-family: ${({ theme }) =>
          theme.regularFont?.fontFamily || "Inter-Regular"};
        font-size: 20px;
        line-height: 25px;
        color: #333333;
        margin-bottom: 8px;
      }
      & .recPlanBuyBtn {
        height: 40px;
        width: 148px;
        border-radius: 4px;
        outline: none;
        font-family: ${({ theme }) =>
          theme.mediumFont?.fontFamily || "Inter-SemiBold"};
        font-size: 15px;
        line-height: 24px;
        border-radius: 30px;
      }
    }
    &.productRec .recPlanBuyBtn {
      width: 156px;
    }
    &--1 {
      width: 850px;
      & .productRecLabel,
      & .top-info {
        width: 849px;
      }
    }
    &--2 {
      width: 419.5px;
      & .productRecLabel,
      & .top-info {
        width: 415px;
      }
    }
    & .cd-features-list {
      padding: 0;
      list-style: none;
      & li {
        padding-top: 95px;
        font-size: 12px;
        color: ${({ theme }) => theme.regularFont?.textColor || "#333"};

        & .planSubOptionValue {
          padding-bottom: 0;
          min-height: 39px;
          overflow: hidden;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1177px) {
      width: 177.333px !important;
      .addonValueText {
        font-size: 10px !important;
      }
      & .productRecLabel,
      & .top-info {
        // width: 176.333px !important;
        width: 173.5px;
        & img {
          height: 60px;
        }
      }
      &--1 {
        width: 554px !important;
        & .productRecLabel,
        & .top-info {
          width: 553px !important;
        }
      }
      &--2 {
        width: 271.5px !important;
        & .productRecLabel,
        & .top-info {
          width: 267.5px !important;
        }
      }
    }
    @media screen and (max-width: 767px) {
      width: 50% !important;
      margin-right: 0;
      border: none;
      background: none;
      .addonValueText {
        font-size: 9px !important;
      }
      &:nth-child(3) {
        display: none;
      }
      & .productRecLabel {
        display: none;
      }
      &__logo-wrap {
        width: 34px;
        height: 34px;
        margin: 8px auto;
      }
      & .top-info {
        width: 99.672%;
        height: 220px !important;
        & img {
          width: auto;
          height: 40px;
          transform: translateX(-30%);
        }
        & .planName {
          font-size: 11px;
          line-height: 16px;
          /* border-top: 1px solid #e3e4e8; */
          margin-top: 1px;
          padding-top: 10px;
          height: 44px;
          max-height: 44px;
          overflow: hidden;
          margin-bottom: 0;
        }
        & .planAmt {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 4px;
        }
      }
      &.productRec .recPlanBuyBtn,
      & .top-info .recPlanBuyBtn {
        width: 80%;
      }
      & .cd-features-list {
        border-top: none;
        & li {
          font-size: 11px;
          line-height: 15px;
          color: #333;
          font-family: ${({ theme }) =>
            theme.mediumFont?.fontFamily || "Inter-SemiBold"};
          padding-top: 72px;
          padding-bottom: 31px;
          border-bottom: none;
          & .planSubOptionValue {
            height: 28px;
            margin-bottom: 8px !important;
          }
          &.features-list-Discount {
            // padding-top: 60px !important;
          }
          &.features-list-top-li {
            padding-top: 60px !important;
          }
        }
      }
    }
  }
  .compare-page-product {
    /* background: #d0e438; */
  }
  .compare-products-list .top-info {
    /* background: #fff; */
  }
  .compare-page-product .cd-features-list li .planSubOptionValue {
    font-size: 14px;
    @media screen and (min-width: 768px) and (max-width: 1177px) {
      font-size: 10px;
    }
  }
  .compare-page-product .top-info .planName {
    /* color: #fff; */
    margin-top: 10px;
  }
  .check {
    color: green;
    font-size: 1.5rem;
  }
  .cross {
    color: red;
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    .longNameText {
      min-height: 60px !important;
      font-size: 9px !important;
      padding: 0px 10px 0px 0px !important;
    }
    .compare-page-product {
      /* box-shadow: none;
        border-radius: 0px;
        background: #fff; */
    }
    .compare-products-list .top-info {
      /* border-bottom: none;
        border-radius: 0px;
        background: #fff!important; */
    }
    .compare-page-product .top-info .planName {
      color: black;
      margin-top: 4px;
    }
    .compare-page-product .top-info .recPlanBuyBtn {
      font-size: 8px;
      width: 70px;
      height: 30px;
      margin-top: 15px;
    }
    .compare-page-product .cd-features-list li .planSubOptionValue {
      font-size: 9px;
    }
    .check {
      font-size: 1rem;
    }
    .cross {
      font-size: 1rem;
    }
  }
  .newProductList {
    padding: "10px 32px";
    max-height: "400px";
    overflow: "scroll";
    overflow-x: "hidden !important";
  }
  @media (max-width: 766px) {
    .premiumBreakupLi {
      margin-top: -6px !important;
      padding-top: 60px !important;
    }
    .addonDetailsLi {
      margin-top: -10px !important;
      padding-top: 76px !important;
    }
    .accessoriesBenifits {
      margin-top: -10px !important;
    }
    .accessoriesBenifits1 {
      margin-top: -51px !important;
    }
    .icUsp {
      padding-top: 80px !important;
    }
    .keyAddon {
      padding-top: 12px !important;
    }
    .engineDiv {
      padding-top: 19px !important;
    }
    .ncbDiv {
      padding-top: 25px !important;
    }
    .consumableDiv {
      padding-top: 31px !important;
    }
    .tyreSecureDiv {
      padding-top: 37px !important;
    }
    .returnVoiceDiv {
      padding-top: 43px !important;
    }
    .roadSideDiv {
      padding-top: 6px !important;
    }
    .lossDiv {
      padding-top: 43px !important;
    }
    .additionalCoverDiv {
      margin-top: -45px !important;
    }
    .totalPremiumDiv {
      padding-top: 8px !important;
    }
  }
  .buyNowBtn {
    padding: 10px 0px;
    font-weight: bold;
    font-size: 0.65rem;
    color: ${({ theme }) => theme.floatButton?.whiteColor || ""};
    background: ${({ theme }) => theme.comparePage?.color || "#d7df23"};
    border: ${({ theme }) => theme.comparePage?.border || "none"};
    border-radius: ${({ theme }) =>
      theme.QuoteBorderAndFont?.borderRadius || "30px"} !important;
  }

  @media (max-width: 766px) {
    .mobile-top-info {
      position: fixed !important;
      width: 42% !important;
      top: 2%;

      @media (max-width: 600px) {
        width: 40% !important;
      }
      @media (max-width: 450px) {
        width: 36% !important;
      }
    }
    .mobile-data-card {
      margin-top: 160px;
    }
  }

  @media (max-width: 767px) {
    .buy_now_div {
      width: 85%;
      margin: auto;
    }
    .recPlanBuyBtn {
      width: 100% !important;
      font-size: 16px !important;
    }
  }
  @media (max-width: 400px) {
    // .buy_now_div {
    //   width: 95%;
    //   margin: auto;
    // }
    .recPlanBuyBtn {
      // width: 100% !important;
      font-size: 14px !important;
    }
  }
  @media (max-width: 350px) {
    // .buy_now_div {
    //   width: 100%;
    //   margin: auto;
    // }
    .recPlanBuyBtn {
      // width: 100% !important;
      font-size: 12px !important;
    }
  }
`;

const TopPop = styled.div`
  .add_plans {
    font-family: ${({ theme }) =>
      theme.mediumFont?.fontFamily || "Inter-SemiBold"};
  }
`;

const FoldedRibbon = styled.div`
  --f: 5px; /* control the folded part*/
  --r: 5px; /* control the ribbon shape */
  --t: 13px; /* the top offset */

  position: absolute;

  font-size: 11.5px;
  font-weight: 800;
  color: #fff;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #4ca729;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  z-index: 999 !important;
`;
