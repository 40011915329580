import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button as Btn } from "react-bootstrap";
import { Textbox, Button, Error, Loader } from "components";
import { useForm } from "react-hook-form";
import styled, { createGlobalStyle } from "styled-components";
import * as yup from "yup";
import { numOnly, reloadPage } from "utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  set_temp_data,
  Enquiry,
  clear,
  SaveQuoteData,
  TokenValidation,
} from "modules/Home/home.slice";
import "./lead.scss";
import swal from "sweetalert";
import { Url } from "modules/proposal/proposal.slice";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import Realistic from "components/canvas-confetti/CanvasConfetti";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ls = new SecureLS();
//theme variable
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

// validation schema
const yupValidate = yup.object({
  emailId: yup
    .string()
    .email("Please enter valid email id")
    .required("Email id is required")
    .trim(),
  mobileNo: yup
    .string()
    .required("Mobile No. is required")
    .min(10, "Mobile No. should be 10 digits")
    .max(10, "Mobile No. should be 10 digits")
    .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number"),
  lastName: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(1, "Minimum 2 chars required")
    .max(50, "Must be less than 50 chars")
    .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
    .trim(),
  firstName: yup
    .string()
    .required("First Name is required")
    .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
    .min(1, "Minimum 2 chars required")
    .max(50, "Must be less than 50 chars")
    .trim(),
});

export const LeadPage = ({ type, lessthan767, isPartner }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const token = query.get("token") || localStorage?.SSO_user_motor;
  const typeId = query.get("typeid");
  const user_id = query.get("userId");
  const corp_id = query.get("corpId");
  const journey_type = query.get("journey_type");

  const [btnDisable, setbtnDisable] = useState(false);
  const [consent, setConsent] = useState(true);

  const {
    temp_data,
    enquiry_id,
    saveQuoteData,
    userId: uid,
    corpId: cid,
    loading,
    tokenData,
    tokenFailure,
    error,
    rd_link,
    leadLoad,
  } = useSelector((state) => state.home);
  const notify = () => {
    toast(
      "Congratulations! Your work email ID is eligible for exclusive discounts and corporate offers.",
      "Custom style",
      {
        toastId: "customId",
        className: "black-background",
        bodyClassName: "grow-font-size",
      }
    );
  };

  const { handleSubmit, register, errors, reset, watch } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });
  const handleChange = () => {};

  const corpId = corp_id || uid || ls.get("corpId");
  const userId = user_id || cid || ls.get("userId");

  //Url
  useEffect(() => {
    if (enquiry_id)
      dispatch(
        Url({
          proposalUrl: window.location.href,
          quoteUrl: window.location.href,
          stage: "Lead Generation",
          userProductJourneyId: enquiry_id?.enquiryId,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //prefill
  useEffect(() => {
    if (temp_data?.firstName) {
      reset({
        firstName: temp_data?.firstName === "skip" ? "" : temp_data?.firstName,
        lastName: temp_data?.lastName === "skip" ? "" : temp_data?.lastName,
        mobileNo:
          Number(temp_data?.mobileNo) === 8286147635 ? "" : temp_data?.mobileNo,
        emailId:
          temp_data?.emailId === "skip@skip.com" ? "" : temp_data?.emailId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //validate Token
  useEffect(() => {
    if (token && process.env.REACT_APP_BROKER !== "RB") {
      let decodedToken = decodeURIComponent((token + "").replace(/\+/g, "%20"));
      dispatch(
        TokenValidation({
          token: String(decodedToken).replace(/'/g, ""),
          ...(journey_type && {
            journeyType: journey_type,
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  //redirection
  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola-dashboard.fynity.in/";
      case "UNILIGHT":
        return "https://www.policyongo.com/pos/login";
      case "CP":
        return "https://www.comparepolicy.com/";
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return "http://preprod-dasbhoard-abibl.fynity.in/";
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-abibl.fynity.in/";
        } else {
          return "http://abibl-prod-dashboard.fynity.in/";
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-gramcover.fynity.in/";
        } else {
          return "https://dashboard.gramcover.com/";
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://live.pincnews.co.in/"
          : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return ``;
      case "UIB":
        return ``;
      default:
        break;
    }
  };

  //onError
  useEffect(() => {
    if (tokenFailure) {
      swal("Error", tokenFailure, "error").then(() =>
        reloadPage(rd_link || UrlFn())
      );
    }
    setbtnDisable(false);
    return () => {
      dispatch(clear("token"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFailure]);

  //onSuccess
  useEffect(() => {
    if (enquiry_id?.enquiryId) {
      dispatch(set_temp_data({ enquiry_id: enquiry_id?.enquiryId }));
    }
    if (enquiry_id?.enquiryId) {
      dispatch(
        SaveQuoteData({
          stage: "1",
          ...(isPartner === "Y" && { frontendTags: "Y" }),
          ...(journey_type === "Z3JhbWNvdmVyLWFwcC1qb3VybmV5" && {
            addtionalData: tokenData,
          }),
          firstName: temp_data?.firstName,
          lastName: temp_data?.lastName,
          mobileNo: temp_data?.mobileNo,
          emailId: temp_data?.emailId,
          userProductJourneyId: enquiry_id?.enquiryId,
          enquiryId: enquiry_id?.enquiryId,
          corpId,
          userId,
          ...(token && { token: token }),
          ...((!_.isEmpty(tokenData) || localStorage?.SSO_user) && {
            tokenResp: !_.isEmpty(tokenData)
              ? tokenData
              : JSON.parse(localStorage?.SSO_user),
          }),
          ...(journey_type && {
            journeyType: journey_type,
          }),
          ...(process.env.REACT_APP_BROKER === "ABIBL" && {
            whatsappConsent: consent,
          }),
          ...(typeId && { productSubTypeId: typeId }),
          ...{
            sellerType:
              tokenData?.usertype || tokenData?.seller_type
                ? tokenData?.usertype || tokenData?.seller_type
                : null,
          },
          ...(tokenData?.category && { categoryName: tokenData?.category }),
          ...(tokenData?.relationSbi && {
            relationSbi: tokenData?.relationSbi,
          }),
          ...(token && {
            ...(tokenData?.first_name && {
              userfirstName: tokenData?.first_name,
            }),
            ...(tokenData?.last_name && {
              userlastName: tokenData?.last_name,
            }),
            ...(tokenData?.user_name && {
              userName: tokenData?.user_name,
            }),
            agentId: tokenData?.seller_id,
            agentName: tokenData?.seller_name,
            agentMobile: tokenData?.mobile,
            agentEmail: tokenData?.email,
            ...((tokenData?.usertype === "P" ||
              tokenData?.seller_type === "P") && {
              panNo: tokenData?.pan_no,
              aadharNo: tokenData?.aadhar_no,
            }),
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data.firstName]);

  //on save success
  useEffect(() => {
    if (saveQuoteData && temp_data?.enquiry_id) {
      enquiry_id?.corporate_id && notify();
      enquiry_id?.corporate_id &&
        document.getElementById("fireBtn") &&
        document.getElementById("fireBtn").click();
      setTimeout(
        () => {
          history.push(
            `/${type}/registration?enquiry_id=${
              enquiry_id?.enquiryId || temp_data?.enquiry_id
            }${token ? `&token=${token}` : ``}${
              typeId ? `&typeid=${typeId}` : ``
            }${journey_type ? `&journey_type=${journey_type}` : ``}`
          );
        },
        enquiry_id?.corporate_id ? 5000 : 0
      );
    }
    return () => {
      temp_data?.enquiry_id &&
        setTimeout(
          () => dispatch(clear("saveQuoteData")),
          enquiry_id?.corporate_id ? 4500 : 0
        );
      temp_data?.enquiry_id &&
        setTimeout(
          () => dispatch(clear("enquiry_id")),
          enquiry_id?.corporate_id ? 4500 : 0
        );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveQuoteData, temp_data?.enquiry_id]);

  useEffect(() => {
    if (error) {
      setbtnDisable(false);
    }
  }, [error]);

  //check values
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const mobileNo = watch("mobileNo");
  const emailId = watch("emailId");

  const onSubmit = (data) => {
    dispatch(
      set_temp_data({
        ...data,
        ...(process.env.REACT_APP_BROKER === "ABIBL" && {
          whatsappConsent: consent,
        }),
      })
    );
    //clearing registration
    // dispatch(set_temp_data({ journeyType: null, regNo: null }));
    dispatch(
      Enquiry({
        ...data,
        ...((!_.isEmpty(tokenData) || localStorage?.SSO_user) && {
          tokenResp: !_.isEmpty(tokenData)
            ? tokenData
            : JSON.parse(localStorage?.SSO_user),
        }),
      })
    );
    setTimeout(setbtnDisable(false), 2000);
  };

  const FinalSubmit = (
    <Col
      sm="12"
      md="12"
      lg="12"
      xl="12"
      className="d-flex justify-content-center"
    >
      <SubmitDiv>
        <label className="checkbox-container">
          <input
            className="bajajCheck"
            defaultChecked={false}
            name="accept"
            type="checkbox"
            value={consent}
            checked={consent ? true : false}
            onChange={(e) => {
              setConsent(e.target.checked);
            }}
          />
          <span className="checkmark"></span>
        </label>
        <p className="privacyPolicy">
          <span>I Agree to be contacted via</span>{" "}
          <i
            className="fab fa-whatsapp text-success"
            style={{ fontSize: "14px" }}
          ></i>{" "}
          <span>Whatsapp.</span>
        </p>
      </SubmitDiv>
    </Col>
  );

  return (
    <>
      <div className="ml-4 my-4 w-100 mx-auto ElemFade">
        <Row className="text-center w-100 mx-auto d-flex justify-content-center">
          <div
            className={`mt-4 d-flex justify-content-center w-100 mx-auto ${
              !lessthan767 ? `flex-column` : ""
            }`}
          >
            <StyledH4 className="text-center w-100 mx-auto d-flex justify-content-center">
              {lessthan767
                ? `Buy vehicle insurance in easy steps. Please fill the details`
                : `Get the best Vehicle Insurance quotes in`}
            </StyledH4>
            {!lessthan767 && (
              <StyledH4
                className={`text-center w-100 mx-auto d-flex justify-content-center ${
                  process.env.REACT_APP_BROKER === "UIB" && "font-weight-bold"
                }  `}
              >
                2 minutes.
              </StyledH4>
            )}
          </div>
        </Row>
        {!leadLoad || (enquiry_id && enquiry_id?.corporate_id) ? (
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="w-100 d-flex no-wrap mt-5 mx-auto">
                <Col sm="12" md="6" lg="6" xl="6">
                  <div className="w-100">
                    <Textbox
                      lg
                      type="text"
                      id="firstName"
                      fieldName="First Name"
                      name="firstName"
                      placeholder=" "
                      fontWeight={"1000"}
                      onInput={(e) =>
                        (e.target.value =
                          e.target.value.length <= 1
                            ? ("" + e.target.value).toUpperCase()
                            : e.target.value)
                      }
                      register={register}
                      onChange={handleChange}
                      error={errors?.firstName}
                    />
                    {!!errors.firstName && (
                      <Error
                        style={{
                          marginTop: "-20px",
                          ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                            textAlign: "left",
                            marginLeft: "18px",
                          }),
                        }}
                      >
                        {errors.firstName.message}
                      </Error>
                    )}
                  </div>
                </Col>
                <Col sm="12" md="6" lg="6" xl="6">
                  <div className="w-100">
                    <Textbox
                      lg
                      type="text"
                      id="lastName"
                      fieldName="Last Name"
                      fontWeight={"1000"}
                      name="lastName"
                      onInput={(e) =>
                        (e.target.value =
                          e.target.value.length <= 1
                            ? ("" + e.target.value).toUpperCase()
                            : e.target.value)
                      }
                      placeholder=" "
                      register={register}
                      onChange={handleChange}
                      error={errors?.lastName}
                    />
                    {!!errors.lastName && (
                      <Error
                        style={{
                          marginTop: "-20px",
                          ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                            textAlign: "left",
                            marginLeft: "18px",
                          }),
                        }}
                      >
                        {errors.lastName.message}
                      </Error>
                    )}
                  </div>
                </Col>
                <Col sm="12" md="6" lg="6" xl="6">
                  <div className="w-100">
                    <Textbox
                      lg
                      type="tel"
                      id="mobileNo"
                      // icon="fa fa-phone icon"
                      fieldName="Mobile No."
                      fontWeight={"1000"}
                      name="mobileNo"
                      placeholder=" "
                      register={register}
                      error={errors?.mobileNo}
                      onChange={(e) =>
                        e.target.value.length === 10
                          ? document
                              .querySelector(`input[name=emailId]`)
                              .focus()
                          : ""
                      }
                      maxLength="10"
                      onKeyDown={numOnly}
                    />
                    {!!errors.mobileNo && (
                      <Error
                        style={{
                          marginTop: "-20px",
                          ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                            textAlign: "left",
                            marginLeft: "18px",
                          }),
                        }}
                      >
                        {errors.mobileNo.message}
                      </Error>
                    )}
                  </div>
                </Col>
                <Col sm="12" md="6" lg="6" xl="6">
                  <div className="w-100">
                    <Textbox
                      lg
                      // icon="fa fa-envelope icon"
                      type="text"
                      id="emailId"
                      fieldName="Email"
                      fontWeight={"1000"}
                      name="emailId"
                      placeholder=" "
                      register={register}
                      onChange={handleChange}
                      error={errors?.emailId}
                    />
                    {!!errors.emailId && (
                      <Error
                        style={{
                          marginTop: "-20px",
                          ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                            textAlign: "left",
                            marginLeft: "18px",
                          }),
                        }}
                      >
                        {errors.emailId.message}
                      </Error>
                    )}
                  </div>
                </Col>
                {(process.env.REACT_APP_BROKER === "ABIBL" ||
                  process.env.REACT_APP_BROKER === "ACE") &&
                  FinalSubmit}
                <Col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="d-flex justify-content-center mt-1"
                  style={
                    firstName && lastName && mobileNo && emailId
                      ? {
                          transition:
                            "top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                        }
                      : {}
                  }
                >
                  <Button
                    className=""
                    buttonStyle="outline-solid"
                    disabled={btnDisable}
                    onClick={() => {
                      if (
                        firstName &&
                        firstName.match(/^([A-Za-z\s])+$/) &&
                        ((lastName && lastName.match(/^([A-Za-z\s])+$/)) ||
                          (!lastName && lastName !== 0)) &&
                        emailId &&
                        emailId.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
                        mobileNo &&
                        mobileNo.match(/^[6-9][0-9]{9}$/)
                      ) {
                        navigator &&
                          navigator?.vibrate &&
                          navigator.vibrate([100, 0, 50]);
                        onSubmit({
                          firstName: firstName,
                          lastName: lastName,
                          emailId: emailId,
                          mobileNo: mobileNo,
                        });
                        setbtnDisable(true);
                      } else {
                        handleSubmit(onSubmit);
                      }
                    }}
                    style={
                      (firstName && mobileNo && emailId) || !btnDisable
                        ? {}
                        : { cursor: "not-allowed" }
                    }
                    hex1={
                      firstName && mobileNo && emailId
                        ? process.env.REACT_APP_BROKER === "RB"
                          ? Theme?.leadPageBtn?.background1
                          : Theme?.leadPageBtn?.background || "#bdd400"
                        : "#e7e7e7"
                    }
                    hex2={
                      firstName &&
                      // (lastName || process.env.REACT_APP_BROKER === "ACE") &&
                      mobileNo &&
                      emailId
                        ? process.env.REACT_APP_BROKER === "RB"
                          ? Theme?.leadPageBtn?.background2
                          : Theme?.leadPageBtn?.background || "#bdd400"
                        : "#e7e7e7"
                    }
                    shadow={
                      firstName &&
                      // (lastName || process.env.REACT_APP_BROKER === "ACE") &&
                      mobileNo &&
                      emailId
                        ? false
                        : "none"
                    }
                    borderRadius={
                      Theme?.leadPageBtn?.borderRadius
                        ? Theme?.leadPageBtn?.borderRadius
                        : "20px"
                    }
                    type="submit"
                  >
                    <text
                      style={{
                        color:
                          firstName &&
                          // (lastName ||
                          //   process.env.REACT_APP_BROKER === "ACE") &&
                          mobileNo &&
                          emailId
                            ? Theme?.leadPageBtn?.textColor
                              ? Theme?.leadPageBtn?.textColor
                              : "black"
                            : " black",
                      }}
                    >
                      Proceed
                    </text>
                  </Button>
                </Col>
                <Col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="d-flex justify-content-center mt-3 noOutLine"
                >
                  <Btn
                    className={`lead_link ${
                      Theme?.leadPageBtn?.link ? Theme?.leadPageBtn?.link : ""
                    }`}
                    variant={"link"}
                    type="button"
                    disabled={btnDisable}
                    onClick={() => {
                      navigator &&
                        navigator?.vibrate &&
                        navigator.vibrate([100, 0, 50]);
                      onSubmit({
                        firstName: null,
                        lastName: null,
                        emailId: null,
                        mobileNo: null,
                      });
                      setbtnDisable(true);
                    }}
                  >
                    Skip for now
                  </Btn>
                </Col>
                {process.env.REACT_APP_BROKER === "UIB" && (
                  <Col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="d-flex justify-content-center mt-2 noOutLine"
                  >
                    <Ribbon className="ribbon" type="button">
                      Enter Your Corporate Email-ID for Additional Discount
                    </Ribbon>
                  </Col>
                )}
              </Row>
            </Form>
            <GlobalStyle />
            <>
              <Realistic />
              <div>
                <StyledContainer
                  autoClose={false}
                  hideProgressBar
                  position="top-center"
                  style={{
                    width: "100%",
                    marginTop: "-20px",
                    textAlign: "center",
                  }}
                />
              </div>
            </>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const StyledH4 = styled.h4`
  font-size: ${process.env.REACT_APP_BROKER === "BAJAJ" ||
  process.env.REACT_APP_BROKER === "SPA"
    ? "34px"
    : "36px"};
  color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  font-family: ${({ theme }) =>
    theme.regularFont?.headerFontFamily || "sans-serif"};
  white-space: pre-wrap;
  max-width: 760px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 375px) {
    font-size: 20.5px;
  }
  @media (max-width: 360px) {
    font-size: 20px;
  }
`;

export const GlobalStyle = createGlobalStyle`
body {
  // background: #EAEAEA !important;
}
.lead_link, .lead_link:hover {
  color: ${({ theme }) => theme.links?.color || ""};
}
`;

const SubmitDiv = styled.div`
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox-container input:checked ~ .checkmark,
  .plan-card .checkbox-container input:checked ~ .checkmark {
    background-color: ${({ theme }) =>
      theme?.proposalProceedBtn?.hex1
        ? theme?.proposalProceedBtn?.hex1
        : "#268f05"};
  }
  .checkbox-container .checkmark {
    position: absolute;
    top: 2.1px !important;
    left: 0 !important;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .checkbox-container .checkmark:after {
    content: url(${process.env.REACT_APP_BASENAME !== "NA"
      ? `/${process.env.REACT_APP_BASENAME}`
      : ""}/assets/images/checkbox-select.png);
    left: 1px;
    top: -10px;
    width: 17px;
    height: 16px;
    position: absolute;
  }
  .privacyPolicy {
    padding-left: 40px;
    font-size: 13px;
    color: #545151;
    font-family: sans-serif;
    text-align: justify;
    text-justify: inter-word;
  }

  @media screen and (max-width: 993px) {
    .checkbox-container .checkmark:after {
      content: url(/assets/images/checkbox-select.png);
      left: 1px;
      top: 0px;
      width: 17px;
      height: 16px;
      position: absolute;
      color: #0000;
    }
  }
`;

const Ribbon = styled.button`
  cursor: default !important;
  background-color: ${({ theme }) =>
    theme?.leadPageBtn?.background3 || "#f2f7cc"};
  color: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB"
      ? theme?.leadPageBtn?.textColor
      : theme?.Registration?.otherBtn?.hex1 || "#006400"};
  @media (max-width: 767px) {
    font-size: 10.5px;
    padding: 10px 35px;
  }
`;

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    width: 100%;
    background-color: ${({ theme }) =>
      theme?.leadPageBtn?.background3 || "#f2f7cc"};
    color: ${({ theme }) =>
      process.env.REACT_APP_BROKER === "UIB"
        ? theme?.leadPageBtn?.textColor
        : theme?.Registration?.otherBtn?.hex1 || "#006400"};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
