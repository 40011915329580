export const TypeReturn = (type) => {
  switch (type) {
    case "car-insurance":
      return "car";
    case "two-wheeler-insurance":
      return "bike";
    case "commercial-vehicle-insurance":
      return "cv";
    default:
      return type;
  }
};

export const subroutes = [
  "car",
  "bike",
  "cv",
  "car-insurance",
  "two-wheeler-insurance",
  "commercial-vehicle-insurance",
];
