import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { MultiSelect, Error, ErrorMsg, Textbox } from "components";
import Popup from "../../../../components/Popup/Popup";
import { Row, Col, Button, Badge } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  setTempData,
  saveQuoteData,
} from "../../filterConatiner/quoteFilter.slice";
import DateInput from "../../../Home/steps/car-details/DateInput";
import "../policyTypePopup/policyTypePopup.css";
import _, { isNull } from "lodash";
import {
  set_temp_data,
  Variant as VariantType,
  getFuelType,
  //fueldelay as fdelay,
  getFuel as setFuelType,
} from "modules/Home/home.slice";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  clear as clearQuote,
  setQuotesList,
  SaveAddonsData,
} from "../../quote.slice";
import moment from "moment";
import { toDate, toDateOld, toDateDayOld } from "utils";
import {
  addDays,
  subMonths,
  differenceInMonths,
  differenceInDays,
  getYear,
  differenceInYears,
  subDays,
} from "date-fns";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import { getCalculatedNcb, getNewNcb } from "../../quoteUtil";
import Drawer from "@mui/material/Drawer";
import { CancelAll } from "modules/quotesPage/quote.slice";
//---------------------Theme imports----------------------------

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

//---------------------yup validate for man date and variant----------------------------

const yupValidate = yup.object({
  // variant: yup.string("Variant is required").required("Variant is required"),
  date2: yup.string().required("Date is required"),
});

const EditInfoPopup = ({ show, onClose, type, TypeReturn }) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan600 = useMediaPredicate("(max-width: 600px)");
  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const journey_type = query.get("journey_type");
  const FuelSelected = watch("fuel");
  const PrevIcSelected = watch("preIc");
  const GcvCarrierType = watch("carrierType");
  const versionSelected = watch("variant");
  const newRegDate = watch("date1");
  const newManDate = watch("date2");
  const { tempData, prevInsList } = useSelector((state) => state.quoteFilter);
  const typeId = query.get("typeid");
  const {
    temp_data,
    variant: varntMod,
    getFuel,
  } = useSelector((state) => state.home);
  //filtering electric vehicles
  const varnt =
    !_.isEmpty(varntMod) && TypeReturn(type) === "car"
      ? varntMod.filter((el) => el?.fuelFype === "ELECTRIC")
      : !_.isEmpty(varntMod)
      ? varntMod
      : [];
  const [manufactureDate, setManfactureDate] = useState(false);

  console.log("FuelSelected", FuelSelected);

  //----------------setting maximum date for new registration date-----------------

  let policyMax = temp_data?.newCar ? subMonths(new Date(Date.now()), 0) : subMonths(new Date(Date.now()), 9);
  let policyMin = temp_data?.newCar && subDays(new Date(Date.now()), 15)
  //---------------setLoader for fuel api & clear previous fuel type data-------------------

  // useEffect(() => {
  // 	dispatch(setFuelType([]));
  // }, []);

  //-------------------------------get available fuel types----------------------------
  // useEffect(() => {
  // 	if (temp_data?.productSubTypeId && temp_data?.modelId)
  // 		dispatch(
  // 			getFuelType({
  // 				modelId: temp_data?.modelId,
  // 				productSubTypeId: temp_data?.productSubTypeId,
  // 			})
  // 		);
  // }, [temp_data?.modelId, temp_data?.productSubTypeId]);

  //----------------------------setting man date to component--------------------------------

  useEffect(() => {
    if (newManDate) {
      setManfactureDate(`01-${newManDate}`);
    }
  }, [newManDate]);

  const dispatch = useDispatch();

  //--------------------------handle edit which redirect to reg page (also clearing quote data)-----------------------------------

  const handleEdit = () => {
    dispatch(setQuotesList([]));
    dispatch(clearQuote());
    dispatch(saveQuoteData(null));
    dispatch(
      setTempData({
        policyType: false,
      })
    );
    dispatch(
      set_temp_data({
        newCar: false,
        breakIn: false,
        leadJourneyEnd: false,
      })
    );

    history.push(
      `/${type}/registration?enquiry_id=${enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}${
        journey_type ? `&journey_type=${journey_type}` : ``
      }`
    );
    var data1 = {
      enquiryId: temp_data?.enquiry_id || enquiry_id,
      addonData: {
        addons: null,
        accessories: null,
        discounts: null,
        additional_covers: null,
      },
    };

    dispatch(SaveAddonsData(data1));
  };

  //---------------------on submit function for edit info popup---------------------------------

  const onSubmit = () => {
    dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
    let today = moment().format("DD-MM-YYYY");
    let a = temp_data?.expiry;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    let diffYear =
      newRegDate &&
      today &&
      differenceInYears(toDate(today), toDate(newRegDate));
    dispatch(
      set_temp_data({
        // gcvCarrierType: GcvCarrierType?.id,
        // fuel: FuelSelected?.value,
        // versionId: versionSelected?.id,
        // versionName: versionSelected?.name,
        regDate: newRegDate,
        manfDate: newManDate,
        prevIc: prevInsList.filter(
          (i) => i.previousInsurer === PrevIcSelected?.name
        )[0]?.companyAlias,
        prevIcFullName: PrevIcSelected?.name,

        ncb:
          tempData?.policyType === "Third-party" ||
          temp_data?.newCar ||
          tempData?.policyType === "Not sure"
            ? "0%"
            : diffDays && diffDays > 90
            ? "0%"
            : temp_data?.ncb && temp_data?.isNcbVerified === "Y"
            ? temp_data?.ncb
            : diffYear > 8
            ? "50%"
            : getCalculatedNcb(diffYear),

        newNcb:
          tempData?.policyType === "Third-party" ||
          temp_data?.newCar ||
          tempData?.policyType === "Not sure"
            ? "0%"
            : diffDays && diffDays > 90
            ? "0%"
            : temp_data?.prevShortTerm * 1
            ? temp_data?.ncb
            : temp_data?.newNcb && temp_data?.isNcbVerified === "Y"
            ? temp_data?.newNcb
            : getNewNcb(diffYear > 8 ? "50%" : getCalculatedNcb(diffYear)),
        ownerTypeId: owner?.value,
      })
    );
    dispatch(
      setTempData({
        idvChoosed: 0,
        idvType: "lowIdv",
      })
    );
    dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
    onClose(false);
  };

  //-------------------resetting man data nd its max and min limits when reg date value changes -------------------------

  const handleValueChange = (date) => {
    let newDate = moment(date).format("DD-MM-YYYY");

    if (newDate && manufactureDate) {
      let differenceInMonthsMan = differenceInMonths(
        toDate(newDate),
        toDate(manufactureDate)
      );

      let differneceInDaysMan = differenceInDays(
        toDate(newDate),
        toDate(manufactureDate)
      );

      if (
        differenceInMonthsMan < 0 ||
        differenceInMonthsMan > 36 ||
        differneceInDaysMan < 0
      ) {
        setValue("date2", "");
      }
    }
  };

  //--------------------loading variant data when fuel selected is changed-----------------------------

  // useEffect(() => {
  // 	if (temp_data?.modelId && FuelSelected) {
  // 		dispatch(
  // 			VariantType({
  // 				productSubTypeId: temp_data?.productSubTypeId,
  // 				modelId: temp_data?.modelId,
  // 				fuelType: FuelSelected?.value || temp_data?.fuel,
  // 				LpgCngKitValue: temp_data?.kit_val ? temp_data?.kit_val : null,
  // 			})
  // 		);
  // 	}
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [temp_data.modelId, FuelSelected]);

  ///-------------creating options for variant from api response  -----------------------------

  const Options = !_.isEmpty(varnt)
    ? varnt?.map(
        ({
          versionId,
          versionName,
          cubicCapacity,
          grosssVehicleWeight,
          kw,
          fuelFype,
          vehicleBuiltUp,
        }) => ({
          label:
            temp_data?.parent?.productSubTypeCode !== "GCV"
              ? `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${
                  fuelFype === "ELECTRIC"
                    ? kw
                      ? ` - ${kw}kW`
                      : ""
                    : cubicCapacity
                    ? ` - ${cubicCapacity}CC`
                    : ""
                }`
              : `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${grosssVehicleWeight ? ` - ${grosssVehicleWeight}GVW` : ""}`,
          name:
            temp_data?.parent?.productSubTypeCode !== "GCV"
              ? `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${
                  fuelFype === "ELECTRIC"
                    ? kw
                      ? ` - ${kw}kW`
                      : ""
                    : cubicCapacity
                    ? ` - ${cubicCapacity}CC`
                    : ""
                }`
              : `${versionName}${
                  vehicleBuiltUp ? `${" (" + vehicleBuiltUp}` + ")" : ""
                }${grosssVehicleWeight ? ` - ${grosssVehicleWeight}GVW` : ""}`,
          id: versionId,
          value: versionId,
        })
      )
    : [];
  //------------------------getiing fuel types from api and formating them -----------------------------

  const availableTypes = !_.isEmpty(getFuel)
    ? getFuel.map((item) => item.toUpperCase())
    : [];
  const Fuel = _.compact([
    availableTypes?.includes("PETROL") && {
      name: "Petrol",
      label: "Petrol",
      value: "PETROL",
      id: "PETROL",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/petrol10.png`,
    },
    availableTypes?.includes("DIESEL") && {
      name: "Diesel",
      label: "Diesel",
      value: "DIESEL",
      id: "DIESEL",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/oil2.png`,
    },
    (availableTypes?.includes("CNG") || availableTypes?.includes("LPG")) && {
      name: "Inbuilt CNG/LPG",
      label: "Inbuilt CNG/LPG",
      value: "CNG",
      id: "CNG",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/cng3.png`,
    },
    availableTypes?.includes("ELECTRIC") && {
      name: "Electric",
      label: "Electric",
      value: "ELECTRIC",
      id: "ELECTRIC",
      logo: `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/electric.png`,
    },
  ]);

  //------------carrier type options (this options will only be there in gcv) --------------------------

  const carrierType = [
    {
      name: "PUBLIC",
      label: "PUBLIC",
      value: "PUBLIC",
      id: "PUBLIC",
    },
    {
      name: "PRIVATE",
      label: "PRIVATE",
      value: "PRIVATE",
      id: "PRIVATE",
    },
  ];

  //--------------------owner type variables --------------------------

  const ownerType = [
    {
      value: 1,
      id: 1,
      name: "Individual",
      label: "Individual",
      ownerType: "Individual",
      ownerCode: "I",
    },
    {
      value: 2,
      id: 2,
      name: "Company",
      label: "Company",
      ownerType: "Company",
      ownerCode: "C",
    },
  ];

  //-----------------------------prefill man date and reg date------------------
  useEffect(() => {
    if (temp_data?.manfDate) setValue("date2", temp_data?.manfDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (temp_data?.regDate) setValue("date1", temp_data?.regDate);
  }, [temp_data]);

  //-------------------------------prev ic logic and prefill--------------------------------

  const [prevIcData, setPrevIcData] = useState(false);

  // ------ logic( only show prev ic option to change when it is selcted once )-----------------------

  useEffect(() => {
    if (
      temp_data?.prevIc &&
      !temp_data?.newCar &&
      temp_data?.prevIc !== "NEW" &&
      temp_data?.prevIc !== "New"
    ) {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);

  const insData = !_.isEmpty(prevInsList)
    ? prevInsList?.map(({ previousInsurer }) => {
        return {
          label: previousInsurer,
          name: previousInsurer,
          value: previousInsurer,
          id: previousInsurer,
        };
      })
    : [];

  ///-----------------handling drawer mobile----------------------
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (temp_data?.prevIcFullName) {
      let check = prevInsList?.filter(
        ({ previousInsurer }) => previousInsurer === temp_data?.prevIcFullName
      );
      let selected_option = check?.map(({ previousInsurer }) => {
        return {
          label: previousInsurer,
          name: previousInsurer,
          value: previousInsurer,
          id: previousInsurer,
        };
      });

      !_.isEmpty(selected_option) && setValue("preIc", selected_option[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.prevIcFullName, drawer]);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);

  const getPetrolValue = () => {
    let value = temp_data?.fuel;
    let finalValue =
      value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
    return finalValue;
  };

  const owner = watch("ownerType");

  useEffect(() => {
    if (temp_data?.ownerTypeId && !owner) {
      let selectedOption =
        ownerType.filter((x) => x.value * 1 === temp_data?.ownerTypeId * 1) ||
        [];
      console.log(selectedOption);
      !_.isEmpty(selectedOption) && setValue("ownerType", selectedOption[0]);
      console.log(selectedOption);
    }
  }, [temp_data?.ownerTypeId, ownerType]);
  console.log("errors", errors);

  const content = (
    <>
      <ContentWrap>
        {/* <HeaderPopup> Edit Policy Details</HeaderPopup> */}
        <ContentBox>
          <DetailsWrapper>
            {!lessthan767 && (
              <CarLogo
                src={
                  TypeReturn(type) !== "bike"
                    ? `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/new-car.jpg`
                    : `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/vehicle/bike2.png`
                }
                alt="car"
              />
            )}
            <span className="vehicleDetails">
              {temp_data?.manfName}-{temp_data?.modelName} -
              {temp_data?.versionName}{" "}
            </span>
          </DetailsWrapper>
          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>Registration Date</DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12}>
                <StyledDatePicker>
                  <div className="py-2 dateTimeOne " style={{}}>
                    <Controller
                      control={control}
                      name="date1"
                      defaultValue={temp_data?.regDate}
                      render={({ onChange, onBlur, value, name }) => (
                        <DateInput
                          editPopupDate
                          value={value}
                          selected={
                            newRegDate || temp_data?.regDate
                              ? toDate(newRegDate || temp_data?.regDate)
                              : false
                          }
                          minDate={policyMin}
                          maxDate={policyMax}
                          name={name}
                          onChange={onChange}
                          ref={register}
                          onValueChange={(date) => {
                            handleValueChange(date);
                          }}
                          rangeMax={
                            !temp_data?.newCar
                              ? getYear(new Date(Date.now()) + 1)
                              : false
                          }
                          readOnly={
                             isNull(temp_data?.regDate)
                          }
                          withPortal={lessthan767 ? true : false}
                        />
                      )}
                    />
                    {!!errors.date1 && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors.date1.message}
                      </ErrorMsg>
                    )}
                  </div>
                </StyledDatePicker>
              </Col>
            </Row>
          </DetailsSection>

          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>
                  Manufacture Month-Year
                </DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12}>
                <StyledDatePicker>
                  <div className="py-2 dateTimeOne " style={{}}>
                    <Controller
                      control={control}
                      name="date2"
                      defaultValue={temp_data?.manfDate}
                      render={({ onChange, onBlur, value, name }) => (
                        <DateInput
                          editPopupDate
                          selected={
                            newRegDate || temp_data?.regDate
                              ? toDate(
                                  manufactureDate || `01-${temp_data?.manfDate}`
                                )
                              : false
                          }
                          maxDate={
                            newRegDate || temp_data?.regDate
                              ? toDate(newRegDate || temp_data?.regDate)
                              : false
                          }
                          minDate={
                            newRegDate || temp_data?.regDate
                              ? toDateOld(newRegDate || temp_data?.regDate)
                              : false
                          }
                          value={value}
                          name={name}
                          onChange={onChange}
                          ref={register}
                          rangeMax={
                            !temp_data?.newCar
                              ? getYear(new Date(Date.now()) + 1)
                              : false
                          }
                          showMonthYearPicker={true}
                          dateFormat={"MM/yyyy"}
                          withPortal={lessthan767 ? true : false}
                        />
                      )}
                    />
                  </div>
                </StyledDatePicker>
              </Col>
            </Row>
          </DetailsSection>
          <DetailsSection>
            <Row>
              <Col md={4} sm={12}>
                <DetailsSectionLabel>Owner Type </DetailsSectionLabel>
              </Col>
              <Col md={8} sm={12} style={{}} className="dropDownColomn">
                <Controller
                  control={control}
                  name="ownerType"
                  // defaultValue={""}
                  render={({ onChange, onBlur, value, name }) => (
                    <MultiSelect
                      quotes
                      knowMore
                      name={name}
                      onChange={onChange}
                      ref={register}
                      value={value}
                      onBlur={onBlur}
                      isMulti={false}
                      options={ownerType}
                      placeholder={"Owner type"}
                      errors={errors.ownerType}
                      Styled
                      closeOnSelect
                    />
                  )}
                />
              </Col>
            </Row>
          </DetailsSection>
          <div className={!prevIcData ? "hiddenInput" : ""}>
            <DetailsSection>
              <Row>
                <Col md={4} sm={12}>
                  <DetailsSectionLabel>Previous Ic </DetailsSectionLabel>
                </Col>
                <Col md={8} sm={12} style={{}} className="dropDownColomn">
                  <Controller
                    control={control}
                    name="preIc"
                    defaultValue={""}
                    render={({ onChange, onBlur, value, name }) => (
                      <MultiSelect
                        quotes
                        knowMore
                        name={name}
                        onChange={onChange}
                        ref={register}
                        value={value}
                        onBlur={onBlur}
                        isMulti={false}
                        options={insData}
                        placeholder={"Previous Insurer"}
                        errors={errors.preIc}
                        Styled
                        closeOnSelect
                      />
                    )}
                  />
                </Col>
              </Row>
            </DetailsSection>
          </div>
        </ContentBox>
        <PremChangeWarning>
          <div class="ncb_msg">
            <div class="image"></div>
            <p
              className="messagetxt"
              style={{ fontSize: "15px", fontWeight: "800" }}
            >
              {"Your premium will be updated based on your changes"}
              <b></b>.
            </p>
          </div>
        </PremChangeWarning>
        <UpdateButton onClick={handleSubmit(onSubmit)}>Update</UpdateButton>
      </ContentWrap>
    </>
  );
  return !lessthan767 ? (
    <Popup
      height={lessthan767 ? "100%" : "auto"}
      width={lessthan767 ? "100%" : "700px"}
      top="40%"
      show={show}
      onClose={onClose}
      content={content}
      position="middle"
      hiddenClose={tempData?.policyType ? false : true}
    />
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          onClose={() => {
            setDrawer(false);
            onClose(false);
          }}
          onOpen={() => setDrawer(true)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MobileDrawerBody>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>
            {content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle />
    </>
  );
};

// PropTypes
EditInfoPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
EditInfoPopup.defaultProps = {
  show: false,
  onClose: () => {},
};

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 99999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 100000 !important;
  }
}
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;
const ContentWrap = styled.div`
  padding: 0px 32px 20px 32px;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 14px;
  line-height: 22px;
  color: #333;
  position: relative;
  margin-top: 30px;
`;
const HeaderPopup = styled.div`
  text-align: center !important;
  font-family: Merriweather, Georgia, serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #333;
  width: 100%;
`;
const ContentBox = styled.div`
  height: auto;
  width: 562px;
  margin: 12px auto 24px;
  background-color: #fff;
  .hiddenInput {
    display: none;
  }
  @media screen and (max-width: 993px) {
    width: 100%;
    margin-top: 50px;
  }
`;
const DetailsWrapper = styled.div`
  font-size: 16px;
  text-align: center;
  line-height: 25px;
  margin-top: -15px;
  max-height: 45px !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .vehicleDetails {
    max-width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
`;

const DetailsSection = styled.div`
  margin: 25px 0;
  display: table;
  width: 100%;
  min-height: 34px;

  .detail {
    float: left;
    width: 64%;
    float: right;
    margin-top: 15px;
  }
  .detail button {
    width: 44%;
    height: 21px;
    border-radius: 10.5px;
    border: solid 1px #212121;
    color: #212121;
    font-size: 14px;
    line-height: 12px;
    margin-right: 20px;
    padding: 0 12px;
    background: #fff;
  }
  .detail button.selected {
    color: #fff;
    border: solid #1596fe 1px;
    background: #1596fe;
  }
  .editDate {
    position: relative !important;
    z-index: 1000 !important;
  }
  .DetailsSection {
    width: 67%;
  }
  .vehicleCategory {
    height: 40px !important;
  }
  @media (max-width: 993px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    margin: 0;
  }
`;

const DetailsSectionLabel = styled.div`
  float: left;
  width: 120px;
  font-size: 14px;
  color: #696969;
  font-weight: 300;
  margin-top: 15px !important;
  white-space: pre;
`;

const UpdateButton = styled.button`
  background: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  color: #fff;
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
  width: 134px;
  height: 36px;
  border-radius: 3px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 0;
  margin-top: 30px;
`;

const PremChangeWarning = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  .ncb_msg {
    //background: #f9ffc8;
    line-height: normal;
    padding-left: 52px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 90%;
    height: 48px;
  }
  .ncb_msg .image {
    background-image: url(${process.env.REACT_APP_BASENAME !== "NA"
      ? `/${process.env.REACT_APP_BASENAME}`
      : ""}/assets/images/icon/bulb.png);
    width: 93px;
    height: 92px;
    left: -28px;
  }
  .newpopup_wrapper .ncb_msg p {
    color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
    line-height: normal;
    font-size: 12px;
  }
  .messagetxt {
    margin-top: 10px;
    margin-left: 10px;
  }
  @media screen and (max-width: 993px) {
    display: none;
  }
`;

const CarLogo = styled.img`
  height: 100px;
  width: 100px;
  max-wodth: 100px;
  max-height: 100px;
  margin-right: 10px;
  // margin-top: 10px;
  //	border-radius: 20px;
  //	box-shadow: 0px 4px 13px rgba(41, 41, 41, 0.35);
  //border: 2.5px solid #bdd400;
  position: relative;
  //right: 60px;
  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
    margin-top: 2px;
  }
`;

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }

  .dateTimeOne .react-datepicker__day--keyboard-selected,
  .dateTimeOne .react-datepicker__month-text--keyboard-selected,
  .dateTimeOne .react-datepicker__quarter-text--keyboard-selected,
  .dateTimeOne .react-datepicker__year-text--keyboard-selected,
  .dateTimeThree .react-datepicker__day--keyboard-selected,
  .dateTimeThree .react-datepicker__month-text--keyboard-selected,
  .dateTimeThree .react-datepicker__quarter-text--keyboard-selected,
  .dateTimeThree .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    color: #fff;
  }
`;
export default EditInfoPopup;
