import React from "react";
import { useSelector } from "react-redux";
import {
  NotFoundContainer,
  NotFound,
  NotFound404,
  H1Tag,
  H2Tag,
  AnchorTag,
} from "./style";

const Error = () => {
  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola-dashboard.fynity.in/";
      case "UNILIGHT":
        return "https://www.policyongo.com/pos/login";
      case "CP":
        return "https://www.comparepolicy.com/";
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return "http://preprod-dasbhoard-abibl.fynity.in/";
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-abibl.fynity.in/";
        } else {
          return "http://abibl-prod-dashboard.fynity.in/";
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-gramcover.fynity.in/";
        } else {
          return "https://dashboard.gramcover.com/";
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === 'YES' ? "https://nammacover.com/" : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === 'YES' ? "https://live.pincnews.co.in/" : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return "https://uatdashboard.insuringall.com/";
      case "BIMA":
        return "";
      case "ANALAH":
        return "";
      case "BAJAJ":
        return "https://www.bajajcapitalinsurance.com/";
        return "";
      case "UIB":
        return "";
      default:
        break;
    }
  };

  return (
    <NotFoundContainer>
      <NotFound>
        <NotFound404>
          <H1Tag>Oops!</H1Tag>
          <H2Tag>404 - The Page can't be found</H2Tag>
        </NotFound404>
        <AnchorTag
          onClick={() => {
            window.Android &&
              window.Android.SendToHomePage("Redirecting to homepage");
          }}
          href={UrlFn()}
        >
          Go To Homepage
        </AnchorTag>
      </NotFound>
    </NotFoundContainer>
  );
};

export default Error;
