//getting broker support Mail

export const brokerEmailFunction = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "insurance@olacabs.com";
    case "UNILIGHT":
      return "Support@policyongo.com";
    case "CP":
      return "help@comparepolicy.com";
    case "FYNTUNE":
      return "help@fyntune.com";
    case "ABIBL":
      return "clientfeedback.abibl@adityabirlacapital.com";
    case "GRAM":
      return "info@gramcover.com";
    case "ACE":
      return "operations@aceinsurance.com";
    case "SRIYAH":
      return "care@nammacover.com";
    case "EPOCH":
      return "support@policylo.com";
    case "PINC":
      return "info@pincinsure.com";
    case "RB":
      return "customersupport@renewbuy.com";
    case "SPA":
      return "care@insuringall.com";
    case "BIMA":
      return "contact@bimaplanner.com";
    case "ANALAH":
      return "contactus@analahinsurance.com";
    case "BAJAJ":
      return "info@bajajcapitalinsurance.com";
    case "UIB":
      return "services@uibindia.com";
    default:
      break;
  }
};

//getting broker number

export const brokerNumberFunction = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "7829411222";
    case "UNILIGHT":
      return "18002101225";
    case "CP":
      return "1800120000055";
    case "FYNTUNE":
      return "1800120000065";
    case "ABIBL":
      return "18002707000";
    case "GRAM":
      return "+91 9311672463";
    case "ACE":
      return "+918750881122";
    case "SRIYAH":
      return "+1800 203 0504";
    case "PINC":
      return "18002672670";
    case "EPOCH":
      return "1800-12000-0065";
    case "RB":
      return `18004197852`;
    case "SPA":
      return `+91-11-45675555`;
    case "BIMA":
      return `9999366494`;
    case "ANALAH":
      return `+91 9920878181`;
    case "BAJAJ":
      return `1800 212 123123`;
    case "UIB":
      return `+91 79820 39210`;
    default:
      return "18002669639";
  }
};

//getting logo url

export const getLogoUrl = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ola.png`;
    case "UNILIGHT":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/unilight.png`;
    case "CP":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/cp.png`;
    case "FYNTUNE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/fyntune.png`;
    case "ABIBL":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/abiblPdf.jpeg`;
    case "GRAM":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/gc.png`;
    case "ACE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ace.png`;
    case "SRIYAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/sriyah.png`;
    case "PINC":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/pinc.png`;
    case "EPOCH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/epochlogo.png`;
    case "RB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/rb.png`;
    case "SPA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/insuringall.jpeg`;
    case "BIMA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bima.png`;
    case "ANALAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/analah.jpeg`;
    case "BAJAJ":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bajaj.png`;
    case "UIB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/uib.png`;
    default:
      break;
  }
};

//------------ getting addon name---------------

export const getAddonName = (addon) => {
  switch (addon) {
    case "roadSideAssistance":
      return "Road Side Assistance";
    case "zeroDepreciation":
      return "Zero Depreciation";
    case "imt23":
      return "IMT - 23";
    case "keyReplace":
      return "Key Replacement";
    case "engineProtector":
      return "Engine Protector";
    case "ncbProtection":
      return "NCB Protection";
    case "consumables":
      return "Consumable";
    case "tyreSecure":
      return "Tyre Secure";
    case "returnToInvoice":
      return "Return To Invoice";
    case "lopb":
      return "Loss of Personal Belongings";
    default:
      return "";
  }
};

//------------ getting nvb calculation---------------

export const getCalculatedNcb = (yearDiff) => {
  switch (yearDiff) {
    case 0:
      return "0%";
    case 1:
      return "0%";
    case 2:
      return "20%";
    case 3:
      return "25%";
    case 4:
      return "35%";
    case 5:
      return "45%";
    case 6:
      return "50%";
    case 7:
      return "50%";
    case 8:
      return "50%";

    default:
      return "0%";
  }
};

export const getNewNcb = (ncb) => {
  switch (ncb) {
    case "0%":
      return "20%";
    case "20%":
      return "25%";
    case "25%":
      return "35%";
    case "35%":
      return "45%";
    case "45%":
      return "50%";
    case "50%":
      return "50%";
    default:
      return "0%";
  }
};

export const ContactFn = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "7829411222";
    case "UNILIGHT":
      return "18002101225";
    case "CP":
      return "1800120000055";
    case "FYNTUNE":
      return "1800120000065";
    case "ABIBL":
      return "18002707000";
    case "GRAM":
      return "+91 9311672463";
    case "ACE":
      return "+918750881122";
    case "SRIYAH":
      return "+1800 203 0504";
    case "PINC":
      return "18002672670";
    case "EPOCH":
      return "1800120000065";
    case "RB":
      return `18004197852`;
    case "SPA":
      return `+91-11-45675555`;
    case "BIMA":
      return `9999366494`;
    case "ANALAH":
      return `+91 9920878181`;
    case "BAJAJ":
      return `1800 212 123123`;
    case "UIB":
      return `+91 79820 39210`;
    default:
      return "18002669639";
  }
};
