import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Tile, ErrorMsg, Button as Btn, Error, MultiSelect } from "components";
import Popup from "../../../../components/Popup/Popup";
import { set_temp_data } from "modules/Home/home.slice";
import DateInput from "../../../proposal/DateInput";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toDate } from "utils";
import { subYears, addDays, differenceInDays } from "date-fns";
import _ from "lodash";
import "./preInsurerPopup.scss";
import { useLocation } from "react-router";
import { setTempData } from "../../filterConatiner/quoteFilter.slice";
import {
  CancelAll,
  saveSelectedQuoteResponse,
  setQuotesList,
  clear,
  SaveQuotesData,
  SaveAddonsData,
} from "../../quote.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const PrevInsurerPopup3 = ({
  show,
  onClose,
  type,
  typeId,
  quoteData,
  lessthan993,
  journey_type,
}) => {
  const { handleSubmit, register, watch, control, errors, setValue } = useForm({
    //resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const enquiry_id = query.get("enquiry_id");
  const { temp_data } = useSelector((state) => state.home);
  const { saveQuoteResponse, updateQuoteLoader, addOnsAndOthers } = useSelector(
    (state) => state.quotes
  );
  const [step, setStep] = useState(1);
  const prevIns = watch("prevIns");
  const { prevInsList, tempData } = useSelector((state) => state.quoteFilter);

  // prev ic check

  const [prevIcData, setPrevIcData] = useState(false);

  useEffect(() => {
    if (
      temp_data?.prevIc &&
      temp_data?.prevIc !== "others" &&
      temp_data?.prevIc !== "Not selected"
    ) {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);

  //break in 90 days logic

  const [daysToExpiry, setDaysToExpiry] = useState(false);

  useEffect(() => {
    let a = temp_data?.expiry;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    setDaysToExpiry(diffDays);
  }, [temp_data?.expiry]);

  //on submit condition

  const onSubmitPage1 = (data) => {
    // dispatch(
    // 	set_temp_data({
    // 		prevIc: data,
    // 		prevIcFullName: prevInsList.filter((i) => i.companyAlias === data)[0]
    // 			?.previousInsurer,
    // 	})
    // );
  };
  useEffect(() => {
    if (!updateQuoteLoader && prevIcData) {
      if (
        temp_data?.tab === "tab2" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90
      ) {
        if (temp_data?.tab === "tab2") {
          var newSelectedAccesories = [];
          if (
            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
          ) {
            var newD = {
              name: "External Bi-Fuel Kit CNG/LPG",
              sumInsured: Number(addOnsAndOthers?.externalBiFuelKit),
            };
            newSelectedAccesories.push(newD);
          }
          var discount = [];

          if (addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")) {
            discount.push({ name: "TPPD Cover" });
          }
          var data1 = {
            enquiryId: temp_data?.enquiry_id || enquiry_id,

            addonData: {
              addons: null,
              accessories: newSelectedAccesories,
              discounts: discount,
            },
          };

          dispatch(SaveAddonsData(data1));
        }
      }
      dispatch(SaveQuotesData(quoteData));
    }
  }, [updateQuoteLoader, prevIcData]);
  console.log(updateQuoteLoader, prevIcData, "updateQuoteLoader");
  useEffect(() => {
    if (saveQuoteResponse && !updateQuoteLoader) {
      dispatch(CancelAll(false));

      history.push(
        `/${type}/proposal-page?enquiry_id=${enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
      dispatch(saveSelectedQuoteResponse(false));
      dispatch(setQuotesList([]));
      dispatch(clear());
    }
  }, [saveQuoteResponse, updateQuoteLoader]);

  const length = !_.isEmpty(prevInsList) ? prevInsList?.length : 0;
  const TileModels = !_.isEmpty(prevInsList)
    ? length > 8
      ? prevInsList.slice(0, 25)
      : prevInsList
    : [];

  const content = (
    <>
      <Body>
        <Page1 display={step === 1}>
          <Row>
            <ModelWrap>
              <RegiHeading>
                Who was your previous insurance provider?
              </RegiHeading>
              <TileConatiner>
                <Row className="mx-auto">
                  {!_.isEmpty(prevInsList) ? (
                    TileModels?.map(
                      ({ previousInsurer, companyAlias, logo }, index) => (
                        <Col
                          xs="6"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          className="d-flex justify-content-center mx-auto forcedWidth"
                        >
                          <Tile
                            prevIns
                            logo={logo}
                            id={companyAlias}
                            register={register}
                            name={"prevIns"}
                            value={companyAlias}
                            height={"80px"}
                            Imgheight={"auto"}
                            setValue={setValue}
                            Selected={prevIns || temp_data?.prevIc}
                            onClick={() => {
                              dispatch(
                                set_temp_data({
                                  prevIc: companyAlias,
                                  prevIcFullName: prevInsList.filter(
                                    (i) => i.companyAlias === companyAlias
                                  )[0]?.previousInsurer,
                                })
                              );
                            }}
                          />
                        </Col>
                      )
                    )
                  ) : (
                    <Col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      className="d-flex flex-column justify-content-center align-content-center"
                    >
                      <img
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/nodata3.png`}
                        alt="nodata"
                        height="200"
                        width="200"
                        className="mx-auto"
                      />
                      <label
                        className="text-secondary text-center mt-1"
                        style={{ fontSize: "16px" }}
                      >
                        No Data Found
                      </label>
                    </Col>
                  )}
                </Row>
              </TileConatiner>
              <TabContinueWrap>
                {/* <div onClick={() => handleNoPrev()}>
									I dont know the Policy details
								</div> */}
              </TabContinueWrap>
            </ModelWrap>
          </Row>
        </Page1>
      </Body>
    </>
  );

  return (
    <Popup
      height={lessthan993 ? "100%" : step === 4 ? "auto" : "auto"}
      width={lessthan993 ? "100%" : step === 4 ? "520px" : "900px"}
      top="40%"
      show={show}
      onClose={onClose}
      content={content}
      position="middle"
      //	backGround="grey"
      outside={step === 5 || step === 4 ? true : false}
      overFlowDisable={true}
      hiddenClose={step === 5 || step === 4 ? true : false}
      //	backGroundImage={true}
    />
  );
};

// PropTypes
PrevInsurerPopup3.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
PrevInsurerPopup3.defaultProps = {
  show: false,
  onClose: () => {},
};

//comment

const Body = styled.div`
  padding: 0 15px 15px;
  position: relative;
  margin-top: 15px;
  @media (max-width: 993px) {
  }
`;
const ModelWrap = styled.div`
  float: left;
  width: 100%;
  padding: 10px 22px 22px 22px;
  min-height: ${(props) => (props?.reviced ? " 480px" : "560px")};
  height: ${(props) =>
    props?.ncbPopup ? "580px" : props?.reviced ? " 480px" : "560px"};
  max-height: ${(props) =>
    props?.ncbPopup ? "580px" : props?.reviced ? " 480px" : "560px"};
  //overflow-y: scroll;
  //	margin-top: 30px;

  .btn-danger {
    color: #fff;
    background-color: ${({ theme }) =>
      theme.prevPolicy?.color1 || theme?.prevPop?.background || "#fb6c46"};
    border-color: ${({ theme }) =>
      theme.prevPolicy?.color2 || theme?.prevPop?.color || "#fb6c47"};

    &:focus,
    &.focus {
      box-shadow: ${({ theme }) =>
        theme.prevPolicy?.boxShadow || theme.prevPop?.boxShadow};
    }
  }

  .btn-outline-danger {
    color: ${({ theme }) =>
      theme.prevPolicy?.color1 || theme?.prevPop?.background || "#fb6c46"};
    border-color: ${({ theme }) =>
      theme.prevPolicy?.color2 || theme?.prevPop?.color || "#fb6c47"};
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: ${({ theme }) =>
      theme.prevPolicy?.color1 || theme?.prevPop?.background || "#fb6c46"};
    border-color: ${({ theme }) =>
      theme.prevPolicy?.color2 || theme?.prevPop?.color || "#fb6c47"};
  }

  .btn-outline-danger:hover {
    color: #fff;
    background-color: ${({ theme }) =>
      theme.prevPolicy?.color1 || theme?.prevPop?.background || "#fb6c46"};
    border-color: ${({ theme }) =>
      theme.prevPolicy?.color2 || theme?.prevPop?.color || "#fb6c47"};
  }

  @media (max-width: 993px) {
    max-height: 600px;
    height: auto;
  }
`;
const RegiHeading = styled.div`
  text-align: center !important;
  font-family: ${({ theme }) =>
    theme.regularFont?.fontFamily || " Merriweather, Georgia, serif"};
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #333;
  width: 100%;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const TabContinueWrap = styled.div`
  /* float: left; */
  position: relative;
  bottom: 0px;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 0;
  & div {
    font-size: 13px;
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 8px;
  }
`;

const TileConatiner = styled.div`
  position: relative;
  left: 0px;
  top: 0px;

  .forcedWidth {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }
  @media (max-width: 993px) {
    .forcedWidth {
      flex: 0 0 33.33337%;
      max-width: 33.33337%;
    }
  }
`;

const Page1 = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
`;

const Page2 = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
`;

export default PrevInsurerPopup3;
