/*___________________unilight______________________*/
// const proposalCardSummary = {
// 	background: `linear-gradient(90deg, rgba(0,116,149,1) 0%, rgba(0,162,204,1) 100%)`,
// };
// //uni
// const proposalCardActive = {
// 	background: `linear-gradient(90deg, rgba(0,86,179,1) 0%, rgba(0,123,255,1) 100%)`,
// };

// const proposalProceedBtn = {
// 	hex1: "#007bff",
// 	hex2: "#007bff",
// };

// //gender button (uni)
// const genderProposal = {
// 	background: `radial-gradient(circle, rgba(0,103,214,1) 0%, rgba(0,95,197,1) 71%, rgba(0,95,197,1) 79%)`,
// 	boxShadow: "6.994px 5.664px 21px #5cabff",
// };

// //toggle questions
// const questionsProposal = {
// 	color: `rgb(24 163 204)`,
// 	toggleBackgroundColor: `#007bff`,
// }

// const sideCardProposal = {
// 	icon: 'text-primary',
// 	badge:'primary'

// }

// const Button = {
// 	default: {
// 		background: "#3fd49f",
// 		border_color: "#1ad2a4",
// 		text_color: "#fff",
// 	},
// 	danger: {
// 		background: "#ff8983",
// 		border_color: "#ff8683",
// 		text_color: "#fff",
// 	},
// 	warning: {
// 		background: "#eebb4d",
// 		border_color: "#eebb4d",
// 		text_color: "#fff",
// 	},
// 	outline: {
// 		background: "#fff",
// 		border_color: "#cb68d9",
// 		text_color: "#b406cc",
// 	},
// 	square_outline: {
// 		background: "#fff",
// 		border_color: "#CE93D8",
// 		text_color: "#000000",
// 	},
// 	outline_secondary: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	submit_disabled: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	outline_solid: {
// 		background1: "#0084f4",
// 		background2: "#00c48c",
// 		border_color: "#D0D0D0",
// 		text_color: "#fff",
// 	},
// };

// const Products = {
// 	color: "#007bff;",
// 	headColor: "rgb(20 116 125)",
// 	border: " 1px solid #007bff;",
// 	liBorder: "2px solid #007bff;",
// };

// const CheckCircle = {
// backgroundImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/check-blue-circle.svg)`,
// };

// const Tab = {
// 	color: `#007bff;`,
// };

// const CheckBox = {
// 	color: `#007bff;`,
// 	border: " 1px solid #007bff;",
// };

// const Header = {
// 	color: `#007bff;`,
// 	border: " 1px solid #007bff;",
// 	borderLogo: " 2.5px solid #007bff;",
// 	hex1: "#007bff;",
// 	hex2: "#007bff;",
// };

// const FilterConatiner = {
// 	color: `#007bff;`,
// };

// const QuoteCard = {
// 	color: `#007bff ;`,
// 	color2: "rgba(1,44,82,1) !important",
// 	color3: "#007bff  !important",
// 	border: " 1px solid #007bff;",
// 	border2: "1px solid rgba(1,44,82,1) !important",
// 	borderCheckBox: "2px solid #007bff;",
// 	boxShadow:
// 		"0 8px 25px -5px rgb(133 244 255), 0 10px 10px -5px rgb(133 244 255)",
// 	mouseHoverShadow: "0px 0px 8px 0px rgb(133 244 255  / 50%)",
// };

// const QuotePopups = {
// 	color: `#007bff;`,
// 	border: "1px solid #007bff;",
// 	hex1: "#007bff;",
// 	hex2: "#007bff;",
// 	color2: "rgba(1,44,82,1)",
// 	border2: "1px solid rgba(1,44,82,1)",
// 	border3: "2px solid #007bff;",
// };

// const ReviewCard = {
// 	color: `#007bff;`,
// 	border: " 1px solid #007bff;",
// 	borderDashed: " 1px dashed #007bff;",
// 	color2: "#007bff;",
// };

// const avatar = {
// 	// border: "solid 2px #006600",
// 	border: "solid 2px #007bff",
// };

// const reactCalendar = {
// 	selected: "#007bff",
// 	header: "#007bff",
// };

// //lead-page
// const leadPageBtn = {
// 	background: "#007bff",
// };

// //registration-page
// const Registration = {
// 	proceedBtn: {
// 		background: "#66b0ff",
// 	},
// 	otherBtn: {
// 		hex1: "#007bff",
// 		hex2: "#006fe6",
// 	},
// };

// //vehicle-type
// const VehicleType = {
// 	buttonVariant: "primary",
// 	outlineVariant: "outline-primary",
// 	filterIconCol: "none",
// };

// //vehicle-details
// /*stepper-color*/
// const Stepper = {
// 	stepperColor: {
// 		background: "#007bff",
// 	},
// };

// //Tile-Component
// const Tile = {
// 	boxShadow: "0px 0px 7px 0px #66b0ff",
// 	border: "2px solid #007bff",
// 	color: "#007bff !important",
// };

// const VehicleProceed = {
// 	hex1: "#054f6c",
// 	hex2: "#085572",
// };

// const journeyType = {
// 	buttonVariant: "primary",
// 	outlineVariant: "outline-primary",
// 	filterIconCol: "none",
// };

// const toggleModal = {
// 	color: "#033500",
// walletImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/wallet-1.svg)`,
// };

// const comparePage = {
// 	color: "#fb6b45 !important",
// 	border: "1px solid #fb6b45 !important",
// 	textColor: "#fff",
// };

// const City = {
// 	color: '#fff',
// 	background: '#007bff',
// 	border: '1px solid #007bff'
// }

/*________________Compare Policy____________________*/

// const proposalCardSummary = {
// 	background: `linear-gradient(90deg, rgba(255,127,49,1) 0%, rgba(255,142,74,1) 100%)`,
// };

// const proposalCardActive = {
// 	background: `linear-gradient(90deg, #e13405 0%,  #fb7550 100%)`,
// };

// const proposalProceedBtn = {
// 	hex1: "#fb6b45",
// 	hex2: "#fb6b45",
// };

// //gender button (uni)
// const genderProposal = {
// 	background: `radial-gradient(circle, rgba(255,110,22,1) 17%, rgba(255,123,41,1) 85%)`,
// 	boxShadow: "6.994px 5.664px 21px #fdc4b4",
// };

// //toggle questions
// const questionsProposal = {
// 	color: `rgb(251 147 68)`,
// 	toggleBackgroundColor: `#fb6b45`,
// };

// const sideCardProposal = {
// 	icon: "text-danger",
// 	badge: "danger",
// 	editBadge: "dark",
// 	linkColor: "",
// };

// const Button = {
// 	default: {
// 		background: "#3fd49f",
// 		border_color: "#1ad2a4",
// 		text_color: "#fff",
// 	},
// 	danger: {
// 		background: "#ff8983",
// 		border_color: "#ff8683",
// 		text_color: "#fff",
// 	},
// 	warning: {
// 		background: "#eebb4d",
// 		border_color: "#eebb4d",
// 		text_color: "#fff",
// 	},
// 	outline: {
// 		background: "#fff",
// 		border_color: "#cb68d9",
// 		text_color: "#b406cc",
// 	},
// 	square_outline: {
// 		background: "#fff",
// 		border_color: "#CE93D8",
// 		text_color: "#000000",
// 	},
// 	outline_secondary: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	submit_disabled: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	outline_solid: {
// 		background1: "#0084f4",
// 		background2: "#00c48c",
// 		border_color: "#D0D0D0",
// 		text_color: "#fff",
// 	},
// };

// const Products = {
// 	color: "#007bff;",
// 	headColor: "rgb(20 116 125)",
// 	border: " 1px solid #fb6b45;",
// 	liBorder: "2px solid #fb6b45;",
// };

// const CheckCircle = {
// backgroundImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/check-blue-circle.svg)`,
// };

// const Tab = {
// 	color: `#fb6b45`,
// 	border: " 1px solid #fb6b45;",
// };

// const CheckBox = {
// 	color: `#fb6b45`,
// 	border: " 1px solid #fb6b45",
// };

// const Header = {
// 	color: `#fb6b45`,
// 	border: " 1px solid #fb6b45",
// 	borderLogo: " 2.5px solid #fb6b45",
// 	hex1: "#fb6b45",
// 	hex2: "#fb6b45",
// };

// const FilterConatiner = {
// 	color: `#fb6b45`,
// 	lightColor: "#ffa992",
// 	lightBorder: " .5px solid #ffa992",
// 	lightBorder1: " .5px solid #ffa992",
// };

// const QuoteBorderAndFont = {
// 	navColor: "#ffa992 !important",
// 	//checkBoxAndToggleBackground:""
// 	journeyCategoryButtonColor: "#fb6b45",
// };

// const QuoteCard = {
// 	color: `#fb6b45`,
// 	color2: " #fb6b45 !important",
// 	color3: "#fb6b45 !important",
// 	border: " 1px solid #fb6b45",
// 	border2: "1px solid  #fb6b45 !important",
// 	borderCheckBox: "1px solid d4d5d9",
// 	boxShadow: "0 8px 25px -5px #ffc1b1, 0 10px 10px -5px #ffc1b1",
// 	mouseHoverShadow: "0px 0px 8px 0px #ffc1b1",
// };

// const QuotePopups = {
// 	color: `#fb6b45`,
// 	border: "1px solid #fb6b45",
// 	hex1: "#fb6b45",
// 	hex2: "#fb6b45",
// 	color2: " #fb6b45",
// 	border2: "1px solid  #fb6b45",
// 	border3: "2px solid #fb6b45",
// 	lg: "-webkit-linear-gradient(-134deg,#fb6b45,#d9b7bb)",
// 	prevpopBorder: "33px solid #fb6b45",
// };

// const ReviewCard = {
// 	color: `#007bff;`,
// 	border: " 1px solid #007bff;",
// 	borderDashed: " 1px dashed #007bff;",
// 	color2: "#007bff;",
// };

// const avatar = {
// 	// border: "solid 2px #006600",
// 	border: "solid 2px #fb6b45",
// };

// const reactCalendar = {
// 	selected: "#fb6b45",
// 	background: "#fb6b45",
// };

// //lead-page
// const leadPageBtn = {
// 	background: "#fb6b45",
// 	link: "",
// 	textColor: "",
// 	borderRadius: "",
// };

// //registration-page
// const Registration = {
// 	proceedBtn: {
// 		background: `#fb6b45`,
// 		color: "white",
// 	},
// 	otherBtn: {
// 		hex1: "#fb7550",
// 		hex2: "#fb6b45",
// 	},
// };

// //vehicle-type
// const VehicleType = {
// 	buttonVariant: "primary",
// 	outlineVariant: "outline-primary",
// 	filterIconCol: "none",
// };

// //vehicle-details
// /*stepper-color*/
// const Stepper = {
// 	stepperColor: {
// 		background: "#fb6b45",
// 	},
// 	linkColor: "text-primary",
// };

// //Tile-Component
// const Tile = {
// 	boxShadow: "0px 0px 7px 0px #fb6b45",
// 	border: "2px solid #fb6b45",
// 	color: "#fb6b45 !important",
// };

// const VehicleProceed = {
// 	hex1: "#054f6c",
// 	hex2: "#085572",
// };

// const journeyType = {
// 	buttonVariant: "danger",
// 	outlineVariant: "outline-danger",
// 	filterIconCol: "none",
// };

// const toggleModal = {
// 	color: "#033500",
// walletImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/wallet-1.svg)`,
// };

// const comparePage = {
// 	color: "#fb6b45 !important",
// 	border: "1px solid #fb6b45 !important",
// 	textColor: "#fff",
// };

// //payment-confirmation
// const paymentConfirmation = {
// 	headers: "linear-gradient(81.67deg,#fb6b45 0%,#ffffcc 100%)",
// 	Button: { hex2: "#fb6b45", hex1: "#fb6b45" },
// 	table: "danger",
// 	buttonTextColor: "white",
// };

// const CallUs = {
// 	icon: "#fb6b45",
// };

// const PaymentStatus = {
// 	hex1: "#fb7550",
// 	hex2: "#fb6b45",
// };

// const MultiSelect = {
// 	color: "#fb6b45",
// };

// const proposalHeader = {
// 	color: "black",
// };

// const comparePage2 = {
// 	background: "#fb6b45",
// 	borderBottom: "1px solid #fb6b45",
// 	iconsColor: "#fb6b45",
// 	borderHeader: "5px solid #fb6b45",
// 	lg: "-webkit-linear-gradient(-134deg, #ffffff, #fb6b45) ",
// };

// const CardPop = {
// 	background: "#fb6b4521",
// 	border: "1px solid #fb6b45",
// };

// const NoPlanCard = {
// 	background: "#fb6b4521",
// 	border: "2px dotted #fb6b45",
// };

// const prevPolicy = {
// 	color1: "#fb6c46",
// 	color2: "#fb6c47",
// 	boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
// };

// const LandingPage = {
// 	color: "#fb6b45",
// 	color2: "#fff",
// 	color3: "black",
// 	filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
// };

// const Payment = {
// 	color: "#fb6b45",
// 	fontFamily: '',
// };

// const City = {
// 	color: "#fff",
// 	background: "#fb6b45",
// 	border: "1px solid #fb6b45",
// };

// const Home = {
// 	backgroundColor: "",
// };

// const regularFont = {
// 	fontFamily: ''
// }

// const mediumFont = {
// 	fontFamily: ''
// }

// export default process.env.REACT_APP_BROKER === "CP"
// 	? {
// 			Button,
// 			proposalCardSummary,
// 			proposalCardActive,
// 			genderProposal,
// 			proposalProceedBtn,
// 			Tab,
// 			CheckBox,
// 			Header,
// 			FilterConatiner,
// 			QuoteCard,
// 			QuotePopups,
// 			ReviewCard,
// 			avatar,
// 			reactCalendar,
// 			Products,
// 			leadPageBtn,
// 			Registration,
// 			VehicleType,
// 			Stepper,
// 			Tile,
// 			VehicleProceed,
// 			CheckCircle,
// 			journeyType,
// 			toggleModal,
// 			comparePage,
// 			questionsProposal,
// 			sideCardProposal,
// 			paymentConfirmation,
// 			CallUs,
// 			PaymentStatus,
// 			MultiSelect,
// 			proposalHeader,
// 			comparePage2,
// 			CardPop,
// 			NoPlanCard,
// 			prevPolicy,
// 			LandingPage,
// 			Payment,
// 			City,
// 			Home,
// 			QuoteBorderAndFont,
// 	  }
// 	: {};

const CP = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, rgba(255,127,49,1) 0%, rgba(255,142,74,1) 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #e13405 0%,  #fb7550 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#fb6b45",
    hex2: "#fb6b45",
  },
  genderProposal: {
    background: `radial-gradient(circle, rgba(255,110,22,1) 17%, rgba(255,123,41,1) 85%)`,
    boxShadow: "6.994px 5.664px 21px #fdc4b4",
  },
  questionsProposal: {
    color: `rgb(251 147 68)`,
    toggleBackgroundColor: `#fb6b45`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff;",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #fb6b45;",
    liBorder: "2px solid #fb6b45;",
  },
  CheckCircle: {
    backgroundImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#fb6b45`,
    border: "1px solid #fb6b45;",
  },
  CheckBox: {
    color: `#fb6b45`,
    border: "1px solid #fb6b45",
  },
  Header: {
    color: `#fb6b45`,
    border: " 1px solid #fb6b45",
    borderLogo: " 2.5px solid #fb6b45",
    hex1: "#fb6b45",
    hex2: "#fb6b45",
  },
  FilterConatiner: {
    color: `#fb6b45`,
    lightColor: "#ffa992",
    lightBorder: " .5px solid #ffa992",
    lightBorder1: " .5px solid #ffa992",
    clearAllIcon: "",
    editIconColor: "#000",
    clearAllTextColor: "#000",
  },
  QuoteCard: {
    color: `#fb6b45`,
    color2: " #fb6b45 !important",
    color3: "#fb6b45 !important",
    border: " 1px solid #fb6b45",
    border2: "1px solid  #fb6b45 !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #ffc1b1, 0 10px 10px -5px #ffc1b1",
    mouseHoverShadow: "0px 0px 8px 0px #ffc1b1",
  },
  QuotePopups: {
    color: `#fb6b45`,
    border: "1px solid #fb6b45",
    hex1: "#fb6b45",
    hex2: "#fb6b45",
    color2: " #fb6b45",
    border2: "1px solid  #fb6b45",
    border3: "2px solid #fb6b45",
    lg: "-webkit-linear-gradient(-134deg,#fb6b45,#d9b7bb)",
    prevpopBorder: "33px solid #fb6b45",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#ffa992 !important",
    checkBoxAndToggleBackground: "",
    journeyCategoryButtonColor: "#fb6b45",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #fb6b45",
  },
  reactCalendar: {
    selected: "#fb6b45",
    background: "#fb6b45",
  },
  leadPageBtn: {
    background: "#fb6b45",
    background3: "#ffe2da",
    link: "",
    textColor: "",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#fb6b45`,
      color: "white",
    },
    otherBtn: {
      hex1: "#fb7550",
      hex2: "#fb6b45",
    },
  },
  VehicleType: {
    buttonVariant: "primary",
    outlineVariant: "outline-primary",
    filterIconCol: "none",
  },
  Stepper: {
    stepperColor: {
      background: "#fb6b45",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #fb6b45",
    border: "2px solid #fb6b45",
    color: "#fb6b45 !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#fb6b45 !important",
    border: "1px solid #fb6b45 !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#fb6b45 0%,#ffffcc 100%)",
    Button: { hex2: "#fb6b45", hex1: "#fb6b45" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#fb6b45",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#fb6b45",
  },
  MultiSelect: {
    color: "#fb6b45",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#fb6b45",
    borderBottom: "1px solid #fb6b45",
    iconsColor: "#fb6b45",
    borderHeader: "5px solid #fb6b45",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #fb6b45) ",
  },
  CardPop: {
    background: "#fb6b4521",
    border: "1px solid #fb6b45",
  },
  NoPlanCard: {
    background: "#fb6b4521",
    border: "2px dotted #fb6b45",
  },
  prevPolicy: {
    color1: "#fb6c46",
    color2: "#fb6c47",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#fb6b45",
    color2: "#fff",
    color3: "black",
    filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    loginBtnColor: "#fb6b45 !important",
  },
  Payment: {
    color: "#fb6b45",
  },
  City: {
    color: "#fff",
    background: "#fb6b45",
    border: "1px solid #fb6b45",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    fontFamily: "",
  },
  mediumFont: {
    fontFamily: "",
  },
  floatButton: {
    floatColor: "#fb6b45",
    floatBorder: "1px solid #fb6b45",
  },
  boldBorder: {
    border: "5px solid #fb6b45",
    boxShadow: "#fb6b4542 0px -50px 36px -28px inset",
  },
  links: {
    color: "rgb(255, 127, 49)",
  },
};

// export default process.env.REACT_APP_BROKER === "CP"
// 	? { ...CP } : {}

//-----------------ABIBL-----------------

const ABIBL = {
  proposalCardSummary: {
    background: "linear-gradient(90deg,#95181A 0%,#95181A 100%)",
  },
  proposalCardActive: {
    background: "linear-gradient(90deg,#c7222a 0%,#c7222a 100%)",
  },
  proposalProceedBtn: {
    hex1: "#C7222A",
    hex2: "#C7222A",
  },
  genderProposal: {
    background: "radial-gradient(circle,rgb(199 34 42) 17%,rgb(179 45 51) 85%)",
    boxShadow: "6.994px 5.664px 21px #fffff",
  },
  questionsProposal: {
    color: "#C7222A",
    toggleBackgroundColor: "#C7222A",
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "danger",
    linkColor: "#C7222A",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff;",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #C7222A;",
    liBorder: "2px solid #C7222A;",
  },
  CheckCircle: {
    backgroundImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: "#c7222a",
    border: " 1px solid #c7222a;",
  },
  CheckBox: {
    color: "#f",
    border: "1px solid #000",
  },
  Header: {
    color: "#c7222a",
    border: " 1px solid #c7222a",
    borderLogo: " 2.5px solid #c7222a",
    hex1: "#c7222a",
    hex2: "#c7222a",
  },
  FilterConatiner: {
    color: "#c7222a",
    lightColor: "#DA9089",
    lightBorder: ".5px solid #DA9089",
    lightBorder1: "1px solid #DA9089",
    clearAllIcon: "",
    editIconColor: "#000",
    clearAllTextColor: "#000",
  },
  QuoteCard: {
    color: "#c7222a",
    color2: " #8B151B !important",
    color3: "#c7222a !important",
    border: " 1px solid #c7222a",
    border2: "1px solid  #8B151B !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #DA9089, 0 10px 10px -5px #DA9089",
    mouseHoverShadow: "0px 0px 8px 0px #DA9089",
  },
  QuotePopups: {
    color: "#c7222a",
    border: "1px solid #c7222a",
    hex1: "#c7222a",
    hex2: "#c7222a",
    color2: " #8B151B",
    color3: " #8B151B !important",
    border2: "1px solid  #8B151B",
    border3: "2px solid #c7222a",
    lg: "-webkit-linear-gradient(-134deg,#c7222a,#d9b7bb)",
    prevpopBorder: "33px solid #c7222a",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    borderRadius: "5px",
    linkColor: "#c7222a",
    navColor: " #DA9089 !important",
    shadowCheck:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",

    fontColor: "white",
    filterPropertyCheckBox:
      "invert(22%) sepia(76%) saturate(2340%) hue-rotate(338deg) brightness(89%) contrast(99%)",
    fontFamily: "pfhandbooksans_regular",
    fontFamilyBold: "pfhandbooksans_medium",
    moneyBackground: " #EDC9C6 !important",
    filterShadow: " 0 9px 13px #cfcfcf",
    checkBoxAndToggleBackground: "#c7222a",
    headerTopQuotesPage: "137px",
    scrollHeight: 137,
    toasterTop: "210px",
    alertTop: "205px",
  },
  BackButton: {
    backButtonTop: "190px",
    backButtonTopMobile: "160px",
  },

  //
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #c7222a",
  },
  reactCalendar: {
    selected: "#C7222A",
    background: "#C7222A",
  },
  leadPageBtn: {
    background: "#C7222A",
    background3: "#ffe2da",
    link: "text-danger",
    textColor: "white",
    borderRadius: "8px",
  },
  Registration: {
    proceedBtn: {
      background: "#C7222A",
      color: "white",
    },
    otherBtn: {
      hex2: "#C7222A",
      hex1: "#C7222A",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol:
      "invert(42%) sepia(93%) saturate(1352%) hue-rotate(324deg) brightness(90%) contrast(119%)",
  },
  Stepper: {
    stepperColor: {
      background: "#C7222A",
    },
    linkColor: "text-danger",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #C7222A",
    border: "2px solid #C7222A",
    color: "#C7222A !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#c7222a !important",
    border: "1px solid #c7222a !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#C7222A 0%,#ffffcc 100%)",
    Button: {
      hex2: "#C7222A",
      hex1: "#C7222A",
    },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#C7222A",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#C7222A",
  },
  MultiSelect: {
    color: "#909090",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#c7222a",
    borderBottom: "1px solid #c7222a",
    iconsColor: "#c7222a",
    borderHeader: "5px solid #C7222A",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #c7222a)",
  },
  CardPop: {
    background: "#EDC9C6",
    border: "1px solid #EDC9C6",
  },
  NoPlanCard: {
    background: "#EDC9C6",
    border: "2px dotted #EDC9C6",
  },
  prevPolicy: {
    color1: "#c7222a",
    color2: "#c7222a",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#c7222a",
    color2: "#fff",
    color3: "black",
    // filter:
    // 	"invert(21%) sepia(52%) saturate(3968%) hue-rotate(295deg) brightness(86%) contrast(98%)",
    filter:
      "invert(97%) sepia(83%) saturate(3866%) hue-rotate(65deg) brightness(80%) contrast(98%)",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    loginBtnColor: "#c7222a !important",
  },
  Home: {
    backgroundColor: "#FFF",
  },
  regularFont: {
    fontFamily: "pfhandbooksans_regular",
  },
  mediumFont: {
    fontFamily: "pfhandbooksans_medium",
  },
  floatButton: {
    floatColor: "#C7222A",
    floatBorder: "1px solid #C7222A",
    whiteColor: "#fff",
  },
  boldBorder: {
    border: "5px solid #C7222A",
    boxShadow: "#c7222a21 0px -50px 36px -28px inset",
  },
  links: {
    color: "rgb(199, 34, 42)",
  },
  primaryColor: {
    color: "rgb(199, 34, 42) !important"
  }
};

//Gram Cover

const Gramcover = {
  proposalCardSummary: {
    background: "linear-gradient(90deg,rgb(26 63 90) 0%,rgb(26 63 90) 100%)",
  },
  proposalCardActive: {
    background:
      "linear-gradient(90deg,rgb(52, 91, 120) 0%,rgb(52, 91, 120) 100%)",
  },
  proposalProceedBtn: {
    hex1: "rgb(52, 91, 120)",
    hex2: "rgb(52, 91, 120)",
  },
  genderProposal: {
    background:
      "radial-gradient(circle,rgb(52, 91, 120) 17%,rgb(52, 91, 120) 85%)",
    boxShadow: "6.994px 5.664px 21px #fffff",
  },
  questionsProposal: {
    color: "rgb(52, 91, 120)",
    toggleBackgroundColor: "rgb(52, 91, 120)",
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "danger",
    linkColor: "rgb(52, 91, 120)",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff;",
    headColor: "rgb(20 116 125)",
    border: " 1px solid rgb(52, 91, 120);",
    liBorder: "2px solid rgb(52, 91, 120);",
  },
  CheckCircle: {
    backgroundImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: "rgb(52, 91, 120)",
    border: " 1px solid rgb(52, 91, 120);",
  },
  CheckBox: {
    color: "#f",
    border: " 1px solid #000",
  },
  Header: {
    color: "rgb(52, 91, 120)",
    border: " 1px solid rgb(52, 91, 120)",
    borderLogo: " 2.5px solid rgb(52, 91, 120)",
    hex1: "rgb(52, 91, 120)",
    hex2: "rgb(52, 91, 120)",
  },
  FilterConatiner: {
    color: "rgb(52, 91, 120)",
    lightColor: "rgb(52, 91, 120)",
    lightBorder: ".5px solid rgb(52, 91, 120)",
    lightBorder1: "1px solid rgb(52, 91, 120)",
    clearAllIcon: "",
    editIconColor: "#000",
    clearAllTextColor: "#000",
  },
  QuoteCard: {
    color: "rgb(52, 91, 120)",
    color2: " #8B151B !important",
    color3: "rgb(52, 91, 120) !important",
    border: " 1px solid rgb(52, 91, 120)",
    border2: "1px solid  #8B151B !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow:
      "0 8px 25px -5px rgb(52 91 120 / 40%), 0 10px 10px -5px rgb(52 91 120 / 40%)",
    mouseHoverShadow: "0px 0px 8px 0px rgb(52 91 120 / 40%)",
  },
  QuotePopups: {
    color: "rgb(52, 91, 120)",
    border: "1px solid rgb(52, 91, 120)",
    hex1: "rgb(52, 91, 120)",
    hex2: "rgb(52, 91, 120)",
    color2: " rgb(52, 91, 120)",
    color3: " rgb(52, 91, 120) !important",
    border2: "1px solid  rgb(52, 91, 120)",
    border3: "2px solid rgb(52, 91, 120)",
    lg: "-webkit-linear-gradient(-134deg,rgb(52, 91, 120),#d9b7bb)",
    prevpopBorder: "33px solid rgb(52, 91, 120)",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    borderRadius: "5px",
    linkColor: "rgb(52, 91, 120)",
    navColor: " #DA9089 !important",
    shadowCheck:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",

    fontColor: "white",
    filterPropertyCheckBox:
      "invert(22%) sepia(76%) saturate(2340%) hue-rotate(150deg) brightness(89%) contrast(99%)",
    moneyBackground: " #EDC9C6 !important",
    filterShadow: " 0 9px 13px #cfcfcf",
    checkBoxAndToggleBackground: "rgb(52, 91, 120)",
    headerTopQuotesPage: "137px",
    scrollHeight: 137,
    toasterTop: "210px",
  },
  BackButton: {
    backButtonTop: "190px",
    backButtonTopMobile: "160px",
  },

  //
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px rgb(52, 91, 120)",
  },
  reactCalendar: {
    selected: "rgb(52, 91, 120)",
    background: "rgb(52, 91, 120)",
  },
  leadPageBtn: {
    background: "rgb(52, 91, 120)",
    background3: "",
    link: "rgb(52, 91, 120)",
    textColor: "white",
    // borderRadius: "8px",
  },
  Registration: {
    proceedBtn: {
      background: "rgb(52, 91, 120)",
      color: "white",
    },
    otherBtn: {
      hex2: "rgb(52, 91, 120)",
      hex1: "rgb(52, 91, 120)",
    },
  },
  VehicleType: {
    buttonVariant: "info",
    outlineVariant: "outline-info",
    filterIconCol:
      "invert(42%) sepia(93%) saturate(1352%) hue-rotate(191deg) brightness(48%) contrast(119%)",
  },
  Stepper: {
    stepperColor: {
      background: "rgb(52, 91, 120)",
    },
    linkColor: "rgb(52, 91, 120)",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px rgb(52, 91, 120)",
    border: "2px solid rgb(52, 91, 120)",
    color: "rgb(52, 91, 120) !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "rgb(52, 91, 120) !important",
    border: "1px solid rgb(52, 91, 120) !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,rgb(52, 91, 120) 0%,#ffffcc 100%)",
    Button: {
      hex2: "rgb(52, 91, 120)",
      hex1: "rgb(52, 91, 120)",
    },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "rgb(52, 91, 120)",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "rgb(52, 91, 120)",
  },
  MultiSelect: {
    color: "#909090",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "rgb(52, 91, 120)",
    borderBottom: "1px solid rgb(52, 91, 120)",
    iconsColor: "rgb(52, 91, 120)",
    borderHeader: "5px solid rgb(52, 91, 120)",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, rgb(52, 91, 120))",
  },
  CardPop: {
    background: "#EDC9C6",
    border: "1px solid #EDC9C6",
  },
  NoPlanCard: {
    background: "#EDC9C6",
    border: "2px dotted #EDC9C6",
  },
  prevPolicy: {
    color1: "rgb(52, 91, 120)",
    color2: "rgb(52, 91, 120)",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "rgb(52, 91, 120)",
    color2: "#fff",
    color3: "black",
    // filter:
    // 	"invert(21%) sepia(52%) saturate(3968%) hue-rotate(295deg) brightness(86%) contrast(98%)",
    filter:
      "invert(15%) sepia(96%) saturate(3866%) hue-rotate(155deg) brightness(70%) contrast(50%)",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    loginBtnColor: "rgb(52, 91, 120) !important",
  },
  Home: {
    backgroundColor: "#FFF",
  },
  regularFont: {
    // fontFamily: 'Quicksand',
  },
  mediumFont: {
    // fontFamily: 'Quicksand',
  },
  floatButton: {
    floatColor: "rgb(52, 91, 120)",
    floatBorder: "1px solid rgb(52, 91, 120)",
  },
  boldBorder: {
    border: "5px solid rgb(52, 91, 120)",
    boxShadow: "rgb(52 91 120 / 38%) 0px -50px 36px -28px inset",
  },
  links: {
    color: "rgb(52, 91, 120)",
  },
  primaryColor: {
    color: "rgb(52, 91, 120) !important"
  }
};

//-----------------PINC-----------------

const PINC = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #E1056D 0%, #E1056D 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #E1056D 0%,  #E1056D 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#E1056D",
    hex2: "#E1056D",
  },
  genderProposal: {
    background: `radial-gradient(circle, #E1056D 17%, #E1056D 85%)`,
    boxShadow: "6.994px 5.664px 21px #e1056d45",
  },
  questionsProposal: {
    color: `#E1056D`,
    toggleBackgroundColor: `#E1056D`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #E1056D;",
    liBorder: "2px solid #E1056D;",
  },
  CheckCircle: {
    backgroundImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#E1056D`,
    border: "1px solid #E1056D;",
  },
  CheckBox: {
    color: `#e1056d80`,
    border: "1px solid #E1056D",
  },
  Header: {
    color: `#E1056D`,
    border: " 1px solid #E1056D",
    borderLogo: " 2.5px solid #E1056D",
    hex1: "#E1056D",
    hex2: "#E1056D",
  },
  FilterConatiner: {
    color: `#E1056D`,
    lightColor: "#e1056d80",
    lightBorder: " .5px solid #E1056D",
    lightBorder1: " .5px solid #E1056D",
    clearAllIcon: "",
    editIconColor: "#000",
    clearAllTextColor: "#000",
  },
  QuoteCard: {
    color: `#E1056D`,
    color2: " #E1056D !important",
    color3: "#E1056D !important",
    border: " 1px solid #E1056D",
    border2: "1px solid  #E1056D !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #e1056d3d, 0 10px 10px -5px #e1056d3d",
    mouseHoverShadow: "0px 0px 8px 0px #E1056D",
  },
  QuotePopups: {
    color: `#E1056D`,
    border: "1px solid #E1056D",
    hex1: "#E1056D",
    hex2: "#E1056D",
    color2: " #E1056D",
    border2: "1px solid  #E1056D",
    border3: "2px solid #E1056D",
    lg: "-webkit-linear-gradient(-134deg,#E1056D,#d9b7bb)",
    prevpopBorder: "33px solid #E1056D",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#E1056D !important",
    checkBoxAndToggleBackground: "",
    journeyCategoryButtonColor: "#E1056D !important",
    fontFamily: "sans-serif",
    fontFamilyBold: "sans-serif",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #E1056D",
  },
  reactCalendar: {
    selected: "#E1056D",
    background: "#E1056D",
  },
  leadPageBtn: {
    background: "#E1056D",
    background3: "#ffc6e1",
    link: "",
    textColor: "",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#E1056D`,
      color: "white",
    },
    otherBtn: {
      hex1: "#e1056db0",
      hex2: "#E1056D",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    filterIconCol:
      "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
  },
  Stepper: {
    stepperColor: {
      background: "#E1056D",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #E1056D",
    border: "2px solid #E1056D",
    color: "#E1056D !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#E1056D !important",
    border: "1px solid #E1056D !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#E1056D 0%,#ffffcc 100%)",
    Button: { hex2: "#E1056D", hex1: "#E1056D" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#E1056D",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#E1056D",
  },
  MultiSelect: {
    color: "#E1056D",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#E1056D",
    borderBottom: "1px solid #E1056D",
    iconsColor: "#E1056D",
    borderHeader: "5px solid #E1056D",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #E1056D) ",
  },
  CardPop: {
    background: "#fb6b4521",
    border: "1px solid #E1056D",
  },
  NoPlanCard: {
    background: "#fb6b4521",
    border: "2px dotted #E1056D",
  },
  prevPolicy: {
    color1: "#E1056D",
    color2: "#E1056D",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#E1056D",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    loginBtnColor: "#E1056D !important",
  },
  Payment: {
    color: "#E1056D",
  },
  City: {
    color: "#fff",
    background: "#E1056D",
    border: "1px solid #E1056D",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "quickSand",
    fontFamily: "sans-serif",
  },
  mediumFont: {
    fontFamily: "sans-serif",
  },
  floatButton: {
    floatColor: "#E1056D",
    floatBorder: "1px solid #E1056D",
  },
  boldBorder: {
    border: "5px solid #E1056D",
    boxShadow: "#fb6b4542 0px -50px 36px -28px inset",
  },
  links: {
    color: "rgb(225, 5, 109)",
  },
};

const RB = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #F27F21 0%, #F27F21 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #F27F21 0%,  #F27F21 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#F27F21",
    hex2: "#F27F21",
  },
  genderProposal: {
    background: `radial-gradient(circle, #F27F21 17%, #F27F21 85%)`,
    boxShadow: "6.994px 5.664px 21px #ff66004a",
  },
  questionsProposal: {
    color: `#F27F21`,
    toggleBackgroundColor: `#F27F21`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #F27F21;",
    liBorder: "2px solid #F27F21;",
  },
  CheckCircle: {
    backgroundImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#F27F21`,
    border: "1px solid #F27F21;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #F27F21",
  },
  Header: {
    color: `#F27F21`,
    border: " 1px solid #F27F21",
    borderLogo: " 2.5px solid #F27F21",
    hex1: "#F27F21",
    hex2: "#F27F21",
  },
  FilterConatiner: {
    color: `#F27F21`,
    lightColor: "#F27F21",
    lightBorder: " .5px solid #F27F21",
    lightBorder1: " .5px solid #F27F21",
    clearAllIcon: "",
    editIconColor: "#0066AF",
    clearAllTextColor: "#fff",
  },
  QuoteCard: {
    color: `#F27F21`,
    color2: " #F27F21 !important",
    color3: "#F27F21 !important",
    border: " 1px solid #F27F21",
    border2: "1px solid  #F27F21 !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #F27F21",
  },
  QuotePopups: {
    color: `#F27F21`,
    border: "1px solid #F27F21",
    hex1: "#F27F21",
    hex2: "#F27F21",
    color2: "#F27F21",
    border2: "1px solid  #F27F21",
    border3: "2px solid #F27F21",
    lg: "-webkit-linear-gradient(-134deg,#F27F21,#d9b7bb)",
    prevpopBorder: "33px solid #F27F21",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#F27F21 !important",
    checkBoxAndToggleBackground: "",
    journeyCategoryButtonColor: "#F27F21 !important",
    fontFamily: "lato",
    fontFamilyBold: "lato",
    linkColor: "#F27F21",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #F27F21",
  },
  reactCalendar: {
    selected: "#F27F21",
    background: "#F27F21",
  },
  leadPageBtn: {
    background1: "#DD5F41",
    background2: "#E2844A",
    background3: "#ffe2da",
    link: "",
    textColor: "",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#F27F21`,
      color: "white",
    },
    otherBtn: {
      hex1: "#DD5F41",
      hex2: "#E2844A",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(42%) sepia(93%) saturate(1183%) hue-rotate(347deg) brightness(90%) contrast(111%)",
  },
  Stepper: {
    stepperColor: {
      background: "#F27F21",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #F27F21",
    border: "2px solid #F27F21",
    color: "#F27F21 !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#F27F21 !important",
    border: "1px solid #F27F21 !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#F27F21 0%,#ffffcc 100%)",
    Button: { hex2: "#F27F21", hex1: "#F27F21" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#F27F21",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#F27F21",
  },
  MultiSelect: {
    color: "#F27F21",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#F27F21",
    borderBottom: "1px solid #F27F21",
    iconsColor: "#F27F21",
    borderHeader: "5px solid #F27F21",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #F27F21) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #F27F21",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #F27F21",
  },
  prevPolicy: {
    color1: "#F27F21",
    color2: "#F27F21",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#F27F21",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter: "grayscale(118%) sepia(81%) hue-rotate(351deg) saturate(26)",
    loginBtnColor: "#F27F21 !important",
  },
  Payment: {
    color: "#F27F21",
  },
  City: {
    color: "#fff",
    background: "#F27F21",
    border: "1px solid #F27F21",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "pt-serif",
    fontFamily: "lato",
    fontColor: "#0066AF",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "lato",
  },
  floatButton: {
    floatColor: "#0066AF",
    floatBorder: "1px solid #0066AF",
  },
  boldBorder: {
    border: "5px solid #F27F21",
    boxShadow: "#ff660059 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(242, 127, 33)",
  },
  primaryColor: {
    color: "rgb(242, 127, 33) !important"
  }
};

const SPA = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #3877D6 0%, #3877D6 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #3877D6 0%,  #3877D6 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#3877D6",
    hex2: "#3877D6",
  },
  genderProposal: {
    background: `radial-gradient(circle, #3877D6 17%, #3877D6 85%)`,
    boxShadow: "6.994px 5.664px 21px #3877d64a",
  },
  questionsProposal: {
    color: `#3877D6`,
    toggleBackgroundColor: `#3877D6`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #3877D6;",
    liBorder: "2px solid #3877D6;",
  },
  CheckCircle: {
    backgroundImage: `url(/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#3877D6`,
    border: "1px solid #3877D6;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #3877D6",
  },
  Header: {
    color: `#3877D6`,
    border: " 1px solid #3877D6",
    borderLogo: " 2.5px solid #3877D6",
    hex1: "#3877D6",
    hex2: "#3877D6",
  },
  FilterConatiner: {
    color: `#3877D6`,
    lightColor: "#3877D6",
    lightBorder: " .5px solid #3877D6",
    lightBorder1: " .5px solid #3877D6",
    clearAllIcon: "",
    editIconColor: "#000",
    clearAllTextColor: "#fff",
  },
  QuoteCard: {
    color: `#3877D6`,
    color2: " #3877D6 !important",
    color3: "#3877D6 !important",
    border: " 1px solid #3877D6",
    border2: "1px solid  #3877D6 !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #3877D6",
  },
  QuotePopups: {
    color: `#3877D6`,
    border: "1px solid #3877D6",
    hex1: "#3877D6",
    hex2: "#3877D6",
    color2: "#3877D6",
    border2: "1px solid  #3877D6",
    border3: "2px solid #3877D6",
    lg: "-webkit-linear-gradient(-134deg,#3877D6,#d9b7bb)",
    prevpopBorder: "33px solid #3877D6",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#3877D6 !important",
    checkBoxAndToggleBackground: "#3877D6",
    journeyCategoryButtonColor: "#3877D6 !important",
    fontFamily: "roboto",
    fontFamilyBold: "roboto",
    linkColor: "#3877D6",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #3877D6",
  },
  reactCalendar: {
    selected: "#3877D6",
    background: "#3877D6",
  },
  leadPageBtn: {
    background1: "#3877D6",
    background2: "#3877D6",
    background3: "#bbd6ff",
    link: "",
    textColor: "",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#3877D6`,
      color: "white",
    },
    otherBtn: {
      hex1: "#3877D6",
      hex2: "#3877D6",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(42%) sepia(75%) saturate(1183%) hue-rotate(558deg) brightness(91%) contrast(93%)",
  },
  Stepper: {
    stepperColor: {
      background: "#3877D6",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #3877D6",
    border: "2px solid #3877D6",
    color: "#3877D6 !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#3877D6 !important",
    border: "1px solid #3877D6 !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#3877D6 0%,#ffffcc 100%)",
    Button: { hex2: "#3877D6", hex1: "#3877D6" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#3877D6",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#3877D6",
  },
  MultiSelect: {
    color: "#3877D6",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#3877D6",
    borderBottom: "1px solid #3877D6",
    iconsColor: "#3877D6",
    borderHeader: "5px solid #3877D6",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #3877D6) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #3877D6",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #3877D6",
  },
  prevPolicy: {
    color1: "#3877D6",
    color2: "#3877D6",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#3877D6",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter: "grayscale(118%) sepia(64%) hue-rotate(558deg) saturate(26)",
    loginBtnColor: "#3877D6 !important",
  },
  Payment: {
    color: "#3877D6",
  },
  City: {
    color: "#fff",
    background: "#3877D6",
    border: "1px solid #3877D6",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "Poppins",
    fontFamily: "roboto",
    fontColor: "#000",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "roboto",
  },
  floatButton: {
    floatColor: "#0066AF",
    floatBorder: "1px solid #0066AF",
  },
  boldBorder: {
    border: "5px solid #3877D6",
    boxShadow: "#0066af59 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(56, 119, 214)",
  },
  primaryColor: {
    color: "rgb(56, 119, 214) !important"
  }
};
const EPOCH = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #27adb0 0%, #27adb0 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #27adb0 0%,  #27adb0 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#27adb0",
    hex2: "#27adb0",
  },
  genderProposal: {
    background: `radial-gradient(circle, #27adb0 17%, #27adb0 85%)`,
    boxShadow: "6.994px 5.664px 21px #3877d64a",
  },
  questionsProposal: {
    color: `#27adb0`,
    toggleBackgroundColor: `#27adb0`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #27adb0;",
    liBorder: "2px solid #27adb0;",
  },
  CheckCircle: {
    backgroundImage: `url(/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#27adb0`,
    border: "1px solid #27adb0;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #27adb0",
  },
  Header: {
    color: `#27adb0`,
    border: " 1px solid #27adb0",
    borderLogo: " 2.5px solid #27adb0",
    hex1: "#27adb0",
    hex2: "#27adb0",
  },
  FilterConatiner: {
    color: `#27adb0`,
    lightColor: "#27adb0",
    lightBorder: " .5px solid #27adb0",
    lightBorder1: " .5px solid #27adb0",
    clearAllIcon: "",
    editIconColor: "#f47220",
    clearAllTextColor: "#000",
  },
  QuoteCard: {
    color: `#27adb0`,
    color2: " #27adb0 !important",
    color3: "#27adb0 !important",
    border: " 1px solid #27adb0",
    border2: "1px solid  #27adb0 !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #27adb0",
  },
  QuotePopups: {
    color: `#27adb0`,
    border: "1px solid #27adb0",
    hex1: "#27adb0",
    hex2: "#27adb0",
    color2: "#27adb0",
    border2: "1px solid  #27adb0",
    border3: "2px solid #27adb0",
    lg: "-webkit-linear-gradient(-134deg,#27adb0,#d9b7bb)",
    prevpopBorder: "33px solid #27adb0",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#27adb0 !important",
    checkBoxAndToggleBackground: "#27adb0",
    journeyCategoryButtonColor: "#27adb0 !important",
    fontFamily: "roboto",
    fontFamilyBold: "roboto",
    linkColor: "#27adb0",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #27adb0",
  },
  reactCalendar: {
    selected: "#27adb0",
    background: "#27adb0",
  },
  leadPageBtn: {
    background1: "#27adb0",
    background2: "#27adb0",
    background3: "#c3feff",
    link: "",
    textColor: "",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#27adb0`,
      color: "white",
    },
    otherBtn: {
      hex1: "#27adb0",
      hex2: "#27adb0",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(42%) sepia(75%) saturate(1135%) hue-rotate(513deg) brightness(91%) contrast(93%)",
  },
  Stepper: {
    stepperColor: {
      background: "#27adb0",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #27adb0",
    border: "2px solid #27adb0",
    color: "#27adb0 !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#27adb0 !important",
    border: "1px solid #27adb0 !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#27adb0 0%,#ffffcc 100%)",
    Button: { hex2: "#27adb0", hex1: "#27adb0" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#27adb0",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#27adb0",
  },
  MultiSelect: {
    color: "#27adb0",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#27adb0",
    borderBottom: "1px solid #27adb0",
    iconsColor: "#27adb0",
    borderHeader: "5px solid #27adb0",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #27adb0) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #27adb0",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #27adb0",
  },
  prevPolicy: {
    color1: "#27adb0",
    color2: "#27adb0",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#27adb0",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter: "grayscale(118%) sepia(64%) hue-rotate(558deg) saturate(26)",
    loginBtnColor: "#27adb0 !important",
  },
  Payment: {
    color: "#27adb0",
  },
  City: {
    color: "#fff",
    background: "#27adb0",
    border: "1px solid #27adb0",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "Poppins",
    fontFamily: "roboto",
    fontColor: "#000",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "roboto",
  },
  floatButton: {
    floatColor: "#f47220",
    floatBorder: "1px solid #f47220",
  },
  boldBorder: {
    border: "5px solid #27adb0",
    boxShadow: "#beffee 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(56, 119, 214)",
  },
  primaryColor: {
    color: "rgb(56, 119, 214) !important"
  }
};

const ANALAH = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #092C4C 0%, #092C4C 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #092C4C 0%,  #092C4C 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#092C4C",
    hex2: "#092C4C",
  },
  genderProposal: {
    background: `radial-gradient(circle, #092C4C 17%, #092C4C 85%)`,
    boxShadow: "6.994px 5.664px 21px #092C4C4a",
  },
  questionsProposal: {
    color: `#092C4C`,
    toggleBackgroundColor: `#092C4C`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #092C4C;",
    liBorder: "2px solid #092C4C;",
  },
  CheckCircle: {
    backgroundImage: `url(/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#092C4C`,
    border: "1px solid #092C4C;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #092C4C",
  },
  Header: {
    color: `#092C4C`,
    border: " 1px solid #092C4C",
    borderLogo: " 2.5px solid #092C4C",
    hex1: "#092C4C",
    hex2: "#092C4C",
  },
  FilterConatiner: {
    color: `#E2B93B`,
    lightColor: "#092C4C",
    lightBorder: " .5px solid #092C4C",
    lightBorder1: " .5px solid #092C4C",
    clearAllIcon: "#E2B93B",
    editIconColor: "#E2B93B",
    clearAllTextColor: "#fff",
  },
  QuoteCard: {
    color: `#092C4C`,
    color2: " #092C4C !important",
    color3: "#092C4C !important",
    border: " 1px solid #092C4C",
    border2: "1px solid  #092C4C !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #092C4C",
  },
  QuotePopups: {
    color: `#092C4C`,
    border: "1px solid #092C4C",
    hex1: "#092C4C",
    hex2: "#092C4C",
    color2: "#092C4C",
    border2: "1px solid  #092C4C",
    border3: "2px solid #092C4C",
    lg: "-webkit-linear-gradient(-134deg,#092C4C,#d9b7bb)",
    prevpopBorder: "33px solid #092C4C",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#092C4C !important",
    checkBoxAndToggleBackground: "#092C4C",
    journeyCategoryButtonColor: "#092C4C !important",
    fontFamily: "Inter-Regular",
    fontFamilyBold: "Inter-Regular",
    linkColor: "#092C4C",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #092C4C",
  },
  reactCalendar: {
    selected: "#092C4C",
    background: "#092C4C",
  },
  leadPageBtn: {
    background: "#092C4C",
    background1: "#092C4C",
    background2: "#092C4C",
    link: "",
    textColor: "#fff",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#092C4C`,
      color: "white",
    },
    otherBtn: {
      hex1: "#092C4C",
      hex2: "#092C4C",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(25deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(42%) sepia(75%) saturate(1183%) hue-rotate(560deg) brightness(50%) contrast(93%)",
  },
  Stepper: {
    stepperColor: {
      background: "#092C4C",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #092C4C",
    border: "2px solid #092C4C",
    color: "#092C4C !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#092C4C !important",
    border: "1px solid #092C4C !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#092C4C 0%,#ffffcc 100%)",
    Button: { hex2: "#092C4C", hex1: "#092C4C" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#092C4C",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#092C4C",
  },
  MultiSelect: {
    color: "#092C4C",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#092C4C",
    borderBottom: "1px solid #092C4C",
    iconsColor: "#092C4C",
    borderHeader: "5px solid #092C4C",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #092C4C) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #092C4C",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #092C4C",
  },
  prevPolicy: {
    color1: "#092C4C",
    color2: "#092C4C",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#092C4C",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter: "grayscale(118%) sepia(64%) hue-rotate(558deg) saturate(26)",
    loginBtnColor: "#092C4C !important",
  },
  Payment: {
    color: "#092C4C",
  },
  City: {
    color: "#fff",
    background: "#092C4C",
    border: "1px solid #092C4C",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "Inter-Regular",
    fontFamily: "Inter-Regular",
    fontColor: "#E2B93B",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "Inter-Regular",
  },
  floatButton: {
    floatColor: "#E2B93B",
    floatBorder: "1px solid #E2B93B",
  },
  boldBorder: {
    border: "5px solid #092C4C",
    boxShadow: "#092C4C59 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(56, 119, 214)",
  },
  primaryColor: {
    color: "rgb(56, 119, 214) !important"
  }
};
const BAJAJ = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #D71419 0%, #D71419 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #ED1C24 0%,  #ED1C24 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#ED1C24",
    hex2: "#ED1C24",
  },
  genderProposal: {
    background: `radial-gradient(circle, #ED1C24 17%, #ED1C24 85%)`,
    boxShadow: "6.994px 5.664px 21px #ED1C244a",
  },
  questionsProposal: {
    color: `#ED1C24`,
    toggleBackgroundColor: `#ED1C24`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #ED1C24;",
    liBorder: "2px solid #ED1C24;",
  },
  CheckCircle: {
    backgroundImage: `url(/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#ED1C24`,
    border: "1px solid #ED1C24;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #ED1C24",
  },
  Header: {
    color: `#ED1C24`,
    border: " 1px solid #ED1C24",
    borderLogo: " 2.5px solid #ED1C24",
    hex1: "#ED1C24",
    hex2: "#ED1C24",
  },
  FilterConatiner: {
    color: `#1F9DD3`,
    lightColor: "#ED1C24",
    lightBorder: " .5px solid #ED1C24",
    lightBorder1: " .5px solid #ED1C24",
    clearAllIcon: "#1F9DD3",
    editIconColor: "#1F9DD3",
    clearAllTextColor: "#fff",
  },
  QuoteCard: {
    color: `#ED1C24`,
    color2: " #ED1C24 !important",
    color3: "#ED1C24 !important",
    border: " 1px solid #ED1C24",
    border2: "1px solid  #ED1C24 !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #ED1C24",
  },
  QuotePopups: {
    color: `#ED1C24`,
    border: "1px solid #ED1C24",
    hex1: "#ED1C24",
    hex2: "#ED1C24",
    color2: "#ED1C24",
    border2: "1px solid  #ED1C24",
    border3: "2px solid #ED1C24",
    lg: "-webkit-linear-gradient(-134deg,#ED1C24,#d9b7bb)",
    prevpopBorder: "33px solid #ED1C24",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#ED1C24 !important",
    checkBoxAndToggleBackground: "#ED1C24",
    journeyCategoryButtonColor: "#ED1C24 !important",
    fontFamily: "Montserrat",
    fontFamilyBold: "Montserrat",
    linkColor: "#ED1C24",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #ED1C24",
  },
  reactCalendar: {
    selected: "#ED1C24",
    background: "#ED1C24",
  },
  leadPageBtn: {
    background: "#ED1C24",
    background1: "#ED1C24",
    background2: "#ED1C24",
    link: "",
    textColor: "#fff",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#ED1C24`,
      color: "white",
    },
    otherBtn: {
      hex1: "#ED1C24",
      hex2: "#ED1C24",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(25deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(48%) sepia(91%) saturate(1352%) hue-rotate(202deg) brightness(90%) contrast(189%)",
  },
  Stepper: {
    stepperColor: {
      background: "#ED1C24",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #ED1C24",
    border: "1px solid #ED1C24",
    color: "#ED1C24 !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#ED1C24 !important",
    border: "1px solid #ED1C24 !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#ED1C24 0%,#ffffcc 100%)",
    Button: { hex2: "#ED1C24", hex1: "#ED1C24" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#ED1C24",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#ED1C24",
  },
  MultiSelect: {
    color: "#ED1C24",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#ED1C24",
    borderBottom: "1px solid #ED1C24",
    iconsColor: "#ED1C24",
    borderHeader: "5px solid #ED1C24",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #ED1C24) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #ED1C24",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #ED1C24",
  },
  prevPolicy: {
    color1: "#ED1C24",
    color2: "#ED1C24",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#ED1C24",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter:
      "invert(97%) sepia(83%) saturate(3866%) hue-rotate(65deg) brightness(95%) contrast(98%)",
    loginBtnColor: "#ED1C24 !important",
  },
  Payment: {
    color: "#ED1C24",
  },
  City: {
    color: "#fff",
    background: "#ED1C24",
    border: "1px solid #ED1C24",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "Montserrat",
    fontFamily: "Montserrat",
    fontColor: "#1F9DD3",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "Montserrat",
  },
  floatButton: {
    floatColor: "#1F9DD3",
    floatBorder: "1px solid #1F9DD3",
  },
  boldBorder: {
    border: "5px solid #ED1C24",
    boxShadow: "#ED1C2459 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(56, 119, 214)",
  },
  primaryColor: {
    color: "rgb(56, 119, 214) !important"
  }
};
const UIB = {
  primary: "#0067b1",
  secondary: "#6a7695",
  proposalCardSummary: {
    background: `linear-gradient(90deg, #4b6bfa  0%, #4b6bfa  100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #0067b1 0%,  #0067b1 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#0067b1",
    hex2: "#0067b1",
  },
  genderProposal: {
    background: `radial-gradient(circle, #0067b1 17%, #0067b1 85%)`,
    boxShadow: "6.994px 5.664px 21px #6a7695",
  },
  questionsProposal: {
    color: `#0067b1`,
    toggleBackgroundColor: `#0067b1`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #0067b1;",
    liBorder: "2px solid #0067b1;",
  },
  CheckCircle: {
    backgroundImage: `url(/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#0067b1`,
    border: "1px solid #0067b1;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #0067b1",
  },
  Header: {
    color: `#0067b1`,
    border: " 1px solid #0067b1",
    borderLogo: " 2.5px solid #0067b1",
    hex1: "#0067b1",
    hex2: "#0067b1",
  },
  FilterConatiner: {
    color: `#0067b1`,
    lightColor: "#0067b1",
    lightBorder: " .5px solid #0067b1",
    lightBorder1: " .5px solid #0067b1",
    clearAllIcon: "#0067b1",
    editIconColor: "#0067b1",
    clearAllTextColor: "#fff",
  },
  QuoteCard: {
    color: `#0067b1`,
    color2: " #0067b1 !important",
    color3: "#0067b1 !important",
    border: " 1px solid #0067b1",
    border2: "1px solid  #0067b1 !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #0067b1",
  },
  QuotePopups: {
    color: `#0067b1`,
    border: "1px solid #0067b1",
    hex1: "#0067b1",
    hex2: "#0067b1",
    color2: "#0067b1",
    border2: "1px solid  #0067b1",
    border3: "2px solid #0067b1",
    lg: "-webkit-linear-gradient(-134deg,#0067b1,#d9b7bb)",
    prevpopBorder: "33px solid #0067b1",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#0067b1 !important",
    checkBoxAndToggleBackground: "#0067b1",
    journeyCategoryButtonColor: "#0067b1 !important",
    fontFamily: "Arial",
    fontFamilyBold: "Arial",
    linkColor: "#0067b1",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #0067b1",
  },
  reactCalendar: {
    selected: "#0067b1",
    background: "#0067b1",
  },
  leadPageBtn: {
    background: "#0067b1",
    background1: "#0067b1",
    background2: "#0067b1",
    background3: "#0067b1",
    link: "",
    textColor: "#fff",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#0067b1`,
      color: "white",
    },
    otherBtn: {
      hex1: "#0067b1",
      hex2: "#0067b1",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(25deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(42%) sepia(93%) saturate(1352%) hue-rotate(589deg) brightness(47%) contrast(119%)",
  },
  Stepper: {
    stepperColor: {
      background: "#0067b1",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #0067b1",
    border: "2px solid #0067b1",
    color: "#0067b1 !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#0067b1 !important",
    border: "1px solid #0067b1 !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#0067b1 0%,#ffffcc 100%)",
    Button: { hex2: "#0067b1", hex1: "#0067b1" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#0067b1",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#0067b1",
  },
  MultiSelect: {
    color: "#0067b1",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#0067b1",
    borderBottom: "1px solid #0067b1",
    iconsColor: "#0067b1",
    borderHeader: "5px solid #0067b1",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #0067b1) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #0067b1",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #0067b1",
  },
  prevPolicy: {
    color1: "#0067b1",
    color2: "#0067b1",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#0067b1",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter:
      "invert(97%) sepia(83%) saturate(3866%) hue-rotate(256deg) brightness(95%) contrast(98%)",
    loginBtnColor: "#0067b1 !important",
  },
  Payment: {
    color: "#0067b1",
  },
  City: {
    color: "#fff",
    background: "#0067b1",
    border: "1px solid #0067b1",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "Arial",
    fontFamily: "Arial",
    fontColor: "#0067b1",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "Arial",
  },
  floatButton: {
    floatColor: "#0067b1",
    floatBorder: "1px solid #0067b1",
  },
  boldBorder: {
    border: "5px solid #0067b1",
    boxShadow: "#6a7695 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(0, 103, 177)",
  },
  footer: {
    background: "#e2e1e5",
  },
  primaryColor: "rgb(0, 103, 177) !important"
};

const ACE = {
  proposalCardSummary: {
    background: `linear-gradient(90deg, #32b964 0%, #32b964 100%)`,
  },
  proposalCardActive: {
    background: `linear-gradient(90deg, #23974e 0%,  #23974e 100%)`,
  },
  proposalProceedBtn: {
    hex1: "#23974e",
    hex2: "#23974e",
  },
  genderProposal: {
    background: `radial-gradient(circle, #23974e 17%, #23974e 85%)`,
    boxShadow: "6.994px 5.664px 21px #23974e4a",
  },
  questionsProposal: {
    color: `#23974e`,
    toggleBackgroundColor: `#23974e`,
  },
  sideCardProposal: {
    icon: "text-danger",
    badge: "danger",
    editBadge: "dark",
    linkColor: "",
  },
  Button: {
    default: {
      background: "#3fd49f",
      border_color: "#1ad2a4",
      text_color: "#fff",
    },
    danger: {
      background: "#ff8983",
      border_color: "#ff8683",
      text_color: "#fff",
    },
    warning: {
      background: "#eebb4d",
      border_color: "#eebb4d",
      text_color: "#fff",
    },
    outline: {
      background: "#fff",
      border_color: "#cb68d9",
      text_color: "#b406cc",
    },
    square_outline: {
      background: "#fff",
      border_color: "#CE93D8",
      text_color: "#000000",
    },
    outline_secondary: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    submit_disabled: {
      background: "#efefef",
      border_color: "#606060",
      text_color: "#606060",
    },
    outline_solid: {
      background1: "#0084f4",
      background2: "#00c48c",
      border_color: "#D0D0D0",
      text_color: "#fff",
    },
  },
  Products: {
    color: "#007bff",
    headColor: "rgb(20 116 125)",
    border: " 1px solid #23974e;",
    liBorder: "2px solid #23974e;",
  },
  CheckCircle: {
    backgroundImage: `url(/assets/images/check-blue-circle.svg)`,
  },
  Tab: {
    color: `#23974e`,
    border: "1px solid #23974e;",
  },
  CheckBox: {
    color: `#fff`,
    border: "1px solid #23974e",
  },
  Header: {
    color: `#23974e`,
    border: " 1px solid #23974e",
    borderLogo: " 2.5px solid #23974e",
    hex1: "#23974e",
    hex2: "#23974e",
  },
  FilterConatiner: {
    color: `#0093c7`,
    lightColor: "#23974e",
    lightBorder: " .5px solid #23974e",
    lightBorder1: " .5px solid #23974e",
    clearAllIcon: "#0093c7",
    editIconColor: "#0093c7",
    clearAllTextColor: "#fff",
  },
  QuoteCard: {
    color: `#23974e`,
    color2: " #23974e !important",
    color3: "#23974e !important",
    border: " 1px solid #23974e",
    border2: "1px solid  #23974e !important",
    borderCheckBox: "1px solid d4d5d9",
    boxShadow: "0 8px 25px -5px #1769B35c, 0 10px 10px -5px #1769B35c",
    mouseHoverShadow: "0px 0px 8px 0px #23974e",
  },
  QuotePopups: {
    color: `#23974e`,
    border: "1px solid #23974e",
    hex1: "#23974e",
    hex2: "#23974e",
    color2: "#23974e",
    border2: "1px solid  #23974e",
    border3: "2px solid #23974e",
    lg: "-webkit-linear-gradient(-134deg,#23974e,#d9b7bb)",
    prevpopBorder: "33px solid #23974e",
  },
  //new changes for quotes
  QuoteBorderAndFont: {
    navColor: "#23974e !important",
    checkBoxAndToggleBackground: "#23974e",
    journeyCategoryButtonColor: "#23974e !important",
    fontFamily: "Montserrat",
    fontFamilyBold: "Montserrat",
    linkColor: "#23974e",
  },
  ReviewCard: {
    color: "#007bff;",
    border: " 1px solid #007bff;",
    borderDashed: " 1px dashed #007bff;",
    color2: "#007bff;",
  },
  avatar: {
    border: "solid 2px #23974e",
  },
  reactCalendar: {
    selected: "#23974e",
    background: "#23974e",
  },
  leadPageBtn: {
    background: "#23974e",
    background1: "#23974e",
    background2: "#23974e",
    link: "",
    textColor: "#fff",
    borderRadius: "",
  },
  Registration: {
    proceedBtn: {
      background: `#23974e`,
      color: "white",
    },
    otherBtn: {
      hex1: "#23974e",
      hex2: "#23974e",
    },
  },
  VehicleType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(286deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(309deg) brightness(90%) contrast(119%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(179deg) brightness(90%) contrast(89%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(347deg) brightness(90%) contrast(131%)",
    // filterIconCol: "invert(42%) sepia(93%) saturate(1352%) hue-rotate(25deg) brightness(90%) contrast(131%)",
    filterIconCol:
      "invert(42%) sepia(93%) saturate(1352%) hue-rotate(434deg) brightness(75%) contrast(119%)",
  },
  Stepper: {
    stepperColor: {
      background: "#23974e",
    },
    linkColor: "text-primary",
  },
  Tile: {
    boxShadow: "0px 0px 7px 0px #23974e",
    border: "2px solid #23974e",
    color: "#23974e !important",
  },
  VehicleProceed: {
    hex1: "#054f6c",
    hex2: "#085572",
  },
  journeyType: {
    buttonVariant: "danger",
    outlineVariant: "outline-danger",
    filterIconCol: "none",
  },
  toggleModal: {
    color: "#033500",
    walletImage: `url(${
      process.env.REACT_APP_BASENAME !== "NA"
        ? `/${process.env.REACT_APP_BASENAME}`
        : ""
    }/assets/images/wallet-1.svg)`,
  },
  comparePage: {
    color: "#23974e !important",
    border: "1px solid #23974e !important",
    textColor: "#fff",
  },
  paymentConfirmation: {
    headers: "linear-gradient(81.67deg,#23974e 0%,#ffffcc 100%)",
    Button: { hex2: "#23974e", hex1: "#23974e" },
    table: "danger",
    buttonTextColor: "white",
  },
  CallUs: {
    icon: "#23974e",
  },
  PaymentStatus: {
    hex1: "#fb7550",
    hex2: "#23974e",
  },
  MultiSelect: {
    color: "#23974e",
  },
  proposalHeader: {
    color: "black",
  },
  comparePage2: {
    background: "#23974e",
    borderBottom: "1px solid #23974e",
    iconsColor: "#23974e",
    borderHeader: "5px solid #23974e",
    lg: "-webkit-linear-gradient(-134deg, #ffffff, #23974e) ",
  },
  CardPop: {
    background: "#1769B31c",
    border: "1px solid #23974e",
  },
  NoPlanCard: {
    background: "#1769B31c",
    border: "2px dotted #23974e",
  },
  prevPolicy: {
    color1: "#23974e",
    color2: "#23974e",
    boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
  },
  LandingPage: {
    color: "#23974e",
    color2: "#fff",
    color3: "black",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(318deg) saturate(5)",
    // filter: "grayscale(100%) sepia(100%) hue-rotate(663deg) saturate(23)",
    // filter: "grayscale(100%) sepia(72%) hue-rotate(552deg) saturate(115)",
    filter:
      "invert(97%) sepia(83%) saturate(3866%) hue-rotate(276deg) brightness(54%) contrast(98%)",
    loginBtnColor: "#23974e !important",
  },
  Payment: {
    color: "#23974e",
  },
  City: {
    color: "#fff",
    background: "#23974e",
    border: "1px solid #23974e",
  },
  Home: {
    backgroundColor: "",
  },
  regularFont: {
    headerFontFamily: "Arial",
    fontFamily: "Montserrat",
    fontColor: "#0093c7",
    textColor: "#686868",
    fontWeight: "600",
  },
  mediumFont: {
    fontFamily: "Montserrat",
  },
  floatButton: {
    floatColor: "#0093c7",
    floatBorder: "1px solid #0093c7",
  },
  boldBorder: {
    border: "5px solid #23974e",
    boxShadow: "#23974e59 0px -50px 36px -28px inset",
  },
  headings: {
    fontSize: "21px",
    textColor: "#686868",
  },
  links: {
    color: "rgb(0, 147, 199)",
  },
  primaryColor: "rgb(0, 147, 199) !important"
};

export default process.env.REACT_APP_BROKER === "ABIBL"
  ? { ...ABIBL }
  : process.env.REACT_APP_BROKER === "CP"
  ? { ...CP }
  : process.env.REACT_APP_BROKER === "Gram-cover"
  ? { ...Gramcover }
  : process.env.REACT_APP_BROKER === "PINC"
  ? { ...PINC }
  : process.env.REACT_APP_BROKER === "RB"
  ? { ...RB }
  : process.env.REACT_APP_BROKER === "SPA"
  ? { ...SPA }
  : process.env.REACT_APP_BROKER === "EPOCH"
  ? { ...EPOCH }
  : process.env.REACT_APP_BROKER === "ANALAH"
  ? { ...ANALAH }
  : process.env.REACT_APP_BROKER === "BAJAJ"
  ? { ...BAJAJ }
  : process.env.REACT_APP_BROKER === "UIB"
  ? { ...UIB }
  : process.env.REACT_APP_BROKER === "ACE"
  ? { ...ACE }
  : {};

// const proposalCardSummary = {
// 	background: "linear-gradient(90deg,rgb(203 39 47) 0%,#c7222a 100%)",
// }
// const proposalCardActive = {
// 	background: "linear-gradient(90deg,#c7222a 0%,#ff7472 100%)",
// }
// const proposalProceedBtn = {
// 	hex1: "#C7222A",
// 	hex2: "#C7222A",
// }
// const genderProposal = {
// 	background:
// 		"radial-gradient(circle,rgb(199 34 42) 17%,rgb(179 45 51) 85%)",
// 	boxShadow: "6.994px 5.664px 21px #fdc4b4",
// }
// const questionsProposal = {
// 	color: "#C7222A",
// 	toggleBackgroundColor: "#C7222A",
// }
// const sideCardProposal = {
// 	icon: "text-danger",
// 	badge: "danger",
// }
// const Button = {
// 	default: {
// 		background: "#3fd49f",
// 		border_color: "#1ad2a4",
// 		text_color: "#fff",
// 	},
// 	danger: {
// 		background: "#ff8983",
// 		border_color: "#ff8683",
// 		text_color: "#fff",
// 	},
// 	warning: {
// 		background: "#eebb4d",
// 		border_color: "#eebb4d",
// 		text_color: "#fff",
// 	},
// 	outline: {
// 		background: "#fff",
// 		border_color: "#cb68d9",
// 		text_color: "#b406cc",
// 	},
// 	square_outline: {
// 		background: "#fff",
// 		border_color: "#CE93D8",
// 		text_color: "#000000",
// 	},
// 	outline_secondary: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	submit_disabled: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	outline_solid: {
// 		background1: "#0084f4",
// 		background2: "#00c48c",
// 		border_color: "#D0D0D0",
// 		text_color: "#fff",
// 	},
// }
// const Products = {
// 	color: "#007bff;",
// 	headColor: "rgb(20 116 125)",
// 	border: " 1px solid #C7222A;",
// 	liBorder: "2px solid #C7222A;",
// }
// const CheckCircle = {
// backgroundImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/check-blue-circle.svg)`,
// }
// const Tab = {
// 	color: "#c7222a",
// 	border: " 1px solid #c7222a;",
// }
// const CheckBox = {
// 	color: "#c7222a",
// 	border: " 1px solid #c7222a",
// }
// const Header = {
// 	color: "#c7222a",
// 	border: " 1px solid #c7222a",
// 	borderLogo: " 2.5px solid #c7222a",
// 	hex1: "#c7222a",
// 	hex2: "#c7222a",
// }
// const FilterConatiner = {
// 	color: "#c7222a",
// 	lightColor: "#DA9089",
// 	lightBorder: " .5px solid #DA9089",
// }
// const QuoteCard = {
// 	color: "#c7222a",
// 	color2: " #8B151B !important",
// 	color3: "#c7222a !important",
// 	border: " 1px solid #c7222a",
// 	border2: "1px solid  #8B151B !important",
// 	borderCheckBox: "1px solid d4d5d9",
// 	boxShadow: "0 8px 25px -5px #DA9089, 0 10px 10px -5px #DA9089",
// 	mouseHoverShadow: "0px 0px 8px 0px #DA9089",
// }
// const QuotePopups = {
// 	color: "#c7222a",
// 	border: "1px solid #c7222a",
// 	hex1: "#c7222a",
// 	hex2: "#c7222a",
// 	color2: " #8B151B",
// 	border2: "1px solid  #8B151B",
// 	border3: "2px solid #c7222a",
// 	lg: "-webkit-linear-gradient(-134deg,#c7222a,#d9b7bb)",
// 	prevpopBorder: "33px solid #c7222a",
// }
// const ReviewCard = {
// 	color: "#007bff;",
// 	border: " 1px solid #007bff;",
// 	borderDashed: " 1px dashed #007bff;",
// 	color2: "#007bff;",
// }
// const avatar = {
// 	border: "solid 2px #c7222a",
// }
// const reactCalendar = {
// 	selected: "#C7222A",
// 	background: "#C7222A",
// }
// const leadPageBtn = {
// 	background: "#C7222A",
// }
// const Registration = {
// 	proceedBtn: {
// 		background: "#C7222A",
// 	},
// 	otherBtn: {
// 		hex2: "#C7222A",
// 		hex1: "#fb7550",
// 	},
// }
// const VehicleType = {
// 	buttonVariant: "danger",
// 	outlineVariant: "outline-danger",
// 	filterIconCol:
// 		"invert(42%) sepia(93%) saturate(1352%) hue-rotate(324deg) brightness(90%) contrast(119%)",
// }
// const Stepper = {
// 	stepperColor: {
// 		background: "#C7222A",
// 	},
// }
// const Tile = {
// 	boxShadow: "0px 0px 7px 0px #C7222A",
// 	border: "2px solid #C7222A",
// 	color: "#C7222A !important",
// }
// const VehicleProceed = {
// 	hex1: "#054f6c",
// 	hex2: "#085572",
// }
// const journeyType = {
// 	buttonVariant: "danger",
// 	outlineVariant: "outline-danger",
// 	filterIconCol: "none",
// }
// const toggleModal = {
// 	color: "#033500",
// walletImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/wallet-1.svg)`,
// }
// const comparePage = {
// 	color: "#c7222a !important",
// 	border: "1px solid #c7222a !important",
// 	textColor: "#fff",
// }
// const paymentConfirmation = {
// 	headers: "linear-gradient(81.67deg,#C7222A 0%,#ffffcc 100%)",
// 	Button: {
// 		hex2: "#C7222A",
// 		hex1: "#C7222A",
// 	},
// 	table: "danger",
// 	buttonTextColor: "white",
// }
// const CallUs = {
// 	icon: "#C7222A",
// }
// const PaymentStatus = {
// 	hex1: "#fb7550",
// 	hex2: "#C7222A",
// }
// const MultiSelect = {
// 	color: "#C7222A",
// }
// const proposalHeader = {
// 	color: "black",
// }
// const comparePage2 = {
// 	background: "#c7222a",
// 	borderBottom: "1px solid #c7222a",
// 	iconsColor: "#c7222a",
// 	borderHeader: "5px solid #C7222A",
// 	lg: "-webkit-linear-gradient(-134deg, #ffffff, #c7222a)",
// }
// const CardPop = {
// 	background: "#DA9089",
// 	border: "1px solid #c7222a",
// }
// const NoPlanCard = {
// 	background: "#DA9089",
// 	border: "2px dotted #DA9089",
// }
// const prevPolicy = {
// 	color1: "#c7222a",
// 	color2: "#c7222a",
// 	boxShadow: "0 0 0 0.2rem rgb(225 83 97 / 50%)",
// }
// const LandingPage = {
// 	color: "#c7222a",
// 	color2: "#fff",
// 	color3: "black",
// 	// filter:
// 	// 	"invert(21%) sepia(52%) saturate(3968%) hue-rotate(295deg) brightness(86%) contrast(98%)",
// 	filter:
// 		"invert(21%) sepia(50%) saturate(300%) hue-rotate(295deg) brightness(80%) contrast(140%)",
// 	fontFamily: 'sans-serif',
// 	fontWeight: 'bold'
// }
// const Payment = {
// 	color: '#c7222a',
// 	color2: '#8b151b',
// 	color3: '#fff',
// 	fontFamily: 'sans-serif',
// 	fontWeight: 'bold'
// }

// const City = {
// 	color: '#fff',
// 	background: '#c7222a',
// 	border: '1px solid #c7222a'
// }

// export default process.env.REACT_APP_BROKER === "ABIBL"
// ? {
// 		Button,
// 		proposalCardSummary,
// 		proposalCardActive,
// 		genderProposal,
// 		proposalProceedBtn,
// 		Tab,
// 		CheckBox,
// 		Header,
// 		FilterConatiner,
// 		QuoteCard,
// 		QuotePopups,
// 		ReviewCard,
// 		avatar,
// 		reactCalendar,
// 		Products,
// 		leadPageBtn,
// 		Registration,
// 		VehicleType,
// 		Stepper,
// 		Tile,
// 		VehicleProceed,
// 		CheckCircle,
// 		journeyType,
// 		toggleModal,
// 		comparePage,
// 		questionsProposal,
// 		sideCardProposal,
// 		paymentConfirmation,
// 		CallUs,
// 		PaymentStatus,
// 		MultiSelect,
// 		proposalHeader,
// 		comparePage2,
// 		CardPop,
// 		NoPlanCard,
// 		prevPolicy,
// 		LandingPage,
// 		Payment,
// 		City
//   }
// : {};

//--------------------Fyntune Theme--------------------

// const proposalCardSummary = {
// 	background: `linear-gradient(90deg,rgb(3 142 236) 0%,rgb(67 174 246) 100%)`,
// };

// const proposalCardActive = {
// 	background: `linear-gradient(90deg,rgb(29,155,240) 0%,rgb(29 155 240 / 50%) 100%)`,
// };

// const proposalProceedBtn = {
// 	hex1: "rgb(29, 155, 240)",
// 	hex2: "rgb(29, 155, 240)",
// };

// //gender button (uni)
// const genderProposal = {
// 	background: `radial-gradient(circle, rgb(29, 155, 240) 17%, rgb(29, 155, 240) 85%)`,
// 	boxShadow: "6.994px 5.664px 21px rgb(29, 155, 240)",
// };

// //toggle questions
// const questionsProposal = {
// 	color: `rgb(29, 155, 240)`,
// 	toggleBackgroundColor: `rgb(29, 155, 240)`,
// };

// const sideCardProposal = {
// 	icon: "text-danger",
// 	badge: "danger",
// };

// const Button = {
// 	default: {
// 		background: "#3fd49f",
// 		border_color: "#1ad2a4",
// 		text_color: "#fff",
// 	},
// 	danger: {
// 		background: "rgb(29, 155, 240)",
// 		border_color: "rgb(29, 155, 240)",
// 		text_color: "#fff",
// 	},
// 	warning: {
// 		background: "#eebb4d",
// 		border_color: "#eebb4d",
// 		text_color: "#fff",
// 	},
// 	outline: {
// 		background: "#fff",
// 		border_color: "#cb68d9",
// 		text_color: "#b406cc",
// 	},
// 	square_outline: {
// 		background: "#fff",
// 		border_color: "#CE93D8",
// 		text_color: "#000000",
// 	},
// 	outline_secondary: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	submit_disabled: {
// 		background: "#efefef",
// 		border_color: "#606060",
// 		text_color: "#606060",
// 	},
// 	outline_solid: {
// 		background1: "#0084f4",
// 		background2: "rgb(29, 155, 240)",
// 		border_color: "#D0D0D0",
// 		text_color: "#fff",
// 	},
// };

// const Products = {
// 	color: "#007bff;",
// 	headColor: "rgb(20 116 125)",
// 	border: " 1px solid rgb(29, 155, 240);",
// 	liBorder: "2px solid rgb(29, 155, 240);",
// };

// const CheckCircle = {
// backgroundImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/check-blue-circle.svg)`,
// };

// const Tab = {
// 	color: `rgb(29, 155, 240)`,
// 	border: " 1px solid rgb(29, 155, 240);",
// };

// const CheckBox = {
// 	color: `rgb(29, 155, 240)`,
// 	border: " 1px solid rgb(29, 155, 240)",
// };

// const Header = {
// 	color: `rgb(29, 155, 240)`,
// 	border: " 1px solid rgb(29, 155, 240)",
// 	borderLogo: " 2.5px solid rgb(29, 155, 240)",
// 	hex1: "rgb(29, 155, 240)",
// 	hex2: "rgb(29, 155, 240)",
// };

// const FilterConatiner = {
// 	color: `rgb(29, 155, 240)`,
// 	lightColor: "rgb(29, 155, 240)",
// 	lightBorder: " .5px solid rgb(29, 155, 240)",
// };

// const QuoteCard = {
// 	color: `rgb(29, 155, 240)`,
// 	color2: " rgb(29, 155, 240) !important",
// 	color3: "rgb(29, 155, 240) !important",
// 	border: " 1px solid rgb(29, 155, 240)",
// 	border2: "1px solid  rgb(29, 155, 240) !important",
// 	borderCheckBox: "1px solid d4d5d9",
// 	boxShadow: "0 8px 25px -5px rgb(29 155 240 / 50%), 0 10px 10px -5px rgb(29 155 240 / 20%)",
// 	mouseHoverShadow: "0px 0px 8px 0px rgb(29 155 240 / 20%)",
// };

// const QuotePopups = {
// 	color: `rgb(29, 155, 240)`,
// 	border: "1px solid rgb(29, 155, 240)",
// 	hex1: "rgb(29, 155, 240)",
// 	hex2: "rgb(29, 155, 240)",
// 	color2: " rgb(29, 155, 240)",
// 	border2: "1px solid  rgb(29, 155, 240)",
// 	border3: "2px solid rgb(29, 155, 240)",
// 	lg: "-webkit-linear-gradient(-134deg,rgb(29, 155, 240),#d9b7bb)",
// 	prevpopBorder: "33px solid rgb(29, 155, 240)",
// };

// const ReviewCard = {
// 	color: `#007bff;`,
// 	border: " 1px solid #007bff;",
// 	borderDashed: " 1px dashed #007bff;",
// 	color2: "#007bff;",
// };

// const avatar = {
// 	// border: "solid 2px #006600",
// 	border: "solid 2px rgb(29, 155, 240)",
// };

// const reactCalendar = {
// 	selected: "rgb(29, 155, 240)",
// 	background: "rgb(29, 155, 240)",
// };

// //lead-page
// const leadPageBtn = {
// 	background: "rgb(29, 155, 240)",
// };

// //registration-page
// const Registration = {
// 	proceedBtn: {
// 		background: `rgb(29, 155, 240)`,
// 	},
// 	otherBtn: {
// 		hex1: "rgb(29, 155, 240)",
// 		hex2: "rgb(29, 155, 240)",
// 	},
// };

// //vehicle-type
// const VehicleType = {
// 	buttonVariant: "info",
// 	outlineVariant: "outline-info",
// 	filterIconCol: "hue-rotate(-36deg) saturate(2) brightness(0.8) contrast(1) sepia(.2)",
// };

// //vehicle-details
// /*stepper-color*/
// const Stepper = {
// 	stepperColor: {
// 		background: "rgb(29, 155, 240)",
// 	},
// };

// //Tile-Component
// const Tile = {
// 	boxShadow: "0px 0px 7px 0px rgb(29, 155, 240)",
// 	border: "2px solid rgb(29, 155, 240)",
// 	color: "rgb(29, 155, 240) !important",
// };

// const VehicleProceed = {
// 	hex1: "#054f6c",
// 	hex2: "#085572",
// };

// const journeyType = {
// 	buttonVariant: "danger",
// 	outlineVariant: "outline-danger",
// 	filterIconCol: "none",
// };

// const toggleModal = {
// 	color: "#033500",
// walletImage: `url(${
//   process.env.REACT_APP_BASENAME !== "NA"
//     ? `/${process.env.REACT_APP_BASENAME}`
//     : ""
// }/assets/images/wallet-1.svg)`,
// };

// const comparePage = {
// 	color: "rgb(29, 155, 240) !important",
// 	border: "1px solid rgb(29, 155, 240) !important",
// 	textColor: "#fff",
// };

// //payment-confirmation
// const paymentConfirmation = {
// 	headers: "linear-gradient(81.67deg,rgb(29, 155, 240) 0%,#ffffcc 100%)",
// 	Button: { hex2: "rgb(29, 155, 240)", hex1: "rgb(29, 155, 240)" },
// 	table: "danger",
// 	buttonTextColor: "white",
// };

// const CallUs = {
// 	icon: "rgb(29, 155, 240)",
// };

// const PaymentStatus = {
// 	hex1: "#fb7550",
// 	hex2: "rgb(29, 155, 240)",
// };

// const MultiSelect = {
// 	color: 'rgb(29, 155, 240)'
// }

// const proposalHeader = {
// 	color: 'black'
// }

// const comparePage2 = {
// 	background: 'rgb(29, 155, 240)',
// 	borderBottom: '1px solid rgb(29, 155, 240)',
// 	iconsColor: 'rgb(29, 155, 240)'
// }

// const CardPop = {
// 	background: 'rgb(29 155 240 / 20%)',
// 	border: '1px solid rgb(29, 155, 240)'
// }

// const NoPlanCard = {
// 	background: 'rgb(29 155 240 / 20%)',
// 	border: '2px dotted rgb(29, 155, 240)'
// }

// const prevPop = {
// 	background: 'rgb(29, 155, 240)',
// 	color: 'rgb(29, 155, 240)',
// 	boxShadow: '0 0 0 0.2rem rgb(82 223 203 / 50%)'
// }

// export default process.env.REACT_APP_BROKER === "FYNTUNE"
// 	? {
// 			Button,
// 			proposalCardSummary,
// 			proposalCardActive,
// 			genderProposal,
// 			proposalProceedBtn,
// 			Tab,
// 			CheckBox,
// 			Header,
// 			FilterConatiner,
// 			QuoteCard,
// 			QuotePopups,
// 			ReviewCard,
// 			avatar,
// 			reactCalendar,
// 			Products,
// 			leadPageBtn,
// 			Registration,
// 			VehicleType,
// 			Stepper,
// 			Tile,
// 			VehicleProceed,
// 			CheckCircle,
// 			journeyType,
// 			toggleModal,
// 			comparePage,
// 			questionsProposal,
// 			sideCardProposal,
// 			paymentConfirmation,
// 			CallUs,
// 			PaymentStatus,
// 			MultiSelect,
// 			proposalHeader,
// 			comparePage2,
// 			CardPop,
// 			NoPlanCard,
// 			prevPop
// 	  }
// 	: {};
