import React from "react";
import ReactTooltip from "react-tooltip";
import "./CustomTooltip.scss";
import { useMediaPredicate } from "react-media-hook";
function CustomTooltip({ ...props }) {
	const moreThan993 = useMediaPredicate("(min-width: 993px)");
	const {
		id,
		place,
		arrowPosition,
		customClassName,
		Position,
		rider,
		backColor,
		color,
		allowClick,
	} = props;
	let offset = undefined;
	let mobilePlace = undefined;
	if (window.matchMedia("(max-width: 767px)").matches) {
		// offset = { top: 90, right: 41 };
		mobilePlace = "top";
	}

	const getPositionClass = () => {
		if (place === "right") {
			return "tooltipRight";
		} else if (place === "left") {
			return "tooltipLeft";
		} else if (place === "top") {
			return "tooltipTop";
		}
	};

	const getArrowClass = () => {
		if (arrowPosition === "bottom") {
			offset = { top: 60, right: 3 };
			return "from1__arrowBottom";
		}
		if (arrowPosition === "top") {
			offset = { top: -60, right: 3 };
			return "from1__arrowTop";
		}
	};

	return (
		<>
			{props.children}

			<ReactTooltip
				id={(id && moreThan993) || allowClick ? id : undefined}
				className={`customTooltip ${customClassName} ${getArrowClass()} ${getPositionClass()} modifyTipStyle`}
				offset={
					mobilePlace === "top" ? undefined : Position ? Position : offset
				}
				type="light"
				effect="float"
				place={mobilePlace === "top" ? undefined : place}
				backgroundColor={backColor ? backColor : "#fff"}
				borderColor="#000"
			/>
		</>
	);
}

export default CustomTooltip;

// let tooltipOffset;
// let tooltipPlace;
// if (window.matchMedia("(max-width: 767px)").matches) {
//    tooltipOffset = { top: 90,right: 41 };
//    tooltipPlace ="top";
// } else {
//   tooltipPlace ="right";
//   tooltipOffset = { top: 60, right: 3 };
// }
