import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";
import { Row, Col } from "react-bootstrap";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { reloadPage } from "utils";

const Footer = () => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  return (
    <>
      <FooterTag
        style={{
          backgroundColor: "#E5E5E5",
          textAlign: lessthan767 ? "left" : "center",
        }}
      >
        <Row
          style={{
            padding: lessthan767 ? "30px 0px" : "38px 81px 0 38px",
          }}
        >
          <Col xl={3} lg={3} md={3} sm={6}>
            <div>
              <Logo
                style={{ width: lessthan767 ? "50%" : "100%" }}
                src={`${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ""
                }/assets/images/logo/bajaj.png`}
                alt="logo"
              />
              <Content>
                This site is owned and operated by Bajaj Capital Insurance
                Broking Limited [“BCIBL” (CIN:U67200DL2002PLC117625)], an IRDA
                licensed Composite Broker bearing License No. 241, License Code
                CB 042/02, license dated 09-01-2022 valid till 08-01-2025
                (originally licensed by IRDA on 09/01/2004 and renewed
                thereafter).
              </Content>
            </div>
          </Col>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={6}
            sx={6}
            style={{ marginTop: lessthan767 ? "22px" : "" }}
          >
            <FooterTitle>Our Services</FooterTitle>
            <div style={{ marginTop: "22px" }}>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "#"
                  )
                }
              >
                Motor Insurance
              </p>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "#"
                  )
                }
              >
                Health Insurance
              </p>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "#"
                  )
                }
              >
                Travel Insurance
              </p>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "#"
                  )
                }
              >
                Term Insurance
              </p>
            </div>
          </Col>
          <Col xl={3} lg={3} md={3} sm={6} sx={6}>
            <FooterTitle>Quick Links</FooterTitle>
            <div style={{ marginTop: "22px" }}>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "https://b2b.bajajcapitalinsurance.com/BajajInsurance/About"
                  )
                }
              >
                About us
              </p>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "https://b2b.bajajcapitalinsurance.com/BajajInsurance/Contact"
                  )
                }
              >
                Contact us
              </p>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "https://b2b.bajajcapitalinsurance.com/BajajInsurance/MotorInsurance#"
                  )
                }
              >
                Our Blog
              </p>
              <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "https://b2b.bajajcapitalinsurance.com/RegisterPos/Index"
                  )
                }
              >
                Register
              </p>
            </div>
          </Col>
          <Col xl={3} lg={3} md={3} sm={6} sx={6}>
            <FooterTitle>Contact Us</FooterTitle>
            <div style={{ marginTop: "22px" }}>
              <p style={{ fontWeight: "bold" }}>1800-212-123123</p>
              {/* <p>Mon — Sat(10AM — 6PM)</p> */}
              <p>
                Bajaj Capital Insurance Broking Limited Bajaj House, 97, Nehru
                Place, New Delhi -110019. info@bajajcapitalinsurance.com
              </p>
            </div>
            <MediaContainer>
              <FacebookIcon
                className="cursorChange"
                onClick={() =>
                  reloadPage("https://www.facebook.com/bajajcapitalinsurance/")
                }
                style={{ fontSize: "35px", color: "#4867aa" }}
              />
              <TwitterIcon
                className="cursorChange"
                onClick={() => reloadPage("https://twitter.com/mybajajcapital")}
                style={{ fontSize: "35px", color: "#1da1f2" }}
              />
              <YouTubeIcon
                className="cursorChange"
                onClick={() =>
                  reloadPage("https://www.youtube.com/user/bajajcapital")
                }
                style={{ fontSize: "35px", color: "#ff0000" }}
              />
              <LinkedInIcon
                className="cursorChange"
                onClick={() =>
                  reloadPage(
                    "https://in.linkedin.com/company/bajaj-capital-ltd"
                  )
                }
                style={{ fontSize: "35px", color: "#0077b5" }}
              />
            </MediaContainer>
          </Col>
        </Row>
        <Line />
        <BottomFooter
          style={{
            padding: lessthan767 ? "5px" : "5px 81px",
            flexDirection: lessthan767 ? "column" : "row",
          }}
        >
          <p>Copyright © 2020 Bajaj Capital Limited</p>
          <p style={{ fontSize: lessthan767 && "12px" }}>
            <text
              onClick={() =>
                reloadPage(
                  "https://b2b.bajajcapitalinsurance.com/BajajInsurance/Disclaimer"
                )
              }
              className="underline-on-hover"
            >
              Legal Disclaimer
            </text>{" "}
            |{" "}
            <text
              onClick={() =>
                reloadPage(
                  "https://b2b.bajajcapitalinsurance.com/BajajInsurance/PrivacyPolicy"
                )
              }
              className="underline-on-hover"
            >
              Privacy Policy
            </text>{" "}
            |{" "}
            <text
              onClick={() =>
                reloadPage(
                  "https://b2b.bajajcapitalinsurance.com/BajajInsurance/TermsAndCondition"
                )
              }
              className="underline-on-hover"
            >
              Terms and Conditions
            </text>
          </p>
        </BottomFooter>
        <GlobalStyle lessthan767={lessthan767} />
      </FooterTag>
    </>
  );
};

export default Footer;

const GlobalStyle = createGlobalStyle`
${({ lessthan767 }) =>
  lessthan767
    ? `
body::after {
    content: '';
    display: block;
    height: 950px; /* Set same as footer's height */
  }`
    : `
body::after {
    content: '';
    display: block;
    height: 420px; /* Set same as footer's height */
  }
`}`;

const Logo = styled.img``;
const Content = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 26px;
  text-align: justify;
  text-justify: inter-word;
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterTag = styled.footer`
  padding: unset !important;
  .underline-on-hover:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .cursorChange {
    cursor: pointer;
  }
  //   text-align: left !important;
`;

const FooterTitle = styled.div`
  font-size: 24px;
  font-wight: 500;
`;

const Line = styled.hr`
  height: 3px;
`;

const BottomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
