import Popup from "components/Popup/Popup";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";
import { currencyFormater } from "utils";
import _ from "lodash";
import { compareQuotes, setShowPop } from "modules/quotesPage/quote.slice";
import { Modal } from "react-bootstrap";

const ProductPopup = ({ validQuote }) => {
  // console.log("Top Show Pop", showPop);

  const lessThan768 = useMediaPredicate("(max-width: 768px)");

  const { prevInsList, tempData } = useSelector((state) => state.quoteFilter);

  const dispatch = useDispatch();

  const {
    addOnsAndOthers,
    finalPremiumlist,
    saveQuoteResponse,
    saveQuoteLoader,
    compareQuotesList,
    quoteComprehesive,
    quotesList,
    updateQuoteLoader,
    showPop
  } = useSelector((state) => state.quotes);

  // const [policyIds, setPolicyIds] = useState(
  //   _.compact(compareQuotesList.map((x) => x.policyId))
  // );

  // useEffect(() => {
  //   if (compareQuotesList) {
  //     setPolicyIds(_.compact(compareQuotesList.map((x) => x.policyId)));
  //   }
  // }, [compareQuotesList]);

  // const [ids, setIds] = useState(policyIds);

    // const [validCompareQuotes, setValidCompareQuotes] = useState(compareQuotesList?.filter(x => x.idv))
    // console.log(validCompareQuotes, "validCompareQuotes")

    // console.log("validQuote", validQuote)

  const compareFn = (singleQuote) => {
    if (validQuote?.length < 3) {
    console.log("singleQuote", singleQuote)
    let validCompareQuotes = compareQuotesList?.filter(x => x.idv)
    dispatch(compareQuotes([...validCompareQuotes, singleQuote]))
    }
    // dispatch(compareQuotes([...validCompareQuotes, singleQuote]))
  }

  const removeFn = (singleQuote) => {
    if (validQuote?.length > 1) {
      let allQuotes = compareQuotesList?.filter(x => x.policyId !== singleQuote.policyId)
      dispatch(compareQuotes(allQuotes))
    }
  }

  // useEffect(() => {
  //   console.log(validCompareQuotes, "validCompareQuotes")
  //   dispatch(compareQuotes(validCompareQuotes))
  // }, [validCompareQuotes])

  // // Adding Quotes to compare
  // const compareFn = (singleQuote) => {
  //   console.log("Single Quote", singleQuote);
  //   if (ids?.includes(singleQuote.policyId)) {
  //     if (ids?.length > 1) {
  //       let x = _.compact(compareQuotesList.map((x) => x.policyId));
  //       console.log("X", x);
  //       const allIds = _.without(x, singleQuote.policyId);
  //       console.log("All the Ids", allIds);
  //       setIds(allIds);
  //     }
  //   } else {
  //     if (
  //       (ids?.length < 3 && !lessThan768) ||
  //       (ids?.length < 2 && lessThan768)
  //     ) {
  //       let allIds = [...ids, singleQuote.policyId];
  //       console.log("All Ids", allIds);
  //       setIds(allIds);
  //     }
  //   }
  // };

  // // Removing Sigle Quote from comparision
  // const compareFn2 = (singleQuote) => {
  //   let x = _.compact(compareQuotesList.map((x) => x.policyId));
  //   let allIds = x.filter((item) => item !== singleQuote.policyId);
  //   console.log("Remove Ids", allIds);

  //   setIds(allIds);
  // };

  // Run when quotes are added or removed
  // useEffect(() => {
  //   if (!_.isEmpty(ids)) {
  //     let allQuotesData = !_.isEmpty(tempData?.quoteComprehesiveGrouped)
  //       ? _.compact(
  //           tempData?.quoteComprehesiveGrouped?.map((x) =>
  //             ids.includes(x?.policyId) ? x : null
  //           )
  //         )
  //       : [];

  //     if (!_.isEmpty(allQuotesData)) dispatch(compareQuotes(allQuotesData));
  //   }
  // }, [ids]);

  // 		if (!_.isEmpty(allQuotesData)) dispatch(compareQuotes(allQuotesData));
  // 	}
  // }, [ids]);

  // useEffect(() => {
  //   if (ids?.length === 3) {
  //     closePop();
  //   }
  // }, [ids]);

  // let selectedIds = _.compact(compareQuotesList.map((x) => x.policyId))

  const content = (
    <TopPop>
      <div
        style={{ overflow: lessThan768 ? "auto" : "hidden", overflowX: "clip" }}
      >
        <h4
          className="mt-4 add_plans"
          style={{
            paddingLeft: lessThan768 ? "" : "40px",
            paddingBottom: "20px",
            paddingTop: lessThan768 ? "20px" : "",
            fontSize: lessThan768 ? "20px" : "",
            textAlign: lessThan768 ? "center" : "",
            fontSize: lessThan768 ? "17px" : "",
          }}
        >
          Add upto {lessThan768 ? "2" : "3"} plans to compare
        </h4>
        <div
          className="row mt-4"
          style={{
            borderBottom: "1px dotted black",
            paddingBottom: "20px",
            width: "100%",
            padding: "0px 20px 20px 20px",
          }}
        >
          {(lessThan768
            ? compareQuotesList.slice(0, 2)
            : compareQuotesList
          )?.map((item) =>
            item.idv ? (
              <div className="col-6 col-md-4">
                <CardDiv
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    height: "100%",
                    padding: "10px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 20px",
                    boxShadow: "rgb(0 131 10 / 18%) 0px 5px 20px 0px",
                  }}
                >
                  {validQuote?.length > 1 && (
                    <CancelIcon
                      onClick={() => removeFn(item)}
                      // onClick={() => {
                      //   compareFn2(item);
                      // }}
                      style={{
                        position: "absolute",
                        top: lessThan768 ? "-10px" : "-12px",
                        right: lessThan768 ? "-15px" : "-17px",
                        fontSize: lessThan768 ? "18px" : "24px",
                        background: "#fff",
                        borderRadius: "50%",
                        cursor: "pointer",
                        color: "black",
                      }}
                    />
                  )}
                  <img
                    src={item?.companyLogo}
                    alt="myImage"
                    style={{
                      width: lessThan768 ? "90px" : "100%",
                      height: lessThan768 ? "" : "80px",
                      padding: lessThan768 ? "" : "0px 40px",
                      objectFit: "cover"
                    }}
                  />
                  {/* <p
										style={{
											fontSize: "13px",
											margin: "10px",
											textAlign: "center",
										}}
									>
										{item?.companyName}
									</p> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      fontSize: "13.5px",
                      marginTop: "10px",
                      flexDirection: lessThan768 ? "column" : "",
                      alignItems: lessThan768 ? "" : "center",
                      paddingLeft: lessThan768 ? "15px" : "",
                    }}
                  >
                    <p style={{ margin: lessThan768 ? "0px" : "" }}>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Base premium:
                      </div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(item?.finalPayableAmount)}
                      </strong>
                    </p>

                    <p style={{ margin: lessThan768 ? "0px" : "" }}>
                      <div style={{ textAlign: "left" }}>IDV: </div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(item?.idv)}
                      </strong>
                    </p>
                  </div>
                </CardDiv>
              </div>
            ) : (
              <div className={lessThan768 ? "col-6" : "col-4"}>
                <NoPlansDiv
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 20px",
                  }}
                >
                  <i
                    className="fa fa-plus"
                    style={{
                      marginBottom: "5px",
                      boxShadow: "1px 1px 5px grey",
                      fontSize: "25px",
                      background: "#fff",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                    }}
                  ></i>
                  <p className="text-center">No Plans Added</p>
                </NoPlansDiv>
              </div>
            )
          )}
        </div>
        {/* <p>All available quotes</p> */}
        <div
          className="row"
          //	className="newProductList"
          style={{
            padding: "10px 32px",
            maxHeight: "214px",
            overflow: "scroll",
            overflowX: "clip",
            maxWidth: "915px",
          }}
        >
          {tempData.quoteComprehesiveGrouped?.map((singleQuote, index) => (
            <div className="col-md-4" style={{ width: "100%", height: "100%" }}>
              <div
                className="temp_data_quotes"
                style={{
                  boxShadow: "1px 1px 5px grey",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (_.compact(compareQuotesList.map((x) => x.policyId))?.includes(singleQuote?.policyId)) {
                    removeFn(singleQuote)
                  } else {
                    compareFn(singleQuote)}
                }
              }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={singleQuote.companyLogo}
                    width="150"
                    height="75"
                    style={{ margin: "auto", objectFit: "cover" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      fontSize: "13.5px",
                      marginTop: "10px",
                      // flexDirection: lessThan768 ? "column" : "",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Base premium:
                      </div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.finalPayableAmount)}
                      </strong>
                    </p>

                    <p style={{ marginBottom: "0px" }}>
                      <div style={{ textAlign: "left" }}>IDV:</div>
                      <br />
                      <strong
                        style={{
                          fontSize: "14px",
                          position: "relative",
                          bottom: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.idv)}
                      </strong>
                    </p>
                  </div>
                  <StyledDiv1>
                    <span
                      className="group-check float-right"
                      style={{ width: "5%" }}
                      // onClick={document.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // ? document
                      // 		.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // 		.click()
                      // : {}}
                    >
                      {" "}
                      {_.compact(
                        compareQuotesList.map((x) => x.policyId)
                      )?.includes(singleQuote?.policyId) ? (
                        <i
                          style={{
                            background: "green",
                            color: "#fff",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                          className="fa fa-check"
                        ></i>
                      ) : (
                        <i
                          style={{
                            background: "#fff",
                            color: "#fff",
                            marginTop: "15px",
                            border: "0.5px solid #0000007a",
                            fontSize: "13.5px",
                          }}
                          className="fa fa-check"
                        ></i>
                      )}
                      {/* <input
							    // onClick={() => console.log(singleQuote?.policyId)}
								type="checkbox"
								className="round-check"
								id={`compareCheck${singleQuote?.policyId}`}
								name={`compareCheck[${index}]`}
								ref={register}
								// value={singleQuote?.policyId}
								defaultValue={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
								value={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}

								// disabled={
								// length >= (lessThan768 ? 2 : 3) &&
								// !watch(`checkmark[${index}]`)
								// 	? true
								// 	: false
								// 		}
									/> */}
                      {/* <label
										className="round-label"
										htmlFor={`compareCheck${singleQuote?.policyId}`}
									></label> */}
                    </span>
                  </StyledDiv1>
                  {/* <i className="fa fa-plus" onClick={() => addItems(singleQuote)}>Add</i> */}
                </div>
                {/* <p style={{ fontSize: '13px' }}> {singleQuote.companyName} </p> */}
              </div>
            </div>
          ))}
        </div>
        {/* {ids.length === 3 && closePop()} */}
        <div style={{ margin: "20px 0px", textAlign: "center" }}>
          <CompareButton
            // onClick={closePop}
            onClick={() => dispatch(setShowPop(false))}
            style={{
              border: "none",
              padding: "15px 50px",
              letterSpacing: "0.5px",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Compare
          </CompareButton>
        </div>
      </div>
    </TopPop>
  );

  // const YOffSet = window.pageYOffset;
  // const [visible, setVisible] = useState(false);

  // useEffect(() => {
  // 	if (YOffSet > 45) {
  // 		setVisible(true);
  // 	} else {
  // 		setVisible(false);
  // 	}
  // }, [YOffSet]);

  const innerHeight = window.innerHeight;

  const contentModal = (
    <>
      <TopPop2 innerHeight={innerHeight}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "sticky",
              top: "0",
              background: "#fff",
              zIndex: "999",
              paddingBottom: "20px",
              boxShadow: "rgb(0 0 0 / 24%) 0px 8px 6px -6px",
            }}
          >
            <h4
              className="add_plans"
              style={{
                // paddingLeft: lessThan768 ? "" : "40px",
                paddingBottom: "20px",
                // paddingTop: lessThan768 ? "20px" : "",
                fontSize: "15px",
                textAlign: "left",
                marginLeft: "15px",
                paddingTop: "15px",
              }}
            >
              Add upto {lessThan768 ? "2" : "3"} plans to compare
            </h4>
            <div
              className="row mt-4"
              style={{
                // borderBottom: "1px dotted black",
                // paddingBottom: "20px",
                width: "100%",
                // padding: "0px 20px 20px 20px",
                paddingLeft: "15px",
              }}
            >
              {(lessThan768
                ? compareQuotesList.slice(0, 2)
                : compareQuotesList
              )?.map((item) =>
                item.idv ? (
                  <div className="col-6">
                    <CardDiv
                      style={{
                        position: "relative",
                        borderRadius: "5px",
                        width: "100%",
                        // height: "100%",
                        padding: "10px 4px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // margin: "0px 20px",
                        boxShadow: "rgb(0 131 10 / 18%) 0px 5px 20px 0px",
                      }}
                    >
                      {validQuote?.length > 1 && (
                        <CancelIcon
                          onClick={() => removeFn(item)}
                          // onClick={() => {
                          //   compareFn2(item);
                          // }}
                          style={{
                            position: "absolute",
                            top: lessThan768 ? "-10px" : "-12px",
                            right: lessThan768 ? "-15px" : "-17px",
                            background: "#fff",
                            borderRadius: "50%",
                            cursor: "pointer",
                            color: "black",
                          }}
                        />
                      )}
                      <div style={{ height: "90px" }}>
                        <img
                          src={item?.companyLogo}
                          alt="myImage"
                          style={{
                            width: "100%",
                            padding: "0 5px",
                            objectFit: "cover"
                          }}
                        />
                      </div>
                      {/* <p
										style={{
											fontSize: "13px",
											margin: "10px",
											textAlign: "center",
										}}
									>
										{item?.companyName}
									</p> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                          fontSize: "11px",
                          marginTop: "10px",
                          flexDirection: lessThan768 ? "column" : "",
                          alignItems: lessThan768 ? "" : "center",
                          padding: "0 5px",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Base premium:
                          </div>
                          <br />
                          <strong
                            style={
                              {
                                // fontSize: "14px",
                                // position: "relative",
                                // bottom: "15px",
                              }
                            }
                          >
                            <span
                              style={
                                {
                                  // fontSize: "14px",
                                }
                              }
                            >
                              ₹
                            </span>{" "}
                            {currencyFormater(item?.finalPayableAmount)}
                          </strong>
                        </p>

                        <p
                          style={{
                            margin: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ textAlign: "left" }}>IDV: </div>
                          <br />
                          <strong
                            style={
                              {
                                // fontSize: "14px",
                                // position: "relative",
                                // bottom: "15px",
                              }
                            }
                          >
                            <span
                              style={
                                {
                                  // fontSize: "14px",
                                }
                              }
                            >
                              ₹
                            </span>{" "}
                            {currencyFormater(item?.idv)}
                          </strong>
                        </p>
                      </div>
                    </CardDiv>
                  </div>
                ) : (
                  <div className={lessThan768 ? "col-6" : "col-4"}>
                    <NoPlansDiv
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0px 20px",
                      }}
                    >
                      <i
                        className="fa fa-plus"
                        style={{
                          marginBottom: "5px",
                          // boxShadow: "1px 1px 5px grey",
                          fontSize: "16px",
                          background: "#80808078",
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                        }}
                      ></i>
                      <p className="text-center">No Plans Added</p>
                    </NoPlansDiv>
                  </div>
                )
              )}
            </div>
          </div>
          {/* <div style={{ width: '100%', marginTop: '10px', height: '20px', borderRadius: '10%', boxShadow: '0 8px 6px -6px #0000003d' }}></div> */}
          {/* <p>All available quotes</p> */}
          <div
            className="row"
            //	className="newProductList"
            style={{
              // padding: "10px 32px",
              // maxHeight: "214px",
              // overflow: "scroll",
              // overflowX: "clip",
              // maxWidth: "915px",
              padding: "10px 30px",
              marginTop: "20px",
              position: "relative",
              height: "auto",
              overflow: "auto",
            }}
          >
            {tempData.quoteComprehesiveGrouped?.map((singleQuote, index) => (
              <div
                className="col-6"
                style={{ margin: "3px 0px", padding: "5px 15px" }}
              >
                <div
                  // onClick={() => compareFn(singleQuote)}
                  style={{
                    position: "relative",
                    borderRadius: "5px",
                    width: "100%",
                    // height: "100%",
                    padding: "10px 4px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // margin: "0px 20px",
                    // boxShadow: "rgb(0 131 10 / 18%) 0px 5px 20px 0px",
                    boxShadow: "1px 1px 5px grey",
                    background: "#fff !important",
                    border: "none",
                  }}
                >
                  {/* {validQuote?.length > 1 && (
							<CancelIcon
								onClick={() => {
									compareFn2(singleQuote);
								}}
								style={{
									position: "absolute",
									top: lessThan768 ? "-10px" : "-12px",
									right: lessThan768 ? "-15px" : "-17px",
									background: "#fff",
									borderRadius: "50%",
									cursor: "pointer",
									color: "black",
								}}
							/>
						)} */}
                  <div style={{ height: "90px" }}>
                    <img
                      src={singleQuote?.companyLogo}
                      alt="myImage"
                      style={{
                        width: "100%",
                        padding: "0 5px",
                        objectFit: "cover"
                      }}
                    />
                  </div>
                  {/* <p
							style={{
								fontSize: "13px",
								margin: "10px",
								textAlign: "center",
							}}
						>
							{item?.companyName}
						</p> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      fontSize: "10.5px",
                      marginTop: "10px",
                      flexDirection: lessThan768 ? "column" : "",
                      alignItems: lessThan768 ? "" : "center",
                      padding: "0 5px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Base premium:
                      </div>
                      <br />
                      <strong
                        style={
                          {
                            // fontSize: "14px",
                            // position: "relative",
                            // bottom: "15px",
                          }
                        }
                      >
                        <span
                          style={
                            {
                              // fontSize: "14px",
                            }
                          }
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.finalPayableAmount)}
                      </strong>
                    </p>

                    <p
                      style={{
                        margin: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>IDV: </div>
                      <br />
                      <strong
                        style={
                          {
                            // fontSize: "14px",
                            // position: "relative",
                            // bottom: "15px",
                          }
                        }
                      >
                        <span
                          style={
                            {
                              // fontSize: "14px",
                            }
                          }
                        >
                          ₹
                        </span>{" "}
                        {currencyFormater(singleQuote?.idv)}
                      </strong>
                    </p>
                  </div>
                  <StyledDiv2>
                    <span
                      className="group-check float-right"
                      style={{ width: "5%" }}
                      // onClick={document.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // ? document
                      // 		.getElementById(`compareCheck${singleQuote?.policyId}`)
                      // 		.click()
                      // : {}}
                    >
                      {" "}
                      {_.compact(
                        compareQuotesList.map((x) => x.policyId)
                      )?.includes(singleQuote?.policyId) ? (
                        <i
                          style={{
                            color: "#fff",
                            // marginTop: "15px",
                            fontSize: "14px",
                            borderRadius: "50%",
                            padding: "3px",
                          }}
                          className="fa fa-check productCheck"
                        ></i>
                      ) : (
                        // <i
                        // 	style={{
                        // 		background: "#fff",
                        // 		color: "#fff",
                        // 		marginTop: "15px",
                        // 		border: "0.5px solid #0000007a",
                        // 		fontSize: "13.5px",
                        // 	}}
                        // 	className="fa fa-check"
                        // ></i>
                        <noscript></noscript>
                      )}
                      {/* <input
							    // onClick={() => console.log(singleQuote?.policyId)}
								type="checkbox"
								className="round-check"
								id={`compareCheck${singleQuote?.policyId}`}
								name={`compareCheck[${index}]`}
								ref={register}
								// value={singleQuote?.policyId}
								defaultValue={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
								value={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}

								// disabled={
								// length >= (lessThan768 ? 2 : 3) &&
								// !watch(`checkmark[${index}]`)
								// 	? true
								// 	: false
								// 		}
									/> */}
                      {/* <label
										className="round-label"
										htmlFor={`compareCheck${singleQuote?.policyId}`}
									></label> */}
                    </span>
                  </StyledDiv2>
                </div>
              </div>
              // <div className="col-6">
              // 	<div
              // 		className="temp_data_quotes"
              // 		style={{
              // 			boxShadow: "1px 1px 5px grey",
              // 			padding: "10px",
              // 			margin: "10px",
              // 			borderRadius: "6px",
              // 			cursor: "pointer",
              // 		}}
              // 		onClick={() => compareFn(singleQuote)}
              // 	>
              // 		<div
              // 			style={{
              // 				display: "flex",
              // 				justifyContent: "space-between",
              // 				padding: "5px",
              // 				flexDirection: "column",
              // 			}}
              // 		>
              // 			<img
              // 				src={singleQuote.companyLogo}
              // 				width="150"
              // 				height="75"
              // 				style={{ margin: "auto" }}
              // 			/>
              // 			<div
              // 				style={{
              // 					display: "flex",
              // 					justifyContent: "space-around",
              // 					width: "100%",
              // 					fontSize: "13.5px",
              // 					marginTop: "10px",
              // 					// flexDirection: lessThan768 ? "column" : "",
              // 					alignItems: "center",
              // 				}}
              // 			>
              // 				<p style={{ marginBottom: "0px" }}>
              // 					<div
              // 						style={{
              // 							textAlign: "left",
              // 						}}
              // 					>
              // 						Base premium:
              // 					</div>
              // 					<br />
              // 					<strong
              // 						style={{
              // 							fontSize: "14px",
              // 							position: "relative",
              // 							bottom: "15px",
              // 						}}
              // 					>
              // 						<span
              // 							style={{
              // 								fontSize: "14px",
              // 							}}
              // 						>
              // 							₹
              // 						</span>{" "}
              // 						{currencyFormater(singleQuote?.finalPayableAmount)}
              // 					</strong>
              // 				</p>

              // 				<p style={{ marginBottom: "0px" }}>
              // 					<div style={{ textAlign: "left" }}>IDV:</div>
              // 					<br />
              // 					<strong
              // 						style={{
              // 							fontSize: "14px",
              // 							position: "relative",
              // 							bottom: "15px",
              // 						}}
              // 					>
              // 						<span
              // 							style={{
              // 								fontSize: "14px",
              // 							}}
              // 						>
              // 							₹
              // 						</span>{" "}
              // 						{currencyFormater(singleQuote?.idv)}
              // 					</strong>
              // 				</p>
              // 			</div>
              // 			<StyledDiv1>
              // 				<span
              // 					className="group-check float-right"
              // 					style={{ width: "5%" }}
              // 					// onClick={document.getElementById(`compareCheck${singleQuote?.policyId}`)
              // 					// ? document
              // 					// 		.getElementById(`compareCheck${singleQuote?.policyId}`)
              // 					// 		.click()
              // 					// : {}}
              // 				>
              // 					{" "}
              // 					{_.compact(
              // 						compareQuotesList.map((x) => x.policyId)
              // 					)?.includes(singleQuote?.policyId) ? (
              // 						<i
              // 							style={{
              // 								background: "green",
              // 								color: "#fff",
              // 								marginTop: "15px",
              // 								fontSize: "16px",
              // 							}}
              // 							className="fa fa-check"
              // 						></i>
              // 					) : (
              // 						<i
              // 							style={{
              // 								background: "#fff",
              // 								color: "#fff",
              // 								marginTop: "15px",
              // 								border: "0.5px solid #0000007a",
              // 								fontSize: "13.5px",
              // 							}}
              // 							className="fa fa-check"
              // 						></i>
              // 					)}
              // 					{/* <input
              // 		    // onClick={() => console.log(singleQuote?.policyId)}
              // 			type="checkbox"
              // 			className="round-check"
              // 			id={`compareCheck${singleQuote?.policyId}`}
              // 			name={`compareCheck[${index}]`}
              // 			ref={register}
              // 			// value={singleQuote?.policyId}
              // 			defaultValue={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}
              // 			value={policyIds?.includes(Number(singleQuote?.policyId)) || singleQuote?.policyId}

              // 			// disabled={
              // 			// length >= (lessThan768 ? 2 : 3) &&
              // 			// !watch(`checkmark[${index}]`)
              // 			// 	? true
              // 			// 	: false
              // 			// 		}
              // 				/> */}
              // 					{/* <label
              // 					className="round-label"
              // 					htmlFor={`compareCheck${singleQuote?.policyId}`}
              // 				></label> */}
              // 				</span>
              // 			</StyledDiv1>
              // 			{/* <i className="fa fa-plus" onClick={() => addItems(singleQuote)}>Add</i> */}
              // 		</div>
              // 		{/* <p style={{ fontSize: '13px' }}> {singleQuote.companyName} </p> */}
              // 	</div>
              // </div>
            ))}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            position:
              tempData.quoteComprehesiveGrouped.length > 2
                ? "sticky"
                : "absolute",
            bottom: "0",
            right: "0",
            width: "100%",
            zIndex: "999",
          }}
        >
          <CompareButton
            // onClick={closePop}
            style={{
              width: "100%",
              fontWeight: "bold",
              border: "none",
              padding: "15px 50px",
              letterSpacing: "0.5px",
              color: "#fff",
              borderRadius: "5px",
            }}
          >
            Compare
          </CompareButton>
        </div>
      </TopPop2>
    </>
  );

  const onClose = () => {
    dispatch(setShowPop(false))
  }

  return (
    <div>
      {!lessThan768 && (
        <Popup
          content={content}
          show={showPop}
          // closePop={onClo}
          reduxClose
          width="900px"
          mobileHeight="90% !important"
          height={lessThan768 ? "90% !important" : "auto"}
        />
      )}
    </div>
  );
};

export default ProductPopup;

const TopPop = styled.div`
  .add_plans {
    font-family: ${({ theme }) =>
      theme.mediumFont?.fontFamily || "Inter-SemiBold"};
  }
`;

const TopPop2 = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  height: ${({ innerHeight }) => (innerHeight ? innerHeight + "px" : "100vh")};
  .add_plans {
    font-family: ${({ theme }) =>
      theme.mediumFont?.fontFamily || "Inter-SemiBold"};
  }
  .productCheck {
    background: ${({ theme }) => theme.comparePage?.color || "#bdd400"};
  }
`;

const CardDiv = styled.div`
  background: ${({ theme }) =>
    theme.CardPop?.background || "rgb(18 211 77 / 6%)"};
  border: ${({ theme }) => theme.CardPop?.border || "1px solid green"};
`;

const NoPlansDiv = styled.div`
  background: ${({ theme }) => theme.NoPlanCard?.background || "#f7f7fa"};
  // border: ${({ theme }) => theme.NoPlanCard?.border || "2px dotted green"};
`;

const CompareButton = styled.button`
  background: ${({ theme }) => theme.comparePage?.color || "#bdd400"};
`;

const StyledDiv1 = styled.div`
  position: absolute;
  right: 47px;
  top: 1px;
  z-index: 101;
  .round-label::after {
    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid  #d4d5d9"};
  }

  .group-check input[type="checkbox"]:checked + label::before {
    transform: scale(1);
    background-color: ${({ theme }) => theme?.QuoteCard?.color || "#bdd400"};

    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid #bdd400"};
  }
`;

const StyledDiv2 = styled.div`
  position: absolute;
  right: 10px;
  top: -12px;
  z-index: 101;
  .round-label::after {
    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid  #d4d5d9"};
  }

  .group-check input[type="checkbox"]:checked + label::before {
    transform: scale(1);
    background-color: ${({ theme }) => theme?.QuoteCard?.color || "#bdd400"};

    border: ${({ theme }) =>
      theme?.QuoteCard?.borderCheckBox || "1px solid #bdd400"};
  }
`;
