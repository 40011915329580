import HttpClient from "api/httpClient";
import _ from 'lodash';

//lead-page
const enquiry = (data) =>
  HttpClient("/createEnquiryId", { method: "POST", data });

//journey-type
const type = (data) => HttpClient("/getOwnerTypes", { method: "POST", data });

//vehicle type
const vehicleType = (data) =>
  HttpClient("/getVehicleCategory", { method: "POST", data });

//variant type
const variantType = (data) =>
  HttpClient("/getModelVersion", { method: "POST", data });

//brand type
const brandType = (data) =>
  HttpClient("/getManufacturer", { method: "POST", data });

//model type
const modelType = (data) => HttpClient("/getModel", { method: "POST", data });

//RTO
const rto = (data) => HttpClient("/getRto", { method: "POST", data });

//prefill
const prefill = (data) =>
  HttpClient("/getUserRequestedData", { method: "POST", data });

//save
const save = (data) =>
  HttpClient("/saveQuoteRequestData", { method: "POST", data });

//callUs
const callUs = (data) => HttpClient("/callUs", { method: "POST", data });

//shareQuote
const shareQuote = (data) => HttpClient("/sendEmail", { method: "POST", data });

//Token Validation
const tokenVal = (data) =>
  HttpClient("/tokenValidate", { method: "POST", data }, false, false, true);

//produvt category
const subType = (data) =>
  HttpClient("/getProductSubType", { method: "POST", data });

//Theme category
const themeService = (data) => HttpClient("/themeConfig", { method: "GET" });

//getFuel
const getFuel = (data) => HttpClient("/getFuelType", { method: "POST", data });

//fastLane
const getFastLane = (data) =>
  HttpClient(
    `/getVehicleDetails?enquiryId=${data?.enquiryId}&registration_no=${
      data?.registration_no
    }${data?.productSubType ? `&productSubType=${data?.productSubType}` : ""}${
      data?.section ? `&section=${data?.section}` : ""
    }${
      data?.tokenResp && !_.isEmpty(data?.tokenResp)
        ? `&tokenResp=${JSON.stringify(data?.tokenResp)}`
        : ""
    }
    ${
      process.env.REACT_APP_BROKER === "FYNTUNE" ||
      process.env.REACT_APP_BROKER === "OLA" ||
      process.env.REACT_APP_BROKER === "RB" ||
      process.env.REACT_APP_BROKER === "ABIBL" || 
      process.env.REACT_APP_BROKER === "ACE"
        ? `&is_renewal=Y`
        : ``
    }`,
    {
      method: "GET",
    }
  );

//fastLane
const getFastLaneRenewal = (data) =>
  HttpClient(
    `/getVehicleDetails?enquiryId=${
      data?.enquiryId
    }${`&registration_no=${data?.registration_no}`}${
      data?.productSubType ? `&productSubType=${data?.productSubType}` : ""
    }${
      data?.tokenResp && !_.isEmpty(data?.tokenResp)
        ? `&tokenResp=${JSON.stringify(data?.tokenResp)}`
        : ""
    }
    ${data?.section ? `&section=${data?.section}` : ""}${`&is_renewal=Y`}${
      data?.policyNumber ? `&isPolicyNumber=Y` : ""
    }${data?.policyNumber ? `&policyNumber=${data?.policyNumber}` : ""}`,
    {
      method: "GET",
    }
    // true
    // https://apimotor.fynity.in/api
  );

//whatsapp service
const whatsappTrigger = (data) =>
  HttpClient("/whatsappNotification", { method: "POST", data });

//link-click & delivery
const linkTrigger = (data) =>
  HttpClient("/linkDelivery", { method: "POST", data });

const validationService = (data) =>
  HttpClient("/addProposalValidation", { method: "POST", data });

const getValidationService = (data) =>
  HttpClient("/getProposalValidation", { method: "GET" });

const getIcList = (data) => HttpClient("/getIcList", { method: "GET" });

const getFrontendUrl = (data) => HttpClient("/frontendUrl", { method: "GET" });

export default {
  enquiry,
  type,
  vehicleType,
  brandType,
  modelType,
  rto,
  variantType,
  prefill,
  save,
  callUs,
  tokenVal,
  shareQuote,
  subType,
  themeService,
  getFuel,
  getFastLane,
  getFastLaneRenewal,
  whatsappTrigger,
  linkTrigger,
  validationService,
  getValidationService,
  getIcList,
  getFrontendUrl,
};
